import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { exchangeApiStatusSelector } from "../../../../common/commonSlice";
import { useNavigate } from "react-router-dom";
import ErrorInfo from "../../../../common/components/ErrorInfo";

type Props = {
  editId: string | undefined;
  isLocked: boolean;
  lockDate: string;
  name: string;
  isFormSubmit: boolean;
  rolePermission: any;
  isPrint: boolean;
  isError?: boolean;
  action?: string;
  isButtonGroupDisabled?: boolean;
  isDisabledSave?: boolean;
  isDisabledDraft: boolean;
  onClickSubmit: (status: string, action: string) => void;
  onClickDelete: () => void;
  onCancelClick?: () => void;
};
function SubscriptionFormFooter({
  name,
  isFormSubmit,
  isError,
  action,
  isDisabledSave,
  onClickSubmit,
  onCancelClick,
}: Props) {
  const navigate = useNavigate();
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  return (
    <div className="ri-form-footer row mb-0">
      <div className="col">
        <div
          className={
            isFormSubmit && !exchangeRateAPiStatus
              ? "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          {isError ? (
            <div className="form-error-container w-100">
              <ErrorInfo
                message={`Oops, we were unable to ${action}. Please fix the errors.`}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-btn-container d-flex w-100">
            <button
              type="button"
              className="save-button mx-0"
              onClick={() => onClickSubmit("Open", "Send")}
              disabled={isDisabledSave ? isDisabledSave : false}
            >
              Save
            </button>
            <button
              type="button"
              onClick={() =>
                name === "plan-popover"
                  ? onCancelClick && onCancelClick()
                  : navigate(-1)
              }
              className="text-decoration-none cancel-button mx-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionFormFooter;
