import * as yup from "yup";
import { message } from "../../../../constants/messages";

export const vatSettingsValidation = yup.object({
  tax_registration_number: yup.string().when("vat_enabled", {
    is: "Yes",
    then: (schema) =>
      schema
        .required(message().trnRequired)
        .matches(
          /^\d{15}$/,
          message().trnLength
        ),
  }),
});
