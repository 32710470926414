import { Container } from "../../../common/components/htmlTags/Container";
import { Section } from "../../../common/components/htmlTags/Section";
import BudgetSummaryReportTable from "./components/budgetSummaryReportTable/BudgetSummaryReportTable";
import InfoBox from "../../../common/components/infoBox/InfoBox";
import BudgetSummaryReportFilter from "./components/budgetSummaryReportFilter/BudgetSummaryReportFilter";
import { message } from "../../../constants/messages";
import BudgetSummaryReportOrgDetails from "./components/budgetSummaryReportOrgDetails/BudgetSummaryReportOrgDetails";
import { useBudgetSummaryReport } from "./hooks/useBudgetSummaryReport";
import { DataInput } from "./types";

const BudgetSummaryReport = () => {
  const {
    handleChange,
    handleReset,
    handleSubmit,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
  } = useBudgetSummaryReport();

  return (
    <Section className="budget-variance-report-section">
      {/* <Container className="budget-variance-report-header"> */}
      <h1>Budget Summary Report</h1>
      {/* </Container> */}
      <Container className="budget-variance-report-container">
        <BudgetSummaryReportFilter
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          setValues={setValues}
          setErrors={setErrors}
          setTouched={setTouched}
          touched={touched}
        />
        {values.budgetData && Object.keys(values.budgetData).length > 0 && (
          <>
            <BudgetSummaryReportOrgDetails
              organizationDetails={
                (values.data as DataInput)?.organization_details
              }
              budgetRange={
                (values.data as DataInput)?.budget_range
              }
            />
            {/* <Container className="report-note-container">
              <InfoBox
                message={
                  message((values.data as DataInput).currency_code)
                    .reportCurrencyInfo
                }
                className="report-note"
              />
            </Container> */}
            <BudgetSummaryReportTable
              accountNames={values.accountNames}
              budgetData={values.budgetData}
              editableDateRanges={values.editableDateRanges}
              currency_code={(values.data as DataInput)?.currency_code}
            />
          </>
        )}
      </Container>
    </Section>
  );
};

export default BudgetSummaryReport;
