import BackButton from "./BackButton";
import TransactionNumber from "./TransactionNumber";
import { useNavigate } from "react-router-dom";

type Props = {
  transactionName: string;
  transactionNumber: number;
};
const TransactionHeader = (props: Props) => {
  const navigate = useNavigate();
  const firstHeading =
    props.transactionName === "Customer" ||
    props.transactionName === "Vendor" ||
    props.transactionName === "Budget"
      ? "Add"
      : props.transactionName === "Credit Note" ||
        props.transactionName === "Debit Note"
      ? "Add New"
      : props.transactionName === "Record Payment" ||
        props.transactionName === "Bill Payment" ||
        props.transactionName === "Budget"
      ? ""
      : "Create";

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <h1 className="fw-bold m-0">
        {props.transactionNumber
          ? `Edit ${props.transactionName}`
          : `${firstHeading} ${props.transactionName}`}
      </h1>
      {props.transactionNumber && props.transactionName !== "Charges" ? (
        <TransactionNumber transactionNumber={props.transactionNumber} />
      ) : null}
    </>
  );
};

export default TransactionHeader;
