import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import "./invoicingForm.css";
import { InvoicingForm } from "./InvoicingForm";
import { PlusIconTransparent } from "../../../common/components";
import { colors } from "../../../constants/colors";

type Props = {};
export const InvoicingFormModal = (props: Props) => {
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onSaveClose } = context;
            return (
              <InvoicingForm
                getSiteWideBilling={() => {}}
                onCloseModal={onSaveClose}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      onCloseFunction={() => {}}
      modalTitle="Create invoicing frequency"
      isButtonDisabled={false}
      drawerWrapperClass="calendar-billing billing-and-proration-modal"
      wrapperClass={`
        "calendar-billing-button"
       w-100 d-flex`}
      // buttonClass={`add-coupon-button add-tags-button`}
      buttonContent={
        <span
          style={{ display: "flex", marginLeft: "7px", alignItems: "center" }}
        >
          <PlusIconTransparent
            color={colors.booger}
            margin="0px 3px 0px 0px"
            width="20px"
            height="20px"
          />
          Add custom frequency
        </span>
      }
      buttonClass={"no-appearance cancel-btn configure-proration-setting-btn"}
    />
  );
};
