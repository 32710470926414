import { getFormatedDate } from "../../../../../helpers/helper";
import { initialItemValues } from "../../../../../helpers/transactionHelper";
import { sourceTaxTypes, tdsApplyLevel } from "../../../../constants/constants";

export const initialValues = {
  customer_id: "",
  customer_name: "",
  credit_note_number: "",
  subject: "",
  credit_note_date: getFormatedDate(new Date()),
  customer_notes: "",
  file_ids: [],
  tag_ids: [],
  items: [initialItemValues],
  email_to: [],
  conversion_id: "",
  currency_id: "",
  reference_number: "",
  is_locked: false,
  lock_date: "",
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,
  reason: "",
  place_of_supply: "",
};

export const initialTotalValues = {
  total_taxable_amount: 0,
  tax_amount: 0,
  total: 0,
  taxes: [],
  credits_used: 0,
  credit_note_balance: 0,
  transaction_tds_amount: 0,
};

export const initialDefaultValues = {
  customer_id: "",
  customer_name: "",
  credit_note_number: "",
  subject: "",
  credit_note_date: getFormatedDate(new Date()),
  currency_id: "",
  reference_number: "",
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,
  reason: "",
  place_of_supply: "",
};

export const creditNoteDetailsInitialValues = {
  id: "",
  customer_id: "",
  user_id: "",
  organization_id: "",
  credit_note_status: "",
  customer_name: "",
  customer_notes: "",
  currency_code: "",
  credit_note_number: "",
  subject: "",
  credit_note_date: "",
  custom_fields: {},
  address: {
    country_id: "",
    country_name: "",
    address: "",
    city: "",
    state_id: "",
    state_name: "",
    zip_code: "",
    phone: "",
    is_billing: false,
    is_shipping: false,
  },
  item_details: [],
  total_details: {
    total_taxable_amount: "",
    tax_amount: "",
    total: "",
    taxes: [],
    credits_used: "",
    credit_note_balance: "",
    transaction_tds_amount: 0,
  },
  credit_note_files: [],
  email_to: [],
  organization_details: {
    organization_name: "",
    email: "",
    country: "",
    state: "",
    address: "",
    logo_path: "",
    phone_number: "",
    zip_code: "",
    city: "",
  },
  reference_number: "",
  is_locked: false,
  lock_date: "",
  reason: "",
  tcs_total: 0,
  tds_level: "",
  tds_name: null,
  tds_percentage: null,
  tcs_name: null,
  tcs_percentage: null,
  place_of_supply: "",
  conversion_id: 0,
  invoice_status: true,
  is_fiscal_closed: false,
  customer_trn: null
};
