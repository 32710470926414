import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../app/hooks";
import { ErrorImg, InfoIconImg } from "../../../../assets/images";
import { COA, ObjectType } from "../../../../types";
import "../ChartOfAccounts.css";
import Select from "react-select";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import {
  accountsSelector,
  getCoaListView,
  getCoaTreeView,
  getCoaTreeViewInAddAccount,
  update,
  checkAccountNameExist,
  amountCalculation,
  checkGLCodeExists,
} from "../coaSlice";
import {
  currenciesSelector,
  latestCurrencyRateSelector,
  exchangeApiStatusSelector,
  currencyDropdown,
} from "../../../common/commonSlice";
import { colors } from "../../../constants/colors";
import {
  getFormatedDate,
  splitNumberByDecimalPoint,
} from "../../../../helpers/helper";
import InputPrefix from "../../../common/components/formPrefixInputField/InputPrefix";
import ExchangeRate from "../../../common/components/ExchangeRate";
import Loader from "../../components/Loader";
import { getRoundOffAmount } from "../../../../helpers/roundOffHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../hooks/useCommon";
import ChangeBranchModal from "./changeBranchModal/ChangeBranchModal";
import InfoTooltip from "../../../common/components/InfoTooltip";
import { getFiscalYear } from "../../../../helpers/fiscalYearHelper";

type Props = {
  accountIdReset: () => void;
  editAccountId: number;
  page: number;
  itemsPerPage: number;
  accountName: string;
  accountType: string;
  editAccountName: string;
  editAccountDescription: string;
  editGlStartCode: string;
  editGlEndCode: string;
  editParentId: number;
  editCurrencyId: number;
  parentGlStartCode: string;
  parentGlEndCode: string;
  nameSort: string;
  typeSort: string;
  balanceSort: string;
  organizationId: number;
  isCategory: boolean;
  isTransactionExist: boolean;
  isSuperParent: boolean;
  openingBalance?: number | string;
  currentBalance: number | string;
  conversionId: number | null;
  superParentId: number;
  isMutable: boolean;
  parentId: number;
  isBankAccount: boolean;
  refreshList: () => void;
};
const EditAccount: React.FC<Props> = ({
  accountIdReset,
  editAccountId,
  page,
  itemsPerPage,
  accountName,
  accountType,
  editAccountName,
  editAccountDescription,
  editGlStartCode,
  editGlEndCode,
  editParentId,
  editCurrencyId,
  parentGlStartCode,
  parentGlEndCode,
  nameSort,
  typeSort,
  balanceSort,
  organizationId,
  isCategory,
  isTransactionExist,
  isSuperParent,
  openingBalance,
  currentBalance,
  conversionId,
  superParentId,
  isMutable,
  parentId,
  isBankAccount,
  refreshList,
}) => {
  const initialValues = {
    account_name: "",
    description: "",
    gl_start_code: "",
    currency_id: 0,
    opening_balance: 0,
    // ledger_code: ""
  };
  let interval: any = null;
  const [accountId, setAccountId] = useState(editAccountId);
  const [inputs, setInputs] = useState<COA>(initialValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [convertedAmount, setConvertedAmount] = useState(currentBalance);
  const [bankCurrencyCode, setBankCurrencyCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const accountsData = useAppSelector(accountsSelector);
  const accounts = accountsData.list_view;
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const [isGLCodeAvailable, setGLCodeAvailable] = useState(true);
  const [isAccNameAvailable, setAccNameAvailable] = useState(true);
  const [accountTypeState, setAccountTypeState] = useState(accountType);
  const currencies = useAppSelector(currenciesSelector);
  const exchangeRef = useRef<any>([]);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);

  const currencyOptions = currencies?.map((curr) => {
    return {
      label: curr.code,
      value: curr.id,
    };
  });
  const fiscal_year_date = getFiscalYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "current"
  );

  useEffect(() => {
    setConvertedAmount(currentBalance);
  }, [currentBalance]);

  useEffect(() => {
    setAccountTypeState(accountType);
  }, [accountType]);

  useEffect(() => {
    inputs.currency_id &&
      setInputs({ ...inputs, conversion_id: exchangeRateValues.id });
  }, [exchangeRateValues.id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getOpeningBalanceConversion(inputs.opening_balance, signal);
    return () => {
      controller.abort();
    };
  }, [inputs.opening_balance, inputs.currency_id, exchangeRateValues.id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (
      inputs.gl_start_code?.toString() !== parentGlStartCode?.toString() &&
      inputs.gl_start_code?.toString() !== parentGlEndCode?.toString()
    ) {
      glCodeExist(inputs.gl_start_code, signal);
    }
    return () => {
      controller.abort();
    };
  }, [inputs.gl_start_code]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    isAccountNameExist(inputs.account_name.trim(), "account_name", signal);
    return () => {
      controller.abort();
    };
  }, [inputs.account_name]);

  useEffect(() => {
    let inputData = { ...inputs };
    if (inputData.opening_balance) {
      const updatedCurrencyCode = getCurrencyCode(inputs.currency_id);
      const updatedDecimals = decimalPlaceOfCurrency(
        updatedCurrencyCode,
        orgCurrencyList
      );
      inputData.opening_balance = getRoundOffAmount(
        Number(inputData.opening_balance),
        updatedDecimals
      );
      setInputs(inputData);
    }
  }, [inputs.currency_id]);

  const getCurrencyCode = (id: number) => {
    const code = currencies?.filter((curr) => {
      return curr.id === id;
    })[0]?.code;
    return code ? code : "";
  };

  const onChangeCurrency = (e: any) => {
    const name = e.label;
    const value = e.value;
    setInputs((values) => ({ ...values, currency_id: value }));
    setConvertedAmount("");
    setBankCurrencyCode(name);
  };

  // /**
  //  * Fetch all currency list
  //  */
  // const getCurrencyList = async () => {
  //   await dispatch(currencyDropdown(currentUserInfo.organization_id));
  // };

  /**
   * Set the input field with selected account details
   */
  useEffect(() => {
    if (editAccountId > 0) {
      setInputs({
        account_name: editAccountName,
        description: editAccountDescription,
        gl_start_code: editGlStartCode,
        currency_id: editCurrencyId,
        opening_balance: openingBalance,
      });
    }
  }, [editAccountId]);

  // useEffect(() => {
  //   getCurrencyList();
  // }, []);

  /**
   * Edit Account form submission handler
   */
  const handleUpdateAccounts = (e: any) => {
    e.preventDefault();
    const errors = validateUpdateAccount(inputs);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setIsSubmit(true);
      setFormErrors({});
      $("#update-account-button").addClass(
        "update-account-form-section-disable"
      );

      let inputData = JSON.parse(JSON.stringify(inputs));
      if (inputData.gl_start_code === "" || inputData.gl_start_code === null) {
        delete inputData.gl_start_code;
      }
      editAccountSubmitHandler({
        accountId: editAccountId,
        value: inputData,
        orgId: organizationId,
      });
    }
  };

  /**
   * Validate edit account form field
   */
  const validateUpdateAccount = (values: COA) => {
    const errors: ObjectType = {};
    if (!values.account_name?.trim()) {
      errors.account_name = "Please enter your account name";
    }
    if (!isAccNameAvailable) {
      errors.account_name = "Account name already exists!";
    }
    const glCodeRegex = /^[0-9\b]+$/;
    if (
      values.gl_start_code?.trim() &&
      !glCodeRegex.test(values.gl_start_code)
    ) {
      errors.gl_start_code = "Please enter valid ledger code";
    } else if (
      values.gl_start_code?.trim() &&
      values.gl_start_code.length !== 6
    ) {
      errors.gl_start_code = "The ledger code should be 6 digits";
    } else if (
      values.gl_start_code?.trim() &&
      (values.gl_start_code <= parentGlStartCode ||
        values.gl_start_code >= parentGlEndCode)
    ) {
      errors.gl_start_code = "The ledger code is out of the range";
    } else if (!isGLCodeAvailable) {
      errors.gl_start_code = "Ledger code already exists!";
    }
    return errors;
  };

  /**
   * Edit COA
   */
  const editAccountSubmitHandler = async (data: {
    accountId: number;
    value: COA;
    orgId: number;
  }) => {
    setIsLoading(true);
    const responseAction = await dispatch(update(data));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setIsLoading(false);
        $(".close-btn").click();
        await dispatch(
          getCoaListView({
            page: page,
            itemsPerPage: itemsPerPage,
            accountName: accountName,
            nameSort: nameSort,
            typeSort: typeSort,
            balanceSort: balanceSort,
            orgId: organizationId,
          })
        );
        await dispatch(getCoaTreeView({ name: "", orgId: organizationId }));
        await dispatch(getCoaTreeViewInAddAccount(organizationId));
        toast.success("Account updated successfully!", {
          toastId: "account-update-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        setIsLoading(false);
        if (response.error?.detail?.[0]?.msg === "GL Code already exists") {
          $("#update-account-button").removeClass(
            "update-account-form-section-disable"
          );
          setFormErrors({
            ...formErrors,
            gl_start_code: "The ledger code already exist",
          });
        } else if (response.error?.detail?.[0]?.msg === "Invalid GL Code") {
          $("#update-account-button").removeClass(
            "update-account-form-section-disable"
          );
          setFormErrors({
            ...formErrors,
            gl_start_code: "The ledger code is out of the range",
          });
        } else {
          $(".close-btn").click();
          $("#update-account-button").removeClass(
            "update-account-form-section-disable"
          );
          toast.error("Failed to updates account details", {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} width="16" height="14" />
            ),
            toastId: "update-account-failed-error",
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
        }
      }
    }
  };

  /**
   * Input change handler in edit account form field
   */
  const inputChangeHandler = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    const openingBalanceRegex = /^-?([0-9,]{1,})?(\.)?([0-9]{1,})?$/;

    if (name === "gl_start_code") {
      const glCodeRegex = /^[0-9\b]+$/;
      if (value === "" || glCodeRegex.test(value)) {
        setInputs((values) => ({ ...values, [name]: value }));
      }
    } else if (name === "opening_balance") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || openingBalanceRegex.test(value)) {
          setInputs({ ...inputs, [name]: value === "" ? 0 : value });
          if (value === "") {
            setConvertedAmount("");
          }
        }
      }
    } else if (name === "account_name") {
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  /**
   * Dynamic converted amount calculation for opening balance
   */
  const getOpeningBalanceConversion = async (
    opening_balance: number | string | undefined,
    signal?: Object
  ) => {
    if (inputs.currency_id) {
      const responseAction = await dispatch(
        amountCalculation({
          orgId: currentUserInfo.organization_id,
          values: {
            conversion_date: getFormatedDate(new Date()),
            currency_id: inputs.currency_id,
            organization_id: currentUserInfo.organization_id,
            conversion_id: exchangeRateValues.id,
            amount: opening_balance,
            org_currency_id: currentUserInfo.organization_currency,
            account_id: editAccountId,
          },
          signal: signal ? signal : {},
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setConvertedAmount(response.converted_amount);
        }
      }
    }
  };

  /**
   * Function to round off default input elements after out of focus.
   */
  const handleItemOnBlur = (
    name: string,
    amount: number,
    currencyCode: string
  ) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
      if (name === "opening_balance" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        setInputs({ ...inputs, [name]: roundedAmount });
      }
    }
  };

  const isAccountNameExist = async (
    name: string,
    field: string,
    signal: AbortSignal
  ) => {
    if (name) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkAccountNameExist({
            accountName: name,
            orgId: organizationId,
            editAccountId: editAccountId,
            signal: signal,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.name_available === true) {
              formErrors.account_name && delete formErrors.account_name;
              setFormErrors({ ...formErrors });
              setAccNameAvailable(true);
            } else {
              setFormErrors({
                ...formErrors,
                account_name: "Account name already exists!",
              });
              setAccNameAvailable(false);
            }
          }
        }
      }, 1000);
    } else {
      formErrors.account_name && delete formErrors.account_name;
      setFormErrors({ ...formErrors });
      setAccNameAvailable(true);
    }
  };

  const glCodeExist = async (glCode: string | number, signal: AbortSignal) => {
    const responseAction = await dispatch(
      checkGLCodeExists({
        accountId: editAccountId,
        orgId: organizationId,
        glCode: glCode,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.is_available === true) {
          formErrors.account_name && delete formErrors.account_name;
          formErrors.gl_start_code && delete formErrors.gl_start_code;
          setFormErrors({ ...formErrors });
          setGLCodeAvailable(true);
        } else {
          setFormErrors({
            ...formErrors,
            gl_start_code: "Ledger code already exists!",
          });
          setGLCodeAvailable(false);
        }
      }
    }
  };

  const setAccountTypeHandler = (account: string) => {
    setAccountTypeState(account);
  };
  /**
   * Edit account modal close handler
   */
  $(document).ready(function () {
    $(".modal .close-btn").on("click", function () {
      setAccountId(-1);
      setInputs(initialValues);
      setIsSubmit(false);
      setFormErrors({});
      accountIdReset();
      $("#update-account-button").removeClass(
        "update-account-form-section-disable"
      );
    });
  });

  const dateFormatHandler = (date: string | null) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };

  const openingBalanceToolTip = currentUserInfo.is_ledger_org_closed
    ? `Opening Balance is as of ${dateFormatHandler(
        fiscal_year_date.startDate
      )}`
    : `Opening Balance is as of ${dateFormatHandler(
        currentUserInfo.migration_date
      )}`;

  const openingBalanceDate = currentUserInfo.is_ledger_org_closed
    ? fiscal_year_date.startDate
    : currentUserInfo.migration_date;

  return (
    <div>
      <div
        className="modal fade right"
        id="editAccountModal"
        tabIndex={-1}
        aria-labelledby="editAccountModalLabel"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-fullscreen w-100">
          <div className="modal-content">
            <div className="modal-header close-button-container">
              <h4></h4>
              <button
                type="button"
                className="close-btn pull-right"
                data-bs-dismiss="modal"
                id="close-modal"
                aria-label="Close"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      fill={colors.slate}
                      fillRule="nonzero"
                      d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    />
                  </g>
                </svg>
              </button>
            </div>
            <div className="modal-body edit-form">
              <div className="edit-form-head">
                <h4 className="mb-3">Edit Account</h4>
              </div>
              <div className="selected-branch-wrapper">
                <span className="selected-branch">Selected Branch: </span>
                <span className="branch">
                  {accountTypeState}/{inputs.account_name}
                </span>
                {!isCategory &&
                  !isBankAccount &&
                  isMutable &&
                  !isTransactionExist &&
                  isSuperParent &&
                  Number(openingBalance) === 0 && (
                    <span style={{ cursor: "pointer" }}>
                      <ChangeBranchModal
                        superParentId={superParentId}
                        editAccountId={editAccountId}
                        parentId={parentId}
                        refreshList={refreshList}
                        isBankAccount={isBankAccount}
                        setAccountTypeHandler={setAccountTypeHandler}
                      />
                    </span>
                  )}
              </div>
              <form
                className="edit-account-form-wrapper mb-4 pb-2"
                onSubmit={handleUpdateAccounts}
              >
                <div className={formErrors.account_name ? "error" : "mb-4"}>
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label form-labels required"
                  >
                    Account Name
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.account_name
                        ? "form-control error"
                        : "form-control"
                    }
                    id="exampleInputEmail1"
                    aria-describedby="accountName"
                    placeholder="Enter account name"
                    name="account_name"
                    disabled={
                      !isSuperParent || (isCategory && isTransactionExist)
                        ? true
                        : false
                    }
                    value={inputs.account_name || ""}
                    onChange={inputChangeHandler}
                    maxLength={40}
                  />
                  <span className="error">{formErrors.account_name}</span>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="accountDescription"
                    className="form-label form-labels"
                  >
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="accountDescription"
                    name="description"
                    placeholder="Add description"
                    disabled={
                      !isSuperParent || (isCategory && isTransactionExist)
                        ? true
                        : false
                    }
                    value={inputs.description || ""}
                    onChange={inputChangeHandler}
                  ></textarea>
                </div>
                {!isCategory ? (
                  <div className="mb-3">
                    <label
                      htmlFor="ledgerCode"
                      className="form-label form-labels"
                    >
                      Ledger Code ( Range between {parentGlStartCode} -{" "}
                      {parentGlEndCode})
                    </label>
                    <input
                      type="text"
                      className={
                        formErrors.gl_start_code
                          ? "form-control error"
                          : "form-control"
                      }
                      id="ledgerCode"
                      aria-describedby="accountName"
                      placeholder="Enter ledger code"
                      name="gl_start_code"
                      maxLength={6}
                      value={inputs.gl_start_code || ""}
                      onChange={inputChangeHandler}
                      disabled={
                        isTransactionExist || !isSuperParent ? true : false
                      }
                    />
                    <span className="error">{formErrors.gl_start_code}</span>
                  </div>
                ) : null}
                {accountTypeState.slice(0, 20) === "Assets/Bank Accounts" &&
                  !isCategory && (
                    <div className="mb-3">
                      <label
                        htmlFor="currency"
                        className="form-label form-labels"
                      >
                        Currency
                      </label>
                      <Select
                        name="currency"
                        options={currencyOptions}
                        placeholder="Choose currency"
                        className="form-select custom-select accounts-select"
                        isDisabled={
                          !isSuperParent || isTransactionExist ? true : false
                        }
                        value={
                          inputs.currency_id == null || inputs.currency_id == 0
                            ? ""
                            : {
                                label: currencies?.filter((curr) => {
                                  return curr.id == inputs.currency_id;
                                })[0]?.code,
                                value: inputs.currency_id,
                              }
                        }
                        styles={customSelectStyle}
                        // isDisabled={isSelected ? false : true}
                        onChange={onChangeCurrency}
                        isSearchable={true}
                      />
                    </div>
                  )}
                {(accountTypeState.slice(0, 20) === "Assets/Bank Accounts" ||
                  accountTypeState.slice(0, 20) ===
                    "Liabilities/Credit Card") &&
                  !isCategory && (
                    <div
                      className={
                        "mb-3" +
                        (inputs.currency_id !== 0 ? "" : " hide-base-currency")
                      }
                    >
                      <div className="opening-balance-wrap">
                        <label
                          htmlFor="accountBalance"
                          className="form-label form-labels coa-opening-balance-label"
                          style={{ width: "auto" }}
                        >
                          Opening Balance{" "}
                          <span className="sub-label">{`(Bank Currency)`}</span>
                          <InfoTooltip
                            title={openingBalanceToolTip}
                            placement={"top"}
                          />
                        </label>
                        <span className="opening-balance-exchange-rate">
                          <ExchangeRate
                            currencyId={inputs.currency_id}
                            currencyCode={bankCurrencyCode}
                            editId={editAccountId.toString()}
                            conversionId={conversionId ? conversionId : ""}
                            conversionCurrencyId={editCurrencyId}
                            conversionDate={getFormatedDate(new Date(openingBalanceDate as string))}
                            newConversionDate={getFormatedDate(new Date(openingBalanceDate as string))}
                            ref={exchangeRef}
                            module="COA"
                          />
                        </span>
                      </div>
                      <InputPrefix
                        inputType="text"
                        className="form-control"
                        wrapperClass="coa-opening-balance-field"
                        id="accountBalance"
                        name={
                          (accountTypeState.slice(0, 20) ===
                            "Assets/Bank Accounts" ||
                            accountTypeState.slice(0, 20) ===
                              "Liabilities/Credit Card") &&
                          !inputs.is_category
                            ? "opening_balance"
                            : ""
                        }
                        value={
                          inputs?.opening_balance ? inputs?.opening_balance : ""
                        }
                        placeholder="Enter opening balance"
                        onChange={inputChangeHandler}
                        disabled={
                          !accountTypeState ||
                          isCategory ||
                          currentUserInfo.is_ledger_org_closed
                            ? true
                            : false
                        }
                        onBlur={() =>
                          handleItemOnBlur(
                            "opening_balance",
                            inputs?.opening_balance
                              ? Number(inputs?.opening_balance)
                              : 0,
                            getCurrencyCode(inputs.currency_id)
                          )
                        }
                        prefix={
                          inputs.currency_id == null || inputs.currency_id == 0
                            ? ""
                            : currencies?.filter((curr) => {
                                return curr.id == inputs.currency_id;
                              })[0]?.code
                        }
                        error=""
                      />
                    </div>
                  )}
                {inputs.currency_id !== currentUserInfo.organization_currency &&
                  !isCategory && (
                    <div
                      className={
                        inputs.currency_id !==
                        currentUserInfo.organization_currency
                          ? "mb-0"
                          : "mb-3"
                      }
                    >
                      <label
                        htmlFor="accountBalance"
                        className="form-label form-labels"
                        style={{ width: "auto" }}
                      >
                        Opening Balance{" "}
                        <span className="sub-label">
                          {`(Base Currency)`}
                          <InfoTooltip
                            title={openingBalanceToolTip}
                            placement={"top"}
                          />
                        </span>
                      </label>
                      <InputPrefix
                        inputType="text"
                        className="form-control"
                        wrapperClass="coa-opening-balance-field"
                        id="accountBalance"
                        name={
                          (accountTypeState.slice(0, 20) ===
                            "Assets/Bank Accounts" ||
                            accountTypeState.slice(0, 20) ===
                              "Liabilities/Credit Card") &&
                          !inputs.is_category
                            ? ""
                            : "opening_balance"
                        }
                        value={
                          inputs?.currency_id
                            ? convertedAmount
                            : inputs?.opening_balance
                            ? inputs?.opening_balance
                            : ""
                        }
                        placeholder="Enter opening balance"
                        onChange={inputChangeHandler}
                        disabled={
                          (inputs.currency_id !==
                            currentUserInfo.organization_currency &&
                            (accountTypeState.slice(0, 20) ===
                              "Assets/Bank Accounts" ||
                              accountTypeState.slice(0, 20) ===
                                "Liabilities/Credit Card")) ||
                          currentUserInfo.is_ledger_org_closed
                            ? true
                            : false
                        }
                        onBlur={() =>
                          handleItemOnBlur(
                            "opening_balance",
                            inputs?.opening_balance
                              ? Number(inputs?.opening_balance)
                              : 0,
                            getCurrencyCode(
                              currentUserInfo.organization_currency
                            )
                          )
                        }
                        prefix={
                          currencies?.filter((curr) => {
                            return (
                              curr.id == currentUserInfo.organization_currency
                            );
                          })[0]?.code
                        }
                        error=""
                      />
                    </div>
                  )}
                <div className="row mb-0">
                  <div className="col">
                    <div
                      className="form-button-wrapper w-100"
                      id="update-account-button"
                    >
                      <button
                        className="save-button"
                        type="submit"
                        disabled={
                          (!isSuperParent && isCategory) ||
                          (isCategory && isTransactionExist) ||
                          exchangeRateAPiStatus ||
                          !isGLCodeAvailable ||
                          !isAccNameAvailable
                            ? true
                            : false
                        }
                      >
                        Update
                      </button>
                      <button
                        className="cancel-button"
                        type="button"
                        onClick={() => $(".close-btn").click()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default EditAccount;
