import React, { forwardRef, useRef } from "react";
import { ObjectType } from "../../../../../types";
import { message } from "../../../../constants/messages";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  downloadFile,
  getFileName,
} from "../../componets/exportDownloadHelper";
import ReportFilter from "../../componets/ReportFilter";
import ReportHeader from "../../componets/ReportHeader";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import CustomizeReportModal from "../../../../common/components/reportCustomizationModal/CustomizeReportModal";
import SavedCustomization from "../../../../common/components/reportCustomizationModal/CustomizeAgingReport/SavedCustomization";
import ReportsExportComponent from "../../../../common/components/reportsExportComponent/reportsExportComponent";
import "./AgingReport.css";

type Props = {
  title: string;
  reportData: ObjectType;
  showReport: boolean;
  type: string;
  module: string;
  children: React.ReactNode;
  reportRef: React.RefObject<any>;
  clearReport: () => void;
  handleReport: (type: string) => void;
};
export const initialAgingReportData = {
  currency_id: 0,
  currency_code: "INR",
  date_range: "today",
  organization_id: 0,
  interval_range: 0,
  interval_type: "",
  number_of_columns: 0,
  start_date: getFormatedDate(),
  end_date: getFormatedDate(),
  items: [],
  organization_details: {
    organization_name: "",
    email: "",
    country: "",
    state: "",
  },
};

function AgingReport(props: Props) {
  const exportFile = () => {
    const { ...filterValues } = props.reportRef.current.filterValues;

    let filePath = "";
    switch (props.title) {
      case "Sundry Debtor Aging Summary for Invoice Due Date":
        filePath = "aging-report/sundry-debtor-summary";
        break;
      case "Sundry Debtor Aging Details for Invoice Due Date":
        filePath = "aging-report/sundry-debtor-report";
        break;
      case "Sundry Creditor Aging Summary for Bill Due Date":
        filePath = "aging-report/sundry-creditor-summary";
        break;
      case "Sundry Creditor Aging Details for Bill Due Date":
        filePath = "aging-report/sundry-creditor-report";
        break;
      default:
        console.error("Invalid title for the aging report.");
        return;
    }

    downloadFile(
      filterValues,
      filePath,
      getFileName(
        initialAgingReportData.organization_details.organization_name || "",
        filePath,
        initialAgingReportData.start_date,
        initialAgingReportData.end_date
      )
    );
  };

  return (
    <div className="reports aging-report">
      <div
        className="card card-dashboard report-card aging-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Aging Report</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={true}
                isAdvanced={true}
                defaultValue="today"
                reportType="AR"
                handleReport={props.handleReport}
                clearReport={props.clearReport}
                ref={props.reportRef}
              />
              <div className="addition-filter-settings">
                <div className="filter-btn-col">
                  <SavedCustomization
                    module={props.module}
                    handleReport={props.handleReport}
                    reportData={props.reportData}
                    type={props.type}
                  />
                </div>
                <div className="filter-btn-col">
                  <CustomizeReportModal
                    module={props.module}
                    handleReport={props.handleReport}
                    reportData={{}}
                    type={props.type}
                    reportType="AR"
                    buttonClass="aging-report-advanced-filter cancel-button"
                  />
                </div>
              </div>
            </div>
            {props.showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center "></div>
                <ReportsExportComponent
                  onExportPDF={() => {}}
                  onExportExcel={exportFile}
                />
                <ReportHeader
                  reportData={props.reportData}
                  reportName={props.title}
                />
                <div className="table-section report-table-section">
                  <InfoBox
                    message={
                      message(props.reportData.currency_code).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  {props.children}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(AgingReport);
