import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { defaultValues } from "./StateInitialization";
import {
  DefaultDetailsRef,
  ObjectType,
  ShortCodeRef,
  VendorDefaultValues,
} from "../../../../../types";
import PhoneInput from "../../../../common/components/PhoneInput";
import EmailInput from "../../../../common/components/EmailInput";
import VendorCode from "./VendorCode";
import { FormInputFieldsInRows } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import { CustomFieldInTransactions } from "../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";

type Props = {
  defaultFormValues: VendorDefaultValues;
  formErrors: any;
  vendorId: number;
  customFieldRef: any;
  customFieldValue: ObjectType;
  getCustomFieldData: (data: ObjectType) => void;
};

const DefaultDetails: ForwardRefRenderFunction<DefaultDetailsRef, Props> = (
  {
    defaultFormValues,
    formErrors,
    vendorId,
    customFieldRef,
    getCustomFieldData,
    customFieldValue,
  },
  ref
) => {
  const shortCodeRef = useRef<ShortCodeRef | null>(null);
  const [defaultData, setDefaultData] = useState(defaultValues);
  const [vendorName, setVendorName] = useState("");

  const vendorCustomFields = useModuleCustomFieldList("Vendor");
  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultData,
      isShortCodeExist: shortCodeRef.current?.isShortCodeExist
        ? shortCodeRef.current?.isShortCodeExist
        : false,
    }),
    [defaultData]
  );
  useEffect(() => {
    if (defaultFormValues) {
      setDefaultData(defaultFormValues);
    }
  }, [defaultFormValues]);

  const handleChange = (data: { name: string; value: string | number }) => {
    let { name, value } = data;
    setDefaultData({ ...defaultData, [name]: value });
  };
  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <FormInputField
          type="text"
          name="vendor_display_name"
          id="c_display_name"
          className={
            formErrors.vendor_display_name
              ? "form-control error"
              : "form-control"
          }
          placeholder="Enter vendor display name"
          label="Vendor Display Name"
          value={defaultData.vendor_display_name}
          onChange={(e) => handleChange(e.target)}
          onInput={(e: any) => setVendorName(e?.target?.value)}
          maxLength={200}
          isRequired={true}
          disabled={vendorId ? true : false}
          error={formErrors.vendor_display_name}
        />
        <FormInputField
          type="text"
          name="company_name"
          id="company"
          className="form-control"
          placeholder="Enter company name"
          label="Company Name"
          value={defaultData.company_name}
          onChange={(e) => handleChange(e.target)}
          maxLength={200}
        />
      </div>
      <div className="row custom-row mb-0">
        <EmailInput
          name="vendor_email"
          id="c_email"
          label="Vendor Email"
          email={defaultData.vendor_email}
          isRequired={false}
          handleChange={handleChange}
          error={formErrors.vendor_email}
        />
        <PhoneInput
          name="vendor_phone"
          id="c_phone_number"
          label="Vendor Phone Number"
          phone={defaultData.vendor_phone}
          isRequired={false}
          handleChange={handleChange}
          error={formErrors.vendor_phone}
        />
      </div>
      <div className="row custom-row">
        <CustomFieldInTransactions
          getFieldValues={getCustomFieldData}
          customFieldsArray={vendorCustomFields}
          initialValues={customFieldValue}
          ref={customFieldRef}
          editId={vendorId ? Number(vendorId) : null}
        />
      </div>
      <div className="row custom-row mb-0 ">
        <VendorCode
          shortCode={defaultData.short_code}
          vendorId={vendorId}
          vendorName={vendorName}
          isRequired={true}
          handleChange={!vendorId ? handleChange : () => {}}
          error={formErrors.short_code}
          ref={shortCodeRef}
        />
      </div>
    </section>
  );
};

export default forwardRef(DefaultDetails);
