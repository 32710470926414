import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getFiscalQuarterYear } from "../../../../../helpers/fiscalYearHelper";
import { getFormatedDate } from "../../../../../helpers/helper";
import { ObjectType } from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import CustomizeReportModal from "../../../../common/components/reportCustomizationModal/CustomizeReportModal";
import { message } from "../../../../constants/messages";
import Loader from "../../../components/Loader";
import {
  getProfitAndLossReport,
  reportCustomizationFilterSelector,
  reportTableFilterSelector,
  setReportCustomizationFilters,
} from "../../ReportSlice";
import "../../Reports.css";
import ReportFilter from "../../componets/ReportFilter";
import ReportHeader from "../../componets/ReportHeader";
import "../ProfitAndLoss.css";
import filterAccounts from "./ProfitAndLossAccountFilter";
import ProfitAndLossReportTable from "./ProfitAndLossReportTable";
import { useLocation } from "react-router-dom";
import {
  downloadFile,
  getFileName,
} from "../../componets/exportDownloadHelper";
import ReportsExportComponent from "../../../../common/components/reportsExportComponent/reportsExportComponent";
import { PLDateArrayHelper } from "../../../../common/components/reportCustomizationModal/customizeReportPL/PLfilterHelper";

export default function ProfitAndLoss() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const reportRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const reportCustomizationValues = useAppSelector(
    reportCustomizationFilterSelector
  );
  const [isLoading, setIsLoader] = useState(false);
  const initialReportData = {
    currency_code: "",
    currency_id: 0,
    date_range: "today",
    end_date: getFormatedDate(),
    items: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
    },
    organization_id: "",
    profit_and_loss: {
      total_operating_income: 0,
      total_cost_of_goods_sold: 0,
      gross_profit: 0,
      non_operating_income: 0,
      total_operating_expense: 0,
      net_profit_loss_after_tax: 0,
      net_profit_loss_before_tax: 0,
    },
  };
  const initialFiltervalues = {
    currency_id: currentUserInfo.organization_currency,
    date_range: "this_quarter",
    end_date: getFiscalQuarterYear(
      currentUserInfo.organization_fiscal_year
        ? currentUserInfo.organization_fiscal_year
        : "",
      "Current"
    ).endDate,
    organization_id: currentUserInfo.organization_id,
    start_date: getFiscalQuarterYear(
      currentUserInfo.organization_fiscal_year
        ? currentUserInfo.organization_fiscal_year
        : "",
      "Current"
    ).startDate,
    tag_option_in: [],
    is_integrated: true,
  };
  const [reportData, setReportData] = useState<any>(initialReportData);
  const [showReport, setShowReport] = useState(false);
  const [drillDownFilter, setDrillDownFilter] = useState<ObjectType>({});
  const PLFilterValues = useAppSelector(
    reportCustomizationFilterSelector
  ).profitAndLossFilters;
  const tableFilters = useAppSelector(reportTableFilterSelector);
  const [orgName, setOrgName] = useState("");
  const [filterDataForExport, setFilterDataForExport] = useState<ObjectType>(
    {}
  );

  useEffect(() => {
    const storedFilter = localStorage.getItem("drill-down-filter");
    const filterParsed: ObjectType = storedFilter
      ? JSON.parse(storedFilter)
      : {};
    if (Object.keys(filterParsed).length) {
      if (
        currentUserInfo.organization_id &&
        currentUserInfo.organization_currency
      ) {
        setDrillDownFilter({ ...filterParsed });
        dispatch(
          setReportCustomizationFilters({
            ...reportCustomizationValues,
            profitAndLossFilters: {
              start_date: filterParsed.start_date,
              end_date: filterParsed.end_date,
              date_range: filterParsed.date_range,
              report_basis: filterParsed.report_basis,
              account_filter: filterParsed.account_filter,
              compare_with: filterParsed.compare_with,
              compare_value: filterParsed.compare_value,
              compare_list: filterParsed.compare_list,
              is_descending: filterParsed.is_descending,
              is_integrated: filterParsed.is_integrated,
              tag_option_in: filterParsed.tag_option_in,
            },
          })
        );
      }
    } else if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency &&
      PLFilterValues.compare_with !== ""
    ) {
      getReport("initialWithCompare", {}, PLFilterValues.compare_list);
    } else {
      if (
        currentUserInfo.organization_id &&
        currentUserInfo.organization_currency
      )
        getReport("initial");
    }
  }, [
    currentUserInfo.organization_id,
    currentUserInfo.organization_currency,
    location.pathname,
  ]);
  useEffect(() => {
    return () => {
      dispatch(
        setReportCustomizationFilters({
          ...reportCustomizationValues,
          profitAndLossFilters: {
            start_date: initialFiltervalues.start_date,
            end_date: initialFiltervalues.end_date,
            date_range: initialFiltervalues.date_range,
            report_basis: "Accrual",
            account_filter: "Accounts without zero balance",
            compare_with: "",
            compare_value: "",
            compare_list: [],
            is_descending: false,
            is_integrated: true,
          },
        })
      );
    };
  }, []);
  useEffect(() => {
    if (Object.keys(drillDownFilter).length) {
      getReport("drilldown", drillDownFilter);
    }
  }, [drillDownFilter]);

  const handleReport = () => {
    setIsLoader(true);
    getReport("submit");
  };
  const handleCustomizeReport = (type: string, plCustomizationValues?: any) => {
    getReport("submit", {}, plCustomizationValues);
  };
  /**
   * Fetch profit and loss report
   */
  const getReport = async (
    type: string,
    drillDownFilter: ObjectType = {},
    plCustomizationValues?: any
  ) => {
    let filterValues = reportRef.current.filterValues;
    /**
     * To avoid multiple api call is being called with initial data.
     */
    if (type === "initial") {
      filterValues = initialFiltervalues;
    } else if (type === "initialWithCompare") {
      filterValues = {
        currency_id: tableFilters.currency_id,
        organization_id: currentUserInfo.organization_id,
        start_date: tableFilters.start_date,
        end_date: tableFilters.end_date,
        report_basis: plCustomizationValues?.report_basis,
        date_range: tableFilters.date_range,
        comparing_list: plCustomizationValues?.compare_list && [
          ...plCustomizationValues.compare_list,
        ],
        is_descending: plCustomizationValues?.is_descending,
        tag_option_in: plCustomizationValues?.tag_option_in,
        is_integrated: tableFilters.is_integrated,
      };
    } else {
      filterValues = {
        currency_id: tableFilters.currency_id,
        organization_id: tableFilters.organization_id,
        start_date: tableFilters.start_date,
        end_date: tableFilters.end_date,
        report_basis: plCustomizationValues?.report_basis,
        date_range: tableFilters.date_range,
        comparing_list: plCustomizationValues?.compare_list && [
          ...plCustomizationValues.compare_list,
        ],
        is_descending: plCustomizationValues?.is_descending,
        tag_option_in: plCustomizationValues?.tag_option_in
          ? plCustomizationValues?.tag_option_in
          : tableFilters.tag_option_in
          ? tableFilters.tag_option_in
          : [],
        is_integrated: tableFilters.is_integrated,
        account_filter: plCustomizationValues?.account_filter,
      };
    }
    if (type === "drilldown") {
      if (Object.keys(drillDownFilter).length) {
        filterValues = {
          currency_id: drillDownFilter.currencyId,
          organization_id: drillDownFilter.organizationId,
          start_date: drillDownFilter.startDate,
          end_date: drillDownFilter.endDate,
          date_range: drillDownFilter.dateRange,
          is_integrated: drillDownFilter.is_integrated,
          comparing_list: PLDateArrayHelper(
            drillDownFilter.compare_with,
            Number(drillDownFilter.compare_value),
            {
              start: drillDownFilter?.startDate,
              end: drillDownFilter?.endDate,
              date_range: drillDownFilter?.dateRange,
            }
          ),
          report_basis: drillDownFilter?.report_basis,
          is_descending: drillDownFilter?.is_descending,
          account_filter: drillDownFilter?.account_filter,
          tag_option_in: drillDownFilter?.tag_option_in,
        };
      }
    }
    setIsLoader(true);
    if (
      filterValues.organization_id !== "" &&
      filterValues.start_date !== "" &&
      filterValues.end_date !== "" &&
      filterValues.currency_id !== "" &&
      filterValues.date_range !== ""
    ) {
      const responseAction = await dispatch(
        getProfitAndLossReport({
          filterValues: filterValues,
          orgId: currentUserInfo.organization_id,
          temp: location.pathname === "/profit-and-loss-1" ? true : false,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setReportData(response);
          setIsLoader(false);
          setShowReport(true);
          setOrgName(response.organization_details.organization_name);
          setFilterDataForExport(filterValues);
        }
      }
    }
  };

  const exportFile = () => {
    downloadFile(
      filterDataForExport,
      "profit-and-loss",
      getFileName(
        orgName || "",
        "profit-and-loss",
        filterDataForExport.start_date,
        filterDataForExport.end_date
      )
    );
  };
  // const handleReportTypeChange = (reportType: any) => {
  //   reportRef.current.formValues.is_integrated = reportType;
  // };
  return (
    <div className="reports prof-los-report">
      <div
        className="card card-dashboard report-card balance-sheet-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Profit and Loss</h1>
              <CustomizeReportModal
                reportType="PL"
                handleReport={handleReport}
                handlePLReport={handleCustomizeReport}
              />
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={false}
                isAdvanced={true}
                defaultValue="quarter"
                reportType="PL"
                handleReport={handleReport}
                handleReportWithArgs={handleCustomizeReport}
                isReportWithArgs={true}
                clearReport={() => setShowReport(false)}
                filterdata={drillDownFilter}
                ref={reportRef}
                // habdleReportType={handleReportTypeChange}
                enable_toggleReportType={true}
              />
            </div>
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links align-items-center ">
                  {/* hidding the send email, download pdf and export to excel options from profit and loss header as per ticket: https://app.clickup.com/t/85zrmgcae */}

                  {/* <ReportHeaderActionLinks /> */}
                </div>
                <ReportsExportComponent
                  onExportPDF={() => {}}
                  onExportExcel={exportFile}
                />
                <ReportHeader
                  reportName="Profit and Loss"
                  reportData={reportData}
                />
                <div className="table-section report-table-section">
                  <InfoBox
                    message={
                      message(reportData.currency_code).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  <div className="report-table-outline w-100">
                    <ProfitAndLossReportTable
                      jsonData={reportData}
                      key={tableFilters.date_range}
                      filterValues={{
                        ...reportRef.current.filterValues,
                        comparing_list: PLDateArrayHelper(
                          PLFilterValues.compare_with,
                          Number(PLFilterValues.compare_value),
                          {
                            start: reportRef.current.filterValues?.start_date,
                            end: reportRef.current.filterValues?.end_date,
                            date_range:
                              reportRef.current.filterValues?.date_range,
                          }
                        ),
                        compare_with: PLFilterValues?.compare_with,
                        compare_value: PLFilterValues?.compare_value,
                        report_basis: PLFilterValues?.report_basis,
                        is_descending: PLFilterValues?.is_descending,
                        account_filter: PLFilterValues?.account_filter,
                        is_integrated:
                          reportRef.current.filterValues?.is_integrated,
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {isLoading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
