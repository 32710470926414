import React from "react";
import { Row } from "@tanstack/react-table";
import { ProcessedRow } from "../types";
import { Span } from "../../../../../common/components/htmlTags/Span";

interface TotalCellProps {
  row: Row<ProcessedRow>;
  formatNumber: (value: number) => string;
}

export const TotalCell: React.FC<TotalCellProps> = ({ row, formatNumber }) => {
  return row.original.transaction_exist || row.original.is_tag ? (
    <Span style={{ textAlign: "right" }}>
      {row.getIsExpanded()
        ? ""
        : formatNumber(Number(row.original.closing_balance))}
    </Span>
  ) : (
    <></>
  );
};
