import { FormikProps } from "formik";
import { Container } from "../../../../../../common/components/htmlTags/Container";
import { Section } from "../../../../../../common/components/htmlTags/Section";
import { SystemVoucherDate } from "./SystemVoucherDate";
import { SystemVoucherNumber } from "./SystemNumber";
import { SystemVoucherReferenceNumber } from "./SystemReferenceNumber";
import { SystemVoucherNotes } from "./SystemNotes";
import { SystemVoucherCurrency } from "./SystemCurrency";
import { SystemVoucherType } from "./SystemType";
import { SystemVoucherListInJV } from "./SystemVoucherListInJV";
import { useModuleCustomFieldList } from "../../../../../../hooks/useModuleCustomFieldList";
import { ObjectType } from "../../../../../../../types";
import {
  FormInputFieldsInRows,
  useParams,
} from "../../../../../paymentsReceived/components/paymentReceivedForm/imports";
import { SystemVoucherConfigurationType } from "./SystemVoucherType";
import { CustomFieldInTransactions } from "../../../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";

type Props = {
  formik: FormikProps<any>;
  customFieldRef: any;
};
export const SystemVoucherBasicDetails = (props: Props) => {
  const { editId } = useParams();
  const systemVoucherCustomFields = useModuleCustomFieldList("System Voucher");
  const getCustomFieldData = async (customFieldData: ObjectType) => {
    await props.formik.setFieldValue("custom_fields", customFieldData.values);
  };
  return (
    <Section className="form-top-section">
      <Container className="row custom-row">
        <SystemVoucherConfigurationType formik={props.formik} />
        <SystemVoucherDate formik={props.formik} />
      </Container>
      <Container className="row custom-row">
        <SystemVoucherNumber formik={props.formik} />
        <SystemVoucherReferenceNumber formik={props.formik} />
      </Container>
      <Container className="row custom-row ">
        <SystemVoucherCurrency formik={props.formik} />
        <SystemVoucherNotes formik={props.formik} />
      </Container>
      <Container className="row custom-row">
        <SystemVoucherType formik={props.formik} />
        <SystemVoucherListInJV formik={props.formik} />
      </Container>
      <Container className="mb-4">
        <CustomFieldInTransactions
          getFieldValues={getCustomFieldData}
          customFieldsArray={systemVoucherCustomFields}
          initialValues={props.formik.values.custom_fields}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
          wrapperClass={!props.formik.values.currency_id ? "disabled-item" : ""}
          isDisabled={!props.formik.values.currency_id}
        />
      </Container>
    </Section>
  );
};
