import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SuccessToaster } from "../../../../../../helpers/toastHelper";
import {
  ApplyTagsValues,
  AssociatedTagOptionsType,
  ObjectType,
  ReportingTagTransactionObjectPM,
} from "../../../../../../types";
import { setLoaderState } from "../../../../../common/commonSlice";
import { getReportingTagDropdown } from "../../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import useCommonData from "../../../../../hooks/useCommon";
import { useFetchJournalListPM } from "../../../hooks/useJournalListPM";
import { useFetchPMDetails } from "../../../hooks/usePMDetails";
import { useFetchTagOptionsForBills } from "../../../hooks/useTagOptionsForBills";
import { updateReportingTagsPM } from "../../../paymentsMadeSlice";
import { getPayloadForJournalGenerate } from "../../paymentMadeForm/reportingtags/utils/helper";
import messages from "../resources/messages";
import { initialValues } from "../resources/stateInitialization";
import { validation } from "../resources/validation";
import { formikTouchedFieldSetter } from "../../../../../../helpers/formikTouchedFieldSetter";

const useReportingTags = () => {
  const { editId, accountId, billId, entityId, entityType } = useParams();
  const uniqueKey = `${accountId}${entityId}${entityType}`;
  const [otherAccountsExistingTags, setOtherAccountsExistingTags] =
    useState<any>([]);
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const [journals, setJournals] = useState<ReportingTagTransactionObjectPM>({});
  const [tagOptionsForBills, setTagOptionsForBills] =
    useState<AssociatedTagOptionsType>({});

  const { getPMDetails } = useFetchPMDetails();
  const { getTagOptionsForBills } = useFetchTagOptionsForBills();
  const { getJournals } = useFetchJournalListPM();
  const [pmDetails, setpMDetails] = useState<ObjectType>({});
  const [otherBillTags, setOtherBillTags] = useState<ObjectType[]>([]);
  const [formValues, setFormValues] = useState<ApplyTagsValues>(initialValues);
  const [allTags, setAllTags] = useState<ObjectType[]>([]);
  const [currency, setCurrency] = useState({
    currency_id: null,
    currency_code: "",
  });
  useEffect(() => {
    dispatch(setLoaderState(true));
    onLoadFunctions().finally(() => {
      // getAssociatedReportingTags()

      setTimeout(() => {
        dispatch(setLoaderState(false));
      }, 2000);
    });
  }, [currentUserInfo.organization_id]);
  const getOtherbillReportingTag = (
    billIds: number[],
    pmJournals: ObjectType
  ) => {
    const result: ObjectType[] = [];

    billIds.forEach((id) => {
      // Skip the currentID
      if (Number(id) === Number(billId)) return;

      const entity = pmJournals[id.toString()];
      if (!entity) return;

      // Loop through transactions
      entity.transaction_list.forEach((transaction: any) => {
        // Only process transactions with reporting tags
        transaction.reporting_tag_list.forEach((tag: any) => {
          const processedTag: any = {
            account_id: transaction.account_id,
            entity_id: transaction.entity_id,
            entity_type: transaction.entity_type,
            transaction_amount: transaction.transaction_amount,
            tag_amount: tag.tag_amount,
            split_type: tag.split_type,
            reporting_tag_id: tag.reporting_tag_id,
            options_list: tag.option_list.map((option: any) => ({
              option_id: option.option_id,
              amount: option.amount,
            })),
          };

          result.push(processedTag);
        });
      });
    });
    setOtherBillTags(result);
    return result;
  };
  const onLoadFunctions = async () => {
    const response = await getPMDetails(Number(editId));
    if (!("error" in response)) {
      setCurrency({
        currency_id: response.currency_id,
        currency_code: response.currency_code,
      });
    }
    setpMDetails(response);
    let billsIds = Array.isArray(response.unpaid_bill_lines)
      ? response.unpaid_bill_lines.map((bills: ObjectType) => {
          return bills.bill_id;
        })
      : [];

    getTagOptionsForBills(billsIds, setTagOptionsForBills);

    let pmJournals: ObjectType = await getJournals(
      getPayloadForJournalGenerate(response),
      Number(editId),
      setJournals
    );
    let ll = getOtherbillReportingTag(billsIds, pmJournals);
    let newOtherAccountsExistingTags = pmJournals[
      Number(billId)
    ].transaction_list
    .filter(
      (singletransaction: any) => {
        const transKey = `${singletransaction.account_id}${singletransaction.entity_id}${singletransaction.entity_type}`;
        return uniqueKey !== transKey;
      }
     )
      .map((singletransaction: ObjectType) => {
        return {
          account_id: singletransaction.account_id,
          account_name: singletransaction.account_name,
          transaction_amount: singletransaction.transaction_amount,
          tag_amount: singletransaction.tag_amount,
          transaction_type: singletransaction.transaction_type,
          entity_id: singletransaction.entity_id,
          entity_type: singletransaction.entity_type,
          reporting_tag_list: singletransaction.reporting_tag_list
            ? singletransaction.reporting_tag_list
            : [],
        };
      });

    setOtherAccountsExistingTags(newOtherAccountsExistingTags);

    const transaction = pmJournals[Number(billId)].transaction_list.find(
      (singletransaction: ObjectType) => {
        return Number(singletransaction.account_id) === Number(accountId) &&
        Number(singletransaction.entity_id) === Number(entityId) &&
        String(singletransaction.entity_type) === String(entityType);
      }
    );
    setFormValues((prevValues: ApplyTagsValues) => {
      return {
        ...prevValues,
        account_name: transaction.account_name,
        transaction_amount: transaction.transaction_amount,
        tag_amount: transaction.tag_amount,
        transaction_type: transaction.transaction_type,
        entity_id: transaction.entity_id,
        entity_type: transaction.entity_type,
        reporting_tag_list: transaction.reporting_tag_list
          ? transaction.reporting_tag_list
          : [],
      };
    });

    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  };
  useEffect(() => {
    getReportingTagsList();
  }, [currentUserInfo.organization_id]);
  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      saveReportingTags();
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  const saveReportingTags = async () => {
    dispatch(setLoaderState(true));
    formik.setSubmitting(true)
    formikTouchedFieldSetter(formik.values, formik.setTouched);
    let reportingTagList = [] as any;
    formik.values.reporting_tag_list.map((reporting_tag) => {
      reportingTagList.push({
        account_id: accountId,
        entity_id: formik.values.entity_id,
        entity_type: formik.values.entity_type,
        transaction_amount: formik.values.transaction_amount,
        tag_amount: reporting_tag.tag_amount,
        split_type: reporting_tag.split_type,
        reporting_tag_id: reporting_tag.reporting_tag_id,
        options_list: reporting_tag.option_list.map((option) => {
          return {
            option_id: option.option_id,
            amount: option.amount,
          };
        }),
      });
    });
    let am = 0;
    otherAccountsExistingTags.map((otherAccount: any) => {
      otherAccount.reporting_tag_list.map((reporting_tag: any) => {
        reporting_tag.option_list.map((option: any) => {
          am = am + Number(option.amount);
          return {
            option_id: option.option_id,
            amount: option.amount,
          };
        });
      });
    });
    // set existing reporting for other accounts

    otherAccountsExistingTags.map((otherAccount: any) => {
      otherAccount.reporting_tag_list.map((reporting_tag: any) => {
        reportingTagList.push({
          account_id: otherAccount.account_id,
          entity_id: otherAccount.entity_id,
          entity_type: otherAccount.entity_type,
          transaction_amount: otherAccount.transaction_amount,
          tag_amount: reporting_tag.tag_amount || am,
          split_type: reporting_tag.split_type,
          reporting_tag_id: reporting_tag.reporting_tag_id,
          options_list: reporting_tag.option_list.map((option: any) => {
            am = am + Number(option.amount);
            return {
              option_id: option.option_id,
              amount: option.amount,
            };
          }),
        });
      });
    });
    const responseAction = await dispatch(
      updateReportingTagsPM({
        values: {
          reporting_tag_list: [...reportingTagList, ...otherBillTags].filter(
            (tag) => tag.options_list.length
          ),
        },
        pmId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    SuccessToaster(messages.success.reportingTagSave, "bill-reporting-tag");
    dispatch(setLoaderState(false));
    formik.setSubmitting(false)
    navigate(-1);
  };

  const getReportingTagsList = async () => {
    const responseAction = await dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setAllTags(responseAction.payload);
    }
  };

  // const getAssociatedReportingTags = async () => {
  //   const responseAction = await dispatch(
  //     generateJournalListpm({
  //       pmId: Number(editId),
  //       values: getPayloadForJournalGenerate(
  //         pmDetails as PaymentReceivedJournalListPayload
  //       ) as PaymentReceivedJournalListPayload,
  //       orgId: currentUserInfo.organization_id,
  //     })
  //   );
  //   const response = responseAction.payload;
  //  };
  let currencyCode = currency.currency_code;
  return { formik, allTags, tagOptionsForBills, currencyCode };
};

export default useReportingTags;
