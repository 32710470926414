import { useParams } from "react-router-dom";
import { FormValues } from "../../../../../../adapters/formAdapter/type";
import { ErrorToaster } from "../../../../../../helpers/toastHelper";
import { InventoryItemValues, ObjectType } from "../../../../../../types";
import {
  EXCEPTION_TYPES,
  GST_STATE_TYPES,
  gstRegistrationTypes,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../../../constants/constants";
import { useInvoiceAmountCalculation } from "../../../../../home/invoice/components/createInvoice/hooks/useInvoiceAmountCalculation";
import { initialItemValues } from "../../../../../home/invoice/components/createInvoice/resources/stateinitialization";
import useCommonData from "../../../../../hooks/useCommon";
import { handleAmountRoundOff } from "../../../../../utils/handleAmountRoundOff";
import {
  CUSTOMER_NON_TAXABLE,
  EXEMPTION_TYPES,
} from "../../gstSelect/constants";
import { useGstSelect } from "../../gstSelect/GstSelectProvider";
import { isTDSLineLevelExist } from "../utils/checkLineLevelTDS";
import {
  getDefaltGstItems,
  getDefaultNonTaxableGst,
  getDefaultSezGst_forSales,
  getDeletedItemGst,
  getGstTaxItem,
} from "../utils/taxUtils";
import messages from "../../../../../home/invoice/components/createInvoice/resources/messages";
import { deferredIncomeChecker } from "../../../../../home/invoice/components/createInvoice/utils/deferredIncomeChecker";

type Props = {
  values: FormValues;
  setValues: (values: any) => void;
  module?: string;
};

const useItemForSales = ({ values, setValues, module }: Props) => {
  const { gstTaxList, exemptionDropdownList, defaultPreference } =
    useGstSelect();
  const { orgCurrencyList } = useCommonData();
  const { calculateInvoiceTotalAmount, abortControllerRef } =
    useInvoiceAmountCalculation({ values, setValues });
  const { editId } = useParams();
  const handleItemDropdownChange = async (
    item: InventoryItemValues,
    index: number
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    let gstTaxOfItems = [] as any;
    let taxObj = null;
    if (
      item.tax_preference === EXCEPTION_TYPES.NON_TAXABLE ||
      item.tax_preference === EXCEPTION_TYPES.OUT_OF_SCOPE ||
      item.tax_preference === EXCEPTION_TYPES.NON_GST_SUPPLY
    ) {
      const taxPreference = EXEMPTION_TYPES.find(
        (type) => type.value === item.tax_preference
      )?.key;
      const gst_tax = gstTaxList.filter((gstTax: any) => {
        return (
          gstTax.name === taxPreference &&
          gstTax.tax_type === "NONE" &&
          gstTax.rate === 0
        );
      });
      if (
        values.customer_details.customer_exemption_type !==
          EXCEPTION_TYPES.NON_TAXABLE &&
        values.customer_details.gst_registration_type !==
          gstRegistrationTypes.sez &&
        values.customer_details.gst_registration_type !==
          gstRegistrationTypes.sezDeveloper &&
        values.customer_details.gst_registration_type !==
          gstRegistrationTypes.overseas
      ) {
        taxObj = await getGstTaxItem(gst_tax[0]);
      }
    }

    let items = values.items;
    let taxArray: any[] = [];
    if (item.taxes) {
      item.taxes.map((tax) => {
        taxArray.push({
          tax_id: tax.tax_id,
          tax_percentage: tax.tax_percentage,
        });
      });
    }
    gstTaxOfItems[index] = {
      intra_state_id: item?.intra_state_id ? item.intra_state_id : null,
      inter_state_id: item?.inter_state_id ? item.inter_state_id : null,
      is_intra_state_group: item?.is_intra_state_group
        ? item.is_intra_state_group
        : false,
      is_inter_state_group: item?.is_inter_state_group
        ? item.is_inter_state_group
        : false,
    };
    const defaultExemptionReasonId = exemptionDropdownList.find(
      (reason) => reason.exemption_reason === CUSTOMER_NON_TAXABLE
    )?.id;
    const itemExemptionType =
      values.customer_details.customer_exemption_type ===
      EXCEPTION_TYPES.NON_TAXABLE
        ? EXCEPTION_TYPES.NON_TAXABLE
        : item.tax_preference;
    const itemExemptionReasonId =
      values.customer_details.customer_exemption_type ===
      EXCEPTION_TYPES.NON_TAXABLE
        ? defaultExemptionReasonId
        : item.exemption_reason_id;
    items[index] = {
      ...items[index],
      item_name: item.name,
      hsn_or_sac_code: item.hsn_or_sac_code,
      account_id: item.account_id,
      account_name: item.account_name ? item.account_name : "",
      account_type: item?.account_type ? item.account_type : "",
      rate: Number(item.rate),
      taxes: taxArray,
      inventory_item_id: item.id,
      gst_tax: taxObj ? { ...taxObj } : null,
      item_exemption_type: itemExemptionType,
      item_exemption_id: itemExemptionReasonId,
      type: item.type,
      gstTaxOfItems: gstTaxOfItems[index],
      is_deferred_item:
        String(item?.account_type).split("/")[0] === "Liabilities" && module === "Invoice"
          ? //  &&
            // String(items[index].account_type).split("/")[0] === "Liabilities"
            true
          : false,
    };
    const value = {
      ...values,
      items: items,
    };
    if (values.state_type) {
      items = getDefaltGstItems(value, gstTaxList, gstRegistrationTypes, index);
    }
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
      is_item_modified: values.deferred_conf ? true : false,
      is_deferred: module === "Invoice" ? deferredIncomeChecker(items) : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };

  const handleItemGstChange = (
    tax: any,
    itemIndex: number,
    itemExemption: any
  ) => {
    // Updating deleteGstItems starts
    const items = values.items;
    const item = items[itemIndex];
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    // if isSez customer, then allow only rate zero tax.
    if (
      (values.customer_details.gst_registration_type ===
        gstRegistrationTypes.sez ||
        values.customer_details.gst_registration_type ===
          gstRegistrationTypes.sezDeveloper) &&
      (tax?.rate !== 0 ||
        (tax?.is_tax_preference &&
          values.customer_details.customer_exemption_type !==
            EXCEPTION_TYPES.NON_TAXABLE))
    ) {
      ErrorToaster(messages.errors.onlyIGSTForSez);
      return;
    } else if (
      values.customer_details.gst_registration_type ===
        gstRegistrationTypes.overseas &&
      (tax?.rate !== 0 || tax?.is_tax_preference)
    ) {
      ErrorToaster(messages.errors.onlyZeroTaxForOverseas);
      return;
    }

    let newdeleteItemGst
    if (item.gst_tax && (item.id || item.gst_tax_item)) {
      newdeleteItemGst = getDeletedItemGst(values.deleteItemGst, item);
    }
    // Updating itemFormValues with new tax values start
    const taxObj = getGstTaxItem(tax);
    items[itemIndex] = {
      ...items[itemIndex],
      gst_tax: taxObj ? taxObj : null,
      item_exemption_type: itemExemption
        ? itemExemption.item_exemption_type
        : values.item[itemIndex].item_exemption_type,
      item_exemption_id: itemExemption
        ? itemExemption.item_exemption_id
        : values.item[itemIndex].item_exemption_id,
    };
    setValues({
      items: items,
      deleteItemGst: newdeleteItemGst,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
      },
    });
  };

  const addItemContainer = () => {
    const itemObject = initialItemValues;
    const exemption = {
      item_exemption_type: values.customer_details.customer_exemption_type,
      item_exemption_id: "",
    };
    if (
      values.customer_details.customer_exemption_type ===
      EXCEPTION_TYPES.NON_TAXABLE
    ) {
      let default_gst = getDefaultNonTaxableGst(gstTaxList);
      const taxObj = getGstTaxItem(default_gst[0]);
      itemObject.gst_tax = { ...taxObj } as any;
      itemObject.item_exemption_type = exemption.item_exemption_type;
      itemObject.item_exemption_id = exemption.item_exemption_id;
    } else if (
      values.customer_details.gst_registration_type ===
        gstRegistrationTypes.sez ||
      values.customer_details.gst_registration_type ===
        gstRegistrationTypes.sezDeveloper ||
      values.customer_details.gst_registration_type ===
        gstRegistrationTypes.overseas
    ) {
      let default_gst = getDefaultSezGst_forSales(gstTaxList) as any;
      const taxObj = getGstTaxItem(default_gst[0]);
      itemObject.gst_tax = { ...taxObj } as any;
    } else {
      if (
        values.state_type === GST_STATE_TYPES.INTER_STATE &&
        values.settings.default_inter_state_id
      ) {
        let defaultTax = gstTaxList.filter(
          (tax) => tax.id === values.settings.default_inter_state_id
        );
        if (defaultTax.length > 0) {
          const taxObj = getGstTaxItem(defaultTax[0]);
          itemObject.gst_tax = { ...taxObj } as any;
        }
      }
      if (
        values.state_type === GST_STATE_TYPES.INTRA_STATE &&
        values.settings.default_intra_state_id
      ) {
        let defaultTax = gstTaxList.filter(
          (tax) => tax.id === values.settings.default_intra_state_id
        );
        if (defaultTax.length > 0) {
          const taxObj = getGstTaxItem(defaultTax[0]);
          itemObject.gst_tax = { ...taxObj } as any;
        }
      }
    }
    // sales or purchase default update
    let salesDefault = values.customer_details.sales_default;
    if (salesDefault) {
      itemObject.discount_type = salesDefault.discount_type
        ? salesDefault.discount_type
        : "Absolute";
      itemObject.discount = salesDefault.discount || 0;
      itemObject.discount_account_id = salesDefault.discount_account_id;
      itemObject.discount_account_name = salesDefault.discount_account_name;
      itemObject.account_id = salesDefault.sales_account_id;
      itemObject.account_name = salesDefault.sales_account_name;
    }
    let tempItem = values.items || [];
    setValues({
      items: [...tempItem, itemObject],
      is_item_modified: values.deferred_conf ? true : false,
    });
  };

  const handleItemChange = (e: any, index: number) => {
    let { name, value } = e.target;
    const items = values.items;
    const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (name === "quantity" || name === "rate" || name === "discount") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || numberRegex.test(value)) {
          items[index] = { ...items[index], [name]: value };
          setValues({
            items: items,
            total: 0,
            is_user_edited_total: false,
          });
        }
      }
      if (name === "rate") {
        items[index] = { ...items[index], ["is_user_edited_rate"]: true };
      }
    } else {
      items[index] = { ...items[index], [name]: value };
      setValues({
        items: items,
        total: 0,
        is_user_edited_total: false,
        is_item_modified: values.deferred_conf ? true : false,
      });
    }
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
    // setIsItemEdited(true);
  };

  const handleDiscountAccountChange = (
    accountId: number | string,
    accountName: string,
    index: number,
    accountType: string
  ) => {
    let items = values.items;

    items[index] = {
      ...items[index],
      ["discount_account_id"]: accountId,
      ["discount_account_name"]: accountName,
      ["discount_account_type"]: accountType,
      ["is_deferred_item"]:
        String(accountType).split("/")[0] === "Liabilities"
          ? //  &&
            // String(items[index].account_type).split("/")[0] === "Liabilities"
            true
          : false,
    };

    setValues({
      items: items,
      is_deferred: module === "Invoice" ? deferredIncomeChecker(items) : false,
      is_item_modified: values.deferred_conf ? true : false,
    });
  };

  const handleDiscountValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    let items = values.items;
    items[index] = { ...items[index], ["discount"]: e.target.value };
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };
  const handleDiscountTypeChange = (value: string, index: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    let items = values.items;
    items[index] = { ...items[index], ["discount_type"]: value };
    setValues({
      items: items,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
      },
    });
  };
  const handleHSNChange = (value: string, index: number) => {
    const items = values.items;
    items[index] = { ...items[index], ["hsn_or_sac_code"]: value };
    setValues({
      items: items,
      is_item_modified: values.deferred_conf ? true : false,
    });
  };
  const handleTDSChange = (
    id: number,
    rate: number,
    name: string,
    is_percentage: boolean,
    index: number
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    let is_tcs_enabled = true;
    const items = values.items;
    items[index] = {
      ...items[index],
      ["tds_id"]: id,
      ["tds_account_id"]: id,
      ["tds_percentage"]: is_percentage ? rate : 0,
      ["default_tds_percentage"]: is_percentage ? rate : 0,
      ["tds_amount"]: !is_percentage ? rate : 0,
      ["tds_name"]: name,
      ["is_tds_percentage"]: is_percentage,
    };
    if (items?.length) {
      is_tcs_enabled =
        Array.isArray(items) &&
        items.some(
          (value) => value.tds_id && (value.tds_percentage || value.tds_amount)
        );
    }
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
      is_tcs_enabled: !is_tcs_enabled,
      is_item_modified: values.deferred_conf ? true : false,
      tds_level: tdsApplyLevel.LINE_ITEM_LEVEL,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: null,
      },
    });
  };
  const handleTDSAmountChange = (name: string, e: any, index: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const items = values.items;
    items[index] = { ...items[index], [name]: e.target.value };
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };
  const clearTDS = (index: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const items = values.items;
    items[index] = {
      ...items[index],
      ["tds_id"]: null,
      ["tds_account_id"]: null,
      ["tds_percentage"]: "",
      ["default_tds_percentage"]: 0,
      ["tds_name"]: "",
      ["tds_amount"]: "",
    };
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
      is_tcs_enabled: !isTDSLineLevelExist(items),
      source_tax_type: !isTDSLineLevelExist(items)
        ? sourceTaxTypes.TCS
        : sourceTaxTypes.TDS,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        is_user_edited_total: false,
        total: 0,
      },
    });
  };
  const handleItemAccountChange = (
    accountId: number | null,
    accountName: string,
    index: number,
    accountType: string
  ) => {
    const items = values.items;

    items[index] = {
      ...items[index],
      ["account_id"]: accountId,
      ["account_name"]: accountName,
      ["account_type"]: accountType,
      ["is_deferred_item"]:
        String(accountType).split("/")[0] === "Liabilities"
          ? //  &&
            // String(items[index].discount_account_type).split("/")[0] === "Liabilities"
            true
          : false,
    };
    setValues({
      items: items,
      is_deferred: module === "Invoice" ? deferredIncomeChecker(items) : false,
      is_item_modified: values.deferred_conf ? true : false,
    });
  };
  const handleDiscountOnBlur = (amount: number, index: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const items = values.items;
    items[index] = { ...items[index], ["discount"]: amount };
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };
  const handleTDSOnBlur = (
    name: string,
    amount: number | string,
    index: number,
    currencyCode: string
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const items = values.items;
    const roundedAmount = handleAmountRoundOff(
      amount,
      currencyCode,
      orgCurrencyList
    );
    items[index] = { ...items[index], [name]: roundedAmount };
    setValues({
      items: items,
      total: 0,
      is_user_edited_total: false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };
  const deleteItemContainer = async (index: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    let deletedItemIds = values.deletedItemIds;
    if (editId) {
      deletedItemIds.push({ itemId: Number(values.items[index]?.id) });
    }
    const items = values.items;
    items.splice(index, 1);

    setValues({
      items: [...items],
      deletedItemIds: deletedItemIds,
      total: 0,
      is_user_edited_total: false,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: JSON.parse(JSON.stringify(items)),
        total: 0,
      },
    });
  };
  const addItemTaxContainer = (itemIndex: number) => {
    const taxObject = {
      tax_id: "",
      tax_percentage: "",
    };

    let items = values.items;
    items[itemIndex].taxes.push(taxObject);
    setValues({
      items: items,
      is_item_modified: values.deferred_conf ? true : false,
    });
  };

  /**
   * Append item tax container
   */
  const deleteItemTaxContainer = (
    itemIndex: number,
    taxIndex: number,
    id: number | undefined
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    let items = values.items;
    let deletedItemTaxIds = values.deletedItemTaxIds;

    items.map((item: any, index: number) => {
      if (index === itemIndex) {
        item.taxes.map((tax: ObjectType, taxIndex: number) => {
          if (id && tax.id === id) {
            deletedItemTaxIds.push({
              itemId: Number(item.id),
              taxItemId: Number(tax.id),
            });
          }
        });
      }
    });

    items[itemIndex].taxes.splice(taxIndex, 1);
    setValues({
      items,
      deletedItemTaxIds,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };

  const handleItemTaxChange = (e: any, itemIndex: number, taxIndex: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const { name, value } = e.target;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "tax_percentage" && value !== "") {
      let decimalPointSplitArray: string[] = [];
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (!numberRegex.test(value) || decimalPointSplitArray[0].length > 14) {
        return;
      }
    }
    const items = values.items;
    items[itemIndex].taxes[taxIndex] = {
      ...items[itemIndex].taxes[taxIndex],
      [name]: value,
    };

    setValues({
      items: items,
      is_item_modified: values.deferred_conf ? true : false,
    });
    calculateInvoiceTotalAmount({
      values: {
        ...values,
        items: items,
        total: 0,
      },
    });
  };

  return {
    addItemContainer,
    handleItemDropdownChange,
    handleItemGstChange,
    handleItemChange,
    handleDiscountAccountChange,
    handleDiscountTypeChange,
    handleDiscountValueChange,
    handleHSNChange,
    handleTDSAmountChange,
    handleTDSChange,
    handleDiscountOnBlur,
    handleTDSOnBlur,
    handleItemAccountChange,
    clearTDS,
    deleteItemContainer,
    addItemTaxContainer,
    deleteItemTaxContainer,
    handleItemTaxChange,
  };
};

export default useItemForSales;
