import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewItem,
  deleteItem,
  deleteItemTax,
  editItem,
  getItemDropdown,
  getItemList,
  getExemptionDropdown,
  getExemptionLabel,
  itemsStatusChange,
  checkItemNameExists,
  getItemDetails,
} from "./itemAPI";
import { InventoryItemValues, ObjectType } from "../../../types";
import { RootState } from "../../../app/store";

export const itemList = createAsyncThunk(
  "itemList",
  async (data: {
    page: number;
    itemsPerPage: number;
    nameSortOrder: string;
    orgId: number;
    orgIds: number[];
    status: string[];
    itemType: string[];
    transactionCategory: string[];
  }) => {
    return await getItemList(data);
  }
);

export const createItem = createAsyncThunk(
  "create/item",
  async (data: { values: InventoryItemValues; orgId: number }) => {
    return await createNewItem(data);
  }
);
export const updateItem = createAsyncThunk(
  "item/update",
  async (data: { id: number; values: InventoryItemValues; orgId: number }) => {
    return await editItem(data);
  }
);
export const removeItem = createAsyncThunk(
  "item/delete",
  async (data: { id: number; orgId: number }) => {
    return await deleteItem(data);
  }
);
export const removeItemTax = createAsyncThunk(
  "item/tax/delete",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteItemTax(data);
  }
);
export const itemStatusChange = createAsyncThunk(
  "item/activate/deactivate",
  async (data: { id: number; orgId: number }) => {
    return await itemsStatusChange(data);
  }
);

export const itemDropdown = createAsyncThunk(
  "item/dropdown",
  async (data: {
    currencyId: number;
    conversionId: number | null;
    orgId: number;
    transaction_type?: string;
    for_subscription: boolean;
  }) => {
    return await getItemDropdown(data);
  }
);
export const exemptionDropdown = createAsyncThunk(
  "exemption/dropdown",
  async (data: { orgId: number }) => {
    return await getExemptionDropdown(data);
  }
);
export const exemptionLabel = createAsyncThunk(
  "exemption/dropdown/label",
  async (data: { exemption_id: number; orgId: number }) => {
    return await getExemptionLabel(data);
  }
);
export const getItemDetail = createAsyncThunk(
  "item/get-item-details",
  async (data: { item_id: number | string; orgId: number }) => {
    return await getItemDetails(data);
  }
);
export const itemNameExistsCheck = createAsyncThunk(
  "item/check-name-exists",
  async (data: { item_name: string; orgId: number; signal: AbortSignal }) => {
    return await checkItemNameExists(data);
  }
);
interface ItemState {
  itemsDropdown: InventoryItemValues[];
  itemsDropdownRawList: InventoryItemValues[];
  exemptionList: { id: number; exemption_reason: string }[];
  showItemCreate: boolean;
  showItemEdit: boolean;
  userEditedItemsList: ObjectType;
  userEditedItemIndices: number[];
}
const initialState: ItemState = {
  itemsDropdown: [],
  itemsDropdownRawList: [],
  exemptionList: [],
  showItemCreate: false,
  showItemEdit: false,
  userEditedItemsList: {},
  userEditedItemIndices: [],
};

export const itemSlice = createSlice({
  name: "itemManagement",
  initialState,
  reducers: {
    setShowItemCreate: (state, action) => {
      state.showItemCreate = action.payload;
    },
    setItemsList: (state, action) => {
      state.itemsDropdown = action.payload;
    },
    setUserEditedItemsList: (state, action) => {
      state.userEditedItemsList = action.payload;
    },
    setUserEditedItemIndicesList: (state, action) => {
      state.userEditedItemIndices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(itemDropdown.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.itemsDropdown = payload;
        state.itemsDropdownRawList = payload;
      } else {
        state.itemsDropdown = [];
        state.itemsDropdownRawList = [];
      }
    });
    builder.addCase(exemptionDropdown.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.exemptionList = payload;
      } else {
        state.exemptionList = [];
      }
    });
  },
});

export const {
  setShowItemCreate,
  setItemsList,
  setUserEditedItemsList,
  setUserEditedItemIndicesList,
} = itemSlice.actions;

export default itemSlice.reducer;

export const itemDropdownSelector = (state: RootState) =>
  state.items.itemsDropdown;
export const itemDropdownRawListSelector = (state: RootState) =>
  state.items.itemsDropdownRawList;
export const exemptionDropdownSelector = (state: RootState) =>
  state.items.exemptionList;
export const showItemCreateSelector = (state: RootState) =>
  state.items.showItemCreate;
export const getUserEditedItemsList = (state: RootState) =>
  state.items.userEditedItemsList;
export const getUserEditedItemIndices = (state: RootState) =>
  state.items.userEditedItemIndices;
