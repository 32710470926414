import { useState } from "react";
import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { colors } from "../../../../constants/colors";
import "./IntegeratedReportDropDown.css";

type Props = {
  name: string;
  handleChange: (e: any) => void;
  isDisabled?: boolean;
  value: {
    label: string;
    value: string;
  };
};
interface OptionType {
  label: string;
  value: string;
  description: string;
}

function IntegratedReportSelect(props: Props) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const reportTypeFormattedOptions: OptionType[] = [
    {
      label: "Integrated",
      value: "integrated",
      description:
        "Integrated report of the selected organization and sub organizations associated with it",
    },
    {
      label: "Individual",
      value: "individual",
      description: "Individual report of the selected organization only",
    },
  ];
  const LabelWithRoot = ({ data }: any) => {
    return (
      <div
        onClick={() => {
          props.handleChange({ value: data.value, label: data.label });
          setMenuIsOpen(false);
        }}
        className="custom-select-option-wrapper"
        style={{
          backgroundColor:
            data.value === props.value.value ? colors.whisper : "white",
        }}
      >
        <span className="custom-select-option-account-name">{data.label}</span>
        {data.description && (
          <div className="custom-select-option-account-root">
            {data.description}
          </div>
        )}
      </div>
    );
  };
  return (
    <Select
      name="is_integerated"
      isDisabled={props.isDisabled}
      value={props.value}
      options={reportTypeFormattedOptions}
      placeholder="Choose"
      onChange={props.handleChange}
      className={``}
      autoFocus={false}
      styles={customSelectStyle}
      components={{ Option: LabelWithRoot }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      classNamePrefix="react-select"
    />
  );
}

export default IntegratedReportSelect;
