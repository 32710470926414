import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";

/**
 * API call for getting actuals list in Add Budget Screen
 */
export async function getActuals(data: {
  start_date: string;
  actuals: number;
  orgId: number;
}) {
  const result = await get(
    `${Api.budgetBase}get-actuals?start_date=${data.start_date}&actuals=${data.actuals}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to check budget name exists
 */
export async function budgetNameExists(data: {
  budgetId?: string | number;
  budgetName: string;
  orgId: number;
  signal?: AbortSignal;
}) {
  let url =
    Api.budgetBase +
    Api.budget +
    `/check-name-exist?budget_name=${data.budgetName}&current_organization=${data.orgId}`;
  if (data.budgetId) {
    url += `&budget_id=${data.budgetId}`;
  }
  const result = await get(url, data.signal);
  return result;
}

/**
 * Api to calculate budget formula
 */
export async function calculateFormula(data: {
  option: string;
  amount: number;
  month: string;
  numberOfMonths: number;
  firstAmount: number;
  orgId: number;
  signal?: AbortSignal;
}) {
  const result = await get(
    `${Api.budgetBase}${Api.budget}/get-formulated-values?option=${data.option}&amount=${data.amount}&month=${data.month}&number_of_months=${data.numberOfMonths}&first_amount=${data.firstAmount}&current_organization=${data.orgId}`,
    data.signal
  );
  return result;
}

/**
 * API call for getting all budgets.
 */
export async function getBudgetList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  let url =
    Api.budgetBase +
    Api.budget +
    "/list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}
    `.trim();
  const result = await get(url);
  return result;
}

/**
 * API call for Creating budgets.
 */
export async function calculateBudgetAPI(data: {
  orgId: number;
  signal?: AbortSignal;
  start_date: string;
  budget_entries: {
    account_id: number;
    monthly_values: { month: string; amount: number }[];
  }[];
}) {
  const { orgId, signal, ...rest } = data;
  const result = await post(
    `${Api.budgetBase}${Api.budget}/calculate-budget-totals?current_organization=${data.orgId}`,
    { ...rest },
    signal
  );
  return result;
}

/**
 * API call for creating budget.
 */
export async function createBudgetAPI(data: {
  orgId: number;
  name: string;
  start_date: string;
  actuals: number;
  period: number;
  budget_entries: {
    account_id: number;
    monthly_values: { month: string; amount: number }[];
  }[];
}) {
  const { orgId, ...rest } = data;
  const result = await post(
    `${Api.budgetBase}${Api.budget}/create?current_organization=${data.orgId}`,
    { ...rest }
  );
  return result;
}

/**
 * API call for editing budget.
 */
export async function editBudgetAPI(data: {
  orgId: number;
  budget_id: string | undefined;
  name: string;
  start_date: string;
  actuals: number;
  period: number;
  budget_entries: {
    account_id: number;
    monthly_values: { month: string; amount: number }[];
  }[];
}) {
  const { orgId, ...rest } = data;
  const result = await put(
    `${Api.budgetBase}${Api.budget}/edit/${data.budget_id}?current_organization=${data.orgId}`,
    { ...rest }
  );
  return result;
}

/**
 * API call for getting budget detail.
 */
export async function getBudgetDetail(data: {
  budgetId: number;
  orgId: number;
}) {
  const result = await get(
    Api.budgetBase +
      Api.budget +
      `/details/${data.budgetId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to edit budget
 */
export async function editBudgetDetail(data: {
  budget_id: number;
  orgId: number;
}) {
  const result = await put(
    `${Api.budgetBase}${Api.budget}/edit/budget_id=${data.budget_id}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting budget
 */
export async function deleteBudgetAPI(data: {
  deleteBudgetId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.budgetBase +
      Api.budget +
      "/delete" +
      `/${data.deleteBudgetId}?current_organization=${data.orgId}`
  );
  return result;
}
