import { FormikProps } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../../app/hooks";
import { getFormatedDate } from "../../../../../../../helpers/helper";
import { CurrencyList } from "../../../../../../../types";
import { currentUserSelector } from "../../../../../../common/commonSlice";
import CurrencySelect from "../../../../../../common/components/CurrencySelect";
import MultiExchangeRate from "../../../../../../common/components/exchangeRate/multipleExchangeRate/MutipleExchangeRate";
import { useJVExchange } from "../../../hooks/useJVExchange";

type Props = {
  formik: FormikProps<any>;
};
export const SystemVoucherCurrency = (props: Props) => {
  const {
    userExchangeRateSubmitHandler,
    currencyChangeHandler,
    fetchCustomConversions,
  } = useJVExchange();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const setExchangeData = (values: CurrencyList) => {};
  useEffect(() => {
    if (editId) {
      fetchCustomConversions(
        props.formik.values.conversion_id,
        setExchangeData,
        props.formik,
        "jv_currency"
      );
    }
  }, [editId, props.formik.values.conversion_id]);
  return (
    <>
      <div className="col-12 col-lg-4 ml-0 mw-325 journal_currency">
        <CurrencySelect
          label="Currency"
          currencyId={props.formik.values.currency_id}
          onChange={async (data) => {
            props.formik.setFieldValue("currency_id", data.value);
            props.formik.setFieldValue("currency_code", data.code || "");
            currencyChangeHandler(
              data.value,
              currentUserInfo.organization_currency,
              setExchangeData,
              props.formik,
              "jv_currency",
              0
            );
          }}
          isDisabled={false}
          error={
            (props.formik.errors.currency_id &&
              (props.formik.touched.currency_id ||
                props.formik.submitCount > 0) &&
              String(props.formik.errors.currency_id)) ||
            ""
          }
        />
        {props.formik.values.currency_id ? (
          <MultiExchangeRate
            currencyId={Number(props.formik.values.currency_id)}
            currencyCode={props.formik.values.currency_code}
            editId={editId}
            conversionId={props.formik.values.conversion_id}
            conversionCurrencyId={Number(currentUserInfo.organization_currency)}
            conversionDate={props.formik.values.date || getFormatedDate()}
            newConversionDate={props.formik.values.date || getFormatedDate()}
            isCurrencyChanged={true}
            isDateChanged={true}
            conversionRate={String(props.formik.values.conversion_rate)}
            conversionTimestamp={String(
              props.formik.values.conversion_timestamp
            )}
            toCurrencyCode={currentUserInfo.currency_code}
            toCurrencyId={Number(currentUserInfo.organization_currency)}
            setExchangeData={setExchangeData}
            position="end"
            isReverseConversionRequired={false}
            exchangeRateSubmitHandler={(data) => {
              userExchangeRateSubmitHandler(
                { ...data, by_user: true },
                setExchangeData,
                props.formik,
                "jv_currency"
              );
            }}
            byUser={props.formik.values.by_user}
          />
        ) : null}
      </div>
    </>
  );
};
