import { error } from "console";
import { PlanPriceDefaultValue, ObjectType } from "../../../../../types";
import { ValidtrialCountLimitType } from "../../planType";

const hasErrors = (array: any[]) => {
  return array.some((obj) =>
    obj.price.some(
      (priceObj: { fromErrors: string; price_per_stepErrors: string }) =>
        priceObj.fromErrors !== "" || priceObj.price_per_stepErrors !== ""
    )
  );
};

const hasTrialErrors = (trialErrorArray: any[]) => {
  return trialErrorArray.some((obj) =>
    Object.values(obj).some((value) => value !== "")
  );
};

// const hasTrialErrors = (trialErrorArray: any[]) => {

//   return trialErrorArray
//     .map(
//       (obj) => obj.range_to !== "" || obj.discounted_percentage_per_step !== ""
//     )
//     .every(Boolean);
// };

const ValidtrialCountLimit: ValidtrialCountLimitType = {
  DAYS: 5475,
  MONTHS: 180,
};

export const validationTrailCountMsg = (
  trial_count: number | undefined,
  trial_frequency: string
) => {
  if (
    Number(trial_count) >
    ValidtrialCountLimit[trial_frequency === "DAYS" ? "DAYS" : "MONTHS"]
  ) {
    return `Trial period cannot be more than ${
      ValidtrialCountLimit[trial_frequency === "DAYS" ? "DAYS" : "MONTHS"]
    } ${trial_frequency.toLowerCase()}`;
  }
  return "";
};
const hasInvalidPrice = (array: any[]) => {
  let returnValue = false;
  array.forEach((obj) => {
    obj.price.forEach(
      (priceObj: {
        price: any;
        hasOwnProperty: (arg0: string) => any;
        from: string;
      }) => {
        if (
          isNaN(priceObj.price) ||
          priceObj.price < 0 ||
          priceObj.price === ""
        ) {
          if (!priceObj.hasOwnProperty("fromErrors")) {
            returnValue = true;
            priceObj.from = "Please enter a price";
          }
        } else {
          priceObj.from = "";
        }
      }
    );
  });
  return returnValue;
};

function isPositiveInteger(value: any) {
  typeof value === "number" ? (value = String(value)) : (value = value);
  let arr = value.split(".");
  if (arr[1]) {
    return true;
  }
}

function isPositiveIntegerCheck(str: any) {
  const num = Number(str);
  return Number.isInteger(num) && num > 0;
}

export const validate = (
  planFormValues: PlanPriceDefaultValue,
  planItemListData: any,
  isInternalNameExist: boolean
) => {
  const errors: ObjectType = {};
  if (isInternalNameExist) {
    errors.internal_name =
      "Internal Name already exists. Please choose a different one";
  }
  if (!planFormValues.internal_name) {
    errors.internal_name = "Please enter Internal name";
  }
  if (planFormValues.items.length <= 0) {
    errors.items = "* Please add at least one item";
  }

  if (planFormValues.trial) {
    if (!planFormValues.trial_count) {
      errors.trial_count = "Please enter a valid value";
    } else if (
      planFormValues.trial_count &&
      validationTrailCountMsg(
        planFormValues.trial_count,
        planFormValues.trial_frequency
      ) !== ""
    ) {
      errors.trial_count = validationTrailCountMsg(
        planFormValues.trial_count,
        planFormValues.trial_frequency
      );
    }
  }

  if (planFormValues.invoicing_cycle === "FIXED") {
    if (
      planFormValues.invoicing_cycle_fixed_count &&
      planFormValues.invoicing_cycle_fixed_count < 1
    ) {
      errors.invoicing_cycle_fixed_count = "Please enter a valid value";
    } else if (!planFormValues.invoicing_cycle_fixed_count) {
      errors.invoicing_cycle_fixed_count = "Please enter a valid value";
    }
  }

  if (planFormValues.trial === "TRIAL_NO") {
    errors.trial = "Please choose a trial option";
  }

  // if (planFormValues.trial === "TRIAL_DISCOUNTED") {
  if (planFormValues.trial === true && planFormValues.discount_trial === true) {
    const trialRangeError = planFormValues.discount_trial_ranges.map(
      (priceObj, index) => {
        return {
          range_to:
            (planFormValues.discount_trial_ranges.length - 1 === index &&
              parseFloat(priceObj.range_to as string) <
                parseFloat(priceObj.range_from as string)) ||
            isPositiveIntegerCheck(priceObj.range_to) === false
              ? "Value should be greater than from value and a positive integer"
              : "",
          discounted_percentage_per_step:
            priceObj.discounted_percentage_per_step ||
            // priceObj.discounted_percentage_per_step !== ""
            !["", 0].includes(priceObj.discounted_percentage_per_step)
              ? ""
              : "Please enter a discounted percentage",
        };
      }
    );

    const trialError = hasTrialErrors(trialRangeError);

    if (trialError) {
      errors.trialError = trialRangeError;
    }
  }
  if (
    planFormValues.pricing_model === "TIERED" ||
    planFormValues.pricing_model === "VOLUME" ||
    planFormValues.pricing_model === "STAIRSTEP"
  ) {
    planItemListData.forEach((item: { price: any[] }) => {
      item.price.forEach((priceObj, index) => {
        ({
          range_to: priceObj.fromErrors,
          price_per_step: priceObj.price_per_stepErrors,
        } = {
          range_to:
            item.price.length - 1 === index && priceObj.range_to
              ? parseFloat(priceObj.range_to) <
                  parseFloat(priceObj.range_from) ||
                isPositiveInteger(priceObj.range_to)
                ? "Value should be greater than from value and a positive integer"
                : ""
              : "",
          price_per_step:
            priceObj.price_per_step || priceObj.price_per_step === 0
              ? ""
              : "Please enter a price",
        });
      });
    });
    const itemErrors = hasErrors(planItemListData);
    if (itemErrors) {
      errors.itemErrors = itemErrors;
    }
  } else {
    const errValue = hasInvalidPrice(planItemListData);
    if (errValue) {
      // errors.errValue = true;
      delete errors.errValue;
    } else {
      delete errors.errValue;
    }
  }
  return errors;
};
