import React, {
  Dispatch,
  SetStateAction,
  useImperativeHandle,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  DNDefaultRef,
  DNDefaultValues,
  ObjectType,
} from "../../../../../types";
import PrefixInput from "../../../../common/components/PrefixInput/PrefixInput";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { vendorListSelector } from "../../../vendorManagement/vendorSlice";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { FormSelectField } from "../../../../common/components/formSelectField/FormSelectField";
import SubjectInput from "../../../../common/components/SubjectInput";
import { initialDefaultValues } from "./StateInitialization";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import VendorSelect from "../../../../common/components/VendorSelect";
import {
  currentUserSelector,
  fiscalTearFilterForValidationSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import moment from "moment";
import { getFormatedDate } from "../../../../../helpers/helper";
import { getNewDebitNoteNumber } from "../../debitNoteSlice";
import { getStateList } from "../../../gst/gstSlice";

import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { AddVendorModal } from "../../../vendorManagement/components/addVendorModal/AddVendorModal";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import _ from "lodash";
import useGst from "../../../../hooks/useGst";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import {
  gstRegistrationTypes,
  TAX_SYSTEM,
} from "../../../../constants/constants";
import CustomCheckbox from "../../../../common/components/CustomCheckbox/CustomCheckbox";
import { CustomFieldInTransactions } from "../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";

type Props = {
  defaultFormValues: DNDefaultValues;
  handleVendorChange: (e: any) => void;
  checkDebitNoteNumberExists: (debitNoteNumber: string) => void;
  formErrors: any;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (state_type: string, gst_registration_type: string) => void;
  setSupplyState?: any;
  initialStateType: string;
  gstRegistrationType: string;
  setIsStateChanged: Dispatch<SetStateAction<boolean>>;
  setRCMEnable: (rcmEnabled: boolean) => void;
};
type LocationProps = {
  consumerId?: string;
};
const DefaultDetails: ForwardRefRenderFunction<DNDefaultRef, Props> = (
  props,
  ref
) => {
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const vendorsList = useAppSelector(vendorListSelector);
  const { vendorId, editId } = useParams();
  const locationState = location.state as LocationProps;
  const consumerId = vendorId; //locationState?.consumerId;
  const currentUserInfo = useAppSelector(currentUserSelector);

  let [defaultFormValues, setDefaultFormValues] =
    useState<DNDefaultValues>(initialDefaultValues);
  const [debitNoteNumberObj, setDebitNoteNumberObj] = useState<ObjectType>({});
  const [debitNoteDate, setDebitNoteDate] = useState(new Date());
  const [stateOptions, setStateOptions] = useState<
    { label: string; value: string | number }[]
  >([]);
  const [
    sourceDestinationUpdateModalTriggered,
    setSourceDestinationUpdateModalTriggered,
  ] = useState<number>();
  const [taxAppliedFieldName, setTaxAppliedFieldName] = useState("");
  const debitNotesCustomFields = useModuleCustomFieldList("Debit Notes");
  const { isGstOrg } = useGst();
  const sourceDestinationUpdateModalRef = useRef<any>();
  const fiscalYearFilter = useAppSelector(
    fiscalTearFilterForValidationSelector
  );

  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultFormValues,
      debitNoteNumber: joinForPayload(debitNoteNumberObj),
    }),
    [defaultFormValues, debitNoteNumberObj]
  );

  useEffect(() => {
    if (!editId) {
      debitNoteNumber();
    }

    // fetch states list dropdown options
    if (isGstOrg) getStateListOptions();
  }, []);

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, ...props.defaultFormValues });
    if (editId) {
      const transaction_obj = splitTransactionNumber(
        props.defaultFormValues.debit_note_number
      );
      const DN_obj = {
        debit_note_prefix: transaction_obj["prefix"],
        debit_note_number: transaction_obj["number"],
      };
      setDebitNoteNumberObj(DN_obj);
    }
  }, [props.defaultFormValues]);

  useEffect(() => {
    if (consumerId) {
      let vendor = vendorsList
        .filter((vendor) => vendor.id === Number(consumerId))
        .map((vendor) => ({
          label: vendor.vendor_display_name,
          value: vendor.id,
        }))[0];
      if (vendor) {
        props.handleVendorChange(vendor);
        setDefaultFormValues((values) => {
          return {
            ...values,
            vendor_id: consumerId,
            vendor_name: vendor?.label,
          };
        });
      }
    }
  }, [consumerId, vendorsList]);

  useEffect(() => {
    if (!editId) {
      handleDate(debitNoteDate, "debit-note");
    }
  }, [debitNoteDate]);

  // For checking if the DN number exist on prefix change
  useEffect(() => {
    props.checkDebitNoteNumberExists(joinForPayload(debitNoteNumberObj));
  }, [debitNoteNumberObj["debit_note_prefix"]]);

  useEffect(() => {
    sourceDestinationUpdateModalTriggered &&
      sourceDestinationUpdateModalRef.current?.openAlertModal &&
      props.gstRegistrationType !== gstRegistrationTypes.overseas &&
      props.gstRegistrationType !== gstRegistrationTypes.sez &&
      props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      sourceDestinationUpdateModalRef.current?.openAlertModal();
  }, [sourceDestinationUpdateModalTriggered]);

  /**
   * Fetch state dropdwon options
   */
  const getStateListOptions = async () => {
    const result = await dispatch(
      getStateList({ orgId: currentUserInfo.organization_id })
    );
    const states = result.payload as string[];
    let statesArray = _.values(states);
    let stateOptionsTmp = statesArray.map((state) => {
      return {
        label: state,
        value: state,
      };
    });
    setStateOptions(stateOptionsTmp);
  };

  // Get debit note number
  const debitNoteNumber = async () => {
    const responseAction = await dispatch(
      getNewDebitNoteNumber(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setDebitNoteNumberObj(response);
        let DN_number = joinForPayload(response);
        setDefaultFormValues((values) => ({
          ...values,
          debit_note_number: DN_number ? DN_number : "",
        }));
      }
    }
    dispatch(setLoaderState(false));
  };
  const handleVendorChange = (e: { label: string; value: string | number }) => {
    setDefaultFormValues((values) => ({
      ...values,
      vendor_id: e.value.toString(),
      vendor_name: e.label.trim(),
    }));
    props.handleVendorChange(e);
  };
  const getPrefix = (value: string) => {
    setDebitNoteNumberObj({
      ...debitNoteNumberObj,
      ["debit_note_prefix"]: value.toUpperCase(),
    });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "debit_note_number") {
      props.checkDebitNoteNumberExists(
        debitNoteNumberObj["debit_note_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase()
      );
      setDefaultFormValues({
        ...defaultFormValues,
        [name]:
          debitNoteNumberObj["debit_note_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase(),
      });
      setDebitNoteNumberObj({
        ...debitNoteNumberObj,
        [name]: value.replace("~", "").toUpperCase(),
      });
    } else if (name === "subject" && value.length <= 250) {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    } else if (name === "vendor_notes" && value.length <= 300) {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    } else if (name === "reference_number") {
      if (value.length < 17) {
        setDefaultFormValues({ ...defaultFormValues, [name]: value });
      }
    } else {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    }
  };

  const onStateChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    let newDefaultFormValues = { ...defaultFormValues, [name]: option };
    setDefaultFormValues(newDefaultFormValues);
    name === "destination_of_supply"
      ? setTaxAppliedFieldName("Destination")
      : name === "source_of_supply"
      ? setTaxAppliedFieldName("Source")
      : setTaxAppliedFieldName("");
    props.setSupplyState(option.value);

    if (
      newDefaultFormValues?.source_of_supply &&
      newDefaultFormValues?.destination_of_supply
    ) {
      if (
        props.initialStateType === "Inter State" &&
        newDefaultFormValues?.source_of_supply.value ===
          newDefaultFormValues?.destination_of_supply.value
      ) {
        setSourceDestinationUpdateModalTriggered(
          Math.floor(Math.random() * 1000)
        );
      }
      if (
        props.initialStateType === "Intra State" &&
        newDefaultFormValues?.source_of_supply.value !==
          newDefaultFormValues?.destination_of_supply.value
      ) {
        setSourceDestinationUpdateModalTriggered(
          Math.floor(Math.random() * 1000)
        );
      }
      let state_type =
        !newDefaultFormValues?.source_of_supply ||
        !newDefaultFormValues?.destination_of_supply
          ? ""
          : newDefaultFormValues?.source_of_supply.value ===
            newDefaultFormValues?.destination_of_supply.value
          ? "Intra State"
          : "Inter State";
      props.setIsStateChanged(true);
      props.setStateType(state_type);
      props.fetchGstTaxList(state_type, props.gstRegistrationType);
    } else {
      props.setStateType("");
    }
  };

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        if (type === "debit-note") {
          setDefaultFormValues((values) => ({
            ...values,
            debit_note_date: "Invalid date",
          }));
        }
        // } else if (new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() > new Date(date).setHours(5, 30).toLocaleString()) {
        //   setDefaultFormValues((values) => ({
        //     ...values,
        //     debit_note_date: "Old date",
        //   }));
      } else if (new Date(date) > new Date() && type !== "dueDate") {
        setDefaultFormValues((values) => ({
          ...values,
          debit_note_date: "Future date",
        }));
      } else {
        const dateFormatted = getFormatedDate(date);
        // if (new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() > new Date(date).setHours(5, 30).toLocaleString()) {
        //   setDefaultFormValues((values) => ({
        //     ...values,
        //     debit_note_date: "Old date",
        //   }));
        // } else
        if (moment(dateFormatted, "YYYY-MM-DD", true).isValid()) {
          if (type === "debit-note") {
            setDefaultFormValues((values) => ({
              ...values,
              debit_note_date: dateFormatted,
            }));
            props.handleNewConversionDate(dateFormatted);
          }
        } else {
          setDefaultFormValues((values) => ({
            ...values,
            debit_note_date: "Invalid date",
          }));
        }
      }
    }
  };
  const rcmEnableChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let rcmEnabled: boolean = e.target.checked;
    setDefaultFormValues((prevValue) => {
      return {
        ...prevValue,
        rcm_enabled: rcmEnabled,
      };
    });
    props.setRCMEnable(rcmEnabled);
  };

  return (
    <section className="form-top-section" id="form-default-section">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="vendor" className="required">
              Vendor Name
            </label>
            <AddVendorModal />
          </div>
          <div>
            <VendorSelect
              vendorId={defaultFormValues.vendor_id}
              errors={props.formErrors.vendor_name}
              onChange={handleVendorChange}
            />
            <span className="error">{props.formErrors.vendor_name}</span>
          </div>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="debit_note_number" className="required">
            Debit Note Number
          </label>
          <br />
          <PrefixInput
            prefix={defaultFormValues.debit_note_number.split("~")[0]}
            name={"debit_note_number"}
            id={"debit_note_number"}
            className="form-control border-end-0"
            error={
              props.formErrors.debit_note_number
                ? props.formErrors.debit_note_number
                : ""
            }
            placeholder="Enter debit note number"
            value={defaultFormValues.debit_note_number.split("~")[1]}
            disabled={false}
            maxLength={13}
            onChange={handleChange}
            getPrefix={getPrefix}
          />
          <span className="error">{props.formErrors.debit_note_number}</span>
        </div>
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="debit-note" className="required">
            Debit Note Date
          </label>
          <br />
          <div className="date-selector-wrapper">
            <CustomDatepicker
              date={
                editId
                  ? new Date(defaultFormValues.debit_note_date)
                  : debitNoteDate
              }
              handleDate={handleDate}
              type="debit-note"
              error={props.formErrors.debit_note_date ? "error" : ""}
              id="debit_note_date"
              zIndex={0}
              module="DebitNote"
            />
          </div>
          <span className="error">{props.formErrors.debit_note_date}</span>
        </div>
        <FormInputField
          type="text"
          name="reference_number"
          id="ref_no"
          className={
            props.formErrors.reference_number
              ? "form-control error"
              : "form-control"
          }
          label="Reference Number"
          placeholder="Enter reference number"
          maxLength={16}
          value={defaultFormValues.reference_number}
          onChange={handleChange}
          error={props.formErrors.reference_number}
        />
      </div>
      {isGstOrg && (
        <>
          <div className={`row custom-row ${debitNotesCustomFields.length?"mb-0":""}`}>
            {props.gstRegistrationType !== gstRegistrationTypes.overseas && (
              <>
                <FormSelectField
                  key={0}
                  name="source_of_supply"
                  id="source_of_supply"
                  label="Source of Supply"
                  options={stateOptions}
                  wrapperClass="mw-325"
                  className="state-select form-select custom-select"
                  value={
                    defaultFormValues?.source_of_supply
                      ? defaultFormValues?.source_of_supply
                      : ""
                  }
                  onChange={(option: any) => {
                    onStateChanged("source_of_supply", option);
                  }}
                  styles={customSelectStyle}
                  placeholder={"Choose State"}
                  isSearchable={false}
                  isDisabled={false}
                  error={props.formErrors.source_of_supply}
                  isRequired={true}
                  isOnlyInRow={false}
                />
              </>
            )}

            <FormSelectField
              key={1}
              name="destination_of_supply"
              id="destination_of_supply"
              label="Destination of Supply"
              options={stateOptions}
              wrapperClass="mw-325"
              className="state-select form-select custom-select"
              value={
                defaultFormValues?.destination_of_supply
                  ? defaultFormValues?.destination_of_supply
                  : ""
              }
              onChange={(option: any) => {
                onStateChanged("destination_of_supply", option);
              }}
              styles={customSelectStyle}
              placeholder={"Choose State"}
              isSearchable={false}
              isDisabled={false}
              error={props.formErrors.destination_of_supply}
              isRequired={true}
              isOnlyInRow={false}
            />
          </div>
        </>
      )}
      <div className="mb-3">
        <CustomFieldInTransactions
          getFieldValues={props.getCustomFieldData}
          customFieldsArray={debitNotesCustomFields}
          initialValues={props.customFieldValue}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
          marginTopClass="mt-0"
        />
      </div>
      {currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      isGstOrg &&
      props.gstRegistrationType !== gstRegistrationTypes.composition &&
      props.gstRegistrationType !== gstRegistrationTypes.deemedExp ? (
        <div className="row custom-row">
          <div className="col-12 ps-3 pe-3">
            <CustomCheckbox
              checkboxName="rcm_enabled"
              checkboxId="rcm_enabled"
              isDisabled={false}
              isChecked={
                defaultFormValues.rcm_enabled
                  ? defaultFormValues.rcm_enabled
                  : false
              }
              changeHandler={rcmEnableChangeHandler}
            >
              <label
                className="form-check-label fw-normal"
                htmlFor="rcm_enabled"
              >
                This transaction is applicable for reverse charge
              </label>
            </CustomCheckbox>
          </div>
        </div>
      ) : (
        ""
      )}
     
      <div className="row custom-row">
        <SubjectInput
          isDisabled={defaultFormValues.vendor_id ? false : true}
          infoTitle={
            "You can enter up to 250 characters. This subject will be displayed in the credit note pdf."
          }
          subject={defaultFormValues.subject}
          onChange={handleChange}
        />
      </div>
      
      <AlertModalPopup
        message={`We will update the taxes applied to the items in this transaction as you've changed the ${taxAppliedFieldName} Of Supply`}
        modalTitle="Alert!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={false}
        openRef={sourceDestinationUpdateModalRef}
      />
    </section>
  );
};
export default React.memo(forwardRef(DefaultDetails));
