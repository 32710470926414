import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { PlanDefaultValues, PlanPriceDefaultValue } from "../../../types";
import { AddonPriceDefaultValue } from "./addonType";

export async function checkInternalName(data: {
  internalName: string;
  orgId: number;
  addon_id: Object;
}) {
  const encodedName = encodeURIComponent(data.internalName);
  const result = await get(
    Api.addon +
      Api.internalNameExists +
      `?current_organization=${data.orgId}&internal_name=${encodedName}&addon_id=${data.addon_id}`
  );
  return result;
}

export async function createNewAddon(data: {
  values: PlanDefaultValues;
  orgId: number;
}) {
  const result = await post(
    Api.addon + `create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to get addons list
 */
export async function getAddonList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  orgIds: number[];
  statusList: string[];
}) {
  let url =
    Api.addon +
    "list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

export async function getAddonForEdit(data: {
  addonId: number | string;
  orgId: number;
}) {
  const result = await get(
    Api.addon +
      "edit-details" +
      `/${data.addonId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function editAddonApi(data: {
  values: PlanDefaultValues;
  addonId: string | undefined;
  orgId: number;
}) {
  const result = await put(
    Api.addon + `edit/${data.addonId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to get plan item list
 */
export async function getPlanItemList(data: { planId: any; orgId: number }) {
  const result = await get(
    Api.plan +
      "item/list" +
      `/${data.planId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function addonPlanItemList(data: { addonId: any; orgId: number }) {
  const result = await get(
    Api.addon +
      "item/list" +
      `/${data.addonId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to create addon
 */
export async function createPlanPrice(data: {
  values: PlanPriceDefaultValue;
  orgId: number;
}) {
  const result = await post(
    Api.plan + "price-point/create" + `?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

export async function createAddonPrice(data: {
  values: AddonPriceDefaultValue;
  orgId: number;
}) {
  const result = await post(
    Api.addon + "price-point/create" + `?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 *  API call for getting addon detail and pricing list
 */
export async function getAddonOverviewDetails(data: {
  addonId: number;
  orgId: number;
}) {
  const result = await get(
    Api.addon + `details/${data.addonId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to update plan status (stop/resume/void)
 */
export async function updateAddonStatus(data: {
  id: number;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.addon +
      "change-status" +
      `/${data.id}?current_organization=${data.orgId}`,
    { status: data.status }
  );
  return result;
}

/**
 * API call to delete addon
 */
export async function deleteAddon(data: {
  deleteAddonId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.addon +
      "delete" +
      `/${data.deleteAddonId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to delete plan
 */
export async function deletePricing(data: {
  deletePricingId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.addon +
      "pricing/delete" +
      `/${data.deletePricingId}?current_organization=${data.orgId}`
  );

  return result;
}

export async function getEditPriceDetails(data: {
  priceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.plan +
      "pricing/edit-details" +
      `/${data.priceId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function addonGetEditPriceDetails(data: {
  priceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.addon +
      "pricing/edit-details" +
      `/${data.priceId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function editPlanPrice(data: {
  values: PlanPriceDefaultValue;
  orgId: number;
  priceId: any;
}) {
  const result = await put(
    Api.plan +
      "pricing/edit" +
      `/${data.priceId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

export async function editAddonPrice(data: {
  values: AddonPriceDefaultValue;
  orgId: number;
  priceId: any;
}) {
  const result = await put(
    Api.addon +
      "pricing/edit" +
      `/${data.priceId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

export async function checkPriceInternalName(data: {
  internalName: string;
  orgId: number;
  addon_id: number;
  pricing_id: string;
}) {
  const encodedName = encodeURIComponent(data.internalName);
  let url =
    Api.addon +
    Api.pricePoint +
    "/" +
    Api.internalNameExists +
    `/${data.addon_id}?current_organization=${data.orgId}&internal_name=${encodedName}`;

  if (data?.pricing_id) {
    url += `&pricing_id=${data.pricing_id}`;
  }
  const result = await get(url);
  return result;
}

/**
 * Api to get the transaction limit status
 */
export async function addonTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.addon + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
