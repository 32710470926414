import { Header, Row } from "@tanstack/react-table";
import { CSSProperties } from "react";
import { AccountNode, ProcessedRow } from "../types";

export const useProcessStyle = () => {
  const columnStyleProcessor = (
    row: Row<ProcessedRow>,
    column: string
  ): CSSProperties | undefined => {
    switch (column) {
      case "account_name":
        return {
          width: "100%",
          height: "100%",
          color: "var(--charcoal-grey)",
          paddingLeft: `${row.depth * 3}rem`,
          textAlign: row.original.style_class ? "right" : "left",
          fontWeight:
            ["total-node", "last-node"].includes(
              row.original.style_class || ""
            ) || row.original.depth === 1
              ? "bold"
              : "normal", // Apply bold for total rows
          backgroundColor:
            ["last-sub-node", "last-node"].includes(
              row.original.style_class || ""
            ) || row.original.is_tag
              ? "#f5f5f5"
              : "transparent", // Apply a background color for total rows
          display: "flex",
          alignItems: "center",
          justifyContent: ["last-node", "total-node", "last-sub-node"].includes(
            row.original.style_class || ""
          )
            ? "flex-end"
            : "flex-start",
        };
      default: {
      }
    }
  };
  const headerStyleProcessor = (
    header: Header<AccountNode, unknown>
  ): CSSProperties | undefined => {
    return {
      textAlign: !["closing_balance", "net_debit", "net_credit"].includes(header.id) ? "left" : "right",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "2",
      letterSpacing: "normal",
      color: " var(--battleship-grey)",
      textTransform: "uppercase",
    };
  };
  const rowStyleProcessor = (
    row: Row<AccountNode>
  ): CSSProperties | undefined => {
    return {
      width: "100%",
      height: "100%",
      fontWeight:
        row.original.style_class === "total-node"
          ? "900"
          : row.original.style_class === "last-node" || row.original.depth === 1
          ? "bold"
          : "normal",
      fontSize: row.original.style_class === "total-node" ? "18px" : "16px",

      backgroundColor:
        ["last-sub-node", "last-node"].includes(
          row.original.style_class || ""
        ) || row.original.is_tag
          ? "#f5f5f5"
          : "white",
    };
  };
  const cellStyleProcessor = (
    row: Row<AccountNode>
  ): CSSProperties | undefined => {
    return {
      backgroundColor:
        ["last-node", "total-node", "last-sub-node"].includes(
          row.original.style_class || ""
        ) || row.original.is_tag
          ? "#f5f5f5"
          : "white",
      textAlign: "right",
      fontSize: row.original.style_class === "total-node" ? "18px" : "16px",
      fontWeight: ["last-node", "total-node"].includes(
        row.original.style_class || ""
      )
        ? row.original.style_class === "total-node"
          ? "900"
          : "bold"
        : "normal",
    };
  };
  return {
    columnStyleProcessor,
    headerStyleProcessor,
    rowStyleProcessor,
    cellStyleProcessor,
  };
};
