import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NoAccessIcon, GoBackArrowWhiteIcon } from "../../../assets/images";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setErrorID } from "../../../appSlice";
import { CopyButton } from "./CopyButton";
import { currentUserSelector } from "../commonSlice";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import "../styles/AccessDenied.css";

type Props = {
  error_id?: string | number;
};
export default function AccessDenied(props?: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector)
  const errorID = props?.error_id || 0;
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    return () => setErrorCode();
  }, [window.location.pathname, currentUserInfo.organization_id]);

  const setErrorCode = () => {
    dispatch(setErrorID(0));
  };

  const handleErrorCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
    navigator.clipboard.writeText(errorID.toString());
  };
  
  return (
    <div className="access-denied-wrapper">
      <img src={NoAccessIcon} width={108} height={88} alt="no-access" />
      <h2>Restricted Access!</h2>
      {props && props.error_id ? (
        <span className="error-code">
          Error ID: {props && props.error_id}
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={isCopied ? "Copied ERROR_ID!" : "Copy ERROR ID"}
          >
           <CopyButton onClick={handleErrorCopy} color="blue"/>
          </Tooltip>
        </span>
      ) : (
        <></>
      )}
      <span className="sub">
        Sorry, You have no permission to access this operation. Please contact
        your Administrator.
      </span>
      <button
        type="button"
        className="btn back-btn green-button"
        onClick={() => (props?.error_id ? navigate("/") : navigate("/"))}
      >
        <img
          src={GoBackArrowWhiteIcon}
          width={"24px"}
          height={"24px"}
          alt="Go Back"
        />
        {props?.error_id ? "Go Back" : "Go to Dashboard"}
      </button>
    </div>
  );
}
