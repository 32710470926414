import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import { message } from "../../../../constants/messages";
import { ObjectType } from "../../../../../types";
import { useLocation } from "react-router-dom";
import { getBalanceSheetReport, setSelectedTagsData } from "../../ReportSlice";
import {
  downloadFile,
  getFileName,
} from "../../componets/exportDownloadHelper";
import ReportHeader from "../../componets/ReportHeader";
import ReportFilter from "../../componets/ReportFilter";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import BalanceSheetReportTable from "../balanceSheetReportTable/BalanceSheetReportTable";
import ReportsExportComponent from "../../../../common/components/reportsExportComponent/reportsExportComponent";
import Loader from "../../../components/Loader";
import "../../Reports.css";

export default function BalanceSheet() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const reportRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isLoading, setIsLoader] = useState(true);
  const initialReportData = {
    currency_code: "",
    currency_id: 0,
    date_range: "today",
    end_date: getFormatedDate(),
    items: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
    },
    organization_id: "",
    total_liabilities_equity: 0,
    tag_option_in: [],
  };
  const initialFiltervalues = {
    currency_id: currentUserInfo.organization_currency,
    date_range: "today",
    end_date: getFormatedDate(),
    organization_id: currentUserInfo.organization_id,
    tag_option_in: [],
  };
  const [reportData, setReportData] = useState<any>(initialReportData);
  const [showReport, setShowReport] = useState(false);
  const [drillDownFilter, setDrillDownFilter] = useState<ObjectType>({});
  const [startDate, setStartDate] = useState(getFormatedDate());
  const [orgName, setOrgName] = useState("");
  const [filterDataForExport, setFilterDataForExport] = useState<ObjectType>(
    {}
  );

  useEffect(() => {
    const storedFilter = localStorage.getItem("drill-down-filter");
    const filterParsed: ObjectType = storedFilter
      ? JSON.parse(storedFilter)
      : {};
    if (Object.keys(filterParsed).length) {
      if (
        currentUserInfo.organization_id &&
        currentUserInfo.organization_currency
      ) {
        setDrillDownFilter({ ...filterParsed });
      }
    } else {
      if (
        currentUserInfo.organization_id &&
        currentUserInfo.organization_currency
      )
        getReport("initial");
    }
  }, [
    currentUserInfo.organization_id,
    currentUserInfo.organization_currency,
    location.pathname,
  ]);

  useEffect(() => {
    if (Object.keys(drillDownFilter).length) {
      getReport("drilldown", drillDownFilter);
    }
  }, [drillDownFilter]);

  const handleReport = () => {
    setIsLoader(true);
    getReport();
  };

  /**
   * Fetch balance sheet report
   */
  const getReport = useCallback(
    async (type = "", drillDownFilter: ObjectType = {}) => {
      setIsLoader(true);
      let { start_date, ...filterValues } = reportRef.current.filterValues;
      setStartDate(start_date);
      if (type === "initial") {
        filterValues = initialFiltervalues;
        dispatch(setSelectedTagsData([]));
        localStorage.removeItem("drill-down-filter");
      }
      if (type === "drilldown") {
        if (Object.keys(drillDownFilter).length) {
          filterValues = {
            currency_id: drillDownFilter.currencyId,
            organization_id: drillDownFilter.organizationId,
            end_date: drillDownFilter.endDate,
            date_range: drillDownFilter.dateRange,
            tag_option_in: drillDownFilter.tag_option_in,
            is_integrated: drillDownFilter.is_integrated,
          };
        }
      }
      const responseAction = await dispatch(
        getBalanceSheetReport({
          filterValues: filterValues,
          orgId: currentUserInfo.organization_id,
          temp: location.pathname === "/balance-sheet-1" ? true : false,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setReportData(response);
          setIsLoader(false);
          setShowReport(true);
          setOrgName(response.organization_details.organization_name);
          setFilterDataForExport(filterValues);
        }
      }
    },
    [drillDownFilter, location.pathname]
  );

  const exportFile = () => {
    downloadFile(
      filterDataForExport,
      "balance-sheet",
      getFileName(
        orgName || "",
        "balance-sheet",
        filterDataForExport.start_date,
        filterDataForExport.end_date
      )
    );
  };

  return (
    <div className="reports balance-sheet-report">
      <div
        className="card card-dashboard report-card balance-sheet-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Balance Sheet</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={true}
                isAdvanced={true}
                defaultValue="today"
                reportType="BS"
                handleReport={handleReport}
                clearReport={() => setShowReport(false)}
                filterdata={drillDownFilter}
                ref={reportRef}
                // habdleReportType={handleReportTypeChange}
                enable_toggleReportType={true}
              />
            </div>
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links align-items-center "></div>
                <ReportsExportComponent
                  onExportPDF={() => {}}
                  onExportExcel={exportFile}
                />
                <ReportHeader
                  reportName="Balance Sheet"
                  reportData={reportData}
                />
                <div className="table-section report-table-section">
                  <InfoBox
                    message={
                      message(reportData.currency_code).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  <div className="report-table-outline w-100">
                    <BalanceSheetReportTable
                      jsonData={reportData}
                      filterValues={reportRef.current.filterValues}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {isLoading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
