type ButtonProps = {
  type: "submit" | "reset" | "button";
  isDisabled: boolean;
  wrapperClass?: string;
  children: React.ReactNode;
  onClick?: (e: any) => void;
  fontSize: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};
export const IconButton = (props: ButtonProps) => {
  return (
    <button
      className={`no-appearance text-btn ${
        props.wrapperClass ? props.wrapperClass : ""
      } reusable-icon-button`}
      type={props.type}
      disabled={props.isDisabled}
      style={{
        pointerEvents: props.isDisabled ? "none" : undefined,
        opacity: props.isDisabled ? "0.5" : "",
        fontSize: props.fontSize,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
