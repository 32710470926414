import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import {
  ConsumerBalanceDetailFilter,
  ReportFilters,
  SoaFilters,
} from "../../../types";
import { AgingCustomization } from "./types";

/**
 * API for generate SOA report
 */
export async function generateSOAReport(data: {
  filterValues: SoaFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      "statement-of-accounts/generate?current_organization=" +
      data.orgId,
    data.filterValues
  );
  return result;
}
/**
 * API for generate Balance Sheet report
 */
export async function generateBalanceSheetReport(data: {
  filterValues: ReportFilters;
  orgId: number;
  temp: boolean;
}) {
  const result = await post(
    `${Api.reports}balance-sheet/generate${
      data.temp ? "1" : ""
    }?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}

/**
 * API for generate Trial Balance report
 */
export async function generateTrialBalanceReport(data: {
  filterValues: ReportFilters;
  orgId: number;
  temp: boolean;
}) {
  const result = await post(
    `${Api.reports}trial-balance/generate${
      data.temp ? "1" : ""
    }?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for generate Profit and loss report
 */
export async function generateProfitAndLossReport(data: {
  filterValues: ReportFilters;
  orgId: number;
  temp: boolean;
}) {
  const result = await post(
    `${Api.reports}profit-and-loss/generate${
      data.temp ? "1" : ""
    }?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}

/**
 * API to get Total Payable Amount for Dashboard
 */
export async function totalPayableAmount(data: {
  orgId: number;
  currencyId: number;
}) {
  const result = await get(
    Api.reports +
      `dashboard/total-payables?organization_id=${data.orgId}&currency_id=${data.currencyId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to get Total Receivable Amount for Dashboard
 */
export async function totalReceivableAmount(data: {
  orgId: number;
  currencyId: number;
}) {
  const result = await get(
    Api.reports +
      `dashboard/total-receivables?organization_id=${data.orgId}&currency_id=${data.currencyId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to generate Account transaction report
 */
export async function generateAccountTransactionReport(data: {
  filterValues: ReportFilters;
  orgId: number;
  pageNum: number;
  pageSize: number;
}) {
  const result = await post(
    Api.reports +
      `account-transactions/generate?current_organization=${data.orgId}&page_num=${data.pageNum}&page_size=${data.pageSize}`,
    data.filterValues
  );
  return result;
}

/**
 * API to generate Account transaction report
 */
export async function generateAccountTransactionReportNew(data: {
  filterValues: ReportFilters;
  orgId: number;
  pageNum: number;
  pageSize: number;
}) {
  const result = await post(
    Api.reports +
      `account-transactions/v2/generate?current_organization=${data.orgId}&page_num=${data.pageNum}&page_size=${data.pageSize}`,
    data.filterValues
  );
  return result;
}

/**
 * API to get TDS customers for SOA
 */
export async function getTdsCustomerForSoa(data: {
  reportOrg: number;
  orgId: number;
  customerName: string;
}) {
  let url =
    Api.customerManagement +
    Api.customer +
    "/dropdown-list-for-soa" +
    `?organization_id=${data.reportOrg}&current_organization=${data.orgId}`;

  if (data.customerName !== "") {
    url += `customer_name=${data.customerName}`;
  }
  const result = await get(url);
  return result;
}
/**
 * API to get TDS vendors for SOA
 */
export async function getTdsVendorForSoa(data: {
  reportOrg: number;
  orgId: number;
  vendorName: string;
}) {
  let url =
    Api.vendorManagement +
    Api.vendor +
    "/dropdown-list-for-soa" +
    `?organization_id=${data.reportOrg}&current_organization=${data.orgId}`;

  if (data.vendorName !== "") {
    url += `vendor_name=${data.vendorName}`;
  }
  const result = await get(url);
  return result;
}
/**
 * API to get Top 5 Payables List for Dashboard
 */
export async function totalPayablesList(data: {
  orgId: number;
  currencyId: number;
}) {
  const result = await get(
    Api.reports +
      `dashboard/total-payables-list?organization_id=${data.orgId}&currency_id=${data.currencyId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to get Top 5 Receivables List for Dashboard
 */
export async function topReceivablesList(data: {
  orgId: number;
  currencyId: number;
}) {
  const result = await get(
    Api.reports +
      `dashboard/total-receivables-list?organization_id=${data.orgId}&currency_id=${data.currencyId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API for get the aging report - sundry debtor summary
 */
export async function generateSundryDebtorSummary(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      Api.agingReport +
      `sundry-debtor-summary/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the aging report - sundry debtor details
 */
export async function generateSundryDebtorDetails(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      Api.agingReport +
      `sundry-debtor-report/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the aging report - sundry creditor summary
 */
export async function generateSundryCreditorSummary(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      Api.agingReport +
      `sundry-creditor-summary/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the aging report - sundry creditor details
 */
export async function generateSundryCreditorDetails(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      Api.agingReport +
      `sundry-creditor-report/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the vendor balance report
 */
export async function generateVendorBalance(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      `vendor-balance/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the customer balance report
 */
export async function generateCustomerBalance(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      `customer-balance/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the vendor balance details report
 */
export async function generateVendorBalanceDetail(data: {
  filterValues: ConsumerBalanceDetailFilter;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      `vendor-balance/detail?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}
/**
 * API for get the customer balance details report
 */
export async function generateCustomerBalanceDetail(data: {
  filterValues: ConsumerBalanceDetailFilter;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      `customer-balance/detail?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}

/**
 * API to generate Payable Summary report
 */
export async function generatePayableSummaryReport(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports + `payable-summary/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}

/**
 * API to generate Receivable Summary report
 */
export async function generateReceivableSummaryReport(data: {
  filterValues: ReportFilters;
  orgId: number;
}) {
  const result = await post(
    Api.reports +
      `receivable-summary/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}

/**
 * API to generate Receivable Summary report
 */
export async function generateBudgetVarianceReport(data: {
  filterValues: {
    start_date: string;
    end_date: string;
    organization_id: number;
    currency_id: number;
    budget_id: number;
  };
  orgId: number;
}) {
  const result = await get(
    Api.reports +
      `budget-variance/generate?current_organization=${data.orgId}&currency_id=${data.filterValues.currency_id}&budget_id=${data.filterValues.budget_id}&start_date=${data.filterValues.start_date}&end_date=${data.filterValues.end_date}&organization_id=${data.filterValues.organization_id}`
  );
  return result;
}

/**
 * API to generate Budget Summary report
 */
export async function generateBudgetSummaryReport(data: {
  filterValues: {
    budget_id: number;
    start_date: string | null;
    period: number | null;
    no_of_periods: number | null;
    organization_id: number;
    currency_id: number;
  };
  orgId: number;
}) {
  const result = await post(
    Api.reports + `budget-summary/generate?current_organization=${data.orgId}`,
    data.filterValues
  );
  return result;
}

/**
 * API to get saved customizations of aging report
 */
export async function agingCustomizations(data: {
  orgId: number;
  reportType?: string;
}) {
  const result = await get(
    Api.reports +
      `aging-report/list-customizations?current_organization=${data.orgId}&report_type=${data.reportType}`
  );
  return result;
}
/**
 * API to save customization of aging report
 */
export async function saveAgingCustomizations(data: {
  orgId: number;
  customization: AgingCustomization;
}) {
  const result = await post(
    Api.reports +
      `aging-report/create-customization?current_organization=${data.orgId}`,
    data.customization
  );
  return result;
}
/**
 * API to update saved customization of aging report
 */
export async function updateAgingCustomizations(data: {
  orgId: number;
  customizationId: number;
  customization: AgingCustomization;
}) {
  const result = await put(
    Api.reports +
      `aging-report/update-customization/${data.customizationId}?current_organization=${data.orgId}`,
    data.customization
  );
  return result;
}
/**
 * API to delete saved customization of aging report
 */
export async function deleteAgingCustomizations(data: {
  orgId: number;
  customizationId: number;
}) {
  const result = await remove(
    Api.reports +
      `aging-report/delete-customization/${data.customizationId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to get reporting tags associated with an account Balance Sheet
 */
export async function getReportingTagsAssociatedBalanceSheet(data: {
  currencyId: number;
  accountId: number;
  optionIds: number[];
  endDate: string;
  organizationId: number;
  isIntegrated: boolean;
  currentOrganization: number;
}) {
  const optionIdsParam = data.optionIds
    .map((id) => `option_ids=${id}`)
    .join("&");
  const result = await get(
    Api.reports +
      `balance-sheet/reporting-tag-association?currency_id=${data.currencyId}&account_id=${data.accountId}&${optionIdsParam}&end_date=${data.endDate}&organization_id=${data.organizationId}&is_integrated=${data.isIntegrated}&current_organization=${data.currentOrganization}`
  );
  return result;
}

/**
 * API to get reporting tags associated with an account Trial Balance
 */
export async function getReportingTagsAssociatedTrialBalance(data: {
  currencyId: number;
  accountId: number;
  optionIds: number[];
  endDate: string;
  organizationId: number;
  isIntegrated: boolean;
  currentOrganization: number;
}) {
  const optionIdsParam = data.optionIds
    .map((id) => `option_ids=${id}`)
    .join("&");
  const result = await get(
    Api.reports +
      `trial-balance/reporting-tag-association?currency_id=${data.currencyId}&account_id=${data.accountId}&${optionIdsParam}&end_date=${data.endDate}&organization_id=${data.organizationId}&is_integrated=${data.isIntegrated}&current_organization=${data.currentOrganization}`
  );
  return result;
}

export async function getReportingTagsAssociatedProfitAndLoss(data: {
  currentOrganization: number;
  filterValues: any;
  account_id: number | string | null;
  pl_category_name: string | null;
}) {
  let payload = data.filterValues;
  if(data.pl_category_name) {
    payload = {
      ...payload,
      pl_category_name: data.pl_category_name
    }
  }else {
    payload = {
      ...payload,
      account_id: data.account_id
    }
  }
  const result = await post(
    Api.reports +
      `profit-and-loss/reporting-tag-association?current_organization=${data.currentOrganization}`,
      payload
  );
  return result;
}
