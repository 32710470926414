import { EmailInfoIcon } from "../../../../assets/images";
import ImageUpload from "../../../common/components/ImageUpload";
import Select from "react-select";
import "../OrganizationProfile.css";
import { useEffect, useState } from "react";
import {
  LocationValues,
  ObjectType,
  OrganizationProfileValues,
} from "../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  countrySelector,
  currentUserDetails,
  currentUserSelector,
  getCountries,
  getCountryStates,
} from "../../../common/commonSlice";
import { customFiscalSelectStyle } from "../../../common/components/SelectCustomFiscalSrtyle";

import {
  organizationDetails,
  updateOrganizationProfile,
} from "../OrganizationProfileSlice";
import Loader from "../../components/Loader";
import { RegionSelectCustomStyle } from "../../../common/components/RegionSelectCustomStyle";
import { toast } from "react-toastify";
import { getOrganizationListOfUser } from "../../../common/commonSlice";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import useGst from "../../../hooks/useGst";
import { Container } from "../../../common/components/htmlTags/Container";
import { FormFieldContainer } from "../../../common/components/ui";
import { SelectCustomStyleCustomer } from "../../../common/components/SelectCustomStyleCustomer";
import { DateFormat, dateFormatInitialValue } from "../resources/constants";
import FormSelect from "../../../common/components/ui/formElements/FormSelect/FormSelect";
function OrganizationProfile(props: any) {
  const redirectTo = props?.redirectTo;
  const initialOrganizationValues = {
    name: "",
    organization_id: 0,
    email: "",
    address: "",
    city: "",
    state_id: 0,
    zip_code: "",
    phone_number: "",
    country_id: 0,
    currency_code: "",
    pan_number: "",
    gst: "",
    tan_number: "",
    currency_id: 0,
    transaction_exist: false,
    logo_path: "",
    fiscal_year: "",
    date_format: "DD-MM-YYYY",
  };
  type OptionType = {
    value: string;
    label: string;
  };
  let fiscal_year: OptionType[] = [
    { value: "January-December", label: "January-December" },
    { value: "February-January", label: "February-January" },
    { value: "March-February", label: "March-February" },
    { value: "April-March", label: "April-March" },
    { value: "May-April", label: "May-April" },
    { value: "June-May", label: "June-May" },
    { value: "July-June", label: "July-June" },
    { value: "August-July", label: "August-July" },
    { value: "September-August", label: "September-August" },
    { value: "October-September", label: "October-September" },
    { value: "November-October", label: "November-October" },
    { value: "December-November", label: "December-November" },
  ];

  const dispatch = useAppDispatch();
  const countries = useAppSelector(countrySelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [selectedFiscalOption, setSelectedFiscalOption] = useState<OptionType>({
    value: "",
    label: "",
  });
  const [organizationProfileData, setOrganizationProfileData] =
    useState<OrganizationProfileValues>(initialOrganizationValues);
  const [initialOrganizationProfileData, setInitialOrganizationProfileData] =
    useState<OrganizationProfileValues>(initialOrganizationValues);
  const [states, setStates] = useState<LocationValues[]>([]);
  const [showGstIn, setShowGstIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backendError, setBackendError] = useState<{ [key: string]: any }>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [logoData, setLogoData] = useState<any>();
  const [isLogoRemoved, setIsLogoRemoved] = useState(false);
  const { isGstOrg } = useGst();
  const [selectedDateFormat, setSelectedDateFormat] = useState<OptionType>(dateFormatInitialValue);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let subModulesWithPermission = organizationRole.length
    ? organizationRole.map((items: any) => {
        return items.subModule;
      })
    : [];

  useEffect(() => {
    getAllCountries();
    if (subModulesWithPermission.includes("Organization Profile")) {
      getOrganizationProfileData();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [states, countries, organizationProfileData]);

  const getCountryFiscalYear = (country_id: number) => {
    switch (country_id) {
      case 1:
        return fiscal_year[3];
        break;
      case 2:
        return fiscal_year[9];
        break;
      case 3:
        return fiscal_year[0];
        break;
      case 4:
        return fiscal_year[0];
        break;
      default:
        return fiscal_year[3];
    }
  };

  useEffect(() => {
    const country_id = organizationProfileData.country_id;
    if (country_id !== null && country_id !== 0) {
      getCountryStatesList(country_id).then((responseAction: any) => {
        if (responseAction?.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            setStates([...response]);
          }
        }
      });
    }
    if (
      initialOrganizationProfileData.country_id !==
      organizationProfileData.country_id
    ) {
      setSelectedFiscalOption(getCountryFiscalYear(country_id));
      setOrganizationProfileData({
        ...organizationProfileData,
        ["fiscal_year"]: getCountryFiscalYear(country_id).value,
      });
    }
  }, [organizationProfileData.country_id]);

  /**
   * Fetch organization profile data
   */
  const getOrganizationProfileData = async () => {
    setIsLoading(true);
    setFormErrors({});
    const responseAction = await dispatch(
      organizationDetails(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setOrganizationProfileData(response);
        setSelectedFiscalOption({
          value: response.fiscal_year,
          label: response.fiscal_year,
        });
        setInitialOrganizationProfileData(response);
        if (response.country_id === 1) {
          setShowGstIn(true);
        } else {
          setShowGstIn(false);
        }
        if (response.date_format) {
          let selectedFormat = DateFormat.find((dateformat: OptionType) => {
            return dateformat.value === response.date_format;
          });
          setSelectedDateFormat(selectedFormat|| dateFormatInitialValue);
        }
      }
    }
  };

  /**
   * Fetch all country list
   */
  const getAllCountries = async () => {
    await dispatch(getCountries());
  };

  /**
   * Fetch all state list
   */
  const getCountryStatesList = async (country_id: number) => {
    return await dispatch(getCountryStates(country_id));
  };

  /**
   * Function to handle input change
   */
  const formInputChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setOrganizationProfileData({ ...organizationProfileData, [name]: value });
  };

  /**
   * Function to handle fiscal year change
   */
  const handleFiscalYearChange = (e: any) => {
    setSelectedFiscalOption(e);
    setOrganizationProfileData({
      ...organizationProfileData,
      ["fiscal_year"]: e.value,
    });
  };

  /**
   * Function to handle country change
   */
  const handleCountryChange = (e: any) => {
    const value = e.value;
    const countryId = e.value;
    if (countryId === 1) {
      setShowGstIn(true);
    } else {
      setShowGstIn(false);
    }
    getAllCountryStates(countryId);
    let currencyValue = countries.filter((item) => {
      if (item.id === countryId) {
        return item.currency;
      }
    });
    setOrganizationProfileData({
      ...organizationProfileData,
      country_id: parseInt(value),
      currency_code: currencyValue[0].currency,
      state_id: 0,
    });
  };

  /**
   * Function to handle state change
   */
  const handleStateChange = (e: any) => {
    const value = e.value;
    setOrganizationProfileData({
      ...organizationProfileData,
      state_id: parseInt(value),
    });
  };

  /**
   * Fetch all states list based on country
   */
  const getAllCountryStates = async (countryId: number) => {
    if (countryId) {
      const responseAction = await dispatch(getCountryStates(countryId));
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setStates([...response]);
        }
      }
    }
  };

  /**
   * To display name of state value in the select dropdown
   */
  const stateValue = states?.filter((state) => {
    return state.id === organizationProfileData.state_id;
  })[0]?.name;

  /**
   * Update organization profile handler
   */
  const UpdateOrganizationProfile = async (e: any) => {
    setIsSubmit(true);
    e.preventDefault();
    e.target.blur();
    let organizationProfileInputs: any = { ...organizationProfileData };
    let errors: any = [];
    errors = validate(organizationProfileInputs);
    let logoPathVariable = organizationProfileInputs.logo_path;
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      $("#form-btn-org-profile").addClass("org-profile-form-section-disable");
      setFormErrors({});
      let key: keyof typeof organizationProfileInputs;
      for (key in organizationProfileInputs) {
        if (organizationProfileInputs[key] === "") {
          organizationProfileInputs[key] = null;
        }
        if (key === "country_id" && organizationProfileInputs[key] !== 1) {
          organizationProfileInputs["gst"] = null;
          organizationProfileInputs["pan_number"] = null;
          organizationProfileInputs["tan_number"] = null;
        }
      }
      for (key in organizationProfileInputs) {
        if (
          key === "currency_code" ||
          key === "currency_id" ||
          key === "id" ||
          key === "is_current_user_org" ||
          key === "transaction_exist" ||
          key === "user_id" ||
          key === "logo_path"
        ) {
          delete organizationProfileInputs[key];
        }
      }

      organizationProfileInputs["date_format"] = selectedDateFormat.value;

      const payload = new FormData();
      payload.append("profile_logo", logoData ? logoData : "");
      payload.append("obj_in", JSON.stringify(organizationProfileInputs));

      const responseAction = await dispatch(
        updateOrganizationProfile({
          values: payload,
          orgId: currentUserInfo.organization_id,
          isLogoRemoved: !logoPathVariable && isLogoRemoved ? true : false,
        })
      );
      const response = responseAction.payload;
      $("#form-btn-org-profile").removeClass(
        "org-profile-form-section-disable"
      );
      if (Object.keys(response).length && !("error" in response)) {
        setBackendError({});
        if (redirectTo === "invoice") {
          $(".close-btn").click();
        }
        toast.success("Your organization profile has been saved", {
          toastId: "org-profile-save-success",
          closeButton: false,
          position: "top-center",
        });
        await dispatch(
          currentUserDetails({
            orgId: currentUserInfo.organization_id
              ? currentUserInfo.organization_id
              : 0,
            isOrgChanged: false,
          })
        );
        await dispatch(
          getOrganizationListOfUser(currentUserInfo.organization_id)
        );
        props.handleClose && props.handleClose();
      } else {
        const errorDetails = response.error.detail;
        if (Array.isArray(errorDetails) && errorDetails.length) {
          let errorObject: { [key: string]: any } = {};
          for (const error of errorDetails) {
            if (error.type === "value_error.name") {
              errorObject.name = error.msg;
            }
          }
          setBackendError(errorObject);
          props.handleClose && props.handleClose();
        }
      }
    }
  };

  /**
   * input validation on form submit
   */
  const validate = (values: OrganizationProfileValues) => {
    const errors: ObjectType = {};
    if (!values.name) {
      errors.name = "Please enter organization name";
    }
    const emailRegex = /^(?:\w+[-.])*\w{1,64}@(?:\w+[-.])*\w+\.\w{2,3}$/i;
    if (values.email && !emailRegex.test(values.email)) {
      errors.email = "Please enter valid email address";
    }
    const phoneRegex = /^[\+]?([0-9]){6,18}$/;
    if (
      values.phone_number &&
      !phoneRegex.test(values.phone_number.toString())
    ) {
      errors.phone_number = "Please enter valid phone number";
    }

    if (values.country_id === 1) {
      const gstInRegex =
        /([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([A-Z1-9]){1}Z([A-Z0-9]){1}$/;
      if (values.gst && !gstInRegex.test(values.gst)) {
        errors.gstin = "Please enter a valid GSTIN number to proceed";
      }

      const tanRegex = /([A-Z]){4}([0-9]){5}([A-Z]){1}$/;
      if (values.tan_number && !tanRegex.test(values.tan_number)) {
        errors.tan_number = "Please enter a valid TAN number to proceed";
      }

      const panRegex =
        /([A-Z]){3}([PCHABGJLFT]){1}([A-Z]){1}([0-9]){4}([A-Z]){1}$/;
      if (values.pan_number && !panRegex.test(values.pan_number)) {
        errors.pan_number = "Please enter a valid PAN number to proceed";
      }
    }

    if (values.state_id === 0 || values.state_id === null) {
      errors.state_id = "Please choose state";
    }

    if (values.fiscal_year === "" || values.fiscal_year === null) {
      errors.fiscal_year = "Please choose a fiscal year";
    }
    if (values.date_format === "" || values.date_format === null) {
      errors.date_format = "Please choose a date format";
    }

    return errors;
  };

  /**
   * Function to set the path of uploaded profile logo url
   */
  const uploadLogoHandler = (logoUrlLink: any) => {
    setOrganizationProfileData({
      ...organizationProfileData,
      logo_path: logoUrlLink,
    });
    setLogoData(logoUrlLink);
    setIsLogoRemoved(false);
  };

  /**
   * Function to check whether image removed or not
   */
  const removeLogoHandler = () => {
    setOrganizationProfileData({
      ...organizationProfileData,
      logo_path: "",
    });
    setLogoData("");
    setIsLogoRemoved(true);
  };

  return (
    <>
      <form
        action=""
        name="org-settings-form"
        id="org-settings-form"
        className="org-settings-form w-100"
      >
        <div className="fields-wrapper">
          <section className="form-logo-section">
            <div className="form-fields">
              <div className="image-upload-container">
                <h5>Photo</h5>
                <ImageUpload
                  logoUrl={
                    organizationProfileData.logo_path
                      ? organizationProfileData.logo_path
                      : ""
                  }
                  uploadLogoHandler={uploadLogoHandler}
                  removeLogoHandler={removeLogoHandler}
                />
              </div>
            </div>
          </section>
          <section className="form-fields-section">
            <div className="form-fields">
              {redirectTo === "invoice" ? (
                <div className="row g-0">
                  <div className="col pr-14">
                    <label htmlFor="name" className="required">
                      Organization Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className={`form-control ${
                        formErrors.name || backendError.name ? "error" : ""
                      }`}
                      placeholder="Enter organization name"
                      maxLength={200}
                      value={organizationProfileData.name}
                      onChange={(e) => formInputChangeHandler(e)}
                      disabled={
                        organizationProfileData.transaction_exist ? true : false
                      }
                    />
                    <span className="error w-100">
                      {formErrors.name || backendError.name}
                    </span>
                  </div>
                  <div className="col pl-14">
                    <label htmlFor="organization_email">
                      Organization Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={`form-control ${
                        formErrors.email ? "error" : ""
                      }`}
                      placeholder="Enter organization email"
                      maxLength={200}
                      onChange={(e) => formInputChangeHandler(e)}
                      value={
                        organizationProfileData.email
                          ? organizationProfileData.email
                          : ""
                      }
                    />
                    <span className="error w-100">{formErrors.email}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row g-0">
                    <div className="col mw-500">
                      <label htmlFor="name" className="required">
                        Organization Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className={`form-control ${
                          formErrors.name || backendError.name ? "error" : ""
                        }`}
                        placeholder="Enter organization name"
                        maxLength={200}
                        value={organizationProfileData.name}
                        onChange={(e) => formInputChangeHandler(e)}
                        disabled={
                          organizationProfileData.transaction_exist
                            ? true
                            : false
                        }
                      />
                      <span className="error w-100">
                        {formErrors.name || backendError.name}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col mw-500">
                      <label htmlFor="organization_email">
                        Organization Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className={`form-control ${
                          formErrors.email ? "error" : ""
                        }`}
                        placeholder="Enter organization email"
                        maxLength={200}
                        onChange={(e) => formInputChangeHandler(e)}
                        value={
                          organizationProfileData.email
                            ? organizationProfileData.email
                            : ""
                        }
                      />
                      <span className="error w-100">{formErrors.email}</span>
                    </div>
                  </div>
                </>
              )}
              <div className="row g-0">
                <div className="col mw-500">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="form-control"
                    placeholder="Enter organization address"
                    maxLength={200}
                    onChange={(e) => formInputChangeHandler(e)}
                    value={
                      organizationProfileData.address
                        ? organizationProfileData.address
                        : ""
                    }
                  />
                  <span className="error w-100"></span>
                </div>
              </div>
              <div className="row g-0">
                <div className="col pr-14">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    placeholder="Enter city"
                    maxLength={50}
                    onChange={(e) => formInputChangeHandler(e)}
                    value={
                      organizationProfileData.city
                        ? organizationProfileData.city
                        : ""
                    }
                  />
                </div>
                <div className="col pl-14">
                  <label htmlFor="state_id" className="required">
                    State
                  </label>
                  <div>
                    <Select
                      name="state_id"
                      id="state_id"
                      options={
                        states?.length > 0
                          ? states.map((state) => {
                              return {
                                label: state.name,
                                value: state.id,
                              };
                            })
                          : []
                      }
                      className={`form-select custom-select state-select mt-0 ${
                        formErrors.state_id ? "error" : ""
                      }`}
                      value={
                        organizationProfileData.state_id === null ||
                        organizationProfileData.state_id === 0
                          ? ""
                          : {
                              label: stateValue,
                              value: organizationProfileData.state_id,
                            }
                      }
                      placeholder="Choose state"
                      onChange={(e) => handleStateChange(e)}
                      styles={customSelectStyle}
                      isSearchable={false}
                      isDisabled={
                        organizationProfileData.transaction_exist ? true : false
                      }
                    />
                    <span className="error w-100">{formErrors.state_id}</span>
                  </div>
                </div>
              </div>

              <div className="row g-0">
                <div className="col pr-14">
                  <label htmlFor="zip_code">Zip Code</label>
                  <input
                    type="text"
                    name="zip_code"
                    id="zip_code"
                    className="form-control"
                    placeholder="Enter zip code"
                    maxLength={15}
                    onChange={(e) => formInputChangeHandler(e)}
                    value={
                      organizationProfileData.zip_code
                        ? organizationProfileData.zip_code
                        : ""
                    }
                  />
                  <span className="error w-100"></span>
                </div>
                <div className="col pl-14">
                  <label htmlFor="phone_number">Phone Number</label>
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    className={`form-control ${
                      formErrors.phone_number ? "error" : ""
                    }`}
                    placeholder="Enter phone number"
                    maxLength={19}
                    onChange={(e) => formInputChangeHandler(e)}
                    value={
                      organizationProfileData.phone_number
                        ? organizationProfileData.phone_number
                        : ""
                    }
                  />
                  <span className="error w-100">{formErrors.phone_number}</span>
                </div>
              </div>
              <div className="row g-0">
                <div className="col pr-14">
                  <label htmlFor="country_id" className="required">
                    Country / Region
                  </label>
                  <div>
                    <Select
                      name="country_id"
                      id="country_id"
                      options={
                        countries?.length > 0
                          ? countries.map((country) => {
                              return {
                                label: country.name,
                                value: country.id,
                              };
                            })
                          : []
                      }
                      className={`form-select custom-select country-select mt-0 ${
                        formErrors.country_id ? "error" : ""
                      }`}
                      value={
                        organizationProfileData.country_id == null ||
                        organizationProfileData.country_id === 0
                          ? ""
                          : {
                              label: countries?.filter((country) => {
                                return (
                                  country.id ===
                                  organizationProfileData.country_id
                                );
                              })[0]?.name,
                              value: organizationProfileData.country_id,
                            }
                      }
                      placeholder="Choose country"
                      onChange={(e) => {
                        handleCountryChange(e);
                      }}
                      styles={RegionSelectCustomStyle}
                      isDisabled={
                        organizationProfileData.transaction_exist ? true : false
                      }
                      isSearchable={false}
                    />
                    <span className="error w-100"></span>
                  </div>
                </div>
                <div className="col pl-14">
                  <label htmlFor="currency">Base Currency</label>
                  <input
                    type="text"
                    name="currency"
                    id="currency"
                    className="form-control"
                    placeholder={organizationProfileData.currency_code}
                    maxLength={5}
                    disabled
                  />
                </div>
                {organizationProfileData.transaction_exist ? (
                  <div className="alert-msg info-alert-masg d-flex align-items-center w-100">
                    <img src={EmailInfoIcon} alt="info icon" />
                    <p className="mb-0">
                      Cannot change the country as there are transactions
                      associated to it
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="row g-0">
                <div className="col pr-14">
                  <label htmlFor="fiscal_year" className="required">
                    Fiscal year
                  </label>
                  <div>
                    <Select
                      name="fiscal_year"
                      id="fiscal_year"
                      menuPlacement={"top"}
                      menuShouldScrollIntoView={true}
                      options={
                        fiscal_year?.length > 0
                          ? fiscal_year.map((year) => {
                              return {
                                label: year.value,
                                value: year.label,
                              };
                            })
                          : []
                      }
                      className={`form-select custom-select fiscal-year-select mt-0 ${
                        formErrors.fiscal_year ? "error" : ""
                      }`}
                      value={selectedFiscalOption}
                      placeholder="Choose fiscal year"
                      onChange={(e) => handleFiscalYearChange(e)}
                      styles={customFiscalSelectStyle}
                      isSearchable={false}
                      isDisabled={
                        organizationProfileData?.transaction_exist &&
                        currentUserInfo.organization_fiscal_year !== ""
                          ? true
                          : false
                      }
                    />
                    <span className="error w-100">
                      {formErrors.fiscal_year}
                    </span>
                  </div>
                </div>
                {showGstIn ? (
                  <div className="col pl-14">
                    <label htmlFor="pan_number">PAN Number</label>
                    <input
                      type="text"
                      name="pan_number"
                      id="pan_number"
                      className={`form-control ${
                        formErrors.pan_number ? "error" : ""
                      }`}
                      placeholder="Enter PAN Number"
                      maxLength={10}
                      onChange={(e) => formInputChangeHandler(e)}
                      value={
                        organizationProfileData.pan_number
                          ? organizationProfileData.pan_number
                          : ""
                      }
                    />
                    <span className="error w-100">{formErrors.pan_number}</span>
                  </div>
                ) : (
                  <div className="col pl-14"></div>
                )}
              </div>

              <div className="row g-0">
                {showGstIn && isGstOrg ? (
                  <div className="col pr-14">
                    <label htmlFor="gst">GSTIN Number</label>
                    <input
                      type="text"
                      name="gst"
                      id="gst"
                      className={`form-control ${
                        formErrors.gstin ? "error" : ""
                      }`}
                      placeholder="Enter GSTIN Number"
                      maxLength={15}
                      onChange={(e) => formInputChangeHandler(e)}
                      disabled={true}
                      value={
                        organizationProfileData.gst
                          ? organizationProfileData.gst
                          : ""
                      }
                    />
                    <span className="error w-100">{formErrors.gstin}</span>
                  </div>
                ) : null}
                {currentUserInfo.country_name==="India" && <div className={`col col-md-6 ${showGstIn && isGstOrg ? "pl-14" : "pr-14"}`}>
                  <label htmlFor="tan_number">TAN Number</label>
                  <input
                    type="text"
                    name="tan_number"
                    id="tan_number"
                    className={`form-control ${
                      formErrors.tan_number ? "error" : ""
                    }`}
                    placeholder="Ex: PDES03028F"
                    maxLength={10}
                    onChange={(e) => formInputChangeHandler(e)}
                    value={
                      organizationProfileData.tan_number
                        ? organizationProfileData.tan_number
                        : ""
                    }
                  />
                  <span className="error w-100">{formErrors.tan_number}</span>
                </div>}
              </div>
              <Container className="row g-0">
                <FormFieldContainer
                  wrapperClass="col pr-14"
                  label={"Date format"}
                  // isRequired={fields.mandatory_field}
                >
                  {/* <Select
                    name="date_format"
                    id="date_format"
                    options={DateFormat}
                    className={`currency-select form-select custom-select selectpicker ${props.className}`}
                    value={selectedDateFormat}
                    onChange={(e) => {
                      if (e?.value) {
                        setSelectedDateFormat({
                          label: e.label,
                          value: e.value,
                        });
                      }
                    }}
                    styles={SelectCustomStyleCustomer}
                    isSearchable={false}
                    menuPlacement={"auto"}
                  /> */}

                  <FormSelect
                    name="date_format"
                    id="date_format"
                    value={selectedDateFormat}
                    options={DateFormat}
                    selectClass={"custom-select terms-select terms_name"}
                    // isDisabled={!values.customer_id ? true : false}
                    isSearchable={true}
                    maxMenuHeight={200}
                    autoFocus={false}
                    placeholder={"Select date format"}
                    noOptionsMessage={"No item found"}
                    controlHeight={"41px"}
                    onChange={(e) => {
                      if (e?.value) {
                        setSelectedDateFormat({
                          label: e.label,
                          value: String(e.value),
                        });
                      }
                    }}
                    borderRadius="6px"
                    menuPlacement={"auto"}
                  />
                </FormFieldContainer>
              </Container>
            </div>
          </section>
        </div>
        <section className="form-button-section">
          <div className="alert-msg info-alert-masg d-flex align-items-center w-100">
            <img src={EmailInfoIcon} alt="info icon" />
            <p className="mb-0">
              Changes to the logo and details will be reflected in the new
              transactions.
            </p>
          </div>
          <div
            className="button-fields form-button-wrapper"
            id="form-btn-org-profile"
          >
            <button
              className="save-button"
              onClick={(e) => UpdateOrganizationProfile(e)}
              type="button"
            >
              Save
            </button>
            {redirectTo === "invoice" ? (
              <button
                className="text-decoration-none cancel-button close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => {
                  if (!isSubmit) {
                    setOrganizationProfileData(initialOrganizationProfileData);
                  }
                  props.handleClose && props.handleClose();
                }}
              >
                Cancel
              </button>
            ) : null}
          </div>
        </section>
      </form>
      {isLoading && <Loader />}
    </>
  );
}

export default OrganizationProfile;
