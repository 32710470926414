import { useEffect, useState } from "react";
import useCommonData from "../../../../../../hooks/useCommon";
import { ObjectType } from "../../../../../../../types";
import { getReportingTagDropdown } from "../../../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { Container } from "../../../../htmlTags/Container";
import { TagsOptionsSelectionPopup } from "../../TagOptionsSelectionPopup";
import { Span } from "../../../../htmlTags/Span";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Divider from "@mui/material/Divider";

import "./TagCategoryMapping.css";
import { colors } from "../../../../../../constants/colors";
import { CategoryMappingProps } from "../../../types";
import { useParams } from "react-router-dom";
import { ReportingTagFormModal } from "../../../../../../home/reportingTags/reportingTagForm/ReportingTagFormModal";
export const TagCategoryMapping = (props: CategoryMappingProps) => {
  const { invoiceId, billId } = useParams();
  const { dispatch, currentUserInfo } = useCommonData();
  const [tags, setTags] = useState<ObjectType[]>([]);
  useEffect(() => {
    getTags();
  }, []);
  const getTags = async () => {
    const responseAction = await dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      if (props.isTagFilteringPresent && invoiceId && props.tagFilterObject) {
        let keys = Object.keys(props.tagFilterObject[invoiceId || 0]);
        let values = Object.values(props.tagFilterObject[invoiceId || 0]);
        let subTagOptions = values
          .flatMap((value) => value.options)
          .map((option) => {
            return option.id;
          });
        let filteredTags = responseAction.payload
          .filter((tag: ObjectType) => keys.includes(String(tag.id)))
          .map((filterTag: ObjectType) => {
            return {
              ...filterTag,
              options: filterTag.options.filter((option: ObjectType) =>
                subTagOptions.includes(Number(option.id))
              ),
            };
          });

        setTags(filteredTags);
      } else if (
        billId &&
        props.isTagFilteringPresent &&
        props.tagFilterObject
      ) {
        let keys = Object.keys(props.tagFilterObject[billId || 0]);
        let values = Object.values(props.tagFilterObject[billId || 0]);
        let subTagOptions = values
          .flatMap((value) => value.options)
          .map((option) => {
            return option.id;
          });
        let filteredTags = responseAction.payload
          .filter((tag: ObjectType) => keys.includes(String(tag.id)))
          .map((filterTag: ObjectType) => {
            return {
              ...filterTag,
              options: filterTag.options.filter((option: ObjectType) =>
                subTagOptions.includes(Number(option.id))
              ),
            };
          });

        setTags(filteredTags);
      } else {
        setTags(responseAction.payload);
      }
    }
  };
  const checkIsDisabled = (parentTagId: number) => {
    if (props.isAddNewTag && props.selectedParentTagIds.includes(parentTagId)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Container className="tag-category-mapping-wrapper">
      <Container className="tags-options-mapping-header-wrapper">
        <Span>Select Category</Span>
      </Container>

      <Divider sx={{ color: colors.battleshipGrey }} />
      <Container className="tag-category-mapping-categories-wrapper">
        {!["Payment Received", "Payment Made"].includes(props.module) && (
          <ReportingTagFormModal
            setReportingTagsList={async () => {
              props.getReportingTagsList &&
                (await props.getReportingTagsList());
              getTags();
            }}
            isFromTransaction={true}
            buttonClass="blue-text-btn no-appearance"
            wrapperClass="button-at-start mb-2"
          />
        )}
        {tags.map((tag) => {
          return (
            <TagsOptionsSelectionPopup
              tagId={tag.id}
              selectedIds={props.selectedIds}
              options={tag.options}
              isDisabled={checkIsDisabled(tag.id)}
              buttonContent={
                <Container
                  tabIndex={0}
                  className={`${
                    checkIsDisabled(tag.id)
                      ? "tag-category-mapping-disabled-category"
                      : "tag-category-mapping-category-name "
                  }`}
                >
                  <DragIndicatorIcon sx={{ fontSize: "17px" }} />{" "}
                  <Span>{tag.name}</Span>
                </Container>
              }
              parentTag={{
                id: tag.id,
                name: tag.name,
              }}
              isAddNewTag={props.isAddNewTag}
              getSelectedIds={props.getSelectedIds}
              categoryPopupClose={props.handleClose}
              isTagFilteringPresent={props.isTagFilteringPresent}
              tagFilterObject={props.tagFilterObject}
            />
          );
        })}
      </Container>
    </Container>
  );
};
