import {
  CreateContactError,
  CreateContactValues,
  CreateCustomerValues,
  ObjectType,
} from "../../../../../types";

/**
 * Validate form values
 */
export const validateCustomerForm = (
  values: CreateCustomerValues,
  isGstOrg: boolean,
  isVatOrg?: boolean
) => {
  const errors: ObjectType = {};
  let count = 0;
  if (!values.customer_display_name.trim()) {
    errors.customer_display_name = "Please enter customer display name";
  }
  if (!values.short_code || values.short_code === "") {
    errors.short_code = "Please enter customer short code";
  }
  if (!values.currency_id) {
    errors.currency_id = "Please choose currency";
    count++;
  }
  const regex = /^(?:\w+[-.])*\w{1,64}@(?:\w+[-.])*\w+\.\w{2,3}$/i;
  if (values.customer_email && !regex.test(values.customer_email)) {
    errors.customer_email = "Please enter valid email address";
  }
  const phoneRegex = /^[\+]?([0-9]){6,18}$/;
  if (
    values.customer_phone &&
    !phoneRegex.test(values.customer_phone.toString())
  ) {
    errors.customer_phone = "Please enter valid phone number";
  }
  const panRegex = /([A-Z]){3}([PCHABGJLFT]){1}([A-Z]){1}([0-9]){4}([A-Z]){1}$/;
  if (values.pan_number && !panRegex.test(values.pan_number)) {
    errors.pan_number = "Please enter valid PAN number";
    count++;
  }
  const gstInRegex =
    /([0-9]){2}([A-Z]){3}([PCHABGJLFT]){1}([A-Z]){1}([0-9]){4}([A-Z]){1}([A-Z1-9]){1}Z([A-Z0-9]){1}$/;
  if (values.gstin && !gstInRegex.test(values.gstin)) {
    errors.gstin = "Please enter valid GSTIN";
    count++;
  }
  if (isGstOrg && values.gst_registration_type === "") {
    errors.gst_registration_type = "Please choose GST registration type";
    count++;
  }
  if (
    isGstOrg &&
    values.gst_registration_type &&
    (values.gst_registration_type === "Special Economic Zone(SEZ)" ||
      values.gst_registration_type === "SEZ Developer" ||
      values.gst_registration_type === "GST registered - regular" ||
      values.gst_registration_type === "GST registered - composition" ||
      values.gst_registration_type === "Deemed exports - EOUs, STPs, EHTPs") &&
    !values.gstin
  ) {
    errors.gstin = "Please enter GSTIN";
    count++;
  }
  if(isVatOrg && values?.tax_registration_number && values.tax_registration_number !== "") {
    if(!/^\d{15}$/.test(`${values?.tax_registration_number}`)) {
      errors.tax_registration_number = "Please enter valid TRN"
    }
  }
  if (
    values.opening_balance &&
    values.opening_balance?.toString().includes("-") &&
    values.opening_balance?.toString().length < 2
  ) {
    errors.opening_balance = "Please enter valid opening balance";
    count++;
  }
  if (!values.tax_preference) {
    errors.tax_preference = "Please select tax preference";
    count++;
  }
  if (values.tax_preference === "NON_TAXABLE") {
    if (!values.exemption_reason_id) {
      errors.exemption_reason = "Please select a reason";
      count++;
    }
  }
  // setOtherErrorCount(count);
  return { errors, count };
};

/**
 * Validate contact person form values
 */
export const validateContactPerson = (values: CreateContactValues) => {
  let flag = false;
  const errors: ObjectType = {};
  const regex = /^(?:\w+[-.])*\w{1,64}@(?:\w+[-.])*\w+\.\w{2,3}$/i;
  if (values.email && !regex.test(values.email)) {
    errors.email = "Please enter valid email address";
    flag = true;
  }
  const phoneRegex = /^[\+]?([0-9]){6,18}$/;
  if (values.phone && !phoneRegex.test(values.phone.toString())) {
    errors.phone = "Please enter valid phone number";
    flag = true;
  }
  if (flag) return errors;
  else return false;
};

/**
 * Checking contact form error for empty object
 */
export const checkContactFormError = (
  contactErrorArray: Array<CreateContactError>
) => {
  let count = 0;
  contactErrorArray.map((obj) => {
    if (
      (obj?.email && obj.email.length > 1) ||
      (obj?.phone &&
        (typeof obj.phone === "string"
          ? obj.phone.length > 1
            ? true
            : false
          : false))
    ) {
      count = count + 1;
    }
  });
  if (count === 0) {
    return false;
  } else {
    return true;
  }
};

export const validateSalesDefaultForm = (values: ObjectType) => {
  let errors: ObjectType = {};
  if (
    values.discount.discountType === "Percentage" &&
    Number(values.discount.discount) > 100
  ) {
    errors.discount = "Discount percentage cannot be more than 100%.";
  } else if (
    values.discount.account.id !== null &&
    values.discount.account.id !== "" &&
    !values.discount.discount
  ) {
    errors.discount = "Please enter discount value.";
  } else if (!values.discount.account.id && values.discount.discount && !values.exclude_discount_accounting) {
    errors.discount = "Please choose a discount account.";
  } else {
    errors.discount && delete errors.discount;
  }
  return errors;
};
