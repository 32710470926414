import { useState } from "react";
import { Grid } from "@mui/material";
import RegenerateInvoice from "../regenerateInvoice/RegenerateInvoice";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import "./tableInfo.css";

type Props = {
  currentCycleStartDate: string;
  currentCycleEndDate: string;
  invoiceRegenerationEnabled?: boolean;
  subscriptionId: string | undefined;
  overviewSubscriptionData?: any;
  recurringInvoiceDetail: () => void;
  fetchChildInvoiceList: (recurring_invoice_id: number) => void;
};

export default function RegenerateInvoiceInfo(props: Props) {
  const [openModal, setOpenModal] = useState(false);

  const onRegenerateInvoice = () => {
    setOpenModal(true);
  };

  const closeAllModals = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid container className="info-container">
        <div className="regenerate-invoice-text-and-button">
          <div className="info-content">
            <img src={info} id="message-img" alt="info-icon" />
            <span className="info-text">
              There is no current term charge for this subscription or the
              current term invoice is Voided. You can regenerate an invoice with
              changes/additions you make.
            </span>
          </div>{" "}
          <button className="regenerate-button" onClick={onRegenerateInvoice}>
            Regenerate invoice
          </button>
        </div>
      </Grid>
      {openModal && (
        <RegenerateInvoice
          currentCycleStartDate={props.currentCycleStartDate}
          currentCycleEndDate={props.currentCycleEndDate}
          handleClose={closeAllModals}
          invoiceRegenerationEnabled={props.invoiceRegenerationEnabled}
          subscriptionId={props.subscriptionId}
          overviewSubscriptionData={props.overviewSubscriptionData}
          refreshSubscriptionDetails={() => props.recurringInvoiceDetail()}
          fetchChildInvoiceList={() =>
            props.fetchChildInvoiceList(
              props.overviewSubscriptionData.recurring_invoice_id
            )
          }
        />
      )}
    </>
  );
}
