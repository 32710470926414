import React, { useMemo } from "react";
import { getOrganizationCurrencies } from "../../../../../common/commonSlice";
import { PrimaryButton } from "../../../../../common/components/button/PrimaryButton";
import { SecondaryButton } from "../../../../../common/components/button/SecondaryButton";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Form } from "../../../../../common/components/htmlTags/Form";
import { useCommonData } from "../../../../paymentsReceived/components/paymentReceivedForm/imports";
import "./BudgetVarianceReportFilter.css";
import { BudgetDropDown } from "./budgetDropdown/BudgetDropdown";
import { CurrencyDropdown } from "./currencyDropdown/CurrencyDropdown";
import { OrganizationDropDown } from "./organizationDropdown/OrganizationDropdownReport";
import { VarianceReportDateRangePicker } from "./varianceReportDateRangePicker/VarianceReportDateRangePicker";
import dayjs from "dayjs";
type Props = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleReset: (e: React.FormEvent<HTMLFormElement>) => void;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  errors: any;
  touched: any;
  setValues: (values: any) => void;
  setErrors: (errors: any) => void;
  setTouched: (touched: any) => void;
};
export const BudgetVarianceReportFilter = ({
  handleSubmit,
  handleReset,
  setFieldValue,
  values,
  errors,
  setValues,
  setErrors,
  setTouched,
  touched,
}: Props) => {
  const { currentUserInfo, dispatch } = useCommonData();

  const getFilterCurrencies = async (selectedOrgId: number) => {
    const responseAction = await dispatch(
      getOrganizationCurrencies({
        selectedOrgId: selectedOrgId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (response) {
        setFieldValue("currency_ids", response);
      }
    }
  };
  useMemo(() => {
    setFieldValue("organization_id", currentUserInfo.organization_id);
    setFieldValue("range", "This Month");
    setFieldValue("start_date", dayjs().startOf("month").format("YYYY-MM-DD"));
    setFieldValue("end_date", dayjs().endOf("month").format("YYYY-MM-DD"));
    getFilterCurrencies(currentUserInfo.organization_id);
  }, [currentUserInfo.organization_id]);

  return (
    <Form onSubmit={handleSubmit}>
      <Container
        className={`budget-variance-report-filter-container ${
          values.tableData.length > 0
            ? "budget-variance-report-filter-container-with-border"
            : ""
        }`}
      >
        <Container className="filter-values-container">
          <VarianceReportDateRangePicker
            handleDateRange={(start, end, range) => {
              setValues({
                ...values,
                start_date: start,
                end_date: end,
                range: range,
              });
            }}
            dateObject={{
              startDate: values.start_date,
              endDate: values.end_date,
              range: values.range,
            }}
            error={errors.range || ""}
          />
          <OrganizationDropDown
            selectedOrg={values.organization_id}
            handleOrgChange={(newValue, currencyId) => {
              setFieldValue("organization_id", newValue.value);
              setFieldValue("currency_id", currencyId);
              setFieldValue("budget_id", null);
              getFilterCurrencies(newValue.value);
            }}
            error={errors.organization_id || ""}
            orgDisable={false}
          />
          <BudgetDropDown
            budgetId={values.budget_id || 0}
            handleBudgetChange={(newValue: any) => {
              setFieldValue("budget_id", newValue.value);
            }}
            isDisabled={!values.organization_id}
            orgId={values.organization_id}
            error={
              errors.budget_id && touched.budget_id ? errors.budget_id : ""
            }
            setTouched={setTouched}
            touched={touched}
          />
          <CurrencyDropdown
            currencyId={values.currency_id}
            currencyCode={values.currency_code}
            handleCurrencyChange={(e: any) => {
              setValues({
                ...values,
                currency_id: e.value,
                currency_code: e.label,
              });
            }}
            currencyIds={values.currency_ids}
            error={errors.currency_id || ""}
          />
        </Container>
        <Container className="filter-buttons-container">
          <PrimaryButton type="submit" isDisabled={false}>
            Apply
          </PrimaryButton>
          <SecondaryButton
            type="button"
            buttonContent="Reset"
            isDisabled={false}
            onClick={(e) => {
              getFilterCurrencies(currentUserInfo.organization_id);
              setTouched({
                budget_id: false,
                budget_name: false,
              });
              e.preventDefault();
              setValues({
                budget_id: null,
                budget_name: "",
                organization_id: currentUserInfo.organization_id,
                currency_id: currentUserInfo.organization_currency,
                currency_code: currentUserInfo.currency_code,
                currency_ids: [],
                start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
                end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
                range: "This Month",
                data: {},
                tableData: [],
              });
            }}
          />
        </Container>
      </Container>
    </Form>
  );
};
