import { useRef, useState } from "react";
import { AddFilter } from "../../../../common/components/reportCustomizationModal/customizeReportATandBS/addFilterComponent";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import ExportEmailModal from "../exportEmailModal/exportEmailModal";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { exportInvoice } from "../../exportDataSlice";
import { currentUserSelector } from "../../../../common/commonSlice";
import { toast } from "react-toastify";

type FilterType = {
  field: string;
  comparator: string;
  value: string | string[];
};
function InvoiceFilterComponent(props: any) {
  const initialFilterValue = [
    {
      field: "",
      comparator: "",
      value: "",
    },
  ];
  const [filters, setFilters] = useState<FilterType[]>(initialFilterValue);
  const [filterError, setFilterError] = useState("");
  const [filterErrorIndices, setFilterErrorIndices] = useState<number[]>([]);
  const addFilterRef = useRef<any>();
  const openEmailRef = useRef<any>();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);

  const addFilter = () => {
    let updatedFilter = [...filters];
    updatedFilter.push({
      field: "",
      comparator: "",
      value: "",
    });
    setFilters([...updatedFilter]);
  };
  const deleteFilter = (index: number) => {
    setFilterErrorIndices([]);
    let prevFilter = [...filters];
    prevFilter.splice(index, 1);
    setFilters([...prevFilter]);
  };
  const getFilterValues = async (
    data: {
      field: string;
      comparator: string;
      value: string | string[];
    },
    index: number
  ) => {
    let prevFilters = [...filters];
    prevFilters[index] = await data;
    setFilters([...prevFilters]);
  };
  const validateAdvancedFilter = () => {
    let tempFilters = filters || [];

    return tempFilters
      .map((obj, index) => ({ obj, index })) // Create an array of objects with their corresponding indices
      .filter(({ obj }) => {
        // Skip validation for comparator if field === "Is Recurring"
        if (obj.field === "Is Recurring") {
          return Object.entries(obj)
            .filter(([key]) => key !== "comparator") // Exclude 'comparator' from validation
            .some(([, value]) => value === "");
        }
        // Regular validation for all fields
        return Object.values(obj).some((value) => value === "");
      })
      .map(({ index }) => index);
  };
  const handleModalOpen = () => {
    setFilterErrorIndices(validateAdvancedFilter());
    if (validateAdvancedFilter().length > 0) {
      setFilterError(
        "Please fill the advanced filter completely or delete unused rows!"
      );
    } else {
      setFilterError("");
      openEmailRef.current?.openAlertModal &&
        openEmailRef.current.openAlertModal();
    }
  };
  const handleSubmit = async (emailList: string[]) => {
    let payload: any = {
      is_customer_ids: filters.some(
        (entry) => entry.field === "Customer" && entry.comparator === "is"
      ),
      customer_ids:
        filters.find((entry) => entry.field === "Customer")?.value || [],
      is_invoice_status: filters.some(
        (entry) => entry.field === "Invoice Status" && entry.comparator === "is"
      ),
      invoice_status:
        filters.find((entry) => entry.field === "Invoice Status")?.value || [],
      email_list: emailList,
    };
    const isRecurring =
      filters.find((entry) => entry.field === "Is Recurring")?.value || [];
    if (isRecurring?.length > 0) {
      payload = {
        ...payload,
        is_recurring_invoice:
          filters.find((entry) => entry.field === "Is Recurring")?.value[0] ===
          "Yes"
            ? true
            : false,
      };
    }
    const exportResponseAction = await dispatch(
      exportInvoice({
        payload: payload,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (exportResponseAction.payload) {
      toast.success(exportResponseAction.payload.msg, {
        toastId: "invoice-export-success",
        closeButton: false,
        position: "top-center",
      });
      props.onCloseModal();
    }
  };
  return (
    <>
      <div className="subscription-export-filter-modal-wrapper">
        {filters &&
          filters?.map((filter, index) => (
            <div key={index}>
              <AddFilter
                key={index}
                value={filter}
                onDelete={() => deleteFilter(index)}
                index={index}
                getFilterValues={getFilterValues}
                ref={addFilterRef}
                selectedFields={filters.map((filter) => {
                  return filter.field;
                })}
                isError={filterErrorIndices.includes(index)}
                moduleType="Invoice Export"
              />
            </div>
          ))}
        <span className="error">{filterError}</span>
        <div className="d-flex align-items-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              addFilter();
            }}
            className="no-appearance "
            // disabled={filters && filters.length > 2}
          >
            <span className="w-100 d-flex  add-new m-0 ">Add Filter</span>
          </button>
        </div>
        <div className="customize_report_btn_group mt-3">
          <SaveButton
            type="button"
            buttonContent="Export"
            onClick={handleModalOpen}
            isDisabled={false}
          />
          <SecondaryButton
            type={"button"}
            buttonContent="Cancel"
            onClick={() => {
              setFilters(initialFilterValue);
              props.onCloseModal();
            }}
            isDisabled={false}
          />
        </div>
      </div>
      <ExportEmailModal
        message=""
        modalTitle="Export"
        isCloseButtonEnabled={true}
        isSubmitEnabled={true}
        openRef={openEmailRef}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
export default InvoiceFilterComponent;
