import React, { ReactElement, useId } from "react";
import "../../../../../../ColorVariables.css";
import "./Ul.css";
interface Props extends React.HTMLAttributes<HTMLUListElement> {
  id?: string | undefined;
  wrapperClass?: string;
  children: ReactElement;
};
const List = (props: Props) => {
  const id = useId();
  return (
    <>
      <ul
        id={props.id ? props.id : id}
        className={`list-ul ${props.wrapperClass ? props.wrapperClass : ""}`}
      >
        {props.children}
      </ul>
    </>
  );
};

export default List;
