import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { deleteAddonDetails, deletePricingData } from "../addonSlice";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteAddonId: number;
  deleteAddonName: string;
  deleteActionType: string;
  refreshAddon: (action: string) => void;
  organizationId: number;
};
const AddonDelete = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const addonModalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteRoleResponseAction = await dispatch(
      deleteAddonDetails({
        deleteAddonId: props.deleteAddonId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length && !("error" in deleteRoleResponse)) {
        props.refreshAddon(props.deleteActionType);
        $(".close-btn").click();
        toast.success("Addon deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
};


const pricingModalSubmitHandler = async () => {
  $("#delete-button").addClass("customer-form-section-disable");
  const deleteRoleResponseAction = await dispatch(
    deletePricingData({
      deletePricingId: props.deleteAddonId,
      orgId: props.organizationId,
    })
  );
  $("#delete-button").removeClass("customer-form-section-disable");
  if (deleteRoleResponseAction.payload) {
    const deleteRoleResponse = deleteRoleResponseAction.payload;
    if (Object.keys(deleteRoleResponse).length && !("error" in deleteRoleResponse)) {
      props.refreshAddon(props.deleteActionType);
      $(".close-btn").click();
      toast.success("Price deleted successfully!", {
        toastId: "role-delete-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in deleteRoleResponse) {
      $(".close-btn").click();
    }
  }

};
  return (
    <DeleteModal
      heading={`Delete ${props.deleteActionType}!`}
      subHeading={`Are you sure you want to delete the ${props.deleteActionType.toLowerCase()} ${props.deleteActionType === 'Price' ? 'for' : ''}`}
      transactionName={joinTransactionNumber(props.deleteAddonName)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={ props.deleteActionType === 'Addon' ? addonModalSubmitHandler : pricingModalSubmitHandler}
    />
  );
};
export default AddonDelete;