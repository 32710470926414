import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import { ObjectType, Transactionlimit } from "../../../../../types";
import { SystemVoucherDataList } from "../types";
import { getTransactionLimitStatus } from "../../systemVoucherSlice";
import {
  currentUserSelector,
  fiscalYearFilterSelector,
} from "../../../../common/commonSlice";
import {
  filterAppliedState,
  listFilterSelector,
  setListEndCount,
  setListFilters,
  setListStartCount,
} from "../../../../../appSlice";
import Pagination from "../../../../common/components/table/Pagination";
import ListHeader from "../../../../common/components/list/ListHeader";
import useListData from "../../../../hooks/useListData";
import NotificationModal from "../../../../common/components/NotificationModal";
import SystemVoucherTableContainer from "./systemVoucherTable/SystemVoucherTableContainer";
import "../../../../common/styles/ListTable.css";

type SelectedFilterType = {
  Organizations: number[];
  Status: number[];
  SystemVoucherType: number[];
};

const SystemVoucherList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterAppliedState = useAppSelector(filterAppliedState);
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { systemVoucherPermission } = usePermissionHandler();
  const [systemVoucherData, setSystemVoucherData] = useState<
    SystemVoucherDataList[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSystemVouchers, setTotalSystemVouchers] = useState(0);
  const [deleteSystemVoucherItemId, setDeleteSystemVoucherItemId] =
    useState("");
  const [deleteSystemVoucherId, setDeleteSystemVoucherId] = useState(-1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const { page, pageCount, itemsPerPage, totalList, dateSortOrder } =
    useListData();

  useEffect(() => {
    if (!Object.keys(selectedFilterData).length) {
      initializeFilter();
    }
  }, []);

  useEffect(() => {
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  const setStartCount = (param: number) => {
    dispatch(setListStartCount(param));
  };
  const setEndCount = (param: number) => {
    dispatch(setListEndCount(param));
  };
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalSystemVouchers;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const deleteListData = (id: number, listNum: string) => {
    setDeleteSystemVoucherId(id);
    setDeleteSystemVoucherItemId(listNum);
  };

  const initializeFilter = () => {
    setIsFilterApplied(false);
    let filterData: SelectedFilterType = {
      Organizations: [],
      Status: [],
      SystemVoucherType: [],
    };
    Object.keys(selectedFilters).map((filter) => {
      filterData = {
        ...filterData,
        [Object.keys(filter)[0]]: Object.values(filter)[0],
      };
    });
    dispatch(setListFilters(filterData));
  };

  const handleApplyFilter = () => {
    setIsFilterApplied(true);
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="System Voucher"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="New System Voucher"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/system-voucher/create");
          }}
          haveFilter={true}
          filterType="SV"
          applyFilter={handleApplyFilter}
          roles={systemVoucherPermission}
          ref={filterRef}
          module="SystemVoucher"
          isFiscalYearFilterRequired={false}
          isSystemVoucher={true}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body-wrapper">
            <SystemVoucherTableContainer
              transactionLimit={transactionLimit}
              initializeFilter={initializeFilter}
              isFilterApplied={isFilterApplied}
              deleteListData={deleteListData}
              ref={listRef}
            />
            <div className="card-footer clear-both">
              {totalList > itemsPerPage ? (
                <Pagination
                  pageCount={pageCount}
                  page={page}
                  handlePageClick={handlePageClick}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};

export default SystemVoucherList;
