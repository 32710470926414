import {
  CreateVendorValues,
  EditVendorValues,
  ObjectType,
} from "../../../../types";
import { initialVendorValues } from "../components/vendorForm/StateInitialization";

export const vendorCreatePayloadRefiner = (
  payload: CreateVendorValues,
  defaultConsumerValues: ObjectType
) => {
  const {
    purchase_account_id,
    discount,
    discount_account_id,
    discount_type,
    payment_term_id,
    vendor_email,
    vendor_phone,
    pan_number,
    opening_balance,
    gst_registration_type,
    gstin,
    ...rest
  } = payload;
  return (
    {
      ...rest,
      vendor_email: vendor_email ? vendor_email : null,
      vendor_phone: vendor_phone ? vendor_phone : null,
      pan_number: pan_number ? pan_number : null,
      opening_balance: opening_balance ? opening_balance : 0,
      purchase_account_id: defaultConsumerValues.account.id || null,
      discount: defaultConsumerValues.discount.discount || 0,
      discount_account_id: defaultConsumerValues.discount.account.id || null,
      discount_type: defaultConsumerValues.discount.discountType || "Absolute",
      payment_term_id: defaultConsumerValues.paymentTerm.id,
      gst_registration_type: gst_registration_type
        ? gst_registration_type
        : null,
      gstin: gstin ? gstin : null,
      exclude_discount_accounting:
        defaultConsumerValues.exclude_discount_accounting
          ? defaultConsumerValues.exclude_discount_accounting
          : false,
    } || initialVendorValues
  );
};
export const vendorEditPayloadRefiner = (
  payload: EditVendorValues,
  defaultConsumerValues: ObjectType
) => {
  const {
    purchase_account_id,
    discount,
    discount_account_id,
    discount_type,
    payment_term_id,
    vendor_email,
    vendor_phone,
    pan_number,
    opening_balance,
    gst_registration_type,
    ...rest
  } = payload;
  return (
    {
      ...rest,
      vendor_email: vendor_email ? vendor_email : null,
      vendor_phone: vendor_phone ? vendor_phone : null,
      pan_number: pan_number ? pan_number : null,
      opening_balance: opening_balance ? opening_balance : 0,
      purchase_account_id: defaultConsumerValues.account.id || null,
      discount: defaultConsumerValues.discount.discount || 0,
      discount_account_id: defaultConsumerValues.discount.account.id || null,
      discount_type: defaultConsumerValues.discount.discountType || "Absolute",
      payment_term_id: defaultConsumerValues.paymentTerm.id,
      gst_registration_type: gst_registration_type
        ? gst_registration_type
        : null,
      exclude_discount_accounting:
        defaultConsumerValues.exclude_discount_accounting
          ? defaultConsumerValues.exclude_discount_accounting
          : false,
    } || initialVendorValues
  );
};
