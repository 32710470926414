import { FormikProps } from "formik";
import InfoTooltip from "../../../../../../common/components/InfoTooltip";
import { message } from "../../../../../../constants/messages";
import "../../SystemVoucherForm.css";

type Props = {
  formik: FormikProps<any>;
};
export const SystemVoucherType = (props: Props) => {
  return (
    <div className="col-12 col-lg-4 mw-325">
      <label htmlFor="jv_type" className="jv-type-label">
        Journal Type
      </label>
      <br />
      <div className="form-check jv-type-check-box-wrapper">
        <input
          className="form-check-input"
          type="checkbox"
          id={`check-jv`}
          name={`tax_item_ids`}
          value={""}
          disabled={!props.formik.values.currency_id}
          checked={
            props.formik.values.is_cash_based
              ? props.formik.values.is_cash_based
              : false
          }
          onChange={(e) => {
            if (e.target.checked) {
              props.formik.setFieldValue("is_cash_based", e.target.checked);
            } else if (e.target.checked === false) {
              props.formik.setFieldValue("is_cash_based", false);
            }
          }}
        />
        <label className="form-check-label" htmlFor={`check-jv`}>
          Cash based journal
        </label>
        <InfoTooltip title={message().cashBasedSVInfo} placement="right" />
      </div>
    </div>
  );
};
