import React, { useState } from "react";
import { OptionProps } from "react-select";

interface CustomOptionProps extends OptionProps<any> {
  className?: string;
  name: string;
}

const CustomOption: React.FC<CustomOptionProps> = (props) => {
  const { innerRef, innerProps, isFocused, isSelected, children } = props;
  const [focused, setFocused] = useState(isFocused);

  const handleMouseEnter = () => {
    setFocused(true);
  };

  const handleMouseLeave = () => {
    setFocused(false);
  };
  // const { styles } = props.selectProps;

  // const optionStyles = styles.option ? styles.option({}, props) : {};

  return (
    <div
      ref={innerRef}
      className={`select-option ${props.className || ""} ${
        isFocused || focused ? "select-option-focused" : ""
      } ${isSelected ? "select-option-selected" : ""}`}
      // style={optionStyles as React.CSSProperties}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...innerProps}
    >
      <div>{children}</div>
      {props.name === "customer" ? (
        <div className="additional-text">{props.data.import_id}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomOption;
