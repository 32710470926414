import dayjs from "dayjs";
import { useFormik } from "formik";
import { setLoaderState } from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import { getBudgetVarianceReport } from "../../ReportSlice";
import { budgetVarianceReportFilterSchema } from "../components/budgetVarianceReportFilter/filterValidation";
import { DataInput } from "../types";
import { processData } from "../utils/dataProcessor";
export const useBudgetVarianceReport = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const {
    handleChange,
    handleReset,
    handleSubmit,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
  } = useFormik({
    initialValues: {
      budget_id: null,
      budget_name: "",
      organization_id: currentUserInfo.organization_id,
      currency_id: currentUserInfo.organization_currency,
      currency_code: currentUserInfo.currency_code,
      currency_ids: [],
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      range: "This Month",
      data: {},
      tableData: [],
    },
    validationSchema: budgetVarianceReportFilterSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const { budget_name, currency_code, currency_ids, range, data, ...rest } =
        values;
      const responseAction = await dispatch(
        getBudgetVarianceReport({
          filterValues: { ...rest, budget_id: Number(rest.budget_id) },
          orgId: currentUserInfo.organization_id,
        })
      );
      const response = responseAction.payload;
      if (response && Object.keys(response).length && !("error" in response)) {
        setFieldValue("data", response);
        setFieldValue("tableData", processData(response as unknown as DataInput));
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });

  return {
    handleChange,
    handleReset,
    handleSubmit,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
  };
};
