import { Container } from "../../../common/components/htmlTags/Container";
import "../ExportData.css"
import ExportFilterSideModal from "./FilterSideModal";


function ExportData() {
    return(
        <Container className="export-data-wrapper">
            <Container>
                <h1>Export data</h1>
            </Container>
            <Container className="export-module-wrapper">
                <Container>
                    <h2>Subscriptions</h2>
                </Container>
                <Container>
                    <p>Export a list of Subscriptions including the customer, plan information, billing address (in zip format with a single CSV file)</p>
                </Container>
                <Container>
                    <ExportFilterSideModal moduleType={"Subscription"} />
                </Container>
            </Container>
            <Container className="export-module-wrapper">
                <Container>
                    <h2>Invoices</h2>
                </Container>
                <Container>
                    <p>Export a list of Invoices including the customer, line-item information, and related transactions (in zip format with a single CSV file)</p>
                </Container>
                <Container>
                    <ExportFilterSideModal moduleType={"Invoice"} />
                </Container>
            </Container>
        </Container>
    )
}

export default ExportData;