import React from "react";
import { ErrorImg } from "../../../assets/images";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getProfileFormatedDate } from "../../../helpers/helper";
import { DeleteIcon } from "./customSvgIcons/deleteIcon";
import { colors } from "../../constants/colors";
import useCommonData from "../../hooks/useCommon";

type Props = {
  onClick: () => void;
  className: string;
  deleteIcon: boolean;
  isMutable: boolean;
  isLocked: boolean;
  lockDate: string;
  name: string;
  assignedUserCount?: number;
  iconColor?: string;
  childInvoiceListData?: any;
  child_invoice_exists?: string | boolean;
  isFiscalClosed?: boolean;
  isTransactionExist?: boolean;
  planExistsSubscription?: boolean;
  isChildInvoice?: boolean;
  modalType?: string;
  isInvoiceAssociated?: boolean;
  isJvGenerated?: boolean;
  isBillAssociated?: boolean;
  isSubscriptionAssociated?: boolean;
};
export default function Delete(props: Props) {
  const { currentUserInfo } = useCommonData();
  return (
    <Link
      className={props.className}
      style={{ textDecoration: "none" }}
      to="#"
      data-bs-toggle={
        props?.isTransactionExist ||
        (props.child_invoice_exists &&
          props.child_invoice_exists == "child_invoice_exists")
          ? ""
          : "modal"
      }
      data-bs-target={
        props.isMutable &&
        !props.isLocked &&
        !props.assignedUserCount &&
        !props.isFiscalClosed &&
        !props.isInvoiceAssociated &&
        !props.isJvGenerated &&
        !props.isBillAssociated
          ? props.modalType === "unbilledCharges"
            ? "#deleteUnbilledMessageModal"
            : "#deleteMessageModal"
          : !props.isMutable && !props.isLocked
          ? "#notificationPopupModal"
          : props.assignedUserCount
          ? "#deleteConfMessageModal"
          : ""
      }
      onClick={(e) => {
        e.preventDefault();
        if (
          props.child_invoice_exists &&
          props.child_invoice_exists == "child_invoice_exists"
        ) {
          toast.error(
            `Unable to delete ${props.name}, as there are ${
              props.isChildInvoice ? "" : "child"
            } invoices associated with it!`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `bill-delete-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else if (props?.isTransactionExist) {
          if (props?.planExistsSubscription) {
            toast.error(
              `Unable to delete the ${props.name.toLocaleLowerCase()}. The ${props.name.toLocaleLowerCase()} is associated with a subscription.`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} width="16" height="14" alt="error-img" />
                ),
                toastId: "delete-customer-failed-error",
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else {
            toast.error(
              `Unable to delete ${props.name.toLocaleLowerCase()}. ${
                props.name
              } has transactions posted.`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} width="16" height="14" alt="error-img" />
                ),
                toastId: "delete-customer-failed-error",
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          }
        } else if (
          props.name === "Proforma Invoice" &&
          props.isInvoiceAssociated
        ) {
          toast.error(
            `Unable to delete ${props.name}, as there is invoice associated with it!`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `bill-delete-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else {
          if (props.isLocked && props.name === "Items") {
            toast.error(
              `Items that are a part of other transactions cannot be deleted. Instead, mark them as inactive.`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} alt="error-img" width="16" height="14" />
                ),
                toastId: `bill-delete-failed`,
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else if (props.isLocked) {
            toast.error(
              `Transactions before ${getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                props.lockDate
              )} have been locked. Hence action cannot be performed`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} alt="error-img" width="16" height="14" />
                ),
                toastId: `delete-failed`,
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else if (props.isJvGenerated) {
            toast.error(
              `Deferred income journal entries linked invoices cannot be deleted.`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} alt="error-img" width="16" height="14" />
                ),
                toastId: `delete-failed`,
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else if (
            props.name === "Purchase Order" ||
            props.isBillAssociated
          ) {
            toast.error(
              `Unable to delete ${props.name}, as there are bills associated with it!`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} alt="error-img" width="16" height="14" />
                ),
                toastId: `bill-delete-failed`,
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else if (
            !props.isMutable &&
            props.name !== "Customer" &&
            props.name !== "Vendor"
          ) {
            toast.error(
              `Unable to delete ${props.name}, as there are transactions associated with it!`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} alt="error-img" width="16" height="14" />
                ),
                toastId: `bill-delete-failed`,
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else if (props.isFiscalClosed) {
            toast.error(
              `Deletion of previous fiscal year transactions is disabled.`,
              {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} alt="error-img" width="16" height="14" />
                ),
                toastId: `bill-delete-failed`,
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              }
            );
          } else {
            props.onClick();
          }
        }
      }}
    >
      <span className="d-flex align-items-center">
        {" "}
        {props.deleteIcon ? (
          <>
            <DeleteIcon
              color={props.iconColor ? props.iconColor : colors.tomato}
              margin="0px 5px 0px 0px"
            />
          </>
        ) : null}
        Delete
      </span>
    </Link>
  );
}
