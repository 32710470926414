import { useCallback } from "react";
import useCommonData from "../../../../../hooks/useCommon";
import { AccountNode } from "../types";

export const useProcessTableData = (currencyCode: string) => {
  /**
   * Function to recursively process and sanitize an array of AccountNode objects,
   * adding total nodes where applicable based on the provided criteria.
   *
   * @param {AccountNode[]} inputNodes - Array of AccountNode objects to be processed.
   * @param {string[]} totalsNotNeeded - Array of account names where totals should not be added.
   * @param {number} totalNetCredit - Placeholder value used when creating total nodes for net credit.
   * @param {number} totalNetDebit - Placeholder value used when creating total nodes for net debit.
   *
   * @returns {AccountNode[]} - A new array of processed and sanitized AccountNode objects.
   *
   * @description
   * - The function processes the hierarchical structure of AccountNode objects recursively.
   * - For each node, it recursively processes its children, and if certain criteria are met:
   *    - A total node is created and appended to the list of children.
   * - The function ensures immutability by returning a new structure instead of modifying the input directly.
   */
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  function processAndSanitizeNode(
    inputNodes: AccountNode[],
    totalsNotNeeded: string[],
    totalLiabilityEquity: number
  ): AccountNode[] {
    // Base case: return an empty array if the input nodes are empty or undefined
    if (!inputNodes || inputNodes.length === 0) return [];

    return inputNodes.map((node) => {
      // Recursively process child nodes
      const processedChildren = processAndSanitizeNode(
        node.node as AccountNode[],
        totalsNotNeeded,
        totalLiabilityEquity
      );

      // Determine if a total node should be added based on criteria
      const shouldAddTotalNode =
        processedChildren.length > 0 && // Node has children
        !totalsNotNeeded.includes(node.account_name) && // Node's account name is not in totalsNotNeeded
        !node.is_tag; // Node is not marked as a tag

      // Create a total node if the criteria are met
      const totalNode = shouldAddTotalNode ? createTotalNode(node) : null;

      // Return the node with processed children and the total node (if applicable)
      return {
        ...node,
        // net_credit: NumberFormat(Number(node.net_credit), currencyCode, orgCurrencyList),
        // net_debit: NumberFormat(Number(node.net_debit), currencyCode, orgCurrencyList),
        // net_difference: NumberFormat(Number(node.net_difference), currencyCode, orgCurrencyList),
        node: totalNode ? [...processedChildren, totalNode] : processedChildren,
      };
    });
  }

  /**
   * Factory function to create a total node for a given parent node.
   *
   * @param {AccountNode} parentNode - The node for which the total node is being created.
   *
   * @returns {AccountNode} - A new total node with calculated and default values.
   *
   * @description
   * - The function uses the parent node’s properties to set up a summary total node.
   * - The new node inherits certain properties from the parent node (e.g., parent_id, depth) and sets
   *   values for net credit, net debit, and net difference based on the parent node's net_difference.
   */
  function createTotalNode(parentNode: AccountNode): AccountNode {
    return {
      id: generateDefaultId(),
      parent_id: parentNode.id,
      transaction_exist: true,
      depth: parentNode.depth + 1,
      is_category: false,
      closing_balance: parentNode.closing_balance,
      is_tag_associated: false, // Default value
      account_name: `Total for ${parentNode.account_name}`, // Derives from the parent node's account name
      node: [], // No children for the total node
      style_class: ["Assets", "Liabilities", "Equity"].includes(
        parentNode.account_name
      )
        ? "last-node"
        : "last-sub-node", // Styling class for identification
    };
  }

  /**
   * Utility function to generate a random ID for nodes.
   *
   * @returns {string} - A unique identifier string.
   */
  const appendNetTotalRow = useCallback(
    (nodeList: AccountNode[], closingBalance: string) => {
      const totalObject = {
        id: generateDefaultId(),
        parent_id: generateDefaultId(),
        transaction_exist: true,
        depth: 1,
        is_category: false,
        closing_balance: closingBalance,
        is_tag_associated: false, // Default value
        account_name: `Total for Liabilities and Equities`, // Derives from the parent node's account name
        node: [], // No children for the total node
        style_class: "total-node", // Styling class for identification
      };
      let mergedNodeList = nodeList;
      if (
        nodeList &&
        nodeList?.length &&
        nodeList[nodeList?.length - 1]?.style_class !== "total-node"
      ) {
        mergedNodeList = mergedNodeList?.concat([totalObject]);
      }
      return mergedNodeList;
    },
    [currencyCode]
  );

  function generateDefaultId(): string {
    return Math.random().toString(36).substr(2, 9);
  }
  return { processAndSanitizeNode, appendNetTotalRow };
};
