import { FormInputField, FormSelectField } from "../../../common/components";
import { useFormik } from "formik";
import "./invoicingForm.css";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import useCommonData from "../../../hooks/useCommon";
import * as yup from "yup";
import { createCustomFrequencyHandler } from "../invoicingFrequencySlice";
import {
  fetchCustomFrequencySettings,
  setLoaderState,
} from "../../../common/commonSlice";
import { SuccessToaster } from "../../../../helpers/toastHelper";

type Props = {
  getSiteWideBilling: () => void;
  onCloseModal: () => void;
};

//fetchCustomFrequencySettings

export const InvoicingForm = (props: Props) => {
  const { dispatch, navigate, currentUserInfo } = useCommonData();

  const selectFrequencyOption = [
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
    { label: "Yearly", value: "YEARLY" },
  ];

  const validate = yup.object({
    custom_repeat_frequency: yup
      .string()
      .required("Please select a invoicing frequency"),
    custom_repeat_count: yup
      .string()
      .required("Please enter a count")
      .test(
        "custom_repeat_count",
        "Please enter a valid number",
        function (value) {
          if (Number(value) < 1) {
            return false;
          }
          return true;
        }
      ),
  });

  const initialValues = {
    organization_id: currentUserInfo.organization_id,
    custom_repeat_count: 0,
    custom_repeat_frequency: "DAILY",
    is_enabled: false,
    user_id: currentUserInfo.id,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validate,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (value) => {
      onFormSubmit(value);
    },
  });

  const onFormSubmit = async (data: any) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(createCustomFrequencyHandler(data));
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Invoicing frequency created successfully!",
        "tags-config-succcess"
      );
      props.getSiteWideBilling();
      dispatch(fetchCustomFrequencySettings(currentUserInfo.organization_id));
      props.onCloseModal();
    } else {
    }
    dispatch(setLoaderState(false));
  };

  return (
    <div className="billing-and-proration-modal-container">
      <div className="billing-and-proration-modal-inner-container">
        <FormInputField
          type="number"
          name="custom_repeat_count"
          id="custom_repeat_count"
          className={`${
            1 > 2 ? "form-control error" : "form-control"
          } custom_repeat_count_h`}
          label="Enter Number"
          placeholder="Enter Number"
          maxLength={16}
          value={
            formik.values.custom_repeat_count <= 0
              ? 0
              : formik.values.custom_repeat_count
          }
          onChange={(event: any) => {
            formik.setFieldValue("custom_repeat_count", event.target.value);
          }}
          isRequired={true}
          error={formik.errors.custom_repeat_count}
        />
        <div className="custom_repeat_frequency_mt">
          <FormSelectField
            name="custom_repeat_frequency"
            id="custom_repeat_frequency"
            label="Select Frequency"
            options={selectFrequencyOption}
            wrapperClass="mw-325"
            className="state-select custom-select billing-and-proration-select"
            value={
              selectFrequencyOption.filter(
                (data: any) =>
                  data.value === formik.values.custom_repeat_frequency
              )[0] || ""
            }
            onChange={(option: any) => {
              formik.setFieldValue("custom_repeat_frequency", option.value);
            }}
            placeholder={"Choose Frequency"}
            isSearchable={true}
            isDisabled={false}
            error={formik.errors.custom_repeat_frequency}
            isRequired={true}
            isOnlyInRow={false}
            messagePlacement="top-end"
            styles={customSelectStyle}
          />
        </div>
      </div>
      <div className="billing-and-proration-modal-footer">
        <button
          type="button"
          className="save-button mx-0"
          onClick={() => {
            formik.submitForm();
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="text-decoration-none cancel-button mx-4"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onCloseModal();
            formik.resetForm();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
