import { useEffect, useState } from "react";
import { ObjectType } from "../../../../../types";
import AccordianItem from "../../../../common/components/detailsPage/AccordianItem";
import { CustomFieldTable } from "../customFieldTable/customFieldTable";

type Props = {
  salesCustomFieldList: {
    Invoices: ObjectType[];
    "Credit Notes": ObjectType[];
    "Payments Received": ObjectType[];
    Customer  : ObjectType[];
  };
};
export const SalesCustomFields = (props: Props) => {
  const [customFieldModuleList, setCustomFieldModuleList] = useState(
    props.salesCustomFieldList
  );

  useEffect(() => {
    setCustomFieldModuleList(props.salesCustomFieldList);
  }, [props.salesCustomFieldList]);

  const invoiceFields = customFieldModuleList.Invoices
    ? customFieldModuleList.Invoices?.filter(
        (field) => field.is_deleted === false
      ).map((field) => {
        return {
          show: {
            id: field.id,
            field_name: field.field_name,
            mandatory_field: field.mandatory_field ? "Yes" : "No",
            show_in_pdf: field.show_in_pdf ? "Yes" : "No",
            actions: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field,
              show_in_pdf: field.show_in_pdf,
            },
          },
        };
      })
    : [];

  const creditNoteFields = customFieldModuleList["Credit Notes"]
    ? customFieldModuleList["Credit Notes"]
        .filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];
  const paymentReceivedFields = customFieldModuleList["Payments Received"]
    ? customFieldModuleList["Payments Received"]
        .filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];
  const customersFields = customFieldModuleList.Customer
    ? customFieldModuleList.Customer
        .filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];

  return (
    <div
      className="accordion transactions-accordion"
      id="transactions-accordion"
    >
      {" "}
      <AccordianItem
        title="Sales Invoices"
        index={1}
        createUrl=""
        module="Custom Fields"
        subModule="Invoices"
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={invoiceFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Invoices"}
        />
      </AccordianItem>
      <AccordianItem
        title="Credit Notes"
        index={2}
        module="Custom Fields"
        subModule="Credit Notes"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={creditNoteFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Credit Notes"}
        />
      </AccordianItem>
      <AccordianItem
        title="Payments Received"
        index={3}
        module="Custom Fields"
        subModule="Payments Received"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={paymentReceivedFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Payments Received"}
        />
      </AccordianItem>
      <AccordianItem
        title="Customers"
        index={4}
        module="Custom Fields"
        subModule="Customer"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={customersFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Customer"}
        />
      </AccordianItem>
    </div>
  );
};
