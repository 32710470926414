import { useMemo, useState } from "react";
import { PricingItemValue } from "../../planType";
import {
  PRICE_RANGE_PRICING_MODEL,
  getPricingFromUnitsArray,
  getPricingItemArray,
  getPricingToUnitsArray,
  priceColumnValueConcat,
} from "../../../../../helpers/planHelper";
import useCommonData from "../../../../hooks/useCommon";
import ListCellTablePopover from "../../../../common/components/listCellPopover/ListCellTablePopover";
import Grid from "@mui/material/Grid";
import { values } from "lodash";

type CustomPriceColumnProps = {
  value: PricingItemValue;
  isButtonVisible: boolean;
  showPricingActions: boolean;
};

function CustomPriceColumn(props: CustomPriceColumnProps) {
  const { navigate, orgCurrencyList } = useCommonData();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [poperHeight, setPoperHeight] = useState<number>(0);
  const open = Boolean(anchorEl);
  const id = open ? `price-item-popover ${props.value.index}` : undefined;
  const onMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
    setPoperHeight(0);
  };

  const savePopperHeight = (Popper: any) => {
    if (Popper) {
      setPoperHeight(Popper.clientHeight);
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Item",
        accessor: "show.item",
        sort: false,
        sortOrder: "",
        className: "price-item-col",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Grid item className="item-pricing-grid">
              <div className="price-item-responsive">{value}</div>
            </Grid>
          );
        },
      },
      {
        Header: "From Units",
        accessor: PRICE_RANGE_PRICING_MODEL.includes(props.value.pricingModel)
          ? "show.fromUnits"
          : "From Units",
        sort: false,
        sortOrder: "",
        className: "price-Units-col",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Grid item className="each-pricing-grid">
              {value?.data?.map((price: string, index: number) => (
                <div
                  className={`${
                    price.length > 7
                      ? `pricing-responsive parent-Index-${index}-${value.parentIndex}`
                      : `from-parent-Index-${index}${value.parentIndex}`
                  }`}
                >
                  {price}
                </div>
              )) || ""}
            </Grid>
          );
        },
      },
      {
        Header: "To Units",
        accessor: PRICE_RANGE_PRICING_MODEL.includes(props.value.pricingModel)
          ? "show.toUnits"
          : "To Units",
        sort: false,
        sortOrder: "",
        className: "price-Units-col",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Grid item className="each-pricing-grid">
              {value?.data?.map((price: string, index: number) => (
                <div
                  className={`${
                    price.length > 7
                      ? `pricing-responsive parent-Index-${index}-${value.parentIndex}`
                      : `to-parent-Index-${index}${value.parentIndex}`
                  }`}
                >
                  {price}
                </div>
              )) || ""}
            </Grid>
          );
        },
      },
      {
        Header: "Price",
        accessor: "show.price",
        sort: false,
        sortOrder: "",
        className: "popover_price",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Grid item className="each-pricing-grid">
              {value.data.formatedPrice.map((price: string, index: number) => (
                <div
                  className={`${
                    price && value.data.actualPrice[index].length > 6
                      ? `pricing-responsive parent-Index-${index}-${value.parentIndex}`
                      : `price-parent-Index-${index}${value.parentIndex}`
                  }`}
                >
                  {price}
                </div>
              ))}
            </Grid>
          );
        },
      },
    ],
    []
  );
  /**
   * set table row data
   */

  const data = props.value.item
    ? props.value.item.map((pricing, index) => ({
        show: {
          item: pricing.item_name,
          fromUnits: {
            data: getPricingFromUnitsArray(props.value.pricingModel, pricing),
            parentIndex: index,
          },
          toUnits: {
            data: getPricingToUnitsArray(props.value.pricingModel, pricing),
            parentIndex: index,
          },
          price: {
            data: getPricingItemArray(
              props.value.pricingModel,
              pricing,
              orgCurrencyList,
              props.value.currencyCode
            ),
            parentIndex: index,
          },
        },
      }))
    : [];

  return (
    <Grid container spacing={1} sx={{ padding: "0px 0px", margin: "0px" }}>
      <Grid
        item
        xs={props.showPricingActions ? 7 : 12}
        className="plan-pricing-item-grid"
      >
        <span
          aria-describedby={id}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
        >
          <span className="plan-pricing-item">
            {priceColumnValueConcat(
              props.value.pricingModel,
              props.value.item,
              orgCurrencyList,
              props.value.currencyCode
            )}
          </span>
          <ListCellTablePopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            columns={columns}
            data={data}
            savePopperHeight={savePopperHeight}
            poperHeight={poperHeight}
            isRange={PRICE_RANGE_PRICING_MODEL.includes(
              props.value.pricingModel
            )}
          />
        </span>
      </Grid>
      {props.showPricingActions && props.isButtonVisible ? (
        <Grid item xs={5} className="plan-pricing-button-grid">
          <button
            className="plan-pricing-button"
            onClick={() => {
              navigate(
                `/plan/price/${props.value.price ? "edit" : "create"}/${
                  props.value.planId
                }/${props.value.frequency.toLowerCase()}/${
                  props.value.pricingId ? props.value.pricingId : ""
                }${
                  props.value.pricingId
                    ? "/" + props.value.currencyId
                    : props.value.currencyId
                }/${props.value.currencyCode}`,
                { replace: true }
              );
            }}
          >
            {props.value.price ? "Update Price" : "Set Price"}
          </button>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default CustomPriceColumn;
