import { FormikProps } from "formik";
import moment from "moment";
import { useAppSelector } from "../../../../../../../app/hooks";
import { getFormatedDate } from "../../../../../../../helpers/helper";
import { fiscalYearFilterSelector } from "../../../../../../common/commonSlice";
import { conversionMinDate } from "../../../../../../constants/constants";
import CustomDatepicker from "../../../../../../utils/atoms/datepicker";
import { useJVExchange } from "../../../hooks/useJVExchange";
import useCommonData from "../../../../../../hooks/useCommon";

type Props = {
  formik: FormikProps<any>;
};
export const SystemVoucherDate = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const { dateChangeHandler } = useJVExchange();

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      props.formik.setFieldTouched("date", true);
      if (date?.toString() === "Invalid Date") {
        props.formik.setFieldValue("date", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        // if (
        //   new Date(currentUserInfo.previous_fiscal_start_date)
        //   .setHours(5, 30)
        //   .toLocaleString() > new Date(date).setHours(5, 30).toLocaleString()
        // ) {
        //   props.formik.setFieldValue("date", "Old date");
        // } else 
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          props.formik.setFieldValue("date", dateFormated);
          dateChangeHandler(dateFormated, props.formik);
        } else {
          props.formik.setFieldValue("date", "Invalid date");
        }
      }
    }
  };
  return (
    <div className={"col-12 col-lg-4 mw-325 "}>
      <label htmlFor="check_expiry" className="required">
        Payment Date
      </label>
      <div id="date" className="date-selector-wrapper">
        <CustomDatepicker
          date={new Date(props.formik.values.date)}
          handleDate={handleDate}
          type="paymentDate"
          error={
            props.formik.errors?.date &&
            (props.formik?.touched.date || props.formik.submitCount > 0)
              ? "error"
              : ""
          }
          id="date"
          zIndex={0}
          placeholder="Enter payment date"
          // minDate={new Date(currentUserInfo.previous_fiscal_start_date)}
          enableFutureDate={true}
        />
      </div>
      <span className="error error_date mb-2">
        {props.formik.values?.date && props.formik?.touched.date
          ? props.formik.errors?.date?.toString()
          : ""}
      </span>
    </div>
  );
};
