import { useAppSelector } from "../../../../app/hooks";
import { ErrorToaster } from "../../../../helpers/toastHelper";
import { ReportTagFormValues } from "../../../../types";
import { currentUserSelector } from "../../../common/commonSlice";
import { EditIcon, PlusIcon } from "../../../common/components";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../constants/colors";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { CloneIcon } from "../../../common/components/customSvgIcons/cloneIcon";
import { ReportTagForm } from "../reportingTagForm/ReportingTagForm";
import "./reportingTagFormModal.css";

type Props = {
  editId?: number;
  isClone?: boolean;
  isFromTransaction?: boolean;
  tagDetails?: ReportTagFormValues;
  wrapperClass?: string;
  buttonClass?: string;
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  setReportingTagsList: React.SetStateAction<any>;
};
export const ReportingTagFormModal = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const buttonSelect = () => {
    if (props.isClone) {
      return (
        <span>
          <CloneIcon color={colors.navyBlue} margin="0px 5px 0px 0px" />
          Clone
        </span>
      );
    } else if (props.editId) {
      return (
        <span>
          <EditIcon color={colors.battleshipGrey} margin="0px 5px 0px 0px" />
          Edit
        </span>
      );
    } else if (props.isFromTransaction) {
      return (
        <span>
          <AddBoxOutlinedIcon style={{margin:"0px 5px 0px 0px", fontSize:"20px"}} />
          Add New Tag
        </span>
      );
    } else {
      return (
        <span>
          <PlusIcon color={colors.white} margin="0px 5px 0px 0px" />
          Add New Tag
        </span>
      );
    }
  };
  return (
    <SideModal
      modalTitle={props.editId ? "Edit Reporting Tag" : "Create Reporting Tag"}
      drawerWrapperClass={"reporting-tag-modal button-bottom"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <ReportTagForm
                onClose={onCloseModal}
                isEdit={props.editId ? true : false}
                editId={props.editId}
                tagDetails={props.tagDetails}
                setReportingTagsList={props.setReportingTagsList}
                isClone={props.isClone}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={buttonSelect()}
      buttonClass={
        props.buttonClass
          ? props.buttonClass
          : "no-appearance primary-btn w-100 m-0 "
      }
      disabledActionFunction={() => {
        ErrorToaster(
          "Reporting tag cannot be updated since it has associated transactions"
        );
      }}
      isButtonDisabled={
        !currentUserInfo.is_tags_enabled
          ? true
          // : 
          // props.editId &&
          //   Array.isArray(props.tagDetails?.options) &&
          //   props.tagDetails?.options.some(
          //     (option) => option.is_associated === true
          //   )
          // ? true
          : false
      }
      tooltipMessage={
        !currentUserInfo.is_tags_enabled
          ? "Tagging feature is currently disabled. Please enable it to add new reporting tags"
          : ""
      }
      shouldGreyOut={false}
      tooltipPlacement="bottom-end"
      wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
      onOpenFunction={props.onOpenFunction}
      onCloseFunction={props.onCloseFunction}
      id="reporting-tag-create-modal"
    />
  );
};
