import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getFiscalQuarterYear } from "../../../../helpers/fiscalYearHelper";
import { getFormatedDate } from "../../../../helpers/helper";
import { clearStoredInfoInLocal } from "../../../../helpers/reportHelper";
import { SaveButton } from "../../../common/components";
import { PLDateArrayHelper } from "../../../common/components/reportCustomizationModal/customizeReportPL/PLfilterHelper";
import { ReportTagDropDown } from "../../../common/components/reportingTagDropDown/reportingTagDropDown";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import { useReportFilterInitialization } from "../../../hooks/useReportFilterInitialization";
import {
  CheckedBox,
  CustomerDropdownList,
  ObjectType,
  ReportFilters,
  VendorDropdownList,
} from "../../../../types";
import {
  currenciesSelector,
  currencyDropdown,
  currentUserSelector,
  getOrganizationCurrencies,
} from "../../../common/commonSlice";
import {
  customerDropdownListForSoa,
  customerSoaListSelector,
} from "../../customerManagement/customerSlice";
import {
  getReportingTagDropdown,
  reportingTagsListSelector,
} from "../../reportingTags/reportingTagAPIFiles/reportingTagSlice";
import {
  vendorDropdownListForSoa,
  vendorSoaListSelector,
} from "../../vendorManagement/vendorSlice";
import {
  dateRangeSelector,
  reportCustomizationFilterSelector,
  reportTableFilterSelector,
  selectedTagsDataSelector,
  setATCustomizationFilters,
  setAgingReportCustomizationFilters,
  setDateRange,
  setPLCustomizationFilter,
  setReportTableFilters,
  setSelectedTagsData,
} from "../ReportSlice";
import Select from "react-select";
import IntegratedReportSelect from "./integeratedReportDropDown/IntegeratedReportDropDown";
import OrganizationDropDown from "./OrganizationDropDown";
import PredefinedDateRangePicker from "./PredefinedDateRange";
import "./ReportBody.css";

type Props = {
  isSingleDatePicker: boolean;
  isAdvanced: boolean;
  defaultValue: string;
  reportType: string;
  isReportWithArgs?: boolean;
  handleReportWithArgs?: (type: string, argFilters?: ObjectType) => void;
  clearReport: () => void;
  onSubmit?: () => void;
  filterdata?: ObjectType;
  isEntity?: boolean;
  entityType?: string;
  enable_toggleReportType?: boolean;
  handleReport: (type: string) => void;

};

const ReportFilter = (props: Props, ref: any) => {
  const dispatch = useAppDispatch();
  const orgRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const currencyLists = useAppSelector(currenciesSelector);
  const customerDropdown = useAppSelector(customerSoaListSelector);
  const vendorDropdown = useAppSelector(vendorSoaListSelector);
  const filterValues = useAppSelector(reportTableFilterSelector);
  const PLReportFilters = useAppSelector(
    reportCustomizationFilterSelector
  ).profitAndLossFilters;
  const dateRange = useAppSelector(dateRangeSelector);

  let initialValues: ReportFilters = useReportFilterInitialization(
    props.reportType
  ) || {
    date_range: props.filterdata ? props.filterdata.date_range : "this_quarter",
    currency_id: currentUserInfo.organization_currency,
    end_date: props.filterdata
      ? props.filterdata.end_date
      : getFiscalQuarterYear(
        currentUserInfo.organization_fiscal_year
          ? currentUserInfo.organization_fiscal_year
          : "",
        "Current"
      ).endDate,
    organization_id: currentUserInfo.organization_id,
    start_date: props.filterdata
      ? props.filterdata.start_date
      : getFiscalQuarterYear(
        currentUserInfo.organization_fiscal_year
          ? currentUserInfo.organization_fiscal_year
          : "",
        "Current"
      ).startDate,
    is_integrated: props.filterdata ? props.filterdata.is_integrated : true,
  };
  if (props.isEntity) {
    if (props.entityType === "Customer") initialValues.customer_id = 0;
    if (props.entityType === "Vendor") {
      initialValues.vendor_id = 0;
    }
  }

  if (props.enable_toggleReportType) {
    initialValues.is_integrated = props.filterdata?.is_integrated;
  }
  const [currencyIds, setCurrencyIds] = useState<number[]>([]);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [orgFilterCurrencies, setOrgFilterCurrencies] = useState<number[]>([]);
  const [resetType, setResetType] = useState("initial");
  const [filterByNav, setFilterByNav] = useState<ObjectType>();
  const [customerList, setCustomerList] = useState<CustomerDropdownList[]>([]);
  const [vendorsList, setVendorsList] = useState<VendorDropdownList[]>([]);
  const [selectedTags, setSelectedTags] = useState<CheckedBox[]>([]);
  const reportingTagsDropdownList = useAppSelector(reportingTagsListSelector);
  const selectedTagsData = useAppSelector(selectedTagsDataSelector);

  useEffect(() => {
    getSystemCurrencies();
  }, []);
  useEffect(() => {
    setFilterByNav(props.filterdata);
  }, [props.filterdata]);
  useEffect(() => {
    if (
      (props.reportType === "TB" || props.reportType === "BS") &&
      filterByNav?.tag_option_in
    ) {
      let prevSelectedTags = JSON.parse(JSON.stringify(selectedTagsData));
      setSelectedTags(prevSelectedTags);
    }
    if(selectedTagsData?.length < 1) {
      setSelectedTags([]);
    }
  }, [filterByNav?.tag_option_in, selectedTagsData, currentUserInfo.organization_id]);

  useEffect(() => {
    if (props.reportType === "TB" || props.reportType === "BS") {
      let optionIds: number[] = [];
      selectedTags.map((item) => {
        optionIds = [...optionIds, ...item.optionIDs];
      });
      dispatch(
        setReportTableFilters({
          tag_option_in: [...optionIds],
        })
      );
    }
  }, [selectedTags]);
  useEffect(() => {
    if (!props.filterdata || Object.keys(props.filterdata).length === 0)
      fetchCurrency(currentUserInfo.organization_id);
    if (props.reportType === "TB" || props.reportType === "BS") {
      dispatch(
        getReportingTagDropdown({
          orgId: currentUserInfo.organization_id,
        })
      );
    }
  }, [currentUserInfo]);

  useEffect(() => {
    if (filterByNav && Object.keys(filterByNav).length) {
      dispatch(
        setDateRange({
          start_date: filterByNav?.startDate,
          end_date: filterByNav?.endDate,
          date_range: filterByNav?.dateRange,
        })
      );
      dispatch(
        setReportTableFilters({
          start_date: filterByNav?.startDate,
          end_date: filterByNav?.endDate,
          date_range: filterByNav?.dateRange,
          currency_id: filterByNav?.currencyId,
          organization_id: filterByNav?.organizationId,
          tag_option_in: filterByNav?.tag_option_in,
          is_integrated: filterByNav?.is_integrated,
        })
      );
      fetchCurrency(filterByNav?.organizationId, undefined, false);
    }
  }, [filterByNav]);

  const resetRedux = async () => { };

  useEffect(() => {
    setCurrencyIds([...orgFilterCurrencies]);
  }, [orgFilterCurrencies]);

  useImperativeHandle(
    ref,
    () => ({
      filterValues: filterValues,
    }),
    [filterValues]
  );
  useEffect(() => {
    setCustomerList([...customerDropdown]);
  }, [customerDropdown]);
  useEffect(() => {
    setVendorsList([...vendorDropdown]);
  }, [vendorDropdown]);

  useEffect(() => {
    if (props.entityType === "Customer" || props.entityType === "Vendor") {
      getFormUsersData();
    }
  }, [filterValues["organization_id"]]);

  useEffect(() => {
    dispatch(
      setATCustomizationFilters({
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
        date_range: filterValues.date_range,
        tag_option_in: filterValues.tag_option_in,
      })
    );
    dispatch(
      setAgingReportCustomizationFilters({
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
        date_range: filterValues.date_range,
        currency_id: filterValues.currency_id,
      })
    );
  }, [filterValues]);
  const PLDateFilterSetter = () => {
    if (props.reportType === "PL") {
      dispatch(
        setPLCustomizationFilter({
          start_date: filterValues.start_date,
          end_date: filterValues.end_date,
          date_range: filterValues.date_range,
          compare_list: [
            ...PLDateArrayHelper(
              PLReportFilters.compare_with,
              Number(PLReportFilters.compare_value),
              {
                start: filterValues.start_date
                  ? filterValues.start_date
                  : getFormatedDate(new Date()),
                end: filterValues.end_date,
                date_range: filterValues.date_range,
              }
            ),
          ],
        })
      );
    }
  };

  useEffect(() => {
    if (
      props.reportType === "SOA" ||
      props.reportType === "PL" ||
      props.reportType === "AT" ||
      props.reportType === "DB" ||
      props.reportType === "PS" ||
      props.reportType === "RS"
    ) {
      dispatch(
        setReportTableFilters({
          start_date: dateRange.start_date,
          end_date: dateRange.end_date,
          date_range: dateRange.date_range,
        })
      );
    } else {
      dispatch(
        setReportTableFilters({
          // ...filterValues,
          start_date: dateRange.start_date,
          end_date: dateRange.end_date,
          date_range: dateRange.date_range,
        })
      );
    }
  }, [dateRange]);

  const getSystemCurrencies = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };

  const getFormUsersData = async () => {
    const entity = props.entityType || "";
    if (
      currentUserInfo.organization_id &&
      ["Customer", "Vendor"].includes(entity)
    ) {
      // dispatch(setLoader(true));
      if (props.entityType === "Customer")
        await dispatch(
          customerDropdownListForSoa({
            orgId: filterValues["organization_id"]
              ? Number(filterValues["organization_id"])
              : currentUserInfo.organization_id,
            currentOrgId: currentUserInfo.organization_id,
            isIntegrated: true,
          })
        );
      if (props.entityType === "Vendor")
        await dispatch(
          vendorDropdownListForSoa({
            orgId: filterValues["organization_id"]
              ? Number(filterValues["organization_id"])
              : currentUserInfo.organization_id,
            currentOrgId: currentUserInfo.organization_id,
            isIntegrated: true,
          })
        );
      // dispatch(setLoader(false));
    }
  };
  /**
   * Function to set start date and end date
   */
  const handleDateRange = (start: any, end: any, range: string) => {
    let dateRange = range.replace(" ", "_").toLowerCase();
    if (
      props.reportType === "SOA" ||
      props.reportType === "PL" ||
      props.reportType === "AT" ||
      props.reportType === "DB"
    ) {
      dispatch(
        setReportTableFilters({
          // ...filterValues,
          start_date: start,
          end_date: end,
          date_range: dateRange,
        })
      );
    } else {
      dispatch(
        setReportTableFilters({
          // ...filterValues,
          start_date: start,
          end_date: end,
          date_range: dateRange,
        })
      );
    }
  };
  /**
   * Function to set currency id
   */
  const handleSelectChange = (e: any, name: string) => {
    dispatch(
      setReportTableFilters({
        // ...filterValues,
        [name]: e.value,
      })
    );
  };

  /*
   * Function to set the value directly to the name field given.
   */
  const handleSelectBoolanValues = (fieldValue: any, name: string) => {
    dispatch(
      setReportTableFilters({
        [name]: fieldValue,
      })
    );
  };

  const fetchDependencies = (id: number, currencyId?: number) => {
    if (props.entityType === "Vendor") {
      dispatch(
        setReportTableFilters({
          vendor_id: 0,
        })
      );
    }
    if (props.entityType === "Customer") {
      dispatch(
        setReportTableFilters({
          customer_id: 0,
        })
      );
    }
    fetchCurrency(id, currencyId);
  };
  /**
   * Fetch organization currencies
   */
  const fetchCurrency = async (
    id: number,
    currencyId?: number,
    dispatchFilter: boolean = true
  ) => {
    let props: any = { organization_id: id };
    if (currencyId) props.currency_id = currencyId;
    if (dispatchFilter) {
      dispatch(
        setReportTableFilters({
          // ...filterValues,
          ...props,
        })
      );
    }
    const responseAction = await dispatch(
      getOrganizationCurrencies({
        selectedOrgId: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setOrgFilterCurrencies(response);
      }
    }
  };
  /**
   * Get the list of all currencies
   */
  const currencyListOptions = currencyLists
    .filter((currency) => currencyIds.includes(currency.id))
    .map((currency, index) => ({
      label: currency.code,
      value: currency.id,
    }));
  /*
   * Get the currency name in the selection dropdown
   */
  const getName = (listOption: any[], value: any) => {
    return listOption
      .filter((listData) => listData.value === value)
      .map((listData) => ({
        label: listData.label,
        value: listData.value,
      }));
  };

  /**
   * Get the list of all customers
   */
  const customerListOptions = () => {
    let custlist = [];
    if (customerList.length)
      custlist?.push({ label: "All Customers", value: 0, currencyId: 0 });
    customerList.map((customer, index) => {
      custlist.push({
        label: customer.customer_display_name,
        value: customer.id,
        currencyId: customer.currency_id,
      });
    });
    return custlist;
  };
  /**
   * Get the list of all vendors
   */
  const vendorListOptions = () => {
    let vendList = [];
    if (vendorsList.length)
      vendList?.push({ label: "All Vendors", value: 0, currencyId: 0 });
    vendorsList.map((vendor, index) => {
      vendList.push({
        label: vendor.vendor_display_name,
        value: vendor.id,
        currencyId: vendor.currency_id,
      });
    });
    return vendList;
  };
  /**
   * Fetching report based on filters
   */
  const applyFilter = (applytype?: string) => {
    setFilterByNav({});
    // clearStoredInfoInLocal();
    let errors: any = [];
    errors = validate();
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      if (props.isReportWithArgs === true) {
        switch (props.reportType) {
          case "PL":
            PLDateFilterSetter();
            props.handleReportWithArgs &&
              props.handleReportWithArgs("submit", {
                compare_list: [
                  ...PLDateArrayHelper(
                    PLReportFilters.compare_with,
                    Number(PLReportFilters.compare_value),
                    {
                      start: filterValues.start_date
                        ? filterValues.start_date
                        : getFormatedDate(new Date()),
                      end: filterValues.end_date,
                      date_range: filterValues.date_range,
                    }
                  ),
                ],
                report_basis: PLReportFilters.report_basis,
                is_descending: PLReportFilters.is_descending,
                tag_option_in: PLReportFilters.tag_option_in,
                is_integrated: filterValues.is_integrated,
              });
            break;
          default:
            break;
        }
      } else if (applytype) {
        props.handleReport(applytype);
      } else {
        props.handleReport("submit");
      }
    }
  };
  const validate = () => {
    const errors: ObjectType = {};
    if (
      props.reportType === "SOA" ||
      props.reportType === "PL" ||
      props.reportType === "AT"
    ) {
      if (!filterValues.start_date || !filterValues.end_date) {
        errors.date_ranges = "Please select a date range";
      }
    } else {
      if (!filterValues.end_date && props.reportType !== "DB") {
        errors.date_ranges = "Please select a date range";
      }
    }
    if (!filterValues.organization_id) {
      errors.organization_id = "Please choose organization";
    }
    if (!filterValues.currency_id) {
      errors.currency_id = "Please choose currency";
    }
    return errors;
  };
  /**
   * Clear filter data
   */
  const clearFilter = async () => {
    // e.preventDefault();
    setResetType("reset");
    dispatch(setDateRange({ start_date: "", end_date: "", date_range: "" }));
    dispatch(setReportTableFilters(initialValues));
    dispatch(setSelectedTagsData([]));
    setSelectedTags([]);
    props.clearReport();
    setTimeout(() => {
      setResetType("initial");
    }, 0);
    if (props.reportType === "AR") {
      applyFilter("initial");
    }
  };

  return (
    <form name="filter-form" className="filter-form w-100 p-0 m-0">
      <div
        className={`row g-0 ${["CBD", "VBD"].includes(props.reportType)
            ? "customer-vendor-balance-date-currency-filter-wrapper"
            : ""
          }`}
      >
        <div className="col filter-wrapper three-col-filters d-flex justify-content-start">
          {props.reportType !== "DB" ? (
            <div
              className={`filter-item ${["CBD", "VBD"].includes(props.reportType)
                  ? "customer-vendor-balance-date-filter"
                  : ""
                }`}
            >
              <PredefinedDateRangePicker
                isSingleDatePicker={props.isSingleDatePicker}
                isAdvanced={props.isAdvanced}
                defaultValue={props.defaultValue}
                reportType={props.reportType}
                resetType={resetType}
                handleDateRange={handleDateRange}
                filterdata={filterValues} //filterByNav}
              />
            </div>
          ) : null}
          {props.reportType !== "CBD" && props.reportType !== "VBD" ? (
            <div className="filter-item">
              {/* <OrgSelectionDropdown ref={orgRef} /> */}
              <OrganizationDropDown
                fetchDependencies={fetchDependencies}
                selectedOrg={filterValues.organization_id}
              />
              <span className="error">{formErrors.organization_id}</span>
            </div>
          ) : null}
          {props.isEntity ? (
            <div className="filter-item">
              {props.entityType === "Customer" ? (
                <Select
                  name="customer_id"
                  className={`form-select custom-select customer-select ${formErrors.customerId ? "error" : ""
                    }`}
                  value={
                    filterValues.customer_id === undefined ||
                      filterValues.customer_id === 0
                      ? {
                        label: "All Customers",
                        value: 0,
                      }
                      : filterValues.customer_id !== 0
                        ? getName(customerListOptions(), filterValues.customer_id)
                        : []
                  }
                  options={customerListOptions()}
                  placeholder="Choose customer"
                  onChange={(e) => handleSelectChange(e, "customer_id")}
                  styles={customSelectStyle}
                />
              ) : props.entityType === "Vendor" ? (
                <Select
                  name="vendor_id"
                  className={`form-select custom-select customer-select ${formErrors.entity_id ? "error" : ""
                    }`}
                  value={
                    filterValues.vendor_id === undefined ||
                      filterValues.vendor_id === 0
                      ? {
                        label: "All Vendors",
                        value: 0,
                      }
                      : filterValues.vendor_id !== 0
                        ? getName(vendorListOptions(), filterValues.vendor_id)
                        : []
                  }
                  options={vendorListOptions()}
                  placeholder="Choose vendor"
                  onChange={(e) => handleSelectChange(e, "vendor_id")}
                  styles={customSelectStyle}
                />
              ) : null}
            </div>
          ) : null}
          <div className="filter-item">
            <Select
              name="currency_id"
              className={`form-select custom-select currency-select ${formErrors.currency_id ? "error" : ""
                }`}
              value={getName(currencyListOptions, filterValues.currency_id)}
              options={currencyListOptions}
              placeholder="Choose currency"
              isSearchable={false}
              onChange={(e) => handleSelectChange(e, "currency_id")}
              styles={customSelectStyle}
            />
            <span className="error">{formErrors.currency_id}</span>
          </div>
          {(props.reportType === "TB" || props.reportType === "BS") &&
            currentUserInfo.is_tags_enabled && (
              <div className="filter-item">
                <ReportTagDropDown
                  data={reportingTagsDropdownList}
                  checked={selectedTags}
                  setChecked={setSelectedTags}
                />
              </div>
            )}
          {props.enable_toggleReportType ? (
            <div className="filter-item">
              <IntegratedReportSelect
                handleChange={(e) => {
                  let isIntegartedValue =
                    e?.value === "integrated" ? true : false;
                  handleSelectBoolanValues(isIntegartedValue, "is_integrated");
                }}
                value={
                  filterValues.is_integrated
                    ? { value: "integrated", label: "Integrated" }
                    : { value: "individual", label: "Individual" }
                }
                name="is_integerated"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className={`button-container d-flex ${["CBD", "VBD"].includes(props.reportType)
            ? "customer-vendor-balance-btn-wrapper"
            : ""
          }`}
      >
        <SaveButton
          type="button"
          buttonClassName={` form-btn ${["CBD", "VBD"].includes(props.reportType)
              ? "customer-vendor-balance-save-btn"
              : "save-btn"
            }`}
          onClick={() => {
            if (props.reportType === "TB" || props.reportType === "BS") {
              dispatch(setSelectedTagsData(selectedTags));
            }
            props.onSubmit && props.onSubmit();
            applyFilter();
          }}
          isDisabled={!getName(currencyListOptions, filterValues.currency_id)}
          buttonContent="Apply"
        />
        <a
          href=""
          className="role-cancel text-decoration-none cancel-btn form-btn"
          onClick={(e) => {
            e.preventDefault();
            resetRedux();
            clearFilter();
            setSelectedTags([]);
            clearStoredInfoInLocal();

            props.clearReport();
          }}
        >
          Reset
        </a>
      </div>
    </form>
  );
};

export default forwardRef(ReportFilter);
