import { getFormatedDate } from "../../../../../helpers/helper";
import { initialItemValues } from "../../../../../helpers/transactionHelper";
import { tdsApplyLevel } from "../../../../constants/constants";

export const initialValues = {
  vendor_id: "",
  vendor_name: "",
  debit_note_number: "",
  subject: "",
  debit_note_date: getFormatedDate(new Date()),
  vendor_notes: "",
  file_ids: [],
  tag_ids: [],
  items: [initialItemValues],
  email_to: [],
  debit_note_status: "",
  conversion_id: "",
  currency_id: "",
  reference_number: "",
  is_locked: false,
  lock_date: "",
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  exclude_discount_accounting: false,
};
export const initialTotalValues = {
  total_taxable_amount: 0,
  tax_amount: 0,
  total: 0,
  taxes: [],
  debits_used: 0,
  debit_note_balance: 0,
  transaction_tds_amount: 0,
};

export const initialDefaultValues = {
  vendor_id: "",
  vendor_name: "",
  debit_note_number: "",
  subject: "",
  debit_note_date: getFormatedDate(new Date()),
  currency_id: "",
  reference_number: "",
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_of_supply: null,
  destination_state: null,
  rcm_enabled: false,
};
export const debitNoteDetailsInitialValues = {
  id: "",
  vendor_id: "",
  user_id: "",
  organization_id: "",
  debit_note_status: "",
  vendor_name: "",
  currency_code: "",
  debit_note_number: "",
  debit_note_date: "",
  subject: "",
  vendor_notes: "",
  custom_fields: {},
  address: {
    country_id: "",
    address: "",
    city: "",
    state_id: "",
    zip_code: "",
    country_name: "",
    state_name: "",
    phone: "",
    is_billing: false,
    is_shipping: false,
  },
  item_details: [],
  total_details: {
    total_taxable_amount: "",
    tax_amount: "",
    total: "",
    taxes: [],
    debits_used: "",
    debit_note_balance: "",
    transaction_tds_amount: 0,
  },
  debit_note_files: [],
  email_to: [],
  organization_details: {
    organization_name: "",
    email: "",
    country: "",
    state: "",
    address: "",
    logo_path: "",
    phone_number: "",
    zip_code: "",
    city: "",
  },
  reference_number: "",
  is_locked: false,
  lock_date: "",
  tds_level: "",
  tds_name: null,
  tds_percentage: null,
  conversion_id: 0,
  bill_status: true,
  is_fiscal_closed: false,
};
