import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InvoiceImage } from "../../../../../../assets/images";
import {
  DateFormatHandler,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  billColumnValues,
  billDefaultValues,
  billMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { BillList } from "../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";
import EmptyList from "../../../../../common/components/EmptyList";
import Popover from "../../../../../common/components/Popover";
import Pagination from "../../../../../common/components/table/Pagination";
import { billList } from "../../../../bills/billsSlice";
import Loader from "../../../../components/Loader";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
type Props = {
  isSameOrg: boolean;
};
function BillTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [billData, setBillData] = useState<BillList[]>([]);
  const [totalBills, setTotalBills] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Tags",
    "Terms",
    "actions",
  ]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const vendorId = editId ? editId : "";

  let billsRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Bills")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchBillList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalBills / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalBills, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    billColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [billData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Bills",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = billDefaultValues;
      }
      billMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch all bills
   */
  const fetchBillList = async () => {
    setIsLoading(true);
    if (vendorId) {
      const responseAction = await dispatch(
        billList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          vendorList: [Number(vendorId)],
          orgIds: [],
          statusList: [],
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);

        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setBillData(response.bills);
          let length = response.bills.length;
          setTotalBills(response.total);
          if (response.total !== 0)
            setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Bill Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Bill Number",
        accessor: "show.billId",
        sort: false,
        sortOrder: "",
        className: "bill_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "bill_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Due Date",
        accessor: selectedColumns.includes("Due Date")
          ? "show.dueDate"
          : "Due Date",
        sort: false,
        sortOrder: "",
        className: "due_date",
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      },
      {
        Header: "Balance Due",
        accessor: "show.balanceDue",
        sort: false,
        sortOrder: "",
        className: "balance_due",
      },
      {
        Header: "Tags",
        accessor: selectedColumns.includes("Tags") ? "show.tags" : "Tags",
        sort: false,
        sortOrder: "",
        className: "tags",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? (
            <div className="pills-wrapper">
              <Popover values={value} tag={true} />
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: "actions",
        accessor: "actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, selectedColumns, hiddenColumns]
  );
  /**
   * set table row data
   */
  const data = billData
    ? billData.map((bill, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            bill.bill_date
          ),
          billId: joinTransactionNumber(bill.Bill_ID),
          referenceNumber: bill.reference_number,
          status: bill.bill_status,
          dueDate: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            bill.due_date
          ),
          totalTaxableAmount:
            bill.currency_code +
            " " +
            NumberFormat(
              Number(bill.total_taxable_amount),
              bill.currency_code ? bill.currency_code : "",
              orgCurrencyList
            ),
          amount:
            bill.currency_code +
            " " +
            NumberFormat(
              Number(bill.total),
              bill.currency_code ? bill.currency_code : "",
              orgCurrencyList
            ),
          tds:
            bill.currency_code +
            " " +
            NumberFormat(
              Number(bill.tax_deducted),
              bill.currency_code ? bill.currency_code : "",
              orgCurrencyList
            ),
          balanceDue: bill.balance_due
            ? bill.currency_code +
              " " +
              NumberFormat(
                Number(bill.balance_due),
                bill.currency_code ? bill.currency_code : "",
                orgCurrencyList
              )
            : bill.currency_code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  bill.currency_code
                    ? bill.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          tags: bill.tags,
        },
        actions: {
          id: bill.id,
          status: bill.bill_status,
          listName: bill.Bill_ID,
          emailTo: bill.email_to,
          isMutable: bill.is_mutable,
          redirectTo: `/vendors/detail/${editId}`,
          consumerId: editId,
        },
      }))
    : [];
  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalBills;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  const sortHandler = (column: string) => {
    if (column === "Bill Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped w-100"
        columns={columns}
        data={data}
        url={"/bill"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={billsRolePermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            billsRolePermission.includes("Create")
              ? "Create new bill!"
              : "No Bills found!"
          }
          subheading={
            billsRolePermission.includes("Create")
              ? "Get paid faster by creating bills."
              : "Oops, There is no Bill data available right now"
          }
          list="Bills"
          alt="create bill preview"
          buttonText="Create Bill"
          navigateLink={`/bill/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/vendors/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            billsRolePermission.includes("Create") && props.isSameOrg
          }
          icon={InvoiceImage}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}

      <div className="table-footer pagination-container w-100 clear-both">
        {totalBills > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default BillTransactions;
