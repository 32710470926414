import React from "react";
import {
  DateFormatHandler,
  getProfileFormatedDate,
} from "../../../../helpers/helper";
import { ObjectType } from "../../../../types";
import OrgTag from "../OrgTag";
import "./HeaderBody.css";
import HeaderTag from "../headerTag/HeaderTag";
import { useAppSelector } from "../../../../app/hooks";
import { currentUserSelector } from "../../commonSlice";

type Props = {
  data: ObjectType;
  dataId: string | undefined;
  isSameOrg: boolean;
  children: React.ReactNode;
};
export default function HeaderBody(props: Props) {
  const currentUserInfo = useAppSelector(currentUserSelector);
  return (
    <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
      {props.dataId ? (
        <>
          <div className="left-group">
            <span className="biller-label ps-2">{props.data.userName}</span>
            <span>|</span>
            <span className="bill-date pe-2 ps-2">
              {/* {DateFormatHandler(props.data.date)} */}
              {getProfileFormatedDate(
                currentUserInfo?.organization_date_format,
                props.data.date
              )}
            </span>
            <span>|</span>
            <span>
              <OrgTag organization={props.data.orgName} />
            </span>
            {props.data.entity_tag?.length > 0 ? (
              <>
                <span className="me-2">|</span>
                <span className="pe-2">
                  <HeaderTag
                    status={props.data.status}
                    entityTag={props.data.entity_tag}
                  />
                </span>
              </>
            ) : (
              ""
            )}
          </div>
          {props.isSameOrg && (
            <div className="actions-wrap d-flex">{props.children}</div>
          )}
        </>
      ) : null}
    </div>
  );
}
