import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
  createColumnHelper,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { Container } from "../../../../common/components/htmlTags/Container";
import { TableBody } from "../../../../common/components/htmlTags/TableTags/TableBody";
import { TableCell } from "../../../../common/components/htmlTags/TableTags/TableCell";
import { TableContainer } from "../../../../common/components/htmlTags/TableTags/TableContainer";
import { TableHeader } from "../../../../common/components/htmlTags/TableTags/TableHeader";
import { TableHeaderContainer } from "../../../../common/components/htmlTags/TableTags/TableHeaderContainer";
import { TableRow } from "../../../../common/components/htmlTags/TableTags/TableRow";
import useCommonData from "../../../../hooks/useCommon";
import "../ProfitAndLoss.css";
import AccountNameCell from "./components/AccountNameCell";
import { TotalCell } from "./components/TotalCell";
import { useDrillDown } from "../hooks/useDrillDown";
import { useGetRowsToBeExpanded } from "../hooks/useGetRowsToBeExpanded";
import { useProcessStyle } from "../hooks/useProcessStyle";
import { useProcessTableData } from "../hooks/useProcessTableData";
import { useProcessData } from "../hooks/useProcessTagData";
import { AccountNode, ProcessedRow } from "../types";
import { useScrollToElement } from "../../../../hooks/useScrollToElement";
import formatDateRange from "../../../../../helpers/headerFormatterHelper";

const ProfitAndLossReportTable = ({
  jsonData,
  filterValues,
}: {
  jsonData: {
    currency_code: string;
    profit_and_loss: any;
    tag_profit_and_loss: any;
    items: AccountNode[];
  };
  key: string;
  filterValues?: any;
}) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const shouldAppend = useRef(true);
  const expandedIds = useRef<ExpandedState>({});
  const { scrollToElement } = useScrollToElement();
  const [currencyCode, setCurrencyCode] = useState(jsonData.currency_code);
  const { getRowsToBeExpanded } = useGetRowsToBeExpanded();
  const { processNodeById } = useProcessData(shouldAppend.current);
  const { processAndSanitizeNode, appendLastRow } =
    useProcessTableData(currencyCode);
  const {
    columnStyleProcessor,
    headerStyleProcessor,
    rowStyleProcessor,
    cellStyleProcessor,
  } = useProcessStyle();
  const [data, setData] = useState<any>([]);

  const [expanded, setExpanded] = useState<ExpandedState>(
    getRowsToBeExpanded()
  );
  const { accTransactionNavHandler } = useDrillDown();
  const formatNumber = useMemo(
    () => (value: number) =>
      NumberFormat(value, jsonData.currency_code, orgCurrencyList),
    [jsonData.currency_code, orgCurrencyList, jsonData.items]
  );

  useEffect(() => {
    scrollToElement("className", "active-selected-account");
  }, []);

  useMemo(() => {
    let processedData = processAndSanitizeNode(
      jsonData.items,
      jsonData.profit_and_loss,
      jsonData.tag_profit_and_loss
    );
    const lastRow = appendLastRow(
      processedData,
      jsonData.profit_and_loss,
      jsonData.tag_profit_and_loss
    );
    processedData.map((item) => {
      if (item.account_name === "Expenses") {
        item.node?.push(lastRow);
      }
    });
    setData(processedData);
  }, [
    jsonData.items,
    currentUserInfo.organization_id,
    jsonData.profit_and_loss,
    jsonData.tag_profit_and_loss,
  ]);
  function createDynamicColumns(data: any, formatNumber: any) {
    // Extract unique date ranges from the data
    const dateRanges = Array.from(
      new Set(
        data.flatMap((item: any) =>
          item.node.flatMap((subItem: any) => Object.keys(subItem.range || {}))
        )
      )
    );

    // Define base columns for account name and other necessary fields
    const baseColumns = [
      {
        id: "account_name",
        header: "",
        columns: [
          {
            id: "account_name",
            accessorKey: "account_name",
            header: "Account",
            cell: ({ row }: { row: Row<ProcessedRow> }) => (
              <AccountNameCell
                row={row}
                columnStyleProcessor={columnStyleProcessor}
                accTransactionNavHandler={accTransactionNavHandler}
                expandedIds={expandedIds}
                processNodeById={processNodeById}
                shouldAppend={shouldAppend}
                setData={setData}
                data={data}
                jsonData={jsonData}
                table={table}
                filterValues={filterValues}
              />
            ),
          },
        ],
        footer: (info: any) => info.column.id,
      },
    ];

    // Create dynamic columns based on date ranges
    const totalColumns = dateRanges.map((range, index) => ({
      id: `range-${range}`,
      header: formatDateRange(
        String(range),
        filterValues.date_range,
        currentUserInfo.organization_fiscal_year
      ),
      columns: [
        {
          id: `range-${range}-total`,
          accessorFn: (row: ProcessedRow) =>
            row.range?.[String(range)]?.total || "0.00",
          cell: ({ row }: { row: Row<ProcessedRow> }) => {
            const total = row.original.range?.[String(range)]?.total || "0.00";
            return (
              <TotalCell row={row} formatNumber={formatNumber} value={total} />
            );
          },
          header: "Total",
        },
      ],
      footer: (info: any) => info.column.id,
    }));

    return [...baseColumns, ...totalColumns];
  }

  // Usage in your component
  const columns = useMemo(
    () => createDynamicColumns(data, formatNumber),
    [data, formatNumber]
  );

  const table = useReactTable({
    data: data as any,
    columns,
    state: { expanded },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.node || [],
    getRowCanExpand: (row) => true,
  });
  return (
    <Container className="reusable-report-table-wrapper">
      <TableContainer className="profit-and-loss-report-table">
        <TableHeaderContainer>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    style={headerStyleProcessor(header)}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHeader>
                ))}
              </TableRow>
            );
          })}
        </TableHeaderContainer>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} style={rowStyleProcessor(row)}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id} style={cellStyleProcessor(row)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Container>
  );
};

export default ProfitAndLossReportTable;
