import React from "react";

import { CurrencyFormType, ObjectType } from "../types";
import { decimalPlaceOfCurrency } from "./decimalPlaceHelper";

export const NumberFormat = (
  number: number,
  code: string,
  orgCurrencyList: CurrencyFormType[]
) => {
  const numberString = number !== null && !Number.isNaN(number) ? number.toString() : "";
  const decimalIndex = numberString.indexOf(".");
  const format = orgCurrencyList.filter((item: ObjectType) => {
    return item.code === code;
  })[0]?.format;
  const decimalPart =
    decimalIndex >= 0 ? numberString.slice(decimalIndex + 1) : "";
  const decimalPlaces = decimalPlaceOfCurrency(code, orgCurrencyList);

  const getFormat = () => {
    if (
      format === "##,##,##,###.##" ||
      format === "##,##,##,###" ||
      format === "##,##,##,###.###"
    ) {
      return "##,##,##,###.##";
    } else if (
      format === "###,###,###.##" ||
      format === "###,###,###" ||
      format === "###,###,###.###"
    ) {
      return "###,###,###.##";
    } else if (
      format === "###.###.###,##" ||
      format === "###.###.###" ||
      format === "###.###.###,###"
    ) {
      return "###.###.###,##";
    } else {
      return "### ### ###,##";
    }
  };

  switch (getFormat()) {
    case "##,##,##,###.##":
      return decimalPart.length > 0
        ? new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: 6,
          })
            .format(number)
            .replace(".", (0.1).toLocaleString("en-IN").replace(/\d/g, ""))
        : new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimalPlaces,
          }).format(number);
    case "###,###,###.##":
      return decimalPart.length > 0
        ? new Intl.NumberFormat("en-US", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: 6,
          })
            .format(number)
            .replace(".", (0.1).toLocaleString("en-US").replace(/\d/g, ""))
        : new Intl.NumberFormat("en-US", {
            minimumFractionDigits: decimalPlaces,
          }).format(number);
    case "###.###.###,##":
      return decimalPart.length > 0
        ? new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: 6,
          })
            .format(number)
            .replace(".", (0.1).toLocaleString("en-IN").replace(/\d/g, ""))
        : new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: decimalPlaces,
          }).format(number);
    case "### ### ###,##":
      return decimalPart.length > 0
        ? new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: 6,
          })
            .format(number)
            .replace(",", ", ")
            .replace(".", (0.1).toLocaleString("fr-FR").replace(/\d/g, ""))
        : new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: decimalPlaces,
          }).format(number);
    default:
      return decimalPart.length > 0
        ? new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: 6,
          })
            .format(number)
            .replace(".", (0.1).toLocaleString("en-IN").replace(/\d/g, ""))
        : new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimalPlaces,
          }).format(number);
  }
};
/**
 * Format number tp Cr, Lac, K, B, M
 */
export const NumDifferentiation = (
  value: number,
  currency: string,
  decimal: number,
  orgCurrencyList: CurrencyFormType[]
) => {
  const val = Math.abs(value);
  if (decimal === 0 && orgCurrencyList) {
    return NumberFormat(value, currency, orgCurrencyList);
  }
  if (currency === "INR") {
    if (val >= 10000000)
      return `${Math.floor((value / 10000000) * 100) / 100} Cr`;
    if (val >= 100000) return `${Math.floor((value / 100000) * 100) / 100} Lac`;
    if (val >= 1000) return `${Math.floor((value / 1000) * 100) / 100} K`;
    return value;
  } else {
    if (val >= 1000000000)
      return `${Math.floor((value / 1000000000) * 100) / 100} B`;
    if (val >= 1000000) return `${Math.floor((value / 1000000) * 100) / 100} M`;
    if (val >= 1000) return `${Math.floor((value / 1000) * 100) / 100} K`;
    return value;
  }
};

export const zeroDisplayFormat = (decimals: number) => {
  switch (decimals) {
    case 0:
      return "0";
    case 2:
      return "0.00";
    case 3:
      return "0.000";
    default:
      return "0.00";
  }
};

