export const DateFormat = [
  { label: "YY-MM-DD [24-09-02]", value: "YY-MM-DD" },
  { label: "MM-DD-YY [09-02-24]", value: "MM-DD-YY" },
  { label: "DD-MM-YY [02-09-24]", value: "DD-MM-YY" },
  { label: "YY.MM.DD [24.09.02]", value: "YY.MM.DD" },
  { label: "MM.DD.YY [09.02.24]", value: "MM.DD.YY" },
  { label: "DD.MM.YY [02.09.24]", value: "DD.MM.YY" },
  { label: "YY/MM/DD [24/09/02]", value: "YY/MM/DD" },
  { label: "MM/DD/YY [09/02/24]", value: "MM/DD/YY" },
  { label: "DD/MM/YY [02/09/24]", value: "DD/MM/YY" },
  { label: "YYYY-MM-DD [2024-09-02]", value: "YYYY-MM-DD" },
  { label: "MM-DD-YYYY [09-02-2024]", value: "MM-DD-YYYY" },
  { label: "DD-MM-YYYY [02-09-2024]", value: "DD-MM-YYYY" },
  { label: "YYYY.MM.DD [2024.09.02]", value: "YYYY.MM.DD" },
  { label: "MM.DD.YYYY [09.02.2024]", value: "MM.DD.YYYY" },
  { label: "DD.MM.YYYY [02.09.2024]", value: "DD.MM.YYYY" },
  { label: "YYYY/MM/DD [2024/09/02]", value: "YYYY/MM/DD" },
  { label: "MM/DD/YYYY [09/02/2024]", value: "MM/DD/YYYY" },
  { label: "DD/MM/YYYY [02/09/2024]", value: "DD/MM/YYYY" },
  // { label: "DD MMM YYYY [02 Sep 2024]", value: "DD MMM YYYY" },
  // { label: "DD MMMM YYYY [02 September 2024]", value: "DD MMMM YYYY" },
  // { label: "MMMM DD, YYYY [September 02, 2024]", value: "MMMM DD, YYYY" },
];
export const dateFormatInitialValue = {
  value: "DD-MM-YYYY",
  label: "DD-MM-YYYY [02-09-2024]",
};
