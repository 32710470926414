import React, { useRef, useState } from "react";
import { ErrorToaster } from "../../../../helpers/toastHelper";
import parseAmount from "../../../../helpers/amountCopyPasteHelper";
import "../../../../index.css";
import "../PrefixInput/PrefixInput.css";
import "./InputPrefix.css";
type Props = {
  wrapperClass?: string;
  prefix: string;
  inputType: string;
  name: string;
  id: string;
  className: string;
  placeholder?: string;
  value: string | number;
  disabled: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  error: string;
  onChange?: (e: any) => void;
  onClick?: () => void;
  onBlur?: (name: string, amount: number, index: number) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  index?: number;
  endAdornment?: React.ReactNode;
  isAmountField?: boolean;
  hoverIcon?: React.ReactNode;
};
export default function InputPrefix(props: Props) {
  const [isCopyPaste, setCopyPaste] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check for copy (Ctrl+C) and paste (Ctrl+V) shortcuts
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "c" ||
        event.key === "v" ||
        event.key === "a" ||
        event.key === "x")
    ) {
      setCopyPaste(true);
      return; // Allow copy-paste functionality
    } else {
      setCopyPaste(false);
    }

    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };

  return (
    <>
      <div
        className={`${
          props.wrapperClass
            ? `${props.wrapperClass} inv-group d-flex w-100 input-prefix-wrap`
            : "inv-group d-flex w-100 input-prefix-wrap"
        } ${props.error ? "error" : ""}`}
      >
        <div className="inv-prefix border-start-0 prefix-section position-relative">
          {props.prefix}
        </div>
        <input
          ref={inputRef}
          type="text"
          name={props.name}
          id={props.id}
          data-testid="inputPrefix"
          className={`${props.className} ${
            props.disabled ? "input-disabled" : ""
          }`}
          placeholder={props.placeholder ? props.placeholder : ""}
          value={props.value}
          disabled={props.disabled}
          maxLength={props.maxLength ? props.maxLength : undefined}
          autoFocus={props.autoFocus ? props.autoFocus : false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { id, name, value } = e.target;
            const regex = /^(\d*\.?\d*)$/;
            if (props.isAmountField) {
              let filteredValue = parseAmount(value, isCopyPaste) || value;
              if (value === "" || regex.test(filteredValue.toString())) {
                props.onChange &&
                  props.onChange({
                    ...e,
                    target: { ...e.target, id, name, value: filteredValue },
                  });
              } else if (!regex.test(filteredValue.toString())) {
                ErrorToaster("Invalid amount input!");
              }
            } else {
              props.onChange && props.onChange(e);
            }
          }}
          onClick={props.onClick ? props.onClick : () => {}}
          onFocus={(e: any) => e.target.parentElement.classList.add("focused")}
          onBlur={(e: any) => {
            e.target.parentElement.classList.remove("focused");
            if (props.onBlur) {
              props.onBlur(
                props.name,
                props.value && !isNaN(Number(props.value))
                  ? Number(props.value)
                  : 0,
                props.index ? props.index : 0
              );
            }
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (props.isAmountField) {
              handleKeyPress(e);
            } else {
              props.onKeyPress && props.onKeyPress(e);
            }
          }}
        />
        {props.endAdornment}
        {props.hoverIcon && <div className="hover-icon">{props.hoverIcon}</div>}
      </div>
    </>
  );
}
