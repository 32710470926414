import React from "react";
import { ObjectType } from "../../../../types";

type Props = {
  organizationData: ObjectType;
};
export default function CompanyDetails(props: Props) {
  return !Object.values(
    props.organizationData ? props.organizationData : {}
  ).every((value) => value === "") ? (
    <div className="company-details">
      {props.organizationData?.logo_path ? (
        <div className="img-container">
          <img
            src={
              props.organizationData.logo_path
                ? props.organizationData.logo_path
                : ""
            }
            width={100}
            height={100}
            alt="organization logo"
          />
        </div>
      ) : null}

      <div className="company-address">
        <span className="company-name">
          {props.organizationData?.organization_name}
        </span>
        <br />
        <span>
          {props.organizationData?.address}{" "}
          {props.organizationData?.address ? "," : ""}{" "}
          {props.organizationData?.city}
        </span>
        {props.organizationData?.address || props.organizationData?.city ? (
          <br />
        ) : null}
        <span>
          {props.organizationData?.state} {props.organizationData?.zip_code},{" "}
          {props.organizationData?.country}
        </span>
        <br />
        <span>Email - {props.organizationData?.email}</span>
        <br />
        {props.organizationData?.organization_gstin && (
          <span className="gst-info">
            GSTIN:{" " + props.organizationData?.organization_gstin}
          </span>
        )}
        {props.organizationData?.organization_trn && (
          <span className="gst-info">
            TRN:{" " + props.organizationData?.organization_trn}
          </span>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
