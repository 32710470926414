import "./AddressCard.css";
import { Link } from "react-router-dom";
import { editShippingAddress } from "./addressTypeSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { AddressValues } from "../../../../../../types";
import {
  addressSelector,
  deleteAddress,
  setAsPrimaryShippingAddress,
} from "./addressSlice";
import { DeleteImg } from "../../../../../../assets/images";
import { useEffect, useRef } from "react";
type Props = {
  OpenAddressModal: () => void;
  shippingAddress: AddressValues;
  setAddressIdHandler: (id: string) => void;
  addressId: string;
  shouldScrollTo: boolean;
};
const ShippingAddressCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const addressList = useAppSelector(addressSelector);
  const ref: any = useRef();

  useEffect(() => {
    props.shouldScrollTo &&
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }, []);

  const selectAddressHandler = (addressId: string) => {
    dispatch(setAsPrimaryShippingAddress({ id: addressId }));
  };

  const editAddressHandler = () => {
    dispatch(editShippingAddress("edit_shipping"));
  };

  const removeAddressHandler = (id: string, isPrimary: boolean) => {
    dispatch(deleteAddress({ id: id }));
    /**
     * Setting first index address as primary if the deleted address is default
     */
    if (isPrimary) {
      const shippingAddressList = addressList
        .filter((item) => item.is_shipping)
        .map((address) => {
          return address;
        });
      if (shippingAddressList.length > 1) {
        const updatedShippingAddressList = shippingAddressList
          .filter((item) => item.id !== "" && item.id !== id)
          .map((address) => {
            return address;
          });
        selectAddressHandler(updatedShippingAddressList[0].id);
      }
    }
  };

  return (
    <div ref={ref} className="address-box-wrapper">
      <div
        className={`boxed-item address-item card ${
          props.shippingAddress.is_primary ? "selected" : ""
        }`}
      >
        <div
          className="card-header"
          onMouseDownCapture={() => selectAddressHandler(props.addressId)}
        >
          <h5>Default Address</h5>
          <div className="radio-wrapper form-check">
            <input
              type="radio"
              className="form-check-input option_address_selected"
              name="shipping"
              onChange={() => {}}
              value=""
              checked={props.shippingAddress.is_primary}
            />
          </div>
        </div>
        <div
          className="card-body"
          onMouseDownCapture={() => selectAddressHandler(props.addressId)}
        >
          <p>
            <b>{props.shippingAddress?.customer_name || ""}</b>
          </p>
          <p>
            {props.shippingAddress.address}
            {props.shippingAddress.address &&
            (props.shippingAddress.city ||
              props.shippingAddress.country_id ||
              props.shippingAddress.zip_code)
              ? ", "
              : ""}
            {props.shippingAddress.city}
            {props.shippingAddress.city &&
            (props.shippingAddress.country_id || props.shippingAddress.zip_code)
              ? ", "
              : ""}
            {props.shippingAddress.state_name}
            {props.shippingAddress.state_id &&
            (props.shippingAddress.zip_code || props.shippingAddress.country_id)
              ? ", "
              : ""}
            {props.shippingAddress.zip_code
              ? "Pin " + props.shippingAddress.zip_code
              : ""}
            {props.shippingAddress.zip_code && props.shippingAddress.country_id
              ? ", "
              : ""}
            {props.shippingAddress.country_name}
          </p>
          <p className="contact-dets">
            {" "}
            {props.shippingAddress.phone
              ? "Phone: " + props.shippingAddress.phone
              : ""}
          </p>
        </div>
        <div className="card-footer">
          <Link
            to="#"
            role="button"
            className="edit-address-link"
            onClick={(e: any) => {
              e.preventDefault();
              editAddressHandler();
              props.setAddressIdHandler(props.addressId);
              props.OpenAddressModal();
            }}
          >
            Edit
          </Link>
          <Link
            className="text-decoration-none delete-item"
            to="#"
            onClick={() => {
              removeAddressHandler(
                props.addressId,
                props.shippingAddress.is_primary
              );
            }}
          >
            <img src={DeleteImg} alt="delete-img" />
            <span>Delete</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddressCard;
