import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCustomFrequency,
  updateCustomFrequency,
} from "./invoicingFrequencyAPI";

export const createCustomFrequencyHandler = createAsyncThunk(
  "InvoicingFrequencyManagement/createCustomFrequencyHandler",
  async (data: {
    organization_id: number;
    custom_repeat_count: number;
    custom_repeat_frequency: string;
    is_enabled: boolean;
    user_id: number;
  }) => {
    return await createCustomFrequency(data);
  }
);

export const updateCustomFrequencyHandler = createAsyncThunk(
  "InvoicingFrequencyManagement/updateCustomFrequencyHandler",
  async (data: { orgId: number; formData: any[] }) => {
    return await updateCustomFrequency(data);
  }
);

const initialState = {};

export const invoicingFrequencySlice = createSlice({
  name: "InvoicingFrequencyManagement",
  initialState,
  reducers: {},
});

export default invoicingFrequencySlice.reducer;
