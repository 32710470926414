import { getFormatedDate } from "../../../../../helpers/helper";

export const initialItemValues = {
  account_id: "",
  account_name: "",
  description: "",
  debit: "",
  credit: "",
  contact_id: "",
  contact_name: "",
  contact_type: "",
  currency_id: 1,
  currency_code: "",
  conversion_id: null,
  conversion_rate: null,
  conversion_timestamp: "",
  converted_debit_rate: "",
  converted_credit_rate: "",
  by_user: false,
  index: 0,
};
export const initialValues = {
  journal_voucher_number: "",
  reference_number: "",
  notes: "",
  date: "",
  currency_id: "",
  file_ids: [],
  item_details: [initialItemValues],
  delete_email_ids: [],
  deleted_items: [],
  email_to: [],
  conversion_id: "",
  is_locked: false,
  lock_date: "",
  by_user: false,
};
export const initialDefaultValues = {
  journal_voucher_number: "",
  reference_number: "",
  notes: "",
  date: "",
  currency_id: "",
  is_cash_based: false,
  by_user: false,
};
export const svInitialItemValues = {
  id: null,
  account_id: "",
  account_name: "",
  description: "",
  debit: "",
  credit: "",
  contact_id: "",
  contact_name: "",
  contact_type: "",
  currency_id: null,
  currency_code: "",
  conversion_id: null,
  by_user: false,
  conversion_rate: null,
  conversion_timestamp: "",
  converted_debit_rate: "",
  converted_credit_rate: "",
  index: 0,
};
export const svInitialValues = {
  journal_voucher_number: "",
  reference_number: "",
  notes: "",
  date: getFormatedDate(),
  custom_fields: {},
  currency_id: "",
  currency_code: "",
  files: [],
  file_ids: [],
  item_details: [svInitialItemValues],
  delete_email_ids: [],
  deleted_items: [],
  email_to: [],
  email_data: [],
  conversion_id: "",
  is_locked: false,
  lock_date: "",
  is_cash_based: false,
  jv_status: "Published",
  action: "Send",
  is_amount_equal: false,
  write_off_cancelled_jv: false,
  list_in_jv: false,
  system_config_id: null,
  by_user: false,
};
