import { useRef, useState } from "react";
import { AddFilter } from "../../../../common/components/reportCustomizationModal/customizeReportATandBS/addFilterComponent";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import ExportEmailModal from "../exportEmailModal/exportEmailModal";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { exportSubscription } from "../../exportDataSlice";
import { currentUserSelector } from "../../../../common/commonSlice";
import { toast } from "react-toastify";

type FilterType = {
  field: string;
  comparator: string;
  value: string | string[];
};
function SubscriptionFilterComponent(props: any) {
  const initialFilterValue = [
    {
      field: "",
      comparator: "",
      value: "",
    },
  ];
  const [filters, setFilters] = useState<FilterType[]>(initialFilterValue);
  const [filterError, setFilterError] = useState("");
  const [filterErrorIndices, setFilterErrorIndices] = useState<number[]>([]);
  const addFilterRef = useRef<any>();
  const openEmailRef = useRef<any>();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);

  const addFilter = () => {
    let updatedFilter = [...filters];
    updatedFilter.push({
      field: "",
      comparator: "",
      value: "",
    });
    setFilters([...updatedFilter]);
  };
  const deleteFilter = (index: number) => {
    setFilterErrorIndices([]);
    let prevFilter = [...filters];
    prevFilter.splice(index, 1);
    setFilters([...prevFilter]);
  };
  const getFilterValues = async (
    data: {
      field: string;
      comparator: string;
      value: string | string[];
    },
    index: number
  ) => {
    let prevFilters = [...filters];
    prevFilters[index] = await data;
    setFilters([...prevFilters]);
  };
  const validateAdvancedFilter = () => {
    let tempFilters = filters || [];

    return tempFilters
      .map((obj, index) => ({ obj, index })) // Create an array of objects with their corresponding indices
      .filter(({ obj }) => Object.values(obj).some((value) => value === ""))
      .map(({ index }) => index);
  };
  const handleModalOpen = () => {
    setFilterErrorIndices(validateAdvancedFilter());
    if (validateAdvancedFilter().length > 0) {
      setFilterError(
        "Please fill the advanced filter completely or delete unused rows!"
      );
    } else {
      setFilterError("");
      openEmailRef.current?.openAlertModal &&
        openEmailRef.current.openAlertModal();
    }
  };
  const handleSubmit = async (emailList: string[]) => {
    const payload = {
      is_customer_ids: filters.some((entry) => entry.field === "Customer" && entry.comparator === "is"),
      customer_ids:
        filters.find((entry) => entry.field === "Customer")?.value || [],
      is_frequency: filters.some((entry) => entry.field === "Frequency" && entry.comparator === "is"),
      frequency:
        filters.find((entry) => entry.field === "Frequency")?.value || [],
      is_plan_ids: filters.some((entry) => entry.field === "Plan" && entry.comparator === "is"),
      plan_ids: filters.find((entry) => entry.field === "Plan")?.value || [],
      is_coupon_ids: filters.some((entry) => entry.field === "Coupons" && entry.comparator === "is"),
      coupon_ids:
        filters.find((entry) => entry.field === "Coupons")?.value || [],
      is_charge_ids: filters.some((entry) => entry.field === "Charges" && entry.comparator === "is"),
      charge_ids:
        filters.find((entry) => entry.field === "Charges")?.value || [],
      is_addon_ids: filters.some((entry) => entry.field === "Addons" && entry.comparator === "is"),
      addon_ids: filters.find((entry) => entry.field === "Addons")?.value || [],
      is_subscription_status: filters.some(
        (entry) => entry.field === "Subscription Status" && entry.comparator === "is"
      ),
      subscription_status:
        filters.find((entry) => entry.field === "Subscription Status")?.value ||
        [],
      is_organization_ids: false,
      organization_ids: [],
      email_list: emailList
    };
    const exportResponseAction = await dispatch(
      exportSubscription({
        payload: payload,
        orgId: currentUserInfo.organization_id,
      })
    );
    if(exportResponseAction.payload) {
      toast.success(exportResponseAction.payload.msg, {
        toastId: "subscription-export-success",
        closeButton: false,
        position: "top-center",
      });
      props.onCloseModal();
    }
  };
  return (
    <>
      <div className="subscription-export-filter-modal-wrapper">
        {filters &&
          filters?.map((filter, index) => (
            <div key={index}>
              <AddFilter
                key={index}
                value={filter}
                onDelete={() => deleteFilter(index)}
                index={index}
                getFilterValues={getFilterValues}
                ref={addFilterRef}
                selectedFields={filters.map((filter) => {
                  return filter.field;
                })}
                isError={filterErrorIndices.includes(index)}
                moduleType="Subscription Export"
              />
            </div>
          ))}
        <span className="error">{filterError}</span>
        <div className="d-flex align-items-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              addFilter();
            }}
            className="no-appearance "
            // disabled={filters && filters.length > 2}
          >
            <span className="w-100 d-flex  add-new m-0 ">Add Filter</span>
          </button>
        </div>
        <div className="customize_report_btn_group mt-3">
          <SaveButton
            type="button"
            buttonContent="Export"
            onClick={handleModalOpen}
            isDisabled={false}
          />
          <SecondaryButton
            type={"button"}
            buttonContent="Cancel"
            onClick={() => {
              setFilters(initialFilterValue)
              props.onCloseModal();
            }}
            isDisabled={false}
          />
        </div>
      </div>
      <ExportEmailModal
        message=""
        modalTitle="Export"
        isCloseButtonEnabled={true}
        isSubmitEnabled={true}
        openRef={openEmailRef}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
export default SubscriptionFilterComponent;
