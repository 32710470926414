import { getFormatedDate } from "../../../../../helpers/helper";
import { sourceTaxTypes, tdsApplyLevel } from "../../../../constants/constants";

export const initialTaxValues = {
  tax_id: "",
  tax_percentage: 0,
  item_tax_amount: 0,
};

export const initialItemValues = {
  account_name: "",
  item_name: "",
  hsn_or_sac_code: 0,
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "Absolute",
  discount_account_id: "",
  discount_account_name: "",
  taxes: [initialTaxValues],
  inventory_item_id: null,
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  tds_name: "",
  gst_tax: null,
  currency_id: null,
  account_id: 0,
  gst_id: 0,
};

export const initialDefaultValues = {
  customer_id: "",
  customer_name: "",
  profile_name: "",
  repeat_every: "",
  custom_repeat_count: 0,
  start_on: getFormatedDate(new Date()),
  ends_on: "",
  unit_price: "",
  preferred_invoice_status: "Save and Send",
  invoice_number: "",
  invoice_date: getFormatedDate(new Date()),
  reference_number: "",
  payment_term_id: "",
  currency_id: "",
  place_of_supply: "",
  invoice_status: "",
  is_mutable: true,
  customer_notes: "",
  items: [initialItemValues],
  email_to: [],
  conversion_id: 0,
  file_ids: [],
  tds_percentage: 0,
  is_locked: false,
  lock_date: "",
  custom_fields: {},
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,

  tds_id: 0,
  tds_amount: 0,
  tds_account_id: 0,
  conversion_date: "",
  tcs_id: 0,
  tcs_amount: 0,
  tcs_percentage: 0,
  tcs_account_id: 0,
  total: 0,
  never_expire: true,
  custom_repeat_frequency: "",
  address: [],
};

export const initialInvoiceValues = {
  customer_id: "",
  customer_name: "",
  profile_name: "",
  repeat_every: "",
  custom_repeat_count: 0,
  start_on: getFormatedDate(new Date()),
  ends_on: getFormatedDate(new Date()),
  unit_price: "",
  preferred_invoice_status: "",
  invoice_number: "",
  invoice_date: getFormatedDate(new Date()),
  reference_number: "",
  payment_term_id: "",
  currency_id: "",
  place_of_supply: "",
  invoice_status: "",
  is_mutable: true,
  customer_notes: "",
  items: [initialItemValues],
  email_to: [],
  conversion_id: 0,
  file_ids: [],
  tds_percentage: 0,
  is_locked: false,
  lock_date: "",
  custom_fields: {},
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,

  tds_id: 0,
  tds_amount: 0,
  tds_account_id: 0,
  conversion_date: "",
  tcs_id: 0,
  tcs_amount: 0,
  tcs_percentage: 0,
  tcs_account_id: 0,
  total: 0,
  never_expire: false,
  custom_repeat_frequency: "",
  address: [],
  is_email_reminder_automatic: false,
};
export const initialTotalValues = {
  total_taxable_amount: 0,
  tax_amount: 0,
  total: 0,
  taxes: [],
  tds_percentage: 0,
  transaction_tds_amount: 0,
};

export const billingEmptyObject = {
  country_id: null,
  address: null,
  city: null,
  state_id: null,
  zip_code: null,
  phone: null,
  is_billing: true,
  is_shipping: false,
};

export const shippingEmptyObject = {
  country_id: null,
  address: null,
  city: null,
  state_id: null,
  zip_code: null,
  phone: null,
  is_billing: false,
  is_shipping: true,
};
export const shippingEmptyFormvalues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: false,
  is_shipping: false,
  is_primary: false,
  state_name: "",
  country_name: "",
};
export const billingEmptyFormValues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: false,
  is_shipping: false,
  is_primary: false,
  state_name: "",
  country_name: "",
};

export const initialTDSTotalValues = {
  tds_id: null,
  tds_amount: 0,
  tds_percentage: 0,
  tds_account_id: 0,
  tds_name: "",
};

export const initialTCSTotalValues = {
  tcs_id: null,
  tcs_amount: 0,
  tcs_percentage: 0,
  tcs_account_id: 0,
  tcs_name: "",
};

export const dropdownValueRepeatInvoices = [
  { label: "Day(s)", value: "days" },
  { label: "Week(s)", value: "weeks" },
  { label: "Month(s)", value: "months" },
  { label: "Year(s)", value: "years" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Custom", value: "custom" },
];
export const previewDetailsInitialValues = {
  id: 0,
  customer_id: 0,
  customer_name: "",
  user_id: 0,
  conversion_id: 0,
  currency_id: 0,
  organization_id: 0,
  payment_term_id: 0,
  payment_term: "",
  reference_number: "",
  invoice_date: "",
  currency_code: "",
  total: 0,
  created_at: "",
  start_on: "",
  ends_on: "",
  invoice_status: "",
  custom_repeat_count: 0,
  custom_repeat_frequency: "",
  repeat_every: "",
  profile_name: "",
  address_details: [],
  organization_name: "",
  never_expire: null,
  organization_currency_code: "",
  total_unpaid_amount: 0,
  next_invoice_date: "",
};

export const previewDetailsInitialValuesView = {
  id: 0,
  customer_id: 0,
  customer_name: "",
  user_id: 0,
  conversion_id: 0,
  currency_id: 0,
  organization_id: 0,
  payment_term_id: 0,
  payment_term: "",
  reference_number: "",
  invoice_date: "",
  currency_code: "",
  total: 0,
  created_at: "",
  start_on: "",
  ends_on: "",
  invoice_status: "",
  custom_repeat_count: 0,
  custom_repeat_frequency: "",
  repeat_every: "",
  profile_name: "",
  address_details: [],
  organization_name: "",
  never_expire: null,
  organization_currency_code: "",
  total_unpaid_amount: 0,
  next_invoice_date: "",
  roundoff_enabled: false,
  tcs_percentage: 0,
  default_tds_percentage: 0,
  tcs_name: "",
  tds_percentage: 0,
  tcs_total: 0,
  total_details: {
    sub_total: 0,
    total: 0,
    tax_amount: 0,
    transaction_tds_amount: 0,
    transaction_tcs_amount: 0,
  },
  tds_name: "",
  custom_fields: {},
  item_details: [],
  tds_level: "",
  recurring_invoice_files: [],
  is_words_enabled: false,
};

export const recurringInvoiceAddressInitialValues = {
  country_id: null,
  address: "",
  city: "",
  state_id: null,
  zip_code: null,
  phone: null,
  is_billing: false,
  is_shipping: false,
  customer_address_id: 0,
  id: "",
  country_name: "",
  state_name: "",
};

export const RoundOffPreferencesData = {
  account_id: 0,
  account_name: "",
  is_enabled: false,
  is_total_editable: false,
  roundoff_transaction_type: "",
};
