import { FormikProps } from "formik";
import { ObjectType, CurrentUserValues } from "../../types";
import checkObjectFieldChanged from "../../features/utils/checkObjectFieldChanged";
import { Id } from "react-toastify";
import { getFormatedDate } from "../helper";

// export const getPrCreationPayload = (value: ObjectType, formik: FormikProps<any> ): any => {

export const getPrCreationPayload = (
  value: ObjectType,
  currentUserInfo: CurrentUserValues,
  formik: FormikProps<any>,
  intermediate_account_id?: number,
  intermediate_account_name?: string
): any => {
  let deletedIds = [] as Number[];
  let unpaidInvoiceRequestPayload = value.unpaid_invoice_lines
    .filter((invoice: any) => {
      if (
        (Number(invoice.payment) !== 0 && !isNaN(Number(invoice.payment))) ||
        (Number(invoice.withholding_tax) !== 0 &&
          !isNaN(Number(invoice.withholding_tax)))
      ) {
        return true;
      } else {
        if (invoice.id) {
          deletedIds.push(invoice.id);
        }
        return false;
      }
    })
    .map((unpaidInvoice: ObjectType, index: number) => {
      let isFieldChanged = checkObjectFieldChanged(
        unpaidInvoice,
        formik.initialValues["unpaid_invoice_lines"][index]
      );
      return {
        invoice_id: unpaidInvoice.invoice_id,
        id: unpaidInvoice.id || null,
        payment:
          Number(unpaidInvoice.payment) !== 0 &&
          !isNaN(Number(unpaidInvoice.payment))
            ? Number(unpaidInvoice.payment)
            : 0,
        withholding_tax:
          Number(unpaidInvoice.withholding_tax) !== 0 &&
          !isNaN(Number(unpaidInvoice.withholding_tax))
            ? Number(unpaidInvoice.withholding_tax)
            : 0,
        is_line_item_changed: isFieldChanged ? true : false,
        allocation_date: getFormatedDate(unpaidInvoice.allocation_date),
      };
    });

  return {
    payment_mode: value.payment_mode,
    check_number: value.payment_mode === "Check" ? value.check_number : "",
    check_expiry_in:
      value.payment_mode === "Check" ? value.check_expiry_in : null,
    check_expiry:
      value.payment_mode === "Check"
        ? value.check_expiry
          ? value.check_expiry
          : null
        : null,
    check_date:
      value.payment_mode === "Check"
        ? value.check_date
          ? value.check_date
          : null
        : null,
    post_dated_check_notes: value.post_dated_check_notes,
    payment_number: value.payment_number,
    reference_number: value.reference_number,
    payment_date: value.payment_date,
    customer_id: value.customer_id,
    deposit_to_account_id: value.deposit_to_account_id,
    intermediate_account_id: intermediate_account_id,
    intermediate_account_name: intermediate_account_name,
    amount_received: value.amount_received,
    bank_charges_amount: value.bank_charges_amount
      ? value.bank_charges_amount
      : 0,
    bank_charges_account_id: value.bank_charges_account_id,
    tax_account_id: value.tax_account_id,
    notes: value.notes,
    is_tax_deducted: value.is_tax_deducted,
    custom_fields: value.custom_fields,
    bank_currency_id: value.bank_currency_id,
    bank_conversion_id:
      value.bank_currency_id === currentUserInfo.organization_currency
        ? value.conversion_id
        : value.bank_conversion_id,
    amount_received_bank_currency: value.amount_received_bank_currency
      ? value.amount_received_bank_currency
      : 0,
    bank_charges_bank_currency: value.bank_charges_bank_currency
      ? value.bank_charges_bank_currency
      : 0,
    conversion_id: value.conversion_id,
    customer_name: value.customer_name,
    file_ids: [...value.file_ids],
    unpaid_invoice_lines: unpaidInvoiceRequestPayload,
    email_to: [...value.email_to],
    deleted_allocation_ids: deletedIds,
  };
};
