import React, { useRef } from "react";
import { AlertModal } from "../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../common/components/alertModal/alertModalContext";
import { ExcessAmountAlert } from "./excessAllocationAlert";
import { FormikProps } from "formik";

type ExcessAllocationAlertModalProps = {
  openRef: React.ForwardedRef<any>;
  excessAmount: number | null;
  currencyCode: string | null;
  module: string;
  handleSave: (id: number, name: string) => void;
  onClose?: () => void;
  formik?: FormikProps<any>;
};
export const ExcessAllocationAlertModal = (
  props: ExcessAllocationAlertModalProps
) => {
  const openRef = useRef<any>();
  return (
    <>
      <AlertModal
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return (
                <ExcessAmountAlert
                  excessAmount={props.excessAmount}
                  currencyCode={props.currencyCode}
                  module={props.module}
                  handleClose={onCloseModal}
                  handleSave={(id: number, name: string) => {
                    props.handleSave(id, name);
                    onCloseModal();
                  }}
                  formik={props.formik}
                />
              );
            }}
          </ModalContext.Consumer>
        }
        buttonContent={<span></span>}
        buttonClass="no-appearance d-none"
        isCloseButtonEnabled={true}
        modalTitle="Excess Payment"
        wrapperClass="excess-amount-alert"
        ref={props.openRef}
        onCloseFunction={props.onClose}
      />
    </>
  );
};
