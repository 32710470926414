import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { exportInvoices, exportSubscriptions } from "./exportDataAPI";

export const exportSubscription = createAsyncThunk(
  "subscription/export",
  async (data: { payload: any; orgId: number }) => {
    return await exportSubscriptions(data);
  }
);

export const exportInvoice = createAsyncThunk(
  "invoice/export",
  async (data: { payload: any; orgId: number }) => {
    return await exportInvoices(data);
  }
);

const initialState = {};
export const exportDataSlice = createSlice({
  name: "ExportData",
  initialState,
  reducers: {},
});
export default exportDataSlice.reducer;
