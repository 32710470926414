import React from "react";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import SendEmail from "../../../../../common/components/kebabMenu/SendEmail";
import DownloadPdf from "../../../../../common/components/kebabMenu/DownloadPdf";
import Delete from "../../../../../common/components/Delete";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";

type Props = {
  value: any;
  onClickSendMail: () => void;
  onClickDownloadPdf: () => void;
  onClickDelete: () => void;
};
function ListActions({
  value,
  onClickSendMail,
  onClickDownloadPdf,
  onClickDelete,
}: Props) {
  const { paymentsReceivedRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {paymentsReceivedRolePermission.includes("Update") ? (
        <li>
          <Edit
            url={`/payment-received/edit/${value.id}`}
            isMutable={value.isMutable}
            name="Payment Received"
            isLocked={value.isLocked}
            className={"dropdown-item"}
            lockDate={value.lockDate}
            isFiscalClosed={value.is_fiscal_closed}
          />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <li>
          <SendEmail onClick={onClickSendMail} />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <li>
          <DownloadPdf onClick={onClickDownloadPdf} />
        </li>
      ) : null}
      {paymentsReceivedRolePermission.includes("Delete") ? (
        <li>
          <Delete
            className={"dropdown-item delete"}
            deleteIcon={true}
            isMutable={value.isMutable}
            onClick={onClickDelete}
            isLocked={value.isLocked}
            lockDate={value.lockDate}
            name="Payment Received"
            isFiscalClosed={value.is_fiscal_closed}
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default React.memo(ListActions);
