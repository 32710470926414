import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCustomFieldValues } from "../../../../../appSlice";
import CustomFieldValidation from "./customFieldValidation";
import { useFormik } from "formik";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import {
  checkCustomFieldExists,
  createCustomField,
  editCustomField,
  getCustomFieldTotalList,
} from "../customFieldAPIFiles/customFieldSlice";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import "./customFieldForm.css";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import React, { useEffect } from "react";

type Props = {
  module: string;
  action?: string;
  handleClose: () => void;
};
export const CustomFieldForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const customFieldValue = useAppSelector(getCustomFieldValues);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const initialValues =
    props.action === "edit"
      ? {
          field_name: customFieldValue.field_name,
          mandatory_field: customFieldValue.mandatory_field,
          show_in_pdf: customFieldValue.show_in_pdf,
        }
      : {
          field_name: "",
          mandatory_field: true,
          show_in_pdf: !["Vendor", "Customer"].includes(props.module)
            ? false
            : true,
        };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: CustomFieldValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const responseAction =
        props.action === "edit" && customFieldValue.id
          ? await dispatch(
              editCustomField({
                orgId: currentUserInfo.organization_id,
                value: formik.values,
                custom_field_id: customFieldValue.id,
              })
            )
          : await dispatch(
              createCustomField({
                orgId: currentUserInfo.organization_id,
                value: formik.values,
                transaction_type: props.module,
              })
            );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        SuccessToaster(
          props.action === "edit"
            ? "Custom field updated successfully!"
            : "Custom field created successfully!",
          "custom-field-create-edit"
        );
        await dispatch(
          getCustomFieldTotalList({ orgId: currentUserInfo.organization_id })
        );
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
      props.handleClose && props.handleClose();
    },
  });
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    formik.dirty.valueOf() &&
      checkFieldNameExists(formik.values.field_name, signal);
    return () => {
      controller.abort();
    };
  }, [formik.values.field_name]);
  const checkFieldNameExists = async (name: string, signal: AbortSignal) => {
    const encodedName = encodeURIComponent(name);
    const responseAction = await dispatch(
      checkCustomFieldExists({
        field_name: encodedName,
        orgId: currentUserInfo.organization_id,
        module: props.module,
        signal: signal,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      response.field_name_available === false && formik.dirty.valueOf()
        ? formik.setFieldError("field_name", "Custom field already exists!")
        : formik.setFieldError("field_name", undefined);
    }
  };
  return (
    <div className="custom-field-wrapper">
      <form className="custom-field-form">
        <FormInputField
          type="text"
          name="field_name"
          id="field_name"
          className={"form-control"}
          placeholder="Enter field name"
          wrapperClass="field-name-input"
          maxLength={30}
          value={formik.values.field_name}
          onChange={(e) => {
            if (e.target?.value && e.target?.value.includes(".")) {
              formik.setFieldValue(
                "field_name",
                e.target?.value?.replaceAll(".", "")
              );
            } else {
              formik.setFieldValue("field_name", e.target?.value);
            }
          }}
          isRequired={true}
          error={formik.errors.field_name}
          disabled={formik.isSubmitting}
          label="Field Name"
        />
        <div className="row mb-3 p-3">
          <FormControl
            className="form-control-custom-field-radio-button"
            disabled={formik.isSubmitting}
          >
            <div className="link-label-grouped">
              <label htmlFor="mandatory_field" className=" fw-bold">
                Mandatory Field
              </label>
            </div>
            <RadioGroup
              row
              className="custom-field-radio-group"
              aria-labelledby="mandatory_field"
              name="mandatory_field"
              id="mandatory_field"
              value={formik.values.mandatory_field}
            >
              <FormControlLabel
                className="custom-field-form-control-label"
                value={true}
                control={
                  <Radio
                    className="custom-field-radio-btn"
                    onClick={() =>
                      formik.setFieldValue("mandatory_field", true)
                    }
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                className="custom-field-form-control-label"
                value={false}
                control={
                  <Radio
                    className="custom-field-radio-btn"
                    onClick={() =>
                      formik.setFieldValue("mandatory_field", false)
                    }
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
          {!["Vendor", "Customer"].includes(props.module) && (
            <FormControl
              className="form-control-custom-field-radio-button"
              disabled={formik.isSubmitting}
            >
              <div className="link-label-grouped">
                <label htmlFor="show_in_pdf" className=" fw-bold">
                  Show in pdf
                </label>
              </div>
              <RadioGroup
                className="custom-field-radio-group"
                row
                aria-labelledby="show_in_pdf"
                name="show_in_pdf"
                id="show_in_pdf"
                value={formik.values.show_in_pdf}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      className="custom-field-radio-btn"
                      onClick={() => formik.setFieldValue("show_in_pdf", true)}
                    />
                  }
                  label="Yes"
                  className="custom-field-form-control-label"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio className="custom-field-radio-btn" />}
                  label="No"
                  className="custom-field-form-control-label"
                  onClick={() => formik.setFieldValue("show_in_pdf", false)}
                />
              </RadioGroup>
            </FormControl>
          )}
        </div>
        <div
          className=" justify-content-start cropper-btn-wrapper mt-1"
          id="send-email-button "
        >
          <SaveButton
            onClick={formik.handleSubmit}
            type="button"
            isDisabled={
              formik.isSubmitting || Object.values(formik.errors).length > 0
            }
            buttonContent="Save"
          />
          <SecondaryButton
            onClick={props.handleClose}
            type="button"
            isDisabled={formik.isSubmitting}
            buttonContent="Cancel"
          />
        </div>
      </form>
    </div>
  );
};
