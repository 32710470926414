import React, { useEffect, useState } from "react";
import "../styles/EditInputModal.css";
type Props = {
  title: string;
  inputType: string;
  currentValue: string;
  inputId: string;
  label: string;
  maxLimit: number;
  saveAction: (inputValue: string) => void;
  cancelAction: () => void;
  setError?: (value: string) => void;
  error?: string;
  isMandatory: boolean;
};

export default function EditInputModal(props: Props) {
  const moduleTitle: string = props.title;
  const inputType: string = props.inputType;
  const inputID: string = props.inputId;
  const ariaLabel: string = props.label;
  const initialValue: string = props.currentValue;
  const lengthMax: number = props.maxLimit;
  const saveHandler = props.saveAction;
  const cancelHandler = props.cancelAction;
  const [inputValue, setInput] = useState("");

  useEffect(() => {
    setInput(initialValue);
  }, [initialValue]);
  const handleInput = (e: any) => {
    const _target = e.target;
    props.setError && props.setError("");
    setInput(_target.value?.replace("~", ""));
  };
  return (
    <>
      <div id="editInputPopup" className="editInputPopup" tabIndex={-1}>
        <div className="card card-body">
          <div className="row mb-0">
            <div className={"col"}>
              <label
                htmlFor={inputID}
                className={`${props.isMandatory === true ? "required" : ""}`}
              >
                {moduleTitle}
              </label>
              <input
                type={inputType}
                className={`form-control ${inputID} ${
                  props.error ? "error" : ""
                }`}
                placeholder="Enter prefix"
                name={inputID}
                id={inputID}
                aria-label={ariaLabel}
                value={inputValue}
                maxLength={lengthMax}
                onChange={handleInput}
              />
              <span className="error">{props.error}</span>
              <div className="form-button-wrapper">
                <button
                  type="button"
                  className="save-button"
                  data-bs-toggle="collapse"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    saveHandler(inputValue);
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  className="cancel-button"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    cancelHandler();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
