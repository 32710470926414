import React, {
  forwardRef,
  useEffect,
  useRef,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { toast } from "react-toastify";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { ObjectType, Transactionlimit } from "../../../../../../types";
import {
  dateSortOrderSelector,
  endCountSelector,
  filterAppliedState,
  itemOffsetSelector,
  itemsPerPageSelector,
  listFilterSelector,
  pageCountSelector,
  pageSelector,
  setListDateSortOrder,
  setListEndCount,
  setListItemOffset,
  setListItemsPerPage,
  setListPage,
  setListPageCount,
  setListStartCount,
  startCountSelector,
} from "../../../../../../appSlice";
import {
  getSystemVoucherLink,
  getTransactionLimitStatus,
  systemVoucherList,
  sendSystemVoucherData,
} from "../../../systemVoucherSlice";
import {
  DateFormatHandler,
  downloadUsingURL,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../../helpers/helper";
import {
  currentUserSelector,
  fiscalYearFilterSelector,
  orgCurrencyListSelector,
  reportOrgSelector,
  dateListFilterSelector,
  dueDateListFilterSelector,
  setLoaderState,
} from "../../../../../common/commonSlice";
import {
  getModuleStatusFilter,
  getStatusFilterString,
  getTagFilterString,
} from "../../../../../../helpers/statusFilterHelper";
import {
  systemVoucherColumnValues,
  systemVoucherDefaultValues,
  systemVoucherMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import SystemVoucherTable from "./SystemVoucherTable";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import TableSetup from "../../../../../common/components/table/TableSetup";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import SendEmail from "../../../../../common/components/kebabMenu/SendEmail";
import DownloadPdf from "../../../../../common/components/kebabMenu/DownloadPdf";
import Delete from "../../../../../common/components/Delete";
import CustomTooltips from "../../../../../common/components/Tooltip";
import { SystemVoucherDataList } from "../../types";
import DeleteSystemVoucher from "../../Delete";

interface FiltersType {
  [category: string]: { id: number; name: string }[];
}
type OrgListObjectType = {
  id: number;
  name: string;
};

type SelectedFilterType = {
  Organizations: number[];
  Status: number[];
};

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, listNum: string, status: string) => void;
};

const SystemVoucherTableContainer = (props: Props, ref: any) => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const page = useAppSelector(pageSelector);
  const itemsPerPage = useAppSelector(itemsPerPageSelector);
  const startCount = useAppSelector(startCountSelector);
  const endCount = useAppSelector(endCountSelector);
  const pageCount = useAppSelector(pageCountSelector);
  const itemOffset = useAppSelector(itemOffsetSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const dateSortOrder = useAppSelector(dateSortOrderSelector);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterAppliedState = useAppSelector(filterAppliedState);
  const orgList = useAppSelector(reportOrgSelector);
  const { journalVoucherRolePermission } = usePermissionHandler(); //change
  const [systemVoucherData, setSystemVoucherData] = useState<
    SystemVoucherDataList[]
  >([]);
  const [totalSystemVouchers, setTotalSystemVouchers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteSystemVoucherItemId, setDeleteSystemVoucherItemId] =
    useState("");
  const [deleteSystemVoucherId, setDeleteSystemVoucherId] = useState(-1);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [systemVoucherId, setSystemVoucherId] = useState(0);
  const [orgListFilter, setOrgListFilter] = useState<OrgListObjectType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Reference",
    "Notes",
    "Created By",
  ]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let journalVoucherPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Journal Entry"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useImperativeHandle(
    ref,
    () => ({
      fetchSystemVoucherList: fetchSystemVoucherList,
    }),
    []
  );

  //   useEffect(() => {
  //     if (!Object.keys(selectedFilterData).length) {
  //       initializeFilter();
  //     }
  //   }, []);
  useEffect(() => {}, []);

  useEffect(() => {
    fetchTableCustomizationFields(
      "System Voucher",
      systemVoucherDefaultValues,
      systemVoucherMandatoryValues
    );
    fetchSystemVoucherList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    // JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  // useEffect(() => {
  //   if (isFilterAppliedState) fetchSystemVoucherList();
  // }, [isFilterAppliedState, selectedFilterData]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    systemVoucherColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [systemVoucherData, selectedColumns]);

  useEffect(() => {
    getOrgList();
  }, [orgList]);

  useEffect(() => {
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  const setPage = (param: number) => {
    dispatch(setListPage(param));
  };
  const setItemsPerPage = (param: number) => {
    dispatch(setListItemsPerPage(param));
  };
  const setStartCount = (param: number) => {
    dispatch(setListStartCount(param));
  };
  const setEndCount = (param: number) => {
    dispatch(setListEndCount(param));
  };
  const setPageCount = (param: number) => {
    dispatch(setListPageCount(param));
  };
  const setItemOffset = (param: number) => {
    dispatch(setListItemOffset(param));
  };
  const setDateSortOrder = (param: string) => {
    dispatch(setListDateSortOrder(param));
  };
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Fetch all system vouchers
   */
  const fetchSystemVoucherList = async () => {
    fetchTransactionLimit();
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      systemVoucherList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        tagList: selectedFilterData.Tag
          ? getTagFilterString(selectedFilterData.Tag)
          : [],
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
        voucherConfigIds: selectedFilterData.SystemVoucherType
          ? selectedFilterData.SystemVoucherType
          : [],
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload as any;

      if (!("error" in response)) {
        setSystemVoucherData(response.journals);
        let length = response.journals.length;
        setTotalSystemVouchers(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalSystemVouchers / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount !== 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalSystemVouchers, page]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalSystemVouchers;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const sortHandler = (column: string) => {
    if (column === "Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };

  /**
   * Set the contact emails
   */
  const fetchContactEmail = (id: number, emailsList: any[]) => {
    let emails = emailsList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setSystemVoucherId(id);
  };

  const downloadPdf = async (systemVoucherId: number) => {
    const responseAction = await dispatch(
      getSystemVoucherLink({
        systemVoucherId: systemVoucherId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          systemVoucherId + ".pdf"
        );
        if (result) {
          toast.success("System Voucher note downloaded successfully!", {
            toastId: "jounal-voucher-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };

  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  const getJournalColumnValues = () => {
    // let newJournalColumnValues = journalColumnValues;
    // if (
    //   currentUserInfo.organization_tax_system ===
    //     TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
    //   !newJournalColumnValues.includes("Tax Deducted(TDS)")
    // ) {
    //   newJournalColumnValues.push("Tax Deducted(TDS)");
    // }
    // return newJournalColumnValues;
    return systemVoucherColumnValues;
  };

  /**
   * Set delete data details
   */
  const deleteListData = (id: number, listNum: string) => {
    setDeleteSystemVoucherId(id);
    setDeleteSystemVoucherItemId(listNum);
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "System Voucher Type",
        accessor: "show.systemVoucherType",
        sort: false,
        sortOrder: "",
        className: "sv_type",
      },
      {
        Header: "System Voucher #",
        accessor: "show.systemVoucherNum",
        sort: false,
        sortOrder: "",
        className: "jv_num",
      },
      {
        Header: "Reference #",
        accessor: selectedColumns.includes("Reference")
          ? "show.reference"
          : "Reference",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "jv_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value?.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Notes",
        accessor: selectedColumns.includes("Notes") ? "show.notes" : "Notes",
        sort: false,
        sortOrder: "",
        className: "notes",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement="bottom"
              title={value?.length > 15 ? value : ""}
            >
              <span className="jv_notes">
                {value && value.slice(0, 40).trim()}
                {value?.length > 40 ? "..." : ""}
              </span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "amount",
      },
      {
        Header: "Created By",
        accessor: selectedColumns.includes("Created By")
          ? "show.createdBy"
          : "Created By",
        sort: false,
        sortOrder: "",
        className: "created_by",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="System Voucher"
            tableColumns={selectedColumns}
            customizeColumnValues={systemVoucherColumnValues}
            mandatoryColumns={systemVoucherMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "System Voucher",
                systemVoucherDefaultValues,
                systemVoucherMandatoryValues
              );
              fetchSystemVoucherList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          //check
          return (!journalVoucherPermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <KebabMenu>
              {journalVoucherPermission.includes("Update") &&
              value.isMutable ? (
                <li>
                  <Edit
                    url={`/system-voucher/edit/${value.id}`}
                    isMutable={true}
                    name="System Voucher"
                    isLocked={value.isLocked}
                    className={"dropdown-item"}
                    lockDate={value.lockDate}
                    isFiscalClosed={value.is_fiscal_closed}
                  />
                </li>
              ) : null}
              {value.status !== "Draft" ? (
                <>
                  <li>
                    <SendEmail
                      onClick={() => fetchContactEmail(value.id, value.emailTo)}
                    />
                  </li>
                  <li>
                    <DownloadPdf onClick={() => downloadPdf(value.id)} />
                  </li>
                </>
              ) : null}
              {journalVoucherPermission.includes("Delete") &&
              value.isMutable ? (
                <li>
                  <Delete
                    className={"dropdown-item delete"}
                    deleteIcon={true}
                    isMutable={true}
                    onClick={() => deleteListData(value.id, value.listName)}
                    isLocked={value.isLocked}
                    lockDate={value.lockDate}
                    name="System Voucher"
                    isFiscalClosed={value.is_fiscal_closed}
                  />
                </li>
              ) : null}
            </KebabMenu>
          );
        },
      },
    ],
    [
      dateSortOrder,
      currentUserInfo.organization_id,
      selectedColumns,
      hiddenColumns,
    ]
  );
  /**
   * set table row data
   */
  const data = systemVoucherData
    ? systemVoucherData.map((system_voucher, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            system_voucher.date
          ),
          systemVoucherType: system_voucher.system_voucher_type,
          systemVoucherNum: joinTransactionNumber(
            `${system_voucher.journal_voucher_number}`
          ),
          reference: system_voucher.reference_number,
          status: system_voucher.journal_status,
          notes: system_voucher.notes,
          amount:
            system_voucher.total_amount !== "-"
              ? system_voucher.currency_code +
                " " +
                NumberFormat(
                  Number(system_voucher.total_amount),
                  system_voucher.currency_code,
                  orgCurrencyList
                )
              : system_voucher.total_amount,
          createdBy: system_voucher.created_by,
          organization: system_voucher.organization,
          actions: {
            id: system_voucher.id,
            status: system_voucher.journal_status,
            listName: system_voucher.journal_voucher_number,
            emailTo: system_voucher.email_to,
            organizationId: system_voucher.organization_id,
            isLocked: system_voucher.is_locked,
            lockDate: system_voucher.lock_date,
            isMutable: system_voucher.is_mutable,
            is_fiscal_closed: system_voucher.is_fiscal_closed,
          },
        },
      }))
    : [];

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendSystemVoucherData({
        systemVoucherId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchSystemVoucherList();
      }
    }
    return responseAction;
  };

  /**
   * fetching org list for filters
   */
  const getOrgList = async () => {
    let orgsList: OrgListObjectType[] = [];
    if (orgList.length) {
      orgList.map((orgs: ObjectType) => {
        const { name, id } = orgs;
        orgsList.push({ name: name, id: id });
      });
    }
    setOrgListFilter(orgsList);
  };

  /**
   * Setting filter options for the ListFilter Component
   */
  const Filters: FiltersType[] = [
    {
      Organizations: orgListFilter,
    },
    {
      Status: getModuleStatusFilter("JV"),
    },
  ];
  return (
    <>
      <SystemVoucherTable
        totalList={totalSystemVouchers}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        JournalEntryRolePermission={journalVoucherRolePermission} //check
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
        pageCount={pageCount}
        isLoading={isLoading}
        handlePageClick={handlePageClick}
      />
      <SendEmailModal
        emailLists={emailList}
        id={systemVoucherId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteSystemVoucher
        deleteSystemVoucherId={deleteSystemVoucherId}
        deleteSystemVoucherItemId={deleteSystemVoucherItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshJournals={fetchSystemVoucherList}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
};

export default React.memo(forwardRef(SystemVoucherTableContainer));
