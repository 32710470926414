import { useFormik } from "formik";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import {
  setLoaderState,
  useCommonData,
  useEffect,
  useState,
} from "../../paymentsReceived/components/paymentReceivedForm/imports";
import { ObjectType } from "../../systemVoucher/components/types";
import {
  createPaymentGatewayConfig,
  getPaymentGatewayConfig,
  updatePaymentGatewayConfig,
} from "../PaymentGatewaySlice";
import { paymentGatewayConfigSchema } from "../resources/paymentGatewayValidation";
import { supportedPaymentGateways } from "../resources/supportedPaymentGateways";
import { PaymentGatewayConfigUpdateValues } from "../types";
export const usePaymentGateway = ({
  setIsSetup,
  isSetup,
}: {
  setIsSetup: (value: boolean) => void;
  isSetup: boolean;
}) => {
  const { dispatch, currentUserInfo } = useCommonData();
  // const [isSetup, setIsSetup] = useState(false);
  const keyPattern = "***************************";
  const {
    handleChange,
    handleReset,
    handleSubmit,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
    isValid,
    validateForm,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
  } = useFormik({
    initialValues: {
      selected_payment_gateway: supportedPaymentGateways[0],
      supported_payment_gateways: supportedPaymentGateways,
    },
    validationSchema: paymentGatewayConfigSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.selected_payment_gateway.id) {
        handleUpdatePaymentGatewayConfig({
          ...values.selected_payment_gateway,
          id: Number(values.selected_payment_gateway.id),
          chart_of_account_id: Number(
            values.selected_payment_gateway.chart_of_account_id
          ),
        });
      } else {
        handleCreatePaymentGatewayConfig(values.selected_payment_gateway);
      }
    },
  });

  {
    /* Function to get list of configured payment gateways */
  }
  useEffect(() => {
    handleGetPaymentGatewayConfig();
  }, [currentUserInfo.organization_id]);

  {
    /* Function to remove keys with value pattern */
  }
  function removeKeysWithValuePattern<T extends Record<string, any>>(
    obj: T
  ): ObjectType {
    const filteredObject = Object.keys(obj).reduce((acc, key) => {
      if (["api_key", "api_secret", "webhook_secret"].includes(key)) {
        if (!obj[key]?.includes(keyPattern.slice(0, 3))) {
          acc[key] = obj[key];
        }
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {} as ObjectType);
    return filteredObject;
  }
  {
    /* Function to get list of configured payment gateways */
  }
  const handleGetPaymentGatewayConfig = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getPaymentGatewayConfig({
        orgId: currentUserInfo.organization_id,
        values: { skip: 0, limit: 10 },
      })
    ).finally(() => {
      dispatch(setLoaderState(false));
    });
    const response = responseAction.payload;
    if (response) {
      if (response.length) {
        let tempDefault = response.find((item: any) => item.is_default);
        let temp = supportedPaymentGateways.map((value) => {
          const found = response.find(
            (item: any) => item.gateway_type === value.gateway_type
          );
          if (found) {
            return {
              ...value,
              id: found?.id,
              chart_of_account_id: found?.chart_of_account_id,
              chart_of_account_name: found?.account_name,
              is_active: found?.is_active,
              is_default: found?.is_default,
              api_key: keyPattern,
              api_secret: keyPattern,
              webhook_secret: keyPattern,
              metadata: {},
              mapping_metadata: {},
            };
          } else {
            return { ...value, is_active: false };
          }
        });
        setFieldValue(
          "selected_payment_gateway",
          temp.find((item: any) => item.is_default || item.is_active)
        );
        setFieldValue(
          "supported_payment_gateways",
          temp.length ? temp : supportedPaymentGateways
        );
      }
    }
  };
  {
    /* Function to create payment gateway config for the selected payment gateway */
  }
  const handleCreatePaymentGatewayConfig = async (values: any) => {
    dispatch(setLoaderState(true));
    const { logo, name, description, config, id, ...payload } = values;
    const responseAction = await dispatch(
      createPaymentGatewayConfig({
        orgId: currentUserInfo.organization_id,
        values: { ...payload, is_default: true },
      })
    ).finally(() => {
      dispatch(setLoaderState(false));
    });
    const response = responseAction.payload;
    if (!("error" in response)) {
      setIsSetup(false);
      handleGetPaymentGatewayConfig();
      SuccessToaster(
        "Payment gateway configuration created successfully!",
        "paymentGatewayConfigCreated"
      );
    }
  };
  {
    /* Function to update payment gateway config for the selected payment gateway */
  }
  const handleUpdatePaymentGatewayConfig = async (
    values: PaymentGatewayConfigUpdateValues
  ) => {
    const filteredValues = removeKeysWithValuePattern(values);
    const { logo, name, description, config, ...payload } = filteredValues;
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updatePaymentGatewayConfig({
        orgId: currentUserInfo.organization_id,
        values: {
          ...payload,
          is_default: true,
        } as PaymentGatewayConfigUpdateValues,
      })
    ).finally(() => {
      dispatch(setLoaderState(false));
    });
    const response = responseAction.payload;

    if (!("error" in response)) {
      setIsSetup(false);
      handleGetPaymentGatewayConfig();
      SuccessToaster(
        "Payment gateway configuration updated successfully!",
        "paymentGatewayConfigUpdated"
      );
    }
  };
  return {
    handleChange,
    handleSubmit,
    values,
    touched,
    isValid,
    validateForm,
    errors,
    isSubmitting,
    // isSetup,
    // setIsSetup,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
  };
};
