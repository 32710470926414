import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../../app/hooks";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import { TAX_SYSTEM } from "../../../../../constants/constants";
import {
  getInvoiceTypeFilterString,
  getStatusFilterString,
} from "../../../../../../helpers/statusFilterHelper";
import {
  InvoiceList,
  ObjectType,
  Transactionlimit,
} from "../../../../../../types";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import {
  dateListFilterSelector,
  dueDateListFilterSelector,
  fiscalYearFilterSelector,
  setLoaderState,
} from "../../../../../common/commonSlice";
import {
  getInvoicePdfLink,
  invoiceList,
  sendInvoiceData,
} from "../../../invoiceSlice";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import {
  StatusColor,
  downloadUsingURL,
  getProfileFormatedDate,
} from "../../../../../../helpers/helper";
import {
  invoiceColumnValues,
  invoiceDefaultValues,
  invoiceMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import TableSetup from "../../../../../common/components/table/TableSetup";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import CustomTooltips from "../../../../../common/components/Tooltip";
import EmailPopover from "../../../../../common/components/EmailPopover";
import useCommonData from "../../../../../hooks/useCommon";
import useListData from "../../../../../hooks/useListData";
import InvoiceTable from "./InvoiceTable";
import ListActions from "./ListActions";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, name: string) => void;
};
function InvoiceTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { customerRolePermission, invoicesRolePermission } =
    usePermissionHandler();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const [invoiceData, setInvoiceData] = useState<InvoiceList[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["Terms"]);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [invoiceId, setInvoiceId] = useState(0);
  const [invoiceToPay, setInvoiceToPay] = useState<ObjectType>({});
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();

  useImperativeHandle(
    ref,
    () => ({
      fetchInvoiceList: fetchInvoiceList,
    }),
    []
  );
  const [moduleListItemDates, setModuleListItemDates] = useState({
    date: { startDate: "", endDate: "" },
  });
  useEffect(() => {
    fetchTableCustomizationFields(
      "Invoices",
      invoiceDefaultValues,
      invoiceMandatoryValues
    );
    fetchInvoiceList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchInvoiceList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    invoiceColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    if (
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !selectedColumns.includes("Tax Deducted(TDS)")
    ) {
      hiddenItems.push("Tax Deducted(TDS)");
    }
    setHiddenColumns([...hiddenItems]);
  }, [invoiceData, selectedColumns]);

  useEffect(() => {
    // setModuleListItemDates(fiscalYearFilter);
  }, [fiscalYearFilter]);
  useEffect(() => {
    setModuleListItemDates(dateListFilter);
  }, [dateListFilter]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch all invoices
   */
  const fetchInvoiceList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const customerList = selectedFilterData.hasOwnProperty("Customers")
      ? selectedFilterData?.Customers
      : [];
    const responseAction = await dispatch(
      invoiceList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        customerList: customerList,
        // endDate: fiscalYearFilter.endDate || "",
        // startDate: fiscalYearFilter.startDate || "",
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate,
        endDueDate: dueDateListFilter.dueDate.endDate,
        invoiceType: selectedFilterData?.InvoiceType
          ? getInvoiceTypeFilterString(selectedFilterData.InvoiceType)
          : [],
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setInvoiceData(response.invoices);
        let length = response.invoices.length;
        setTotalList(response.total);
        if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    selectedFilterData,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    // selectedFilterData.Status,    ,
    dueDateListFilter,
    dateListFilter,
  ]);

  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  /**
   * set table column headings
   */
  const getInvoiceColumnValues = () => {
    let newInvoiceColumnValues = JSON.parse(
      JSON.stringify(invoiceColumnValues)
    );
    if (
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !newInvoiceColumnValues.includes("Tax Deducted(TDS)")
    ) {
      newInvoiceColumnValues.push("Tax Deducted(TDS)");
    }
    return newInvoiceColumnValues;
  };
  const columns = useMemo(() => {
    let columns = [
      {
        Header: "Invoice Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Invoice Number",
        accessor: "show.invoiceId",
        sort: false,
        sortOrder: "",
        className: "inv_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Customer Name",
        accessor: "show.customerName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value?.customer_name : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "inv_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Due Date",
        accessor: selectedColumns.includes("Due Date")
          ? "show.dueDate"
          : "Due Date",
        sort: false,
        sortOrder: "",
        className: "due_date",
      },
      {
        Header: "Total",
        accessor: "show.invoiceAmount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Balance Due",
        accessor: "show.balanceDue",
        sort: false,
        sortOrder: "",
        className: "balance_due",
      },
      {
        Header: "Billing Address",
        accessor: selectedColumns.includes("Billing Address")
          ? "show.billingAddress"
          : "Billing Address",
        sort: false,
        sortOrder: "",
        className: "billing_addr",
      },
      {
        Header: "Created By",
        accessor: selectedColumns.includes("Created By")
          ? "show.createdBy"
          : "Created By",
        sort: false,
        sortOrder: "",
        className: "created_by",
      },
      {
        Header: "Due Days",
        accessor: selectedColumns.includes("Due Days")
          ? "show.dueDays"
          : "Due Days",
        sort: false,
        sortOrder: "",
        className: "due_days",
      },
      {
        Header: "Email",
        accessor: selectedColumns.includes("Email") ? "show.email" : "Email",
        sort: false,
        sortOrder: "",
        className: "email",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? <EmailPopover values={value} /> : "-";
        },
      },
      {
        Header: "Shipping Address",
        accessor: selectedColumns.includes("Shipping Address")
          ? "show.shippingAddress"
          : "Shipping Address",
        sort: false,
        sortOrder: "",
        className: "shipping_addr",
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Invoices"
            tableColumns={selectedColumns}
            customizeColumnValues={getInvoiceColumnValues()}
            mandatoryColumns={invoiceMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Invoices",
                invoiceDefaultValues,
                invoiceMandatoryValues
              );
              fetchInvoiceList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!invoicesRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              write_off_values={{
                max_amount: invoiceData.find((data) => data.id === value.id)
                  ?.balance_due,
                currency_code: invoiceData.find((data) => data.id === value.id)
                  ?.currency_code,
              }}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id)}
              onClickPayment={() => {
                setOpenModalFlag(true);
                let invoice = invoiceData.find((data) => data.id === value.id);
                if (invoice) setInvoiceToPay(invoice);
              }}
              onClickDelete={() => {
                if (value.isMutable) {
                  props.deleteListData(value.id, value.listItemId);
                }
              }}
              fetchInvoiceList={fetchInvoiceList}
            />
          );
        },
      },
    ];
    if (
      currentUserInfo.organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM
    ) {
      let index = columns.length - 1;
      columns.splice(index - 1, 0, {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      });
    }
    return columns;
  }, [
    dateSortOrder,
    selectedColumns,
    hiddenColumns,
    currentUserInfo.organization_id,
  ]);
  /**
   * set table row data
   */
  const data = invoiceData
    ? invoiceData.map((invoice) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            invoice.invoice_date
          ),
          invoiceId: joinTransactionNumber(invoice.Invoice_ID),
          referenceNumber: invoice.reference_number,
          customerName: invoice.customer_name,
          status: invoice.invoice_status,
          dueDate: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            invoice.due_date
          ),
          invoiceAmount:
            getCurrencyCode(invoice.currency_code) +
            " " +
            NumberFormat(invoice.total, invoice.currency_code, orgCurrencyList),
          balanceDue: invoice.balance_due
            ? getCurrencyCode(invoice.currency_code) +
              " " +
              NumberFormat(
                invoice.balance_due,
                invoice.currency_code,
                orgCurrencyList
              )
            : getCurrencyCode(invoice.currency_code) +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  invoice.currency_code
                    ? invoice.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          billingAddress: invoice.billing_address,
          createdBy: invoice.created_by,
          dueDays: invoice.due_days,
          email: invoice.email_to
            .map((emailData) => emailData.email)
            .join(", "),
          shippingAddress: invoice.shipping_address,
          totalTaxableAmount:
            getCurrencyCode(invoice.currency_code) +
            " " +
            NumberFormat(
              invoice.total_taxable_amount,
              invoice.currency_code,
              orgCurrencyList
            ),
          tds:
            getCurrencyCode(invoice.currency_code) +
            " " +
            NumberFormat(
              invoice.tax_deducted,
              invoice.currency_code,
              orgCurrencyList
            ),
          organization: invoice.organization,
          actions: {
            id: invoice.id,
            status: invoice.invoice_status,
            listItemId: invoice.Invoice_ID,
            emailTo: invoice.email_to,
            isMutable: invoice.is_mutable,
            organizationId: invoice.organization_id,
            isLocked: invoice.is_locked,
            lockDate: invoice.lock_date,
            balanceDue: invoice.balance_due,
            currencyCode: invoice.currency_code,
            writeOffId: invoice.write_off_id,
            transactionDate: invoice.invoice_date,
            is_fiscal_closed: invoice.is_fiscal_closed,
            subscription_id: invoice.subscription_id,
            is_deferred_associated: invoice.is_deferred_associated,
          },
        },
      }))
    : [];

  /**
   * Set the contact email in a state of selected invoice
   */
  const fetchContactEmail = (id: number, emailList: any[]) => {
    let emails = emailList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setInvoiceId(id);
  };
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Invoice Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * Download Pdf handler
   */
  const downloadPdf = async (invoiceId: number) => {
    const responseAction = await dispatch(
      getInvoicePdfLink({
        invoiceId: invoiceId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          invoiceId + ".pdf"
        );
        if (result) {
          toast.success("Invoice downloaded successfully!", {
            toastId: "invoice-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendInvoiceData({
        invoiceId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchInvoiceList();
      }
    }
    return responseAction;
  };
  return (
    <>
      <InvoiceTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        invoicesRolePermission={invoicesRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={invoiceId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(InvoiceTableContainer));
