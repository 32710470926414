import React, { useEffect, useState } from "react";
import Select from "react-select";
import { initialSubAccountValues } from "../../../home/paymentsMade/components/createBillPayment/StateInitialization";
import { SubAccountValues } from "../../../../types";
import useCommonData from "../../../hooks/useCommon";
import { fetchBankAccounts } from "../../../home/chartOfAccounts/coaSlice";
import { customSelectStyle } from "../SelectCustomStyle";
import "./paymentBankSelect.css";
import { colors } from "../../../constants/colors";

type Props = {
  name: string;
  currencyId: number;
  accountId: number | string;
  handleAccountChange: (e: any) => void;
  source: string;
  errors: string;
  isDisabled?: boolean;
  getSelectedAccount?: (account: SelectedAccountProps) => void;
};
type SelectedAccountProps = {
  id: number;
  account_name: string;
  currency_code?: string | null | undefined;
  currency_id?: number | null;
};
function PaymentBankSelect(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<SubAccountValues[]>([
    initialSubAccountValues,
  ]);

  useEffect(() => {
    getBankAccounts();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    props.getSelectedAccount &&
      props.getSelectedAccount(
        bankAccounts.filter(
          (subAccount) => subAccount.id === Number(props.accountId)
        )[0]
      );
  }, [props.accountId]);
  /**
   * Get Bank accounts list drop down Api
   * There was an update here to list accounts in customer currency before sprint-30 prod release to change the account dropdown
   */
  const getBankAccounts = async () => {
    const responseAction = await dispatch(
      fetchBankAccounts({
        currencyId: props.currencyId,
        orgId: currentUserInfo.organization_id,
        transaction_source: props.source,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBankAccounts(response);
      }
    }
  };
  /**
   * Get the bank account name in the selection dropdown : Edit Bill Payment
   */
  const bankAccountName = bankAccounts
    .filter((subAccount) => subAccount.id === Number(props.accountId))
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));
  /**
   * Get the list of all bank  accounts
   */
  const bankAccountList = bankAccounts.map((account) => ({
    label: account.account_name,
    value: account.id,
    account_type: account.account_type,
  }));

  const LabelWithRoot = ({ props }: any) => {
    const { innerRef, innerProps, isFocused, isSelected, children, data } =
      props;
    return (
      <div
        // onClick={() => {
        //   props.handleAccountChange({ value: data.value, label: data.label });
        //   setMenuIsOpen(false);
        // }}
        className="custom-select-option-wrapper"
        style={{
          backgroundColor: isFocused || isSelected ? colors.whisper : "white",
        }}
        {...innerProps}
      >
        <span className="custom-select-option-account-name">{data.label}</span>
        {data.account_type && (
          <div className="custom-select-option-account-root">
            {data.account_type}
          </div>
        )}
      </div>
    );
  };

  return (
    <Select
      name="customer_id"
      isDisabled={props.isDisabled}
      value={bankAccountName}
      options={bankAccountList}
      placeholder="Choose"
      onChange={props.handleAccountChange}
      className={`form-select custom-select customer-select paid_through  ${
        props.errors ? "error" : ""
      }`}
      styles={customSelectStyle}
      components={{ Option: (props) => <LabelWithRoot props={props} /> }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      classNamePrefix="react-select"
    />
  );
}

export default PaymentBankSelect;
