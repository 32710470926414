import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Span } from "../../../../../common/components/htmlTags/Span";
import { TableBody } from "../../../../../common/components/htmlTags/TableTags/TableBody";
import { TableCell } from "../../../../../common/components/htmlTags/TableTags/TableCell";
import { TableContainer } from "../../../../../common/components/htmlTags/TableTags/TableContainer";
import { TableHeader } from "../../../../../common/components/htmlTags/TableTags/TableHeader";
import { TableHeaderContainer } from "../../../../../common/components/htmlTags/TableTags/TableHeaderContainer";
import { TableRow } from "../../../../../common/components/htmlTags/TableTags/TableRow";
import useCommonData from "../../../../../hooks/useCommon";
import { useProcessStyle } from "../../hooks/useProcessStyle";
import { DataInput, ProcessedRow } from "../../types";
import { processData } from "../../utils/dataProcessor";
import { processHeader } from "../../utils/processHeader";
import {
    CumulativeVarianceCell,
    VarianceCell,
} from "./VarianceCell/VarianceCell";
import "./BudgetVarianceReportTable.css";
const BudgetVarianceReportTable = ({data, tableData}: {data: DataInput, tableData: ProcessedRow[]}) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    cellStyleProcessor,
    rowStyleProcessor,
    columnStyleProcessor,
    headerStyleProcessor,
  } = useProcessStyle();
  const formatNumber = useMemo(
    () => (value: number) =>
      NumberFormat(value, currentUserInfo.currency_code, orgCurrencyList),
    [currentUserInfo.currency_code, orgCurrencyList, data, tableData]
  );
  // Define the columns for the table
  const columns = useMemo<ColumnDef<ProcessedRow>[]>(
    () => [
      {
        accessorKey: "account_name",
        header: "Account",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <Span style={columnStyleProcessor(row, "account_name")}>
            {row.original.account_name}
          </Span>
        ),

        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "actual_total",
        header:
          processHeader(
            "actual_range",
            data.actual_date_range || "",
            data.budget_name || ""
          ) || "",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <Span>{row.original.is_category?"-":formatNumber(row.original.actual_total || 0)}</Span>
        ),
        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "budget_total",
        header:
          processHeader(
            "budget_range",
            data.actual_date_range || "",
            data.budget_name || ""
          ) || "",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <Span>{row.original.is_category?"-":formatNumber(row.original.budget_total || 0)}</Span>
        ),
        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "variance",
        header: "Variance",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <VarianceCell
            row={row}
            isPercentage={false}
            accountType={row.original.account_type}
            formatNumber={formatNumber}
          />
        ),
        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "variance_percent",
        header: "Variance %",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <VarianceCell
            row={row}
            isPercentage={true}
            accountType={row.original.account_type}
            formatNumber={formatNumber}
          />
        ),
        footer: (info: any) => info.column.id,
      },

      {
        accessorKey: "cumulated_total",
        header:
          processHeader(
            "actual_range",
            data.cumulated_date_range || "",
            data.budget_name || ""
          ) || "",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <Span>{row.original.is_category?"-":formatNumber(row.original.cumulated_total || 0)}</Span>
        ),
        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "cumulated_budget_total",
        header:
          processHeader(
            "budget_range",
            data.cumulated_date_range || "",
            data.budget_name || ""
          ) || "",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <Span>{row.original.is_category?"-":formatNumber(row.original.cumulated_budget_total || 0)}</Span>
        ),
        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "cumulated_variance",
        header: "Variance",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <CumulativeVarianceCell
            row={row}
            isPercentage={false}
            accountType={row.original.account_type}
            formatNumber={formatNumber}
          />
        ),
        footer: (info: any) => info.column.id,
      },
      {
        accessorKey: "cumulated_variance_percent",
        header: "Variance %",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <CumulativeVarianceCell
            row={row}
            isPercentage={true}
            accountType={row.original.account_type}
            formatNumber={formatNumber}
          />
        ),
        footer: (info: any) => info.column.id,
      },
    ],
    [data, formatNumber, tableData]
  );
  // Memoized function to format numbers

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.node || [],
    getRowCanExpand: (row) => !!row.original.node?.length,
  });
  return (
    <Container className="budget-variance-report-table-wrapper">
      <TableContainer className="budget-variance-report-table-container">
        <TableHeaderContainer className="budget-variance-report-table-header-container">
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    style={headerStyleProcessor(header)}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHeader>
                ))}
              </TableRow>
            );
          })}
        </TableHeaderContainer>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} style={rowStyleProcessor(row)}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id} style={cellStyleProcessor(row)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Container>
  );
};

export default BudgetVarianceReportTable;
