import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  smtpServerConfigFetch,
  smtpServerConfigUpdate,
} from "./SmtpsettingsAPI";
import { SmtpServerConfigUpdateValues } from "./types";

export const getSmtpServerConfig = createAsyncThunk(
  "smtpServerConfigFetch",
  async (data: { orgId: number }) => {
    return await smtpServerConfigFetch(data);
  }
);

export const updateSmtpServerConfig = createAsyncThunk(
  "updateSmtpServerConfig",
  async (data: {
    orgId: number;
    values: {
      encryption_type: string;
      is_active: boolean;
      smtp_server: string;
      port:number | string;
      username: string;
      password: string;
    };
  }) => {
    return await smtpServerConfigUpdate(data);
  }
);
