import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { getPrCreationPayload } from "../../../../../helpers/prHelpers/prCreateEditPayloadHelper";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../helpers/toastHelper";
import { ObjectType } from "../../../../../types";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { ScrollToFieldError } from "../../../../common/components";
import { message } from "../../../../constants/messages";
import { initialPRFormValues } from "../../../../home/paymentsReceived/components/paymentReceivedForm/initialFormValues";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import {
  getAmountCalculationResult,
  paymentReceivedDetails,
  setAmountCalculationResult,
  unpaidInvoiceList,
} from "../../paymentReceivedSlice";
import * as PRImports from "./imports";
import { usePr } from "./usePr";
import { PaymentDetailValidation } from "./validations/paymentDetailsValidation";
import { getFullyAllocatedSundryInvoiceIds } from "./utils/getFullyAllocatedInvoiceIds";
import { ConfirmationPopup } from "../../../../common/components/confirmationPopup/ConfirmationPopup";
import { Span } from "../../../../common/components/htmlTags/Span";

export const PaymentReceivedForm = () => {
  const customFieldRef = useRef<any>();
  const confirmationPopupRef = useRef<any>();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const navigate = useNavigate();
  const openExcessAllocationAlertRef = useRef<any>();
  const openEditAlertRef = useRef<any>();
  const [wasConfirmationPopupOpen, setConfirmationPopupWasOpened] =
    useState(false);
  // const [shouldPostTagsOfInvoice, setShouldPostTagsOfInvoice] = useState(false);
  const shouldPostTagsOfInvoice = useRef(false);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { paymentsReceivedRolePermission } = usePermissionHandler();
  const [deleteBillPaymentId, setDeleteBillPaymentId] = useState(-1);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [deleteBillPaymentItemId, setDeleteBillPaymentItemId] = useState("");
  const dispatch = useAppDispatch();
  const totalDetails = useAppSelector(getAmountCalculationResult).total_details;
  const { editId, customerId } = useParams();
  const [emailError, setEmailError] = useState("");
  const [initialFormValues, setInitialFormValues] = useState<ObjectType>({
    ...initialPRFormValues,
  });
  const [isUnpaidInvoiceExist, setIsUnpaidInvoiceExist] = useState(false);

  useEffect(() => {
    if (!editId) {
      formik.resetForm();
      dispatch(
        setAmountCalculationResult({
          total_details: {
            total: 0,
            witholding_total: 0,
            amount_used_payment: 0,
            amount_received: 0,
            bank_charges: 0,
            amount_excess: 0,
          },
          allocatable_amount: 0,
        })
      );
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (editId) {
      paymentDetailsResponse().finally(() => {
        dispatch(setLoaderState(false));
      });
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    return () => {
      dispatch(
        setAmountCalculationResult({
          total_details: {
            total: 0,
            witholding_total: 0,
            amount_used_payment: 0,
            amount_received: 0,
            bank_charges: 0,
            amount_excess: 0,
          },
          allocatable_amount: 0,
        })
      );
    };
  }, []);

  const formik = useFormik({
    initialValues: { ...initialFormValues },
    enableReinitialize: true,
    validationSchema: PaymentDetailValidation,
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {},
  });
  useEffect(() => {
    if (customerId) {
      setInitialFormValues((values) => {
        return {
          ...values,
          customer_id: Number(customerId),
          is_currency_changed: true,
        };
      });
    }
    return () => {
      setInitialFormValues({});
    };
  }, []);
  useEffect(() => {
    formik.setFieldValue(
      "fullyAllocatedSundryInvoiceIds",
      getFullyAllocatedSundryInvoiceIds(formik.values.unpaid_invoice_lines)
    );
  }, [JSON.stringify(formik.values.unpaid_invoice_lines)]);
  const { fetchCustomConversions, allocateTagsToPRFromInvoice } = usePr({
    formik: formik,
  });

  const fetchPaymentListAfterDelete = async () => {
    const responseAction = await dispatch(
      PRImports.invoicePaymentList({
        page: 1,
        itemsPerPage: 10,
        dateSortOrder: "ASC",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        customerList: [],
        statusList: [],
      })
    );
    // if (redirectTo) {
    //   navigate(redirectTo);
    // } else {
    //   navigate("/payment-received");
    // }
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };
  const actionsAfterAPISuccess = async (
    status: string,
    action: string,
    id: number
  ) => {
    dispatch(PRImports.setLoaderState(true));
    let paymentReceivedId = id;
    if (
      status === "Open" &&
      action === "Send" &&
      formik.values.email_to?.length > 0
    ) {
      await dispatch(
        PRImports.sendPaymentReceivedData({
          prId: paymentReceivedId,
          emails: formik.values.email_to,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let downloadResult;
    if (status === "Open" && action === "Download") {
      const responseAction = await dispatch(
        PRImports.getPaymentReceivedPdfLink({
          paymentReceivedId: paymentReceivedId,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response)?.length && !("error" in response)) {
          downloadResult = await PRImports.downloadUsingURL(
            response.download_link,
            paymentReceivedId + ".pdf"
          );
        }
      }
    }
    dispatch(PRImports.setLoaderState(false));
    // if (redirectTo) {
    //   navigate(redirectTo);
    // } else {
    //   navigate("/payment-received");
    // }
    if (status === "Draft") navigate(-1);
    else {
      redirectUrl
        ? navigate(
            `/payment-received/detail/${paymentReceivedId}?redirect=${redirectUrl}`,
            {
              replace: true,
            }
          )
        : navigate(`/payment-received/detail/${paymentReceivedId}`, {
            replace: true,
          });
    }
    SuccessToaster(
      editId
        ? "Invoice payment updated successfully!"
        : "Invoice payment created successfully!",
      "invoice-payment-create-success"
    );

    if (status === "Open" && action === "Download" && downloadResult) {
      SuccessToaster(
        "Downloaded Successfully!",
        "invoice-payment-download-success"
      );
    }
    if (status !== "Draft" && shouldPostTagsOfInvoice.current === true) {
      allocateTagsToPRFromInvoice(
        formik.values.fullyAllocatedSundryInvoiceIds,
        paymentReceivedId
      );
    }
  };

  const handleFormSubmit = async (
    status: string,
    action: string,
    intermediate_account_id?: number,
    intermediate_account_name?: string
  ) => {
    dispatch(PRImports.setLoaderState(true));

    if (editId) {
      const responseAction = await dispatch(
        PRImports.updateInvoicePayment({
          paymentId: Number(editId),
          values: getPrCreationPayload(
            formik.values,
            currentUserInfo,
            formik,
            intermediate_account_id || formik.values.intermediate_account_id,
            intermediate_account_name || formik.values.intermediate_account_name
          ),
          status: status,
          orgId: currentUserInfo.organization_id,
          isCustomerChange:
            formik.initialValues.customer_id !== formik.values.customer_id,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response)?.length && !("error" in response)) {
        dispatch(PRImports.setLoaderState(false));
        await actionsAfterAPISuccess(status, action, Number(editId));
      }
    } else {
      const createInvoiceResponseAction = await dispatch(
        PRImports.createInvoicePayment({
          values: getPrCreationPayload(
            formik.values,
            currentUserInfo,
            formik,
            intermediate_account_id,
            intermediate_account_name
          ),
          orgId: currentUserInfo.organization_id,
          status: status,
        })
      );
      const response = createInvoiceResponseAction.payload;
      if (Object.keys(response)?.length && !("error" in response)) {
        dispatch(PRImports.setLoaderState(false));
        await actionsAfterAPISuccess(status, action, Number(response.id));
      }
    }
  };
  const submitBtnClickHandler = async (status: string, action: string) => {
    formik.setFieldValue("pr_status", status);
    formik.setFieldValue("action", action);
    // validating forms
    await customFieldRef.current.handleSubmit();
    formik.submitForm();
    if (action === "Send" && formik.values.email_to?.length === 0) {
      setEmailError(message().pm.emailRequired);
      formik.setSubmitting(false);
      return;
    } else {
      setEmailError("");
    }

    if (
      Object.keys(formik.errors)?.length > 0 ||
      customFieldRef.current.errors
    ) {
      return;
    } else if (
      formik.values.fullyAllocatedSundryInvoiceIds?.length &&
      wasConfirmationPopupOpen === false &&
      status !== "Draft"
    ) {
      confirmationPopupRef.current?.openAlertModal &&
        confirmationPopupRef.current?.openAlertModal();
      setConfirmationPopupWasOpened(true);
    } else if (
      totalDetails.amount_excess &&
      totalDetails.amount_excess > 0 &&
      status !== "Draft" &&
      Object.keys(formik.errors)?.length === 0 &&
      !customFieldRef.current.errors &&
      (didAlertModalOpen || !currentUserInfo.is_tags_enabled || !editId) &&
      (wasConfirmationPopupOpen === true ||
        !formik.values.fullyAllocatedSundryInvoiceIds?.length)
    ) {
      formik.validateForm().then((res: any) => {
        if (!(Object.keys(res)?.length > 0)) {
          openExcessAllocationAlertRef.current?.openAlertModal &&
            openExcessAllocationAlertRef.current?.openAlertModal();
        }
      });
    } else {
      if (
        editId &&
        currentUserInfo.is_tags_enabled &&
        didAlertModalOpen === false
      ) {
        setDidAlertModalOpen(true);
        openEditAlertRef.current?.openAlertModal &&
          openEditAlertRef.current?.openAlertModal();
        return;
      } else {
        handleFormSubmit(status, action);
      }
    }
  };

  const getCustomerUnpaidInvoice = async (id: number) => {
    let result: any = [];
    if (!isNaN(id) && id !== null) {
      const responseAction: any = await dispatch(
        unpaidInvoiceList({
          currentOrgId: currentUserInfo.organization_id,
          customerId: id,
        })
      );
      if (responseAction.payload) {
        result = responseAction.payload;
      }
    }

    return result;
  };

  const paymentDetailsResponse = async () => {
    // dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      paymentReceivedDetails({
        paymentReceivedId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );

    const response = responseAction.payload;

    const isSameOrg =
      response.organization_id === currentUserInfo.organization_id;
    const isLocked = response.is_locked;
    if (
      Object.keys(response)?.length &&
      !("error" in response) &&
      isSameOrg &&
      !isLocked
    ) {
      let defaultValues = {
        customer_id: response.customer_id,
        customer_name: response.customer_name,
        payment_number: response.payment_number,
        payment_date: response.payment_date,
        check_expiry: response.check_expiry,
        check_expiry_in: response.check_expiry_in,
        check_date: response.check_date,
        check_number: response.check_number,
        payment_mode: response.payment_mode,
        custom_fields: response.custom_fields,
        deposit_to_account_name: response.deposit_to_account_name,
        deposit_to_account_id: response.deposit_to_account_id,
        intermediate_account_id: response.intermediate_account_id || null,
        intermediate_account_name:
          response.intermediate_account_name ||
          initialPRFormValues.intermediate_account_name,
        amount_received: response.amount_received,
        bank_charges_account_name: response.bank_charges_account_name,
        bank_charges_amount: response.bank_charges_amount,
        bank_charges_account_id: response.bank_charges_account_id,
        tax_account_name: response.tax_account_name,
        tax_account_id: response.tax_account_id,
        is_tax_deducted: response.is_tax_deducted,
        currency_id: response.currency_id,
        currency_code: response.currency_code,
        reference_number: response.reference_number,
        amount_received_bank_currency: response.amount_received_bank_currency,
        bank_charges_bank_currency: response.bank_charges_bank_currency,
        bank_conversion_id: response.bank_conversion_id,
        bank_currency_id: response.bank_currency_id,
        notes: response.notes,
        files: response.files ? response.files : [],
        file_ids: response.files.map((file: any) => file.id),
        email_to: response.pr_emails.map((email: any) => email.email),
        delete_email_ids: [],
        email_data: response.emails,
        unpaid_invoice_lines: [...response.unpaid_invoice_lines],
        amount_for_payment: response.amount_for_payment,
        amount_excess: response.amount_excess,
        amount_total: response.amount_total,
        unpaid_invoice_lines_exist: response.unpaid_invoice_lines_exist,
        pr_status: response.pr_status,
        conversion_id: response.conversion_id,
        is_locked: response.is_locked,
        lock_date: response.lock_date,
        total: response.total,
        total_refunded_amount: response.total_refunded_amount,
        refund_history: response.refund_history,
      };
      let unPaidInvoices = [...response.unpaid_invoice_lines];
      let initialUnpaidBillIds: number[] = unPaidInvoices.map(
        (unpaidInvoice) => unpaidInvoice.invoice_id
      );

      let newUnpaidInvoice = await getCustomerUnpaidInvoice(
        response.customer_id
      );
      for (let newInvoice of newUnpaidInvoice) {
        if (!initialUnpaidBillIds.includes(newInvoice.id)) {
          unPaidInvoices.push({
            invoice_id: newInvoice.id,
            amount_due: newInvoice.amount_due,
            invoice_amount: newInvoice.invoice_amount,
            invoice_date: newInvoice.invoice_date,
            allocation_date: PRImports.getFormatedDate(
              newInvoice.allocation_date
            ),
            invoice_number: newInvoice.invoice_number,
            is_deleted: false,
            id: null,
            payment: 0,
            conversion_id: newInvoice.conversion_id,
            forex_amount: 0,
          });
        }
      }
      defaultValues.unpaid_invoice_lines = unPaidInvoices;

      setIsUnpaidInvoiceExist(response.unpaid_invoice_lines_exist);
      setInitialFormValues({ ...defaultValues });
      await fetchCustomConversions(response.conversion_id, "user");
      if (response.bank_conversion_id) {
        await fetchCustomConversions(response.bank_conversion_id, "bank");
      }
      dispatch(setLoaderState(false));
    } else {
      if (isLocked) {
        navigate(-1);
        ErrorToaster(
          `Transactions before ${DateFormatHandler(
            response.lock_date
          )} have been locked. Hence action cannot be performed`
        );
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };

  return (
    <div className="card card-user-management card-customer main-card overflowX-hidden h-100 payment-detail-card mbottom-100">
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <PRImports.TransactionHeader
          transactionName="Record Payment"
          transactionNumber={Number(editId)}
        />
      </div>
      <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
        <div className="card-body">
          <form
            name="add_payment_form"
            id="add_invoice_form"
            className={`add_payment_form create-pr-form add-module-item-form ${
              (Object.keys(formik.errors)?.length > 0 ||
                customFieldRef?.current?.errors ||
                emailError !== "") &&
              formik.submitCount > 0
                ? "with-form-error-alert"
                : ""
            }`}
            tabIndex={-1}
          >
            <ScrollToFieldError
              submitCount={formik.submitCount}
              errors={{
                ...formik.errors,
                ...customFieldRef.current?.errorList,
                email_to: emailError,
              }}
            />
            <PRImports.PaymentDetailsForm
              formik={formik}
              customFieldRef={customFieldRef}
            />

            <PRImports.UnpaidInvoices formik={formik} />
            <div
              className={` ${
                !formik.values.customer_id ? "disabled-item" : ""
              }`}
            >
              <PRImports.AttachFiles
                formik={formik}
                attachFiles={PRImports.attachPaymentReceivedFiles}
                deleteFile={PRImports.deletePaymentReceivedFile}
                appendName="pr_file"
              />
              <PRImports.EmailTo formik={formik} error={emailError} />
            </div>

            <PRImports.FormActionButtons
              editId={editId}
              isLocked={formik.values.is_locked}
              lockDate={formik.values.lock_date}
              isError={
                (Object.keys(formik.errors)?.length > 0 ||
                  customFieldRef?.current?.errors ||
                  emailError !== "") &&
                formik.submitCount > 0
              }
              isDisabledDraft={
                !formik.values.customer_id ||
                formik.isSubmitting ||
                (!isNaN(Number(editId)) && formik.values.pr_status !== "Draft")
              }
              isFormSubmitting={false}
              isButtonGroupDisabled={false}
              isPrint={false}
              name="Payment Received"
              // redirectUrl="/payment-received"
              onClickDelete={() => {
                setDeleteBillPaymentId(Number(editId));
                setDeleteBillPaymentItemId(formik.values.payment_number);
              }}
              onClickSubmit={(status: string, action: string) => {
                setButtonAction({ status: status, action: action });
                submitBtnClickHandler(status, action);
              }}
              action=""
              rolePermission={paymentsReceivedRolePermission}
            />
            <PRImports.DeletePaymentItem
              deleteInvoicePaymentId={deleteBillPaymentId}
              deleteInvoicePaymentItemId={deleteBillPaymentItemId}
              deleteInvoicePaymentStatus={formik.values.pr_status}
              refreshInvoicePayments={fetchPaymentListAfterDelete}
              organizationId={currentUserInfo.organization_id}
              isUnpaidInvoiceExist={isUnpaidInvoiceExist}
            />
            <PRImports.ExcessAllocationAlertModal
              openRef={openExcessAllocationAlertRef}
              excessAmount={totalDetails?.amount_excess}
              currencyCode={
                formik.values?.currency_code ? formik.values?.currency_code : ""
              }
              module="Payments Received"
              handleSave={(id: number, name: string) => {
                handleFormSubmit(
                  formik.values.pr_status,
                  formik.values.action,
                  id,
                  name
                );
              }}
              onClose={() => {
                formik.setSubmitting(false);
                setConfirmationPopupWasOpened(false);
              }}
              formik={formik}
            />
            <PRImports.TransactionEditTagRemovalAlertModal
              module="Payments Received"
              onCancel={() => {
                formik.setSubmitting(false);
                setDidAlertModalOpen(false);
              }}
              onSubmit={() => {
                submitBtnClickHandler(buttonAction.status, buttonAction.action);
              }}
              openAlertRef={openEditAlertRef}
            />
            <ConfirmationPopup
              confirmationOpenRef={confirmationPopupRef}
              onCancel={() => {
                setConfirmationPopupWasOpened(false);
                submitBtnClickHandler(
                  formik.values.pr_status,
                  formik.values.action
                );
              }}
              onSubmit={() => {
                shouldPostTagsOfInvoice.current = true;
                submitBtnClickHandler(
                  formik.values.pr_status,
                  formik.values.action
                );
                // handleFormSubmit(formik.values.status, formik.values.action);
              }}
              popupTitle="Auto allocate the reporting tags to the invoice!"
              buttonText={{
                submit: "Yes",
                cancel: "No",
              }}
              popupButtonContent={<></>}
              bodyWrapperClass=""
            >
              <Span>
                Do you want to auto allocate the reporting tags to the invoice?
              </Span>
            </ConfirmationPopup>
          </form>
        </div>
      </div>
    </div>
  );
};
