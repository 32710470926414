import React, { useState } from "react";
import { DownArrowIcon } from "../DownArrowIcon";
import DownloadIcon from "../../../../assets/images/download_icon_blue.svg";
import ExcelIcon from "../../../../assets/images/excel-icon.svg";
import "./reportsExportComponents.css";

interface ReportsExportComponentProps {
  onExportPDF?: () => void;
  onExportExcel?: () => void;
}

const ReportsExportComponent: React.FC<ReportsExportComponentProps> = ({
  onExportPDF,
  onExportExcel,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="export-dropdown-container">
      <img src={DownloadIcon} alt="download" />
      <button className="export-dropdown-button" onClick={toggleDropdown}>
        Export
      </button>
      <DownArrowIcon
        color="var(--cerulean-blue)"
        transform={showDropdown ? "rotate(180deg)" : "none"}
        transition="transform 0.2s"
        scale="2"
        onClick={toggleDropdown}
      />
      {showDropdown && (
        <div className="export-dropdown-content">
          {/* <button
            className="export-option"
            onClick={() => {
              onExportPDF?.();
              setShowDropdown(false);
            }}
          >
            Export as PDF
          </button> */}
          <button
            className="export-option"
            onClick={() => {
              onExportExcel?.();
              setShowDropdown(false);
            }}
          >
            <img src={ExcelIcon} alt="excel" className="excel-icon" />
            Export as Excel
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportsExportComponent;
