import { useEffect, useState } from "react";
import { ObjectType } from "../../../../../types";
import AccordianItem from "../../../../common/components/detailsPage/AccordianItem";
import { CustomFieldTable } from "../customFieldTable/customFieldTable";

type Props = {
  subscriptionsCustomFieldList: {
    Addons: ObjectType[];
    Plans: ObjectType[];
    Subscriptions: ObjectType[];
  };
};
export const SubscriptionsCustomFields = (props: Props) => {
  const [customFieldModuleList, setCustomFieldModuleList] = useState(
    props.subscriptionsCustomFieldList
  );

  useEffect(() => {
    setCustomFieldModuleList(props.subscriptionsCustomFieldList);
  }, [props.subscriptionsCustomFieldList]);

  const addonFields = customFieldModuleList?.Addons
    ? customFieldModuleList?.Addons?.filter(
        (field) => field.is_deleted === false
      ).map((field) => {
        return {
          show: {
            id: field.id,
            field_name: field.field_name,
            mandatory_field: field.mandatory_field ? "Yes" : "No",
            show_in_pdf: field.show_in_pdf ? "Yes" : "No",
            actions: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field,
              show_in_pdf: field.show_in_pdf,
            },
          },
        };
      })
    : [];

  const planFields = customFieldModuleList?.Plans
    ? customFieldModuleList?.Plans.filter(
        (field) => field.is_deleted === false
      ).map((field) => {
        return {
          show: {
            id: field.id,
            field_name: field.field_name,
            mandatory_field: field.mandatory_field ? "Yes" : "No",
            show_in_pdf: field.show_in_pdf ? "Yes" : "No",
            actions: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field,
              show_in_pdf: field.show_in_pdf,
            },
          },
        };
      })
    : [];
  const subscriptionFields = customFieldModuleList?.Subscriptions
    ? customFieldModuleList?.Subscriptions.filter(
        (field) => field.is_deleted === false
      ).map((field) => {
        return {
          show: {
            id: field.id,
            field_name: field.field_name,
            mandatory_field: field.mandatory_field ? "Yes" : "No",
            show_in_pdf: field.show_in_pdf ? "Yes" : "No",
            actions: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field,
              show_in_pdf: field.show_in_pdf,
            },
          },
        };
      })
    : [];

  return (
    <div
      className="accordion transactions-accordion"
      id="transactions-accordion"
    >
      {" "}
      {/* <AccordianItem
        title="Addons"
        index={1}
        createUrl=""
        module="Custom Fields"
        subModule="Addons"
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={addonFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Addons"}
        />
      </AccordianItem> */}
      {/* <AccordianItem
        title="Plans"
        index={2}
        module="Custom Fields"
        subModule="Plans"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={planFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Plans"}
        />
      </AccordianItem> */}
      <AccordianItem
        title="Subscriptions"
        index={3}
        module="Custom Fields"
        subModule="Subscriptions"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={subscriptionFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Subscriptions"}
        />
      </AccordianItem>
    </div>
  );
};
