export const deleteInitialData = {
  actionType: "",
  id: -1,
  planName: "",
};

export const planDetailInitialData = {
  description: "",
  external_name: "",
  id: 0,
  item_detail: [],
  organization_id: 0,
  organization_name: "",
  plan_status: "",
  currency_code: "",
  pricings: null,
};

export const defaultFrequencyCol = {
  frequency: "",
  pricing_model: "",
  invoicing_cycle: "",
  trial: false,
  trial_count: 0,
  trial_frequency: "",
  isPriceSet: false,
  item_price: [],
  pricing_id: 0,
};

export const initialCouponDefaultValues = {
  name: "",
  account_id: 0,
  account_name: "",
  discount_value: 0,
  discount_type: "PERCENTAGE",
  duration_type: "FOREVER",
  limited_duration_unit: "DAYS",
  limited_duration_value: 1,
  valid_till: null,
  maximum_redemptions: 0,
  organization_id: 0,
  currency_id: null,
  currency_code: ""
};

export const DurationTypeDropdown = [
  { label: "One time", value: "ONE_TIME" },
  { label: "Forever", value: "FOREVER" },
  { label: "Limited Period", value: "LIMITED_PERIOD" },
];

export const durationUnitDropdown = [
  { label: "Day(s)", value: "DAYS" },
  { label: "Week(s)", value: "WEEKS" },
  { label: "Month(s)", value: "MONTHS" },
  { label: "Year(s)", value: "YEARS" },
];

export const discountTypeDropdown = [
  { label: "Percentage", value: "PERCENTAGE" },
  { label: "Fixed Value", value: "ABSOLUTE" },
];

export const initialCouponEditValues = {
  name: "",
  account_id: 0,
  account_name: "",
  discount_value: 1,
  discount_type: "PERCENTAGE",
  duration_type: "FOREVER",
  limited_duration_unit: "DAYS",
  limited_duration_value: 1,
  valid_till: null,
  maximum_redemptions: 0,
  organization_id: 0,
  id: 0,
  is_redeemed: false,
  currency_id: null,
  currency_code: ""
};

export const couponOverviewInitialData = {
  id: 0,
  name: "",
  coupon_status: "",
  discount: "",
  account_id: 0,
  account_name: "",
  duration: "",
  validity: "",
  redemptions: "",
  organization_id: 0,
  organization: "",
  is_deletable: false,
  date_of_creation: ''
};
