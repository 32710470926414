import { useMemo, useRef } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import {
  DateFormatHandler,
  getProfileFormatedDate,
} from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { BillDetailValues } from "../../../../../types";
import {
  amountInWordsConfigSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { ApplyCreditsFormModal } from "./ApplyCredits/ApplyCreditsFormModal";
import { tdsApplyLevel } from "../../../../constants/constants";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import DebitNoteAndPaymentMadeDetails from "../DNandPMDetails/DNandPMDetails";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import JournelView from "../../../../common/components/JournelView";
import FileList from "../../../../common/components/FileList";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";

type Props = {
  billData: BillDetailValues;
  billDetail: () => void;
  reRenderFlag: number;
};

const BillsDetailBody: React.FC<Props> = ({
  billData,
  billDetail,
  reRenderFlag,
}) => {
  let taxTypes = Object.entries(billData.total_details.taxes);
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const { debitNoteRolePermission } = usePermissionHandler();
  const transactionRef = useRef<{ fetchDNPMList: () => void }>(null);
  const creditApplyRef = useRef<{ getApplyCreditList: () => void }>(null);
  const { isGstOrg } = useGst();
  const isAuditLogEnabled = process.env.REACT_APP_AUDIT_LOG_ENABLED;
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "DESCRIPTION",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "Hsn/sac code",
        accessor: "show.hsnCode",
        className: "hsncode text-nowrap",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "QUANTITY",
        accessor: "show.quantity",
        className: "quantity text-nowrap text-align-right",
      },
      {
        Header: "RATE",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "DISCOUNT",
        accessor: "show.discount",
        className: "discount text-nowrap text-align-right",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "TAX",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn values={value} currencyCode={billData.currency_code} />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          billData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {billData.currency_code}
                {NumberFormat(
                  Number(value.tds_total),
                  billData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {/* <span>
                
              </span> */}
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "TAXABLE AMOUNT",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ],
    [billData.currency_code]
  );

  /**
   * set table row data
   */
  const data = billData.item_details
    ? billData.item_details.map((itemData, index) => ({
        show: {
          description: itemData.item_name,
          hsnCode: itemData.hsn_or_sac_code,
          account: itemData.account_name,
          quantity: NumberFormat(
            Number(itemData.quantity),
            billData.currency_code,
            orgCurrencyList
          ),
          rate:
            billData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.rate),
              billData.currency_code,
              orgCurrencyList
            ) +
            " / Unit",
          discount: {
            discount:
              billData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.discount.discount_value),
                billData.currency_code,
                orgCurrencyList
              ),
            discountAccount: itemData.discount_account_name,
          },
          tax:
            itemData.gst_tax?.tax_id !== null
              ? itemData.gst_tax
              : itemData.taxes,
          taxableAmount:
            billData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.taxable_amount),
              billData.currency_code,
              orgCurrencyList
            ),
          item: itemData,
        },
      }))
    : [];
  return (
    <div id="invoice" className="transaction-preview">
      <div className="preview-top-butons w-100 d-flex justify-content-end">
        {billData.organization_id === currentUserInfo.organization_id &&
        debitNoteRolePermission.includes("Read") &&
        billData.total_details?.balance_due &&
        Number(billData?.total_details?.balance_due) > 0 &&
        // billData.balance_due &&
        // Number(billData.balance_due) > 0 &&
        billData.excess_debit &&
        Number(billData.excess_debit) > 0 ? (
          <div>
            <div className="credits-available credits-available-label">
              Credits Available{" "}
              <span className="available-amount">
                {NumberFormat(
                  Number(billData.excess_debit),
                  billData.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
            <ApplyCreditsFormModal
              billData={billData}
              refreshBillDetails={() => {
                billDetail();
                if (transactionRef.current)
                  transactionRef.current.fetchDNPMList();
              }}
              isVoid={billData.bill_status === "Void"}
            />
          </div>
        ) : null}
      </div>
      <DebitNoteAndPaymentMadeDetails
        orgId={Number(billData.organization_id)}
        billId={Number(billData.id)}
        billDetail={() => {
          billDetail();
          if (creditApplyRef.current)
            creditApplyRef.current.getApplyCreditList();
        }}
        ref={transactionRef}
      />
      <div className="invoice-header d-flex justify-content-between">
        <CompanyDetails organizationData={billData.organization_details} />
        <div className="invoice-details">
          <div className="label-invoice">Bill</div>
          <div className="invoice-number-label">Bill Number</div>
          <div className="invoice-number">
            {joinTransactionNumber(billData.bill_number) || ""}
          </div>

          {billData.reference_number ? (
            <>
              <div className="invoice-number-label">Reference Number</div>
              <div className="invoice-number">{billData.reference_number}</div>
            </>
          ) : null}

          <div className="invoice-number-label">Balance Due</div>
          <div className="invoice-number mb-0">
            {billData.currency_code}{" "}
            {billData.balance_due
              ? NumberFormat(
                  Number(billData.balance_due),
                  billData.currency_code,
                  orgCurrencyList
                )
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    billData.currency_code
                      ? billData.currency_code
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
          </div>
        </div>
      </div>
      <div className="bill-details-section d-flex justify-content-between">
        <div className="billed-to">
          <span className="bill-details-header">Billed From</span>
          <div className="company-address pt-3">{billData.vendor_name}</div>
        </div>
        <div className="inv-date-wrap">
          <div className="inv-date">
            <span className="bill-details-header">Bill Date</span>
            <div>
              {getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                billData.bill_date
              )}
            </div>
          </div>
          <div className="inv-date">
            <span className="bill-details-header">Due Date</span>
            <div>
              {getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                billData.due_date
              )}
            </div>
          </div>
        </div>
        <div className="payment-terms">
          <span className="bill-details-header">Payment Terms</span>
          <div>
            {billData.payment_term_id ? billData.payment_term : "Custom"}
          </div>
        </div>
        {(billData?.source_of_supply !== null ||
          billData?.destination_of_supply !== null) && (
          <>
            <div className="inv-date-wrap bill-detail-right-align">
              {billData?.source_of_supply && (
                <>
                  <div className="inv-date">
                    <span className="bill-details-header">
                      Source Of Supply
                    </span>
                    <div>{billData?.source_of_supply}</div>
                  </div>
                </>
              )}

              <div
                className="inv-date"
                style={{
                  marginTop: !billData?.source_of_supply ? "0px" : "21px",
                }}
              >
                <span className="bill-details-header">
                  Destination Of Supply
                </span>
                <div>{billData?.destination_of_supply}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <RowWiseFieldView mapObject={billData.custom_fields} module="Bills" />
      <div className="table-section">
        <div className="table-wrap">
          <DetailsTableContainer
            columns={columns}
            data={data}
            className="table bills-preview-table"
            haveColumnClassName={true}
            hiddenColumns={["TDS"]}
          />
        </div>
        <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
          <div className="thankyou-note w-50 d-flex align-items-end">
            {billData?.vendor_notes && (
              <div className="note-container">
                <span className="label">Vendor Notes</span>
                <p>{billData.vendor_notes}</p>
              </div>
            )}
          </div>
          <DetailTotalSection
            currencyCode={billData.currency_code}
            totalTax={billData.total_details.tax_amount}
            paymentLabel="Payment made"
            payment={billData.total_details.payment_made}
            taxTypes={taxTypes}
            subTotal={billData.total_details.total_taxable_amount}
            total={billData.total_details.total}
            totalInWords={billData.total_details.amount_in_words}
            balanceLabel="Balance Due"
            balanceAmount={
              billData.balance_due
                ? billData.balance_due
                : zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      billData.currency_code
                        ? billData.currency_code
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )
            }
            tdsItems={{
              tdsAmount: billData.total_details.transaction_tds_amount
                ? billData.total_details.transaction_tds_amount
                : 0,
              tdsName: billData.tds_name,
              tdsPercentage: billData.tds_percentage,
              defaultTdsPercentage: billData.default_tds_percentage,
            }}
            tcsItems={{
              tcsAmount: billData.tcs_total,
              tcsName: billData.tcs_name,
              tcsPercentage: billData.tcs_percentage,
            }}
            roundOffValue={billData.total_details.roundoff_amount}
            roundoffEnabled={billData.roundoff_enabled}
            isAmountInWordsEnabled={billData.is_words_enabled}
          >
            <></>
          </DetailTotalSection>
        </div>
      </div>
      {billData.bill_files.length ? (
        <FileList files={billData.bill_files} />
      ) : null}
      <JournelView
        title="Journal - Bill"
        transactionType="Bills"
        transactionId={Number(billData.id)}
        reRenderFlag={reRenderFlag}
        isVoid={billData.bill_status === "Void"}
      />
      {isAuditLogEnabled === "true" && (
        <AuditTrail
          title="Audit Trail"
          transactionType="bills"
          transactionId={Number(billData.id)}
          reRenderFlag={reRenderFlag}
        />
      )}
    </div>
  );
};
export default BillsDetailBody;
