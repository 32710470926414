import { SalesCustomFields } from "./components/salesCustomFields/salesCustomFields";
import { PurchaseCustomFields } from "./components/purchaseCustomFields/purchaseCustomFields";
import { AccountsCustomFields } from "./components/accountsCustomFields/accountsCustomFields";
import { HorizontalTab } from "../../common/components/HorizontalTab";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCustomFieldTotalList } from "./components/customFieldAPIFiles/customFieldSlice";
import {
  currentUserSelector,
  customFieldListSelector,
} from "../../common/commonSlice";
import { ObjectType } from "../../../types";
import { SubscriptionsCustomFields } from "./components/subscriptionsCustomFields/subscriptionsCustomFields";

type CustomFieldList = {
  sales: {
    Invoices: ObjectType[];
    "Credit Notes": ObjectType[];
    "Payments Received": ObjectType[];
    Customer: ObjectType[];
  };
  purchases: {
    Bills: ObjectType[];
    "Debit Notes": ObjectType[];
    "Payments Made": ObjectType[];
    Vendor: ObjectType[];
  };
  accounts: {
    "Journal Entry": ObjectType[];
    "System Voucher": ObjectType[]
  };
  subscriptions: {
    Addons: ObjectType[];
    Plans: ObjectType[];
    Subscriptions: ObjectType[];
  };
};
export const CustomFields = () => {
  const initialCustomFieldList = {
    sales: {
      Invoices: [],
      "Credit Notes": [],
      "Payments Received": [],
      Customer: [],
    },
    purchases: {
      Bills: [],
      "Debit Notes": [],
      "Payments Made": [],
      Vendor: [],
    },
    accounts: {
      "Journal Entry": [],
      "System Voucher":[]
    },
    subscriptions: {
      Addons: [],
      Plans: [],
      Subscriptions: [],
    },
  };
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const customFields = useAppSelector(customFieldListSelector);
  const [customFieldList, setCustomFieldList] = useState<CustomFieldList>(
    initialCustomFieldList
  );
  // const customFieldList:
  useEffect(() => {
    const getCustomFieldList = async () => {
      const responseAction = await dispatch(
        getCustomFieldTotalList({
          orgId: currentUserInfo.organization_id,
        })
      );
      const response = responseAction.payload;
      setCustomFieldList({
        sales: response.Sales,
        purchases: response.Purchases,
        accounts: response.Accounts,
        subscriptions: response.Subscription,
        // subscriptions: {
        //   Addons: [],
        //   Plans: [],
        //   Subscriptions: [],
        // },
      });
    };
    getCustomFieldList();
  }, []);
  return (
    <HorizontalTab
      tabHeaders={["Sales", "Purchases", "Accounts", "Subscriptions"]}
      tabBody={[
        <SalesCustomFields salesCustomFieldList={customFields?.Sales} />,
        <PurchaseCustomFields purchasesModuleList={customFields?.Purchases} />,
        <AccountsCustomFields accountsModuleList={customFields?.Accounts} />,
        <SubscriptionsCustomFields
          subscriptionsCustomFieldList={customFields?.Subscription}
        />,
      ]}
      disableRerender={true}
    />
  );
};
