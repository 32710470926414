import * as React from "react";
import {
  DateFormatHandler,
  getProfileFormatedDate,
} from "../../../../../../helpers/helper";
import { ObjectType } from "../../../../../../types";
import CompanyDetails from "../../../../../common/components/detailsPage/CompanyDeatails";
import useCommonData from "../../../../../hooks/useCommon";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Span } from "../../../../../common/components/htmlTags/Span";
import "./BudgetVarianceReportOrgDetails.css";
type Props = {
  organizationDetails: ObjectType;
  startDate: string;
  endDate: string;
};
function BudgetVarianceReportOrgDetails({ organizationDetails, startDate, endDate }: Props) {
  const reportNames = ["Balance Sheet", "Trial Balance"];
  const { currentUserInfo } = useCommonData();
  return (
    <Container className="report-header budget-variance-report-org-details">
      <Container className="report-header-container d-flex justify-content-between">
        <CompanyDetails
          organizationData={organizationDetails}
        />
        <Container className="report-details align-right">
          <Container className="page-type-label">{"Budget Variance Report"}</Container>
          <Container className="preview-date-field">
            <Span className="start-dt">
              {(startDate &&
              !reportNames.includes("Budget Variance Report")
                ? getProfileFormatedDate(
                    currentUserInfo.organization_date_format,
                    startDate
                  ) + " to "
                : "As of ") +
                getProfileFormatedDate(
                  currentUserInfo.organization_date_format,
                  endDate
                )}
            </Span>
            <Container className="report-header-org-name mt-2">
              {organizationDetails?.organization_name}
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default BudgetVarianceReportOrgDetails;
