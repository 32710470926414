import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IsFeatureEnabled } from "../../../../helpers/featureFlagHelper";
import { getFiscalQuarterYear } from "../../../../helpers/fiscalYearHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { consumerDetailsValues, SoaFilters } from "../../../../types";
import {
  currenciesSelector,
  currencyDropdown,
  currentUserSelector,
} from "../../commonSlice";
import {
  dateRangeSelector,
  getSoaReport,
  reportTableFilterSelector,
  setDateRange,
  setReportCustomizationFlag,
  setReportTableFilters,
} from "../../../home/reports/ReportSlice";
import Loader from "../../../home/components/Loader";
import PredefinedDateRangePicker from "../../../home/reports/componets/PredefinedDateRange";
import StatementOfAccountsBody from "../../../home/reports/statementOfAccount/components/StatementOfAccountsBody";
import "../../../home/reports/Reports.css";

export default function ConsumerStatement(props: {
  consumerDetails: consumerDetailsValues;
  consumerType: string;
}) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const currencyLists = useAppSelector(currenciesSelector);
  const [isLoading, setIsLoader] = useState(true);
  const [transactionBy, setTransactionBy] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Organization",
    "Vendor",
    "Customer",
  ]);
  const [transactionOrg, setTransactionOrg] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [reportResponse, setReportResponse] = useState<any>([]);
  const dateRange = useAppSelector(dateRangeSelector);
  const quarterStartDate = getFiscalQuarterYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "Current"
  ).startDate;
  const quarterEndDate = getFiscalQuarterYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "Current"
  ).endDate;
  const initialFormValues: SoaFilters = {
    statement_type:
      props.consumerType === "customer"
        ? "Customer"
        : props.consumerType === "vendor"
        ? "Vendor"
        : "",
    contact_or_account_id: "",
    currency_id: null,
    date_range: "this_quarter",
    start_date: quarterStartDate,
    end_date: quarterEndDate,
    is_sundry: false,
    organization_id: null,
    sundry_type: null,
    is_integrated: true,
  };
  const [formValues, setFormValues] = useState<SoaFilters>(initialFormValues);
  const [currencyIds, setCurrencyIds] = useState<number[]>([]);
  const tableFilterValues = useAppSelector(reportTableFilterSelector);

  useEffect(() => {
    dispatch(
      setReportTableFilters({
        ...tableFilterValues,
        date_range: "this_quarter",
        start_date: quarterStartDate,
        end_date: quarterEndDate,
        account_id: 0,
        statement_type: "",
        contact_or_account_id: "",
        currency_id: currentUserInfo.organization_currency,
        is_sundry: false,
        organization_id: currentUserInfo.organization_id,
      })
    );
    dispatch(
      setDateRange({
        date_range: "this_quarter",
        start_date: quarterStartDate,
        end_date: quarterEndDate,
      })
    );
    dispatch(setReportCustomizationFlag(true));
  }, [currentUserInfo.organization_id, formValues.statement_type]);

  useEffect(() => {
    if (editId) formValues["contact_or_account_id"] = editId;
    if (props.consumerDetails?.other_details?.currency_id) {
      setCurrencyIds([
        Number(props.consumerDetails?.other_details?.currency_id),
      ]);
      formValues["currency_id"] = Number(
        props.consumerDetails?.other_details?.currency_id
      );
      formValues["organization_id"] = Number(
        props.consumerDetails?.organization_id
      );
    }
    formValues["is_integrated"] = true;
    setFormValues({ ...formValues });
  }, [editId, props.consumerDetails]);

  useEffect(() => {
    if (
      formValues["contact_or_account_id"] !== "" &&
      formValues["currency_id"] !== null
    ) {
      getReport();
    }
  }, [formValues]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      start_date: dateRange.start_date,
      end_date: dateRange.end_date,
      date_range: dateRange.date_range.replace(" ", "_").toLowerCase(),
    });
  }, [dateRange]);

  /**
   * Fetch organization currencies
   */
  const fetchCurrency = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };

  /**
   * Function to set start date and end date
   */
  const handleDateRange = (start: any, end: any, range: string) => {
    let dateRange = range.replace(" ", "_").toLowerCase();
    setFormValues({
      ...formValues,
      start_date: start,
      end_date: end,
      date_range: dateRange,
    });
  };
  /**
   * Function to set currency id
   */
  const handleSelectChange = (e: any) => {
    if (e.value) {
      setFormValues((values) => ({
        ...values,
        currency_id: e.value,
      }));
    }
  };

  const getReport = async () => {
    if (IsFeatureEnabled("Users", currentUserInfo.disabled_feature)) {
      setIsLoader(true);
      const responseAction = await dispatch(
        getSoaReport({
          filterValues: {
            ...formValues,
            organization_id: currentUserInfo.organization_id,
          },
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setReportResponse(response);
        }
      }
      setShowReport(true);
      setTimeout(() => setIsLoader(false), 1000);
    }
  };
  /**
   * Get the list of all currencies
   */
  const currencyListOptions = currencyLists
    .filter((currency) => currencyIds.includes(currency.id))
    .map((currency, index) => ({
      label: currency.code,
      value: currency.id,
    }));

  const getName = () => {
    return currencyListOptions.map((listData) => ({
      label: listData.label,
      value: listData.value,
    }));
  };

  return (
    <div className="reports soa-report">
      <div
        className="card card-dashboard report-card soa-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="report-body w-100">
            <div className="report-body-links justify-content-between align-items-center ">
              <div className="top-filter-container filter-box w-100">
                <div className="row g-0">
                  <div className="col filter-col d-flex">
                    <div className="filter-wrapper d-flex  pt-0">
                      <div className="filter-item">
                        <PredefinedDateRangePicker
                          isSingleDatePicker={false}
                          isAdvanced={false}
                          defaultValue="quarter"
                          reportType="SOA"
                          resetType={props.consumerType}
                          handleDateRange={handleDateRange}
                          filterdata={{
                            startDate: tableFilterValues.start_date,
                            endDate: tableFilterValues.end_date,
                            dateRange: tableFilterValues.date_range,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <StatementOfAccountsBody
              showReport={showReport}
              reportResponse={reportResponse}
              transactionBy={transactionBy}
              hiddenColumns={hiddenColumns}
              transactionOrg={transactionOrg}
              filterValues={formValues}
            />
            {isLoading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
