import { Container } from "../../../../../common/components/htmlTags/Container";

import { useEffect, useMemo, useState } from "react";
import { getOrganizationCurrencies } from "../../../../../common/commonSlice";
import { useCommonData } from "../../../../paymentsReceived/components/paymentReceivedForm/imports";
import { OrganizationDropDown } from "../../../budgetVarianceReport/components/budgetVarianceReportFilter/organizationDropdown/OrganizationDropdownReport";
import { CurrencyDropdown } from "../../../budgetVarianceReport/components/budgetVarianceReportFilter/currencyDropdown/CurrencyDropdown";
import { BudgetDropDown } from "../../../budgetVarianceReport/components/budgetVarianceReportFilter/budgetDropdown/BudgetDropdown";
import { PrimaryButton } from "../../../../../common/components/button/PrimaryButton";
import { SecondaryButton } from "../../../../../common/components/button/SecondaryButton";
import { VarianceReportDateRangePicker } from "../../../budgetVarianceReport/components/budgetVarianceReportFilter/varianceReportDateRangePicker/VarianceReportDateRangePicker";
import { FiscalYearSelect } from "../../../../../common/components/fiscalYearSelect/FiscalYearSelect";
import { Form } from "../../../../../common/components/htmlTags/Form";
import { FormSelectField } from "../../../../../common/components";
import { customSelectStyle } from "../../../../../common/components/SelectCustomStyle";
import Select from "react-select";
import "./BudgetSummaryReportFilter.css";
import { budgetDetail } from "../../../../budget/budgetSlice";
import { Span } from "../../../../../common/components/htmlTags/Span";

type Props = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleReset: (e: React.FormEvent<HTMLFormElement>) => void;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  errors: any;
  touched: any;
  setValues: (values: any) => void;
  setErrors: (errors: any) => void;
  setTouched: (touched: any) => void;
};
const BudgetSummaryReportFilter = ({
  handleSubmit,
  handleReset,
  setFieldValue,
  values,
  errors,
  setValues,
  setErrors,
  setTouched,
  touched,
}: Props) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const [fiscalYearList, setFiscalYearList] = useState<any[]>([]);
  const periodList = [
    { label: "1 Months", value: 1 },
    { label: "2 Months", value: 2 },
    { label: "3 Months", value: 3 },
    { label: "6 Months", value: 6 },
    { label: "12 Months", value: 12 },
  ];
  const numberOfPeriodsList = [
    { label: "1 Period", value: 1 },
    { label: "2 Periods", value: 2 },
    { label: "3 Periods", value: 3 },
    { label: "4 Periods", value: 4 },
    { label: "5 Periods", value: 5 },
    { label: "6 Periods", value: 6 },
    { label: "7 Periods", value: 7 },
    { label: "8 Periods", value: 8 },
    { label: "9 Periods", value: 9 },
    { label: "10 Periods", value: 10 },
    { label: "11 Periods", value: 11 },
    { label: "12 Periods", value: 12 },
  ];
  const getFilterCurrencies = async (selectedOrgId: number) => {
    const responseAction = await dispatch(
      getOrganizationCurrencies({
        selectedOrgId: selectedOrgId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (response) {
        setFieldValue("currency_ids", response);
      }
    }
  };

  const getFiscalYearList = async (id: number) => {
    const responseAction = await dispatch(
      budgetDetail({
        budgetId: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (response) {
      const fiscalYearMonths = generateFiscalYearMonthsWithBudget(
        currentUserInfo.previous_fiscal_end_date,
        response.start_date
      );
      const monthOptions = generateMonthOptions(fiscalYearMonths);
      setFiscalYearList(monthOptions);
    }
  };

  useMemo(() => {
    setFieldValue("organization_id", currentUserInfo.organization_id);
    getFilterCurrencies(currentUserInfo.organization_id);
  }, [currentUserInfo.organization_id]);

  function generateFiscalYearMonthsWithBudget(
    fiscalYearEndDate: string,
    budgetStartDate: string
  ) {
    const fiscalEndDate = new Date(fiscalYearEndDate);
    const budgetStart = new Date(budgetStartDate);

    // Find the fiscal year containing the budget start date
    const fiscalEndMonth = fiscalEndDate.getMonth();
    const fiscalStartDate = new Date(fiscalEndDate);
    fiscalStartDate.setDate(fiscalStartDate.getDate() + 1);

    // Get fiscal year containing budget start date
    let targetFiscalStartDate = new Date(fiscalStartDate);
    while (
      budgetStart >
      new Date(targetFiscalStartDate.getFullYear() + 1, fiscalEndMonth + 1, 0)
    ) {
      targetFiscalStartDate.setFullYear(
        targetFiscalStartDate.getFullYear() + 1
      );
    }

    const fiscalStartYear = targetFiscalStartDate.getFullYear();
    const fiscalStartMonth = targetFiscalStartDate.getMonth();

    // Generate months for that fiscal year
    const months = [];
    for (let i = 0; i < 12; i++) {
      const currentMonth = (fiscalStartMonth + i) % 12;
      const yearOffset = Math.floor((fiscalStartMonth + i) / 12);
      const currentYear = fiscalStartYear + yearOffset;

      const monthName = new Date(currentYear, currentMonth).toLocaleString(
        "default",
        { month: "short" }
      );
      months.push(`${monthName} ${currentYear}`);
    }

    return months;
  }

  const generateMonthOptions = (fiscalYearMonths: string[]) => {
    return fiscalYearMonths.map((monthYear) => {
      const [month, year] = monthYear.split(" ");
      const monthIndex = new Date(`${year}-${month}-01`).getMonth() + 1;
      const paddedMonth = monthIndex.toString().padStart(2, "0");
      const value = `${year}-${paddedMonth}-01`;
      return { label: monthYear, value: value };
    });
  };
  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <Container
        className={`budget-summary-report-filter-container ${
          values.budgetData.length > 0
            ? "budget-summary-report-filter-container-with-border"
            : ""
        }`}
      >
        <Container className="filter-values-container">
          <BudgetDropDown
            budgetId={values.budget_id || 0}
            handleBudgetChange={(newValue: any) => {
              setValues({
                ...values,
                budget_id: newValue.value,
                start_date: null,
              });
              getFiscalYearList(newValue.value);
            }}
            isDisabled={!values.organization_id}
            orgId={values.organization_id}
            error={
              errors.budget_id && touched.budget_id ? errors.budget_id : ""
            }
            setTouched={setTouched}
            touched={touched}
          />
          <Container className="budget-dropdown-container">
            <Select
              name="from"
              id="from"
              options={fiscalYearList}
              className={`${errors.start_date && touched.start_date ? "mw-325 select-error" : "mw-325"}`}
              value={
                fiscalYearList.find(
                  (month) => month.value === values.start_date
                ) || null
              }
              onChange={(option: any) => {
                setFieldValue("start_date", option.value);
              }}
              styles={customSelectStyle}
              placeholder={"From"}
              isDisabled={values.budget_id ? false : true}
              onMenuClose={() => {
                setTouched({ ...touched, start_date: true });
              }}
            />
            {errors.start_date && touched.start_date && (
              <Span className="error">{errors.start_date}</Span>
            )}
          </Container>
          <Container className="budget-dropdown-container">
            <Select
              name="period"
              id="period"
              options={periodList}
              className={`${errors.period && touched.period ? "mw-325 select-error" : "mw-325"}`}
              value={
                periodList.find((period) => period.value === values.period) ||
                null
              }
              onChange={(option: any) => {
                setFieldValue("period", option.value);
              }}
              styles={customSelectStyle}
              placeholder={"Period"}
              isDisabled={values.budget_id ? false : true}
              onMenuClose={() => {
                setTouched({ ...touched, period: true });
              }}
            />
            {errors.period && touched.period && (
              <Span className="error">{errors.period}</Span>
            )}
          </Container>
          <Container className="budget-dropdown-container">
            <Select
              name="number_of_periods"
              id="number_of_periods"
              options={numberOfPeriodsList}
              className={`${errors.no_of_periods && touched.no_of_periods ? "mw-325 select-error" : "mw-325"}`}
              value={
                numberOfPeriodsList.find(
                  (period) => period.value === values.no_of_periods
                ) || null
              }
              onChange={(option: any) => {
                setFieldValue("no_of_periods", option.value);
              }}
              styles={customSelectStyle}
              placeholder={"Number of Periods"}
              isDisabled={values.budget_id ? false : true}
              onMenuClose={() => {
                setTouched({ ...touched, no_of_periods: true });
              }}
            />
            {errors.no_of_periods && touched.no_of_periods && (
              <Span className="error">{errors.no_of_periods}</Span>
            )}
          </Container>
          <OrganizationDropDown
            selectedOrg={values.organization_id}
            handleOrgChange={(newValue, currencyId) => {
              setFieldValue("organization_id", newValue.value);
              setFieldValue("currency_id", currencyId);
              setFieldValue("budget_id", null);
              getFilterCurrencies(newValue.value);
            }}
            error={errors.organization_id || ""}
            orgDisable={false}
          />
          <CurrencyDropdown
            currencyId={values.currency_id}
            currencyCode={values.currency_code}
            handleCurrencyChange={(e: any) => {
              setValues({
                ...values,
                currency_id: e.value,
                currency_code: e.label,
              });
            }}
            currencyIds={values.currency_ids}
            error={errors.currency_id || ""}
          />
        </Container>
        <Container className="filter-buttons-container">
          <PrimaryButton type="submit" isDisabled={false}>
            Apply
          </PrimaryButton>
          <SecondaryButton
            type="reset"
            buttonContent="Reset"
            isDisabled={false}
          />
        </Container>
      </Container>
    </Form>
  );
};

export default BudgetSummaryReportFilter;
