import React from "react";
import "./formInputFieldItem.css";
import InfoTooltip from "../InfoTooltip";

type InputProps = {
  type: "text" | "number" | "email";
  id: string;
  name: string;
  className: string;
  wrapperClass?: string;
  wrapperId?: string;
  placeholder: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  value: any;
  maxLength?: number;
  minLength?: number;
  error?: string | undefined;
  errorClass?: string;
  min?: number | undefined;
  isSideIcon?: boolean;
  sideIcon?: React.ReactNode;
  isActionItem?: boolean;
  actionItem?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.FormEvent<HTMLInputElement>) => void;
  tooltipMessage?: string | undefined;
  messagePlacement?: any | undefined;
};
export const FormInputFieldItem = (props: InputProps) => {
  return (
    <div
      className={`${props.wrapperClass} reusable-form-input-control`}
      id={props.wrapperId || ""}
    >
      <div className="link-label-grouped">
        {props.label && (
          <label
            className={`fw-bold  ${props.isRequired ? "required" : ""}`}
            htmlFor={props.id}
          >
            {props.label}
          </label>
        )}
        {props?.tooltipMessage ? (
          <InfoTooltip
            title={props.tooltipMessage}
            placement={props?.messagePlacement || "top"}
          />
        ) : null}
        {props.isActionItem && props.actionItem ? props.actionItem : ""}
      </div>
      <div
        className={`form-input-icon-wrapper ${
          props.isSideIcon === true ? "with-side-icon" : ""
        }`}
      >
        <input
          type={props.type}
          name={props.name}
          id={props.id}
          className={`${props.className} ${props.error ? "error" : ""}`}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          minLength={props.minLength}
          min={props.min}
          value={props.value || ""}
          // value={props.value}
          disabled={props.disabled ? true : false}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onClick={props.onClick}
          onInput={props.onInput}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
        />
        {props.isSideIcon && props.isSideIcon === true && (
          <div className="form-input-field-icon">{props.sideIcon}</div>
        )}
      </div>
      <span className={`error ${props.errorClass ? props.errorClass : ""}`}>
        {props.error}
      </span>
    </div>
  );
};
