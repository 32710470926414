import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Select from "react-select";
import { useAppSelector } from "../../../../../app/hooks";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { getFiscalYear } from "../../../../../helpers/fiscalYearHelper";
import {
  getProfileFormatedDate,
  splitNumberByDecimalPoint,
} from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import {
  ObjectType,
  OtherDetailsType,
  PaymentTerms,
} from "../../../../../types";
import {
  currenciesSelector,
  paymentTermsSelector,
  setLoaderState,
  tagsList,
  tagsSelector,
} from "../../../../common/commonSlice";
import CurrencySelect from "../../../../common/components/CurrencySelect";
import GstSelect from "../../../../common/components/GstSelect";
import InfoTooltip from "../../../../common/components/InfoTooltip";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import {
  gstTypesList as vendorGstList,
  gstTypesSelector as vendorGstTypesSelector,
} from "../../../vendorManagement/vendorSlice";
import {
  gstTypesList as customerGstList,
  gstTypesSelector as customerGstTypesSelector,
} from "../../customerSlice";
import { otherDetailsValue } from "./StateInitialization";
import { TAX_SYSTEM } from "../../../../constants/constants";
import useVat from "../../../../hooks/useVat";

type Props = {
  redirectTo: string;
  otherFormValues: OtherDetailsType;
  formErrors: any;
  id: number;
  isCancel: boolean;
  type: string;
  isTaxPreference?: boolean;
  taxExemptReasons?: { value: number; label: string }[];
  organizationId?: number | null;
  isContactOrgClosed: boolean;
  fetchCurrencyCode: (code: string) => void;
};
const OtherDetails = (props: Props, ref: any) => {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { isGstOrg } = useGst();
  const { isVatOrg } = useVat();
  const tags = useAppSelector(tagsSelector);
  const gstTypes = useAppSelector(
    props.type === "Customer"
      ? customerGstTypesSelector
      : vendorGstTypesSelector
  );
  const currencies = useAppSelector(currenciesSelector);
  const paymentTerms = useAppSelector(paymentTermsSelector);
  const [isPaymentTermChange, SetIsPaymentTermChange] = useState(false);
  const [gstIn, setGstIn] = useState("");
  const [otherDetails, setOtherDetails] =
    useState<OtherDetailsType>(otherDetailsValue);
  const [countOfTags, setCountOfTags] = useState(0);
  const fiscal_year_date = getFiscalYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "current"
  );
  const [taxExemptionReasonSelect, setTaxExemptionReasonSelect] =
    useState<ObjectType>({
      value: 0,
      label: "",
    });
  const [taxPreference, setTaxPreference] = useState("TAXABLE");

  let fetchApis: any = [];

  let organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let subModulesWithPermission = organizationRole.length
    ? organizationRole.map((permission: any) => {
        return permission.subModule;
      })
    : [];

  useImperativeHandle(
    ref,
    () => ({
      otherDetails: otherDetails,
    }),
    [otherDetails]
  );

  useEffect(() => {
    dispatch(setLoaderState(true));
    getFormListData();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [tags, currencies, paymentTerms, gstTypes]);

  useEffect(() => {
    if (!props.id && !isPaymentTermChange) {
      paymentTerms.map((term: PaymentTerms, index) => {
        if (term.term_name === "Due on receipt") {
          setOtherDetails((values) => ({
            ...values,
            payment_term_id: term.id,
          }));
        }
      });
    }
  }, [paymentTerms, props.isCancel]);

  useEffect(() => {
    if (props.otherFormValues) {
      setOtherDetails({ ...props.otherFormValues });
      setGstIn(props.otherFormValues.gstin);
      let regType = props.otherFormValues.gst_registration_type;
      if (
        regType === "GST registered - regular" ||
        regType === "GST registered - composition" ||
        regType === "SEZ" ||
        regType === "Deemed exports - EOUs, STPs, EHTPs" ||
        regType === "SEZ Developer" ||
        regType === "Special Economic Zone(SEZ)"
      ) {
        $("#c_gst_number").prop("disabled", false);
        $(".gst-disable").css("opacity", "unset");
      } else {
        $("#c_gst_number").prop("disabled", true);
        $(".gst-disable").css("opacity", "0.5");
      }
      if (props.otherFormValues.tax_preference) {
        setTaxPreference(props.otherFormValues.tax_preference);
      }
    }
  }, [props.otherFormValues]);

  useEffect(() => {
    if (props.taxExemptReasons) {
      let taxExemptionReason = props.taxExemptReasons.filter(
        (item) => item.value === props.otherFormValues.exemption_reason_id
      );
      setTaxExemptionReasonSelect(taxExemptionReason);
    }
  }, [props.taxExemptReasons, props.otherFormValues]);

  useEffect(() => {
    const otherDetailsData = { ...otherDetails };
    const currencyCode = getCurrencyCode(Number(otherDetails.currency_id));
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    otherDetailsData.opening_balance = getRoundOffAmount(
      Number(otherDetailsData.opening_balance),
      decimals
    );
    setOtherDetails(otherDetailsData);
  }, [otherDetails.currency_id]);

  useEffect(() => {
    setTaxPreferenceToOtherDetails(taxPreference);
  }, [taxPreference, otherDetails.tax_preference]);

  const getCurrencyCode = (id: number) => {
    const currencyCode = currencies
      .filter((currency) => currency.id === id)
      .map((currency) => currency.code)[0];
    return currencyCode ? currencyCode : "";
  };

  const getFormListData = useCallback(async () => {
    if (subModulesWithPermission.includes(props.type)) {
      if (props.type === "Customer")
        fetchApis.push(
          dispatch(customerGstList(currentUserInfo.organization_id))
        );
      else if (props.type === "Vendor")
        fetchApis.push(
          dispatch(vendorGstList(currentUserInfo.organization_id))
        );
    }
    fetchApis.push(dispatch(tagsList(currentUserInfo.organization_id)));
    if (fetchApis.length) {
      await Promise.all(fetchApis);
      dispatch(setLoaderState(false));
    }
  }, [currentUserInfo.organization_id, props.type]);

  const handleChange = (data: { name: string; value: any }) => {
    let { name, value } = data;
    const re = /^[\+0-9\b]+$/;
    const balanceRegex = /^-?([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    const shortCodeRegex = /^[a-zA-Z0-9]*$/;

    if (name === "opening_balance") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value.toString());
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || balanceRegex.test(value)) {
          setOtherDetails({ ...otherDetails, [name]: value });
        }
      }
    } else if (name === "payment_term_id") {
      setOtherDetails({ ...otherDetails, [name]: value });
      SetIsPaymentTermChange(true);
    } else {
      if (name === "currency_id") {
        setOtherDetails({ ...otherDetails, [name]: Number(value) });
      } else if (name === "pan_number" || name === "gstin") {
        if (name === "gstin") setGstIn(value);
        setOtherDetails({
          ...otherDetails,
          [name]: value.toUpperCase(),
        });
      } else if (name === "short_code" || value === "") {
        if (shortCodeRegex.test(value)) {
          setOtherDetails({
            ...otherDetails,
            [name]: value.toUpperCase(),
          });
        }
      } else {
        setOtherDetails({ ...otherDetails, [name]: value });
      }
    }
    setOtherDetails({ ...otherDetails, [name]: value });
  };

  const tagsListObject = Array.isArray(tags)
    ? tags.map((tag, index) => ({
        value: tag.id,
        label: tag.name,
      }))
    : ([] as any);

  const editUserTags = Array.isArray(tags)
    ? tags
        .filter((tag, index) => otherDetails.tag_ids.includes(tag.id))
        .map((tag) => ({ value: tag.id, label: tag.name }))
    : ([] as any);

  /**
   * Function to handle GST type change
   */
  const handleGstType = (e: any) => {
    if (props.otherFormValues.transaction_exist) {
      if (props.type === "Customer") {
        ErrorToaster(
          "You cannot edit GST Treatment Type as there are transactions Linked to Customer."
        );
      }
      if (props.type === "Vendor") {
        ErrorToaster(
          "You cannot edit GST Treatment Type as there are transactions Linked to Vendor."
        );
      }
      return;
    }
    if (e.value) {
      setOtherDetails((values) => ({
        ...values,
        gst_registration_type: e.value,
      }));
      if (
        e.value === "GST registered - regular" ||
        e.value === "GST registered - composition" ||
        e.value === "SEZ" ||
        e.value === "Deemed exports - EOUs, STPs, EHTPs" ||
        e.value === "SEZ Developer" ||
        e.value === "Special Economic Zone(SEZ)"
      ) {
        $("#c_gst_number").prop("disabled", false);
        $(".gst-disable").css("opacity", "unset");
        setOtherDetails((values) => ({ ...values, gstin: gstIn }));
      } else {
        $("#c_gst_number").prop("disabled", true);
        $(".gst-disable").css("opacity", "0.5");
        setOtherDetails((values) => ({ ...values, gstin: "" }));
      }
    }
  };

  /**
   * Multiple Tag Handler
   */
  const handleSelectTags = (e: any) => {
    const selectedTag = Array.isArray(e)
      ? e.map((tag: { value: any }) => tag.value)
      : ([] as any);
    setCountOfTags(countOfTags + 1);
    setOtherDetails((values) => ({ ...values, tag_ids: selectedTag }));
  };

  const handleRemoveTags = (e: any) => {
    const selectedTag = Array.isArray(e)
      ? e.map((tag: { value: any }) => tag.value)
      : ([] as any);
    setCountOfTags(countOfTags - 1);
    setOtherDetails((values) => ({ ...values, tag_ids: selectedTag }));
  };

  /**
   * Function to round off default input elements after out of focus.
   */
  const handleItemOnBlur = (name: string, amount: number) => {
    if (!amount.toString().includes(".")) {
      return;
    }
    const [, fractionalPart] = splitNumberByDecimalPoint(amount);
    let roundedAmount: number = amount;
    const currencyCode = getCurrencyCode(Number(otherDetails.currency_id));
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    if (name === "opening_balance" && fractionalPart.length > decimals) {
      roundedAmount = getRoundOffAmount(amount, decimals);
      setOtherDetails({ ...otherDetails, [name]: roundedAmount });
    }
  };

  /*
    Tax Preference Radio Buttons click event
  */
  const onTaxableChange = (value: any) => {
    setTaxPreference(value);
    if (value === "Taxable") {
      props.formErrors.exemption_reason = "";
    }
    setTaxPreferenceToOtherDetails(value);
  };

  /**
   * Set the tax_preference value to the otherDetails Object
   * */
  const setTaxPreferenceToOtherDetails = (value: string) => {
    setOtherDetails({ ...otherDetails, ["tax_preference"]: value });
  };
  const dateFormatHandler = (date: string | null) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };

  return (
    <div
      className="tab-pane fade show active"
      id="pills-other"
      role="tabpanel"
      aria-labelledby="pills-other-tab"
    >
      {currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM && (
        <div className="row custom-row">
          <FormInputField
            type="text"
            name="pan_number"
            id="c_pan"
            className={
              props.formErrors.pan_number
                ? "form-control error"
                : "form-control"
            }
            placeholder="Enter valid PAN number"
            label="PAN"
            value={otherDetails?.pan_number}
            maxLength={10}
            onChange={(e) => handleChange(e.target)}
            error={props.formErrors.pan_number}
          />
          <div className="col-12 col-lg-4"></div>
        </div>
      )}

      {isGstOrg && (
        <>
          <div className="row custom-row">
            <div className="col-12 col-lg-4 mw-325">
              <label htmlFor="c_gst_type" className="required">
                GST Registration Type
              </label>
              <GstSelect
                gstTypes={gstTypes}
                gstRegistrationType={otherDetails.gst_registration_type}
                onGstTypeChange={(e) =>
                  handleGstType({
                    name: "gst_registration_type",
                    value: e?.value,
                  })
                }
                dropPosition="top"
                error={props.formErrors.gst_registration_type}
              />
              <span className="error">
                {props.formErrors.gst_registration_type}
              </span>
            </div>
            <FormInputField
              type="text"
              name="gstin"
              id="c_gst_number"
              wrapperClass="gst-disable"
              className={
                props.formErrors.gstin ? "form-control error" : "form-control"
              }
              placeholder="Enter GSTIN"
              label="GSTIN"
              value={otherDetails?.gstin}
              maxLength={15}
              onChange={(e) => handleChange(e.target)}
              error={props.formErrors.gstin}
              disabled={true}
            />
          </div>
        </>
      )}
      {isVatOrg && props.type === "Customer" && (
        <>
          <div className="row custom-row">
            <div className="col-12 mw-325 remove-row-padding">
              <FormInputField
                type="text"
                name="tax_registration_number"
                id="tax_registration_number"
                // wrapperClass="gst-disable"
                className={
                  props.formErrors.gstin ? "form-control error" : "form-control"
                }
                placeholder="Enter Tax ID"
                label="Tax Registration Number (TRN)"
                value={otherDetails?.tax_registration_number}
                maxLength={15}
                onChange={(e) => {
                  if (e.target.value === "" || /^-?\d+(\.\d+)?$/.test(e.target.value)) {
                    handleChange(e.target);
                  }
                }}
                error={props.formErrors.tax_registration_number}
                disabled={false}
              />
            </div>
          </div>
        </>
      )}

      {isGstOrg && props.isTaxPreference && (
        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-325 radio-button-wrap d-init">
            <label htmlFor="taxable" className="taxable-label required">
              Tax Preference
            </label>
            <RadioButtonGroup
              name="tax_preference"
              id="tax_preference"
              valuesArray={[
                { label: "Taxable", value: "TAXABLE" },
                { label: "Non-Taxable", value: "NON_TAXABLE" },
              ]}
              value={taxPreference}
              icon={
                <span>
                  <RadioButtonUncheckedIcon />
                </span>
              }
              checkedIcon={
                <span>
                  <RadioButtonCheckedIcon className="radio-btn-tds" />
                </span>
              }
              isDisabled={false}
              onClick={onTaxableChange}
            />
            <span className="error">
              {(props.formErrors.tax_preference &&
                props.formErrors.tax_preference?.toString()) ||
                ""}
            </span>
          </div>
          {isGstOrg && taxPreference === "NON_TAXABLE" && (
            <>
              <div
                className={`col-12 col-lg-4 mw-325 `} //${!props.formik.values.customer_id ? "disabled-item" : ""  }
              >
                <label htmlFor="tax_exemption_reason" className="required">
                  Exemption Reason
                  <InfoTooltip
                    title={"Enter the reason for excluding this from the GST"}
                  />
                </label>
                <div className="form-group">
                  <div>
                    <Select
                      name="tax_exemption_id"
                      id="tax_exemption_reason"
                      value={
                        // taxExemptionReasonSelect.label !== "" &&
                        // taxExemptionReasonSelect.value
                        //   ? taxExemptionReasonSelect
                        //   : null
                        taxExemptionReasonSelect
                      }
                      options={
                        props.taxExemptReasons ? props.taxExemptReasons : []
                      }
                      placeholder="Choose tax exemption reason"
                      onChange={(e: any) => {
                        setTaxExemptionReasonSelect({
                          value: e.value,
                          label: e.label,
                        });
                        setOtherDetails({
                          ...otherDetails,
                          ["exemption_reason_id"]: e.value,
                        });
                      }}
                      className={`currency-select form-select custom-select tax_category payment_method w-325 m-0 ${
                        props.formErrors.exemption_reason ? "error" : ""
                      }`}
                      styles={customSelectStyle}
                      menuPlacement="top"
                    />
                    <span className="error">
                      {/* {(props.formik.touched.tax_account_name &&
                      props.formErrors.errors.tax_account_name?.toString()) ||
                      ""} */}

                      {(props.formErrors.exemption_reason &&
                        props.formErrors.exemption_reason?.toString()) ||
                        ""}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <CurrencySelect
            label="Currency"
            currencyId={otherDetails.currency_id}
            onChange={(data: {
              name: string;
              value: number;
              code?: string | undefined;
            }) => {
              props.fetchCurrencyCode(data.code || "");

              handleChange(data);
            }}
            isDisabled={props.id ? true : false}
            dropPosition="top"
            error={props.formErrors.currency_id}
          />
        </div>
        {(!props.id ||
          props.organizationId === currentUserInfo.organization_id) && (
          <FormInputField
            type="text"
            name="opening_balance"
            id="c_opening_bal"
            className={
              props.formErrors.opening_balance
                ? "form-control error"
                : "form-control"
            }
            placeholder="Enter opening balance"
            disabled={props.isContactOrgClosed && Number(props.id) > 0}
            label="Opening Balance"
            value={
              otherDetails?.opening_balance ? otherDetails?.opening_balance : ""
            }
            onChange={(e) => handleChange(e.target)}
            onBlur={() =>
              handleItemOnBlur(
                "opening_balance",
                otherDetails?.opening_balance
                  ? Number(otherDetails?.opening_balance)
                  : 0
              )
            }
            error={props.formErrors.opening_balance}
            tooltipMessage={
              props.isContactOrgClosed && Number(props.id) > 0
                ? `Opening Balance is as of ${getProfileFormatedDate(
                    currentUserInfo.organization_date_format,
                    fiscal_year_date.startDate
                  )}`
                : currentUserInfo.is_ledger_org_closed && Number(props.id) === 0
                ? `Opening Balance is as of ${getProfileFormatedDate(
                    currentUserInfo.organization_date_format,
                    fiscal_year_date.startDate
                  )}`
                : `Opening Balance is as of ${getProfileFormatedDate(
                    currentUserInfo.organization_date_format,
                    currentUserInfo.migration_date
                      ? currentUserInfo.migration_date
                      : ""
                  )}`
            }
          />
        )}
      </div>
      {/* <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="c_payment_terms">Payment Terms</label>
            {(props.redirectTo === "/customers" ||
              props.redirectTo === "/vendors") && 
            subModulesWithPermission.includes("Payments Terms") ? (
              <PaymentTermsModal />
            ) : null}
          </div>
          <PaymentTermSelect
            paymentTermId={otherDetails.payment_term_id}
            onChange={handleChange}
          />
        </div>
      </div> */}
    </div>
  );
};

export default forwardRef(OtherDetails);
