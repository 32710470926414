import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  countrySelector,
  currentUserSelector,
  getCountries,
} from "../../../../../common/commonSlice";
import AddAddress from "./AddAddress";
import BillingAddressCard from "./BillingAddressCard";
import ShippingAddressCard from "./ShippingAddressCard";
import { useSelector } from "react-redux";
import { addressSelector } from "./addressSlice";
import AddressModal from "../../../../../common/components/AddressModal";
import { useParams } from "react-router-dom";
import _ from "lodash";

import "./AddressDetails.css";

type Props = {
  initialAddress: any[];
  consumerName: string;
};
const AddressDetails = (props: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const countries = useAppSelector(countrySelector);
  const addressList = useSelector(addressSelector);
  const newAddressId = useRef<string>();
  const [addressId, setAddressId] = useState("");
  const [showAddressModal, setShowAddressModal] = useState(false);

  const OpenAddressModal = () => {
    setShowAddressModal(true);
  };
  const closeAddressModal = () => {
    setShowAddressModal(false);
  };

  useEffect(() => {
    if (!countries.length) getAllCountries();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [countries]);

  /**
   * Fetch all country list
   */
  const getAllCountries = async () => {
    await dispatch(getCountries());
  };
  /**
   * set address ID handler
   */
  const setAddressIdHandler = (id: string) => {
    setAddressId(id);
  };
  /**
   * Function to check whether billing address is changed or not
   */
  const isBillingAddressChanged = () => {
    if (!id) {
      const billingAddressList = addressList.find(
        (address) => address.is_billing === true
      );
      return billingAddressList ? true : false;
    } else {
      const initialBillingAddressList = props.initialAddress
        .filter((item) => item.is_billing)
        .map((address) => address);
      const updatedBillingAddressList = addressList
        .filter((item) => item.is_billing)
        .map((address) => address);
      if (_.isEqual(initialBillingAddressList, updatedBillingAddressList)) {
        return false;
      } else {
        return true;
      }
    }
  };
  /**
   * Function to check whether shipping address is changed or not
   */
  const isShippingAddressChanged = () => {
    if (!id) {
      const billingAddressList = addressList.find(
        (address) => address.is_shipping === true
      );
      return billingAddressList ? true : false;
    } else {
      const initialShippingAddressList = props.initialAddress
        .filter((item) => item.is_shipping)
        .map((address) => address);
      const updatedShippingAddressList = addressList
        .filter((item) => item.is_shipping)
        .map((address) => address);
      if (_.isEqual(initialShippingAddressList, updatedShippingAddressList)) {
        return false;
      } else {
        return true;
      }
    }
  };
  /**
   * Function to store id of newly created address to implement scroll to newly created address
   */
  const storeNewAddressIdHandler = (id: string) => {
    newAddressId.current = id;
  };

  return (
    <>
      <div
        className="tab-pane address-tab-pane boxed-addresses fade"
        id="pills-address"
        role="tabpanel"
        aria-labelledby="pills-address-tab"
      >
        <div className="row mb-0">
          <div className="col col-lg-12">
            <div className="row g-0 mb-0">
              <div className="col-12 col-lg-6 billing-address d-flex">
                <h2>
                  Billing Address
                  {isBillingAddressChanged() && (
                    <span className="unsaved-msg">
                      (Unsaved changes on address)
                    </span>
                  )}
                </h2>
                <div className="address-container-wrapper">
                  <div className="address-container d-flex">
                    <AddAddress
                      title={"Add Billing Address"}
                      type="billing"
                      OpenAddressModal={OpenAddressModal}
                      setAddressIdHandler={setAddressIdHandler}
                      customerName={props.consumerName}
                    />
                    {addressList.map((address, index) => {
                      return address.is_billing ? (
                        <BillingAddressCard
                          OpenAddressModal={OpenAddressModal}
                          key={index}
                          billingAddress={address}
                          setAddressIdHandler={setAddressIdHandler}
                          addressId={address.id}
                          shouldScrollTo={newAddressId.current === address.id}
                        />
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 shipping-address d-flex">
                <h2>
                  Shipping Address
                  {isShippingAddressChanged() && (
                    <span className="unsaved-msg">
                      (Unsaved changes on address)
                    </span>
                  )}
                </h2>
                <div className="address-container-wrapper">
                  <div className="address-container d-flex">
                    <AddAddress
                      title={"Add Shipping Address"}
                      type="shipping"
                      OpenAddressModal={OpenAddressModal}
                      setAddressIdHandler={setAddressIdHandler}
                      customerName={props.consumerName}
                    />
                    {addressList.map((address, index) => {
                      return address.is_shipping ? (
                        <ShippingAddressCard
                          OpenAddressModal={OpenAddressModal}
                          key={index}
                          shippingAddress={address}
                          setAddressIdHandler={setAddressIdHandler}
                          addressId={address.id}
                          shouldScrollTo={newAddressId.current === address.id}
                        />
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAddressModal && (
          <AddressModal
            closeAddressModal={closeAddressModal}
            addressId={addressId}
            consumerName={props.consumerName}
            createdFrom="customer_page"
            fetchConsumerDetail={() => {}}
            storeNewAddressIdHandler={storeNewAddressIdHandler}
          />
        )}
      </div>
    </>
  );
};

export default AddressDetails;
