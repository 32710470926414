import Keycloak from "keycloak-js";
import { permissionHandler } from "../helpers/permissionHelper";
import { store } from "../app/store";
import { roleMapping } from "../features/common/commonSlice";

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_JSON_URL,
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
};

const _kc = new Keycloak(keycloakConfig.url);

const storeTokens = () => {
  if (_kc.token) sessionStorage.setItem("token", _kc.token);
  if (_kc.refreshToken) sessionStorage.setItem("refresh-token", _kc.refreshToken);
};

const clearSession = () => {
  [
    "token",
    "refresh-token",
    "orgId",
    "rolePermissions",
    "organizationRole",
    "OrgName",
  ].forEach((item) => sessionStorage.removeItem(item));
};

const scheduleTokenCheck = () => {
  const tokenParsed = _kc.tokenParsed;
  if (tokenParsed && tokenParsed.exp) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeRemaining = tokenParsed.exp - currentTime;

    if (timeRemaining > 0) {
      const checkInterval = Math.max(10, timeRemaining / 2); // Half of the remaining time, min 10 seconds
      const nextRefreshTime = currentTime + checkInterval;
      sessionStorage.setItem("nextRefreshTime", nextRefreshTime); // Save the next refresh time for other tabs
      setTimeout(() => {
        checkTokenExpiry();
        scheduleTokenCheck(); // Schedule the next check dynamically
      }, checkInterval * 1000);
    } else {
      console.warn("Token has already expired. Attempting to refresh...");
      updateToken();
    }
  }
};

/**
 * Handles token expiration checks and refreshes the token if needed.
 */
const checkTokenExpiry = () => {
  const tokenParsed = _kc.tokenParsed;
  if (tokenParsed && tokenParsed.exp) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeRemaining = tokenParsed.exp - currentTime;

    if (timeRemaining < 120) {
      updateToken();
    }
  }
};

/**
 * Event listener for tab synchronization via storage event.
 */
window.addEventListener("storage", (event) => {
  if (event.key === "nextRefreshTime") {
    const nextRefreshTime = parseInt(sessionStorage.getItem("nextRefreshTime"), 10);
    const currentTime = Math.floor(Date.now() / 1000);

    if (nextRefreshTime && nextRefreshTime - currentTime < 60) {
    //  Detected upcoming token expiration from another tab. Refreshing...
      updateToken();
    }
  }
});

/**
 * Check token status on tab activation.
 */
document.addEventListener("visibilitychange", () => {
  if (!document.hidden) {
    // Tab is now active. Checking token expiration...
    checkTokenExpiry();
  }
});

// Initialize token checks
const initKeycloak = async (onAuthenticatedCallback) => {
  try {
    const authenticated = await _kc.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    });

    if (!authenticated) {
      console.log("User is not authenticated.");
      return;
    }

    storeTokens();
    _kc.redirectUri = keycloakConfig.redirectUri;
    onAuthenticatedCallback();

    const response = await store.dispatch(roleMapping());
    const permissionMapping = response.payload;

    if (
      permissionMapping &&
      Object.keys(permissionMapping).length &&
      !("error" in permissionMapping)
    ) {
      permissionHandler(_kc.tokenParsed?.realm_access, permissionMapping);
    }

    scheduleTokenCheck(); // Start the dynamic token check
  } catch (error) {
    console.error("Keycloak initialization error:", error);
  }
};

const handleTokenExpiry = () => {
  _kc
    .updateToken(30)
    .then(storeTokens)
    .catch(clearSession);
};

const updateToken = async () => {
  try {
    const refreshed = await _kc.updateToken(-1);
    if (refreshed) storeTokens();
  } catch (error) {
    console.error("Failed to update token:", error);
  }
};

const UserService = {
  initKeycloak,
  doLogin: _kc.login.bind(_kc),
  doLogout: _kc.logout.bind(_kc),
  isLoggedIn: () => !!_kc.token,
  getToken: () => _kc.token,
  updateToken,
  getUsername: () => _kc.tokenParsed?.preferred_username,
  hasRole: (roles) => roles.some((role) => _kc.hasRealmRole(role)),
};

export default UserService;
