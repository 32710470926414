import { Formik, FormikProps } from "formik";
import InfoTooltip from "../../../../../../common/components/InfoTooltip";
import { message } from "../../../../../../constants/messages";
import "../../SystemVoucherForm.css";
import Select from "react-select";
import { customSelectStyle } from "../../../../../../common/components/SelectCustomStyle";
import {
  getNewSystemVoucherNumber,
  getSystemVoucherConfigList,
} from "../../../../systemVoucherSlice";
import useCommonData from "../../../../../../hooks/useCommon";
import { useEffect, useState } from "react";
import { joinForPayload } from "../../../../../../../helpers/prefixHelper";
import { useParams } from "react-router-dom";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { svInitialItemValues } from "../../../helpers/StateInitialization";
import SystemVoucherConfigurationListModal from "../../../systemVoucherConfigurationList/systemVoucherConfigurationListModal";
import SystemVoucherConfigurationModal from "../../../systemVoucherConfiguration/systemVoucherConfigurationModal";
import { AddTagIcon } from "../../../../../../../assets/images";
import { ObjectType } from "../../../../../../../types";


type Props = {
  formik: FormikProps<any>;
};
type ConfigList = {
  account_ids: number[];
  id: number;
  prefix: string;
  system_voucher_name: string;
};
export const SystemVoucherConfigurationType = (props: Props) => {
  const { editId } = useParams();
  const { currentUserInfo, dispatch } = useCommonData();
  const [configList, setConfigList] = useState<ConfigList[]>([]);
  const [configValue, setConfigValue] = useState();

  useEffect(() => {
    getConfigList();
  }, [currentUserInfo.organization_id]);
  useEffect(() => {
    if (
      editId &&
      props.formik.values?.system_config_id &&
      configList.length > 0
    ) {
      const configObj = configList.find(
        (option: any) => option.id === props.formik.values.system_config_id
      );
      const dropDownObj: any = {
        label: configObj?.system_voucher_name,
        value: configObj?.id,
      };
      if (dropDownObj) {
        setConfigValue(dropDownObj);
        const accounts = configObj?.account_ids;
        props.formik.setFieldValue("accounts_for_sv", accounts);
      }
    }
  }, [props.formik.values?.system_config_id]);

  const getConfigList = async () => {
    const responseAction = await dispatch(
      getSystemVoucherConfigList({
        orgId: currentUserInfo.organization_id,
        isIntegrated: false
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setConfigList(response.system_voucher_configs);
    } else {
      setConfigList([]);
    }
  };
  const handleSelectChange = (e: any) => {
    props.formik.setFieldValue("system_config_id", e.value);
    setConfigValue(e);
    const configureValue = configList.find((option) => option.id === e.value);
    const accounts = configureValue?.account_ids;
    props.formik.setFieldValue("accounts_for_sv", accounts);
    const itemInitializingValues = props.formik.values?.item_details?.map((item: ObjectType) => ({
      ...svInitialItemValues,
      id: item.id,
    }));
    props.formik.setFieldValue("item_details", itemInitializingValues);
    let paymentNumberSplit =
      props.formik.values.journal_voucher_number?.split("~");
    props.formik.setFieldValue(
      "journal_voucher_number",
      joinForPayload({
        journal_voucher_prefix: configureValue?.prefix,
        journal_voucher_number: paymentNumberSplit[1],
      })
    );
    if (configureValue?.prefix) {
      paymentNumber(configureValue.prefix);
    }
  };
  const paymentNumber = async (prefix: string) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getNewSystemVoucherNumber({
        orgId: currentUserInfo.organization_id,
        prefix: prefix,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let PR_number = joinForPayload(response);
        props.formik.setFieldValue("journal_voucher_number", PR_number);
      }
    }
    dispatch(setLoaderState(false));
  };

  const AddSystemVoucherTypeButton = () => {
    return (
      <span>
        <img src={AddTagIcon} alt="add system voucher type" />
        Add Voucher Type
      </span>
    );
  };
  return (
    <div className="col-12 col-lg-4 mw-325">
      <label htmlFor="check_expiry" className="required">
        System Voucher type
      </label>
      <SystemVoucherConfigurationModal
        buttonContent={<AddSystemVoucherTypeButton />}
        handleRefreshList={getConfigList}
      />
      <Select
        name="system_voucher_type"
        id="system_voucher_type"
        value={configValue ? configValue : null}
        options={
          configList.length > 0
            ? (configList.map((item) => ({
                value: item.id,
                label: item.system_voucher_name.toString(),
              })) as any)
            : []
        }
        placeholder="Choose system voucher type"
        onChange={handleSelectChange}
        styles={customSelectStyle}
        menuPlacement="bottom"
      />
    </div>
  );
};
