import { FormikProps } from "formik";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Section } from "../../../../../common/components/htmlTags/Section";
import { JournalDate } from "./components/JournalDate";
import { JournalNumber } from "./components/JournalNumber";
import { JournalReferenceNumber } from "./components/JournalReferenceNumber";
import { JournalNotes } from "./components/JournalNotes";
import { JournalCurrency } from "./components/JournalCurrency";
import { JournalType } from "./components/JournalType";
import { useModuleCustomFieldList } from "../../../../../hooks/useModuleCustomFieldList";
import { ObjectType } from "../../../../../../types";
import {
  FormInputFieldsInRows,
  useParams,
} from "../../../../paymentsReceived/components/paymentReceivedForm/imports";
import { CustomFieldInTransactions } from "../../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";

type Props = {
  formik: FormikProps<any>;
  customFieldRef: any;
};
export const JournalVoucherBasicDetails = (props: Props) => {
  const { editId } = useParams();
  const journalVoucherCustomFields = useModuleCustomFieldList("Journal Entry");
  const getCustomFieldData = async (customFieldData: ObjectType) => {
    await props.formik.setFieldValue("custom_fields", customFieldData.values);
  };
  return (
    <Section className="form-top-section">
      <Container className="row custom-row">
        <JournalDate formik={props.formik} />
        <JournalNumber formik={props.formik} />
      </Container>
      <Container className="row custom-row ">
        <JournalReferenceNumber formik={props.formik} />
        <JournalCurrency formik={props.formik} />
      </Container>
      <Container className="row custom-row">
        <JournalNotes formik={props.formik} />
        <JournalType formik={props.formik} />
      </Container>
      <Container className="mb-3">
        <CustomFieldInTransactions
          getFieldValues={getCustomFieldData}
          customFieldsArray={journalVoucherCustomFields}
          initialValues={props.formik.values.custom_fields}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
          wrapperClass={!props.formik.values.currency_id ? "disabled-item" : ""}
          isDisabled={!props.formik.values.currency_id}
        />
      </Container>
    </Section>
  );
};
