import "./AddressCard.css";
import { MailBox } from "../../../../../../assets/images";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../../../../app/hooks";
import { addBillingAddress, addShippingAddress } from "./addressTypeSlice";

type Props = {
  title: string;
  type: string;
  customerName: string;
  OpenAddressModal: () => void;
  setAddressIdHandler: (id: string) => void;
};
const AddAddress = (props: Props) => {
  const dispatch = useAppDispatch();
  const addAddressHandler = () => {
    props.type === "billing"
      ? dispatch(addBillingAddress("add_billing"))
      : dispatch(addShippingAddress("add_shipping"));
  };
  return (
    <>
      <div className="boxed-item address-item add-address-card card">
        <div className="card-body d-flex flex-column">
          <img src={MailBox} alt="add address" width={80} height={80} />
          <Link
            to="#"
            role="button"
            className="add-customer-link add-address-link"
            onClick={(e: any) => {
              e.preventDefault();
              addAddressHandler();
              props.setAddressIdHandler("");
              props.OpenAddressModal();
            }}
          >
            {props.title}
          </Link>
        </div>
      </div>
    </>
  );
};
export default AddAddress;
