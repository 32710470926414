import { getProfileFormatedDate } from "../../../../../../helpers/helper";
import { ObjectType } from "../../../../../../types";
import CompanyDetails from "../../../../../common/components/detailsPage/CompanyDeatails";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Span } from "../../../../../common/components/htmlTags/Span";
import useCommonData from "../../../../../hooks/useCommon";
type Props = {
    organizationDetails: ObjectType;
    budgetRange: string;
  };
const BudgetSummaryReportOrgDetails = ({ organizationDetails, budgetRange }: Props) => {
    const reportNames = ["Balance Sheet", "Trial Balance"];
    const { currentUserInfo } = useCommonData();
    return (
      <Container className="report-header budget-variance-report-org-details">
        <Container className="report-header-container d-flex justify-content-between">
          <CompanyDetails
            organizationData={organizationDetails}
          />
          <Container className="report-details align-right">
            <Container className="page-type-label">{"Budget Summary Report"}</Container>
            <Container className="preview-date-field">
              <Span className="start-dt">
                {budgetRange}
              </Span>
              <Container className="report-header-org-name mt-2">
                {organizationDetails?.organization_name}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    );
};

export default BudgetSummaryReportOrgDetails;
