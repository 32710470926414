import React, { useState } from "react";
import { FormFieldContainer } from "../../../../common/components/ui";
import { regenerateVoidInvoices } from "../../subscriptionSlice";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../helpers/toastHelper";
import useCommonData from "../../../../hooks/useCommon";
import RegenerateInvoicePreview from "../regenerateInvoicePreview/RegenerateInvoicePreview";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import Modal from "@mui/material/Modal";
import "./RegenerateInvoice.css";

interface RegenerateInvoiceProps {
  handleClose: () => void;
  refreshSubscriptionDetails: () => void;
  fetchChildInvoiceList: (recurring_invoice_id: number) => void;
  currentCycleStartDate: string;
  currentCycleEndDate: string;
  invoiceRegenerationEnabled?: boolean;
  subscriptionId: string | undefined;
  overviewSubscriptionData?: any;
}

const formatDate = (dateString?: string) => {
  if (!dateString) return "";
  const [day, month, year] = dateString.split("-");
  const date = new Date(`${year}-${month}-${day}`);
  return date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

const formatToISODate = (date: Date | null): string => {
  return date ? date.toISOString().split("T")[0] : "";
};

const RegenerateInvoice = ({
  handleClose,
  subscriptionId,
  currentCycleStartDate,
  currentCycleEndDate,
  invoiceRegenerationEnabled,
  overviewSubscriptionData,
  refreshSubscriptionDetails,
  fetchChildInvoiceList,
}: RegenerateInvoiceProps) => {
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(currentCycleStartDate.split("-").reverse().join("-"))
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(currentCycleEndDate.split("-").reverse().join("-"))
  );
  const [prorationEnabled, setProrationEnabled] = useState(false);
  const { currentUserInfo, dispatch } = useCommonData();
  const [includeCharges, setIncludeCharges] = useState(false);
  const [showCustomPeriod, setShowCustomPeriod] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const formattedStartDate = formatDate(currentCycleStartDate);
  const formattedEndDate = formatDate(currentCycleEndDate);
  const startDateObject = new Date(
    currentCycleStartDate.split("-").reverse().join("-")
  );
  const endDateObject = new Date(
    currentCycleEndDate.split("-").reverse().join("-")
  );
  const invoicingOptionElement = document.querySelector(
    'input[name="invoicingOptions"]:checked'
  ) as HTMLInputElement;
  const invoicing_option =
    invoicingOptionElement?.value === "invoice-now"
      ? "IMMEDIATELY"
      : "UNBILLED";

  const handleGenerate = async () => {
    const invoice_start_date = formatToISODate(startDate);
    const invoice_end_date = formatToISODate(endDate);

    try {
      const response = await dispatch(
        regenerateVoidInvoices({
          orgId: currentUserInfo.organization_id,
          subscriptionId: Number(subscriptionId),
          invoiceStartDate: invoice_start_date,
          invoiceEndDate: invoice_end_date,
          prorationEnabled,
          includeCharges,
          invoicingOptions: invoicing_option,
        })
      ).unwrap();

      if (response?.message === "Invoice generated") {
        SuccessToaster(
          "Invoice regenerated successfully!",
          "invoice-regeneration-success"
        );
        setOpenModal(false);
        handleClose();
        refreshSubscriptionDetails();
        setTimeout(() => {
          fetchChildInvoiceList(overviewSubscriptionData.recurring_invoice_id);
        }, 5000);
      } else {
        ErrorToaster("Could not regenerate invoice. Try again later");
      }
    } catch (error) {
      ErrorToaster("Could not regenerate invoice. Try again later");
    }
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="regenerate-invoice-title"
      aria-describedby="regenerate-invoice-description"
      className="regenerate-invoice-modal-wrapper"
    >
      <div className="regenerate-invoice-modal">
        <div className="modal-header-regenerate-invoice">
          <h5 className="modal-title-regenerate-invoice">
            Regenerate the invoices with changes, additions for this period
          </h5>
        </div>

        <div className="modal-body-regenerate-invoice">
          <div className="radio-group-regenerate-invoice">
            <div className="radio-option-regenerate-invoice">
              <input
                type="radio"
                name="invoicePeriod"
                defaultChecked
                id="full-term"
                value="full-term"
                onChange={() => {
                  setShowCustomPeriod(false);
                  setStartDate(
                    new Date(
                      currentCycleStartDate.split("-").reverse().join("-")
                    )
                  );
                  setEndDate(
                    new Date(currentCycleEndDate.split("-").reverse().join("-"))
                  );
                  setProrationEnabled(false);
                }}
              />
              <label htmlFor="full-term">
                Full term (Invoice will be regenerated for the period from{" "}
                {formattedStartDate} to {formattedEndDate})
              </label>
            </div>

            <div className="radio-option-regenerate-invoice">
              <input
                type="radio"
                name="invoicePeriod"
                id="custom-period"
                value="custom-period"
                onChange={() => setShowCustomPeriod(true)}
              />
              <label htmlFor="custom-period">
                Custom period (Select the period for which you want to
                regenerate the invoice)
              </label>
            </div>
          </div>
          {showCustomPeriod && (
            <>
              <FormFieldContainer
                wrapperClass="mw-325"
                label={"Start Date"}
                isRequired={false}
              >
                <CustomDatepicker
                  date={startDate}
                  handleDate={(date) => setStartDate(date)}
                  type="invoice"
                  module="Invoice"
                  error={""}
                  zIndex={0}
                  enableFutureDate={true}
                  isDisabled={false}
                  id={"start-date-picker"}
                  minDate={startDateObject}
                  maxDate={endDate || endDateObject}
                />
              </FormFieldContainer>
              <FormFieldContainer
                wrapperClass="mw-325"
                label={"End Date"}
                isRequired={false}
              >
                <CustomDatepicker
                  date={endDate}
                  handleDate={(date) => setEndDate(date)}
                  type="invoice"
                  module="Invoice"
                  error={""}
                  zIndex={0}
                  enableFutureDate={true}
                  isDisabled={false}
                  id={"end-date-picker"}
                  minDate={startDate || startDateObject}
                  maxDate={endDateObject}
                />
              </FormFieldContainer>

              <div className="checkbox-wrap regenerate-invoices-checkbox mb-4">
                <input
                  name="prorate_charges"
                  className="form-check-input"
                  type="checkbox"
                  checked={prorationEnabled}
                  onChange={(e) => setProrationEnabled(e.target.checked)}
                  disabled={false}
                  id="prorateCharges"
                />
                <label
                  className="form-check-label fw-normal"
                  htmlFor="prorateCharges"
                >
                  Prorate charges for the period
                </label>
              </div>
            </>
          )}
          <div className="checkbox-wrap regenerate-invoices-checkbox mb-4">
            <input
              name="include_charges"
              className="form-check-input"
              type="checkbox"
              checked={includeCharges}
              onChange={(e) => setIncludeCharges(e.target.checked)}
              disabled={false}
              id="includeCharges"
            />
            <label
              className="form-check-label fw-normal"
              htmlFor="includeCharges"
            >
              Include one time charges if any to the regenerated invoice
            </label>
          </div>

          {invoiceRegenerationEnabled && (
            <>
              <div className="modal-sub-part-regenerate-invoice">
                Invoice options
              </div>
              <div className="radio-group-regenerate-invoice-2">
                <div className="radio-option-regenerate-invoice">
                  <input
                    type="radio"
                    name="invoicingOptions"
                    defaultChecked
                    id="invoice-now"
                    value="invoice-now"
                  />
                  <label htmlFor="invoice-now">Invoice Now</label>
                </div>

                <div className="radio-option-regenerate-invoice">
                  <input
                    type="radio"
                    name="invoicingOptions"
                    id="unbilled-charges"
                    value="unbilled-charges"
                  />
                  <label htmlFor="unbilled-charges">
                    Add to unbilled charges
                  </label>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="allocation-button-wrapper">
          <button
            type="button"
            className="btn save-button"
            onClick={() => setOpenPreviewModal(true)}
          >
            Preview
          </button>
          <button
            type="button"
            className="btn cancel-button allocation-cancel"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>

        {openPreviewModal && (
          <RegenerateInvoicePreview
            startDate={startDate}
            endDate={formatToISODate(endDate)}
            handleClose={() => setOpenPreviewModal(false)}
            handleGenerate={handleGenerate}
            overviewSubscriptionData={overviewSubscriptionData}
          />
        )}
      </div>
    </Modal>
  );
};

export default RegenerateInvoice;
