import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { FormikProps } from "formik";
import moment from "moment";
import {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  useEffect,
} from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getFormatedDate } from "../../../../../helpers/helper";
import { ObjectType, PlanDefaultRef } from "../../../../../types";
import CurrencySelect from "../../../../common/components/CurrencySelect";
import CustomSearchSelect from "../../../../common/components/CustomSearchSelect/CustomSearchSelect";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { Container } from "../../../../common/components/htmlTags/Container";
import { Label } from "../../../../common/components/htmlTags/Label";
import { Section } from "../../../../common/components/htmlTags/Section";
import InfoTooltip from "../../../../common/components/InfoTooltip";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { SelectWithLeftInputCustomStyle } from "../../../../common/components/SelectWithInputCustomStyle";
import { message } from "../../../../constants/messages";
import useCommonData from "../../../../hooks/useCommon";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { accountsGlCodeSelector } from "../../../chartOfAccounts/coaSlice";
import { checkCouponNameExist } from "../../couponSlice";
import {
  DurationTypeDropdown,
  discountTypeDropdown,
  durationUnitDropdown,
} from "../../CouponStateInitialization";
import { validationDurationCountMsg } from "./couponFormValidation/CouponFormValidation";

type Props = {
  formik: FormikProps<any>;
  editId: string;
  formErrors: ObjectType;
  setFormErrors: Dispatch<SetStateAction<ObjectType>>;
};

const CouponDetails: ForwardRefRenderFunction<PlanDefaultRef, Props> = (
  props
) => {
  const { currentUserInfo, navigate } = useCommonData();
  const accountsGlCode = useAppSelector(accountsGlCodeSelector);
  let interval: any = null;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    checkCouponNameDuplicate(props.formik.values.name, signal);
    return () => {
      controller.abort();
    };
  }, [props.formik.values.name]);

  const checkCouponNameDuplicate = async (
    couponName: string,
    signal: AbortSignal
  ) => {
    if (!couponName.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkCouponNameExist({
            name: couponName,
            orgId: currentUserInfo.organization_id,
            coupon_id: props.editId ? Number(props.editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (response) {
            if (response.coupon_name_available === true) {
              props.setFormErrors({
                ...props.formErrors,
                name: "",
              });
              props.formik.setFieldError("name", undefined);
            } else {
              props.setFormErrors({
                ...props.formErrors,
                name: "Coupon Name already exists. Please choose a different one",
              });
              props.formik.setFieldError("name", "Already exists");
            }
          }
        }
      }, 1000);
    }
  };

  const onFormInputChange = (event: any) => {
    const { name, value } = event.target;
    if (
      (name === "maximum_redemptions" || name === "limited_duration_value") &&
      !Number.isNaN(Number(value)) &&
      value !== "0"
    ) {
      const modifiedValue = `${value.replaceAll(".", "").trim()}`;
      props.formik.setFieldValue(name, modifiedValue);
      if (name === "limited_duration_value")
        props.setFormErrors({
          ...props.formErrors,
          limited_duration_value: validationDurationCountMsg(
            Number(modifiedValue),
            props.formik.values.limited_duration_unit
          ),
        });
    }
    if (
      name === "discount_value" &&
      !Number.isNaN(Number(value)) &&
      ((props.formik.values.discount_type === "PERCENTAGE" &&
        Number(value) <= 100) ||
        props.formik.values.discount_type === "ABSOLUTE")
    ) {
      let splitValue = value.split(".");
      if (
        splitValue[0].length > 5 ||
        (splitValue[1] && splitValue[1].length > 5)
      )
        return;
      props.formik.setFieldValue(name, value);
    }
  };

  const onFormSelectChange = (value: any, name: any) => {
    if (name.name === "discount_type") {
      props.formik.setFieldValue(name.name, value.value);
      props.formik.setFieldValue("discount_value", 0);
      props.formik.setFieldValue(
        "currency_id",
        currentUserInfo.organization_currency
      );
      props.formik.setFieldValue(
        "currency_code",
        currentUserInfo.currency_code
      );
    }
    if (name.name === "limited_duration_unit") {
      props.formik.setFieldValue(name.name, value.value);
      props.setFormErrors({
        ...props.formErrors,
        limited_duration_value: validationDurationCountMsg(
          Number(props.formik?.values?.limited_duration_value),
          value.value
        ),
      });
    }
  };

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      if (date?.toString() === "Invalid Date" || currentDate > targetDate) {
        props.formik.setFieldValue("valid_till", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          props.formik.setFieldValue("valid_till", dateFormated);
        } else {
          props.formik.setFieldValue("valid_till", "Invalid date");
        }
      }
    } else {
      props.formik.setFieldValue("valid_till", "");
    }
  };

  $(function () {
    setTimeout(() => {
      $(document).on("focus", ".discount_field_pre", function (this: Element) {
        $(this).parent().addClass("focused");
      });
      $(document).on("blur", ".discount_field_pre", function (this: Element) {
        $(this).parent().removeClass("focused");
      });
    }, 50);
  });

  return (
    <Section className="form-top-section">
      <Container className="row custom-row">
        <FormInputField
          type="text"
          name="name"
          id="name"
          className={
            props?.formErrors?.name ? "form-control error" : "form-control"
          }
          label="Coupon Name"
          placeholder="Enter Coupon Name"
          value={props.formik.values.name}
          onChange={(e) => props.formik.setFieldValue("name", e.target.value)}
          error={props?.formErrors?.name ? props.formErrors.name : ""}
          isRequired={true}
        />
        <div className="row mw-325">
          <div className="">
            <div className="col">
              <label htmlFor="account" className="required">
                Account
              </label>
            </div>
            <CustomSearchSelect
              itemAccount={Number(props.formik.values.account_id)}
              itemAccountName={props.formik.values.account_name}
              organizationId={currentUserInfo.organization_id}
              itemIndex={0}
              handleItemAccountChange={(e) => {
                props.formik.setFieldValue("account_id", Number(e));
                props.formik.setFieldValue(
                  "account_name",
                  accountsGlCode.account_list.filter(
                    (data: any) => data?.id === e
                  )?.[0]?.account_name || ""
                );
              }}
              error={props.formErrors?.account_id ? "error" : ""}
              baseAccount="Expenses"
            />
            <span className="error">
              {props.formErrors?.account_id ? props.formErrors.account_id : ""}
            </span>
          </div>
        </div>
      </Container>
      <Container className="row custom-row">
        <Container className="col coupon-discount">
          <div>
            <Label htmlFor="check_expiry" className="required">
              Discount
            </Label>
            <InfoTooltip title={message().coupon.discountType} />
          </div>
          <div className="form-input-icon-wrapper discount_count_wrapper mw-325">
            <div
              className="discount_count-div"
              style={{
                flex:
                  props.formik.values.discount_type === "PERCENTAGE" ? 1 : 2,
              }}
            >
              <div className={`d-flex discount_type_inputprefix`}>
                {props.formik.values.discount_type === "ABSOLUTE" ? (
                  <span className="prefix_span_org">
                    {props.formik.values.currency_code}
                  </span>
                ) : null}
                <input
                  type="text"
                  name="discount_value"
                  id="discount_count"
                  className={`discount_field_pre`}
                  placeholder=""
                  onChange={onFormInputChange}
                  value={
                    props.formik.values.discount_value === 0
                      ? ""
                      : props.formik.values.discount_value
                  }
                  disabled={props.formik.values.is_redeemed ? true : false}
                />
                {props.formik.values.discount_type === "PERCENTAGE" ? (
                  <span className="prefix_span_per">%</span>
                ) : null}
              </div>

              {props.formErrors?.discount_value && (
                <span className="trial-period-error error">
                  {props.formErrors?.discount_value}
                </span>
              )}
            </div>
            <Select
              name="discount_type"
              id="discount_type"
              options={discountTypeDropdown}
              className={`discount-period-count trial-period-type-radius state-select form-select custom-select `}
              onChange={onFormSelectChange}
              isSearchable={false}
              placeholder=""
              styles={SelectWithLeftInputCustomStyle}
              value={
                discountTypeDropdown.filter(
                  (data) => data.value === props.formik.values.discount_type
                )[0]
              }
              isDisabled={props.formik.values.is_redeemed ? true : false}
            />
          </div>
        </Container>
        {props.formik.values.discount_type !== "PERCENTAGE" && (
          <Container className="col mw-325">
            <CurrencySelect
              label="Currency"
              currencyId={Number(props.formik.values.currency_id)}
              onChange={async (data) => {
                props.formik.setFieldValue("currency_id", data.value);
                props.formik.setFieldValue("currency_code", data.code || "");
              }}
              isDisabled={props.formik.values.is_redeemed}
              error={
                (props.formik.errors.currency_id &&
                  (props.formik.touched.currency_id ||
                    props.formik.submitCount > 0) &&
                  String(props.formik.errors.currency_id)) ||
                ""
              }
            />
          </Container>
        )}
      </Container>
      <Container className="row custom-row pleft-16">
        <Label htmlFor="check_expiry" className="duration-label">
          Duration
          <InfoTooltip title={message().coupon.duration} />
        </Label>
        <RadioButtonGroup
          name="duration_type"
          id="duration_type"
          valuesArray={DurationTypeDropdown}
          value={props.formik.values.duration_type}
          icon={
            <span>
              <RadioButtonUncheckedIcon />
            </span>
          }
          checkedIcon={
            <span>
              <RadioButtonCheckedIcon className="radio-btn-tds" />
            </span>
          }
          isDisabled={props.formik.values.is_redeemed ? true : false}
          formik={props.formik}
          onClick={(e) => props.formik.setFieldValue("duration_type", e)}
        />
        {props.formik.values.duration_type === "LIMITED_PERIOD" ? (
          <div className="form-input-icon-wrapper duration_count_wrapper">
            <div className="duration-count-div">
              <input
                type="text"
                name="limited_duration_value"
                id="duration_count"
                className={`duration-period-count trial-period-count-radius ${
                  props.formErrors?.limited_duration_value ? "error" : ""
                }`}
                placeholder=""
                onChange={onFormInputChange}
                value={
                  props.formik.values.limited_duration_value === 0
                    ? ""
                    : props.formik.values.limited_duration_value
                }
                maxLength={5}
                disabled={props.formik.values.is_redeemed ? true : false}
              />
              {props.formErrors?.limited_duration_value && (
                <span className="trial-period-error error">
                  {props.formErrors.limited_duration_value}
                </span>
              )}
            </div>
            <Select
              name="limited_duration_unit"
              id="limited_duration_unit"
              options={durationUnitDropdown}
              className={`duration-unit-count trial-period-type-radius state-select form-select custom-select`}
              onChange={onFormSelectChange}
              isSearchable={false}
              placeholder=""
              styles={customSelectStyle}
              value={
                durationUnitDropdown.filter(
                  (data) =>
                    data.value === props.formik.values.limited_duration_unit
                )[0]
              }
              isDisabled={props.formik.values.is_redeemed ? true : false}
            />
          </div>
        ) : null}
      </Container>
      <Container className="row custom-row">
        <div className={"col-12 col-lg-4 mw-325 "}>
          <Label htmlFor="check_expiry" className="">
            Valid Till
          </Label>
          <InfoTooltip title={message().coupon.validTill} />
          <div id="date" className="date-selector-wrapper">
            <CustomDatepicker
              date={
                !props.formik.values?.valid_till
                  ? null
                  : new Date(props.formik.values.valid_till)
              }
              handleDate={async (date, type) => handleDate(date, type)}
              type="valid_till"
              error={props.formErrors?.valid_till ? "error" : ""}
              id="date"
              zIndex={0}
              placeholder="Enter Valid Till"
              minDate={new Date()}
              enableFutureDate={true}
            />
          </div>
          <span className="error error_date mb-2">
            {props.formErrors?.valid_till ? props.formErrors?.valid_till : ""}
          </span>
        </div>
        <FormInputField
          type="text"
          name="maximum_redemptions"
          id="maximum_redemptions"
          className={
            props?.formErrors?.maximum_redemptions
              ? "form-control error"
              : "form-control"
          }
          label="Maximum Redemptions"
          placeholder="Enter Maximum Redemptions"
          maxLength={9}
          value={props.formik.values.maximum_redemptions}
          onChange={onFormInputChange}
          tooltipMessage={message().coupon.maximumRedumption}
          messagePlacement={"top-end"}
          error={
            props?.formErrors?.maximum_redemptions
              ? props.formErrors.maximum_redemptions
              : ""
          }
        />
      </Container>
      <div className="row mb-0">
        <div className="col">
          <div
            className="form-button-wrapper w-100 docked-button-container"
            id="form-btn"
          >
            <button
              onClick={(e: any) => {
                e.preventDefault();
                props.formik.submitForm();
              }}
              className="save-button"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-decoration-none cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default CouponDetails;
