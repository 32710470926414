import React, { InputHTMLAttributes, ReactComponentElement, ReactElement, ReactHTMLElement, useId } from "react";
import "../../../../../../ColorVariables.css";
import "./Input.css";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  errorClass?: string;
  dataBsToggle?: string;
  startAdornment?: ReactElement;
  endAdornment?: ReactElement;
}

const Input = (props: Props) => {
  const id = useId();
  return (
    <div className="reusable-input-container">
      {props.startAdornment && (
        <div className="input-start-adornment">{props.startAdornment}</div>
      )}
      <input
        type={props.type ? props.type : "text"}
        name={props.name}
        id={props.id ? props.id : id}
        className={`input-field-item ${props.className} ${
          props.error ? "error" : ""
        } ${props.startAdornment ? "start-adornment-present" : ""} ${
          props.endAdornment ? "end-adornment-present" : ""
        }`}
        data-bs-toggle={props.dataBsToggle}
        autoComplete={props.autoComplete}
        value={props.value}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        minLength={props.minLength}
        disabled={props.disabled ? props.disabled : false}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onBlur}
        onInput={props.onInput}
        onKeyDown={props.onKeyDown}
        
      />
      {props.endAdornment && (
        <div className="input-end-adornment">{props.endAdornment}</div>
      )}

      {props.error ? (
        <span className={`error ${props.errorClass ? props.errorClass : ""}`}>
          {props.error}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
