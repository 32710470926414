import { setLoaderState } from "../../../../common/commonSlice";
import { useFormik } from "formik";
import { budgetSummaryReportFilterSchema } from "../utils/filterValidation";
import { useCommonData } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import { getBudgetSummaryReport } from "../../ReportSlice";
import {
  generateAccountsData,
  mergeAccountsData,
} from "../../../budget/helper/mergeAndProcess";
import { extractAccountNames } from "../../../budget/helper/createBudgetPayload";

export const useBudgetSummaryReport = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const {
    handleChange,
    handleReset,
    handleSubmit,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
  } = useFormik({
    initialValues: {
      budget_id: null,
      budget_name: "",
      period: null,
      no_of_periods: null,
      organization_id: currentUserInfo.organization_id,
      currency_id: currentUserInfo.organization_currency,
      currency_code: currentUserInfo.currency_code,
      currency_ids: [],
      start_date: null,
      range: "Today",
      data: {},
      budgetData: {},
      profit_and_loss: {},
      accountNames: [],
      editableDateRanges: [],
      tableConfig: {},
      organizationDetails: {},
    },
    validationSchema: budgetSummaryReportFilterSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const {
        budget_name,
        currency_code,
        currency_ids,
        range,
        data,
        budgetData,
        profit_and_loss,
        accountNames,
        editableDateRanges,
        organizationDetails,
        ...rest
      } = values;
      const responseAction = await dispatch(
        getBudgetSummaryReport({
          filterValues: { ...rest, budget_id: Number(rest.budget_id) },
          orgId: currentUserInfo.organization_id,
        })
      );
      const response = responseAction.payload;
      const generatedData = generateAccountsData(
        response,
        response.start_date,
        0
      );
      if (response && Object.keys(response).length && !("error" in response)) {
        // setFieldValue("data", response);
        // setFieldValue("budgetData", mergeAccountsData(generatedData, response));
        // setFieldValue("profit_and_loss", response.profit_and_loss);
        // const names = extractAccountNames(response.accounts);
        // setFieldValue("accountNames", names);
        // setFieldValue(
        //   "editableDateRanges",
        //   Object.keys(response.profit_and_loss)
        // );
        // setFieldValue("tableConfig", {
        //   actualColumns: 0,
        //   periodColumns: rest.no_of_periods,
        //   totalColumns: rest.no_of_periods,
        //   periodStartDate: rest.start_date,
        // });
        setValues({
          ...values,
          data: response,
          budgetData: mergeAccountsData(generatedData, response),
          profit_and_loss: response.profit_and_loss,
          accountNames: extractAccountNames(response.accounts) as any,
          editableDateRanges: Object.keys(response.profit_and_loss) as any,
          tableConfig: {
            actualColumns: 0,
            periodColumns: rest.no_of_periods,
            totalColumns: rest.no_of_periods,
            periodStartDate: rest.start_date,
          } as any,
          organizationDetails: response.organization_details,
        });
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });

  return {
    handleChange,
    handleReset,
    handleSubmit,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
  };
};
