import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../constants/colors";
import { CreateInvoiceIcon } from "./CreateInvoiceIcon";

type Props = {
  onClick: () => void;
  className: string;
  icon: boolean;
  name: string;
  assignedUserCount?: number;
  iconColor?: string;
  childInvoiceListData?: any;
  child_invoice_exists?: string | boolean;
  isChildInvoice?: boolean;
  modalType?: string;
};
export default function CreateInvoice(props: Props) {
  return (
    <Link
      className={props.className}
      style={{ textDecoration: "none" }}
      to="#"
      onClick={props.onClick}
    >
      <span className="d-flex align-items-center">
        {" "}
        {props.icon ? (
          <CreateInvoiceIcon
            color={props.iconColor ? props.iconColor : colors.ceruleanBlue}
            margin="0px 5px 0px 0px"
          />
        ) : null}
        Create Invoice
      </span>
    </Link>
  );
}
