import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { CreateChargeDefaultValues } from "./types";

/**
 * API call to get charges list
 */
export async function getChargesList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  orgIds: number[];
  statusList: string[];
}) {
  let url =
    Api.charges +
    "list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

/**
 * API call for deleting charges
 */
export async function deleteChargesAPI(data: {
  deleteChargesId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.charges +
      "delete" +
      `/${data.deleteChargesId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to cancel charges
 */
export async function updateChargesStatus(data: {
  id: number;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.charges +
      "change-status" +
      `/${data.id}?current_organization=${data.orgId}`,
    { status: data.status }
  );
  return result;
}

/**
 * Api to get the transaction limit status
 */
export async function chargesTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.charges + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to check if the charges name exist
 */
export async function checkPriceInternalName(data: {
  internalName: string;
  orgId: number;
  charge_id?: string | number;
}) {
  const encodedName = encodeURIComponent(data.internalName);
  let url =
    Api.charges +
    `internal-name-exist?current_organization=${data.orgId}&internal_name=${encodedName}`;
  if (data.charge_id) {
    url += `&charge_id=${data.charge_id}`;
  }
  const result = await get(url);
  return result;
}

/**
 * API call to create charges
 */
export async function createNewCharges(data: {
  values: CreateChargeDefaultValues;
  orgId: number;
}) {
  const result = await post(
    Api.charges + `create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to edit charges
 */
export async function editChargesAPI(data: {
  values: CreateChargeDefaultValues;
  orgId: number;
  charge_id?: string | number;
}) {
  const result = await put(
    Api.charges +
      "edit" +
      `/${data.charge_id}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 *  API call for getting charges details
 */
export async function getChargesOverviewDetails(data: {
  charge_id: number;
  orgId: number;
}) {
  const result = await get(
    Api.charges + `details/${data.charge_id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 *  API call for getting charges edit details
 */
export async function getChargesEditDetails(data: {
  charge_id: number;
  orgId: number;
}) {
  const result = await get(
    Api.charges +
      `edit-details/${data.charge_id}?current_organization=${data.orgId}`
  );
  return result;
}
