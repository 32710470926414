import React, { useState, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import DateRangePicker from "../../../../componets/DateRangePicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { calendarIcon } from "../../../../../../../assets/images";
import { colors } from "../../../../../../constants/colors";
import { DownArrowIcon } from "../../../../../../common/components/customSvgIcons/downArrowIcon";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import {
  getFiscalQuarterYear,
  getFiscalYear,
} from "../../../../../../../helpers/fiscalYearHelper";
import useCommonData from "../../../../../../hooks/useCommon";
import "./VarianceReportDateRangePicker.css";

dayjs.extend(quarterOfYear);

type DateRangePickerProps = {
  dateObject: { startDate: string; endDate: string; range: string };
  handleDateRange: (start: string, end: string, range: string) => void;
  error?: string;
};

export const VarianceReportDateRangePicker: React.FC<DateRangePickerProps> = ({
  dateObject,
  handleDateRange,
  error,
}) => {

  const { currentUserInfo } = useCommonData();
  const RANGE_OPTIONS = {
    Today: [dayjs(), dayjs()],
    "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
    "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
    "This Quarter": [
      dayjs(
        getFiscalQuarterYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).startDate
      ),
      dayjs(
        getFiscalQuarterYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).endDate
      ),
    ],
    "This Year": [
      dayjs(
        getFiscalYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).startDate
      ),
      dayjs(
        getFiscalYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).endDate
      ),
    ],
    "Year To Date": [
      dayjs(
        getFiscalYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).startDate
      ),
      dayjs(),
    ],
    Yesterday: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    "Last Week": [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
    "Last Month": [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
    "Last Quarter": [
      dayjs(
        getFiscalQuarterYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Previous"
        ).startDate
      ),
      dayjs(
        getFiscalQuarterYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Previous"
        ).endDate
      ),
    ],
    "Last Year": [
      dayjs(
        getFiscalYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "previous"
        ).startDate
      ),
      dayjs(
        getFiscalYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "previous"
        ).endDate
      ),
    ],
  };

  const [selectedRange, setSelectedRange] = useState(dateObject.range);
  const [startDate, setStartDate] = useState(dayjs(dateObject.startDate));
  const [endDate, setEndDate] = useState(dayjs(dateObject.endDate));
  const [key, setKey] = useState(0); // Add key state to force re-render

  const handleCallback = useCallback(
    (start: dayjs.Dayjs, end: dayjs.Dayjs, label: string | undefined) => {
      const rangeLabel = label === "Custom Range" ? "Custom" : String(label);
      setSelectedRange(rangeLabel);
      setStartDate(start);
      setEndDate(end);
      handleDateRange(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD"),
        rangeLabel
      );
    },
    [handleDateRange]
  );

  const getSelectedLabel = () =>
    `${selectedRange} (${startDate.format("DD-MM-YYYY")} to ${endDate.format(
      "DD-MM-YYYY"
    )})`;

  useMemo(() => {
    setStartDate(dayjs(dateObject.startDate));
    setEndDate(dayjs(dateObject.endDate));
    setSelectedRange(dateObject.range);
    setKey(prev => prev + 1); // Increment key to force re-render
  }, [JSON.stringify(dateObject), currentUserInfo.organization_id]);

  const renderDateRangePicker = () => (
    <DateRangePicker
      key={key} // Add key prop to force re-render
      initialSettings={{
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        singleDatePicker: false,
        ranges: Object.fromEntries(
          Object.entries(RANGE_OPTIONS).map(([key, [start, end]]) => [
            key,
            [start.toDate(), end.toDate()],
          ])
        ),
        showDropdowns: true,
      }}
      onCallback={handleCallback}
    >
      <div
        id="reportrange"
        className={`col-4 reportrangeclass ${error ? "error-field" : ""}`}
        style={{
          background: colors.white,
          cursor: "pointer",
          padding: "5px 10px",
          border: `1px solid ${colors.veryLightGrey}`,
          width: "100%",
        }}
      >
        <img width={20} height={20} src={calendarIcon} alt="calendar icon" />
        <span>{getSelectedLabel()}</span>
        <DownArrowIcon color={colors.slate} />
      </div>
    </DateRangePicker>
  );

  return (
    <div
      className="variace-report-daterange-wrapper"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          document.getElementById("reportrange")?.click();
        } else if (e.key === "Tab") {
          return;
        }
      }}
    >
      {renderDateRangePicker()}
      {error && <span className="error">{error}</span>}
    </div>
  );
};
