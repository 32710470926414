import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import CustomSearchSelect from "../../../../common/components/CustomSearchSelect/CustomSearchSelect";
import { AddTagIcon } from "../../../../../assets/images";
import Itemtax from "../../../../common/components/ItemTax";
import {
  InventoryItemInformationValues,
  InventoryItemValues,
  ItemTaxValues,
  ObjectType,
  TagSelector,
} from "../../../../../types";
import {
  createItem,
  exemptionDropdown,
  exemptionDropdownSelector,
  exemptionLabel,
  removeItemTax,
  updateItem,
  itemNameExistsCheck,
  getItemDetail,
} from "../../itemSlice";
import { toast } from "react-toastify";
import InputPrefix from "../../../../common/components/formPrefixInputField/InputPrefix";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import { zeroDisplayFormat } from "../../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useAppSelector } from "../../../../../app/hooks";
import {
  FormInputField,
  FormTextAreaField,
} from "../../../../common/components";
import { useParams } from "react-router-dom";
import { Form } from "../../../../common/components/htmlTags/Form";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import { Label } from "../../../../common/components/htmlTags/Label";
import CheckBox from "../../../../common/components/checkBox/CheckBox";
import { CreateItemValidation } from "./CreateItemFormValidation";
import Search from "../../../../../assets/images/search.svg";
import { InfoIconImg } from "../../../../../assets/images";
import CustomTooltips from "../../../../common/components/Tooltip";
// import { useMediaQuery } from "@react-hook/media-query";
import "../../../invoice/components/invoiceForm/invoiceForm.css";
import "./CreateItem.css";
import { setListFilters } from "../../../../../appSlice";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { stateTaxRates } from "../../../gst/gstSlice";
import { EmailInfoIcon } from "../../../../../assets/images";
import CurrencySelect from "../../../../common/components/CurrencySelect";
import { Container } from "../../../../common/components/htmlTags/Container";
import { InfoMessage } from "../../../../common/components/infos/InfoMessage";
import { setLoaderState } from "../../../../common/commonSlice";

function CreateItem(props: any) {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const { isGstOrg } = useGst();
  const initialTaxValues = {
    tax_id: "",
    tax_percentage: "",
  };
  const initialItemInformationValues: InventoryItemInformationValues = {
    rate: "",
    account_id: "",
    account_name: "",
    description: "",
    is_sales: false,
    is_purchase: false,
  };
  const initialValues: InventoryItemValues = {
    id: null,
    name: "",
    hsn_or_sac_code: "",
    account_id: "",
    account_name: "",
    rate: "",
    isGstOrg: isGstOrg,
    currency_id: editId ? null : currentUserInfo.organization_currency,
    currency_code: editId ? "" : currentUserInfo.currency_code,
    tax_list: [
      {
        tax_id: "",
        tax_percentage: "",
      },
    ],
    tax_preference: undefined,
    exemption_reason_id: undefined,
    type: "GOODS",
    item_information: [
      {
        ...initialItemInformationValues,
        is_sales: true,
      },
      {
        ...initialItemInformationValues,
        is_purchase: true,
      },
    ],
    intra_state_id: null,
    inter_state_id: null,
  };
  const [taxFormValues, setTaxFormValues] = useState<ItemTaxValues[]>([
    initialTaxValues,
  ]);
  const [itemFormErrors, setItemFormErrors] = useState<ObjectType[]>([]);
  const [deleteTaxItems, setDeleteTaxItems] = useState<number[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [actionType, setActionType] = useState("create");
  const [taxPreference, setTaxpPreference] = useState<TagSelector>();
  const [exemptionReason, setExemptionReason] = useState<TagSelector>();
  const exemptionList = useAppSelector(exemptionDropdownSelector);
  const [nameExistsError, setNameExistsError] = useState<string>("");
  const taxPreferenceList = [
    { label: "Taxable", value: "TAXABLE" },
    { label: "Non taxable", value: "NON_TAXABLE" },
    { label: "Out of scope", value: "OUT_OF_SCOPE" },
    { label: "Non-gst supply", value: "NON_GST_SUPPLY" },
  ];
  const itemTypeArray = [
    {
      label: "Goods",
      value: "GOODS",
    },
    {
      label: "Service",
      value: "SERVICE",
    },
  ];
  const [itemInfoError, setItemInfoError] = useState<any[]>();
  const [intraStateTaxRates, setIntraStateTaxRates] = useState<ObjectType[]>(
    []
  );
  const [interStateTaxRates, setInterStateTaxRates] = useState<ObjectType[]>(
    []
  );
  const interStateRef = useRef([]);
  const intraStateRef = useRef([]);

  // const landscapeDevice = useMediaQuery("(min-width: 960px)");
  // const portraitDevices = useMediaQuery("(max-width: 959px)");

  useEffect(() => {
    dispatch(exemptionDropdown({ orgId: currentUserInfo.organization_id }));
  }, []);
  useEffect(() => {
    fetchDefaultTaxDropdown("Intra State");
    fetchDefaultTaxDropdown("Inter State");
    if (editId) {
      handleEditDataFetch();
    }
  }, [editId]);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: CreateItemValidation,

    onSubmit: (values) => {
      if (!nameExistsError) {
        submitItem(values);
      }
    },
  });
  useEffect(() => {
    if (formik.errors.item_information) {
      const errors = JSON.parse(JSON.stringify(formik.errors));
      setItemInfoError([...errors.item_information]);
    } else {
      setItemInfoError([]);
    }
  }, [JSON.stringify(formik.errors)]);
  useEffect(() => {
    let itemTax = JSON.parse(JSON.stringify(taxFormValues));
    let taxData: ItemTaxValues[] = [];
    itemTax.map((item: any) => {
      if (item.tax_percentage === "") {
        item.tax_percentage = 0;
      }
      if (item.tax_id !== "" || item.tax_percentage !== 0) {
        taxData.push(item);
      }
    });
    formik.setValues({
      ...formik.values,
      tax_list: taxData,
    });
  }, [taxFormValues]);
  useEffect(() => {
    if (formik.values?.tax_preference !== "TAXABLE" || !isGstOrg) {
      formik.setValues({
        ...formik.values,
        inter_state_id: null,
        intra_state_id: null,
      });
    }
  }, [formik.values?.inter_state_id, formik.values?.intra_state_id]);
  const fetchDefaultTaxDropdown = async (stateType: string) => {
    let response = await dispatch(
      stateTaxRates({
        orgId: currentUserInfo.organization_id,
        stateType: stateType,
      })
    );
    let options: any[] = [];
    response?.payload.forEach((element: ObjectType) => {
      options.push({
        label: `${element.name} [${element.rate}%]`,
        value: element.id,
      });
    });

    if (stateType == "Intra State") {
      intraStateRef.current = response?.payload;
      setIntraStateTaxRates([...options]);
    } else if (stateType == "Inter State") {
      interStateRef.current = response?.payload;
      setInterStateTaxRates([...options]);
    }
  };
  const handleEditDataFetch = async () => {
    dispatch(setLoaderState(true));
    if (!props.isModal) {
      const { payload } = await dispatch(
        getItemDetail({
          item_id: Number(editId),
          orgId: currentUserInfo.organization_id,
        })
      );
      const itemData = payload;
      if (itemData && Object.keys(itemData).length && !props.isModal) {
        setActionType("edit");
        if (itemData.taxes) {
          itemData.tax_list = itemData.taxes;
          if (Object.keys(itemData.tax_list).length === 0) {
            itemData.tax_list.push(initialTaxValues);
          }
          delete itemData.taxes;
        }
        const sales_information = itemData.item_information.find(
          (obj: any) => obj.is_sales === true
        );
        const purchase_information = itemData.item_information.find(
          (obj: any) => obj.is_purchase === true
        );
        const intra_state_id_obj = intraStateRef.current?.filter(
          (taxRate: ObjectType) => {
            return taxRate.id === itemData.intra_state_id;
          }
        )[0] as ObjectType;
        const inter_state_id_obj = interStateRef.current?.filter(
          (taxRate: ObjectType) => {
            return taxRate.id === itemData.inter_state_id;
          }
        )[0] as ObjectType;
        formik.setValues({
          ...itemData,
          type: itemData?.type ? itemData?.type : "GOODS",
          isGstOrg: isGstOrg,
          item_information: [
            sales_information
              ? sales_information
              : initialItemInformationValues,
            purchase_information
              ? purchase_information
              : initialItemInformationValues,
          ],
          inter_state_id: inter_state_id_obj
            ? {
                label: `${inter_state_id_obj?.name} [${inter_state_id_obj?.rate}%]`,
                value: inter_state_id_obj?.id,
              }
            : null,
          intra_state_id: intra_state_id_obj
            ? {
                label: `${intra_state_id_obj?.name} [${intra_state_id_obj?.rate}%]`,
                value: intra_state_id_obj?.id,
              }
            : null,
          is_intra_state_group: intra_state_id_obj
            ? intra_state_id_obj?.tax_group
            : undefined,
          is_inter_state_group: inter_state_id_obj
            ? inter_state_id_obj?.tax_group
            : undefined,
        });
        if (itemData.tax_preference) {
          const exemptionTypeLabel = taxPreferenceList.find(
            (obj: any) => obj.value === itemData.tax_preference
          );
          setTaxpPreference({
            label: exemptionTypeLabel ? exemptionTypeLabel.label : "",
            value: itemData.tax_preference,
          });
        }
        if (itemData.exemption_reason_id) {
          getExemptionReasonDetails(itemData.exemption_reason_id);
        }
        if (itemData.tax_list) setTaxFormValues([...itemData.tax_list]);
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };
  const submitItem = async (itemData: InventoryItemValues) => {
    dispatch(setListFilters({}));
    setIsDisabled(true);
    let itemTax = JSON.parse(JSON.stringify(taxFormValues));
    let taxData: ItemTaxValues[] = [];
    itemTax.map((item: any) => {
      if (item.tax_percentage === "") {
        item.tax_percentage = 0;
      }
      if (item.tax_id !== "" || item.tax_percentage !== 0) {
        taxData.push(item);
      }
    });
    itemData.tax_list = taxData;
    itemData.hsn_or_sac_code =
      itemData.hsn_or_sac_code !== "" ? itemData.hsn_or_sac_code : null;
    const sales_information = itemData.item_information[0];
    const purchase_information = itemData.item_information[1];
    if (itemData.item_information[0].is_sales === false) {
      itemData.item_information = [purchase_information];
    } else if (itemData.item_information[1].is_purchase === false) {
      itemData.item_information = [sales_information];
    }
    const intra_state_id_value =
      typeof formik.values.intra_state_id === "object"
        ? formik.values.intra_state_id?.value
        : 0;

    const inter_state_id_value =
      typeof formik.values.inter_state_id === "object"
        ? formik.values.inter_state_id?.value
        : 0;

    if (inter_state_id_value) {
      const inter_state_id_obj = interStateRef.current?.filter(
        (taxRate: ObjectType) => {
          return taxRate.id === inter_state_id_value;
        }
      )[0] as ObjectType;

      itemData.inter_state_id = inter_state_id_obj?.id;
      itemData.is_inter_state_group = inter_state_id_obj?.tax_group;
    }
    if (intra_state_id_value) {
      const intra_state_id_obj = intraStateRef.current?.filter(
        (taxRate: ObjectType) => {
          return taxRate.id === intra_state_id_value;
        }
      )[0] as ObjectType;

      itemData.intra_state_id = intra_state_id_obj?.id;
      itemData.is_intra_state_group = intra_state_id_obj?.tax_group;
    }
    let responseAction: ObjectType = {};
    if (actionType === "edit") {
      if (deleteTaxItems) {
        for (let item of deleteTaxItems) {
          await dispatch(
            removeItemTax({
              itemId: Number(itemData.id),
              taxId: item,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
      responseAction = await dispatch(
        updateItem({
          id: Number(itemData.id),
          values: itemData,
          orgId: currentUserInfo.organization_id,
        })
      );
    } else {
      responseAction = await dispatch(
        createItem({
          values: itemData,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    if (responseAction.payload) {
      setTimeout(function () {
        // setIsLoading(false);
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        // if (fetchItemDropdown) fetchItemDropdown();
        handleClose(Number(response.item_id));
        toast.success(
          `Item ${
            actionType === "create" ? "created" : "updated"
          } successfully!`,
          {
            toastId: "item-success",
            closeButton: false,
            position: "top-center",
          }
        );
        setIsDisabled(false);
      } else {
        setIsDisabled(false);
      }
    }
  };

  const handleItemSalesAccountChange = (accountId: number, index: number) => {
    let sales_information = { ...formik.values.item_information[0] };
    let purchase_information = { ...formik.values.item_information[1] };
    if (accountId) {
      sales_information.account_id = accountId;
      formik.setValues({
        ...formik.values,
        item_information: [sales_information, purchase_information],
      });
    }
  };
  const handleItemPurchaseAccountChange = (
    accountId: number,
    index: number
  ) => {
    let sales_information = { ...formik.values.item_information[0] };
    let purchase_information = { ...formik.values.item_information[1] };
    if (accountId) {
      purchase_information.account_id = accountId;
      formik.setValues({
        ...formik.values,
        item_information: [sales_information, purchase_information],
      });
    }
  };
  /**
   * Append item tax container
   */
  const addItemTaxContainer = () => {
    const taxObject = initialTaxValues;
    const taxes = JSON.parse(JSON.stringify(taxFormValues));
    taxes.push(taxObject);
    setTaxFormValues(taxes);
    formik.setValues({
      ...formik.values,
      tax_list: [...formik.values.tax_list, taxObject],
    });
  };
  const handleTaxOnBlur = (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number
  ) => {
    const taxes = JSON.parse(JSON.stringify(taxFormValues));
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      if (name === "tax_percentage" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        taxes[itemIndex] = { ...taxes[itemIndex], [name]: roundedAmount };
      }
    }
    setTaxFormValues(taxes);
  };

  const handleItemTaxChange = (e: any, taxIndex: number) => {
    const { name, value } = e.target;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "tax_percentage" && value !== "") {
      let decimalPointSplitArray: string[] = [];
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (!numberRegex.test(value) || decimalPointSplitArray[0].length > 14) {
        return;
      }
    }
    const taxes = JSON.parse(JSON.stringify(taxFormValues));
    let taxExist = [];
    if (value) {
      taxExist = taxes.filter(
        (tax: any, index: number) => tax.tax_id === value && index !== taxIndex
      );
      if (taxExist.length) {
        if (name === "tax_id") {
          if (typeof itemFormErrors[taxIndex] === "undefined") {
            itemFormErrors[taxIndex] = [];
          }
          itemFormErrors[taxIndex] = {
            tax_id: "",
            tax_percentage: 0,
          };
          itemFormErrors[taxIndex].tax_id = "Tax label already used";
          setItemFormErrors([...itemFormErrors]);
        }
      } else {
        if (typeof itemFormErrors[taxIndex] !== "undefined") {
          if (name === "tax_id") {
            if (!itemFormErrors[taxIndex]) {
              itemFormErrors[taxIndex] = [];
            }
            itemFormErrors[taxIndex] = {
              tax_id: "",
              tax_percentage: 0,
            };
            setItemFormErrors([...itemFormErrors]);
          }
        }
      }
    }
    taxes[taxIndex] = { ...taxes[taxIndex], [name]: value };
    setTaxFormValues(taxes);
  };
  /**
   * Append item tax container
   */
  const deleteItemTaxContainer = (taxIndex: number, id: number | undefined) => {
    taxFormValues.map((tax, index) => {
      if (id && tax.id === id) {
        setDeleteTaxItems((prevItems) => [...prevItems, Number(tax.id)]);
      }
    });
    const taxItems = JSON.parse(JSON.stringify(taxFormValues));
    taxItems.splice(taxIndex, 1);
    setTaxFormValues(taxItems);
    const taxErrors = [...itemFormErrors];
    taxErrors.splice(taxIndex, 1);
    setItemFormErrors(taxErrors);
  };

  const handleAmountOnBlur = (name: string, amount: number, index: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      const sales_information = formik.values.item_information[0];
      const purchase_information = formik.values.item_information[1];
      if (name === "sales_rate" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        sales_information.rate = roundedAmount;
        formik.setValues({
          ...formik.values,
          item_information: [sales_information, purchase_information],
        });
      }
      if (name === "purchase_rate" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        purchase_information.rate = roundedAmount;
        formik.setValues({
          ...formik.values,
          item_information: [sales_information, purchase_information],
        });
      }
    }
  };

  const handleClose = (id?: number) => {
    formik.setValues(initialValues);
    setTaxFormValues([initialTaxValues]);
    setItemFormErrors([]);
    formik.setErrors({});
    setActionType("create");
    setDeleteTaxItems([]);
    setTaxpPreference({ label: "", value: "" });
    setExemptionReason({ label: "", value: "" });
    setItemInfoError([]);
    if (props.handleModalClose) {
      props.handleModalClose();
      props.fetchItemDropdown();
    } else if (id) {
      navigate(`/items/detail/${id}`, {
        replace: true,
      });
    } else {
      navigate("/items/");
    }
  };

  const validateAmount = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    const inputElement = event.target as any;
    if (inputElement.value === "" || numberRegex.test(inputElement.value))
      return true;
    else return false;
  };

  const getExemptionReasonDetails = async (id: number) => {
    const result = await dispatch(
      exemptionLabel({
        exemption_id: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    setExemptionReason({
      label: result.payload[0].exemption_reason,
      value: result.payload[0].id,
    });
  };
  const handleTaxPreferenceChange = (e: any) => {
    if (e.value) {
      formik.setValues({
        ...formik.values,
        tax_list: [],
        tax_preference: e.value,
        exemption_reason_id: null,
      });
      setTaxFormValues([initialTaxValues]);
      setExemptionReason({
        label: "",
        value: "",
      });
      setTaxpPreference({
        label: e.label,
        value: e.value,
      });
    }
  };
  const handleExpemtionReasonChange = (e: any) => {
    if (e.label) {
      formik.setValues({
        ...formik.values,
        exemption_reason_id: parseInt(e.value),
      });
      setExemptionReason({
        label: e.label,
        value: e.value,
      });
    }
  };
  const handleInformationSelect = (e: any) => {
    let sales_information = { ...formik.values.item_information[0] };
    let purchase_information = { ...formik.values.item_information[1] };
    if (e.name === "sales_information") {
      sales_information.is_sales = e.value;
    } else if (e.name === "purchase_information") {
      purchase_information.is_purchase = e.value;
    }
    formik.setValues({
      ...formik.values,
      item_information: [sales_information, purchase_information],
    });
  };
  const handleRateChange = (e: any) => {
    let sales_information = { ...formik.values.item_information[0] };
    let purchase_information = { ...formik.values.item_information[1] };
    if (e.target.id === "sales_rate") {
      sales_information.rate = e.target.value;
    } else if (e.target.id === "purchase_rate") {
      purchase_information.rate = e.target.value;
    }
    formik.setValues({
      ...formik.values,
      item_information: [sales_information, purchase_information],
    });
  };
  const handleDescriptionChange = (e: any) => {
    let sales_information = { ...formik.values.item_information[0] };
    let purchase_information = { ...formik.values.item_information[1] };
    if (e.target.id === "sales_description") {
      sales_information.description = e.target.value;
    } else if (e.target.id === "purchase_description") {
      purchase_information.description = e.target.value;
    }
    formik.setValues({
      ...formik.values,
      item_information: [sales_information, purchase_information],
    });
  };
  const handleNameExistsCheck = async (e: any) => {
    const value = e.target.value.trim();
    if (value !== "") {
      const controller = new AbortController();
      const signal = controller.signal;
      const { payload } = await dispatch(
        itemNameExistsCheck({
          item_name: value,
          orgId: currentUserInfo.organization_id,
          signal: signal,
        })
      );
      if (payload.item_name_exist) {
        setNameExistsError("Duplicate item name.");
      } else {
        setNameExistsError("");
      }
    }
  };
  const handleShowMessage = () => {
    const sales_information = formik.values.item_information[0];
    const purchase_information = formik.values.item_information[1];

    if (sales_information?.is_sales && purchase_information?.is_purchase) {
      if (
        parseFloat(`${sales_information?.rate}`) <
        parseFloat(`${purchase_information?.rate}`)
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <Form className="addItem-form" onSubmit={formik.handleSubmit}>
      <div
        className={`addItem-form-body-wrapper-alignment addItem-form-body-wrapper ${
          props.bodyWrapperClass ? props.bodyWrapperClass : ""
        }`}
      >
        <div className="label-input-wrapper">
          <Label className="required" htmlFor="item_type">
            Type
            <CustomTooltips
              title="Select whether this item is a physical good or a service. Note that the item type cannot be changed once included in a transaction."
              placement="bottom"
            >
              <img
                src={InfoIconImg}
                className="addItem-form tooltip-component"
              />
            </CustomTooltips>
          </Label>
          <RadioButtonGroup
            valuesArray={itemTypeArray}
            // formik={formik}
            onChange={() => {}}
            onClick={(e) => {
              if (formik.values?.is_associated) {
                ErrorToaster(
                  "You cannot edit item type as there are transactions linked to the Item."
                );
                return;
              }
              formik.setFieldValue("type", e);
            }}
            value={formik.values.type ? formik.values.type : ""}
            id="type"
            name="type"
            isDisabled={false}
            icon={<RadioButtonUnchecked />}
            checkedIcon={<RadioButtonChecked />}
            radioStyle={formik.errors.type ? "error" : ""}
          />
          <span className="error">{formik.errors.type}</span>
        </div>
        <FormInputField
          id="name"
          name="name"
          label="Item Name"
          type="text"
          placeholder="Enter item name"
          value={formik.values.name}
          className={formik.errors.name ? "form-control error" : "form-control"}
          isRequired
          onChange={(e) => {
            formik.handleChange(e);
            handleNameExistsCheck(e);
          }}
          maxLength={250}
          error={formik.errors.name || nameExistsError}
        />
        {isGstOrg && (
          <div className="hsn-sac-wrapper">
            <FormInputField
              id="hsn_or_sac_code"
              name="hsn_or_sac_code"
              label={`${formik.values.type === "GOODS" ? "HSN" : "SAC"} Code`}
              type="text"
              placeholder={`Enter ${
                formik.values.type === "GOODS" ? "HSN" : "SAC"
              } Code`}
              value={
                formik.values.hsn_or_sac_code
                  ? formik.values.hsn_or_sac_code
                  : ""
              }
              className={
                formik.errors.hsn_or_sac_code
                  ? "form-control error"
                  : "form-control"
              }
              onChange={formik.handleChange}
              wrapperClass="label-input-wrapper"
              maxLength={15}
              isRequired
              error={formik.errors.hsn_or_sac_code}
            />
            {/* <img src={Search} className="search-icon" /> */}
          </div>
        )}
        {isGstOrg && (
          <div className="label-input-wrapper">
            <Label htmlFor="taxPreference" className="required">
              Tax Preference
            </Label>
            <Select
              name="tax_preference"
              id="tax_preference"
              options={
                taxPreferenceList.length > 0
                  ? taxPreferenceList.map((item) => {
                      return {
                        label: item.label,
                        value: item.value,
                      };
                    })
                  : []
              }
              className={`state-select form-select custom-select tax-selection-dropdown ${
                formik.errors.tax_preference ? "error" : ""
              }`}
              value={
                taxPreference &&
                taxPreference.label !== "" &&
                taxPreference.value !== ""
                  ? {
                      label: taxPreference.label,
                      value: taxPreference.value,
                    }
                  : ""
              }
              onChange={(e) => {
                handleTaxPreferenceChange(e);
              }}
              styles={customSelectStyle}
              placeholder={"Choose tax preference"}
              isSearchable={false}
            />
            <span className="error">{formik.errors.tax_preference}</span>
          </div>
        )}
        <Container className="mw-325">
          <CurrencySelect
            label="Currency"
            currencyId={Number(formik.values.currency_id)}
            onChange={async (data) => {
              formik.setFieldValue("currency_id", data.value);
              formik.setFieldValue("currency_code", data.code || "");
            }}
            isDisabled={formik.values.is_associated ? true : false}
            error={
              (formik.errors.currency_id &&
                (formik.touched.currency_id || formik.submitCount > 0) &&
                String(formik.errors.currency_id)) ||
              ""
            }
          />
          {formik.values.is_associated && (
            <InfoMessage message="Currency cannot be edited as the item is associated with transaction(s)." />
          )}
        </Container>

        {formik.values.tax_preference === "NON_TAXABLE" && isGstOrg && (
          <div className="label-input-wrapper">
            <label htmlFor="taxPreference" className="required">
              Exemption reason
            </label>
            <Select
              name="exemption_reason"
              id="exemption_reason"
              options={
                exemptionList.length > 0
                  ? exemptionList.map((item) => {
                      return {
                        label: item.exemption_reason,
                        value: item.id.toString(),
                      };
                    })
                  : []
              }
              className={`state-select form-select custom-select tax-selection-dropdown ${
                formik.errors.exemption_reason_id ? "error" : ""
              }`}
              value={
                exemptionReason &&
                exemptionReason.label !== "" &&
                exemptionReason.value !== ""
                  ? {
                      label: exemptionReason.label,
                      value: exemptionReason.value,
                    }
                  : ""
              }
              onChange={(e) => {
                handleExpemtionReasonChange(e);
              }}
              styles={customSelectStyle}
              placeholder={"Choose tax exemption reason"}
              isSearchable={false}
            />
            <span className="error">{formik.errors.exemption_reason_id}</span>
          </div>
        )}
        {!isGstOrg && (
          <div id="addItem-form" className="tax-percentage-wrapper">
            <label htmlFor="tax">Tax %</label>
            <Itemtax
              itemIndex={0}
              taxes={taxFormValues}
              taxErrors={
                formik.errors.tax_list
                  ? (formik.errors.tax_list as ObjectType[])
                  : []
              }
              organizationId={currentUserInfo.organization_id}
              taxType={"payable"}
              taxLabelOnChange={(e, num, taxIndex) => {
                const data = {
                  target: {
                    name: "tax_id",
                    value: e,
                  },
                };
                handleItemTaxChange(data, taxIndex);
              }}
              taxValueOnChange={(e, num, taxIndex) => {
                handleItemTaxChange(e, taxIndex);
              }}
              deleteItemTax={(taxIndex, id) => {
                deleteItemTaxContainer(taxIndex, id);
              }}
              handleTaxOnBlur={handleTaxOnBlur}
              disabledFeature={currentUserInfo.disabled_feature}
              currencyCode={currentUserInfo.currency_code}
            />
            {/* {IsFeatureEnabled(
                    "Tax Configuration",
                    currentUserInfo.disabled_feature
                    ) && ( */}
            <a
              href="#"
              role="button"
              className="add-tags-button"
              onClick={(e) => e.preventDefault()}
            >
              <div
                className="d-flex align-items-center mx-0"
                onClick={() => addItemTaxContainer()}
              >
                <img className="add-tax-button" src={AddTagIcon} alt="" />
                <span className="px-2">Add tax </span>
              </div>
            </a>
          </div>
        )}
        <div className="item-info-wrapper">
          <div className="rate-account-wrapper">
            <div className="label-input-wrapper">
              <CheckBox
                id="sales_information"
                name="sales_information"
                checked={formik.values.item_information[0]?.is_sales}
                value={formik.values.item_information[0]?.is_sales}
                label="Sales Information"
                onChange={handleInformationSelect}
                wrapperClass="type-checkbox-label"
              />
              {typeof formik.errors.item_information === "string" && (
                <span className="error">{formik.errors.item_information}</span>
              )}
            </div>
          </div>
          <div className="rate-account-wrapper">
            <CheckBox
              id="purchase_information"
              name="purchase_information"
              checked={formik.values.item_information[1]?.is_purchase}
              value={formik.values.item_information[1]?.is_purchase}
              label="Purchase Information"
              onChange={handleInformationSelect}
              wrapperClass="type-checkbox-label"
            />
          </div>
        </div>
        {handleShowMessage() && (
          <div className="alert-msg info-alert-masg d-flex align-items-center w-100 gap-1">
            <img src={EmailInfoIcon} alt="info icon" />
            <p className="mb-0">
              The entered selling price seems to be less than the cost price
            </p>
          </div>
        )}
        <div className="item-info-wrapper">
          <div className="rate-account-wrapper">
            <div className="label-input-wrapper">
              <Label htmlFor="rate" className="required">
                Selling Price
              </Label>
              <InputPrefix
                prefix={
                  formik.values.currency_code || currentUserInfo.currency_code
                }
                isAmountField
                inputType="text"
                name="sales_rate"
                id="sales_rate"
                className="form-control rate"
                placeholder={zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
                value={formik.values.item_information[0]?.rate}
                disabled={
                  formik.values.item_information[0]?.is_sales ? false : true
                }
                error={itemInfoError && itemInfoError[0]?.rate ? "error" : ""}
                onChange={handleRateChange}
                onBlur={handleAmountOnBlur}
              />

              <span className="error">
                {itemInfoError && itemInfoError[0]?.rate}
              </span>
            </div>
            <div className="label-input-wrapper">
              <Label htmlFor="account" className="required">
                Account
              </Label>
              <CustomSearchSelect
                itemAccount={Number(
                  formik.values.item_information[0]?.account_id
                )}
                itemAccountName={
                  formik.values.item_information[0]?.account_name
                }
                organizationId={currentUserInfo.organization_id}
                handleItemAccountChange={handleItemSalesAccountChange}
                itemIndex={0}
                error={
                  itemInfoError && itemInfoError[0]?.account_id ? "error" : ""
                }
                baseAccount={"Incomes~Liabilities"}
                className="account"
                // wrapperClass={`${landscapeDevice ? "drop-up" : "drop-down"}`}
                wrapperClass="drop-up"
                isDisabled={
                  formik.values.item_information[0]?.is_sales ? false : true
                }
              />
              <span className="error">
                {itemInfoError && itemInfoError[0]?.account_id}
              </span>
            </div>
            <div className="label-input-wrapper">
              <FormTextAreaField
                id="sales_description"
                name="sales_description"
                label="Description"
                type="text"
                placeholder=""
                onChange={handleDescriptionChange}
                value={formik.values.item_information[0]?.description}
                className="form-control"
                wrapperClass="label-input-wrapper w-100"
                disabled={
                  formik.values.item_information[0]?.is_sales ? false : true
                }
                maxLength={250}
              />
            </div>
          </div>
          <div className="rate-account-wrapper">
            <div className="label-input-wrapper">
              <Label htmlFor="rate" className="required">
                Cost Price
              </Label>
              <InputPrefix
                prefix={
                  formik.values.currency_code || currentUserInfo.currency_code
                }
                isAmountField
                inputType="text"
                name="purchase_rate"
                id="purchase_rate"
                className="form-control rate"
                placeholder={zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
                value={formik.values.item_information[1]?.rate}
                disabled={
                  formik.values.item_information[1]?.is_purchase ? false : true
                }
                error={itemInfoError && itemInfoError[1]?.rate ? "error" : ""}
                onChange={handleRateChange}
                onBlur={handleAmountOnBlur}
              />
              <span className="error">
                {itemInfoError && itemInfoError[1]?.rate}
              </span>
            </div>
            <div className="label-input-wrapper">
              <Label htmlFor="account" className="required">
                Account
              </Label>
              <CustomSearchSelect
                itemAccount={Number(
                  formik.values.item_information[1]?.account_id
                )}
                itemAccountName={
                  formik.values.item_information[1]?.account_name
                }
                organizationId={currentUserInfo.organization_id}
                handleItemAccountChange={handleItemPurchaseAccountChange}
                itemIndex={0}
                error={
                  itemInfoError && itemInfoError[1]?.account_id ? "error" : ""
                }
                baseAccount={"Expenses"}
                className="account"
                wrapperClass="drop-up"
                isDisabled={
                  formik.values.item_information[1]?.is_purchase ? false : true
                }
              />
              <span className="error">
                {itemInfoError && itemInfoError[1]?.account_id}
              </span>
            </div>
            <div className="label-input-wrapper">
              <FormTextAreaField
                id="purchase_description"
                name="purchase_description"
                label="Description"
                type="text"
                placeholder=""
                onChange={handleDescriptionChange}
                value={formik.values.item_information[1]?.description}
                className="form-control"
                wrapperClass="label-input-wrapper w-100"
                disabled={
                  formik.values.item_information[1]?.is_purchase ? false : true
                }
                maxLength={250}
              />
            </div>
          </div>
        </div>
        {isGstOrg && formik.values?.tax_preference === "TAXABLE" && (
          <div className="default-tax-wrapper">
            <div className="default-tax-header">Default Tax Rates</div>
            <div className="default-tax-label-input-wrapper">
              <Label htmlFor="gst" className="">
                Intra State Tax Rate
              </Label>
              <span className="sub-label">(Within State)</span>
              <Select
                name="intra_state_id"
                id="intra_state_id"
                menuPlacement={"top"}
                menuShouldScrollIntoView={true}
                options={intraStateTaxRates}
                className={`form-select custom-select fiscal-year-select mt-0`}
                value={formik.values.intra_state_id}
                placeholder="Choose Intra State Tax Rate"
                onChange={(e) => formik.setFieldValue("intra_state_id", e)}
                styles={customSelectStyle}
                isSearchable={false}
                maxMenuHeight={250}
              />
            </div>
            <div>
              <Label htmlFor="gst" className="">
                Inter State Tax Rate
              </Label>
              <span className="sub-label">(Outside State)</span>
              <Select
                name="inter_state_id"
                id="inter_state_id"
                menuPlacement={"top"}
                menuShouldScrollIntoView={true}
                options={interStateTaxRates}
                className={`form-select custom-select fiscal-year-select mt-0`}
                value={formik.values.inter_state_id}
                placeholder="Choose Inter State Tax Rate"
                onChange={(e) => formik.setFieldValue("inter_state_id", e)}
                styles={customSelectStyle}
                isSearchable={false}
                maxMenuHeight={250}
              />
            </div>
          </div>
        )}
      </div>
      <div className="row mb-0">
        <div className="col">
          <div
            className={`form-button-wrapper w-100 docked-button-container ${
              props.buttonWrapperClass ? props.buttonWrapperClass : ""
            }`}
          >
            <button
              className="save-button"
              type="submit"
              disabled={isDisabled ? true : false}
              onClick={() => formik.handleSubmit()}
              style={
                isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}
              }
            >
              Save
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={() => handleClose()}
              disabled={isDisabled ? true : false}
              style={
                isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default CreateItem;
