import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { currentUserSelector } from "../../features/common/commonSlice";

export const usePermissionHandler = () => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  let [organizationRole, setOrganizationRole] = useState<any[]>([]);

  useEffect(() => {
    setOrganizationRole(
      JSON.parse(sessionStorage.getItem("organizationRole") || "{}")
    );
  }, [currentUserInfo.organization_id]);

  let itemsRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Item")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const customerRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Customer")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const invoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Invoices")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let creditNoteRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Credit Notes"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let paymentsReceivedRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Payments Received"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let recurringInvoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Recurring Invoices"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let proformaInvoiceRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Proforma Invoice"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const vendorRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Vendor")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let billsRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Bills")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let debitNoteRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Debit Notes"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let paymentsMadeRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Payments Made"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let purchaseOrderRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Purchase Order"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let journalVoucherRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Journal Entry"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let systemVoucherPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "System Voucher"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let budgetRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Budget")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const taxConfigurationPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Tax Configuration"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let planRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Plans")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let addonsRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Addons")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let couponRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Coupons")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let subscriptionRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Subscriptions"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

    let chargesRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Charges"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const subModulesWithPermission = organizationRole.length
    ? organizationRole.map((permission: any) => {
        return permission.subModule;
      })
    : [];

  const reportinTagsPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Reporting Tag"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const gstPermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "GST")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  return {
    itemsRolePermission,
    customerRolePermission,
    invoicesRolePermission,
    creditNoteRolePermission,
    paymentsReceivedRolePermission,
    recurringInvoicesRolePermission,
    proformaInvoiceRolePermission,
    vendorRolePermission,
    billsRolePermission,
    debitNoteRolePermission,
    paymentsMadeRolePermission,
    purchaseOrderRolePermission,
    journalVoucherRolePermission,
    systemVoucherPermission,
    budgetRolePermission,
    planRolePermission,
    addonsRolePermission,
    couponRolePermission,
    subscriptionRolePermission,
    chargesRolePermission,
    taxConfigurationPermission,
    subModulesWithPermission,
    reportinTagsPermission,
    usersPermission,
    gstPermission,
  };
};
