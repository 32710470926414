import { useAppSelector } from "../../app/hooks";
import { currentUserSelector } from "../common/commonSlice";

const useVat = () => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  let isVatOrg = false;
  //   let vatEnabled = process.env.REACT_APP_GST_ENABLED;

  //   if (vatEnabled === "true") {
  //     if (currentUserInfo.organization_gst_state === null) {
  //       isGstOrg = false;
  //     } else {
  //       isGstOrg = true;
  //     }
  //   }

  //   return {
  //     isGstOrg,
  //   };

  if(currentUserInfo.is_vat_enabled) {
      isVatOrg = true;
  }

  return {
    isVatOrg
  }
};

export default useVat;
