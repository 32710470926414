import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { PricingListData } from "../../planType";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { defaultFrequencyCol } from "../../PlanStateInitialization";
import {
  coustomCase,
  pricingModuleRenderValue,
} from "../../../../../helpers/planHelper";
import {
  currentUserSelector,
  customFrequencySettingsSelector,
} from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import PricingListActions from "./PricingListActions";
import CustomPriceColumn from "./PriceColumn";
import TableContainer from "../../../../common/components/table/TableContainer";

type Props = {
  pricingListData: PricingListData[] | null;
  onClickDelete: (
    actionType: string,
    id: number,
    planName: string,
    planExistsSubscription: boolean
  ) => void;
  organizationId: number;
  frequencyRow: string[];
  planId: number;
  planStatus: string;
  currencyCode: string;
  currencyDict: any;
};
function PricingTable({
  pricingListData,
  onClickDelete,
  organizationId,
  planId,
  planStatus,
  currencyCode,
  currencyDict,
}: Props) {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const customFrequencyList = useAppSelector(customFrequencySettingsSelector);
  const { navigate } = useCommonData();
  const { planRolePermission } = usePermissionHandler();
  let showEmptyTable =
    planRolePermission.includes("Read") &&
    planRolePermission.includes("Create") &&
    organizationId === currentUserInfo.organization_id &&
    !(planStatus.toLowerCase() === "cancelled");

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Currency",
        accessor: "show.currency",
        sort: false,
        sortOrder: "",
        className: "plan_table_col",
      },
      {
        Header: "Frequency",
        accessor: "show.frequency",
        sort: false,
        sortOrder: "",
        className: "plan_table_col",
      },
      {
        Header: "Pricing Model",
        accessor: "show.pricingModel",
        sort: false,
        sortOrder: "",
        className: "plan_table_col",
      },
      {
        Header: "Invoicing Cycle",
        accessor: "show.invoicingCycle",
        sort: false,
        sortOrder: "",
        className: "plan_table_col",
      },
      {
        Header: "Trial",
        accessor: "show.trial",
        sort: false,
        sortOrder: "",
        className: "plan_table_col",
      },
      {
        Header: "Price",
        accessor: "show.priceItem",
        sort: false,
        sortOrder: "",
        className: "plan_price",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomPriceColumn
              value={value}
              showPricingActions={showEmptyTable}
              isButtonVisible={
                value.price
                  ? planRolePermission.includes("Update")
                  : planRolePermission.includes("Create")
              }
            />
          );
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !planRolePermission.includes("Delete") ||
            !value.price ||
            value.planStatus.toLowerCase() === "cancelled" ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <PricingListActions value={value} onClickDelete={onClickDelete} />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, planRolePermission, showEmptyTable]
  );

  function getKeyByValue(
    obj: { [key: number]: string },
    value: string
  ): number | undefined {
    const entry = Object.entries(obj).find(([_, v]) => v === value);
    return entry ? Number(entry[0]) : undefined;
  }

  /**
   * set table row data
   */
  const getPricingData = (plan: any, index: any) => {
    return {
      show: {
        currency: plan.currency || plan.currency_code,
        frequency: coustomCase(plan.frequency),
        pricingModel:
          pricingModuleRenderValue.find(
            (eachPriceModel) => eachPriceModel.key === plan.pricing_model
          )?.value || "",
        invoicingCycle: coustomCase(plan.invoicing_cycle),
        trial: plan.trial
          ? `${plan.trial_count} ${coustomCase(plan.trial_frequency)}`
          : plan.pricing_model
          ? "No Trial"
          : "",
        priceItem: {
          item: plan.item_price,
          price: plan.isPriceSet,
          index: index,
          frequency: plan.frequency,
          pricingModel: plan.pricing_model,
          organizationId: organizationId,
          planId: planId,
          planStatus: planStatus,
          currencyCode: plan.currency_code ? plan.currency_code : plan.currency,
          currencyId: getKeyByValue(
            currencyDict,
            plan.currency || plan.currency_code
          ),

          pricingId: plan.pricing_id,
        },
        actions: {
          organizationId: organizationId,
          price: plan.isPriceSet,
          pricngId: plan.pricing_id,
          planStatus: planStatus,
          frequency: coustomCase(plan.frequency),
          pricingExistsSubscription: plan.pricing_exists_subscription,
          currencyCode: getKeyByValue(currencyDict, plan.currency),
        },
      },
    };
  };
  const data = pricingListData
    ? pricingListData.map((plan, index) => getPricingData(plan, index))
    : [];

  const allCurrency = Object.values(currencyDict);
  const customFrequencyArray: any[] = [];
  customFrequencyList.length > 0 &&
    customFrequencyList.map((item: any) => {
      allCurrency.map((currency: any) => {
        if (item.is_enabled) {
          customFrequencyArray.push(
            currency + "-" + item.frequency.toUpperCase()
          );
        }
      });
    });

  const getDefaultPricingTableData = () =>
    customFrequencyArray.map((data: string) => ({
      ...defaultFrequencyCol,
      frequency: data.split("-")[1],
      currency: data.split("-")[0],
    }));

  const modifiedPricingData = getDefaultPricingTableData().map((plan, index) =>
    getPricingData(plan, index)
  );

  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data plan-table"
      >
        <div className="row">
          <div className="col-md-12">
            {pricingListData && pricingListData.length > 0 ? (
              <TableContainer
                className="list-table table table-striped w-100"
                columns={columns}
                data={data}
                url={"/plan"}
                sortHandler={() => {}}
                editPermission={planRolePermission.includes("Update")}
                isDetailViewExist={true}
              >
                <div>No Data</div>
              </TableContainer>
            ) : (
              // : showEmptyTable ? (
              //   <div className="set-price-container align-center">
              //     <span className="set-price-container-span">Set price for</span>
              //     {customFrequencyArray.length > 0 &&
              //       customFrequencyArray.map((data, index) => (
              //         <div key={data} className="set-price-container-div">
              //           <span className="set-price-container-span">{`${
              //             [customFrequencyArray.length - 1, 0].includes(index)
              //               ? index === 0
              //                 ? ""
              //                 : `  &`
              //               : ","
              //           }`}</span>
              //           <button
              //             className="set-pricing-button"
              //             onClick={() => {
              //               navigate(
              //                 `/plan/price/create/${planId}/${data.toLowerCase()}`,
              //                 { replace: true }
              //               );
              //             }}
              //           >
              //             {coustomCase(data)}
              //           </button>
              //         </div>
              //       ))}
              //   </div>
              // )
              <TableContainer
                className="list-table table table-striped w-100"
                columns={columns}
                data={modifiedPricingData}
                url={"/plan"}
                sortHandler={() => {}}
                editPermission={planRolePermission.includes("Update")}
                isDetailViewExist={true}
              >
                <div>No Data</div>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PricingTable);
