import React from "react";
import { Row } from "@tanstack/react-table";
import { ProcessedRow } from "../../types";
import { Span } from "../../../../../common/components/htmlTags/Span";

interface TotalCellProps {
  row: Row<ProcessedRow>;
  formatNumber: (value: number) => string;
  value: string;
}

export const TotalCell: React.FC<TotalCellProps> = ({
  row,
  formatNumber,
  value,
}) => {
  return (
    <Span style={{ textAlign: "right" }}>
      {row.getIsExpanded() ? "" : formatNumber(Number(value))}
    </Span>
  );
};
