import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PrivateRoute } from "./PrivateRoute";
import { useAppSelector } from "./app/hooks";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import {
  currentUserSelector,
  loaderStateSelector,
  organizationRoleSelector,
} from "./features/common/commonSlice";
import PageNotFound from "./features/common/components/PageNotFound";
import ScrollToTop from "./features/common/components/ScrollToTop";
import AccountClosingMaintenance from "./features/home/AccountClosingMaintenance/AccountClosingMaintenance";
import Home from "./features/home/Home";
import AddonForm from "./features/home/addons/components/addonForm/AddonForm";
import AddonList from "./features/home/addons/components/list/AddonList";
import AddonPriceForm from "./features/home/addons/components/price/AddonPriceForm";
import AddonDetailBody from "./features/home/addons/components/view/AddonView";
import BillForm from "./features/home/bills/components/billForm/BillForm";
import BillApplyTags from "./features/home/bills/components/billForm/reportingtags/applyTags/ApplyTags";
import JournalListBills from "./features/home/bills/components/billForm/reportingtags/journalList/JournalList";
import BillsDetail from "./features/home/bills/components/billsDetail/BillsDetail";
import BillsList from "./features/home/bills/components/list/List";
import AccountsTree from "./features/home/chartOfAccounts/components/Tree";
import AccountsList from "./features/home/chartOfAccounts/components/list/List";
import HomeLoader from "./features/home/components/HomeLoader";
import CouponForm from "./features/home/coupon/components/couponForm/CouponForm";
import CouponList from "./features/home/coupon/components/list/CouponList";
import CouponDetailBody from "./features/home/coupon/components/view/CouponView";
import ConfigureCoupon from "./features/home/couponConfiguration/components/ConfigureCoupon";
import CreditNoteDetail from "./features/home/creditNote/components/creditNoteDetail/CreditNoteDetail";
import CreditNoteApplyTags from "./features/home/creditNote/components/creditNoteForm/reportingtags/applyTags/ApplyTags";
import CreditNoteJournalList from "./features/home/creditNote/components/creditNoteForm/reportingtags/journalList/JournalList";
import CreditNoteList from "./features/home/creditNote/components/list/List";
import CurrencyList from "./features/home/currencyConfiguration/components/CurrencyList";
import CreateCustomer from "./features/home/customerManagement/components/CustomerContainer";
import CustomerDetail from "./features/home/customerManagement/components/customerDetail/CustomerDetail";
import CustomersList from "./features/home/customerManagement/components/list/List";
import CustomizePrefixContainer from "./features/home/customizePrefix/components/CustomizePrefixContainer";
import Dashboard from "./features/home/dashboard/components/Dashboard";
import CreateDebitNote from "./features/home/debitNote/components/createDebitNote/CreateDebitNote";
import DebitNoteApplyTags from "./features/home/debitNote/components/createDebitNote/reportingtags/applyTags/ApplyTags";
import JournalListDebitNote from "./features/home/debitNote/components/createDebitNote/reportingtags/journalList/JournalList";
import DebitNoteDetail from "./features/home/debitNote/components/debitNoteDetail/DebitNoteDetail";
import DebitNoteList from "./features/home/debitNote/components/list/List";
import FiscalYearClosing from "./features/home/fiscalYearClosing/FiscalYearClosing";
import DefaultPreference from "./features/home/gst/components/defaultPreference/DefaultPreference";
import GstSettings from "./features/home/gst/components/settings/GstSettings";
import TaxRateList from "./features/home/gst/taxRates/components/List";
import InvoiceApplyTags from "./features/home/invoice/components/invoiceForm/reportingtags/applyTags/ApplyTags";
import JournalList from "./features/home/invoice/components/invoiceForm/reportingtags/journalList/JournalList";
import InvoicePreview from "./features/home/invoice/components/invoicePreview/InvoicePreview";
import InvoiceList from "./features/home/invoice/components/list/List";
import CreateItemContainer from "./features/home/items/components/createItemForm/CreateItemContainer";
import ItemDetail from "./features/home/items/components/itemDetail/ItemDetail";
import ItemList from "./features/home/items/components/list/List";
import JournalVoucherDetail from "./features/home/journalVoucher/components/JournalVoucherDetail/JournalVoucherDetail";
import JournalEntryApplyTags from "./features/home/journalVoucher/components/createJournalVoucher/reportingtags/applyTags/ApplyTags";
import JournalListJV from "./features/home/journalVoucher/components/createJournalVoucher/reportingtags/journalList/JournalList";
import JournalVoucherList from "./features/home/journalVoucher/components/list/List";
import MigrationDate from "./features/home/migrationDate/MigrationDate";
import OrganizationTree from "./features/home/organizationHierarchy/components/hierarchyListing/hierarchyListing";
import OrganizationProfileContainer from "./features/home/organizationProfile/components/OrganizationProfileContainer";
import PaymentMadeList from "./features/home/paymentsMade/components/list/List";
import PaymentMadeDetail from "./features/home/paymentsMade/components/paymentMadeDetail/paymentMadeDetail";
import PaymentMadeForm from "./features/home/paymentsMade/components/paymentMadeForm/PaymentMadeForm";
import PMApplyTags from "./features/home/paymentsMade/components/reportingtags/applyTags/ApplyTags";
import JournalListPM from "./features/home/paymentsMade/components/reportingtags/journalList/JournalList";
import PaymentReceivedList from "./features/home/paymentsReceived/components/list/List";
import PaymentReceivedDetail from "./features/home/paymentsReceived/components/paymentReceivedPreview/paymentReceivedDetail";
import PRApplyTags from "./features/home/paymentsReceived/components/reportingtags/applyTags/ApplyTags";
import JournalListPR from "./features/home/paymentsReceived/components/reportingtags/journalList/JournalList";
import PlanList from "./features/home/plan/components/list/PlanList";
import PlanForm from "./features/home/plan/components/planForm/PlanForm";
import PriceForm from "./features/home/plan/components/price/PriceForm";
import PlanDetailBody from "./features/home/plan/components/view/PlanView";
import PurchasePreference from "./features/home/preferences/components/PurchasePreference";
import SalesPreference from "./features/home/preferences/components/SalesPreference";
import ConfigureTransactions from "./features/home/preferences/components/configureTransaction/ConfigureTransactions";
import RecurringInvoicePreference from "./features/home/preferences/components/recurringInvoice/RecurringInvoicePreference";
import ProformaPreview from "./features/home/proforma/invoicePreview/ProformaPreview";
import ProformaInvoiceList from "./features/home/proforma/list/ProformaList";
import PurchaseOrderList from "./features/home/purchaseOrder/components/list/List";
import PurchaseOrderDetails from "./features/home/purchaseOrder/components/purchaseOrderDetails/PurchaseOrderDetails";
import PurchaseOrderForm from "./features/home/purchaseOrder/components/purchaseOrderForm/PurchaseOrderForm";
import RecurringInvoiceList from "./features/home/recurringInvoice/components/list/RecurringInvoiceList";
import RecurringInvoiceForm from "./features/home/recurringInvoice/components/recurringInvoiceForm/RecurringInvoiceForm";
import ViewRecurringInvoice from "./features/home/recurringInvoice/components/view/RecurringInvoiceView";
import AccountTransaction from "./features/home/reports/accountTransactions/AccountTransactions";
import SundryCreditorDetails from "./features/home/reports/agingReport/components/SundryCreditorDetails";
import SundryCreditorSummary from "./features/home/reports/agingReport/components/SundryCreditorSummary";
import SundryDebtorDetails from "./features/home/reports/agingReport/components/SundryDebtorDetails";
import SundryDebtorSummary from "./features/home/reports/agingReport/components/SundryDebtorSummary";
import BalanceSheet from "./features/home/reports/balanceSheet/components/BalanceSheet";
import BudgetSummaryReport from "./features/home/reports/budgetSummaryReport/BudgetSummaryReport";
import CustomerBalance from "./features/home/reports/customerBalance/CustomerBalance";
import CustomerBalanceDetails from "./features/home/reports/customerBalance/CustomerBalanceDetails";
import DayBook from "./features/home/reports/dayBook/components/DayBook";
import ProfitAndLoss from "./features/home/reports/profitAndLoss/components/ProfitAndLoss";
import StatementOfAccounts from "./features/home/reports/statementOfAccount/components/StatementOfAccounts";
import TrialBalance from "./features/home/reports/trialBalance/components/TrialBalance";
import VendorBalance from "./features/home/reports/vendorBalance/VendorBalance";
import VendorBalanceDetails from "./features/home/reports/vendorBalance/VendorBalanceDetails";
import SubscriptionList from "./features/home/subscription/components/list/SubscriptionList";
import SubscriptionForm from "./features/home/subscription/components/subscriptionForm/SubscriptionForm";
import SubscriptionView from "./features/home/subscription/components/view/SubscriptionView";
import SystemVoucherDetail from "./features/home/systemVoucher/components/SystemVoucherDetail/SystemVoucherDetail";
import SystemVoucherList from "./features/home/systemVoucher/components/list/List";
import SVApplyTags from "./features/home/systemVoucher/components/reportingtags/applyTags/ApplyTags";
import JournalListSV from "./features/home/systemVoucher/components/reportingtags/journalList/JournalList";
import TaxList from "./features/home/taxConfiguration/components/list/List";
import TCSList from "./features/home/tcs/components/list/List";
import TDSList from "./features/home/tds/components/list/List";
import LockAllTransactions from "./features/home/transactionLocking/components/lockAllTransactions/LockAllTransactions";
import TransactionLocking from "./features/home/transactionLocking/components/transactionLocking/TransactionLocking";
import RolesList from "./features/home/userManagement/components/userRoles/list/List";
import RoleFormContainer from "./features/home/userManagement/components/userRoles/roleForm/RoleFormContainer";
import UsersList from "./features/home/userManagement/components/users/list/List";
import VendorForm from "./features/home/vendorManagement/components/VendorContainer";
import VendorsList from "./features/home/vendorManagement/components/list/List";
import VendorDetail from "./features/home/vendorManagement/components/vendorDetail/VendorDetail";
import useClosingMaintenance from "./features/hooks/useClosingMaintenance";
import UserService from "./keycloak/UserService";
import { AllocationDatePreference } from "./features/home/preferences/components/allocationDatePreference/AllocationDatePreference";
import { BudgetVarianceReport } from "./features/home/reports/budgetVarianceReport/BudgetVarianceReport";
import { CalendarBillingList } from "./features/home/calendarBilling/list/CalendarBillingList";
import { CustomFieldLayout } from "./features/home/customFields/customFieldsLayout";
import { InvoicingFrequencyList } from "./features/home/InvoicingFrequency/list/InvoicingFrequencyList";
import { JournalVoucherForm } from "./features/home/journalVoucher/components/journalVoucherForm/JournalVoucherForm";
import { LoginLoader } from "./features/common/components/loginLoader/LoginLoader";
import { PayableSummaryReport } from "./features/home/reports/payableSummary/payableSummary";
import { PaymentGatewayConfig } from "./features/home/paymentGateway/components/paymentGatewayConfig/PaymentGatewayConfig";
import { PaymentReceivedForm } from "./features/home/paymentsReceived/components/paymentReceivedForm/paymentReceivedForm";
import { ReceivableSummaryReport } from "./features/home/reports/receivableSummary/receivableSummary";
import { ReportingTagsList } from "./features/home/reportingTags/reportingTagList/ReportingTagList";
import { SmtpServerConfig } from "./features/home/smtpSettings/Smtpsettings";
import { Search } from "./features/home/search/searchResultPage/Search";
import { SystemVoucherForm } from "./features/home/systemVoucher/components/systemVoucherForm/SystemVoucherForm";
import { TaxExemptionList } from "./features/home/gst/components/taxExemption/taxExemptionList/TaxExemptionList";
import VatSettings from "./features/home/vat/components/settings/VatSettings";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ExportData from "./features/home/exportData/components/ExportData";

const InvoiceFormNew = React.lazy(
  () => import("./features/home/invoice/components/createInvoice/InvoiceForm")
);
const ProformaFormNew = React.lazy(
  () =>
    import("./features/home/proforma/components/createProforma/ProformaForm")
);
const CreditNoteFormNew = React.lazy(
  () =>
    import(
      "./features/home/creditNote/components/createCreditNote/CreditNoteForm"
    )
);
const BudgetList = React.lazy(
  () => import("./features/home/budget/pages/budgetList/BudgetList")
);
const BudgetForm = React.lazy(
  () => import("./features/home/budget/pages/budgetForm/BudgetForm")
);
const BudgetPreview = React.lazy(
  () => import("./features/home/budget/pages/budgetPreview/BudgetPreview")
);
const ChargesList = React.lazy(
  () => import("./features/home/charges/pages/chargesList/ChargesList")
);
const ChargesForm = React.lazy(
  () => import("./features/home/charges/pages/chargesForm/ChargesForm")
);
const ChargesPreview = React.lazy(
  () => import("./features/home/charges/pages/chargesPreview/ChargesPreview")
);

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

function AppContent() {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { isClosingRunning } = useClosingMaintenance();
  const [toggleOrgId, setToggleOrgId] = useState(false);
  const [orgId, setOrgId] = useState(0);
  const isLoading = useAppSelector(loaderStateSelector);
  const organizationRole = useAppSelector(organizationRoleSelector);
  let gstEnabled = process.env.REACT_APP_GST_ENABLED;
  let svEnabled = process.env.REACT_APP_SV_ENABLED;
  let vatEnabled = currentUserInfo.organization_tax_system === "UAE_TAX_SYSTEM";

  useEffect(() => {
    setToggleOrgId(!toggleOrgId);
    setOrgId(currentUserInfo.organization_id);
  }, [currentUserInfo.organization_id]);

  if (isClosingRunning) return <AccountClosingMaintenance />;
  if (currentUserInfo.onboarding_status === false) {
    return <LoginLoader />;
  }

  return (
    <div className="App">
      <ScrollToTop />
      {(organizationRole === null &&
        currentUserInfo.organization_id !== undefined &&
        currentUserInfo.multi_org_null_last_login === false) ||
      isLoading ? (
        <HomeLoader />
      ) : null}
      {process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID !== "" ? (
        <Helmet>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', ${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID});
          `}
          </script>
        </Helmet>
      ) : null}
      <Routes>
        {UserService.isLoggedIn() ? (
          <Route path="/" element={<Home />}>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/settings/advanced-accounting/migration-date"
              element={<MigrationDate />}
            />
            {/* Users */}
            <Route
              path="/users"
              element={
                <PrivateRoute subModule="Users" permission="Read">
                  <UsersList />
                </PrivateRoute>
              }
            />
            {/* Roles */}
            <Route
              path="roles"
              element={
                <PrivateRoute subModule="Roles" permission="Read">
                  <RolesList />
                </PrivateRoute>
              }
            />
            <Route
              path="roles/create"
              element={
                <PrivateRoute subModule="Roles" permission="Create">
                  <RoleFormContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="roles/clone/:editId"
              element={
                <PrivateRoute subModule="Roles" permission="Read">
                  <RoleFormContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="roles/edit/:editId"
              element={
                <PrivateRoute subModule="Roles" permission="Update">
                  <RoleFormContainer />
                </PrivateRoute>
              }
            />
            {/* Customers */}
            <Route
              path="/customers"
              element={
                <PrivateRoute subModule="Customer" permission="Read">
                  <CustomersList />
                </PrivateRoute>
              }
            />
            <Route
              path="/customers/create"
              element={
                <PrivateRoute subModule="Customer" permission="Create">
                  <CreateCustomer />
                </PrivateRoute>
              }
            />
            <Route
              path="/customers/edit/:id"
              element={
                <PrivateRoute subModule="Customer" permission="Update">
                  <CreateCustomer />
                </PrivateRoute>
              }
            />
            <Route
              path="/customers/detail/:editId"
              element={
                <PrivateRoute subModule="Customer" permission="Read">
                  <CustomerDetail />
                </PrivateRoute>
              }
            />
            {/* Chart of Account */}
            <Route
              path="/accounts/list"
              element={
                <PrivateRoute subModule="Chart of Account" permission="Read">
                  <AccountsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts/tree"
              element={
                <PrivateRoute subModule="Chart of Account" permission="Read">
                  <AccountsTree />
                </PrivateRoute>
              }
            />
            {/* Vendors */}
            <Route
              path="/vendors"
              element={
                <PrivateRoute subModule="Vendor" permission="Read">
                  <VendorsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendors/create"
              element={
                <PrivateRoute subModule="Vendor" permission="Create">
                  <VendorForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendors/edit/:id"
              element={
                <PrivateRoute subModule="Vendor" permission="Update">
                  <VendorForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendors/detail/:editId"
              element={
                <PrivateRoute subModule="Vendor" permission="Read">
                  <VendorDetail />
                </PrivateRoute>
              }
            />
            {/* Organization Hierarchy */}
            <Route
              path="/organization/hierarchy"
              element={
                <PrivateRoute
                  subModule="Organization Hierarchy"
                  permission="Create"
                >
                  <OrganizationTree />
                </PrivateRoute>
              }
            />
            {/* Invoices */}
            <Route
              path="/invoice"
              element={
                <PrivateRoute subModule="Invoices" permission="Read">
                  <InvoiceList />
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/detail/:editInvoiceId"
              element={
                <PrivateRoute subModule="Invoices" permission="Read">
                  <InvoicePreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/detail/:editInvoiceId?redirect=redirect"
              element={
                <PrivateRoute subModule="Invoices" permission="Read">
                  <InvoicePreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/create"
              element={
                <PrivateRoute subModule="Invoices" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <InvoiceFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/create/:customerId"
              element={
                <PrivateRoute subModule="Invoices" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <InvoiceFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/edit/:editId"
              element={
                <PrivateRoute subModule="Invoices" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <InvoiceFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Invoices" permission="Update">
                  <JournalList />
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/edit/:editId/reporting-tags/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Invoices" permission="Update">
                  <InvoiceApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/invoice/edit/:editId?replace=:replace"
              element={
                <PrivateRoute subModule="Invoices" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <InvoiceFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            {/* Proforma Invoice */}
            <Route
              path="/proforma-inv"
              element={
                <PrivateRoute subModule="Proforma Invoice" permission="Read">
                  <ProformaInvoiceList />
                </PrivateRoute>
              }
            />
            <Route
              path="/proforma-inv/create"
              element={
                <PrivateRoute subModule="Proforma Invoice" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ProformaFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/proforma-inv/edit/:editId"
              element={
                <PrivateRoute subModule="Proforma Invoice" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ProformaFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/proforma-inv/detail/:editId"
              element={
                <PrivateRoute subModule="Proforma Invoice" permission="Read">
                  <ProformaPreview />
                </PrivateRoute>
              }
            />
            {/* Recurring Invoice*/}
            <Route
              path="/recurring-inv"
              element={
                <PrivateRoute subModule="Recurring Invoices" permission="Read">
                  <RecurringInvoiceList />
                </PrivateRoute>
              }
            />
            <Route
              path="/recurring-inv/create"
              element={
                <PrivateRoute
                  subModule="Recurring Invoices"
                  permission="Create"
                >
                  <RecurringInvoiceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/recurring-inv/detail/:recurringInvoiceId"
              element={
                <PrivateRoute subModule="Recurring Invoices" permission="Read">
                  <ViewRecurringInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/recurring-inv/edit/:editId"
              element={
                <PrivateRoute
                  subModule="Recurring Invoices"
                  permission="Update"
                >
                  <RecurringInvoiceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/recurring-inv/clone/:editId"
              element={
                <PrivateRoute subModule="Recurring Invoices" permission="Read">
                  <RecurringInvoiceForm />
                </PrivateRoute>
              }
            />
            {/* Bills */}
            <Route
              path="/bill"
              element={
                <PrivateRoute subModule="Bills" permission="Read">
                  <BillsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/create"
              element={
                <PrivateRoute subModule="Bills" permission="Create">
                  <BillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/create/:vendorId"
              element={
                <PrivateRoute subModule="Bills" permission="Create">
                  <BillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/edit/:editId"
              element={
                <PrivateRoute subModule="Bills" permission="Update">
                  <BillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Bills" permission="Update">
                  <JournalListBills />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/edit/:editId/reporting-tags/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Bills" permission="Update">
                  <BillApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/edit/:editId?replace=:replace"
              element={
                <PrivateRoute subModule="Bills" permission="Update">
                  <BillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/detail/:editBillId"
              element={
                <PrivateRoute subModule="Bills" permission="Read">
                  <BillsDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/bill/detail/:editBillId?redirect=redirect"
              element={
                <PrivateRoute subModule="Bills" permission="Read">
                  <BillsDetail />
                </PrivateRoute>
              }
            />
            {/*Purchase Order*/}
            <Route
              path="/purchase-order"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Read">
                  <PurchaseOrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-order/detail/:purchaseOrderId"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Read">
                  <PurchaseOrderDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-order/create"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Create">
                  <PurchaseOrderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-order/edit/:editId"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Update">
                  <PurchaseOrderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-order/edit/:editId?replace=:replace"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Update">
                  <PurchaseOrderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-order/detail/:editBillId"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Read">
                  <PurchaseOrderDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-order/detail/:editBillId?redirect=redirect"
              element={
                <PrivateRoute subModule="Purchase Order" permission="Read">
                  <PurchaseOrderDetails />
                </PrivateRoute>
              }
            />
            {/* Payments Made */}
            <Route
              path="/payment-made"
              element={
                <PrivateRoute subModule="Payments Made" permission="Read">
                  <PaymentMadeList />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/create"
              element={
                <PrivateRoute subModule="Payments Made" permission="Create">
                  <PaymentMadeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/create/:vendorId"
              element={
                <PrivateRoute subModule="Payments Made" permission="Create">
                  <PaymentMadeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/edit/:editId"
              element={
                <PrivateRoute subModule="Payments Made" permission="Update">
                  <PaymentMadeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/edit/:editId?replace=replace"
              element={
                <PrivateRoute subModule="Payments Made" permission="Update">
                  <PaymentMadeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Payments Made" permission="Update">
                  <JournalListPM />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/edit/:editId/reporting-tags/:billId/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Payments Made" permission="Update">
                  <PMApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/detail/:editId"
              element={
                <PrivateRoute subModule="Payments Made" permission="Read">
                  <PaymentMadeDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-made/detail/:editId?redirect=redirect"
              element={
                <PrivateRoute subModule="Payments Made" permission="Read">
                  <PaymentMadeDetail />
                </PrivateRoute>
              }
            />
            {/* Payments Received */}
            <Route
              path="/payment-received"
              element={
                <PrivateRoute subModule="Payments Received" permission="Read">
                  <PaymentReceivedList />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/create"
              element={
                <PrivateRoute subModule="Payments Received" permission="Create">
                  <PaymentReceivedForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/create/:customerId"
              element={
                <PrivateRoute subModule="Payments Received" permission="Create">
                  <PaymentReceivedForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/edit/:editId"
              element={
                <PrivateRoute subModule="Payments Received" permission="Update">
                  <PaymentReceivedForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/edit/:editId?replace=replace"
              element={
                <PrivateRoute subModule="Payments Received" permission="Update">
                  <PaymentReceivedForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Payments Received" permission="Update">
                  <JournalListPR />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/edit/:editId/reporting-tags/:invoiceId/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Invoices" permission="Update">
                  <PRApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/detail/:editId"
              element={
                <PrivateRoute subModule="Payments Received" permission="Read">
                  <PaymentReceivedDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-received/detail/:editId?redirect=redirect"
              element={
                <PrivateRoute subModule="Payments Received" permission="Read">
                  <PaymentReceivedDetail />
                </PrivateRoute>
              }
            />
            {/* Credit Note */}
            <Route
              path="/credit-note"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Read">
                  <CreditNoteList />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/create"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreditNoteFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/credit-note/create/:invoiceId"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreditNoteFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/credit-note/create-new"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Create">
                  <CreditNoteFormNew />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/create/:customerId"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreditNoteFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/edit/:editId"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreditNoteFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Update">
                  <CreditNoteJournalList />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/edit/:editId/reporting-tags/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Update">
                  <CreditNoteApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/edit/:editId?replace=replace"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreditNoteFormNew />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/detail/:editId"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Read">
                  <CreditNoteDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note/detail/:editId?redirect=redirect"
              element={
                <PrivateRoute subModule="Credit Notes" permission="Read">
                  <CreditNoteDetail />
                </PrivateRoute>
              }
            />
            {/* Debit note */}
            <Route
              path="/debit-note"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Read">
                  <DebitNoteList />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/create"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Create">
                  <CreateDebitNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/create/:vendorId"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Create">
                  <CreateDebitNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/edit/:editId"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Update">
                  <CreateDebitNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Update">
                  <JournalListDebitNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/edit/:editId/reporting-tags/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Update">
                  <DebitNoteApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/edit/:editId?replace=replace"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Update">
                  <CreateDebitNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/detail/:editId"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Read">
                  <DebitNoteDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note/detail/:editId?redirect=redirect"
              element={
                <PrivateRoute subModule="Debit Notes" permission="Read">
                  <DebitNoteDetail />
                </PrivateRoute>
              }
            />
            {/* Journal Voucher */}
            <Route
              path="/journal-entry"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Read">
                  <JournalVoucherList />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/create"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Create">
                  <JournalVoucherForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/edit/:editId"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Update">
                  <JournalVoucherForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/edit/:editId/reporting-tags"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Update">
                  <JournalListJV />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/edit/:editId/reporting-tags/:accountId/:entityId/:entityType"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Update">
                  <JournalEntryApplyTags />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/edit/:editId?replace=replace"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Update">
                  <JournalVoucherForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/detail/:editId"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Read">
                  <JournalVoucherDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry/detail/:editId?redirect=redirect"
              element={
                <PrivateRoute subModule="Journal Entry" permission="Read">
                  <JournalVoucherDetail />
                </PrivateRoute>
              }
            />
            {/* System Voucher */}
            {svEnabled === "true" && (
              <>
                <Route
                  path="/system-voucher"
                  element={
                    <PrivateRoute subModule="System Voucher" permission="Read">
                      <SystemVoucherList />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/system-voucher/create"
                  element={
                    <PrivateRoute
                      subModule="System Voucher"
                      permission="Create"
                    >
                      <SystemVoucherForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/system-voucher/edit/:editId"
                  element={
                    <PrivateRoute
                      subModule="System Voucher"
                      permission="Update"
                    >
                      <SystemVoucherForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/system-voucher/detail/:editId"
                  element={
                    <PrivateRoute subModule="System Voucher" permission="Read">
                      <SystemVoucherDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/system-voucher/edit/:editId/reporting-tags"
                  element={
                    <PrivateRoute
                      subModule="System Voucher"
                      permission="Update"
                    >
                      <JournalListSV />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/system-voucher/edit/:editId/reporting-tags/:accountId/:entityId/:entityType"
                  element={
                    <PrivateRoute
                      subModule="System Voucher"
                      permission="Update"
                    >
                      <SVApplyTags />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/system-voucher/detail/:editId?redirect=redirect"
                  element={
                    <PrivateRoute subModule="System Voucher" permission="Read">
                      <SystemVoucherDetail />
                    </PrivateRoute>
                  }
                />
              </>
            )}
            {/* Budget */}
            {/* subModule Name must be updated after permission matrix update */}
            <Route
              path="/budget"
              element={
                <PrivateRoute subModule="Budget" permission="Read">
                  <Suspense fallback={<div>Loading...</div>}>
                    <BudgetList />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/budget/create"
              element={
                <PrivateRoute subModule="Budget" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <BudgetForm />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/budget/edit/:editBudgetId"
              element={
                <PrivateRoute subModule="Budget" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <BudgetForm />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/budget/edit/:editBudgetId?replace=replace"
              element={
                <PrivateRoute subModule="Budget" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <BudgetForm />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/budget/detail/:editBudgetId"
              element={
                <PrivateRoute subModule="Budget" permission="Read">
                  <Suspense fallback={<div>Loading...</div>}>
                    <BudgetPreview />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/budget/detail/:editBudgetId?redirect=redirect"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <BudgetPreview />
                </Suspense>
              }
            />
            {/* Tax Configuration */}
            <Route
              path="/settings/taxes/tax-rates"
              element={
                <PrivateRoute subModule="Tax Configuration" permission="Read">
                  <TaxList />
                </PrivateRoute>
              }
            />
            {/* Organization Profile */}
            <Route
              path="/settings/organization-profile"
              element={
                <PrivateRoute
                  subModule="Organization Profile"
                  permission="Create"
                >
                  <OrganizationProfileContainer />
                </PrivateRoute>
              }
            />
            {/* Customize Prefix */}
            <Route
              path="/settings/customize-prefix"
              element={
                <PrivateRoute subModule="Customize Prefix" permission="Create">
                  <CustomizePrefixContainer />
                </PrivateRoute>
              }
            />
            {/* Currency Configuration */}
            <Route
              path="/settings/currency-config"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <CurrencyList />
                </PrivateRoute>
              }
            />
            {/* Currency Configuration */}
            <Route
              path="/settings/custom-fields"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <CustomFieldLayout />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/preferences/general/rounding-off-sales"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <SalesPreference />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/preferences/general/rounding-off-purchase"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <PurchasePreference />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/preferences/general/recurring-invoice"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <RecurringInvoicePreference />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/preferences/general/configure-transactions"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <ConfigureTransactions />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/preferences/general/allocation-date"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <AllocationDatePreference />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/preferences/reporting-tags"
              element={
                <PrivateRoute subModule="Reporting Tag" permission="Read">
                  <ReportingTagsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/subscriptions/calender-invoicing"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <CalendarBillingList />
                </PrivateRoute>
              }
            />
            {/* InvoicingFrequency */}
            <Route
              path="/settings/subscriptions/invoicing-frequency"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <InvoicingFrequencyList />
                </PrivateRoute>
              }
            />
            <Route
              path="settings/subscriptions/coupon-config"
              element={
                <PrivateRoute subModule="Users" permission="Create">
                  <ConfigureCoupon />
                </PrivateRoute>
              }
            />
            {/* Trial Balance */}
            <Route
              path="/trial-balance"
              element={
                <PrivateRoute subModule="Trial Balance" permission="Read">
                  <TrialBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/trial-balance-1"
              element={
                <PrivateRoute subModule="Trial Balance" permission="Read">
                  <TrialBalance />
                </PrivateRoute>
              }
            />
            {/* Statement of Accounts */}
            <Route
              path="/soa"
              element={
                <PrivateRoute
                  subModule="Statement of Accounts"
                  permission="Read"
                >
                  <StatementOfAccounts />
                </PrivateRoute>
              }
            />
            {/* Balance Sheet */}
            <Route
              path="/balance-sheet"
              element={
                <PrivateRoute subModule="Balance Sheet" permission="Read">
                  <BalanceSheet />
                </PrivateRoute>
              }
            />
            <Route
              path="/balance-sheet-1"
              element={
                <PrivateRoute subModule="Balance Sheet" permission="Read">
                  <BalanceSheet />
                </PrivateRoute>
              }
            />
            {/* Profit and Loss */}
            <Route
              path="/profit-and-loss"
              element={
                <PrivateRoute subModule="Profit and Loss" permission="Read">
                  <ProfitAndLoss />
                </PrivateRoute>
              }
            />
            <Route
              path="/profit-and-loss-1"
              element={
                <PrivateRoute subModule="Profit and Loss" permission="Read">
                  <ProfitAndLoss />
                </PrivateRoute>
              }
            />
            {/* Account Transactions */}
            <Route
              path="/account-transactions"
              element={
                <PrivateRoute
                  subModule="Account Transactions & Day Book"
                  permission="Read"
                >
                  <AccountTransaction />
                </PrivateRoute>
              }
            />
            {/* Day Book */}
            <Route
              path="/day-book"
              element={
                <PrivateRoute
                  subModule="Account Transactions & Day Book"
                  permission="Read"
                >
                  <DayBook />
                </PrivateRoute>
              }
            />
            {/* Lock Transactions */}
            <Route
              path="/transaction-locking"
              element={
                <PrivateRoute
                  subModule="Transaction Locking"
                  permission="Create"
                >
                  <TransactionLocking />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction-locking/lock-all-transactions"
              element={
                <PrivateRoute
                  subModule="Transaction Locking"
                  permission="Create"
                >
                  <LockAllTransactions />
                </PrivateRoute>
              }
            />
            <Route
              path="/fiscal-year-closing"
              element={
                <PrivateRoute
                  subModule="Journal Entry" /** To be Changed after fiscal year SubModule is received **/
                  permission="Create"
                >
                  <FiscalYearClosing />
                </PrivateRoute>
              }
            />
            <Route
              path="/items"
              element={
                <PrivateRoute subModule="Item" permission="Read">
                  <ItemList />
                </PrivateRoute>
              }
            />
            <Route
              path="/items/create"
              element={
                <PrivateRoute subModule="Item" permission="Create">
                  <CreateItemContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/items/edit/:editId"
              element={
                <PrivateRoute subModule="Item" permission="Update">
                  <CreateItemContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/items/detail/:editId"
              element={
                <PrivateRoute subModule="Item" permission="Read">
                  <ItemDetail />
                </PrivateRoute>
              }
            />
            {/* Payment Gateway */}
            <Route
              path="/settings/payment-gateway"
              element={
                <PrivateRoute subModule="Item" permission="Read">
                  <PaymentGatewayConfig />
                </PrivateRoute>
              }
            />
            {/* SMTP Configuration */}
            <Route
              path="/settings/smtps-config"
              element={
                <PrivateRoute subModule="Item" permission="Read">
                  <SmtpServerConfig />
                </PrivateRoute>
              }
            />
            <Route path="/settings/export-data" element={<ExportData />} />
            {!vatEnabled && (
              <Route
                path="/settings/taxes/tds"
                element={
                  <PrivateRoute subModule="TDS" permission="Read">
                    <TDSList />
                  </PrivateRoute>
                }
              />
            )}
            {!vatEnabled && (
              <Route
                path="/settings/taxes/tcs"
                element={
                  <PrivateRoute subModule="TCS" permission="Read">
                    <TCSList />
                  </PrivateRoute>
                }
              />
            )}
            {gstEnabled === "true" ? (
              <>
                <Route
                  path="/settings/taxes/gst-config"
                  element={
                    <PrivateRoute subModule="GST" permission="Read">
                      <GstSettings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings/taxes/default-preference"
                  element={
                    <PrivateRoute subModule="GST" permission="Read">
                      <DefaultPreference />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings/gst-tax-rates"
                  element={
                    <PrivateRoute subModule="GST" permission="Read">
                      <TaxRateList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings/taxes/tax-exemption"
                  element={
                    <PrivateRoute subModule="GST" permission="Read">
                      <TaxExemptionList />
                    </PrivateRoute>
                  }
                />
              </>
            ) : null}
            {vatEnabled ? (
              <Route
                path="/settings/taxes/vat-config"
                element={<VatSettings />}
              />
            ) : null}
            <Route
              path="/sundry-debtor-aging-summary"
              element={
                <PrivateRoute
                  subModule="Sundry Debtor Aging Summary"
                  permission="Read"
                >
                  <SundryDebtorSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="/sundry-debtor-aging-details"
              element={
                <PrivateRoute
                  subModule="Sundry Debtor Aging Details"
                  permission="Read"
                >
                  <SundryDebtorDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/sundry-creditor-aging-summary"
              element={
                <PrivateRoute
                  subModule="Sundry Creditor Aging Summary"
                  permission="Read"
                >
                  <SundryCreditorSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="/sundry-creditor-aging-details"
              element={
                <PrivateRoute
                  subModule="Sundry Creditor Aging Details"
                  permission="Read"
                >
                  <SundryCreditorDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendor-balance"
              element={
                <PrivateRoute subModule="Vendor Balance" permission="Read">
                  <VendorBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendor-balance-details"
              element={
                <PrivateRoute subModule="Vendor Balance" permission="Read">
                  <VendorBalanceDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer-balance"
              element={
                <PrivateRoute subModule="Customer Balance" permission="Read">
                  <CustomerBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer-balance-details"
              element={
                <PrivateRoute subModule="Customer Balance" permission="Read">
                  <CustomerBalanceDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/receivable-summary"
              element={
                <PrivateRoute subModule="Receivable Summary" permission="Read">
                  <ReceivableSummaryReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/payable-summary"
              element={
                <PrivateRoute subModule="Payable Summary" permission="Read">
                  <PayableSummaryReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/variance-report"
              element={
                <PrivateRoute subModule="Budget" permission="Read">
                  <BudgetVarianceReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/summary-report"
              element={
                <PrivateRoute subModule="Budget" permission="Read">
                  <BudgetSummaryReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons"
              element={
                <PrivateRoute subModule="Coupons" permission="Read">
                  <CouponList />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons/create"
              element={
                <PrivateRoute subModule="Coupons" permission="Create">
                  <CouponForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons/detail/:couponDetailId"
              element={
                <PrivateRoute subModule="Coupons" permission="Read">
                  <CouponDetailBody />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons/edit/:editId"
              element={
                <PrivateRoute subModule="Coupons" permission="Update">
                  <CouponForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons/clone/:editId"
              element={
                <PrivateRoute subModule="Coupons" permission="Create">
                  <CouponForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan"
              element={
                <PrivateRoute subModule="Plans" permission="Read">
                  <PlanList />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan/create"
              element={
                <PrivateRoute subModule="Plans" permission="Create">
                  <PlanForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan/detail/:planDetailId"
              element={
                <PrivateRoute subModule="Plans" permission="Read">
                  <PlanDetailBody />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan/edit/:editId"
              element={
                <PrivateRoute subModule="Plans" permission="Update">
                  <PlanForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan/price/create/:planId/:frequency/:currencyId/:currencyCode"
              element={
                <PrivateRoute subModule="Plans" permission="Create">
                  <PriceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan/price/edit/:planId/:frequency/:priceId/:currencyId/:currencyCode"
              element={
                <PrivateRoute subModule="Plans" permission="Update">
                  <PriceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/addons"
              element={
                <PrivateRoute subModule="Addons" permission="Read">
                  <AddonList />
                </PrivateRoute>
              }
            />
            <Route
              path="/addons/detail/:addonDetailId"
              element={
                <PrivateRoute subModule="Addons" permission="Read">
                  <AddonDetailBody />
                </PrivateRoute>
              }
            />
            <Route
              path="/addons/create"
              element={
                <PrivateRoute subModule="Addons" permission="Create">
                  <AddonForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/addons/edit/:editId"
              element={
                <PrivateRoute subModule="Addons" permission="Update">
                  <AddonForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/addons/price/create/:addonId/:frequency/:currencyId/:currencyCode"
              element={
                <PrivateRoute subModule="Addons" permission="Create">
                  <AddonPriceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/addons/price/edit/:addonId/:frequency/:priceId/:currencyId/:currencyCode"
              element={
                <PrivateRoute subModule="Addons" permission="Update">
                  <AddonPriceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscriptions"
              element={
                <PrivateRoute subModule="Subscriptions" permission="Read">
                  <SubscriptionList />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscriptions/create"
              element={
                <PrivateRoute subModule="Subscriptions" permission="Create">
                  <SubscriptionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscriptions/detail/:subscriptionId"
              element={
                <PrivateRoute subModule="Subscriptions" permission="Read">
                  <SubscriptionView />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscriptions/edit/:editId"
              element={
                <PrivateRoute subModule="Subscriptions" permission="Update">
                  <SubscriptionForm />
                </PrivateRoute>
              }
            />
            {/* Charges */}
            <Route
              path="/charges"
              element={
                <PrivateRoute subModule="Charges" permission="Read">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ChargesList />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/charges/create"
              element={
                <PrivateRoute subModule="Charges" permission="Create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ChargesForm />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/charges/detail/:chargesId"
              element={
                <PrivateRoute subModule="Charges" permission="Read">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ChargesPreview />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/charges/edit/:editId"
              element={
                <PrivateRoute subModule="Charges" permission="Update">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ChargesForm />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route path="search" element={<Search />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        ) : (
          ""
        )}
      </Routes>
      <Outlet />
    </div>
  );
}

export default App;
