import { Api } from "../../../constants";
import { get, post, put } from "../../../helpers/apiHelper";
import { SmtpServerConfigUpdateValues } from "./types";

/**
 * API call for updating smtp config
 */
export async function smtpServerConfigUpdate(data: {
  orgId: number;
  values: {
    encryption_type: string;
    is_active: boolean;
    smtp_server: string;
    port: number | string;
    username: string;
    password: string;
  };
}) {
  const result = await put(
    Api.notification + `smtp/configuration?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for getting smtp config
 */
export async function smtpServerConfigFetch(data: { orgId: number }) {
  const result = await get(
    Api.notification + `smtp/configuration?current_organization=${data.orgId}`
  );
  return result;
}
