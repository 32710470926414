import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  JournalLineItemCalc,
  JournalValues,
  systemVoucherConfigurations,
  systemVoucherConfigurationsPayload,
} from "../../../types";
/**
 * API call for getting all system vouchers.
 */
export async function getSystemVoucherList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  orgIds: number[];
  statusList: string[];
  tagList: string[];
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
  voucherConfigIds?: number[];
}) {
  let url =
    Api.journalVoucher +
    "journal/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&voucher_type=SV&transaction_name=System%20Voucher&current_organization=${
      data.orgId
    }${data.startDate ? `&start_date=${data.startDate.trim()}` : ""}${
      data.endDate ? `&end_date=${data.endDate.trim()}` : ""
    }${data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""}${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  if (data.tagList.length !== 0) {
    const tags = data.tagList
      .map((tags) => {
        return `&tag_list=${tags}`;
      })
      .join("");
    url += tags;
  }
  if (
    data.voucherConfigIds?.length !== 0 &&
    Array.isArray(data.voucherConfigIds)
  ) {
    const voucher_config_id = data.voucherConfigIds
      .map((voucher_config_ids) => {
        return `&voucher_config_ids=${voucher_config_ids}`;
      })
      .join("");
    url += voucher_config_id;
  }
  const result = await get(url);
  return result;
}
/**
 * API call for deleting system voucher
 */
export async function deleteSystemVoucher(data: {
  systemVoucherId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      "journal/delete" +
      `/${data.systemVoucherId}?current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}
/**
 * API call for creating system voucher
 */
export async function createNewSystemVoucher(data: {
  values: JournalValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.journalVoucher +
      `journal-voucher/create?journal_status=${data.status}&current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.values
  );
  return result;
}
/**
 * API call for edit system voucher
 */
export async function editSystemVoucher(data: {
  id: number;
  values: JournalValues;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.journalVoucher +
      "journal/edit/" +
      data.id +
      `?journal_status=${data.status}&current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.values
  );
  return result;
}
/**
 * API call for delete system voucher item
 */
export async function deleteSystemVoucherItem(data: {
  systemVoucherId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      "journal-item/delete/" +
      data.systemVoucherId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}
/**
 * API call for delete system voucher recipients
 */
export async function deleteSystemVoucherRecipient(data: {
  systemVoucherId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      "journal-recipient/delete/" +
      data.systemVoucherId +
      `?current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get system voucher number
 */
export async function getSystemVoucherNumber(data: {
  orgId: number;
  prefix?: string;
}) {
  let url = `new-journal-voucher-number?current_organization=${data.orgId}&voucher_type=SV&transaction_name=System%20Voucher`;
  if (data?.prefix) {
    url = url + `&voucher_prefix=${data.prefix}`;
  }
  const result = await get(Api.journalVoucher + url);
  return result;
}
/**
 * API call for checking the system number exist or not
 */
export async function systemVoucherNumberExist(data: {
  systemVoucherNumber: string;
  orgId: number;
  systemVoucherId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      "journal-voucher-number-available?journal_voucher_number=" +
      data.systemVoucherNumber +
      `&journal_voucher_id=${data.systemVoucherId}&current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}
/**
 * API call for getting system voucher detail
 */
export async function getSystemVoucherDetails(data: {
  systemVoucherId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      `journal/detail/${data.systemVoucherId}?current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}
/**
 * API call for calculating system voucher amount
 */
export async function calculateAmount(data: {
  currencyId: number;
  values: JournalLineItemCalc[];
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.journalVoucher +
      `journal/amount-calculation?current_organization=${data.orgId}&currency_id=${data.currencyId}&transaction_name=System%20Voucher`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to upload system voucher attachments
 */
export async function systemVoucherAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.journalVoucher +
      `journal-attachments?current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.file
  );
  return result;
}
/**
 * Api to delete system attachments
 */
export async function systemVoucherAttachmentsDelete(data: {
  systemVoucherId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      `journal-attachments/${data.systemVoucherId}?file_id=${data.fileId}&transaction_name=System%20Voucher&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get system pdf link
 */
export async function systemVoucherPdfLink(data: {
  systemVoucherId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      "download-link?journal_voucher_id=" +
      data.systemVoucherId +
      `&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for sending system voucher to contact details
 */
export async function sendSystemVoucher(data: {
  systemVoucherId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.journalVoucher +
      `send-journal-voucher?journal_voucher_id=${data.systemVoucherId}&current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.emails
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.journalVoucher +
      `transaction-limit-status?current_organization=${orgId}&transaction_name=System%20Voucher`
  );
  return result;
}

/**
 * Api to get system for reporting tags
 */
export async function getReportingTagSystemVoucher(data: {
  systemVoucherId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      `reporting-tag/journal-generate/${data.systemVoucherId}?current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  systemVoucherId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.journalVoucher +
      `reporting-tag/edit/${data.systemVoucherId}?journal_voucher_id=${data.systemVoucherId}&current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.payload
  );
  return result;
}

/**
 * Api to get list of system voucher config
 */
export async function getSystemVoucherConfig(data: {
  orgId: number;
  isIntegrated: boolean;
}) {
  const result = await get(
    Api.journalVoucher +
      `system_voucher/config/list?current_organization=${
        data.orgId
      }&transaction_name=System%20Voucher${
        data.isIntegrated === false
          ? "&is_integrated=false"
          : "&is_integrated=true"
      }`
  );
  return result;
}

/**
 * Api to check system voucher name exists
 */
export async function systemVoucherNameExists(data: {
  systemVoucherId?: string | number;
  systemVoucherName: string;
  orgId: number;
}) {
  let url =
    Api.journalVoucher +
    `system_voucher/system-voucher-name-exist?system_voucher_name=${data.systemVoucherName}&current_organization=${data.orgId}&transaction_name=System%20Voucher`;
  if (data.systemVoucherId) {
    url += `&system_voucher_config_id=${data.systemVoucherId}`;
  }
  const result = await get(url);
  return result;
}

/**
 * Api to check system voucher prefix exists
 */
export async function systemVoucherPrefixExists(data: {
  systemVoucherId?: string | number;
  prefix: string;
  orgId: number;
}) {
  let url =
    Api.journalVoucher +
    `system_voucher/system-voucher-prefix-exist?prefix=${data.prefix}&current_organization=${data.orgId}&transaction_name=System%20Voucher`;
  if (data.systemVoucherId) {
    url += `&system_voucher_config_id=${data.systemVoucherId}`;
  }
  const result = await get(url);
  return result;
}

//Create System Voucher Configuration
export async function createNewSystemVoucherConfiguration(data: {
  values: systemVoucherConfigurationsPayload;
  orgId: number;
}) {
  const result = await post(
    Api.journalVoucher +
      `system_voucher/config/create?current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.values
  );
  return result;
}

//Get System Voucher Configuration Details
export async function getNewSystemVoucherConfigurationDetails(data: {
  systemVoucherId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      `system_voucher/config/detail/${data.systemVoucherId}?current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}

//edit ssystem voucher configuration
export async function updateSystemVoucherConfiguration(data: {
  systemVoucherId: number;
  values: systemVoucherConfigurationsPayload;
  orgId: number;
}) {
  const result = await put(
    Api.journalVoucher +
      `system_voucher/config/edit/${data.systemVoucherId}?current_organization=${data.orgId}&transaction_name=System%20Voucher`,
    data.values
  );
  return result;
}

//delete ssystem voucher configuration
export async function deleteSystemVoucherConfiguration(data: {
  systemVoucherId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      `system_voucher/config/delete/${data.systemVoucherId}?current_organization=${data.orgId}&transaction_name=System%20Voucher`
  );
  return result;
}
