import { DeleteImg, ErrorImg } from "../../../assets/images";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  DateFormatHandler,
  getProfileFormatedDate,
} from "../../../helpers/helper";
import { colors } from "../../constants/colors";
import { VoidIconBtn } from "./customSvgIcons/VoidIcon";
import "./void.css";
import useCommonData from "../../hooks/useCommon";

type Props = {
  className: string;
  deleteIcon: boolean;
  isMutable: boolean;
  onClick: () => void;
  isLocked: boolean;
  lockDate: string;
  name: string;
  assignedUserCount?: number;
  iconColor?: string;
  status: string;
  isButtonClick?: boolean;
  editCancelFromScheduler?: boolean;
};
export default function Void(props: Props) {
  const { currentUserInfo } = useCommonData();
  return (
    <Link
      className={`${
        props?.status.toLowerCase() === "cancelled" ? "disable-btn" : ""
      } ${props.className}`}
      style={
        props.editCancelFromScheduler
          ? { textDecoration: "none", paddingTop: "6px" }
          : { textDecoration: "none" }
      }
      to="#"
      data-bs-toggle={props.isButtonClick ? "" : "modal"}
      data-bs-target={
        props.isMutable && !props.isLocked && !props.assignedUserCount
          ? "#voidMessageModal"
          : !props.isMutable && !props.isLocked
          ? "#notificationPopupModal"
          : props.assignedUserCount
          ? "#voidConfMessageModal"
          : ""
      }
      onClick={(e) => {
        e.preventDefault();
        if (props.isLocked) {
          toast.error(
            `Transactions before ${getProfileFormatedDate(
              currentUserInfo.organization_date_format,
              props.lockDate
            )} have been locked. Hence action cannot be performed`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `void-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else if (
          !props.isMutable &&
          props.name !== "Customer" &&
          props.name !== "Vendor"
        ) {
          toast.error(
            `Unable to void ${props.name}, as there are transactions associated with it!`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `bill-void-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else {
          props.onClick();
        }
      }}
    >
      {!props.editCancelFromScheduler ? (
        <span className="d-flex align-items-center">
          {props.deleteIcon ? (
            <VoidIconBtn
              color={
                props?.status.toLowerCase() === "cancelled"
                  ? "#91919c"
                  : props.iconColor
              }
            />
          ) : null}
          Cancel
        </span>
      ) : (
        <span
          className="d-flex align-items-center"
          style={{ color: "#007bff" }}
        >
          Edit Cancel
        </span>
      )}
    </Link>
  );
}
