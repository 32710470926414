import dayjs from "dayjs";
import {
  ObjectType,
  TransactionItemValues,
  PurchaseOrderErrorFormValues,
} from "../../../../../types";
import {
  gstRegistrationTypes,
  ITEM_TYPES,
} from "../../../../constants/constants";

/**
 * Validate form values
 */
export const validate = (
  purchaseOrderFormValues: PurchaseOrderErrorFormValues,
  action: string,
  isBillNumberExist: boolean,
  itemFormValues: TransactionItemValues[],
  fileRef: React.MutableRefObject<any>,
  isGstOrg: boolean,
  gstRegistrationType: string
) => {
  const errors: ObjectType = {};
  if (!purchaseOrderFormValues.vendor_id) {
    errors.vendor_name = "Please enter vendor name";
  }
  if (!purchaseOrderFormValues.purchase_order_number.split("~")[1].trim()) {
    errors.purchase_order_number = "Please enter a valid purchase order number";
  }
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (
    purchaseOrderFormValues.issue_date &&
    purchaseOrderFormValues.issue_date === "Invalid date" &&
    !dateRegex.test(purchaseOrderFormValues.issue_date)
  ) {
    errors.issue_date = "Please enter valid bill date";
  }
  if (
    purchaseOrderFormValues.issue_date &&
    purchaseOrderFormValues.issue_date === "Future date"
  ) {
    errors.issue_date =
      "You have selected a future date. Please select a valid date.";
  }
  if (
    purchaseOrderFormValues.issue_date &&
    purchaseOrderFormValues.issue_date === "Old date"
  ) {
    errors.issue_date =
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.";
  }
  if (
    !purchaseOrderFormValues.delivery_date ||
    (purchaseOrderFormValues.delivery_date === "Invalid date" &&
      !dateRegex.test(purchaseOrderFormValues.delivery_date))
  ) {
    errors.delivery_date = "Please enter valid delivery date";
  }
  if (
    !purchaseOrderFormValues.delivery_date ||
    (purchaseOrderFormValues.delivery_date === "Old date" &&
      !dateRegex.test(purchaseOrderFormValues.delivery_date))
  ) {
    errors.delivery_date =
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.";
  }
  if (
    dayjs(new Date(purchaseOrderFormValues.delivery_date)).isValid() &&
    dayjs(new Date(purchaseOrderFormValues.delivery_date)).isBefore(
      dayjs(new Date(purchaseOrderFormValues.issue_date))
    ) &&
    !dayjs(new Date(purchaseOrderFormValues.delivery_date)).isSame(
      dayjs(new Date(purchaseOrderFormValues.issue_date))
    )
  ) {
    errors.delivery_date = "Please enter a date greater than issue date";
  }

  // validating state fields
  if (isGstOrg) {
    if (
      !purchaseOrderFormValues.source_of_supply?.value &&
      gstRegistrationType !== gstRegistrationTypes.overseas
    ) {
      errors.source_of_supply = "Please select source of supply";
    }
    if (!purchaseOrderFormValues.destination_of_supply?.value) {
      errors.destination_of_supply = "Please select destination of supply";
    }
  }

  if (Number(purchaseOrderFormValues.tds_percentage) > 100) {
    errors.tds_percentage = "TDS should be less than 100 %";
  }
  if (isBillNumberExist) {
    errors.purchase_order_number =
      "Purchase order number already exists. Please choose a different one";
  }
  if (
    Date.parse(purchaseOrderFormValues.issue_date) >
    Date.parse(purchaseOrderFormValues.delivery_date)
  ) {
    errors.issue_date = "Please enter valid bill date";
  }
  if (fileRef.current.fileIds.length > 3) {
    errors.attachFiles = "* Maximum File Limit Reached";
  }
  if (itemFormValues.length <= 0) {
    errors.items = "* Please add atleast one item";
  }
  if (
    Number(purchaseOrderFormValues?.tds_amount) !== 0 &&
    purchaseOrderFormValues?.tds_id === null
  ) {
    errors.tds_name = "Please select TDS type";
  }
  if (
    Number(purchaseOrderFormValues?.tcs_amount) !== 0 &&
    purchaseOrderFormValues?.tcs_id === null
  ) {
    errors.tcs_name = "Please select TCS type";
  }
  return errors;
};
export const validateItemValues = (
  values: TransactionItemValues,
  itemIndex: number,
  taxFormValues: TransactionItemValues[],
  isRcmEnabled: boolean,
  gstRegistrationType: string,
  discountAccountExcluded: boolean,
) => {
  const errors: ObjectType = {};
  let flag = false;
  if (!values.item_name) {
    errors.item_name = "Please select an item or enter a description";
    flag = true;
  }
  if (
    values.type === ITEM_TYPES.service &&
    gstRegistrationType === gstRegistrationTypes.deemedExp
  ) {
    errors.item_name =
      "Only goods can be associated in the Bill, if the Bill raised for the Deemed Export Supply";
    flag = true;
  }
  if (!values.quantity || values.quantity === 0) {
    errors.quantity = "Please enter quantity";
    flag = true;
  }
  if (!values.rate) {
    errors.rate = "Please enter rate";
    flag = true;
  }
  if (values.discount) {
    if (
      values.discount_type === "Absolute" &&
      Number(values.discount) > Number(values.rate) * Number(values.quantity)
    ) {
      errors.discount = "Discount cannot not be higher than rate * quantity";
      flag = true;
    } else if (
      values.discount_type === "Percentage" &&
      Number(values.discount) > 100
    ) {
      errors.discount = "Discount cannot not be higher than rate * quantity";
      flag = true;
    }
  }
  if (Number(values.tds_id) === 0) {
    if (Number(values.tds_amount) != 0) {
      errors.tds_name = "Please choose TDS type";
      flag = true;
    }
  } else if (Number(values.tds_id) !== 0) {
    if (values.tds_amount > values.rate) {
      errors.tds_amount = "TDS amount must not exceed total taxable amount";
      flag = true;
    }
  }

  if (!values.discount && values.discount_account_id) {
    errors.discount = "Please enter discount";
    flag = true;
  }
  if (values.discount && !values.discount_account_id && !discountAccountExcluded) {
    errors.discount_account_id = "Please choose discount account";
    flag = true;
  }

  if (!values.account_id) {
    errors.account_id = "Please enter account";
    flag = true;
  }
  if (values.hsn_or_sac_code === null) {
    values.hsn_or_sac_code = "";
  }
  let hsnRegex = /^\d{6}$|^\d{8}$/;
  if (
    values.hsn_or_sac_code &&
    !hsnRegex.test(values.hsn_or_sac_code.toString()) &&
    values.hsn_or_sac_code != ""
  ) {
    errors.hsn_or_sac_code = "Please enter valid hsn/sac code";
    flag = true;
  }
  if (isRcmEnabled) {
    if (!values.gst_tax?.tax_id) {
      errors.gst_tax_item = "Please specify a Tax for Reverse Charge";
      flag = true;
    }
  }
  if (taxFormValues[itemIndex]?.taxes) {
    let taxes = taxFormValues[itemIndex].taxes;
    let taxLabels: any[] = [];
    let taxError: any[] = [];
    for (let tax of taxes) {
      if (taxLabels.includes(tax.tax_id)) {
        flag = true;
        taxError.push({
          tax_id: "Tax label already used",
          tax_percentage: 0,
        });
      } else if (tax.tax_id === "" && Number(tax.tax_percentage) > 0) {
        flag = true;
        taxError.push({
          tax_id: "Please choose tax code",
          tax_percentage: 0,
        });
      } else {
        taxError.push({ tax_id: "", tax_percentage: 0 });
        if (tax.tax_id) {
          taxLabels.push(tax.tax_id);
        }
      }
    }
    errors.taxes = taxError;
  }
  if (flag) return errors;
  else return false;
};
