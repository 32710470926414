import React from "react";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import SendEmail from "../../../../../common/components/kebabMenu/SendEmail";
import DownloadPdf from "../../../../../common/components/kebabMenu/DownloadPdf";
import Delete from "../../../../../common/components/Delete";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { WriteOffModal } from "../../writeOffInvoice/WriteOffInvoiceModal";

type Props = {
  value: any;
  onClickSendMail: () => void;
  onClickDownloadPdf: () => void;
  onClickPayment: () => void;
  onClickDelete: () => void;
  write_off_values: {
    max_amount: number | null | undefined;
    currency_code: string | null | undefined;
  };
  fetchInvoiceList: () => Promise<void>;
};
function ListActions({
  value,
  onClickSendMail,
  onClickDownloadPdf,
  onClickPayment,
  onClickDelete,
  write_off_values,
  fetchInvoiceList,
}: Props) {
  const { invoicesRolePermission, paymentsReceivedRolePermission } =
    usePermissionHandler();
  return (
    <KebabMenu>
      {invoicesRolePermission.includes("Update") &&
      value.subscription_id === null ? (
        <li>
          <Edit
            url={`/invoice/edit/${value.id}`}
            isMutable={true} // change to true after implementing edit of closed and partially paid invoice.
            name="Invoice"
            isLocked={value.isLocked}
            className={"dropdown-item"}
            lockDate={value.lockDate}
            isFiscalClosed={value.is_fiscal_closed}
            isVoid={value.status === "Void"}
            isJvGenerated={value.is_deferred_associated}
          />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <>
          <li>
            <SendEmail onClick={onClickSendMail} />
          </li>
          <li>
            <DownloadPdf onClick={onClickDownloadPdf} />
          </li>
        </>
      ) : null}
      {invoicesRolePermission.includes("Delete") ? (
        <li>
          <Delete
            className="dropdown-item delete"
            deleteIcon={true}
            isMutable={value.isMutable}
            onClick={onClickDelete}
            isLocked={value.isLocked}
            lockDate={value.lockDate}
            name="Invoice"
            isFiscalClosed={value.is_fiscal_closed}
            isJvGenerated={value.is_deferred_associated ? true : false}
          />
        </li>
      ) : null}
      {(paymentsReceivedRolePermission.includes("Create") &&
        value.status !== "Draft" &&
        value.status !== "Closed") ||
      (value.status === "Closed" && value.writeOffId !== null) ? (
        <li>
          <WriteOffModal
            currency_code={value.currencyCode}
            invoice_amount={value.balanceDue}
            invoice_id={Number(value.id)}
            fetchInvoiceList={fetchInvoiceList}
            transactionDate={value.transactionDate}
            isVoid={value.status === "Void"}
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default React.memo(ListActions);
