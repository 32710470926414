import { ObjectType } from "../types";

export const customFieldsPayloadSetter = (customFields: ObjectType[]) => {
  const result = customFields.reduce((acc, item) => {
    acc[item.field_name] = {
      value: item.value,
      mandatory_field: item.mandatory_field,
      show_in_pdf: item.show_in_pdf,
    };
    return acc;
  }, {});
  return result;
};
