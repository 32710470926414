import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  CreditApply,
  InvoiceValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";

/**
 * API call for getting all invoices.
 */
export async function getInvoiceList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  customerList: number[];
  orgIds: number[];
  statusList: string[];
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
  invoiceType: string[];
}) {
  let url =
    Api.salesInvoice +
    Api.invoice +
    "/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.startDate ? `&start_date=${data.startDate.trim()}` : ""
    }${data.endDate ? `&end_date=${data.endDate.trim()}` : ""}${
      data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""
    }${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.customerList.length !== 0) {
    const customerIds = data.customerList
      .map((customerId) => {
        return `&customer_ids=${customerId}`;
      })
      .join("");
    url += customerIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  if (data.invoiceType.length != 0) {
    const invoiceType = data.invoiceType
      .map((type) => {
        return `&invoice_type_list=${type}`;
      })
      .join("");
    url += invoiceType;
  }
  const result = await get(url);
  return result;
}

/**
 * API call for deleting invoice
 */
export async function deleteInvoice(data: {
  deleteInvoiceId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      Api.invoice +
      "/delete" +
      `/${data.deleteInvoiceId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating invoice
 */
export async function createNewInvoice(data: {
  values: InvoiceValues;
  status: string;
  isSendMail: boolean;
  isPrintOrDownload: boolean;
  orgId: number;
}) {
  const result = await post(
    Api.salesInvoice +
      Api.invoice +
      `/create?invoice_status=${data.status}&is_send_mail=${data.isSendMail}&is_print_or_download=${data.isPrintOrDownload}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for edit invoice
 */
export async function editInvoice(data: {
  id: number;
  status: string;
  isSendMail: boolean;
  isPrintOrDownload: boolean;
  editInvoiceInputs: InvoiceValues;
  orgId: number;
}) {
  const recaculateFlag = data.editInvoiceInputs?.recalculate_exchange_rate
    ? data.editInvoiceInputs.recalculate_exchange_rate
    : false;
  const result = await put(
    Api.salesInvoice +
      Api.invoice +
      "/edit/" +
      data.id +
      `?invoice_status=${data.status}&is_send_mail=${data.isSendMail}&is_print_or_download=${data.isPrintOrDownload}&recalculate_exchange_rate=${recaculateFlag}&current_organization=${data.orgId}`,
    data.editInvoiceInputs
  );
  return result;
}
/**
 * API call for delete invoice item
 */
export async function deleteInvoiceItem(data: {
  invoiceId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      "invoice-item/delete/" +
      data.invoiceId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for delete invoice recipients
 */
export async function deleteInvoiceRecipient(data: {
  invoiceId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      "invoice-recipient/delete/" +
      data.invoiceId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get invoice number
 */
export async function getInvoiceNumber(orgId: number) {
  const result = await get(
    Api.salesInvoice + `new-invoice-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for checking the invoice number exist or not
 */
export async function invoiceNumberExist(data: {
  invoiceNumber: string;
  orgId: number;
  invoiceId: number | null;
  signal?: AbortSignal;
}) {
  const result = await get(
    Api.salesInvoice +
      `invoice-number-exist?invoice_number=${data.invoiceNumber}&invoice_id=${data.invoiceId}&current_organization=${data.orgId}`,
    data.signal
  );
  return result;
}
/**
 * API call for delete invoice item tax
 */
export async function deleteInvoiceItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      "invoice-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for getting invoice detail
 */
export async function getInvoiceDetails(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      Api.invoice +
      `/detail/${data.invoiceId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for sending invoice to contact details
 */
export async function sendInvoice(data: {
  invoiceId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.salesInvoice +
      `send-invoice?invoice_id=${data.invoiceId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API call for calculating taxable amount
 */
export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.salesInvoice +
      `invoice-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating invoice amount
 */
export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.salesInvoice +
      Api.invoice +
      `/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * Api to get invoice pdf link
 */
export async function invoicePdfLink(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      "download-link?invoice_id=" +
      data.invoiceId +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to upload invoice attachments
 */
export async function invoiceAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.salesInvoice + `invoice-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete invoice attachments
 */
export async function invoiceAttachmentsDelete(data: {
  invoiceId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      `invoice-attachments/${data.invoiceId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.salesInvoice + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to create write off against an invoice
 */
export async function writeOffCreate(data: {
  orgId: number;
  write_off_values: {
    amount: string | number;
    account_id: number | null;
    invoice_id: number | null;
    is_partial: boolean;
  };
}) {
  const result = await post(
    Api.salesInvoice + `write-off/create?current_organization=${data.orgId}`,
    data.write_off_values
  );
  return result;
}
/**
 * Api to update write off against an invoice
 */
export async function writeOffUpdate(data: {
  orgId: number;
  write_off_id: number | null;
  write_off_values: {
    amount: string | number;
    is_partial: boolean;
  };
}) {
  const result = await put(
    Api.salesInvoice +
      `write-off/edit/${data.write_off_id}?current_organization=${data.orgId}`,
    data.write_off_values
  );
  return result;
}
/**
 * Api to update write off against an invoice
 */
export async function writeOffDetails(data: {
  orgId: number;
  invoice_id: number | null;
}) {
  const result = await get(
    Api.salesInvoice +
      `write-off/details?invoice_id=${data.invoice_id}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to update write off against an invoice
 */
export async function writeOffDelete(data: {
  orgId: number;
  write_off_id: number | null;
}) {
  const result = await remove(
    Api.salesInvoice +
      `write-off/cancel/${data.write_off_id}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get apply credit list of invoice
 */
export async function applyCreditList(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      `apply-credits/list?invoice_id=${data.invoiceId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to calculate credits
 */
export async function creditsCalculation(data: {
  orgId: number;
  values: {
    invoice_balance: number;
    allocation_list: number[];
    currency_id: number | null;
  };
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.salesInvoice +
      `apply-credits/calculate?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to apply credits to invoice
 */
export async function applyCreditsToInvoice(data: {
  invoice_id: number;
  orgId: number;
  values: CreditApply[];
}) {
  const result = await put(
    Api.salesInvoice +
      `apply-credits/edit?invoice_id=${data.invoice_id}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api to get journal for reporting tags
 */
export async function getReportingTagJournals(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      `reporting-tag/journal-generate/${data.invoiceId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  invoiceId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.salesInvoice +
      `reporting-tag/edit/${data.invoiceId}?invoice_id=${data.invoiceId}&current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}

/**
 * Api to get tags which are associated to a particular invoice
 */
export async function getTagsOptionsAssociatedToInvoice(data: {
  invoiceIds: number[];
  orgId: number;
}) {
  const idsUrl = data.invoiceIds.map((id) => {
    return `invoice_ids=${id}`;
  });
  const result = await get(
    Api.salesInvoice +
      `reporting-tag/invoice/payment-association?current_organization=${
        data.orgId
      }${idsUrl.length > 0 ? `&${idsUrl.join("&")}` : ""}`
  );
  return result;
}

/**
 * Api to mark invoice as void
 */
export async function invoiceVoidMarker(data: {
  invoiceId: number | null;
  orgId: number;
}) {
  const result = await put(
    Api.salesInvoice +
      `invoice/mark-void/${data.invoiceId}?invoice_id=${data.invoiceId}&current_organization=${data.orgId}`
  );
  return result;
}
