import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../app/hooks";
import { JournalValues } from "../../../../../../types";
import { exchangeApiStatusSelector } from "../../../../../common/commonSlice";
import Delete from "../../../../../common/components/Delete";
import ErrorInfo from "../../../../../common/components/ErrorInfo";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import {
  TransactionEditTagRemovalAlertModal,
  useCommonData,
  useRef,
} from "../../../../paymentsReceived/components/paymentReceivedForm/imports";
import { FormikProps } from "formik";

type Props = {
  journalFormValues: JournalValues;
  editId: string | undefined;
  journalVoucherStatus: string;
  onClickSubmit: (status: string, action: string) => void;
  onClickDelete: () => void;
  isSubmit: boolean;
  accountAmountExist: boolean;
  isError: boolean;
  action: string;
  formik: FormikProps<any>;
};
function SystemVoucherFormFooter({
  journalFormValues,
  editId,
  journalVoucherStatus,
  onClickSubmit,
  onClickDelete,
  isSubmit,
  accountAmountExist,
  isError,
  action,
  formik,
}: Props) {
  const { currentUserInfo } = useCommonData();
  const { journalVoucherRolePermission } = usePermissionHandler();
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const openEditAlertRef = useRef<any>();
  return (
    <div className="row mb-0">
      <div className="col">
        
        <div
          className={
            isSubmit
              ? "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          {isError ? (
            <div className="form-error-container w-100 flex-column">
              <ErrorInfo
                message={`Oops, we were unable to ${action}. Please fix the errors.`}
              />
            </div>
          ) : null}
          <div className="form-btn-container d-flex w-100">
            <div className="btn-group save-only-btn-group ps-3 ms-1">
            {JSON.stringify(formik.values.is_cash_enabled,formik.values.write_off_cancelled_jv)}
              <button
                type="button"
                className="save-button mx-0"
                onClick={() => {
                  // if (
                  //   editId &&
                  //   formik.initialValues.is_cash_based === false &&
                  //   currentUserInfo.is_tags_enabled &&
                  //   formik.values.write_off_cancelled_jv !== true
                  // ) {
                  //   openEditAlertRef.current?.openAlertModal &&
                  //     openEditAlertRef.current?.openAlertModal();
                  // } else {
                    onClickSubmit("Published", "Send");
                  // }
                }}
                disabled={isSubmit && accountAmountExist ? false : true}
              >
                Save and Publish
              </button>
            </div>

            <button
              type="button"
              className={`cancel-button save-as-draft-button ${
                (editId && journalVoucherStatus !== "Draft") ||
                exchangeRateAPiStatus ||
                !accountAmountExist
                  ? "disabled-item"
                  : ""
              }`}
              onClick={() => onClickSubmit("Draft", "")}
              disabled={
                (editId && journalVoucherStatus !== "Draft") ||
                exchangeRateAPiStatus ||
                !accountAmountExist
                  ? true
                  : false
              }
              style={
                (editId && journalVoucherStatus !== "Draft") ||
                exchangeRateAPiStatus
                  ? { opacity: 0.5 }
                  : {}
              }
            >
              Save as Draft
            </button>
            <Link
              to="/system-voucher"
              className="text-decoration-none cancel-button mx-4"
            >
              Cancel
            </Link>
            {editId && journalVoucherRolePermission.includes("Delete") ? (
              <Delete
                className={"text-decoration-none delete-item-button ml-auto"}
                deleteIcon={false}
                isMutable={true}
                onClick={onClickDelete}
                isLocked={journalFormValues.is_locked}
                lockDate={journalFormValues.lock_date}
                name="Journal Voucher"
              />
            ) : null}
          </div>
        </div>
      </div>
      {/* <TransactionEditTagRemovalAlertModal
        module="System Voucher"
        onCancel={() => {
          formik.setSubmitting(false);
        }}
        onSubmit={() => {
          onClickSubmit("Published", "Send");
        }}
        openAlertRef={openEditAlertRef}
      /> */}
    </div>
  );
}

export default SystemVoucherFormFooter;
