import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Row } from "@tanstack/react-table";
import React from "react";
import { TextButton } from "../../../../../common/components/button";
import { IconButton } from "../../../../../common/components/button/IconButton";
import { TagIcon } from "../../../../../common/components/customSvgIcons/TagIcon";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Span } from "../../../../../common/components/htmlTags/Span";
import { colors } from "../../../../../constants/colors";
import { ProcessedRow } from "../types";
import useCommonData from "../../../../../hooks/useCommon";
import { getReportingTagsDataForBalanceSheet } from "../../../ReportSlice";

interface AccountNameCellProps {
  row: Row<ProcessedRow>;
  columnStyleProcessor: (
    row: Row<ProcessedRow>,
    columnName: string
  ) => React.CSSProperties | undefined;
  accTransactionNavHandler: (id: number, data: any, expanded: any) => void;
  expandedIds: React.MutableRefObject<any>;
  processNodeById: (
    id: string,
    items: any,
    newNode: any,
    shouldAppend: boolean,
    filterValues?: any
  ) => any;
  shouldAppend: React.MutableRefObject<boolean>;
  setData: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  jsonData: {
    currency_code: string;
    total_liabilities_equity: string;
    items: any[];
  };
  table: any;
  filterValues: any;
}

const AccountNameCell: React.FC<AccountNameCellProps> = ({
  row,
  columnStyleProcessor,
  accTransactionNavHandler,
  expandedIds,
  processNodeById,
  shouldAppend,
  setData,
  data,
  jsonData,
  table,
  filterValues,
}) => {
  const activeAccountId = localStorage.getItem("drill-down-account");
  const { dispatch, currentUserInfo } = useCommonData();

  const getReportinTagsData = async () => {
    const tagDataPromise = await dispatch(
      getReportingTagsDataForBalanceSheet({
        currencyId: filterValues.currency_id,
        accountId: Number(row.original.id),
        endDate: filterValues.end_date,
        optionIds: filterValues.tag_option_in,
        isIntegrated: filterValues.is_integrated,
        organizationId: filterValues.organization_id,
        currentOrganization: currentUserInfo.organization_id,
      })
    );
    const response = tagDataPromise.payload;
    response.map((item: any) => {
      item.is_debit = true;
      item.node = item.node.map((node: any) => ({
        ...node,
        is_debit: true,
      }));
      return item;
    });
    return response;
  };

  return (
    <Container style={columnStyleProcessor(row, "account_name")}>
      {(row.getCanExpand() &&
        row.original.is_tag &&
        row.original?.node &&
        row.original?.node?.length > 0) ||
      (row.getCanExpand() &&
        row.original?.node &&
        row.original?.node?.length > 0) ? (
        <TextButton
          type="button"
          fontColor={colors.battleshipGrey}
          fontSize="12px"
          fontWeight="bold"
          onClick={() => {
            row.getToggleExpandedHandler()();
          }}
          isDisabled={false}
          buttonContent={
            row.getIsExpanded() ? <ArrowDropDownIcon /> : <ArrowRightIcon />
          }
        />
      ) : null}
      {!["last-node", "total-node"].includes(row.original.style_class || "") &&
      !row.original.is_category &&
      row.original.transaction_exist ? (
        <TextButton
          buttonContent={row.getValue("account_name")}
          type="button"
          onClick={(e) => {
            if (
              !(row.original.is_category || !row.original.transaction_exist)
            ) {
              expandedIds.current = table.getState().expanded;
              accTransactionNavHandler(
                Number(row.original.id),
                filterValues,
                table.getState().expanded
              );
            }
          }}
          fontColor={
            row.original.style_class === "total-node"
              ? "var(--battleship-grey)"
              : "var(--charcoal-grey)"
          }
          fontSize={row.original.style_class === "total-node" ? "18px" : "16px"}
          isDisabled={false}
          wrapperClass={`${
            !(row.original.is_category || !row.original.transaction_exist)
              ? "hover-link-blue"
              : ""
          } ${
            Number(activeAccountId) === Number(row.original.id)
              ? "active-selected-account"
              : ""
          }`}
          fontWeight={
            ["last-node", "total-node"].includes(row.original.style_class || "")
              ? row.original.style_class === "total-node"
                ? "900"
                : "bold"
              : "normal"
          }
        />
      ) : (
        <Span>{row.getValue("account_name")}</Span>
      )}
      {row.original.is_tag_associated &&
        filterValues.tag_option_in?.length > 0 && (
          <IconButton
            type="button"
            isDisabled={false}
            fontSize={"16px"}
            onClick={async () => {
              const newNodeData = await getReportinTagsData();
              await setData(
                processNodeById(
                  String(row.original.id),
                  data,
                  newNodeData,
                  shouldAppend.current
                )
              );
              if (!row.getIsExpanded()) {
                row.getToggleExpandedHandler()();
              }
              shouldAppend.current = !shouldAppend.current;
            }}
          >
            <Span>
              <TagIcon color={colors.ceruleanBlue} margin="0px 0px 0px 0px" />
            </Span>
          </IconButton>
        )}
    </Container>
  );
};

export default AccountNameCell;
