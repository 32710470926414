import React, { useEffect, useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { SxProps } from "@mui/system";
import { getFormatedDate } from "../../../helpers/helper";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import "./datepicker.css";

type Props = {
  date: Date | null;
  type: string;
  error: string;
  id?: string;
  name?: string;
  zIndex?: number;
  inset?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  isOpen?: boolean;
  enableFutureDate?: boolean;
  isDisabled?: boolean;
  views?: any;
  customClass?: any;
  module?: string | null;
  handleDate: (date: Date | null, type: string) => void;
  openPicker?: () => void;
  closePicker?: () => void;
  onBlur?: (e: any) => void;
};

const CustomDatepicker = (props: Props) => {
  const [value, setValue] = React.useState<Date | null>();
  const transactionsToCheck = ["Invoice", "Bills"];
  const dateRef = useRef<any>(null);

  useEffect(() => {
    setValue(props.date);
    dateRef.current = props.date;
  }, [props.date]);

  const handleOpen = () => {
    if (props.openPicker) props.openPicker();
  };

  const handleClose = () => {
    if (
      getFormatedDate(dateRef.current || undefined) !==
        getFormatedDate(props.date || undefined) &&
      transactionsToCheck.includes(props.module || "")
    ) {
      props.handleDate(dateRef.current || null, props.type);
    }
    if (props.closePicker) props.closePicker();
  };
  const handleOnBlur = () => {
    if (
      getFormatedDate(dateRef.current || undefined) !==
        getFormatedDate(props.date || undefined) &&
      transactionsToCheck.includes(props.module || "")
    ) {
      props.handleDate(value || null, props.type);
    }
  };

  const dateHandleChange = (newValue: Date | null) => {
    setValue(newValue);
    dateRef.current = newValue;
    if (!transactionsToCheck.includes(props.module || "")) {
      props.handleDate(newValue, props.type);
    }
  };

  const sxProps: SxProps = {
    "&.MuiPickersPopper-root": {
      zIndex: props.zIndex,
      inset: props.inset,
    },
  };

  let minDate = undefined;
  if (props.minDate) {
    minDate = props.minDate;
  } else if (props.type === "expiryDate") {
    minDate = new Date();
  } else if (props.type === "journalVoucher") {
    // minDate = new Date(currentUserInfo.previous_fiscal_start_date);
  } else {
    // minDate = new Date(fiscalYearFilter.startDate);
  }

  return (
    <div className={`date-container ${props.error ? "error" : ""} ${props.id}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <DesktopDatePicker
            InputProps={{
              id: props.id,
              name: props.name || "",
              onBlur: (e: any) => {
                handleOnBlur();
                props.onBlur && props.onBlur(e);
              },
            }}
            inputFormat="dd/MM/yyyy"
            value={value}
            open={props.isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            className={props.id}
            onChange={dateHandleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: props.placeholder,
                }}
                disabled={!!props.isDisabled}
              />
            )}
            minDate={minDate}
            maxDate={
              props.maxDate
                ? props.maxDate
                : props.enableFutureDate
                ? undefined
                : new Date()
            }
            PopperProps={props.zIndex !== 0 ? { sx: sxProps } : {}}
            disabled={props.isDisabled ? props.isDisabled : false}
            views={props.views ? props.views : ["year", "month", "day"]}
            PaperProps={{
              className: props.customClass,
            }}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatepicker;
