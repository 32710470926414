import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import { TAX_SYSTEM } from "../../../../../constants/constants";
import { BillList, Transactionlimit } from "../../../../../../types";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { useAppSelector } from "../../../../../../app/hooks";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { billList, getBillPdfLink, sendBillData } from "../../../billsSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import {
  fiscalYearFilterSelector,
  setLoaderState,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import {
  DateFormatHandler,
  StatusColor,
  downloadUsingURL,
  getProfileFormatedDate,
} from "../../../../../../helpers/helper";
import {
  billColumnValues,
  billDefaultValues,
  billMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../../hooks/useCommon";
import CustomTooltips from "../../../../../common/components/Tooltip";
import TableSetup from "../../../../../common/components/table/TableSetup";
import useListData from "../../../../../hooks/useListData";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import ListActions from "../ListActions";
import BillTable from "./BillTable";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, name: string) => void;
};
function BillTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const { billsRolePermission } = usePermissionHandler();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const [billData, setBillData] = useState<BillList[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Tags",
    "Terms",
  ]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const [billId, setBillId] = useState(0);
  const [emailList, setEmailList] = useState<string[] | []>([]);

  useImperativeHandle(
    ref,
    () => ({
      fetchBillList: fetchBillList,
    }),
    []
  );

  useEffect(() => {
    fetchTableCustomizationFields(
      "Bills",
      billDefaultValues,
      billMandatoryValues
    );
    fetchBillList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    // selectedFilterData,
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchBillList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    billColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    if (
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !selectedColumns.includes("Tax Deducted(TDS)")
    ) {
      hiddenItems.push("Tax Deducted(TDS)");
    }
    setHiddenColumns([...hiddenItems]);
  }, [billData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch all bills
   */
  const fetchBillList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const vendorList = selectedFilterData.hasOwnProperty("Vendors")
      ? selectedFilterData?.Vendors
      : [];
    const responseAction = await dispatch(
      billList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: vendorList,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        // endDate: fiscalYearFilter.endDate || "",
        // startDate: fiscalYearFilter.startDate || "",
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setBillData(response.bills);
        let length = response.bills.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    selectedFilterData,
    currentUserInfo.organization_id,
    // selectedFilterData.Status,    ,
    dueDateListFilter,
    dateListFilter,
  ]);
  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  const getBillColumnValues = () => {
    let newBillColumnValues = billColumnValues;
    if (
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !newBillColumnValues.includes("Tax Deducted(TDS)")
    ) {
      newBillColumnValues.push("Tax Deducted(TDS)");
    }
    return newBillColumnValues;
  };
  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    let columns = [
      {
        Header: "Bill Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Bill Number",
        accessor: "show.billId",
        sort: false,
        sortOrder: "",
        className: "bill_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Vendor Name",
        accessor: "show.vendorName",
        sort: false,
        sortOrder: "",
        className: "vend_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement="bottom"
              title={value?.length > 15 ? value : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "bill_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Due Date",
        accessor: selectedColumns.includes("Due Date")
          ? "show.dueDate"
          : "Due Date",
        sort: false,
        sortOrder: "",
        className: "due_date",
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Balance Due",
        accessor: "show.balanceDue",
        sort: false,
        sortOrder: "",
        className: "balance_due",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Bills"
            tableColumns={selectedColumns}
            customizeColumnValues={getBillColumnValues()}
            mandatoryColumns={billMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Bills",
                billDefaultValues,
                billMandatoryValues
              );
              fetchBillList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!billsRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id)}
              onClickDelete={() => {
                if (value.isMutable) {
                  props.deleteListData(value.id, value.listName);
                }
              }}
              fetchBillList={fetchBillList}
            />
          );
        },
      },
    ];
    if (
      currentUserInfo.organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM
    ) {
      let index = columns.length - 1;
      columns.splice(index - 1, 0, {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      });
    }
    return columns;
  }, [
    dateSortOrder,
    selectedColumns,
    hiddenColumns,
    currentUserInfo.organization_id,
    // billsRolePermission,
  ]);
  /**
   * set table row data
   */
  const data = billData
    ? billData.map((bill, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            bill.bill_date
          ),
          billId: joinTransactionNumber(bill.Bill_ID),
          referenceNumber: bill.reference_number,
          vendorName: bill.vendor_name,
          status: bill.bill_status,
          dueDate: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            bill.due_date
          ),
          totalTaxableAmount:
            getCurrencyCode(bill.currency_code) +
            " " +
            NumberFormat(
              Number(bill.total_taxable_amount),
              bill.currency_code ? bill.currency_code : "",
              orgCurrencyList
            ),
          amount:
            getCurrencyCode(bill.currency_code) +
            " " +
            NumberFormat(
              Number(bill.total),
              bill.currency_code ? bill.currency_code : "",
              orgCurrencyList
            ),
          tds:
            getCurrencyCode(bill.currency_code) +
            " " +
            NumberFormat(
              Number(bill.tax_deducted),
              bill.currency_code ? bill.currency_code : "",
              orgCurrencyList
            ),
          balanceDue: bill.balance_due
            ? getCurrencyCode(bill.currency_code) +
              " " +
              NumberFormat(
                Number(bill.balance_due),
                bill.currency_code ? bill.currency_code : "",
                orgCurrencyList
              )
            : getCurrencyCode(bill.currency_code) +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  bill.currency_code
                    ? bill.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          organization: bill.organization,
          actions: {
            id: bill.id,
            status: bill.bill_status,
            listName: bill.Bill_ID,
            emailTo: bill.email_to,
            isMutable: bill.is_mutable,
            organizationId: bill.organization_id,
            isLocked: bill.is_locked,
            lockDate: bill.lock_date,
            bill: bill,
            balanceDue: bill.balance_due,
            currencyCode: bill.currency_code,
            writeOffId: bill.write_off_id,
            transactionDate: bill.bill_date,
            is_fiscal_closed: bill.is_fiscal_closed,
          },
        },
      }))
    : [];
  const sortHandler = (column: string) => {
    if (column === "Bill Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendBillData({
        billId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchBillList();
      }
    }
    return responseAction;
  };
  /**
   * Set the contact email in a state of selected bill
   */
  const fetchContactEmail = (id: number, emailsList: any[]) => {
    let emails = emailsList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setBillId(id);
  };

  const downloadPdf = async (billId: number) => {
    const responseAction = await dispatch(
      getBillPdfLink({
        billId: billId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          billId + ".pdf"
        );
        if (result) {
          toast.success("Bill downloaded successfully!", {
            toastId: "bill-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  return (
    <>
      <BillTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        billsRolePermission={billsRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={billId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(BillTableContainer));
