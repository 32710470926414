import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVatSettings, saveVatSettings } from "./vatAPI";

export const fetchVatSettings = createAsyncThunk(
  "settings/vat",
  async (data: { orgId: number }) => {
    return await getVatSettings(data);
  }
);

export const vatSettingsSave = createAsyncThunk(
  "settings/vat/save",
  async (data: { orgId: number; values: any }) => {
    return await saveVatSettings(data);
  }
);
