import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InvoiceImage } from "../../../../../../assets/images";
import {
  DateFormatHandler,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  creditNoteColumnValues,
  creditNoteDefaultValues,
  creditNoteMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { CreditNoteList as CreditNoteListType } from "../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";
import EmptyList from "../../../../../common/components/EmptyList";
import Popover from "../../../../../common/components/Popover";
import Pagination from "../../../../../common/components/table/Pagination";
import Loader from "../../../../components/Loader";
import { creditNoteList } from "../../../../creditNote/creditNoteSlice";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
type Props = {
  isSameOrg: boolean;
};
function CNTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [creditNoteData, setCreditNoteData] = useState<CreditNoteListType[]>(
    []
  );
  const [totalCreditNotes, setTotalCreditNotes] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["actions"]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const customerId = editId ? editId : "";
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let creditNoteRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Credit Notes"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchCreditNoteList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalCreditNotes / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalCreditNotes, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    creditNoteColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [creditNoteData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });

  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Credit Notes",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response)?.length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = creditNoteDefaultValues;
      }
      creditNoteMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch all credit notes
   */
  const fetchCreditNoteList = async () => {
    setIsLoading(true);
    if (customerId) {
      const responseAction = await dispatch(
        creditNoteList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          orgIds: [],
          customerList: [Number(customerId)],
          statusList: [],
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);

        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setCreditNoteData(response.credit_notes);
          let length = response.credit_notes.length;
          setTotalCreditNotes(response.total);
          if (response.total !== 0)
            setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Credit Note Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Credit Note Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Credit Note Number",
        accessor: "show.creditnoteId",
        sort: false,
        sortOrder: "",
        className: "cr_note_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Invoice Number",
        accessor: selectedColumns.includes("Invoice Number")
          ? "show.invoice"
          : "Invoice Number",
        sort: false,
        sortOrder: "",
        className: "inv_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? (
            <div className="pills-wrapper">
              <Popover values={value} tag={false} />
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "cr_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Credit Note Balance",
        accessor: selectedColumns.includes("Credit Note Balance")
          ? "show.balance"
          : "Credit Note Balance",
        sort: false,
        sortOrder: "",
        className: "cr_note_balance",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "actions",
        accessor: "actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, selectedColumns, hiddenColumns]
  );
  /**
   * set table row data
   */
  const data = creditNoteData
    ? creditNoteData.map((creditNote, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            creditNote.credit_note_date
          ),
          creditnoteId: joinTransactionNumber(creditNote.credit_note_no),
          referenceNumber: creditNote.reference_number,
          invoice: creditNote.invoice_numbers.map((inv_num) => {
            return {
              id: inv_num.id,
              name: joinTransactionNumber(inv_num.name),
            };
          }),
          status: creditNote.credit_note_status,
          totalTaxableAmount:
            creditNote.currency_code +
            " " +
            NumberFormat(
              creditNote.total_taxable_amount,
              creditNote.currency_code,
              orgCurrencyList
            ),
          amount:
            creditNote.currency_code +
            " " +
            NumberFormat(
              creditNote.total,
              creditNote.currency_code,
              orgCurrencyList
            ),
          balance: creditNote.balance_due
            ? creditNote.currency_code +
              " " +
              NumberFormat(
                creditNote.balance_due,
                creditNote.currency_code,
                orgCurrencyList
              )
            : creditNote.currency_code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  creditNote.currency_code
                    ? creditNote.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
        },
        actions: {
          id: creditNote.id,
          status: creditNote.credit_note_status,
          listName: creditNote.credit_note_no,
          emailTo: creditNote.email_to,
          isMutable: creditNote.invoice_numbers.length > 0 ? false : true,
          redirectTo: `/customers/detail/${editId}`,
          consumerId: editId,
        },
      }))
    : [];
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalCreditNotes;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped w-100"
        columns={columns}
        data={data}
        url={"/credit-note"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={creditNoteRolePermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            creditNoteRolePermission.includes("Create")
              ? "Create new credit note!"
              : "No Credit notes found!"
          }
          subheading={
            creditNoteRolePermission.includes("Create")
              ? "Issue credits to your customers and apply them to invoices."
              : "Oops, There is no Credit note data available right now"
          }
          list="Credit Notes"
          alt="create credit note preview"
          buttonText="Create Credit Note"
          navigateLink={`/credit-note/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/customers/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            creditNoteRolePermission.includes("Create") && props.isSameOrg
          }
          icon={InvoiceImage}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}
      <div className="table-footer pagination-container w-100 clear-both">
        {totalCreditNotes > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CNTransactions;
