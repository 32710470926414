import React, { useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { currenciesSelector, currencyDropdown } from "../commonSlice";
import Select from "react-select";
import useCommonData from "../../hooks/useCommon";
import "./currencySelectMultiple.css";
import { SelectCustomStyleCustomerMultiselect } from "./SelectCustomStyleCustomerMultiselect";

interface CurrencyOptionType {
  value: number;
  label: string;
}
type Props = {
  selectedOptions: CurrencyOptionType[];
  label: string;
  onChange: (data: any) => void;
  isDisabled: boolean;
  dropPosition?: "auto" | "top" | "bottom";
  error: string;
  wrapperClass?: string;
};

function CurrencySelectMultiple(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const currencies = useAppSelector(currenciesSelector);

  useEffect(() => {
    if (currentUserInfo.organization_id) getCurrencyList();
  }, [currentUserInfo.organization_id]);
  /**
   * Fetch all currency list
   */
  const getCurrencyList = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };
  /**
   * Get the list of all currencies
   */
  const currencyListOptions = currencies.map((currency, index) => ({
    label: currency.code,
    value: currency.id,
  }));

  return (
    <>
      <label htmlFor="c_currency" className="required">
        {props.label}
      </label>
      <Select
        options={currencyListOptions}
        isMulti
        value={props.selectedOptions}
        onChange={(e) => {
          props.onChange(e);
        }}
        placeholder="Choose currency"
        styles={SelectCustomStyleCustomerMultiselect}
        isSearchable={true}
        isDisabled={props.isDisabled}
        menuPlacement={props.dropPosition ? props.dropPosition : "bottom"}
        className="multi-currency-select"
      />
      <span className="error">{props.error}</span>
    </>
  );
}

export default CurrencySelectMultiple;
