import { formatBudgetDateRange } from "./formatDateRanges";

export const processHeader = (headerId: string, headerName: string, budgetName: string) => {
 switch(headerId) {
    case "actual_range":
        return formatBudgetDateRange(headerName);
    case "budget_range":
        return `${formatBudgetDateRange(headerName)} ${budgetName}`;
    default:
        return headerName;
  }
};
    