import React from "react";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import SystemVoucherConfigurationForm from "./systemVoucherConfigurationForm/systemVoucherConfigurationForm";
import "./systemVoucherConfiguration.css";
import { ErrorToaster } from "../../../../../helpers/toastHelper";

type Props = {
  buttonContent: React.ReactNode;
  systemConfId?: number;
  handleRefreshList: () => void;
  buttonWrapperClass?: string;
  isAssociated?: boolean;
};

const SystemVoucherConfigurationModal = (props: Props) => {
  return (
    <>
      <SideModal
        modalTitle={"Add System Voucher List"}
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return (
                <>
                  <SystemVoucherConfigurationForm
                    systemVoucherTypeId={
                      props.systemConfId ? props.systemConfId : undefined
                    }
                    handleRefreshList={props.handleRefreshList}
                    handleModalClose={() => {
                      onCloseModal();
                    }}
                  />
                </>
              );
            }}
          </ModalContext.Consumer>
        }
        buttonContent={props.buttonContent}
        buttonClass={`no-appearance w-auto configure-system-voucher-btn ${
          props.buttonWrapperClass ? props.buttonWrapperClass : ""
        }`}
        isButtonDisabled={props.isAssociated}
        disabledActionFunction={() => {
          ErrorToaster(
            "Unable to edit this system voucher configuration as transactions are associated with it"
          );
        }}
      />
    </>
  );
};

export default SystemVoucherConfigurationModal;
