import { ExpandedState } from "@tanstack/react-table";
import useCommonData from "../../../../hooks/useCommon";

export const useDrillDown = () => {
  const { currentUserInfo, navigate } = useCommonData();
  /**
   * Navigation to account transaction page to view the transactions of a particular account
   */
  const accTransactionNavHandler = (
    accountId: number,
    profitAndLossData: any,
    expanded: ExpandedState
  ) => {
    let profitAndLossFilter = {
      currencyId: profitAndLossData.currency_id,
      dateRange: profitAndLossData.date_range,
      startDate: profitAndLossData.start_date,
      endDate: profitAndLossData.end_date,
      organizationId: profitAndLossData.organization_id,
      tag_option_in: profitAndLossData.tag_option_in,
      compare_list: profitAndLossData.compare_list,
      compare_with: profitAndLossData.compare_with,
      compare_value: profitAndLossData.compare_value,
      report_basis: profitAndLossData.report_basis,
      is_descending: profitAndLossData.is_descending,
      account_filter: profitAndLossData.account_filter,
      is_integrated: profitAndLossData.is_integrated,
    };
    localStorage.setItem(
      "drill-down-filter",
      JSON.stringify(profitAndLossFilter)
    );
    localStorage.setItem(
      "drill-down-report",
      JSON.stringify({ label: "Profit and Loss", url: "/profit-and-loss" })
    );
    localStorage.setItem("expanded-rows", JSON.stringify(expanded));
    localStorage.setItem("drill-down-account", accountId.toString());
    let accTransactionFilter = {
      accountId: accountId,
      currencyId: profitAndLossData.currency_id,
      dateRange: "custom",
      startDate: profitAndLossData.start_date,
      endDate: profitAndLossData.end_date,
      organizationId: profitAndLossData.organization_id,
      tag_option_in: profitAndLossData.tag_option_in,
      report_basis: profitAndLossData.report_basis,
      is_integrated: profitAndLossData.is_integrated,
    };
    navigate("/account-transactions", {
      state: { accTransactionFilter: accTransactionFilter },
    });
  };
  return { accTransactionNavHandler };
};
