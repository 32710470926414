import { ObjectType } from "../../../../../../types";

export const useGetRowsToBeExpanded = () => {
  let rowsToBeExpanded: { [key: string]: boolean } = {
    "0": true,
    "1": true,
    "2": true,
  };
  const getRowsToBeExpanded = () => {
    const expandedIdsString = localStorage.getItem("expanded-rows");
    const drillDownAccount =localStorage.getItem("drill-down-account")
    const expandedIds: ObjectType = expandedIdsString
      ? JSON.parse(expandedIdsString)
      : {};
    if (expandedIds && drillDownAccount) {
      rowsToBeExpanded = expandedIds;
    }
    return rowsToBeExpanded;
  };
  return { getRowsToBeExpanded, rowsToBeExpanded };
};
