import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../common/components/htmlTags/Form";
import { Container } from "../../../common/components/htmlTags/Container";
import { SaveButton, SecondaryButton } from "../../../common/components";
import { useFormik } from "formik";
import { InvoicingFrequencyConfig } from "../invoicingFrequencyConfig/InvoicingFrequencyConfig";
import {
  customFrequencySettingsSelector,
  fetchCustomFrequencySettings,
  setLoaderState,
} from "../../../common/commonSlice";
import { useAppSelector } from "../../../../app/hooks";
import { InvoicingFormModal } from "../invoicingFrequencyForm/InvoicingFormModal";
import useCommonData from "../../../hooks/useCommon";
import { updateCustomFrequencyHandler } from "../invoicingFrequencySlice";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { useEffect } from "react";

export const InvoicingFrequencyList = () => {
  const { dispatch, currentUserInfo } = useCommonData();

  useEffect(() => {
    dispatch(fetchCustomFrequencySettings(currentUserInfo.organization_id));
  }, [currentUserInfo.organization_id]);
  const amountInWordsConfig = useAppSelector(customFrequencySettingsSelector);

  const initialValues = {
    dateFormat: null,
    amountInWordsConfig: amountInWordsConfig,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      updateAmountInWordsConfig(values);
    },
  });

  const updateAmountInWordsConfig = async (values: any) => {
    const finalData = {
      orgId: currentUserInfo.organization_id,
      formData: values.amountInWordsConfig,
    };
    onFormSubmit(finalData);
  };

  const onFormSubmit = async (data: any) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(updateCustomFrequencyHandler(data));
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Invoicing frequency updated successfully!",
        "tags-config-succcess"
      );
      dispatch(fetchCustomFrequencySettings(currentUserInfo.organization_id));
    } else {
    }
    dispatch(setLoaderState(false));
  };

  return (
    <Card>
      <CardHeader
        headerActions={
          <Container className="d-flex w-100 reporting-tag-header-btn-group">
            <InvoicingFormModal />
          </Container>
        }
        wrapperClass="reporting-tag-list-header"
      >
        Invoicing Frequency
      </CardHeader>
      <CardBody>
        <Form
          name="configure-transaction-form"
          id="configure-transaction-form"
          className="preferences-settings-form configure-transaction-form w-100"
          onSubmit={() => {}}
        >
          <Container className="fields-wrapper">
            <section className="form-fields-section">
              {amountInWordsConfig && (
                <InvoicingFrequencyConfig
                  formik={formik}
                  amountInWordsConfig={formik.values.amountInWordsConfig}
                />
              )}
            </section>
          </Container>
          <section className="form-button-section">
            <Container
              className="button-fields form-button-wrapper"
              id="form-btn-gst-profile"
            >
              <SaveButton
                buttonClassName=""
                type="submit"
                buttonContent="Save"
                onClick={(e) => {
                  e.preventDefault();
                  formik.submitForm();
                }}
                isDisabled={false}
              />
              <SecondaryButton
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                buttonContent="Cancel"
                isDisabled={false}
                onClick={formik.handleReset}
              />
            </Container>
          </section>
        </Form>
      </CardBody>
    </Card>
  );
};
