import { Container } from "../../../../../common/components/htmlTags/Container";
import { Span } from "../../../../../common/components/htmlTags/Span";
import "../../../PaymentGateway.css";

type PaymentGatewayConfigViewProps = {
  publishableKey: string;
  secretKey: string;
  accountName: string;
};
export const PaymentGatewayConfigView = ({
  publishableKey,
  secretKey,
  accountName,
}: PaymentGatewayConfigViewProps) => {
  return (
    <Container>
      <Container className="payment-gateway-config-view-container">
        <Span className="payment-gateway-config-view-label">Publishable Key</Span>
        <Span className="payment-gateway-config-view-value">{"**************************"}</Span>
      </Container>
      <Container className="payment-gateway-config-view-container">
        <Span className="payment-gateway-config-view-label">Secret Key</Span>
        <Span className="payment-gateway-config-view-value">{"**************************"}</Span>
      </Container>
      <Container className="payment-gateway-config-view-container">
        <Span className="payment-gateway-config-view-label">Webhook Secret</Span>
        <Span className="payment-gateway-config-view-value">
          {"**************************"}
        </Span>
      </Container>
      <Container className="payment-gateway-config-view-container">
        <Span className="payment-gateway-config-view-label">Account Name</Span>
        <Span className="payment-gateway-config-view-value">{accountName}</Span>
      </Container>
    </Container>
  );
};
