import {
  JournalLineItemCalc,
  JournalValues,
  systemVoucherConfigurations,
} from "../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  calculateAmount,
  getJournalList,
  journalNumberExist,
  getJournalNumber,
  createNewJournalVoucher,
  getJournalDetails,
  deleteJournalItem,
  deleteJournalRecipient,
  editJournalVoucher,
  journalAttachments,
  sendJournal,
  journalAttachmentsDelete,
  deleteJournal,
  journalPdfLink,
  transactionLimitStatus,
  getReportingTagJournals,
  putReportingTags,
  getDeferredIncomeList,
} from "./journalVoucherAPI";

export const journalList = createAsyncThunk(
  "journal/List",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    orgIds: number[];
    statusList: string[];
    tagList: string[];
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getJournalList(data);
  }
);

export const deferredIncomeList = createAsyncThunk(
  "journal/List",
  async (data: {
    page?: number;
    itemsPerPage?: number;
    dateSortOrder: string;
    tag_list: string[];
    orgId: number;
    transaction_ids: number[];
    orgIds: number[];
    startDate?: string;
    endDate?: string;
    cumulativeIncome?: number;
    deferredIncomeBalance?:number;
  }) => {
    return await getDeferredIncomeList(data);
  }
);

export const deleteJournalDetails = createAsyncThunk(
  "delete/journal",
  async (data: { journalId: number; orgId: number }) => {
    return await deleteJournal(data);
  }
);

export const createJournalVoucher = createAsyncThunk(
  "create/journal-voucher",
  async (data: { values: JournalValues; status: string; orgId: number, listInJv?: boolean }) => {
    return await createNewJournalVoucher(data);
  }
);

export const editJournalDetails = createAsyncThunk(
  "edit/journal-voucher",
  async (data: {
    id: number;
    values: JournalValues;
    status: string;
    orgId: number;
  }) => {
    return await editJournalVoucher(data);
  }
);

export const deleteJournalItemDetails = createAsyncThunk(
  "delete/journal/item",
  async (data: { journalId: number; itemId: number; orgId: number }) => {
    return await deleteJournalItem(data);
  }
);

export const deleteJournalRecipientDetails = createAsyncThunk(
  "journal/recipient/delete",
  async (data: { journalId: number; emailIds: number[]; orgId: number }) => {
    return await deleteJournalRecipient(data);
  }
);

export const journalDetails = createAsyncThunk(
  "journalDetail",
  async (data: { journalId: number; orgId: number }) => {
    return await getJournalDetails(data);
  }
);

export const getNewJournalNumber = createAsyncThunk(
  "journal/number",
  async (orgId: number) => {
    return await getJournalNumber(orgId);
  }
);

export const checkJournalNumber = createAsyncThunk(
  "journal/number-exist",
  async (data: { journalNumber: string; orgId: number; journalId: number }) => {
    return await journalNumberExist(data);
  }
);

export const sendJournalData = createAsyncThunk(
  "send-journal",
  async (data: { journalId: number; emails: string[]; orgId: number }) => {
    return await sendJournal(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    currencyId: number;
    values: JournalLineItemCalc[];
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const getJournalLink = createAsyncThunk(
  "journal/pdf",
  async (data: { journalId: number; orgId: number }) => {
    return await journalPdfLink(data);
  }
);

export const attachFiles = createAsyncThunk(
  "journal/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await journalAttachments(data);
  }
);

export const deleteJournalFile = createAsyncThunk(
  "journal/file/delete",
  async (data: { journalId: number; fileId: number; orgId: number }) => {
    return await journalAttachmentsDelete(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "journal/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const generateJournal = createAsyncThunk(
  "journal-generate",
  async (data: { journalVoucherId: number; orgId: number }) => {
    return await getReportingTagJournals(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { journalVoucherId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);

const initialState = {};

export const journalSlice = createSlice({
  name: "journalManagement",
  initialState,
  reducers: {},
});

export default journalSlice.reducer;
