import React from "react";
import { CustomerItemValues } from "../../../types";
import CustomSearchSelect from "./CustomSearchSelect/CustomSearchSelect";
import { zeroDisplayFormat } from "../../../helpers/numberFormatHelper";
import useCommonData from "../../hooks/useCommon";
import { decimalPlaceOfCurrency } from "../../../helpers/decimalPlaceHelper";

type Props = {
  index: number;
  discount: string | number;
  discount_type: string;
  currencyCode: string | undefined;
  baseAccount: string;
  error: string;
  handleItemChange: (e: any, index: number) => void;
  item: CustomerItemValues;
  organizationId: number;
  handleItemAccountChange: (e: any, index: number) => void;
  itemFormErrors: CustomerItemValues | undefined;
  handleOnBlur: (name: string, amount: number, index: number) => void;
  dropdownDisabled?: boolean;
};

export default function Discount(props: Props) {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  return (
    <div
      className={
        props.error
          ? "error discount-selector d-flex w-100"
          : "discount-selector d-flex w-100"
      }
    >
      <input
        type="text"
        name="discount"
        id={"discount_" + props.index}
        placeholder={zeroDisplayFormat(
          decimalPlaceOfCurrency(
            props.currencyCode
              ? props.currencyCode
              : currentUserInfo.currency_code,
            orgCurrencyList
          )
        )}
        value={props.discount ? props.discount : ""}
        onChange={(e) => props.handleItemChange(e, props.index)}
        onBlur={() =>
          props.handleOnBlur(
            "discount",
            props.discount ? Number(props.discount) : 0,
            props.index
          )
        }
      />
      <CustomSearchSelect
        itemAccount={Number(props.item.discount_account_id)}
        itemAccountName={props.item.discount_account_name}
        organizationId={props.organizationId}
        handleItemAccountChange={(e) => {
          const data = {
            target: {
              name: "discount_account_id",
              value: e,
            },
          };
          props.handleItemChange(data, props.index);
        }}
        itemIndex={props.index}
        error={""}
        baseAccount={props.baseAccount}
        isDisabled={props.dropdownDisabled}
        className={props.dropdownDisabled ? "discount-selector-disabled" : ""}
      />
      <span className="error">
        {props.itemFormErrors && props.itemFormErrors.account_id}
      </span>
    </div>
  );
}
