import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { useCommonData } from "../../../../paymentsReceived/components/paymentReceivedForm/imports";
import { AccountNode } from "../../types";
import { formatBudgetDateRange } from "../../utils/formatDateRanges";
import { flattenAndAppend } from "../../../../budget/helper/proccessBudgetResponse";
import { message } from "../../../../../constants/messages";
import InfoBox from "../../../../../common/components/infoBox/InfoBox";
import "./BudgetSummaryReportTable.css"

const BudgetSummaryReportTable: React.FC<any> = ({
  budgetData,
  editableDateRanges,
  currency_code
}) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const Budgets = budgetData;
  const { profit_and_loss } = Budgets;
  const dynamicColumns =  Object?.keys(profit_and_loss)?.map((key) => ({
    accessorKey: key,
    header: formatBudgetDateRange(key) || "",
  }));

  const StaticCell: React.FC<{
    rangeKey: string;
    row: Row<AccountNode>;
  }> = ({ rangeKey, row }) => {
    const total = row.original.range?.[rangeKey]?.total;
    return <span>{row.original.is_category ? "-" : total !== undefined ? total : "-"}</span>;
  };

  const TotalCell: React.FC<{
    accountName: string;
  }> = ({ accountName }) => {
    const total = Budgets.account_total?.[accountName] || "-";
    return <span>{total}</span>;
  };

  const columns = useMemo(() => [
    {
      accessorKey: "account_name",
      header: "Account Name",
      cell: ({ row }: { row: Row<AccountNode> }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            borderRight: "1px solid red !important",
          }}
        >
          {row.original.account_name}
        </span>
      ),
    },
    ...dynamicColumns.map((key) => ({
      accessorKey: key.accessorKey,
      header: key.header,
      cell: ({ row }: { row: Row<AccountNode> }) => (
        <StaticCell rangeKey={key.accessorKey} row={row} />
      ),
    })),
    // {
    //   accessorKey: "total",
    //   header: "Total",
    //   cell: ({ row }: { row: Row<AccountNode> }) => (
    //     <TotalCell accountName={row.original.account_name} />
    //   ),
    //   },
  ], [budgetData]);

  const table = useReactTable({
    columns,
    data: useMemo(() => flattenAndAppend(Budgets, editableDateRanges), [Budgets, editableDateRanges]),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Container className="report-note-container">
        <InfoBox
          message={message(currency_code).budgetCurrencyInfo}
          className="report-note"
        />
      </Container>
      <Container className="fields-wrapper-budget-summary-report-table">
        <Container className="table-container">
          <table className="budget-entry-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      style={{
                        textTransform: "uppercase",
                        color: "var(--battleship-grey) !important",
                        fontSize: "12px !important",
                        textAlign:
                          header.id === "account_name" ? "left" : "right",
                        position: header.id === "account_name" ? "sticky" : "static",
                        left: header.id === "account_name" ? "0" : "auto",
                        backgroundColor:
                          index === headerGroup.headers.length - 1
                            ? ""
                            : index > budgetData.actualColumns
                            ? "var(--new-whisper)"
                            : "",
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        minWidth:
                          cell.column.id === "account_name" ? "250px" : "200px",
                        width:
                          cell.column.id === "account_name" ? "250px" : "auto",
                        position: cell.column.id === "account_name" ? "sticky" : "static",
                        left: cell.column.id === "account_name" ? "0" : "auto",
                        border:
                          cell.column.id === "account_name"
                            ? "1px solid red !important"
                            : "1px solid #f2f2f2",
                        fontWeight:
                          row.original.style_class === "total-nodes" ||
                          row.original.depth === 1
                            ? "bold"
                            : "normal",
                        backgroundColor:
                          row.original.style_class === "total-nodes"
                            ? "#f2f2f2"
                            : "white",
                        textAlign:
                          cell.column.id === "account_name" &&
                          row.original.style_class !== "total-nodes"
                            ? "left"
                            : "right",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </Container>
    </>
  );
};

export default BudgetSummaryReportTable;
