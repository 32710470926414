import { useState } from "react";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Container } from "../../../../common/components/htmlTags/Container";
import { PaymentGateWayMapping } from "../paymentGatewayMapping/PaymentGatewayMapping";
import { PaymentGatewayConfigForm } from "./stripe/PaymentGatewayConfigForm";
import { PaymentGatewayConfigView } from "./stripe/PaymentGatewayConfigView";
import { usePaymentGateway } from "../../hooks/usePaymentGateway";
import { PrimaryButton } from "../../../../common/components/button/PrimaryButton";
import { Form } from "../../../../common/components/ui";
import "../../PaymentGateway.css";

export const PaymentGatewayConfig = () => {
  const [isSetup, setIsSetup] = useState(false);
  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    isValid,
    validateForm,
    setFieldTouched,
  } = usePaymentGateway({setIsSetup:setIsSetup, isSetup:isSetup});
  return (
    <Card wrapperClass="payment-gateway-config-card">
      <CardHeader>Payment Gateway</CardHeader>
      <Form name="">
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--warm-white)",
            borderRadius: "5px",
            padding: "30px 30px",
            backgroundColor: "var(--white-fill)",
            boxShadow: "0 8px 20px 0 var(--black-shadow3)",
          }}
        >
          <Container style={{ padding: "0px 15px" }}>
            <PaymentGateWayMapping
              setIsSetup={setIsSetup}
              isSetup={isSetup}
              values={values}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
            />
          </Container>

          <Container
            style={{
              width: "100%",
              height: "100%",
              padding: "30px",
              border:
                isSetup || values?.selected_payment_gateway?.is_active
                  ? "1px solid #e0e0e0"
                  : "none",
              borderRadius: "5px",
              marginTop: "50px",
            }}
          >
            {values?.selected_payment_gateway?.is_active && !isSetup && (
              <PaymentGatewayConfigView
                publishableKey={values?.selected_payment_gateway?.api_key}
                secretKey={values?.selected_payment_gateway?.api_secret}
                accountName={
                  values?.selected_payment_gateway?.chart_of_account_name
                }
              />
            )}

            {isSetup && (
              <PaymentGatewayConfigForm
                setIsSetup={setIsSetup}
                values={values?.selected_payment_gateway}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched?.selected_payment_gateway || {}}
                errors={errors?.selected_payment_gateway || {}}
                handleChange={handleChange}
                handleSubmit={async () => {

                  handleSubmit();
                }}
                isSetup={isSetup}
                isValid={isValid}
                validateForm={validateForm}
              />
            )}
          </Container>
        </Container>{" "}
      </Form>
    </Card>
  );
};
