import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Row } from "react-table";
import { useAppSelector } from "../../../../app/hooks";
import {
  DateFormatHandler,
  StatusColor,
  getProfileFormatedDate,
  handleNegativeValue,
} from "../../../../helpers/helper";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { ObjectType, TableData } from "../../../../types";
import { orgCurrencyListSelector } from "../../../common/commonSlice";
import EmptyReport from "../../../common/components/EmptyReport";
import DetailsTableContainer from "../../../common/components/detailsPage/DetailsTableContainer";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import useCommonData from "../../../hooks/useCommon";
import "../Reports.css";

type Props = {
  payableSummaryData: any;
  filterString?: string;
};
export const PayableSummaryTable = (props: Props) => {
  const { currentUserInfo } = useCommonData();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const { subModulesWithPermission } = usePermissionHandler();
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: "Vendor",
        accessor: "show.vendorName",
        className: "column-navigation consumer-name",
      },
      {
        Header: "Organization",
        accessor:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organization"
            : "Organization",
        className: "org",
      },
      {
        Header: "Date",
        accessor: "show.date",
        className: "trans-date text-nowrap",
      },
      {
        Header: "Transaction#",
        accessor: "show.transaction",
        className: "column-navigation text-nowrap transactions",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "column-navigation transaction-type",
      },
      {
        Header: "Reference #",
        accessor: "show.reference",
        className: "column-navigation text-nowrap reference",
      },
      {
        Header: "Status",
        accessor: "show.status",
        className: "column-navigation status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },

      {
        Header: "Total(Vendor Currency)",
        accessor: "show.totalVendorCurrency",
        className:
          "column-navigation text-align-right text-nowrap total-vendor-currency",
      },
      {
        Header: "Balance(Vendor Currency)",
        accessor: "show.balanceVendorCurrency",
        className:
          "column-navigation text-align-right text-nowrap balance-vendor-currency",
      },
      {
        Header: "Total",
        accessor: "show.total",
        className: "column-navigation text-align-right text-nowrap total",
      },
      {
        Header: "Balance",
        accessor: "show.balance",
        className: "column-navigation text-align-right text-nowrap balance",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    []
  );
  const data = props.payableSummaryData
    ? props.payableSummaryData.detail_list.map((reportData: ObjectType) => {
        return {
          show: {
            vendorName: reportData.vendor_name,
            organization: reportData.organization_name,
            date: getProfileFormatedDate(
              currentUserInfo.organization_date_format,
              reportData.transaction_date
            ),
            transaction: joinTransactionNumber(reportData.transaction_number),
            transactionType: reportData.transaction_type,
            reference: reportData.reference_number,
            status: reportData.transaction_status,
            totalVendorCurrency: reportData.total
              ? handleNegativeValue(
                  NumberFormat(
                    Number(reportData.total),
                    props.payableSummaryData.currency_code,
                    orgCurrencyList
                  ),
                  reportData.currency_code
                )
              : reportData.vendor_name === "Total"
              ? ""
              : "0",
            balanceVendorCurrency: reportData.balance
              ? handleNegativeValue(
                  NumberFormat(
                    Number(reportData.balance),
                    props.payableSummaryData.currency_code,
                    orgCurrencyList
                  ),
                  reportData.currency_code
                )
              : reportData.vendor_name === "Total"
              ? ""
              : "0",
            total: reportData.total_base
              ? handleNegativeValue(
                  NumberFormat(
                    Number(reportData.total_base),
                    props.payableSummaryData.currency_code,
                    orgCurrencyList
                  ),
                  props.payableSummaryData.currency_code
                )
              : "0",
            balance: reportData.balance_base
              ? handleNegativeValue(
                  NumberFormat(
                    Number(reportData.balance_base),
                    props.payableSummaryData.currency_code,
                    orgCurrencyList
                  ),
                  props.payableSummaryData.currency_code
                )
              : "0",
          },
          action:
            reportData.transaction_type === "Bill"
              ? {
                  transUrl: `/bill/detail/${reportData.transaction_id}${props.filterString}`,
                  transPermission: subModulesWithPermission.includes("Bills"),
                  userUrl: `/vendors/detail/${reportData.vendor_id}${props.filterString}`,
                  userPermission: subModulesWithPermission.includes("Vendor"),
                  customNavigation: true,
                }
              : reportData.transaction_type === "Debit Note"
              ? {
                  transUrl: `/debit-note/detail/${reportData.transaction_id}${props.filterString}`,
                  transPermission:
                    subModulesWithPermission.includes("Debit Notes"),
                  userUrl: `/vendors/detail/${reportData.vendor_id}${props.filterString}`,
                  userPermission: subModulesWithPermission.includes("Vendor"),
                  customNavigation: true,
                }
              : { url: "", permission: "" },
        };
      })
    : [];
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (cell.column.Header) {
      let action = row?.values?.action;
      if (
        cell.column.Header === "Transaction Type" ||
        cell.column.Header === "Transaction#" ||
        cell.column.Header === "Reference #" ||
        cell.column.Header === "Status" ||
        cell.column.Header === "Total(Vendor Currency)" ||
        cell.column.Header === "Balance(Vendor Currency)" ||
        cell.column.Header === "Total" ||
        cell.column.Header === "Balance"
      ) {
        if (action?.transUrl && action?.transPermission) {
          navigate(`?${props.filterString}`);
          navigate(action?.transUrl);
        }
      } else if (cell.column.Header === "Vendor") {
        if (action?.userUrl && action?.userPermission) {
          navigate(`?${props.filterString}`);
          navigate(action?.userUrl);
        }
      }
    }
  };
  return (
    <div className="table-wrapper">
      <DetailsTableContainer
        columns={columns}
        data={data}
        className={"table report-table payable-summary"}
        hiddenColumns={["action"]}
        haveColumnClassName={true}
        navigation={navigation}
      >
        <EmptyReport heading="No transactions found!" alt="Empty Report" />
      </DetailsTableContainer>
    </div>
  );
};
