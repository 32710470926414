import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../../common/components/htmlTags/Form";
import { svInitialValues } from "../helpers/StateInitialization";
import { systemVoucherList } from "../../systemVoucherSlice";
import { useSVFormSubmission } from "../hooks/useFormSubmission";
import { useSVFormIntitialization } from "../hooks/useJVFormInitialization";
import { SystemVoucherBasicDetails } from "./systemVoucherBasicDetails/components/SystemVoucherBasicDetails";
import { SystemVoucherFileUpload } from "./systemVoucherFileUpload/SystemVoucherFileUpload";
import { SystemVoucherValidation } from "./systemVoucherFormValidation/SystemVoucherFormValidation";
import { SystemVoucherSendEmail } from "./systemVoucherSendEmail/SystemSendEmail";
import useCommonData from "../../../../hooks/useCommon";
import SystemVoucherItemDetails from "./journalVoucherItemDetails/JournalItemMapping";
import SystemVoucherFormFooter from "./systemVoucherFormFooter/SystemVoucherFormFooter";
import DeleteSystemVoucher from "../Delete";

export const SystemVoucherForm = () => {
  const { editId } = useParams();
  const { dispatch, currentUserInfo } = useCommonData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let replace = searchParams.get("replace");
  const [initialValues, setInitialValues] = useState(svInitialValues);
  const { createSystemVoucherSubmit, updateSystemVoucherSubmit } =
    useSVFormSubmission();
  const { fetchSVDetails } = useSVFormIntitialization();
  useEffect(() => {
    if (editId) {
      fetchSVDetails(setInitialValues);
    }
  }, [editId]);
  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: SystemVoucherValidation,
    onSubmit: (values) => {
      if (editId) {
        updateSystemVoucherSubmit(
          values,
          formik.values.jv_status,
          formik.values.deleted_items,
          formik.values.delete_email_ids
        );
      } else {
        createSystemVoucherSubmit(
          values,
          formik.values.jv_status,
          formik.values.action,
          formik.values.list_in_jv,
        );
      }
    },
  });
  const customFieldRef = useRef<any>();
  const handleSVFormSubmit = async (status: string, action: string) => {
    const allFields = Object.keys(formik.values);
    const touchedFields: { [key: string]: boolean } = {}; // Explicitly define the type
    allFields.forEach((fieldName) => {
      touchedFields[fieldName] = true;
    });
    formik.setTouched(touchedFields);
    formik.setFieldValue("jv_status", status);
    formik.setFieldValue("action", action);
    // validating forms
    await customFieldRef.current.handleSubmit();
    formik.validateForm();
    if (
      Object.keys(formik.errors).length > 0 ||
      customFieldRef.current.errors
    ) {
      return;
    } else if (
      Object.keys(formik.errors).length === 0 &&
      !customFieldRef.current.errors
    ) {
      formik.submitForm();
    }
  };
  const fetchSustemVoucherListAfterDelete = async () => {
    const responseAction = await dispatch(
      systemVoucherList({
        page: 1,
        itemsPerPage: 10,
        dateSortOrder: "ASC",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        tagList: [],
        isIntegrated: false,
      })
    );
    replace ? navigate(-2) : navigate(-1);
  };
  return (
    <Card>
      <CardHeader transactionNumber={editId} isBackButtonEnabled={true}>
        {editId ? "Edit System Voucher" : "Create System Voucher"}
      </CardHeader>
      <CardBody>
        <Form
          name="add_invoice_form"
          id="add_invoice_form"
          className={`journal-voucher-form journal-voucher-form-wrapper add-module-item-form ${!formik.values?.system_config_id ? "inactive" : ""}`}
          tabIndex={-1}
          // onSubmit={formik.handleSubmit}
        >
          <SystemVoucherBasicDetails
            formik={formik}
            customFieldRef={customFieldRef}
          />
          <SystemVoucherItemDetails
            formik={formik}
            formErrors={formik.errors}
            currencyCode={formik.values.currency_code}
            currencyId={Number(formik.values.currency_id)}
            handleSetAccountAmount={() => {}}
            itemFormErrors={[]}
            itemFormValues={formik.values.item_details as any}
            dateTriggerFlag={0}
            currencyTriggerFlag={6}
            organizationId={currentUserInfo.organization_id}
          />
          <SystemVoucherFileUpload formik={formik} />
          <SystemVoucherSendEmail formik={formik} emailError="" />
          <SystemVoucherFormFooter
            onClickDelete={() => {}}
            onClickSubmit={handleSVFormSubmit}
            editId={editId}
            isError={false}
            isSubmit={true}
            accountAmountExist={formik.values.is_amount_equal}
            action=""
            journalVoucherStatus={formik.values.jv_status}
            journalFormValues={formik.values as any}
            formik={formik}
          />
        </Form>
      </CardBody>
      <DeleteSystemVoucher
        deleteSystemVoucherId={Number(editId)}
        deleteSystemVoucherItemId={formik.values.journal_voucher_number}
        page={1}
        itemsPerPage={10}
        dateSortOrder={"ASC"}
        refreshJournals={fetchSustemVoucherListAfterDelete}
        organizationId={currentUserInfo.organization_id}
      />
    </Card>
  );
};
