import React, { useEffect, useMemo, useState } from "react";
import {
  BillToAllocate,
  DebitNoteDetailValues,
  ObjectType,
  TableData,
} from "../../../../../types";
import { useParams } from "react-router-dom";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useCommonData from "../../../../hooks/useCommon";
import {
  DateFormatHandler,
  getProfileFormatedDate,
  splitNumberByDecimalPoint,
} from "../../../../../helpers/helper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { setLoaderState } from "../../../../common/commonSlice";
import {
  calculateDebitNoteAllocationAmount,
  getBillsToAllocate,
} from "../../debitNoteSlice";
import InputPrefix from "../../../../common/components/formPrefixInputField/InputPrefix";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import useForexCalculation from "../../../../hooks/useForexCalculation";
import { getForexGainOrLossAmount } from "../../../../../helpers/transactionHelper";

export const useBillAllocation = ({
  billId,
  debitNoteData,
}: {
  billId?: number;
  debitNoteData: DebitNoteDetailValues;
}) => {
  const { editId } = useParams();
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { subModulesWithPermission } = usePermissionHandler();
  const { getForexGainOrLoss } = useForexCalculation();
  const [allocationForex, setAllocationForex] = useState<ObjectType[]>([]);
  const [billToAllocate, setBillToAllocate] = useState<BillToAllocate[]>([]);
  const [debitBalance, setDebitBalance] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [allocationTableData, setAllocationTableData] = useState<TableData[]>(
    []
  );
  const [allocatedAmount, setAllocatedAmount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isFormValues, setIsFormValues] = useState(false);

  useEffect(() => {
    allocationData();
    const controller = new AbortController();
    if (debitNoteData.organization_id === currentUserInfo.organization_id) {
      const signal = controller.signal;
      calculateAllocationAmount(signal);
    }
    let flag = false;
    billToAllocate.map((billData) => {
      if (
        billData.amount_debited &&
        billData.amount_debited !== "" &&
        billData.amount_debited !== null &&
        billData.amount_debited !== 0
      ) {
        flag = true;
      }
    });
    if (flag) {
      setIsFormValues(true);
    } else {
      setIsFormValues(false);
    }
    return () => {
      controller.abort();
    };
  }, [billToAllocate, debitNoteData.currency_code]);

  useEffect(() => {
    allocationData();
  }, [allocationForex]);

  const allocationData = () => {
    setAllocationTableData(
      billToAllocate
        ? billToAllocate.map((bill, index) => ({
            show: {
              billNumber: joinTransactionNumber(bill.bill_number),
              billDate: getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                bill.bill_date
              ),
              billAmount:
                debitNoteData.currency_code +
                " " +
                NumberFormat(
                  Number(bill.bill_amount),
                  debitNoteData.currency_code,
                  orgCurrencyList
                ),
              billBalance:
                debitNoteData.currency_code +
                " " +
                NumberFormat(
                  Number(bill.bill_balance),
                  debitNoteData.currency_code,
                  orgCurrencyList
                ),
              amountToDebit: {
                index: index,
                amountToDebit: bill.amount_debited,
                focus: bill.focus,
                error: bill.error,
              },
              forex:
                allocationForex[index] &&
                getForexGainOrLossAmount(
                  bill.bill_id,
                  allocationForex[index],
                  debitNoteData.currency_code
                    ? debitNoteData.currency_code
                    : "",
                  orgCurrencyList
                ),
            },
            action: {
              url: `/bill/detail/${bill.bill_id}?redirect=/bill`,
              permission: subModulesWithPermission.includes("Bills"),
              customNavigation: true,
              type: "applyToBillsModal",
            },
          }))
        : []
    );
  };
  /**
   * Allocation table details
   */
  const allocationColumn = useMemo(
    () => [
      {
        Header: "Bill Number",
        accessor: "show.billNumber",
        className: "column-navigation",
      },
      {
        Header: "Bill Date",
        accessor: "show.billDate",
        className: "",
      },
      {
        Header: "Bill Amount",
        accessor: "show.billAmount",
        className: "",
      },
      {
        Header: "Bill Balance",
        accessor: "show.billBalance",
        className: "",
      },
      {
        Header: "Amount To Debit",
        accessor: "show.amountToDebit",
        className: "",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <InputPrefix
                prefix={debitNoteData.currency_code}
                className="credit_note_number"
                name="amt-debit-input"
                id=""
                inputType="text"
                value={value.amountToDebit ? value.amountToDebit : ""}
                placeholder={zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    debitNoteData.currency_code
                      ? debitNoteData.currency_code
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
                autoFocus={value.focus}
                disabled={false}
                error={value.error ? " error" : ""}
                onClick={() =>
                  handleAmountToDebit(value.index, value.amountToDebit, true)
                }
                onChange={(e: any) => {
                  // Restricting alphabets in amount field
                  const regex = /^\d*\.?\d*$/;
                  let valueStr = e.target.value.toString();
                  if (!regex.test(valueStr) || valueStr === ".") {
                    return;
                  }
                  handleAmountToDebit(value.index, e.target.value, true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    handleItemOnBlur(
                      "amount_debited",
                      value.amountToDebit,
                      value.index
                    );
                  }, 200);
                }}
              />
            </>
          );
        },
      },
      {
        Header: "Forex Gain/Loss",
        accessor: "show.forex",
        className: "",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    [allocationTableData]
  );
  const getBillsToAllocateDebitNote = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getBillsToAllocate({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
        billId: billId,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBillToAllocate(response.bill_allocate_list);
        setDebitBalance(response.debit_balance);
        setRemainingAmount(response.debit_balance);
        response.bill_allocate_list.map(
          (bill: BillToAllocate, index: number) => {
            allocationForex[index] = {
              [bill.bill_id]: bill.forex_amount,
            };
          }
        );
        setAllocationForex([...allocationForex]);
      }
    }
    dispatch(setLoaderState(false));
  };

  const handleAmountToDebit = async (
    index: number,
    value: number,
    focus: boolean
  ) => {
    billToAllocate.map((bill, billIndex) => {
      if (billIndex !== index) {
        if (bill.focus === true) {
          billToAllocate[billIndex] = {
            ...billToAllocate[billIndex],
            focus: false,
          };
        }
      }
    });
    let error = false;
    let updatedValue = value?.toString().includes(",")
      ? Number(value.toString().split(",").join(""))
      : value;
    if (Number(billToAllocate[index].bill_balance) < updatedValue) {
      error = true;
    }
    let tempBillAllocate = [...billToAllocate];
    tempBillAllocate[index] = {
      ...tempBillAllocate[index],
      ...{ amount_debited: updatedValue, focus: focus, error: error },
    };
    setBillToAllocate([...tempBillAllocate]);

    const errorData = tempBillAllocate.filter((bill) => bill.error === true);
    if (errorData.length) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    let forex = await getForexGainOrLoss(
      tempBillAllocate[index].conversion_id,
      debitNoteData.conversion_id,
      updatedValue ? updatedValue : 0,
      tempBillAllocate[index].bill_id,
      "DebitNote",
      true
    );
    allocationForex[index] = forex ? forex : {};
    setAllocationForex([...allocationForex]);
  };
  /**
   * Function to round off tax input elements after out of focus.
   */
  const handleItemOnBlur = (name: string, amount: number, index: number) => {
    if (amount?.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = decimalPlaceOfCurrency(
        debitNoteData.currency_code,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        billToAllocate[index] = {
          ...billToAllocate[index],
          ...{ [name]: roundedAmount, focus: false },
        };
        setBillToAllocate([...billToAllocate]);
      }
    }
  };

  const handleAmountRoundOff = (amount: number) => {
    if (amount?.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = decimalPlaceOfCurrency(
        debitNoteData.currency_code,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        return Number(roundedAmount);
      }
    }
    return Number(amount);
  };
  /**
   * Debit note allocation calculation
   */
  const calculateAllocationAmount = async (signal: AbortSignal) => {
    let input: number[] = [];
    billToAllocate.map((bill: BillToAllocate) => {
      if (
        bill.amount_debited &&
        bill.amount_debited !== null &&
        bill.amount_debited !== ""
      ) {
        input.push(Number(bill.amount_debited));
      } else {
        input.push(0);
      }
    });
    if (input.length) {
      const responseAction = await dispatch(
        calculateDebitNoteAllocationAmount({
          debitNoteId: Number(editId),
          orgId: currentUserInfo.organization_id,
          values: input,
          signal: signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setAllocatedAmount(response.amount_to_debit);
          setRemainingAmount(response.remaining_debits);
        }
      }
    }
  };

  const resetAllocation = () => {
    setIsError(false);
    setAllocationForex([]);
  };

  return {
    billToAllocate,
    allocationData,
    allocationColumn,
    allocationTableData,
    getBillsToAllocateDebitNote,
    resetAllocation,
    debitBalance,
    remainingAmount,
    allocatedAmount,
    isError,
    isFormValues,
    handleAmountRoundOff,
  };
};
