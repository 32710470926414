import React, { useEffect, useState } from "react";
import { AddTagIcon } from "../../../../../../assets/images";
import {
  AddressValues,
  RecurringInvoiceDefaultValues,
} from "../../../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  addBillingAddress,
  addShippingAddress,
} from "../../../../customerManagement/components/customerForm/AddressDetails/addressTypeSlice";
import BillingAddressList from "./BillingAddressList";
import ShippingAddressList from "./ShippingAddressList";
import { addressSelector } from "../../../../customerManagement/components/customerForm/AddressDetails/addressSlice";
import {
  billingEmptyFormValues,
  shippingEmptyFormvalues,
} from "../StateInitialization";

type Props = {
  billingAddressFormValues: AddressValues | undefined;
  shippingAddressFormValues: AddressValues | undefined;
  customerData: AddressValues[] | undefined;
  createBillingAddress: (address: AddressValues) => void;
  createShippingAddress: (address: AddressValues) => void;
  selectBillingAddressHandler: (index: number | undefined | string) => void;
  selectShippingAddressHandler: (index: number | undefined | string) => void;
  selectedBillingAddressId: number | string;
  selectedShippingAddressId: number | string;
  openAddressModal: () => void;
  setAddressIdHandler: (id: number | string) => void;
  customerId: number | undefined;
  customerIdForEdit: number | undefined;
  defaultFormValues: RecurringInvoiceDefaultValues;
};

const InvoiceAddress = (props: Props) => {
  const dispatch = useAppDispatch();
  const [billingValues, setBillingValues] = useState<AddressValues>();
  const [shippingValues, setShippingValues] = useState<AddressValues>();
  const [editBilling, setEditBilling] = useState<boolean>(false);
  const [editShipping, setEditShipping] = useState<boolean>(false);
  const [shippingModalClose, setShippingModalClose] = useState(false);
  const [billingModalClose, setBillingModalClose] = useState(false);
  const addressList = useAppSelector(addressSelector);
  const billingAddressList = addressList.find(
    (address) => address.is_billing === true
  );
  const shippingAddressList = addressList.find(
    (address) => address.is_shipping === true
  );
  useEffect(() => {
    if (props.customerData?.length) {
      props.customerData?.map((item: any) => {
        if (
          props.customerId !== props.customerIdForEdit &&
          item.is_billing === true &&
          (item.id === props.selectedBillingAddressId ||
            (props.selectedBillingAddressId === 0 && item.is_primary)) &&
          (item.country_id !== null ||
            item.address !== null ||
            item.city !== null ||
            item.state_id !== null ||
            item.zip_code !== null ||
            item.phone !== null)
        ) {
          setBillingValues(item);
          setEditBilling(true);
        } else if (props.customerId !== props.customerIdForEdit) {
          setBillingValues(billingEmptyFormValues);
          setEditBilling(false);
        }
        if (
          props.customerId !== props.customerIdForEdit &&
          item.is_shipping === true &&
          (item.id === props.selectedShippingAddressId ||
            (props.selectedShippingAddressId === 0 && item.is_primary)) &&
          (item.country_id !== null ||
            item.address !== null ||
            item.city !== null ||
            item.state_id !== null ||
            item.zip_code !== null ||
            item.phone !== null)
        ) {
          setShippingValues(item);
          setEditShipping(true);
        } else if (props.customerId !== props.customerIdForEdit) {
          setShippingValues(shippingEmptyFormvalues);
          setEditShipping(false);
        }
      });
    } else if (props.customerId !== props.customerIdForEdit) {
      setBillingValues(billingEmptyFormValues);
      setEditBilling(false);
      setShippingValues(shippingEmptyFormvalues);
      setEditShipping(false);
    }
  }, [props.customerData]);
  useEffect(() => {
    if (props.billingAddressFormValues) {
      setBillingValues(props.billingAddressFormValues);
    }
    if (props.shippingAddressFormValues) {
      setShippingValues(props.shippingAddressFormValues);
    }
  }, [props.billingAddressFormValues, props.shippingAddressFormValues]);

  const modalCloseHandler = (type: string) => {
    if (type === "shipping") {
      setBillingModalClose(true);
      setShippingModalClose(false);
    } else {
      setShippingModalClose(true);
      setBillingModalClose(false);
    }
  };

  const getCustomerName = () => {
    const billingAddress: any[] | undefined = props.customerData?.filter(
      (item: any) => item.id === billingValues?.customer_address_id
    );

    return billingAddress?.[0]?.customer_name;
  };

  const getCustomerNameShipping = () => {
    const shippingAddress: any[] | undefined = props.customerData?.filter(
      (item: any) => item.id === shippingValues?.customer_address_id
    );

    return shippingAddress?.[0]?.customer_name;
  };

  return (
    <section className="form-tab-section add-line">
      <div
        className="tab-pane address-tab-pane"
        id="billing-address"
        aria-labelledby="address"
      >
        <div className="row mb-0">
          <div className="col">
            <div className="row mb-0 g-0">
              <div className="col-12 col-lg-6 billing-address mw-349 rp-24">
                <h3>Billing Address</h3>
                {(billingAddressList ||
                  editBilling ||
                  (billingValues?.is_billing === true &&
                    (billingValues.address !== null ||
                      billingValues.city !== null ||
                      billingValues.country_id !== null ||
                      billingValues.phone !== null ||
                      billingValues.zip_code !== null))) && (
                  <BillingAddressList
                    type="billing"
                    billingModalClose={billingModalClose}
                    modalCloseHandler={modalCloseHandler}
                    createBillingAddress={props.createBillingAddress}
                    customerData={props.customerData}
                    selectBillingAddressHandler={
                      props.selectBillingAddressHandler
                    }
                    selectedBillingAddressId={
                      billingValues?.customer_address_id
                        ? billingValues.customer_address_id
                        : 0
                    }
                    openAddressModal={props.openAddressModal}
                    setAddressIdHandler={props.setAddressIdHandler}
                    customerName={
                      billingValues?.customer_name
                        ? billingValues.customer_name
                        : props.defaultFormValues.customer_name
                    }
                  />
                )}
                <div className="row mb-0">
                  {billingAddressList ||
                  (billingValues?.is_billing === true &&
                    (billingValues.address !== null ||
                      billingValues.city !== null ||
                      billingValues.country_id !== null ||
                      billingValues.phone !== null ||
                      billingValues.zip_code !== null)) ? (
                    <address className="bill-address mb-0">
                      <span>
                        <b>
                          {getCustomerName() ||
                            props.defaultFormValues.customer_name ||
                            ""}
                          <br />
                        </b>
                      </span>
                      {billingValues && billingValues?.address !== null && (
                        <>
                          <span>{billingValues?.address}</span>
                          <br />
                        </>
                      )}
                      {billingValues && billingValues?.city?.length !== 0 && (
                        <span>{billingValues?.city}</span>
                      )}
                      {billingValues?.city &&
                      (billingValues.state_id ||
                        billingValues.country_id ||
                        billingValues.zip_code ||
                        billingValues.phone)
                        ? ","
                        : ""}
                      {billingValues &&
                        billingValues?.state_id !== 0 &&
                        billingValues?.state_id !== null && (
                          <span>
                            {billingValues.state_name
                              ? `${billingValues.state_name},`
                              : ""}
                          </span>
                        )}
                      {billingValues &&
                        billingValues?.country_id !== 0 &&
                        billingValues?.country_id !== null && (
                          <>
                            <span>{billingValues.country_name}</span>
                            <br />
                          </>
                        )}
                      {billingValues &&
                        billingValues?.zip_code !== 0 &&
                        billingValues?.zip_code !== null &&
                        billingValues?.zip_code.toString() !== "" && (
                          <>
                            <span>PIN {billingValues?.zip_code}</span>
                            <br />
                          </>
                        )}
                      {billingValues &&
                        billingValues?.phone?.length !== 0 &&
                        billingValues?.phone !== null && (
                          <span>Phone number : {billingValues?.phone}</span>
                        )}
                    </address>
                  ) : (
                    <a
                      href="#"
                      role="button"
                      className="add-tags-button"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="d-flex align-items-center pb-4 mx-3 mb-4">
                        <img src={AddTagIcon} alt="" />
                        <span
                          className="px-2"
                          onClick={() => {
                            props.openAddressModal();
                            dispatch(addBillingAddress("add_billing"));
                            props.setAddressIdHandler("");
                          }}
                        >
                          Add billing address
                        </span>
                      </div>
                    </a>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6 shipping-address mw-349 lp-24">
                <div className="header-with-checkbox-wrapper">
                  <h3>Shipping Address</h3>
                  {(shippingAddressList ||
                    editShipping ||
                    (shippingValues?.is_shipping === true &&
                      (shippingValues.address !== null ||
                        shippingValues.city !== null ||
                        shippingValues.country_id !== null ||
                        shippingValues.phone !== null ||
                        shippingValues.zip_code !== null))) && (
                    <ShippingAddressList
                      type="shipping"
                      shippingModalClose={shippingModalClose}
                      modalCloseHandler={modalCloseHandler}
                      createShippingAddress={props.createShippingAddress}
                      customerData={props.customerData}
                      selectShippingAddressHandler={
                        props.selectShippingAddressHandler
                      }
                      // selectedShippingAddressId={
                      //   props.selectedShippingAddressId
                      // }
                      selectedShippingAddressId={
                        shippingValues?.customer_address_id
                          ? shippingValues.customer_address_id
                          : 0
                      }
                      openAddressModal={props.openAddressModal}
                      setAddressIdHandler={props.setAddressIdHandler}
                      customerName={
                        shippingValues?.customer_name
                          ? shippingValues.customer_name
                          : props.defaultFormValues.customer_name
                      }
                    />
                  )}
                </div>
                <div className="row mb-0">
                  {shippingAddressList ||
                  (shippingValues?.is_shipping === true &&
                    (shippingValues.address !== null ||
                      shippingValues.city !== null ||
                      shippingValues.country_id !== null ||
                      shippingValues.phone !== null ||
                      shippingValues.zip_code !== null)) ? (
                    <address className="bill-address mb-0">
                      <span>
                        <b>
                          {getCustomerNameShipping() ||
                            props.defaultFormValues.customer_name ||
                            ""}
                          <br />
                        </b>
                      </span>
                      {shippingValues && shippingValues?.address !== null && (
                        <>
                          <span>{shippingValues?.address}</span>
                          <br />
                        </>
                      )}
                      {shippingValues && shippingValues?.city?.length !== 0 && (
                        <span>{shippingValues?.city}</span>
                      )}
                      {shippingValues?.city &&
                      (shippingValues.state_id ||
                        shippingValues.country_id ||
                        shippingValues.zip_code ||
                        shippingValues.phone)
                        ? ","
                        : ""}
                      {shippingValues &&
                        shippingValues?.state_id !== 0 &&
                        shippingValues.state_id !== null && (
                          <span>
                            {shippingValues.state_name
                              ? `${shippingValues.state_name},`
                              : shippingValues.state_name}
                          </span>
                        )}
                      {shippingValues &&
                        shippingValues?.country_id !== 0 &&
                        shippingValues.country_id !== null && (
                          <>
                            <span>{shippingValues.country_name}</span>
                            <br />
                          </>
                        )}
                      {shippingValues &&
                        shippingValues?.zip_code !== 0 &&
                        shippingValues?.zip_code !== null &&
                        shippingValues?.zip_code.toString() !== "" && (
                          <>
                            <span>PIN {shippingValues?.zip_code}</span>
                            <br />
                          </>
                        )}
                      {shippingValues &&
                        shippingValues?.phone?.length !== 0 &&
                        shippingValues?.phone !== null && (
                          <span>Phone number : {shippingValues?.phone}</span>
                        )}
                    </address>
                  ) : (
                    <a
                      href="#"
                      role="button"
                      className="add-tags-button"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="d-flex align-items-center pb-4 mx-3 mb-4">
                        <img src={AddTagIcon} alt="add-tag-img" />
                        <span
                          className="px-2"
                          onClick={() => {
                            props.openAddressModal();
                            dispatch(addShippingAddress("add_shipping"));
                            props.setAddressIdHandler("");
                          }}
                        >
                          Add shipping address
                        </span>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvoiceAddress;
