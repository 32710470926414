import { Container } from "../../htmlTags/Container";
import { RadioButtonGroup } from "../../radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import "./SingleTag.css";
import { useEffect, useRef, useState } from "react";
import { DeleteIcon, EditIcon } from "../..";
import { colors } from "../../../../constants/colors";
import { Button, FormFieldContainer, Input } from "../../ui";
import ItemCloseButton from "../../button/ItemCloseButton";
import { ObjectType } from "../../../../../types";
import {
  SPLIT_TYPES,
  SPLIT_TYPES_OPTIONS,
} from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import {
  getReportingDetails,
  getSplitAmount,
} from "../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { TagsOptionsSelectionPopup } from "../../reportingTagInTransactions/tagSelectionPopups/TagOptionsSelectionPopup";
import { Span } from "../../htmlTags/Span";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { orgCurrencyListSelector, setLoaderState } from "../../../commonSlice";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { useAppSelector } from "../../../../../app/hooks";
import InputPrefix from "../../formPrefixInputField/InputPrefix";
import { errorSetter } from "../utils/errorSetter";
import { AssociatedTagOptionsType } from "../../reportingTagInTransactions/types";

type Props = {
  tag: ObjectType;
  index: number;
  values: any;
  setValues: any;
  errors: ObjectType;
  allTags: any;
  currencyCode: string;
  touched: ObjectType;
  setFieldTouched?: (field: string, isTouched?: boolean) => void;
  isTagFilteringPresent: boolean;
  tagFilterObject?: AssociatedTagOptionsType;
};

const SingleTag = ({
  tag,
  values,
  setValues,
  errors,
  index,
  allTags,
  currencyCode,
  touched,
  setFieldTouched,
  isTagFilteringPresent,
  tagFilterObject,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [options, setOptions] = useState([]);
  const { dispatch, currentUserInfo } = useCommonData();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const abortControllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    getTagOptions();
  }, [tag.reporting_tag_id]);

  const handleDeleteTag = (index: number) => {
    const reporting_tag_list = values.reporting_tag_list;
    reporting_tag_list.splice(index, 1);
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
  };
  const handleDeleteOption = (index: number, optionIndex: number) => {
    const reporting_tag_list = values.reporting_tag_list;
    let option_list = reporting_tag_list[index].option_list;
    option_list.splice(optionIndex, 1);
    reporting_tag_list[index].option_list = option_list;
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
    if (
      reporting_tag_list[index].split_type === SPLIT_TYPES.split_equally &&
      reporting_tag_list[index].option_list?.length &&
      reporting_tag_list[index].tag_amount
    ) {
      applySplitEqually(index);
    }
  };

  const handleTagAmountChange = (value: any, index: number) => {
    if (value.startsWith("0") && value.length > 1) {
      value = value.slice(1);
    }
    const reporting_tag_list = values.reporting_tag_list;
    reporting_tag_list[index].tag_amount = value;
    setFieldTouched &&
      setFieldTouched(`reporting_tag_list.${index}.tag_amount`, true);
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });

    if (
      reporting_tag_list[index].split_type === SPLIT_TYPES.split_equally &&
      !Number.isNaN(value) &&
      reporting_tag_list[index].option_list?.length
    ) {
      if (isNaN(value)) {
        return;
      } else {
        applySplitEqually(index, reporting_tag_list);
      }
    }
  };
  const handleTagAmountBlur = (index: number) => {
    let reporting_tag_list = [...values.reporting_tag_list];
    const roundedAmount = getRoundedAmount(
      reporting_tag_list[index].tag_amount
    );
    reporting_tag_list[index].tag_amount = roundedAmount;
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
    if (
      reporting_tag_list[index].split_type === SPLIT_TYPES.split_equally &&
      reporting_tag_list[index].option_list?.length &&
      !Number.isNaN(reporting_tag_list[index].tag_amount)
    ) {
      applySplitEqually(index, reporting_tag_list);
    }
  };

  const handleOptionAmountChange = (
    value: any,
    index: number,
    optionIndex: number
  ) => {
    const reporting_tag_list = values.reporting_tag_list;
    const option_list = reporting_tag_list[index].option_list;
    option_list[optionIndex].amount = value;
    reporting_tag_list[index].option_list = option_list;
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
  };

  const handleOptionAmountBlur = (index: number, optionIndex: number) => {
    let reporting_tag_list = values.reporting_tag_list;
    const newOptionAmount = getRoundedAmount(
      reporting_tag_list[index].option_list[optionIndex].amount
    );
    reporting_tag_list[index].option_list[optionIndex].amount = newOptionAmount;

    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
  };

  const handleSplitTypeChange = async (value: string, index: number) => {
    const reporting_tag_list = values.reporting_tag_list;
    reporting_tag_list[index].split_type = value;
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
    if (
      value === SPLIT_TYPES.split_equally &&
      reporting_tag_list[index].tag_amount &&
      reporting_tag_list[index].option_list?.length
    ) {
      applySplitEqually(index);
    }
  };

  const applySplitEqually = async (
    index: number,
    new_reporting_tag_list?: any
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef?.current?.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const reporting_tag_list = new_reporting_tag_list
      ? new_reporting_tag_list
      : values.reporting_tag_list;

    let response = await dispatch(
      getSplitAmount({
        orgId: currentUserInfo.organization_id,
        splitType: SPLIT_TYPES.split_equally,
        transactionType: "Invoices",
        payload: {
          currency_id: values.currency_id,
          transaction_amount: reporting_tag_list[index].tag_amount || 0,
          tags_list: reporting_tag_list[index].option_list,
        },
        signal: abortController.signal,
      })
    );
    reporting_tag_list[index].option_list = response?.payload?.tag_listing;
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
  };

  const getTagOptions = async () => {
    const responseAction = await dispatch(
      getReportingDetails({
        orgId: currentUserInfo.organization_id,
        id: tag.reporting_tag_id,
      })
    );
    const response = responseAction.payload;
    if (response) {
      setOptions(response.options);
    }
  };

  const updateSelectedIds = (tags: any) => {
    const reporting_tag_list = values.reporting_tag_list;
    let selectedTagIndex = allTags?.findIndex((tag: any) => {
      return Number(tag.id) === Number(tags.tagId);
    });
    let allOptions = allTags[selectedTagIndex]?.options;

    let new_reporting_tag_list = reporting_tag_list?.map(
      (reporting_tag: any) => {
        if (Number(reporting_tag.reporting_tag_id) !== Number(tags.tagId)) {
          return reporting_tag;
        }

        let existing_option_list = reporting_tag.option_list;

        let existing_options_ids = existing_option_list?.map(
          (existing_option: any) => {
            return String(existing_option.option_id);
          }
        );

        let new_option_list = tags?.optionIds?.map((optionId: number) => {
          if (existing_options_ids?.includes(String(optionId))) {
            return existing_option_list?.find(
              (existing_option: any) => existing_option.option_id === optionId
            );
          }

          let optionName = allOptions?.find(
            (singleOption: ObjectType) => singleOption.id == optionId
          ).name;

          return {
            option_id: optionId,
            option_name: optionName,
            amount: 0,
          };
        });
        reporting_tag.option_list = new_option_list;
        return reporting_tag;
      }
    );
    setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: new_reporting_tag_list,
      };
    });
    if (
      reporting_tag_list[index]?.split_type === SPLIT_TYPES.split_equally &&
      reporting_tag_list[index]?.tag_amount &&
      new_reporting_tag_list[index]?.option_list?.length
    ) {
      applySplitEqually(index, new_reporting_tag_list);
    }
  };

  const getRoundedAmount = (amount: number) => {
    if (amount?.toString()?.includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let decimals = decimalPlaceOfCurrency(
        values.currency_code,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        const roundedAmount = getRoundOffAmount(amount, decimals);
        return roundedAmount;
      }
    }
    return amount;
  };

  return (
    <Container
      className={`singleTag ${
        errors?.reporting_tag_list &&
        errors?.reporting_tag_list[index]?.reporting_tag_id
          ? "option-not-set-error"
          : ""
      }`}
      style={{ backgroundColor: colors.backgroundGrey }}
    >
      <Container className="row g-0">
        <Container className="col">
          <Container className="w-100 justify-content d-flex align-items-center">
            <Container className="reporting-tag-title-row d-flex">
              <Button
                wrapperClass={`collapsible-btn ${isOpen ? "active" : ""}`}
                onClick={toggle}
              >
                <></>
              </Button>
              <FormFieldContainer
                wrapperClass="transaction_amount_wrapper"
                label={tag.reporting_tag_name + " :"}
                isRequired={false}
                labelToolTipMessage={
                  String(tag.reporting_tag_name)?.length > 15
                    ? tag.reporting_tag_name
                    : ""
                }
                labelClass="label-tooltip-message"
                labelToolTipSpanClass="max-width-100"
              >
                <Container className="transaction_amount_field_wrapper">
                  <InputPrefix
                    name="amount"
                    id="amount"
                    inputType="text"
                    index={index}
                    isAmountField={true}
                    prefix={currencyCode}
                    className={`form-control w-200`}
                    value={tag.tag_amount}
                    placeholder="Enter Amount"
                    maxLength={20}
                    disabled={false}
                    onChange={(e) => {
                      handleTagAmountChange(e.target.value, index);
                    }}
                    error={errorSetter(
                      touched?.reporting_tag_list,
                      errors?.reporting_tag_list,
                      "tag_amount",
                      index
                    )}
                    onBlur={() => {
                      handleTagAmountBlur(index);
                    }}
                  />
                  {errorSetter(
                    touched?.reporting_tag_list || [],
                    errors?.reporting_tag_list || [],
                    "tag_amount",
                    index
                  ) && (
                    <Span className="error">
                      {errorSetter(
                        touched?.reporting_tag_list || [],
                        errors?.reporting_tag_list || [],
                        "tag_amount",
                        index
                      )}
                    </Span>
                  )}
                </Container>
              </FormFieldContainer>
              <Container className="splittype-group">
                <RadioButtonGroup
                  name="splittype"
                  id="splittype"
                  valuesArray={SPLIT_TYPES_OPTIONS}
                  value={tag.split_type}
                  icon={
                    <Span>
                      <RadioButtonUncheckedIcon />
                    </Span>
                  }
                  checkedIcon={
                    <Span>
                      <RadioButtonCheckedIcon className="radio-btn-tds" />
                    </Span>
                  }
                  isDisabled={false}
                  onClick={(value) => {
                    handleSplitTypeChange(value, index);
                  }}
                />
              </Container>
            </Container>
            <Container className="action-btn-wrapper">
              <TagsOptionsSelectionPopup
                getSelectedIds={updateSelectedIds}
                selectedIds={tag?.option_list?.map((option: ObjectType) => {
                  return Number(option.option_id);
                })}
                tagId={tag.reporting_tag_id}
                parentTag={tag.reporting_tag_id}
                options={options}
                buttonContent={
                  <EditIcon
                    color={colors.slate}
                    height="12px"
                    width="12px"
                    margin="0px 10px 0px 0px"
                  />
                }
                isDisabled={false}
                isAddNewTag={false}
                tagFilterObject={tagFilterObject}
                isTagFilteringPresent={isTagFilteringPresent}
              />

              <Button
                type="button"
                disabled={false}
                onClick={() => {
                  handleDeleteTag(index);
                }}
                wrapperClass="delete-container-btn"
              >
                <DeleteIcon color={colors.slate} />
              </Button>
            </Container>
          </Container>

          {isOpen ? (
            <Container className="all-options-wrapper">
              {tag?.option_list?.map((option: any, optionIndex: any) => {
                return (
                  <>
                    <Container className="d-flex align-center option-wrapper">
                      <FormFieldContainer
                        wrapperClass="transaction_amount_wrapper ellipsis"
                        label={option.option_name}
                        isRequired={false}
                        labelToolTipMessage={
                          String(option.option_name)?.length > 15
                            ? option.option_name
                            : ""
                        }
                        labelClass="label-tooltip-message"
                        labelToolTipSpanClass="max-width-100"
                      >
                        <>
                          <Container className="option-field-and-error-wrap">
                            <Container className="d-flex align-items-center">
                              <InputPrefix
                                name="amount"
                                id="amount"
                                inputType="text"
                                className={`form-control w-150`}
                                value={option.amount}
                                placeholder="Enter Amount"
                                maxLength={20}
                                prefix={currencyCode}
                                isAmountField={true}
                                index={index}
                                onChange={(e) => {
                                  handleOptionAmountChange(
                                    e.target.value,
                                    index,
                                    optionIndex
                                  );
                                }}
                                error={
                                  errors?.reporting_tag_list &&
                                  errors?.reporting_tag_list[index]
                                    ?.option_list &&
                                  errors?.reporting_tag_list[index]
                                    ?.option_list[optionIndex]?.amount
                                }
                                disabled={
                                  tag.split_type === SPLIT_TYPES.split_equally
                                    ? true
                                    : false
                                }
                                onBlur={() => {
                                  handleOptionAmountBlur(index, optionIndex);
                                }}
                              />

                              <Button
                                wrapperClass="delete-container-btn"
                                onClick={() => {
                                  handleDeleteOption(index, optionIndex);
                                }}
                                type="button"
                              >
                                <ItemCloseButton />
                              </Button>
                            </Container>
                            {errors?.reporting_tag_list &&
                              errors?.reporting_tag_list[index]?.option_list &&
                              errors?.reporting_tag_list[index]?.option_list[
                                optionIndex
                              ]?.amount && (
                                <Container className="option-error-wrap">
                                  <Span className="error">
                                    {errors?.reporting_tag_list &&
                                      errors?.reporting_tag_list[index]
                                        ?.option_list &&
                                      errors?.reporting_tag_list[index]
                                        ?.option_list[optionIndex]?.amount}
                                  </Span>
                                </Container>
                              )}
                          </Container>
                        </>
                      </FormFieldContainer>
                    </Container>
                  </>
                );
              })}
            </Container>
          ) : null}
        </Container>
      </Container>

      {errors?.reporting_tag_list &&
        errors?.reporting_tag_list[index]?.reporting_tag_id && (
          <Span className="error">
            {errors?.reporting_tag_list &&
              errors?.reporting_tag_list[index]?.reporting_tag_id}
          </Span>
        )}
    </Container>
  );
};

export default SingleTag;
