import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import { PrimaryButton } from "../../../../common/components/button/PrimaryButton";
import { Container } from "../../../../common/components/htmlTags/Container";
import { Span } from "../../../../common/components/htmlTags/Span";
import "./PaymentGatewayMapping.css";
interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}
export const PaymentGateWayMapping = ({
  setIsSetup,
  isSetup,
  values,
  setFieldValue,
  setFieldError,
}: {
  setIsSetup: (value: boolean) => void;
  isSetup: boolean;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  setFieldError: (field: string, value: any) => void;
}) => {
  const blockedPaymentGateways = [
    "razorpay",
    "amazonpay",
    "billdesk",
    "paypal",
  ];
  const defaultPaymentGateway = "stripe";
  const StyledFormControlLabel = styled(
    (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
  )(({ theme }) => ({
    variants: [
      {
        props: { checked: true },
        style: {
          ".MuiFormControlLabel-label": {
            color: theme.palette.primary.main,
          },
        },
      },
    ],
  }));

  function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  const renderRadioIcon = (isChecked: boolean, paymentGateway: any) => {
    return (
      <Container
        className={`${
          isChecked
            ? "gateway-mapping-label-container-checked"
            : "gateway-mapping-label-container"
        } w-100`}
      >
        <Container
          className={`gateway-mapping-label-container-logo ${
            blockedPaymentGateways.includes(paymentGateway?.gateway_type || "")
              ? "disabled-component"
              : ""
          }`}
        >
          <img src={paymentGateway.logo} alt={paymentGateway.name} />
        </Container>
        <Container
          className={`${
            isChecked
              ? "gateway-mapping-label-container-name-checked"
              : "gateway-mapping-label-container-name"
          }`}
        >
          <Span>{paymentGateway.name}</Span>
          <Span
            className={`gateway-mapping-label-container-description ${
              blockedPaymentGateways.includes(
                paymentGateway?.gateway_type || ""
              )
                ? "disabled-component"
                : ""
            }`}
          >
            {paymentGateway.description}
          </Span>
        </Container>
      </Container>
    );
  };
  return (
    <Container>
      <RadioGroup
        name="use-radio-group"
        defaultValue={defaultPaymentGateway}
        value={values?.selected_payment_gateway?.gateway_type||defaultPaymentGateway}
        onChange={(e) => {
          setIsSetup(false);
          setFieldValue(
            "selected_payment_gateway",
            values.supported_payment_gateways.find(
              (item: any) => item.gateway_type === e.target.value
            )
          );
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "30px",
        }}
      >
        {Array.isArray(values?.supported_payment_gateways) &&
          values?.supported_payment_gateways
            ?.filter(
              (paymentGateway: any) =>
                !blockedPaymentGateways.includes(
                  paymentGateway?.gateway_type || ""
                )
            )
            ?.map((paymentGateway: any) => (
              <MyFormControlLabel
                className="gateway-mapping-label-component"
                value={paymentGateway?.gateway_type}
                disabled={blockedPaymentGateways.includes(
                  paymentGateway?.gateway_type
                )}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  justifyContent: "flex-start",
                }}
                label={
                  <>
                    {!paymentGateway?.is_active &&
                    values?.selected_payment_gateway?.gateway_type ===
                      paymentGateway?.gateway_type ? (
                      <Container className="gateway-mapping-label-container-button">
                        <PrimaryButton
                          type="button"
                          onClick={() => {
                            setIsSetup(true);
                            setFieldValue(
                              "selected_payment_gateway.api_key",
                              ""
                            );
                            setFieldValue(
                              "selected_payment_gateway.api_secret",
                              ""
                            );
                            setFieldValue(
                              "selected_payment_gateway.webhook_secret",
                              ""
                            );
                            setFieldValue(
                              "selected_payment_gateway.chart_of_account_name",
                              null
                            );
                            setFieldValue(
                              "selected_payment_gateway.chart_of_account_id",
                              ""
                            );
                            setFieldError(
                              "selected_payment_gateway.chart_of_account_name",
                              undefined
                            );
                            setFieldError(
                              "selected_payment_gateway.api_key",
                              undefined
                            );
                            setFieldError(
                              "selected_payment_gateway.api_secret",
                              undefined
                            );
                            setFieldError(
                              "selected_payment_gateway.webhook_secret",
                              undefined
                            );
                          }}
                          isDisabled={blockedPaymentGateways.includes(
                            paymentGateway?.gateway_type
                          )}
                        >
                          Set Up Now
                        </PrimaryButton>
                      </Container>
                    ) : paymentGateway.is_active &&
                      values?.selected_payment_gateway?.gateway_type ===
                        paymentGateway?.gateway_type &&
                      !isSetup ? (
                      <Container className="gateway-mapping-label-container-button">
                        <PrimaryButton
                          type="button"
                          onClick={() => {
                            setIsSetup(true);
                            setFieldError(
                              "selected_payment_gateway",
                              undefined
                            );
                          }}
                          isDisabled={blockedPaymentGateways.includes(
                            paymentGateway?.gateway_type
                          )}
                        >
                          Update Configuration
                        </PrimaryButton>
                      </Container>
                    ) : (
                      <></>
                    )}
                  </>
                }
                control={
                  <Radio
                    icon={renderRadioIcon(false, paymentGateway)}
                    checkedIcon={renderRadioIcon(true, paymentGateway)}
                    focusRipple={true}
                    onClick={() => {
                      setFieldValue("is_active", paymentGateway?.is_active);
                    }}
                  />
                }
              />
            ))}
      </RadioGroup>
    </Container>
  );
};
