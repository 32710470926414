import { Container } from "../../../common/components/htmlTags/Container";
import { Section } from "../../../common/components/htmlTags/Section";
import InfoBox from "../../../common/components/infoBox/InfoBox";
import { message } from "../../../constants/messages";
import "./BudgetVarianceReport.css";
import { BudgetVarianceReportFilter } from "./components/budgetVarianceReportFilter/BudgetVarianceReportFilter";
import BudgetVarianceReportOrgDetails from "./components/budgetVarianceReportOrgDetails/BudgetVarianceReportOrgDetails";
import BudgetVarianceReportTable from "./components/budgetVarianceReportTable/BudgetVarianceReportTable";
import { useBudgetVarianceReport } from "./hooks/useBudgetVarianceReport";
import { DataInput } from "./types";
export const BudgetVarianceReport = () => {
  const {
    handleChange,
    handleReset,
    handleSubmit,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
  } = useBudgetVarianceReport();
  return (
    <Section className="budget-variance-report-section">
      {/* <Container className="budget-variance-report-header"> */}
        <h1>Budget Variance Report</h1>
      {/* </Container> */}
      <Container className="budget-variance-report-container">
        <BudgetVarianceReportFilter
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          setValues={setValues}
          setErrors={setErrors}
          setTouched={setTouched}
          touched={touched}
        />
        {values.tableData.length > 0 && <>
        <BudgetVarianceReportOrgDetails
          organizationDetails={(values.data as DataInput)?.organization_details}
          startDate={(values.data as DataInput)?.actual_date_range?.split("_")[0]}
          endDate={(values.data as DataInput)?.actual_date_range?.split("_")[1]}
        />
        <Container className="report-note-container">
          <InfoBox
            message={message((values.data as DataInput).currency_code).reportCurrencyInfo}
            className="report-note"
          />
        </Container>
       <BudgetVarianceReportTable data={values.data as DataInput} tableData={values.tableData} /></>}
      </Container>
    </Section>
  );
};
