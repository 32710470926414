import * as yup from "yup";

export const CreateItemValidation = yup.object({
  name: yup.string().required("Please enter item name"),
  currency_id: yup.string().required("Please choose a currency"),
  isGstOrg: yup.boolean(),
  hsn_or_sac_code: yup
    .mixed()
    .nullable()
    .test("hsn_or_sac_code", "Please enter HSN/SAC code.", function (value) {
      const { isGstOrg, type } = this.options.context as any;
      if (isGstOrg && !value) {
        return false;
      }
      return true;
    })
    .test(
      "hsn_or_sac_code",
      "Please ensure that your HSN code has only numbers and length is less than or equal to 8 digits.",
      function (value) {
        const { isGstOrg, type } = this.options.context as any;
        let hsnRegex8Char = /^[0-9]{1,8}$/;
        if (
          isGstOrg &&
          value &&
          !hsnRegex8Char.test(value.toString()) &&
          type === "GOODS"
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      "hsn_or_sac_code",
      "Please ensure that your SAC has only numbers and length is less than or equal to 6 digits",
      function (value) {
        const { isGstOrg, type } = this.options.context as any;
        let hsnRegex6Char = /^[0-9]{1,6}$/;
        if (
          isGstOrg &&
          value &&
          !hsnRegex6Char.test(value.toString()) &&
          type === "SERVICE"
        ) {
          return false;
        }
        return true;
      }
    ),
  tax_preference: yup
    .string()
    .nullable()
    .test("tax_preference", "Please enter tax preference", function (value) {
      const { isGstOrg } = this.options.context as any;
      if (isGstOrg && !value) {
        return false;
      }
      return true;
    }),
  exemption_reason_id: yup
    .number()
    .nullable()
    .test(
      "exemption_reason_id",
      "Please enter tax exemption reason",
      function (value) {
        const { tax_preference } = this.options.context as any;
        if (tax_preference === "NON_TAXABLE") {
          if (value) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }
    ),
  tax_list: yup.array().of(
    yup.object({
      tax_id: yup
        .string()
        .test("tax_id", "Please choose tax code", function (value) {
          const { isGstOrg, tax_list } = this.options.context as any;
          const { index } = this.options as any;
          const tax_id = value ? value : ""
          if (!isGstOrg) {
            if (tax_id === ''  && Number(tax_list[index].tax_percentage) > 0) {
              return false;
            }
            return true;
          }
          return true;
        }),
      tax_percentage: yup
        .string()
        .test(
          "tax_percentage",
          "Tax percentage must be a positive number",
          function (value) {
            const { isGstOrg } = this.options.context as any;
            if (isGstOrg) {
              if (value) {
                return false;
              }
              return true;
            }
            return true;
          }
        ),
    })
  ),
  item_information: yup
    .array()
    .of(
      yup.object().shape({
        rate: yup
          .mixed()
          .test("rate", "Please enter rate", function (value, { parent }) {
            const { item_information } = this.options.context as any;
            const rate = value?.toString();
            if (parent.is_sales && !parent.is_purchase) {
              if (rate && rate.length > 0) {
                return true;
              }
              return false;
            } else if (parent.is_purchase && !parent.is_sales) {
              if (rate && rate.length > 0) {
                return true;
              }
              return false;
            } else if (!parent.is_sales && !parent.is_purchase) {
              return true;
            }
          }),
        account_id: yup
          .string()
          .test(
            "account_id",
            "Please enter account",
            function (value, { parent }) {
              const { item_information } = this.options.context as any;
              if (parent.is_sales && !parent.is_purchase) {
                if (value) {
                  return true;
                }
                return false;
              } else if (parent.is_purchase && !parent.is_sales) {
                if (value) {
                  return true;
                }
                return false;
              } else if (!parent.is_sales && !parent.is_purchase) {
                return true;
              }
            }
          ),
        is_sales: yup.boolean().required(),
        is_purchase: yup.boolean().required(),
      })
    )
    .test(
      "is_sales_purchase",
      "You need to select sales or purchase information",
      function (value) {
        if (value) {
          const item1 = value[0];
          const item2 = value[1];

          if (
            !item1.is_sales &&
            !item1.is_purchase &&
            !item2.is_sales &&
            !item2.is_purchase
          ) {
            return false;
          }
          return true;
        }
      }
    ),
});
