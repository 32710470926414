import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Cell, Row } from "react-table";
import { TableData } from "../../../../types";
import { useAppSelector } from "../../../../app/hooks";
import { setLoaderState } from "../../../common/commonSlice";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import {
  dateRangeSelector,
  getVendorBalanceDetailReport,
  setDateRange,
} from "../ReportSlice";
import {
  DateFormatHandler,
  StatusColor,
  getFormatedDate,
  getProfileFormatedDate,
} from "../../../../helpers/helper";
import BalanceTable from "./BalanceTable";
import ReportHeader from "../componets/ReportHeader";
import useCommonData from "../../../hooks/useCommon";
import ReportFilter from "../componets/ReportFilter";
import BackButton from "../../../common/components/BackButton";

type LocationProps = {
  vendorId: string;
  balanceType: string;
};
function VendorBalanceDetails() {
  const initialData = {
    currency_id: 0,
    currency_code: "INR",
    date_range: "today",
    organization_id: 0,
    end_date: getFormatedDate(),
    detail_list: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
    },
    total_balance_base: 0,
    vendor_name: "",
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vendorId = queryParams.get("id");
  const balanceType = queryParams.get("type");
  const { dispatch, currentUserInfo, orgCurrencyList, navigate } =
    useCommonData();
  const { subModulesWithPermission } = usePermissionHandler();
  const dateRange = useAppSelector(dateRangeSelector);

  const [reportData, setReportData] = useState<any>(initialData);
  const [showReport, setShowReport] = useState(true);
  const reportRef = React.useRef<any>([]);
  const [filterString, setFilterString] = useState("");
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  const initialFiltervalues = {
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    date_range: filters ? filters.date_range : "today",
    end_date: filters ? filters.end_date : getFormatedDate(),
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
    vendor_id: filters && filters.vendor_id ? filters.vendor_id : vendorId,
    balance_type:
      filters && filters.balance_type ? filters.balance_type : balanceType,
  };
  const [filterValues, setFiltervalues] = useState(initialFiltervalues);

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    )
      getReport("initial");
  }, [currentUserInfo]);

  useEffect(() => {
    if (dateRange.date_range !== "" && dateRange.end_date !== "") {
      setFiltervalues({
        ...filterValues,
        date_range: dateRange.date_range,
        end_date: dateRange.end_date,
      });
    }
  }, [dateRange]);

  const handleReport = (type: string) => {
    getReport(type);
  };

  const getReport = async (type: string) => {
    dispatch(setLoaderState(true));
    let filterValues: any = reportRef.current?.filterValues;
    if (type === "initial" || !filterValues) filterValues = initialFiltervalues;
    filterValues = {
      ...filterValues,
      vendor_id: filters && filters.vendor_id ? filters.vendor_id : vendorId,
      balance_type:
        filters && filters.balance_type ? filters.balance_type : balanceType,
    };
    const tempFilterString = JSON.stringify(filterValues);
    const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
    setFilterString(hashFragment);
    const responseAction = await generateReport(filterValues);
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let reportDetails = response;
        setReportData(reportDetails);
        dispatch(setLoaderState(false));
        setShowReport(true);
      }
    }
    dispatch(setLoaderState(false));
  };
  const generateReport = async (filterValues: any) => {
    const responseAction = await dispatch(
      getVendorBalanceDetailReport({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        className: "date text-align-left",
      },
      {
        Header: "Transaction #",
        accessor: "show.transactionNumber",
        className: "column-navigation transaction-number text-align-left",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "transaction-type text-align-left",
      },
      {
        Header: "Status",
        accessor: "show.status",
        className: "status text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Amount (FCY)",
        accessor: "show.amountFcy",
        className: "amount-fcy text-align-right",
      },
      {
        Header: "Balance (FCY)",
        accessor: "show.balanceFcy",
        className: "balance-fcy text-align-right",
      },
      {
        Header: "Amount",
        accessor: "show.amount",
        className: "amount text-align-right",
      },
      {
        Header: "Balance",
        accessor: "show.balance",
        className: "balance text-align-right",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    []
  );
  const data = () => {
    let report = [];
    if (reportData.detail_list && reportData.detail_list.length > 0) {
      report = reportData.detail_list.map((item: any, index: number) => ({
        show: {
          date:
            item.transaction_type === "Vendor Opening Balance"
              ? `As on ${getProfileFormatedDate(
                  currentUserInfo.organization_date_format,
                  item.transaction_date
                )}`
              : getProfileFormatedDate(
                  currentUserInfo.organization_date_format,
                  item.transaction_date
                ),
          transactionNumber: joinTransactionNumber(item.transaction_number),
          transactionType:
            item.transaction_type === "Vendor Opening Balance"
              ? "Vendor Opening Balance"
              : item.transaction_type,
          status: item.transaction_status,
          amountFcy: `${reportData.vendor_currency_code} ${NumberFormat(
            Number(item.amount),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          balanceFcy: `${reportData.vendor_currency_code} ${NumberFormat(
            Number(item.balance),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          amount: `${reportData.currency_code} ${NumberFormat(
            Number(item.amount_base),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          balance: `${reportData.currency_code} ${NumberFormat(
            Number(item.balance_base),
            reportData.currency_code,
            orgCurrencyList
          )}`,
        },
        action:
          item.transaction_type === "Bill"
            ? {
                url: `/bill/detail/${item.transaction_id}${filterString}`,
                permission: subModulesWithPermission.includes("Bills"),
              }
            : item.transaction_type === "Debit Note"
            ? {
                url: `/debit-note/detail/${item.transaction_id}${filterString}`,
                permission: subModulesWithPermission.includes("Debit Notes"),
              }
            : item.transaction_type === "Payments Made"
            ? {
                url: `/payment-made/detail/${item.transaction_id}${filterString}`,
                permission: subModulesWithPermission.includes("Payments Made"),
              }
            : item.transaction_type === "Vendor Opening Balance"
            ? {
                url: ``,
              }
            : {
                url: `/bill/detail/${item.transaction_id}${filterString}`,
                permission: subModulesWithPermission.includes("Bills"),
              },
      }));
      report.push({
        show: {
          date: "",
          transactionNumber: "",
          transactionType: "",
          status: "",
          amountFcy: "",
          balanceFcy: "Total",
          amount: `${reportData.currency_code} ${NumberFormat(
            Number(reportData.total_amount_base),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          balance: `${reportData.currency_code} ${NumberFormat(
            Number(reportData.total_balance_base),
            reportData.currency_code,
            orgCurrencyList
          )}`,
        },
        className: "total-row no-click-action",
      });
    }
    return report;
  };
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (cell.column.Header === "Transaction #") {
      let action = row?.values?.action;
      if (action?.url && action?.permission) {
        navigate(`?${filterString}`, { replace: true });
        navigate(action?.url);
      }
    }
  };

  return (
    <div className="reports vendor-balance">
      <div
        className="card card-dashboard report-card balance-sheet-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <div className="back-navigation">
                <BackButton onClick={() => navigate(-1)} label=" " />
              </div>
              <h1>Vendor Balance Details for {reportData.vendor_name}</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <ReportFilter
              isSingleDatePicker={true}
              isAdvanced={true}
              defaultValue="today"
              reportType="VBD"
              handleReport={handleReport}
              clearReport={() => setShowReport(false)}
              isEntity={false}
              entityType="VendorDetail"
              ref={reportRef}
            />
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center ">
                  {/* <ReportHeaderActionLinks /> */}
                </div>
                <ReportHeader
                  reportName={`Vendor Balance Details for ${reportData.vendor_name}`}
                  reportData={reportData}
                />
                <div className="table-section report-table-section">
                  <BalanceTable
                    columns={columns}
                    data={data()}
                    className={"vendor-balance-detail-report navigation"}
                    navigation={navigation}
                    haveColumnClassName={true}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorBalanceDetails;
