import { AccountNode, BudgetEntry, Ranges } from "../types/types";

const processDateRangeValue = (
  range: Ranges,
  rangesToInclude: string[],
  isCategory: boolean
) => {
  let dateRanges = Object.keys(range);
  let processedValue = dateRanges
    .filter((ran) => rangesToInclude.includes(ran))
    .map((ran) => {
      return {
        month: ran.split("_")[0],
        amount: isCategory
          ? 0
          : Number.isNaN(Number(range[ran].amount))
          ? 0
          : Number(range[ran].amount),
      };
    });
  return processedValue;
};

export const processBudgetPayloadData = (
  data: AccountNode[],
  dateRangeToInclude: string[]
) => {
  let filteredAccount = data.filter((account) => account.id);
  let processedData: BudgetEntry[] = filteredAccount.map((account) => {
    return {
      account_id: Number(account.id),
      monthly_values: processDateRangeValue(
        account.range,
        dateRangeToInclude,
        account.is_category
      ),
    };
  });
  return processedData;
};

// Function to extract account names from the response
export const extractAccountNames = (accounts: any[]): string[] => {
  const names: string[] = [];
  const traverseNodes = (nodes: any[]) => {
    nodes.forEach((node) => {
      names.push(node.account_name);
      if (node.node) traverseNodes(node.node);
    });
  };
  traverseNodes(accounts);
  return names;
};

// Function to set label of Actuals and Periods
export const getActualsOrPeriodsLabel = (Value: number): string => {
  return Value === 0 ? "None" : `${Value} Months`;
};
