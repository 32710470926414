import { AccountNode, ProfitAndLoss, DataInput } from "../types";

const mapping = [
  {
    accountName: "Direct Incomes",
    accountType: "Incomes",
    profitKey: "total_operating_income",

    label: "Total Operating Income",
  },
  {
    accountName: "Direct Expenses",
    accountType: "Expenses",
    profitKey: "total_cost_of_goods_sold",
    label: "Total Cost of Goods Sold",
  },
  {
    accountName: "Direct Expenses",
    accountType: "Expenses",
    profitKey: "gross_profit",
    label: "Gross Profit",
  },
  {
    accountName: "Indirect Expenses",
    accountType: "Expenses",
    profitKey: "total_operating_expense",
    label: "Total Operating Expense",
  },
  {
    accountName: "Indirect Incomes",
    accountType: "Incomes",
    profitKey: "non_operating_income",
    label: "Non-Operating Income",
  },
  {
    accountName: "Indirect Expenses",
    accountType: "Expenses",
    profitKey: "net_profit_loss_before_tax",
    label: "Net Profit/Loss Before Tax",
  },
  {
    accountName: "Expenses",
    accountType: "Expenses",
    profitKey: "net_profit_loss_after_tax",
    label: "Net Profit/Loss After Tax",
  },
];

// Unique ID generator
const generateUniqueId = (): string => {
  return Math.random().toString(36).substr(2, 9);
};

const processData = (data: DataInput): AccountNode[] => {
  // Step 1: Add Profit and Loss Data
  data.account_details.forEach((account) => {
    const addProfitLoss = (node: AccountNode) => {
      mapping.forEach(({ accountName, profitKey, label,accountType }) => {
        if (node.account_name === accountName) {
          const profitData = data.profit_and_loss[profitKey];
          if (profitData) {
            const newNode: AccountNode = {
              id: generateUniqueId(),
              parent_id: null,
              account_name: label,
              account_type: accountType,
              depth: node.depth + 1,
              is_category: false,
              is_positive: false,
              is_cumulated_positive: false,   
              node: [],
              actual_total: profitData.actual_total,
              budget_total: profitData.budget_total,
              variance: profitData.variance,
              variance_percent: profitData.variance_percent,
              cumulated_total: profitData.cumulated_total,
              cumulated_budget_total: profitData.cumulated_budget_total,
              cumulated_variance: profitData.cumulated_variance,
              cumulated_variance_percent: profitData.cumulated_variance_percent,
              style_class:"total-node"
            };
            node.node.push(newNode);
          }
        }
      });

      node.node.forEach(addProfitLoss);
    };

    addProfitLoss(account);
  });

  // Step 2: Flatten the account structure
  const flattenedAccounts: AccountNode[] = [];

  const flattenAccounts = (node: AccountNode) => {
    const { node: nestedNodes, ...rest } = node;
    flattenedAccounts.push({ ...rest, node: [] });
    nestedNodes.forEach(flattenAccounts);
  };

  data.account_details.forEach(flattenAccounts);

  return flattenedAccounts;
};

export { processData };
