import { useEffect, useState } from "react";
import { setLoaderState } from "../../../common/commonSlice";
import { cancelClosingSchedule } from "../fiscalYearClosingSlice";
import { ErrorToaster, SuccessToaster } from "../../../../helpers/toastHelper";
import { message } from "../../../constants/messages";
import {
  fetchStartDate,
  generateClosingDates,
  getFiscalYears,
} from "../utils/helper";
import { ReopenFiscalYearsModal } from "../components/reopenFiscalYearsModal/reopenFiscalYearsModal";
import {
  CalendarIconGreyed,
  ReopenIconGreyed,
} from "../../../../assets/images";
import {
  getFormatedDate,
  getFormatedDateDMY,
} from "../../../../helpers/helper";
import {
  closeFiscalYear,
  scheduledClosingFiscalYear,
  scheduledForClosingFiscalYear,
} from "../fiscalYearClosingAPI";
import useCommonData from "../../../hooks/useCommon";
import CloseFiscalYear from "../components/closeFiscalYearModal/closeFiscalYearsModal";
import CancelScheduleForClosing from "../components/cancelClosingScheduleModal/cancelClosingScheduleModal";
import CancelReopenFiscalYears from "../components/cancelReopenFiscalYears/cancelReopenFiscalYears";
import Loader from "../../components/Loader";
import "./FiscalYearClosingCard.css";

const FiscalYearClosingCard = () => {
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [cancelClosing, setCancelClosing] = useState<boolean>(false);
  const [cancelReopen, setCancelReopen] = useState<boolean>(false);
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);
  const [sheduledForClosing, setSheduledForClosing] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountingStartDate, setAccountingStartDate] =
    useState<string | null>(null);
  const { dispatch, currentUserInfo } = useCommonData();
  let tommorow = new Date();
  tommorow.setDate(tommorow.getDate() + 1);

  const onCloseFiscalYears = () => {
    setOpenCloseModal(true);
  };

  const handleSubmit = async () => {
    const dates = generateClosingDates(
      account_closing_date || accounting_start_date,
      fiscal_year_end_date
    );
    const data = {
      orgId: currentUserInfo.organization_id,
      closing_date: dates,
    };
    try {
      await closeFiscalYear(data);
      const sheduledClosing = await scheduledForClosingFiscalYear(data);
      setSheduledForClosing(sheduledClosing);
      setOpenCloseModal(false);
      // navigate("/waiting");
      SuccessToaster(
        "Account closing scheduled successfully",
        "fiscal-year-close-success"
      );
    } catch (error) {
      ErrorToaster(message().fiscalYearNotClosed);
    }
  };

  useEffect(() => {
    fetchStartDate(currentUserInfo.organization_id, setAccountingStartDate);
  }, [currentUserInfo.organization_id]);

  const accounting_start_date: any = accountingStartDate
    ? accountingStartDate
    : "";
  const fiscal_year_end_date: any = currentUserInfo.previous_fiscal_end_date;
  const account_closing_date: string | null =
    currentUserInfo.account_closing_date;

  useEffect(() => {
    const fetchScheduledClosing = async () => {
      const data = { orgId: currentUserInfo.organization_id };
      try {
        // await scheduledClosingFiscalYear(data);
        const sheduledClosing = await scheduledForClosingFiscalYear(data);
        setSheduledForClosing(sheduledClosing);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchScheduledClosing();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    const startDate = account_closing_date || accounting_start_date;
    const fiscalYearsList = getFiscalYears(startDate, fiscal_year_end_date);
    setFiscalYears(fiscalYearsList);
  }, [account_closing_date, fiscal_year_end_date, accounting_start_date]);

  const handleClosingCancel = () => {
    setCancelClosing(true);
  };

  const handleCancelReopen = () => {
    setCancelReopen(true);
  };

  const onCancellingClosing = async () => {
    setLoaderState(true);
    const closingDates = sheduledForClosing?.scheduled_closing_dates
      ? sheduledForClosing?.scheduled_closing_dates.map((date: Date) => {
          return getFormatedDate(date);
        })
      : [];
    await dispatch(
      cancelClosingSchedule({
        orgId: currentUserInfo.organization_id,
        closing_date: closingDates,
      })
    );
    const sheduledClosing = await scheduledForClosingFiscalYear({
      orgId: currentUserInfo.organization_id,
    });
    setSheduledForClosing(sheduledClosing);
    SuccessToaster(
      "Scheduled closing cancelled",
      "fiscal-year-cancel-schedule-closing-success"
    );
    setCancelClosing(false);
    setLoaderState(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="fiscal-year-closing-card-wrapper">
      <li>
        <div className="item-wrapper w-100 d-flex">
          <div className="left-sect v-top">
            <img
              src={CalendarIconGreyed}
              alt="locked-item-img"
              width={60}
              height={60}
            />
            <div className="item-det-sect">
              <div className="details-wrapper">
                {fiscalYears.length > 0 &&
                !sheduledForClosing?.is_closing_scheduled ? (
                  <>
                    <h5>Close last {fiscalYears.length} fiscal years</h5>
                    <p>You can lock last {fiscalYears.length} fiscal years</p>
                    <div className="fiscal-year-closing-card-year-list">
                      {fiscalYears.map((year) => (
                        <div
                          key={year}
                          className="fiscal-year-closing-card-year-list-single"
                        >
                          {year}
                        </div>
                      ))}
                    </div>
                  </>
                ) : sheduledForClosing?.is_closing_scheduled ? (
                  <>
                    <h5>Close fiscal years</h5>
                    <p>
                      Closing for {fiscalYears.map((years) => `${years}, `)}is
                      scheduled for {getFormatedDateDMY(tommorow)} 00:00:00
                    </p>
                  </>
                ) : (
                  <>
                    <h5>Close fiscal years</h5>
                    <p>No completed fiscal year is available for closing</p>
                  </>
                )}
              </div>
            </div>
          </div>
          {sheduledForClosing?.is_closing_scheduled ? (
            <div className="btn-sect-cancelling">
              <button
                className="btn lock-button green-button right-button ml-auto"
                type="button"
                onClick={handleClosingCancel}
              >
                Cancel Scheduled Closing
              </button>
            </div>
          ) : (
            <div className="btn-sect">
              <button
                className="btn lock-button green-button right-button ml-auto"
                type="button"
                onClick={onCloseFiscalYears}
                disabled={fiscalYears.length === 0}
              >
                Close Fiscal Years
              </button>
            </div>
          )}
        </div>
      </li>
      <li>
        <div className="item-wrapper w-100 d-flex">
          <div className="left-sect v-top">
            <img
              src={ReopenIconGreyed}
              alt="locked-item-img"
              width={60}
              height={60}
            />
            <div className="item-det-sect">
              <div className="details-wrapper">
                <h5>Reopen fiscal years</h5>
                {account_closing_date === null ||
                account_closing_date === accounting_start_date ||
                accounting_start_date > account_closing_date ? (
                  <p>No closed fiscal year is available for reopening</p>
                ) : (
                  <p>
                    You can reopen previous fiscal year (from{" "}
                    {parseInt(account_closing_date.split("-")[0])} and beyond)
                  </p>
                )}
              </div>
            </div>
          </div>
          {sheduledForClosing?.is_closing_scheduled ? (
            <div className="btn-sect">
              <button
                className="btn lock-button green-button right-button ml-auto"
                type="button"
                onClick={handleCancelReopen}
                disabled={
                  account_closing_date === null ||
                  account_closing_date.split("-")[0] ===
                    accounting_start_date.split("-")[0]
                }
              >
                Reopen Fiscal Years
              </button>
            </div>
          ) : (
            <div className="btn-sect">
              <ReopenFiscalYearsModal
                buttonContent="Reopen Fiscal Years"
                onClose={() => {}}
                buttonClass={`btn lock-button green-button right-button ml-auto ${
                  account_closing_date === null ||
                  account_closing_date === accounting_start_date
                    ? "disabled"
                    : ""
                }`}
                accounting_start_date={accounting_start_date}
                accountClosingDate={account_closing_date}
                fiscalYearEndDate={fiscal_year_end_date}
              />
            </div>
          )}
        </div>
      </li>
      {openCloseModal && (
        <CloseFiscalYear
          handleClose={() => setOpenCloseModal(false)}
          handleSubmit={handleSubmit}
        />
      )}
      {cancelClosing && (
        <CancelScheduleForClosing
          handleClose={() => setCancelClosing(false)}
          handleSubmit={onCancellingClosing}
        />
      )}
      {cancelReopen && (
        <CancelReopenFiscalYears
          handleClose={() => setCancelReopen(false)}
          handleSubmit={onCancellingClosing}
        />
      )}
    </div>
  );
};

export default FiscalYearClosingCard;
