import {
  AccountNode,
  DataStructure,
  ProfitAndLoss,
  Ranges,
} from "../types/types";

const addProfitAndLossNodes = (
  nodes: AccountNode[],
  profit_and_loss: ProfitAndLoss
) => {
  // Helper to create the new node based on profit_and_loss data
  const createNode = (
    accountName: string,
    profitKey: keyof ProfitAndLoss[string]
  ): AccountNode => {
    return {
      id: null,
      parent_id: null,
      account_name: accountName,
      account_type: "",
      depth: null,
      is_category: false,
      is_editable: false,
      range: Object.keys(profit_and_loss).reduce((acc, dateRange) => {
        acc[dateRange] = {
          total: profit_and_loss[dateRange][profitKey] as string,
          transaction_exist: false,
          is_editable: false,
          amount:"0",
        };
        return acc;
      }, {} as Ranges),
      node: [],
      style_class: "total-nodes",
    };
  };

  // Define the mappings
  const mappings: {
    accountName: string;
    profitKey: keyof ProfitAndLoss[string];
    label: string;
  }[] = [
    {
      accountName: "Direct Incomes",
      profitKey: "total_operating_income",
      label: "Total Operating Income",
    },
    {
      accountName: "Direct Expenses",
      profitKey: "total_cost_of_goods_sold",
      label: "Total Cost of Goods Sold",
    },
    {
      accountName: "Direct Expenses",
      profitKey: "gross_profit",
      label: "Gross Profit",
    },
    {
      accountName: "Indirect Expenses",
      profitKey: "total_operating_expense",
      label: "Total Operating Expense",
    },
    {
      accountName: "Indirect Incomes",
      profitKey: "non_operating_income",
      label: "Non-Operating Income",
    },
    {
      accountName: "Indirect Expenses",
      profitKey: "net_profit_loss_before_tax",
      label: "Net Profit/Loss Before Tax",
    },
    {
      accountName: "Expenses",
      profitKey: "net_profit_loss_after_tax",
      label: "Net Profit/Loss After Tax",
    },
  ];

  // Recursive function to find the right node and add the profit_and_loss nodes
  const addNodes = (node: AccountNode) => {
    node?.node?.forEach((child) => addNodes(child));
    mappings?.forEach(({ accountName, profitKey, label }) => {
      if (node.account_name === accountName) {
        const existingNode = node.node.find((n) => n.account_name === label);
        if (!existingNode) {
          const newNode = createNode(label, profitKey);
          node.node.push(newNode);
        }
      }
    });
  };

  nodes?.forEach((node) => addNodes(node));
};
const makeRangesEditable = (
  flattenedAccounts: AccountNode[],
  editableRangeKeys: string[],
  maxDepth: number
) => {
  flattenedAccounts?.forEach((node) => {
    if (
      node.depth !== null &&
      node.depth <= maxDepth &&
      node.is_category === false
    ) {
      editableRangeKeys?.forEach((key) => {
        if (node.range[key]) {
          node.range[key].is_editable = true;
        }
      });
    }
  });
};
export const flattenAndAppend = (
  data: DataStructure,
  editableRangeKeys: string[]
): AccountNode[] => {
  // Define the mappings
  const mappings: {
    accountName: string;
    profitKey: keyof ProfitAndLoss[string];
    label: string;
  }[] = [
    {
      accountName: "Direct Incomes",
      profitKey: "total_operating_income",
      label: "Total Operating Income",
    },
    {
      accountName: "Direct Expenses",
      profitKey: "total_cost_of_goods_sold",
      label: "Total Cost of Goods Sold",
    },
    {
      accountName: "Direct Expenses",
      profitKey: "gross_profit",
      label: "Gross Profit",
    },
    {
      accountName: "Indirect Expenses",
      profitKey: "total_operating_expense",
      label: "Total Operating Expense",
    },
    {
      accountName: "Indirect Incomes",
      profitKey: "non_operating_income",
      label: "Non-Operating Income",
    },
    {
      accountName: "Indirect Expenses",
      profitKey: "net_profit_loss_before_tax",
      label: "Net Profit/Loss Before Tax",
    },
    {
      accountName: "Expenses",
      profitKey: "net_profit_loss_after_tax",
      label: "Net Profit/Loss After Tax",
    },
  ];
  const { accounts, profit_and_loss } = data;
  addProfitAndLossNodes(accounts, profit_and_loss);

  const flattenedAccounts: AccountNode[] = [];

  const flatten = (nodes: AccountNode[], parent: AccountNode | null) => {
    nodes.forEach((node) => {
      // Add the current node to the flattened list
      flattenedAccounts.push({ ...node, parent_id: parent?.id || null });
      // Recursively flatten child nodes
      if (node.node && node.node.length > 0) {
        flatten(node.node, node);
      }
    });
  };

  flatten(accounts, null);
  makeRangesEditable(flattenedAccounts, editableRangeKeys, 7);
  return flattenedAccounts;
};
