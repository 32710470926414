import * as React from "react";
import $ from "jquery";
import "bootstrap-daterangepicker";

export interface EventHandler {
  (event: JQuery.Event, picker: any): any;
}
export interface CallbackHandler {
  (start?: any, end?: any, label?: string): any;
}
export interface Props {
  initialSettings?: any;

  // events supported by the upstream lib
  onApply?: EventHandler;
  onCancel?: EventHandler;
  onHide?: EventHandler;
  onHideCalendar?: EventHandler;
  onShow?: EventHandler;
  onShowCalendar?: EventHandler;

  // custom events in this lib
  onEvent?: EventHandler;
  onCallback?: CallbackHandler;
}

export class DateRangePicker extends React.Component<
  React.PropsWithChildren<Props>
> {
  ref: any;
  $picker: any;
  constructor(props: Props) {
    super(props);
    this.ref = null;
    this.$picker = null;
  }
  componentDidMount() {
    // initialize daterangepicker
    this.$picker = $(this.ref);
    this.$picker.daterangepicker(
      this.props.initialSettings,
      this.handleCallback.bind(this)
    );
    let selectedRange = this.props.initialSettings.selectedRange;

    $('[data-range-key="Custom Range"]').text("Custom");
    // attach event listeners
    ["Show", "Hide", "ShowCalendar", "HideCalendar", "Apply", "Cancel"].forEach(
      (event) => {
        const lcase = event.charAt(0).toLowerCase() + event.slice(1);
        this.$picker?.on(
          lcase + ".daterangepicker",
          this.makeEventHandler("on" + event)
        );
      }
    );
    //this.$picker?.attr('tabindex', 0);
    // this.$picker.on('keydown', (e:any) => {
    //   let _keyCode = e.keyCode || e.which;
    //   let _keyName = e.key;
    //   let item = e.target;
    //   if (_keyCode == "9" || _keyName === "Tab") {
    //     e.preventDefaul();
    //   }
    // });
    if (selectedRange) {
      this.$picker
        ?.data("daterangepicker")
        ?.setStartDate(this.props.initialSettings.startDate);
      this.$picker
        ?.data("daterangepicker")
        ?.setEndDate(this.props.initialSettings.endDate);
    }

    this.$picker.on(
      "show.daterangepicker",
      function (ev: JQuery.Event, picker: any) {
        $(".ranges li").attr("tabindex", 0);
        $(".ranges li.active").focus();
        $(".ranges li").on("keydown", function (e: any) {
          let _keyCode = e.keyCode || e.which;
          let _keyName = e.key;
          let item = e.target;
          if (_keyCode == "40" || _keyName === "ArrowDown") {
            if ($(item).next().length > 0) {
              $(item).next().focus();
            }
          } else if (_keyCode == "38" || _keyName === "ArrowUp") {
            if ($(item).prev().length > 0) {
              $(item).prev().focus();
            }
          } else if (_keyCode == "13" || _keyName === "Enter") {
            $(item).trigger("click");
          } else if (_keyCode == "9" || _keyName === "Tab") {
            picker.hide();
            $("#reportrange").parent().focus();
          }
        });
      }
    );
    this.$picker.on(
      "hide.daterangepicker",
      function (ev: JQuery.Event, picker: any) {
        $(".ranges li").removeAttr("tabindex");
        $("#reportrange").parent().focus();
      }
    );
    let _calendarTimeout: any = null;
    this.$picker.on(
      "showCalendar.daterangepicker",
      function (ev: JQuery.Event, picker: any) {
        if (_calendarTimeout) {
          clearTimeout(_calendarTimeout);
        }
        _calendarTimeout = setTimeout(() => {
          $(".drp-buttons .cancelBtn").focus();
        }, 0);
      }
    );
    // this.$picker.on('hideCalendar.daterangepicker', function(ev:JQuery.Event, picker:any) {
    //   $('.ranges li.active').trigger('click');
    // });

    $(".drp-buttons .applyBtn").on("keydown", (e: any) => {
      let _keyCode = e.keyCode || e.which;
      let _keyName = e.key;
      let item = e.target;
      if (_keyCode == "9" || _keyName === "Tab") {
        // $('.ranges li.active').trigger('click');
        $(item).prev().trigger("click");
      }
    });
  }
  componentWillUnmount() {
    this.$picker?.data("daterangepicker")?.remove();
  }
  handleCallback(...args: any) {
    if (typeof this.props.onCallback === "function") {
      this.props.onCallback(...args);
    }
  }
  makeEventHandler(eventType: string) {
    const { onEvent } = this.props;
    return (event: JQuery.Event, picker: any) => {
      if (typeof onEvent === "function") {
        onEvent(event, picker);
      }
      if (typeof (this.props as any)[eventType] === "function") {
        (this.props as any)[eventType](event, picker);
      }
    };
  }
  setStartDate(dateOrString: any) {
    this.$picker?.data("daterangepicker")?.setStartDate(dateOrString);
  }
  setEndDate(dateOrString: any) {
    this.$picker?.data("daterangepicker")?.setEndDate(dateOrString);
  }
  // $(function() {

  // $('.ranges li').attr('tabindex', 0);

  // $(document).on('keydown', function(e:any) {
  //   let _key:any = e.keyCode || e.which;
  //   let daterangepicker: string = e.key;
  //   let _target = e.target;

  // })
  // });
  render() {
    const childElement: any = React.Children.only(this.props.children);
    return React.cloneElement(childElement, {
      ref: (el: any) => (this.ref = el),
    });
  }
}

export default DateRangePicker;
