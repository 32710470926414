import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import * as yup from "yup";
import { useFormik } from "formik";
import { ObjectType } from "../../../../../types";

type Props = {
  isDisabled?: boolean;
  wrapperClass?: string;
  marginTopClass?: string;
  marginBottomClass?: string;
  getFieldValues?: (data: ObjectType) => void;
  customFieldsArray: {
    id: number;
    field_name: string;
    mandatory_field: boolean;
    show_in_pdf: boolean;
    is_deleted: boolean;
  }[];
  handleSubmit?: () => ObjectType;
  initialValues: {
    [key: string]: {
      mandatory_field: boolean;
      show_in_pdf: boolean;
      value: string;
    };
  };
  editId?: number | null;
};

export interface FormInputFieldsRef {
  handleSubmit: () => void;
  errors: ObjectType;
}

export const CustomFieldInTransactions = forwardRef(
  (props: Props, ref?: React.ForwardedRef<any>) => {
    const nameGenerator = (name: string) => {
      return name.replace(" ", "_");
    };

    // Generate propertiesArray based on conditions
    const propertiesArray = props.editId
      ? Object.entries(props.initialValues).map(([fieldName, fieldData]) => ({
          type: "text",
          id: nameGenerator(fieldName),
          name: fieldName,
          className: "form-control custom-field-input-field",
          placeholder: `Enter ${fieldName.toLowerCase()}`,
          label: fieldName,
          isRequired: fieldData.mandatory_field,
          disabled: props.isDisabled,
          value: fieldData.value,
          error: "",
        }))
      : props.customFieldsArray
          .filter((field) => field.is_deleted === false)
          .map((field) => ({
            type: "text",
            id: nameGenerator(field.field_name),
            name: field.field_name,
            className: "form-control custom-field-input-field",
            placeholder: `Enter ${field.field_name.toLowerCase()}`,
            label: field.field_name,
            isRequired: field.mandatory_field,
            disabled: props.isDisabled,
            value: "",
            error: "",
          }));

    // Create validation schema dynamically
    const validationSchema = yup.object().shape(
      propertiesArray.reduce((schema: any, property: any) => {
        if (property.isRequired) {
          schema[property.name] = yup
            .string()
            .required(`${property.label} is required`);
        }
        return schema;
      }, {})
    );

    // Split properties into rows
    const propertiesInRows = [];
    for (let i = 0; i < propertiesArray.length; i += 2) {
      propertiesInRows.push(propertiesArray.slice(i, i + 2));
    }

    // Formik setup
    const formik = useFormik({
      initialValues: Object.fromEntries(
        propertiesArray.map((property) => [property.name, property.value])
      ),
      enableReinitialize: true,
      validationSchema: validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        const transformedValues = Object.fromEntries(
          Object.entries(values).map(([key, value]) => {
            const isMandatory = props.editId
              ? props.initialValues[key]?.mandatory_field
              : props.customFieldsArray.find(
                  (field) => field.field_name === key
                )?.mandatory_field || false;

            const showInPdf = props.editId
              ? props.initialValues[key]?.show_in_pdf
              : props.customFieldsArray.find(
                  (field) => field.field_name === key
                )?.show_in_pdf || false;

            return [
              key,
              {
                mandatory_field: isMandatory,
                show_in_pdf: showInPdf,
                value: value,
              },
            ];
          })
        );
        props.getFieldValues &&
          props.getFieldValues({
            values: transformedValues,
            isError: Object.keys(formik.errors).length > 0,
          });
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: async () => {
          await formik.submitForm();
        },
        errors: Object.keys(formik.errors).length > 0,
        values: formik.values,
        validate: formik.validateForm,
        errorList: formik.errors,
      }),
      [formik]
    );

    return (
      <form
        onSubmit={formik.handleSubmit}
        className={`${props.wrapperClass ? props.wrapperClass : ""}`}
      >
        {propertiesInRows.map((rowProperties, rowIndex) => (
          <div
            key={rowIndex}
            className={`row ${
              props.marginBottomClass ? props.marginBottomClass : [0,1].includes(rowIndex)?"mb-3":"mb-0"
            } ${props.marginTopClass ? props.marginTopClass : rowIndex===0? "mt-3":""}`}
          >
            {rowProperties.map((property, index) => (
              <FormInputField
                key={index}
                type={property.type}
                id={property.id}
                name={property.name}
                className={`${property.className} mw-325 ${
                  formik.errors[property.name] && formik.touched[property.name]
                    ? "error"
                    : ""
                }`}
                maxLength={300}
                placeholder={property.placeholder}
                label={property.label}
                isRequired={property.isRequired}
                disabled={property.disabled}
                value={formik.values[property.name]}
                error={
                  formik.touched[property.name]
                    ? formik.errors[property.name]
                    : undefined
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldTouched(property.name, true);
                  formik.handleChange(e);
                }}
              />
            ))}
          </div>
        ))}
      </form>
    );
  }
);
