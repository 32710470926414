import { ChangeEvent, Key, useEffect, useState } from "react";
import { FormInputField } from "../../../../common/components";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { message } from "../../../../constants/messages";
import { colors } from "../../../../constants/colors";
import {
  CloseRowIcon,
  DeleteIcon,
} from "../../../../common/components/customSvgIcons";
import useCommonData from "../../../../hooks/useCommon";
import InfoTooltip from "../../../../common/components/InfoTooltip";
import "./item.css";

function Item(props: any) {
  const [modKey, setModKey] = useState(props.sl);
  const { orgCurrencyList, currentUserInfo } = useCommonData();

  const addRowHandle = (e: ChangeEvent<HTMLInputElement>, key: any) => {
    e.preventDefault();
    if (props.item.price.length - 1 === key && showAddIcon())
      props.handleAddNewField(props.item);
  };

  const showAddIcon = () => {
    const lastValue = props.item.price[props.item.price.length - 1];
    if (parseFloat(lastValue.range_to) >= parseFloat(lastValue.range_from)) {
      return true;
    }
    return false;
  };

  const handleChangeTo = (e: ChangeEvent<HTMLInputElement>, key: any) => {
    if (props.item.price.length - 1 === key) {
      props.handleChangeFrom(e, key, props.item);
    }
  };

  useEffect(() => {
    if (props.defaultFormValues && props.defaultFormValues.items) {
      props.defaultFormValues.items[modKey] = {};
      props.defaultFormValues.items[modKey].plan_item_id = props.planItemId;
      props.setDefaultFormValues({ ...props.defaultFormValues });
    }
  }, [modKey, modKey]);
  return (
    <div className="item-cart">
      <div className="item-header d-flex justify-content-between align-items-center">
        <div>
          <b>{props.itemName}</b>{" "}
          {`(Rate: ${
            props.currencyCode
              ? props.currencyCode
              : currentUserInfo.currency_code
          } ${NumberFormat(
            Number(props.rate),
            props.currencyCode
              ? props.currencyCode
              : currentUserInfo.currency_code,
            orgCurrencyList
          )})`}
          <InfoTooltip
            title={
              props.name === "Charges"
                ? message().charges.rate
                : message().addon.rate
            }
            placement="top"
          />
        </div>
        {props.name === "Charges" && (
          <a
            href="#"
            className="removeItem"
            onClick={(e) => {
              e.preventDefault();
              props.onRemoveItem?.(props.planItemId);
            }}
          >
            <DeleteIcon color={colors.battleshipGrey} />
          </a>
        )}
      </div>

      {props.plan.value != "STAIRSTEP" &&
        props.plan.value != "PER_UNIT" &&
        props.plan.value != "TIERED" &&
        props.plan.value != "VOLUME" && (
          <div className="item-price-cont">
            <FormInputField
              type="text"
              name="price"
              id="price"
              className={
                props.formErrors.price
                  ? "form-control error item-price"
                  : "form-control item-price"
              }
              label="Price"
              placeholder="0.00"
              value={props.item.price[0].price}
              onChange={(e) => props.handleChangeFrom(e, 0, props.item)}
              isRequired={true}
              error={props.item.price[0].from}
            />
          </div>
        )}

      {props.plan.value == "PER_UNIT" && (
        <div className="item-price-cont">
          <div className="pricing-field-wrap pricing-field-wrap-per-unit">
            <FormInputField
              type="text"
              name="price"
              id="price"
              className={
                props.formErrors?.price
                  ? "form-control error item-price"
                  : "form-control item-price"
              }
              label="Price"
              placeholder="0.00"
              value={props.item?.price?.[0]?.price}
              onChange={(e) => props.handleChangeFrom(e, 0, props.item)}
              isRequired={true}
              error={props.item?.price?.[0]?.from}
            />
          </div>
        </div>
      )}

      {(props.plan.value == "STAIRSTEP" ||
        props.plan.value == "TIERED" ||
        props.plan.value == "VOLUME") && (
        <>
          {props.item?.price?.map((item: any, key: any | null | undefined) => (
            <>
              <div className="item-price-cont" key={key}>
                <div className="pricing-field-wrap">
                  <FormInputField
                    type="text"
                    name="range_from"
                    id="range_from"
                    className={"range-item-price form-control"}
                    label="From (Units)"
                    placeholder={key <= 0 ? "1" : ""}
                    maxLength={14}
                    value={item.range_from}
                    isRequired={true}
                    disabled={true}
                    errorClass={"priceErrorMessage"}
                  />
                  <FormInputField
                    type="text"
                    name="range_to"
                    id="range_to"
                    className={
                      item.fromErrors
                        ? "form-control error range-item-price"
                        : "form-control range-item-price"
                    }
                    label="To"
                    placeholder="& Above"
                    maxLength={14}
                    value={item.range_to}
                    onChange={(e) => handleChangeTo(e, key)}
                    onBlur={(e) => addRowHandle(e, key)}
                    isRequired={false}
                    error={item.fromErrors}
                    disabled={
                      props.item?.price.length - 1 !== key ? true : false
                    }
                    errorClass={"priceErrorMessage"}
                  />
                  <FormInputField
                    type="text"
                    name="price_per_step"
                    id="price_per_step"
                    className={
                      item.price_per_stepErrors
                        ? "form-control error range-item-price"
                        : "form-control range-item-price"
                    }
                    label="Price Per Step"
                    placeholder="0.00"
                    value={
                      item.price_per_step === 0 ? "0" : item.price_per_step
                    }
                    onChange={(e) => props.handleChangeFrom(e, key, props.item)}
                    isRequired={true}
                    error={item.price_per_stepErrors}
                    errorClass={"priceErrorMessage"}
                  />
                </div>
                <div className="close-row-container">
                  {props.item.price.length - 1 === key && (
                    <>
                      {key > 0 && (
                        <a
                          className="close-row"
                          onClick={() =>
                            props.removeRowHandle(
                              key,
                              props.item,
                              item.item_pricing_id
                            )
                          }
                        >
                          <CloseRowIcon />
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ))}
        </>
      )}

      <div className="border-container">
        <div className="border-div"></div>
      </div>
    </div>
  );
}

export default Item;
