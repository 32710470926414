import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import {
  CustomerItemValues,
  DNDefaultRef,
  DNDefaultValues,
  DebitNoteValues,
  FilesValues,
  ItemRef,
  ItemTaxValues,
  ObjectType,
  TDSTotalValues,
  TotalAmountCalculationValues,
  TotalDebitValues,
  TransactionItemValues,
} from "../../../../../types";
import {
  debitNoteAutoFillDataSelector,
  latestCurrencyRateSelector,
  setDebitNoteAutofillData,
  setLoaderState,
} from "../../../../common/commonSlice";
import ItemDetails from "../../../../common/components/lineItems/ItemDetails";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import { vendorDetailsForTransactions } from "../../../vendorManagement/vendorSlice";
import {
  attachDebitNoteFiles,
  checkDebitNoteNumberExist,
  createDebitNote,
  debitNoteDetails,
  debitNoteList,
  deleteDebitNoteFile,
  deleteDebitNoteItem,
  deleteDebitNoteItemTax,
  deleteDebitNoteRecipient,
  editDebitNoteDetails,
  getDebitNoteTaxableAmount,
  getDebitNoteTotalAmount,
  updateDebitNoteAllocation,
} from "../../debitNoteSlice";
import DeleteDebitNote from "../Delete";
import DefaultDetails from "./DefaultDetails";
import DropZone from "../../../../common/components/DropZone/DropZone";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../helpers/helper";
import _ from "lodash";
import ScrollToError from "../../../../common/components/scrollToError";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import TransactionHeader from "../../../../common/components/TransactionHeader";
import {
  noAccessErrorCodes,
  tdsApplyLevel,
  gstRegistrationTypes,
} from "../../../../constants/constants";
import {
  initialDefaultValues,
  initialTotalValues,
  initialValues,
} from "./StateInitialization";
import {
  getLineItemDetails,
  initialItemValues,
} from "../../../../../helpers/transactionHelper";
import { validate, validateItemValues } from "./ValidateDebitNoteForm";
import CreditNoteFormFooter from "../../../creditNote/components/creditNoteForm/CreditNoteFormFooter";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import { message } from "../../../../constants/messages";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useCommonData from "../../../../hooks/useCommon";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import {
  initialTCSTotalValues,
  initialTDSTotalValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../helpers/toastHelper";
import {
  clearGstTaxList,
  getGstTaxList,
  stateListSelector,
  getStateList,
} from "../../../gst/gstSlice";
import { getKeyByValue } from "../../../../../helpers/helper";

import useGst from "../../../../hooks/useGst";
import { TransactionEditTagRemovalAlertModal } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";

let interval: any = null;
function CreateDebitNote() {
  type LocationProps = {
    redirectTo?: string;
  };
  const location = usePreservedLocation();
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { debitNoteRolePermission } = usePermissionHandler();
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const debitNoteAutofillData = useAppSelector(debitNoteAutoFillDataSelector);
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const defaultRef = useRef<DNDefaultRef | null>(null);
  const itemRef = useRef<ItemRef | null>(null);
  const fileRef = useRef<any>([]);
  const locationState = location?.state as LocationProps;
  const redirectTo = locationState?.redirectTo;

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [currencyId, setCurrencyId] = useState<number | undefined>(
    Number(debitNoteAutofillData.debitNoteData.defaultFormValues.currency_id) ||
      undefined
  );
  const [isVendorName, setIsVendorName] = useState<boolean>(
    debitNoteAutofillData.isFromBill ? true : false
  );
  const [vendorId, setVendorId] = useState<number>();
  const [isDebitNoteNumberExist, setIsDebitNoteNumberExist] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emailId: number }[]>(
    []
  );
  const [statesFlag, setStatesFlag] = useState<boolean>(false);
  const [formSubmit, setFormSubmit] = useState(true);
  const [deleteDebitNoteId, setDeleteDebitNoteId] = useState(-1);
  const [deleteDebitItemId, setDeleteDebitItemId] = useState("");
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [debitNoteFormValues, setDebitNoteFormValues] =
    useState<DebitNoteValues>(
      debitNoteAutofillData.isFromBill
        ? debitNoteAutofillData.debitNoteData.debitNoteFormValues
        : initialValues
    );
  const [defaultFormValues, setDefaultFormValues] = useState<DNDefaultValues>(
    debitNoteAutofillData.isFromBill
      ? debitNoteAutofillData.debitNoteData.defaultFormValues
      : initialDefaultValues
  );
  const [itemFormValues, setItemFormValues] = useState<CustomerItemValues[]>(
    debitNoteAutofillData.isFromBill
      ? debitNoteAutofillData.debitNoteData.itemFormValue
      : [initialItemValues]
  );
  const [taxFormValues, setTaxFormValues] = useState<CustomerItemValues[]>([
    initialItemValues,
  ]);
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDebitValues>(initialTotalValues);
  const [itemFormErrors, setItemFormErrors] = useState<CustomerItemValues[]>(
    []
  );
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const [deleteDebitNoteStatus, setDeleteDebitNoteStatus] = useState("");
  const [isError, setIsError] = useState(false);
  const [action, setAction] = useState("");
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [conversionDate, setConversionDate] = useState(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [debitNoteStatus, setDebitNoteStatus] = useState("");
  const [conversionCurrencyId, setConversionCurrencyId] = useState<
    number | undefined
  >(
    Number(debitNoteAutofillData.debitNoteData.defaultFormValues.currency_id) ||
      undefined
  );
  const [gstRegistrationType, setGstRegistrationType] = useState("");

  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );

  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [isTotalEditable, setIsTotalEditable] = useState(false);
  const [editTotal, setEditTotal] = useState(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);

  const [total, setTotal] = useState<number | string>(0);
  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");

  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();

  const { isGstOrg } = useGst();
  const stateList = useAppSelector(stateListSelector);
  const [isVendorChanged, setIsVendorChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const openEditAlertRef = useRef<any>();
  const [vendorPurchaseDefault, setVendorPurchaseDefault] = useState(null);
  const [emailFormErrors, setEmailFormErrors] = useState("");
  const [rcmEnabled, setRcmEnabled] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(
        setDebitNoteAutofillData({
          isFromBill: false,
          balanceDue: 0,
          billId: null,
          billStatus: "",
          debitNoteData: {
            debitNoteFormValues: initialValues,
            defaultFormValues: initialDefaultValues,
            itemFormValues: initialItemValues,
          },
        })
      );
    };
  }, []);
  useEffect(() => {
    if (
      debitNoteAutofillData?.debitNoteData?.debitNoteFormValues?.vendor_id !==
      ""
    ) {
      getPurchaseDefaultOfVendor();
    }
  }, [debitNoteAutoFillDataSelector]);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId) {
      getRoundOffPreference();
    }
    if (editId) {
      debitNoteDetailsResponse();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [debitNoteFormValues, statesFlag]);

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);
  const getPurchaseDefaultOfVendor = async () => {
    let vendorDetails = await getVendorDetails(
      Number(debitNoteAutofillData.debitNoteData.debitNoteFormValues.vendor_id)
    );
    setVendorPurchaseDefault(vendorDetails.purchase_default);
    setGstRegistrationType(vendorDetails.gst_registration_type);
    setIsVendorName(true);
    setVendorId(Number(vendorDetails.id));
    setVendorPurchaseDefault(vendorDetails.purchase_default);
  };
  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
        roundoff_transaction_type: "Purchases",
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  /**
   * Get details for the edit debit note purpose.
   */
  const debitNoteDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      debitNoteDetails({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      const isLocked = response.is_locked;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg &&
        !isLocked
      ) {
        let defaultValues = {
          vendor_id: response.vendor_id,
          vendor_name: response.vendor_name,
          debit_note_number: response.debit_note_number,
          subject: response.subject,
          debit_note_date: response.debit_note_date,
          currency_id: response.currency_id,
          reference_number: response.reference_number,
          tds_level: response.tds_level,
          source_of_supply: {
            label: response.source_of_supply,
            value: response.source_of_supply,
          },
          destination_of_supply: {
            label: response.destination_of_supply,
            value: response.destination_of_supply,
          },
          rcm_enabled: response.rcm_enabled ? response.rcm_enabled : false,
        };
        let stateType =
          defaultValues.source_of_supply.value ===
          defaultValues.destination_of_supply.value
            ? "Intra State"
            : "Inter State";
        setInitialStateType(stateType);
        setRcmEnabled(response.rcm_enabled);
        let vendorDetails = await getVendorDetails(Number(response.vendor_id));

        if (isGstOrg) {
          let transaction_tax_details = [] as any;
          response.item_details.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group,
                tax_id: gst_tax?.tax_id,
              });
            }
          });

          let state_type =
            !defaultValues.source_of_supply ||
            !defaultValues.destination_of_supply
              ? ""
              : defaultValues.source_of_supply.value ===
                defaultValues.destination_of_supply.value
              ? "Intra State"
              : "Inter State";
          setStateType(state_type);
          if (
            vendorDetails.gst_registration_type === gstRegistrationTypes.sez ||
            vendorDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            vendorDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            state_type = "Inter State";
          }

          let stateCode = await getStateCode(
            defaultValues.destination_of_supply.value
          );

          await fetchGstTaxList(
            state_type,
            vendorDetails.gst_registration_type,
            transaction_tax_details,
            stateCode
          );
          setGstRegistrationType(vendorDetails.gst_registration_type);
          setVendorPurchaseDefault(vendorDetails.purchase_default);
        }
        let otherValues = {
          vendor_notes: response.vendor_notes,
          file_ids: response.debit_note_files.map((file: any) => file.id),
          tag_ids: response.tags.map((tag: any) => tag.id),
          items: response.item_details,
          email_to: response.email_to,
          debit_note_status: response.debit_note_status,
          conversion_id: response.conversion_id,
          is_locked: response.is_locked,
          lock_date: response.lock_date,
          exclude_discount_accounting: vendorDetails.purchase_default
            .exclude_discount_accounting
            ? vendorDetails.purchase_default.exclude_discount_accounting
            : false,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage ? response.tds_percentage : 0,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name,
        };
        setTotalTdsValues(totalTdsValue);
        setConversionDate(getFormatedDate(response.debit_note_date));
        setUploadedFiles(response.debit_note_files);
        setFileIds(response.debit_note_files.map((file: any) => file.id));
        setInsertedFiles(response.debit_note_files.map((file: any) => file.id));
        setCurrencyCode(response.currency_code);
        setCurrencyId(response.currency_id);
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setConversionCurrencyId(response.currency_id);
        setDefaultFormValues({ ...defaultValues });
        setDebitNoteFormValues({ ...defaultValues, ...otherValues });
        setDebitNoteStatus(response.debit_note_status);

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Purchases",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });

        let itemDetails = getLineItemDetails(response.item_details);
        let tdsIdArray: number[] = [];
        itemDetails.map((item: any, index: number) => {
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (itemDetails.length) {
          setItemFormValues([...itemDetails]);
          setTaxFormValues(itemDetails);
        } else {
          setItemFormValues([initialItemValues]);
          setTaxFormValues([initialItemValues]);
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
        }
        setTdsIds(tdsIdArray);
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
        }
        setTcsIds(tcsIdArray);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.email_to;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setVendorId(response.vendor_id);
        setEditTotal(response?.total_details.total);
      } else {
        if (isLocked) {
          navigate(-1);
          ErrorToaster(
            `Transactions before ${DateFormatHandler(
              response.lock_date
            )} have been locked. Hence action cannot be performed`
          );
        } else {
          dispatch(setErrorID(noAccessErrorCodes.DNEdit));
        }
      }
    }
  };
  /**
   * Input change handler in Add debit note
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "vendor_notes" && value.length <= 300) {
      setDebitNoteFormValues({ ...debitNoteFormValues, [name]: value });
    } else {
      setDebitNoteFormValues({ ...debitNoteFormValues, [name]: value });
    }
  };
  /**
   * Vendor change handler
   */
  const handleVendorChange = async (e: any) => {
    if (e.value) {
      setIsVendorChanged(true);
      let response = await getVendorDetails(Number(e.value));
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }

      if (state_type !== "") {
        await fetchGstTaxList(state_type, response.gst_registration_type);
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsVendorName(true);
      setVendorId(Number(e.value));
      setVendorPurchaseDefault(response.purchase_default);
    }
  };

  /**
   * Fetch vendor details by id
   */
  const getVendorDetails = async (vendorId: number) => {
    const responseAction = await dispatch(
      vendorDetailsForTransactions({
        vendorId: vendorId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (defaultRef.current?.defaultData) {
          let defaultFormValues = {
            ...defaultRef.current?.defaultData,
            currency_id: response.other_details.currency_id,
            exclude_discount_accounting: response.purchase_default
              .exclude_discount_accounting
              ? response.purchase_default.exclude_discount_accounting
              : false,
          };
          setDefaultFormValues({ ...defaultFormValues });
        }
        setCurrencyId(response.other_details.currency_id);
      }
    }
    return responseAction.payload;
  };

  /**
   * Check debit note number already exist
   */
  const checkDebitNoteNumberExists = async (debitNoteNumber: string) => {
    if (!debitNoteNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkDebitNoteNumberExist({
            debitNoteNumber: debitNoteNumber,
            orgId: currentUserInfo.organization_id,
            debitNoteId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.debit_note_number_available === false) {
              setIsDebitNoteNumberExist(true);
              setFormErrors({
                ...formErrors,
                ["debit_note_number"]:
                  "Debit note number already exists. Please choose a different one",
              });
            } else {
              setIsDebitNoteNumberExist(false);
              setFormErrors({ ...formErrors, ["debit_note_number"]: "" });
            }
          }
        }
      }, 1000);
    }
  };
  /**
   * function to get total amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    const responseAction = await dispatch(getDebitNoteTotalAmount(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: CustomerItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getDebitNoteTaxableAmount(data));
    return taxResponseAction;
  };

  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };

  const asynchronousSubmit = async (status: string, action: string) => {
    await handleCustomSubmission();
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Vendors")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string) => {
    if (status === "Draft") {
      setAction("save as draft");
    } else if (status === "Open") {
      setAction("save as open");
    }
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let debitNoteInputs: any = {
      ...debitNoteFormValues,
      ...defaultValues,
      ...totalTdsValues,
      custom_fields: custom_fields.current.values,
      tds_level: getTdsApplyLevel(),
    };
    let errors,
      itemErrorResponse: any = [];
    let itemErrors: any = [];

    errors = validate(
      debitNoteInputs,
      action,
      isDebitNoteNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;
    let taxValues = itemRef.current?.taxValues
      ? itemRef.current?.taxValues
      : taxFormValues;
    itemValues.map((item, itemIndex) => {
      itemErrorResponse = validateItemValues(
        item,
        itemIndex,
        taxValues,
        debitNoteInputs.rcm_enabled,
        gstRegistrationType,
        debitNoteInputs?.exclude_discount_accounting
      );
      if (itemErrorResponse) {
        errorExist = true;
        ScrollToError(Object.keys(itemErrorResponse)[0]);
        itemErrors.push(itemErrorResponse);
      } else {
        itemErrors.push({});
      }
    });
    if (
      Object.keys(errors).length ||
      (errorExist && itemErrors.length) ||
      customFieldRef.current.errors ||
      emailFormErrors !== ""
    ) {
      setFormErrors({
        ...errors,
        email: emailFormErrors,
      });
      setItemFormErrors(itemErrors);
      setIsError(true);
    } else {
      setFormErrors({});
      setItemFormErrors([]);
      setIsError(false);
      if (editId) {
        if (editId && currentUserInfo.is_tags_enabled && !didAlertModalOpen) {
          setDidAlertModalOpen(true);
          openEditAlertRef.current?.openAlertModal &&
            openEditAlertRef.current?.openAlertModal();
          return;
        } else {
          UpdateDebitNoteSubmit(debitNoteInputs, itemValues, status, action);
        }
      } else {
        createDebitNoteSubmit(debitNoteInputs, itemValues, status, action);
      }
    }
  };
  /**
   * Create new Debit Note
   */
  const createDebitNoteSubmit = async (
    debitNoteInputs: any,
    itemValues: TransactionItemValues[],
    debitNoteStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    debitNoteInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof debitNoteInputs;
    for (key in debitNoteInputs) {
      if (debitNoteInputs[key] === "") {
        if (key === "tds_percentage" || key === "tds_amount") {
          debitNoteInputs[key] = 0;
        } else {
          debitNoteInputs[key] = null;
        }
      }
      if (
        key === "debit_note_number" &&
        debitNoteInputs[key] !== "" &&
        debitNoteInputs[key] !== null
      ) {
        debitNoteInputs[key] = defaultRef.current?.debitNoteNumber;
      }
      if (key === "reference_number" && debitNoteInputs[key]) {
        debitNoteInputs[key] = debitNoteInputs[key].toString().trim();
      }
      if (key === "destination_of_supply") {
        debitNoteInputs[key] = isGstOrg ? debitNoteInputs[key].value : null;
      }
      if (
        key === "source_of_supply" &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        debitNoteInputs[key] = isGstOrg ? debitNoteInputs[key].value : null;
      } else if (
        key === "source_of_supply" &&
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        debitNoteInputs[key] = null;
      }
      if (key === "tds_amount") {
        debitNoteInputs[key] =
          debitNoteInputs[key] === null ? 0 : debitNoteInputs[key];
      }
    }
    let debitNoteItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of debitNoteItems) {
      let itemInputs = items;

      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemKey: keyof typeof itemInputs;
      for (itemKey in itemInputs) {
        if (itemInputs[itemKey] === "") {
          if (itemKey === "rate" || itemKey === "quantity") {
            itemInputs[itemKey] = 0;
          } else {
            itemInputs[itemKey] = null;
          }
        }
        if (itemKey === "discount") {
          if (itemInputs[itemKey]?.discount_value == 0) {
            itemInputs[itemKey] = itemInputs[itemKey]?.discount_value;
          } else if (itemInputs[itemKey]?.discount_percentage == 0) {
            itemInputs[itemKey] = itemInputs[itemKey]?.discount_percentage;
          } else {
            itemInputs[itemKey] = Number(itemInputs[itemKey]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    debitNoteInputs.items = debitNoteItems;
    debitNoteInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      debitNoteInputs.email_to = emailLists;
    }

    debitNoteInputs.total = calculatedTotal;
    debitNoteInputs.roundoff_enabled = isRoundOffEnabled;
    debitNoteInputs.is_total_editable = isTotalEditable;

    $("#form-btn-invoice").addClass("customer-form-section-disable");
    const createResponseAction = await dispatch(
      createDebitNote({
        values: debitNoteInputs,
        status: debitNoteStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      dispatch(setLoaderState(false));
      let debitNoteId = createResponse.id;
      if (
        debitNoteAutofillData.isFromBill &&
        debitNoteAutofillData.billStatus !== "Closed"
      ) {
        const allocationResponseAction = await dispatch(
          updateDebitNoteAllocation({
            orgId: currentUserInfo.organization_id,
            debitNoteId: createResponse.id,
            values: [
              {
                bill_id: Number(debitNoteAutofillData.billId),
                amount_to_debit:
                  Number(debitNoteAutofillData.balanceDue) < Number(total)
                    ? Number(debitNoteAutofillData.balanceDue)
                    : Number(total),
              },
            ],
          })
        );
        const allocationResponse = allocationResponseAction.payload;
        if (
          Object.keys(allocationResponse).length &&
          !("error" in allocationResponse)
        ) {
          SuccessToaster(
            "Debit note allocated to the bill successfully!",
            "bill-allocation-success"
          );
        }
      }
      // if (redirectTo && !debitNoteAutofillData.isFromBill) {
      //   navigate(redirectTo);
      // } else if (debitNoteAutofillData.isFromBill) {
      //   navigate(`/bill/detail/${debitNoteAutofillData.billId}`);
      // } else {
      //   navigate("/debit-note");
      // }
      if (debitNoteStatus === "Draft") navigate(-1);
      else navigate(`/debit-note/detail/${debitNoteId}`, { replace: true });
      toast.success("Debit note created successfully!", {
        toastId: "debit-note-create-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in createResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const UpdateDebitNoteSubmit = async (
    debitNoteInputs: any,
    itemValues: TransactionItemValues[],
    debitNoteStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    debitNoteInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof debitNoteInputs;
    for (key in debitNoteInputs) {
      if (debitNoteInputs[key] === "") {
        if (key === "tds_percentage" || key === "tds_amount") {
          debitNoteInputs[key] = 0;
        } else {
          debitNoteInputs[key] = null;
        }
      }
      if (
        key === "debit_note_number" &&
        debitNoteInputs[key] !== "" &&
        debitNoteInputs[key] !== null
      ) {
        debitNoteInputs[key] = defaultRef.current?.debitNoteNumber;
      }
      if (key === "reference_number" && debitNoteInputs[key]) {
        debitNoteInputs[key] = debitNoteInputs[key].toString().trim();
      }
      if (key === "destination_of_supply") {
        debitNoteInputs[key] = isGstOrg ? debitNoteInputs[key].value : null;
      }
      if (
        key === "source_of_supply" &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        debitNoteInputs[key] = isGstOrg ? debitNoteInputs[key].value : null;
      } else if (
        key === "source_of_supply" &&
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        debitNoteInputs[key] = null;
      }
      if (key === "tds_amount") {
        debitNoteInputs[key] =
          debitNoteInputs[key] === null ? 0 : debitNoteInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");

    let deleteTaxItems = itemRef.current?.deleteTaxItems;
    if (deleteTaxItems) {
      for (let item of deleteTaxItems) {
        await dispatch(
          deleteDebitNoteItemTax({
            itemId: item.itemId,
            taxId: item.taxItemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    if (isGstOrg) {
      let deleteGstItems = itemRef.current?.deleteGstItems;
      if (deleteGstItems) {
        for (let item of deleteGstItems) {
          await dispatch(
            deleteDebitNoteItemTax({
              itemId: item.itemId,
              taxId: item.id,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
    }

    let deleteItems = itemRef.current?.deleteItems;
    if (deleteItems) {
      for (let item of deleteItems) {
        let res = await dispatch(
          deleteDebitNoteItem({
            debitNoteId: Number(editId),
            itemId: item.itemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emailId);
    });
    if (deleteContactIds.length) {
      await dispatch(
        deleteDebitNoteRecipient({
          debitNoteId: Number(editId),
          emailIds: deleteContactIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let debitNoteItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of debitNoteItems) {
      let itemInputs = items;

      // updating gst_id in request payload
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let key: keyof typeof itemInputs;
      for (key in itemInputs) {
        if (itemInputs[key] === "") {
          if (key === "rate" || key === "quantity") {
            itemInputs[key] = 0;
          } else {
            itemInputs[key] = null;
          }
        }
        if (key === "discount") {
          if (itemInputs[key]?.discount_value) {
            itemInputs[key] = itemInputs[key]?.discount_value;
          } else if (itemInputs[key]?.discount_percentage) {
            itemInputs[key] = itemInputs[key]?.discount_percentage;
          } else {
            itemInputs[key] = Number(itemInputs[key]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    debitNoteInputs.items = debitNoteItems;
    debitNoteInputs.file_ids = fileRef.current.fileIds;
    debitNoteInputs.email_to = emailLists;
    debitNoteInputs.total = calculatedTotal;
    debitNoteInputs.roundoff_enabled = isRoundOffEnabled;
    debitNoteInputs.is_total_editable = isTotalEditable;

    const updateResponseAction = await dispatch(
      editDebitNoteDetails({
        debitNoteId: Number(editId),
        values: debitNoteInputs,
        debitNoteStatus: debitNoteStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateResponse = updateResponseAction.payload;
    if (Object.keys(updateResponse).length && !("error" in updateResponse)) {
      dispatch(setLoaderState(false));
      let debitNoteId = updateResponse.id;
      // if (redirectTo) {
      //   navigate(redirectTo);
      // } else {
      //   navigate("/debit-note");
      // }
      if (debitNoteStatus === "Draft") navigate(-1);
      else {
        redirectUrl
          ? navigate(
              `/debit-note/detail/${Number(
                debitNoteId
              )}?redirect=${redirectUrl}`,
              {
                replace: true,
              }
            )
          : navigate(`/debit-note/detail/${debitNoteId}`, { replace: true });
      }
      toast.success("Debit note updated successfully!", {
        toastId: "debit-note-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in updateResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  /**
   * Upload selected files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("debit_note_file", file);
    let responseAction = await dispatch(
      attachDebitNoteFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };
  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteDebitNoteFile({
          debitNoteId: Number(editId),
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let debitNoteInputs: any = { ...debitNoteFormValues, ...defaultValues };
    let errors = validate(
      debitNoteInputs,
      "",
      isDebitNoteNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  const fetchDebitNoteListAfterDelete = async () => {
    const responseAction = await dispatch(
      debitNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        vendorList: [],
        statusList: [],
      })
    );
    // if (redirectTo) {
    //   navigate(redirectTo);
    // } else {
    //   navigate("/debit-note");
    // }
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };
  /**
   * Function to store emailIds to be deleted in Edit invoice
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      }
    });
  };
  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };
  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };
  const setErrorOnItemTaxChange = (errors: any) => {
    setItemFormErrors([...errors]);
  };

  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };
  const getTotalValue = (total: number | string) => {
    setTotal(total);
  };

  const getStateCode = async (stateName: string) => {
    let stateCode = "";
    if (Object.keys(stateList).length === 0) {
      const result = await dispatch(
        getStateList({ orgId: currentUserInfo.organization_id })
      );
      let states = result.payload;
      stateCode = getKeyByValue(states, stateName);
    } else {
      stateCode = getKeyByValue(stateList, stateName);
    }
    return stateCode;
  };

  const fetchGstTaxList = async (
    state_type: string,
    gstRegType: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }],
    stateCode?: string
  ) => {
    dispatch(clearGstTaxList());
    if (state_type === "Intra State" && !stateCode) {
      if (defaultRef.current?.defaultData.destination_of_supply?.value) {
        stateCode = await getStateCode(
          defaultRef.current?.defaultData.destination_of_supply?.value as string
        );
      } else if (defaultRef.current?.defaultData.source_of_supply?.value) {
        stateCode = await getStateCode(
          defaultRef.current?.defaultData.source_of_supply?.value as string
        );
      }
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      state_type = "Inter State";
    }
    dispatch(clearGstTaxList());

    const result = await dispatch(
      getGstTaxList({
        stateType: state_type,
        orgId: currentUserInfo.organization_id,
        transactionSource: "Debit Notes",
        stateCode,
        transaction_tax_details,
      })
    );
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };
  const handleEmailError = (error: string) => {
    setEmailFormErrors(error);
  };
  const rcmChangeHandler = (isRcmEnable: boolean) => {
    setRcmEnabled(isRcmEnable);
  };

  return (
    <>
      <div
        className="card card-user-management card-customer main-card overflowX-hidden h-100 card-bill-management mbottom-100"
        id="invoice-create"
      >
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Debit Note"
            transactionNumber={Number(editId)}
          />
        </div>
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body">
            <form
              name="add_invoice_form"
              id="add_invoice_form"
              className={`${
                isVendorName || editId
                  ? "cn-form add-module-item-form"
                  : "cn-form add-module-item-form inactive"
              } ${isError ? "with-form-error-alert" : ""}`}
              tabIndex={-1}
            >
              <div className="fields-wrapper pt-0">
                <div className="fields-wrappers">
                  <DefaultDetails
                    defaultFormValues={defaultFormValues}
                    handleVendorChange={handleVendorChange}
                    checkDebitNoteNumberExists={checkDebitNoteNumberExists}
                    formErrors={formErrors}
                    handleNewConversionDate={handleNewConversionDate}
                    ref={defaultRef}
                    customFieldRef={customFieldRef}
                    getCustomFieldData={getCustomFieldData}
                    customFieldValue={customFields}
                    setStateType={setStateType}
                    fetchGstTaxList={fetchGstTaxList}
                    setSupplyState={setSupplyState}
                    initialStateType={initialStateType}
                    gstRegistrationType={gstRegistrationType}
                    setIsStateChanged={setIsStateChanged}
                    setRCMEnable={rcmChangeHandler}
                  />
                  <ItemDetails
                    formValues={debitNoteFormValues}
                    itemFormValues={itemFormValues}
                    totalFormValues={totalFormValues}
                    formErrors={formErrors}
                    itemFormErrors={itemFormErrors}
                    currencyId={currencyId}
                    currencyCode={currencyCode}
                    amountCalculation={amountCalculation}
                    taxableAmount={taxableAmount}
                    isHsnCode={false}
                    taxType="receivable"
                    lineItemBaseAccount={"Expenses~Assets"}
                    discountBaseAccount="Incomes"
                    conversionDate={conversionDate}
                    newConversionDate={newConversionDate}
                    conversionCurrencyId={conversionCurrencyId}
                    userId={vendorId}
                    componentType={"debitNote"}
                    setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                    totalTdsValues={totalTdsValues}
                    totalTcsValues={initialTCSTotalValues}
                    ref={itemRef}
                    isInititialCustomConversionFetch={
                      debitNoteAutofillData.isFromBill
                    }
                    getTotalValue={getTotalValue}
                    tdsIds={tdsIds}
                    tcsIds={tcsIds}
                    stateType={stateType}
                    supplyState={supplyState}
                    gstRegistrationType={gstRegistrationType}
                    isRoundOffEnabled={isRoundOffEnabled}
                    isTotalEditable={isTotalEditable}
                    editTotal={editTotal}
                    updateCalculatedTotal={updateCalculatedTotal}
                    isCustomerVendorChanged={isVendorChanged}
                    isStateChanged={isStateChanged}
                    consumerId={vendorId}
                    consumerTradeDefault={vendorPurchaseDefault}
                    rcmEnabled={rcmEnabled}
                    forSubscription={false}
                  >
                    <NoteTextArea
                      label={
                        "Vendor Notes  (This info will not be display in the debit note pdf )"
                      }
                      name={"vendor_notes"}
                      id={"vendorNotes"}
                      className={"notes-textarea"}
                      placeholder={"Enter notes here..."}
                      value={debitNoteFormValues.vendor_notes}
                      onChange={handleChange}
                      formErrors={formErrors}
                    />
                  </ItemDetails>
                  <section id="upload_section">
                    <div className="upload-header pb-3">Attach Files</div>
                    <DropZone
                      uploadedFiles={uploadedFiles}
                      uploadFileIds={fileIds}
                      uploadAttachedFiles={uploadAttachedFiles}
                      removeFile={removeFile}
                      handleSubmitAction={handleSubmitAction}
                      ref={fileRef}
                    />
                    <span className="error">{formErrors.attachFiles}</span>
                  </section>
                  <div className="email-reciepients-wrapper">
                    <label htmlFor="deposit_to">Email to</label>
                    <br />
                    <MultiEmailBox
                      onChange={setEmailHandler}
                      emailList={emailLists}
                      removeEmailHandler={removeEmailHandler}
                      onError={handleEmailError}
                      className={emailFormErrors !== "" ? "email-error" : ""}
                    />
                    <span className="error">{emailFormErrors}</span>
                    {emailLists.length ? (
                      <InfoBox
                        message={
                          message("Save as Open", "Debit note").emailInfo
                        }
                        className="info-alert email-info-alert"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <CreditNoteFormFooter
                  name="Debit Note"
                  isFormSubmit={formSubmit && formSubmitAction}
                  isError={isError}
                  action={action}
                  transactionUserId={defaultRef.current?.defaultData?.vendor_id}
                  isUserSelect={isVendorName}
                  onClickSave={asynchronousSubmit}
                  onClickDelete={() => {
                    setDeleteDebitNoteId(Number(editId));
                    setDeleteDebitItemId(debitNoteFormValues.debit_note_number);
                    setDeleteDebitNoteStatus(
                      debitNoteFormValues.debit_note_status
                    );
                  }}
                  editId={editId}
                  rolePermission={debitNoteRolePermission}
                  transactionStatus={debitNoteStatus}
                  isLocked={debitNoteFormValues.is_locked}
                  lockDate={debitNoteFormValues.lock_date}
                  redirectUrl={"/debit-note"}
                  isFromInvoice={debitNoteAutofillData.isFromBill}
                />
              </div>
            </form>
          </div>
        </div>
        <DeleteDebitNote
          deleteDebitNoteId={deleteDebitNoteId}
          deleteDebitItemId={deleteDebitItemId}
          debitNoteStatus={deleteDebitNoteStatus}
          page={page}
          itemsPerPage={itemsPerPage}
          dateSortOrder={dateSortOrder}
          refreshDebitNotes={fetchDebitNoteListAfterDelete}
          organizationId={currentUserInfo.organization_id}
        />
      </div>
      <TransactionEditTagRemovalAlertModal
        module="debit note"
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={() => {
          handleSubmit(buttonAction.status, buttonAction.action);
        }}
        openAlertRef={openEditAlertRef}
      />
    </>
  );
}
export default React.memo(CreateDebitNote);
