import { FormikProps } from "formik";
import InfoTooltip from "../../../../../../common/components/InfoTooltip";
import { message } from "../../../../../../constants/messages";
import "../../SystemVoucherForm.css";

type Props = {
  formik: FormikProps<any>;
};
export const SystemVoucherListInJV = (props: Props) => {
  return (
    <div className={`col-12 col-lg-4 mw-325 ${!props.formik.values.currency_id?"disabled-item":""}`}>
      <br />
      <div className="form-check jv-type-check-box-wrapper mt-2">
        <input
          className="form-check-input"
          type="checkbox"
          id={`list_in_jv`}
          name={`list_in_jv`}
          value={""}
          checked={
            props.formik.values.list_in_jv
              ? props.formik.values.list_in_jv
              : false
          }
          onChange={(e) => {
            if (e.target.checked) {
              props.formik.setFieldValue("list_in_jv", e.target.checked);
            } else if (e.target.checked === false) {
              props.formik.setFieldValue("list_in_jv", false);
            }
          }}
        />
        <label className="form-check-label" htmlFor={`list_in_jv`}>
          List in Journal Entry
        </label>
        <InfoTooltip title={message().listInJvInfo} placement="right" />
      </div>
    </div>
  );
};
