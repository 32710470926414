import { Grid } from "@mui/material";
import { CancelChangesModal } from "./CancelChangesModal";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import "./tableInfo.css";

export default function ScheduledStopDetailsInfo(props: any) {
  const stopIndex =
    props.scheduledSubscriptionData.status_change_scheduler?.findIndex(
      (item: { new_status: string }) =>
        ["ACTIVE", "STOPPED"].includes(item.new_status)
    );

  return (
    <>
      <Grid container className="subscription-impact-details-container">
        <Grid item xs="auto">
          <Grid container>
            <Grid item xs="auto">
              <img src={info} id="message-img" alt="clone-alt" />
            </Grid>
            <Grid item xs="auto">
              <div className="m-l-10">
                This subscription is scheduled to be{" "}
                {props.subscriptionStatus === "STOPPED" ? "resumed" : "stopped"}{" "}
                on{" "}
                {
                  props.scheduledSubscriptionData.status_change_scheduler[
                    stopIndex
                  ].scheduled_date
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          {props.overviewSubscriptionData.subscription_status && (
            <button
              className="add-customer-link"
              data-bs-toggle="modal"
              data-bs-target="#stopResumeMessageModal"
              disabled={
                props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                "expired"
              }
              onClick={() => props.editStopHandler("editStop")}
            >
              <span className="d-flex align-items-center">
                {" "}
                <span
                  className={
                    props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                    "stopped"
                      ? ""
                      : "delete"
                  }
                >
                  {props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                  "stopped"
                    ? "Edit Resume"
                    : "Edit Stop"}
                </span>
              </span>
            </button>
          )}
        </Grid>
        <Grid item xs="auto">
          <CancelChangesModal
            type={props.subscriptionStatus === "STOPPED" ? "Resume" : "Stop"}
            action="stopResumeCancel"
            schedulerId={
              props.scheduledSubscriptionData.status_change_scheduler[stopIndex]
                .id
            }
            subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
          />
        </Grid>
      </Grid>
    </>
  );
}
