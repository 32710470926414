import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "../../../htmlTags/Container";
import { Span } from "../../../htmlTags/Span";
import Input from "../../../ui/formElements/Input/Input";
import Li from "../../../ui/formElements/Li/Li";
import List from "../../../ui/formElements/Ul/Ul";
import { useDiscount } from "../DiscountProvider";
import { constants } from "../constants";
import { DiscountAccountDropdownProps } from "../types";
import Select, { components } from "react-select";
import { customSelectStyle } from "../../../SelectCustomStyle";
import { colors } from "../../../../../constants/colors";

const DiscountAccountSelect = (props: DiscountAccountDropdownProps) => {
  const { discountAccounts } = useDiscount();
  const [accountFilter, setAccountFilter] = useState("");
  useEffect(() => {
    resetDropDown();
  }, []);
  useEffect(() => {
    $(".dropdown-toggle").dropdown("hide");
  }, [props.error]);

  const handleSelectChange = (
    accountId: number | string,
    accountName: string,
    index: number,
    accountType: string
  ) => {
    props.onDiscountAccountChange(accountId, accountName, index, accountType);
  };

  /**
   * Function to set search string
   */
  const searchAccount = (e: any) => {
    setAccountFilter(e.target.value);
    handleSelectChange("", e.target.value, props.index, "");
  };

  const accountSearchOnBlur = () => {
    if (discountAccounts.length && props.discountAccountId) {
      let selectedAccount = discountAccounts.filter(
        (account) => account.id === props.discountAccountId
      );
      if (selectedAccount.length === 0) {
        props.onDiscountAccountChange("", "", props.index, "");
      } else {
        props.onDiscountAccountChange(
          Number(selectedAccount[0].id),
          selectedAccount[0].account_name,
          props.index,
          selectedAccount[0].account_type
        );
      }
    }
  };

  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  const CreatableOption = (props: any) => {
    const account = discountAccounts.find(
      (account) => account.id === props.value
    );
    return (
      <components.Option {...props}>
        <div className="option-wrapper">
          <Span className="option-heading">{account?.account_name}</Span>
          <Span className="option-account-root">{account?.account_type}</Span>
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <Container
        className={`btn-group accounts-dropdown discount-account-dropdown ${
          props.wrapperClass ? props.wrapperClass : ""
        } ${props.error}`}
      >
        {/* <Input
          type="text"
          name={props.name ? props.name : "account_id"}
          id={props.id ? props.id : ""}
          className={`${
            props.error ? "error" : ""
          }  discount-select-dropdown-input dropdown-toggle ${
            props.className || ""
          }`}
          placeholder={constants.placeholder.CHOOSE_ACCOUNT}
          dataBsToggle="dropdown"
          aria-expanded="false"
          disabled={props.isDisabled ? props.isDisabled : false}
          value={props.discountAccountName}
          onChange={(e) => {
            searchAccount(e);
          }}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          onBlur={accountSearchOnBlur}
        />
        <List wrapperClass="dropdown-menu dropdown-menu-start">
          <>
            {discountAccounts.length ? (
              discountAccounts
                .filter(
                  (account) =>
                    String(account.account_name)
                      .toLowerCase()
                      .includes(
                        String(props.discountAccountName).toLowerCase()
                      ) || accountFilter === ""
                )
                .map((account, accountIndex) => {
                  return (
                    <Li key={accountIndex}>
                      <Link
                        className={
                          Number(props.discountAccountId) === Number(account.id)
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSelectChange(
                            account.id,
                            account.account_name,
                            props.index,
                            account.account_type
                          );
                          setAccountFilter("");
                          return true;
                        }}
                      >
                        <Span className="account">{account.account_name}</Span>
                        <Span className="account-type">
                          {account.account_type}
                        </Span>
                      </Link>
                    </Li>
                  );
                })
            ) : (
              <Li key="no-result" wrapperClass="no-result">
                <Link
                  className="dropdown-item no-result"
                  to="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <Span className="no-result">No accounts found</Span>
                </Link>
              </Li>
            )}
          </>
        </List> */}
        <Select
          name={props.name ? props.name : "account_id"}
          id={props.id ? props.id : ""}
          placeholder={constants.placeholder.CHOOSE_ACCOUNT}
          options={discountAccounts.map((account) => {
            return {
              label: account.account_name,
              value: account.id,
            };
          })}
          value={(() => {
            const account = discountAccounts.find(
              (account) => account.id === props.discountAccountId
            );
            if (account) {
              return {
                label: account.account_name,
                value: account.id,
              };
            }
            return null;
          })()}
          className={`state-select form-select custom-select ${
            props.error ? "error" : ""
          }`}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          styles={{
            ...customSelectStyle,
            control: (provided: any, state: any) => ({
              ...provided,
              paddingRight: "4px",
              boxShadow: "none",
              border: state.isFocused
                ? `1px solid ${colors.ceruleanBlue}`
                : state.isDisabled
                ? `${colors.warmWhite}`
                : `1px solid ${colors.newWhisper}`,

              "&:hover": {
                border: state.isFocused
                  ? `1px solid ${colors.ceruleanBlue}`
                  : `1px solid ${colors.newWhisper}`,
              },
              backgroundColor: state.isDisabled
                ? `${colors.warmWhite}`
                : `${colors.white}`,
              height: "40px",
            }),
            menu: (provided: any, state: any) => ({
              ...provided,
              transform: state.selectProps.menuIsOpen
                ? "translateY(-140%)"
                : "none",
            }),
            menuList: (provided: any, state: any) => ({
              ...provided,
              height: "150px",
            }),
            option: (provided: any, state: any) => ({
              ...provided,
              fontFamily: "Lato",
              fontSize: "14px",
              fontStretch: "normal",
              fontStyle: "normal",
              fontWeight: "400",
              letterSpacing: "normal",
              lineHeight: "normal",
              margin: "1px 0",
              color:
                state.isFocused || state.isSelected
                  ? colors.ceruleanBlue
                  : colors.charcoalGrey,
              backgroundColor: colors.white,
              border: "0",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "var(--whisper)",
                color: colors.ceruleanBlue,
              },
              "&:after": {
                "display": "none"
              },
              "&:before": {
                "display": "none"
              }
            }), 
          }}
          onChange={(e) => {
            if (e) {
              const account = discountAccounts.find(
                (account) => account.id === e.value
              );
              if (account) {
                handleSelectChange(
                  account.id,
                  account.account_name,
                  props.index,
                  account.account_type
                );
                setAccountFilter("");
                return true;
              }
            }
          }}
          onBlur={accountSearchOnBlur}
          components={{ Option: CreatableOption }}
        />
      </Container>
    </>
  );
};

export default DiscountAccountSelect;
