import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  updateSubscriptionStatus,
  getSubscriptionList,
  deleteSubscription,
  deletePricing,
  getSubscriptionPlanDropdownList,
  getSubscriptionAddonDropdownList,
  getSubscriptionPlanFrequencyList,
  getSubscriptionCouponList,
  getSubscriptionAddonFrequencyList,
  subscriptionTransactionLimitStatus,
  getChildInvoiceList,
  getSubscriptionOverviewDetails,
  subscriptionAttachments,
  subscriptionAttachmentsDelete,
  getSubscriptionNumber,
  calculateTaxableAmount,
  calculateAmount,
  createNewSubscription,
  subscriptionNumberExist,
  nextInvoiceDate,
  getSubscriptionDetails,
  editSubscription,
  cancelSchedulerHandler,
  getSubscriptionPlanFrequencyItems,
  getSubscriptionAddonFrequencyItems,
  getSubscriptionItemsDetails,
  getSubscriptionAddonConvertedItems,
  getSubscriptionSchedulerDetails,
  getConvertedCouponList,
  cancelSubscription,
  getSubscriptionDataForInvoice,
  saveConfigurationProration,
  getProrationPreference,
  reactivateSubscription,
  getUnbilledChargesListDetails,
  deleteUnbilledCharges,
  invoiceUnbilledChargesNow,
  deleteSubscriptionItemTax,
  removeCancelSchedulerHandler,
  getCancelActionDetails,
  getSubscriptionChargesDropdownList,
  getSubscriptionChargeFrequencyItems,
  getSubscriptionChargeConvertedItems,
  regenerateVoidInvoicesAPI,
} from "./subscriptionAPI";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";

export const subscriptionList = createAsyncThunk(
  "subscriptionList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    orgIds: number[];
    statusList: string[];
    CustomerList: number[];
    frequency: string[];
  }) => {
    return await getSubscriptionList(data);
  }
);

export const deleteSubscriptionDetails = createAsyncThunk(
  "subscription/delete",
  async (data: { deleteSubscriptionId: number; orgId: number }) => {
    return await deleteSubscription(data);
  }
);

export const deletePricingData = createAsyncThunk(
  "pricing/delete",
  async (data: { deletePricingId: number; orgId: number }) => {
    return await deletePricing(data);
  }
);

export const subscriptionPlanDropdown = createAsyncThunk(
  "subscriptionPlanDropdownList",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    searchQuery: string;
    signal: AbortSignal | undefined;
  }) => {
    return await getSubscriptionPlanDropdownList(data);
  }
);

export const subscriptionPlanFrequency = createAsyncThunk(
  "subscriptionPlanFrequencyList",
  async (data: { planId: string; orgId: number; currencyId: number }) => {
    return await getSubscriptionPlanFrequencyList(data);
  }
);

export const subscriptionAddonFrequency = createAsyncThunk(
  "subscriptionPlanFrequencyList",
  async (data: {
    addonId: string;
    orgId: number;
    frequency: string;
    custom_frequency_count: number;
    currencyId: number;
  }) => {
    return await getSubscriptionAddonFrequencyList(data);
  }
);

export const subscriptionPlanFrequencyItems = createAsyncThunk(
  "subscriptionPlanFrequencyItems",
  async (data: {
    planId: number;
    pricingId: number;
    orgId: number;
    currencyId: number;
    conversionId: number;
  }) => {
    return await getSubscriptionPlanFrequencyItems(data);
  }
);
export const subscriptionAddonDropdown = createAsyncThunk(
  "subscriptionAddonDropdownList",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    frequency: string;
    custom_frequency_count: number;
    addonIds: number[];
    searchQuery: string;
    signal?: AbortSignal;
  }) => {
    return await getSubscriptionAddonDropdownList(data);
  }
);

export const subscriptionChargesDropdown = createAsyncThunk(
  "subscriptionChargesDropdownList",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    frequency: string;
    chargeIds: number[];
    isForDropdown: boolean;
    searchQuery: string;
    signal?: AbortSignal;
  }) => {
    return await getSubscriptionChargesDropdownList(data);
  }
);

export const subscriptionAddonFrequencyItems = createAsyncThunk(
  "subscriptionAddonFrequencyItems",
  async (data: {
    addonId: number;
    pricingId: number;
    orgId: number;
    currencyId: number;
    conversionId: number;
  }) => {
    return await getSubscriptionAddonFrequencyItems(data);
  }
);

export const subscriptionChargeFrequencyItems = createAsyncThunk(
  "subscriptionChargeFrequencyItems",
  async (data: {
    chargeId: number;
    orgId: number;
    currencyId: number;
    conversionId: number;
  }) => {
    return await getSubscriptionChargeFrequencyItems(data);
  }
);

export const subscriptionCouponSearchList = createAsyncThunk(
  "subscriptionCouponList",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    searchTerm: string;
    couponIds: number[];
    currencyId: number;
    conversionId?: number;
    discountType: string;
  }) => {
    return await getSubscriptionCouponList(data);
  }
);

export const attachSubscriptionFiles = createAsyncThunk(
  "recurringInvoice/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await subscriptionAttachments(data);
  }
);

export const changeSubscriptionStatus = createAsyncThunk(
  "subscription/update-status",
  async (data: {
    subscriptionId: number;
    orgId: number;
    status: string;
    change_status_option?: string;
    specific_date?: any;
    unbilled_charge?: string;
    scheduler_id?: number;
  }) => {
    return await updateSubscriptionStatus(data);
  }
);

export const deleteSubscriptionFile = createAsyncThunk(
  "invoice/file/delete",
  async (data: { fileId: number; orgId: number }) => {
    return await subscriptionAttachmentsDelete(data);
  }
);

export const getNewSubscriptionNumber = createAsyncThunk(
  "invoice/number",
  async (orgId: number) => {
    return await getSubscriptionNumber(orgId);
  }
);
export const getSubscriptionTransactionLimitStatus = createAsyncThunk(
  "plans/limit/status",
  async (orgId: number) => {
    return await subscriptionTransactionLimitStatus(orgId);
  }
);

export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateTaxableAmount(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
    conversionDate: string;
  }) => {
    return await calculateAmount(data);
  }
);

export const createSubscription = createAsyncThunk(
  "create/subscription",
  async (data: { values: any; orgId: number }) => {
    return await createNewSubscription(data);
  }
);

export const checkSubscriptionNumber = createAsyncThunk(
  "subscription/number-exist",
  async (data: {
    subscriptionNumber: string;
    orgId: number;
    subscriptionId: number | null;
  }) => {
    return await subscriptionNumberExist(data);
  }
);

export const getNextInvoiceDate = createAsyncThunk(
  "subscription/next-invoice",
  async (data: {
    invoicingoption: string;
    orgId: number;
    pricing_id: number;
    plan_id: number;
    start_date: string;
    total_amount: number;
    invoicing_cycle?: string;
    invoicing_cycle_fixed_count?: number;
    is_proration_enabled?: boolean;
    subscription_id?: number;
    in_subscription_create?: boolean;
    invoice_generation?: string;
    onlyCouponChange?: boolean;
    total_details?: any;
    coupon_list?: number[];
    conversion_id?: number;
    coupon_configuration?: any;
    updatedItemList?: [];
    charge_item_list?: [];
    currency_id: number | null;
    conversion_date: string;
  }) => {
    return await nextInvoiceDate(data);
  }
);

export const subscriptionEditDetails = createAsyncThunk(
  "subscription/edit-detail",
  async (data: { subscriptionId: number; orgId: number }) => {
    return await getSubscriptionDetails(data);
  }
);

export const cancelScheduler = createAsyncThunk(
  "subscription/cancelScheduler",
  async (data: { schedulerId: number; orgId: number }) => {
    return await cancelSchedulerHandler(data);
  }
);

export const removeCancelScheduler = createAsyncThunk(
  "subscription/removeCancelScheduler",
  async (data: { schedulerId: number; orgId: number; comments: string }) => {
    return await removeCancelSchedulerHandler(data);
  }
);

export const editSubscriptionDetails = createAsyncThunk(
  "edit/subscription",
  async (data: { id: number; editInvoiceInputs: any; orgId: number }) => {
    return await editSubscription(data);
  }
);

export const childInvoiceList = createAsyncThunk(
  "childInvoicesList",
  async (data: {
    page: number;
    dateSortOrder: string;
    itemsPerPage: number;
    invoiceId: number;
    orgId: number;
  }) => {
    return await getChildInvoiceList(data);
  }
);

export const subscriptionDetails = createAsyncThunk(
  "subscriptionDetail",
  async (data: { subscriptionId: number; orgId: number }) => {
    return await getSubscriptionOverviewDetails(data);
  }
);

export const subscriptionDataForInvoice = createAsyncThunk(
  "subscriptionDataForInvoice",
  async (data: { invoiceId: number; orgId: number }) => {
    return await getSubscriptionDataForInvoice(data);
  }
);

export const prorationPreference = createAsyncThunk(
  "prorationPreference",
  async (data: { orgId: number }) => {
    return await getProrationPreference(data);
  }
);

export const configurationProrationSave = createAsyncThunk(
  "configurationProrationSave",
  async (data: { orgId: number; isProrationEnabled: boolean }) => {
    return await saveConfigurationProration(data);
  }
);

export const schedulerDetails = createAsyncThunk(
  "subscription/schedulerDetails",
  async (data: { subscriptionId: number; orgId: number }) => {
    return await getSubscriptionSchedulerDetails(data);
  }
);

export const restoreItemPricing = createAsyncThunk(
  "subscription/updateItemPricing",
  async (data: {
    itemId: number;
    pricingId: number;
    orgId: number;
    currencyId: number;
    conversionId: number;
    isPlan: boolean;
  }) => {
    return await getSubscriptionItemsDetails(data);
  }
);

export const subscriptionAddonConvertedItems = createAsyncThunk(
  "subscriptionPlanFrequencyItems",
  async (data: {
    addonId: number[];
    pricingId: number[];
    orgId: number;
    currencyId: number;
    conversionId: number;
  }) => {
    return await getSubscriptionAddonConvertedItems(data);
  }
);

export const subscriptionChargeConvertedItems = createAsyncThunk(
  "subscriptionChargeFrequencyItems",
  async (data: {
    chargeId: number[];
    pricingId: number[];
    orgId: number;
    currencyId: number;
    conversionId: number;
  }) => {
    return await getSubscriptionChargeConvertedItems(data);
  }
);

export const subscriptionConvertedCouponList = createAsyncThunk(
  "subscriptionCouponList/conversion",
  async (data: {
    orgId: number;
    couponIds: number[];
    currencyId: number;
    conversionId: number;
  }) => {
    return await getConvertedCouponList(data);
  }
);

export const cancelSubscriptionAction = createAsyncThunk(
  "cancelSubscriptionAction",
  async (data: { orgId: number; cancelData: any }) => {
    return await cancelSubscription(data);
  }
);

export const reactivateSubscriptionAction = createAsyncThunk(
  "reactivateSubscriptionAction",
  async (data: { orgId: number; reactivationData: any }) => {
    return await reactivateSubscription(data);
  }
);

export const getUnbilledChargesList = createAsyncThunk(
  "subscription/unbilledChargesList",
  async (data: { subscriptionId: number; orgId: number }) => {
    return await getUnbilledChargesListDetails(data);
  }
);

export const deleteUnbilledInvoice = createAsyncThunk(
  "unbilled-charges/delete",
  async (data: { deleteUnbilledId: number; orgId: number }) => {
    return await deleteUnbilledCharges(data);
  }
);

export const invoiceUnbilledCharges = createAsyncThunk(
  "unbilled-charges/invoice-now",
  async (data: { subscriptionId: number; orgId: number }) => {
    return await invoiceUnbilledChargesNow(data);
  }
);

export const deleteSubscriptionItemTaxDetails = createAsyncThunk(
  "delete/subscription/item/tax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteSubscriptionItemTax(data);
  }
);

export const cancelActionDetails = createAsyncThunk(
  "cancelActionDetails",
  async (data: {
    credit_note: string;
    include_cancellation_date: boolean;
    subscription_id: number;
    manage_unbilled_charges: string | null;
    unused_credits_days: number;
    cancel_status_option: string;
    cancel_date: string;
    apply_refundable_to_unpaid: boolean;
    orgId: number;
  }) => {
    return await getCancelActionDetails(data);
  }
);

export const regenerateVoidInvoices = createAsyncThunk(
  "regenerateVoidInvoices",
  async (data: {
    orgId: number;
    subscriptionId: number;
    invoiceStartDate: string;
    invoiceEndDate: string;
    prorationEnabled?: boolean;
    includeCharges?: boolean;
    invoicingOptions?: any;
  }) => {
    return await regenerateVoidInvoicesAPI(data);
  }
);

const initialState = {};

export const subscriptionSlice = createSlice({
  name: "SubscriptionManagement",
  initialState,
  reducers: {},
});

export default subscriptionSlice.reducer;
