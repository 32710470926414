import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  CurrencyList,
  ExchangeRateValues,
  ObjectType,
} from "../../../../../types";
import {
  createExchangeRateList,
  setExchangeApiSatus,
} from "../../../commonSlice";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { SaveButton } from "../../button/SaveButton";
import { SecondaryButton } from "../../button/SecondaryButton";
import "../../../../../index.css";
import "./MultipleExchangeRate.css";
import { createReverseExchangeRate } from "../exchangeRateSlice";
import { getFormatedDate } from "../../../../../helpers/helper";

type MultipleExchangeRateProps = {
  module?: string;
  exchangeRateValues: CurrencyList;
  currencyId: number | string;
  toCurrencyId: number | string;
  toCurrencyCode: string;
  conversionDate: string;
  conversionRate: string;
  fetchCustomConversions: (id: number) => void;
  getConversionId: (id: number) => void;
  getReverseConversionValue?: (value: CurrencyList) => void;
  handleClose: () => void;
  isReverseConversionRequired: boolean;
  exchangeRateSubmitHandler?: (data: any) => void;
  maxLength?: number;
};
export const MultipleExchangeRateForm = (props: MultipleExchangeRateProps) => {
  const dispatch = useAppDispatch();
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  let initialFormValues = {
    base_currency: props.exchangeRateValues.base_currency_id,
    to_currency: props.exchangeRateValues.to_currency_id,
    rate: props.conversionRate,
    by_user: props.exchangeRateValues.by_user,
  };
  const [exchangeFormValues, setExchangeFormValues] =
    useState(initialFormValues);

  useEffect(() => {
    setExchangeFormValues({
      base_currency: props.exchangeRateValues.base_currency_id,
      to_currency: props.exchangeRateValues.to_currency_id,
      rate: props.conversionRate,
      by_user: props.exchangeRateValues.by_user,
    });
  }, [props.exchangeRateValues]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const regex = /^-?([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (value === "" || regex.test(value)) {
      setExchangeFormValues({ ...exchangeFormValues, [name]: value });
    }
  };

  const validate = (values: ExchangeRateValues) => {
    const errors: ObjectType = {};
    if (!values.rate) {
      errors.rate = "Please enter a valid exchange rate";
    }
    return errors;
  };

  const submitHandler = async () => {
    let inputs: any = { ...exchangeFormValues };
    inputs = {
      ...inputs,
      base_currency: props.currencyId,
      to_currency: props.toCurrencyId,
      by_user: true,
      rate: Number(inputs.rate),
      timestamp: getFormatedDate(),
    };
    let errors = validate(inputs);
    if (Object.keys(errors).length) {
      setFormErrors({ ...errors });
    } else {
      setFormErrors({});
      props.exchangeRateSubmitHandler &&
        props.exchangeRateSubmitHandler(inputs);
      // dispatch(setExchangeApiSatus(true));
      // const createResponseAction = await dispatch(createExchangeRateList({ values: inputs }));
      // const createResponse = createResponseAction.payload;
      // if (Object.keys(createResponse).length && !("error" in createResponse)) {
      //   SuccessToaster(
      //     "Exchange rate updated successfully!",
      //     "rate-create-success"
      //   );
      //   props.getConversionId(createResponse.id);
      //   props.fetchCustomConversions(createResponse.id);
      //   props.handleClose();
      // }
    }
  };
  return (
    <div
      className={props.module === "COA" ? "coa-exchange" : ""}
      id="multipleExchangeRateEdit"
    >
      <div className="card card-body">
        <div className="row mb-0">
          <div className="col">
            <label
              htmlFor=""
              className={
                props.module === "COA"
                  ? "required fw-bold mb-2 ml-0"
                  : "required"
              }
            >
              Edit Exchange Rate
            </label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div className="input-group-text" id="basic-addon1">
                  {props.toCurrencyCode}
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label="exchange rate"
                aria-describedby="basic-addon1"
                name="rate"
                value={exchangeFormValues?.rate}
                onChange={handleInputChange}
                onFocus={(e: any) =>
                  e.target.parentElement.classList.add("focused")
                }
                onBlur={(e: any) =>
                  e.target.parentElement.classList.remove("focused")
                }
                maxLength={
                  props.maxLength
                    ? String(exchangeFormValues.rate as string).includes(".") &&
                      String(exchangeFormValues.rate as string).split(".").length &&
                      String(exchangeFormValues.rate as string).split(".")[0] === "0"
                      ? (props.maxLength as number) + 2
                      : (props.maxLength as number) + 1
                    : String(exchangeFormValues.rate as string).includes(".") &&
                      String(exchangeFormValues.rate as string).split(".").length &&
                      String(exchangeFormValues.rate as string).split(".")[0] === "0"
                    ? 9
                    : 8
                }
              />
              <span className="error">{formErrors?.rate}</span>
            </div>
            <div className="form-button-wrapper mt-4">
              <SaveButton
                onClick={submitHandler}
                type="button"
                isDisabled={false}
                buttonContent="Save"
              />
              <SecondaryButton
                onClick={props.handleClose}
                type="button"
                isDisabled={false}
                buttonContent="Cancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
