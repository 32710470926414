import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { convertToPascalCase } from "../../../../helpers/convertToPascalHelper";
import { ObjectType } from "../../../../types";
import {
  searchLoaderStateSelector,
  setSearchQueryValue,
} from "../../../common/commonSlice";
import { BreadCrumbs } from "../../../common/components/breadCrumbs/BreadCrumbs";
import { majorAttributeSetter } from "../helperFunctions/majorAttributeSelector";
import { redirectLinkSetter } from "../helperFunctions/redirectLinkSetter";
import { SearchLoader } from "../loader/SearchLoader";
import SearchButton from "../searchButton/SearchButton";
import { SearchResultDetails } from "./searchDetails/SearchResultDetails";
// import useHasClass from "../../../hooks/useHasClass";
// import useDeviceSize from "../../../hooks/useDeviceSize";
type Props = {
  searchData: ObjectType;
  searchQuery: string;
  searchModule: string;
  isLoading?: boolean;
};
const SearchResultItem = (props: Props) => {
  const [learnMoreText, setLearnMoreText] = useState("View more");
  const [isViewMoreVisible, setIsViewMoreVisible] = useState(false);
  const isSearching = useAppSelector(searchLoaderStateSelector);
  // const { screenWidth } = useDeviceSize();

  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const link = redirectLinkSetter(
    props.searchData.module,
    props.searchData.resultAttribute.id,
    props.searchData.resultAttribute["Status"]
  );

  useEffect(() => {
    const checkContainerHeight = () => {
      if (containerRef.current) {
        const elementHeight = containerRef.current.offsetHeight;
        setIsViewMoreVisible(elementHeight > 24);
      }
    };
    checkContainerHeight();
    window.addEventListener("resize", checkContainerHeight);

    return () => {
      window.removeEventListener("resize", checkContainerHeight);
    };
  }, []);

  const showMoreHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // const hasClass = useHasClass();
    let _target = e.target,
      _parent = null,
      prevSibling = null;

    if (hasClass(_target, "view-more-btn")) {
      _parent = _target.parentNode;
      prevSibling = _parent.previousElementSibling;
    } else {
      _parent = _target.parentNode.parentNode;
      _target = _target.parentNode;
      prevSibling = _parent.previousElementSibling;
    }

    prevSibling.classList.toggle("fixed-height");
    _target.classList.toggle("show-less");

    setLearnMoreText(
      hasClass(_target, "show-less") ? "View less" : "View more"
    );
  };

  const hasClass = (target: any, className: any) => {
    return new RegExp("(\\s|^)" + className + "(\\s|$)").test(target.className);
  };

  const handleClick = () => {
    dispatch(
      setSearchQueryValue({
        searchQuery: props.searchQuery,
        searchModule: props.searchModule,
      })
    );
  };

  function formatDateToDDMMYYYY(dateString: string): string {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  function isDate(dateString: string) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(dateString)) {
      return formatDateToDDMMYYYY(dateString);
    }
    return dateString;
  }

  return (
    <>
      {isSearching && props.isLoading ? (
        <SearchLoader height={"30px"} margin={"20px 20px"} variant="page" />
      ) : (
        <li>
          <Link to={link || ""} className="search-item" onClick={handleClick}>
            <BreadCrumbs
              crumbsArray={props.searchData.breadCrumbs}
              fontSize="14px"
            />
            {props.searchData.matchedField["value"] ? (
              <div className="search-data">
                <span className="label">
                  {convertToPascalCase(
                    props.searchData.matchedField["field_name"] || ""
                  )}
                  :
                </span>
                {
                  <span className="matched-query-string">
                    {Array.isArray(props.searchData.matchedField["value"])
                      ? props.searchData?.matchedField["value"].filter(
                          (value, index) =>
                            value
                              .toString()
                              .toLowerCase()
                              .includes(
                                props.searchQuery.toString().toLowerCase()
                              )
                        )[0]
                      : isDate(props.searchData.matchedField["value"])}
                  </span>
                }
              </div>
            ) : (
              <div className="search-data">
                <span>
                  {convertToPascalCase(
                    Object.keys(
                      majorAttributeSetter(
                        props.searchData.module,
                        props.searchData.resultAttribute
                      )
                    )[0]
                  )}
                </span>

                <span className="matched-query-string">
                  {":  "}

                  {
                    props.searchData.resultAttribute[
                      Object.keys(
                        majorAttributeSetter(
                          props.searchData.module,
                          props.searchData.resultAttribute
                        )
                      )[0]
                    ]
                  }
                </span>
              </div>
            )}
            <div className="item-summary fixed-height">
              <div
                className="search-result-details"
                id="searchItemVerticalContainer"
                ref={containerRef}
              >
                <SearchResultDetails
                  details={props.searchData.resultAttribute}
                />
              </div>
            </div>

            {isViewMoreVisible && (
              <div className="btn-wrap">
                <SearchButton
                  showMore={showMoreHandler}
                  label={learnMoreText}
                />
              </div>
            )}
          </Link>
        </li>
      )}
    </>
  );
};

export default SearchResultItem;
