import { FormikProps } from "formik";
import { FormTextAreaField } from "../../../../../../common/components";
import { Container } from "../../../../../../common/components/htmlTags/Container";

type Props = {
  formik: FormikProps<any>;
};
export const JournalNotes = (props: Props) => {
  return (
    <Container
    className={
      `${!props.formik.values.currency_id ? "disabled-item" : ""}  mw-325` 
    }
  >
    <FormTextAreaField
      type="text"
      label="Notes"
      isRequired={true}
      isResizable={false}
      colClassDisabled={true}
      disabled={!props.formik.values.currency_id}
      name="notes"
      id="jv_notes"
      className={`jv_notes form-control w-100 ${
        props.formik.touched.notes && props.formik.errors.notes ? "error" : ""
      } ${!props.formik.values.currency_id ? "disabled-item" : ""}`}
      placeholder="Add notes to your journal entry. Max. 300 characters"
      maxLength={300}
      value={props.formik.values.notes}
      errorClass="error"
      error={
        ((props.formik.touched.notes || props.formik.submitCount > 0) &&
          props.formik.errors.notes &&
          String(props.formik.errors.notes)) ||
        ""
      }
      onChange={(e) => {
        props.formik.setFieldTouched("notes", true);
        props.formik.handleChange(e);
      }}
    />
    </Container>
  );
};
