import { OptionsMappingProps } from "../../../types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTagSelection } from "../../../hooks/useTagSelection";
import { useEffect, useMemo, useState } from "react";
import { Form } from "../../../../htmlTags/Form";
import { Container } from "../../../../htmlTags/Container";
import { Span } from "../../../../htmlTags/Span";
import { SaveButton, SecondaryButton, TextButton } from "../../../../button";
import { colors } from "../../../../../../constants/colors";
import Divider from "@mui/material/Divider";

import "./TagOptionsMapping.css";
import BackButton from "../../../../BackButton";
import { useParams } from "react-router-dom";
import { FormInputField } from "../../../../formInputField/FormInputField";
import { SearchIcon } from "../../../../customSvgIcons";
export const TagOptionsMapping = (props: OptionsMappingProps) => {
  const { invoiceId, billId } = useParams();
  const { handleSubmit, handleReset, setFieldValue, setInitialValues, values } =
    useTagSelection();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [search, setSearch] = useState("");
  useEffect(() => {
    getProcessedTagsOptions();
  }, [JSON.stringify(props.options), JSON.stringify(props.selectedIds)]);

  const getProcessedTagsOptions = () => {
    let subTagOptions: any = [];
    if (props.isTagFilteringPresent && invoiceId && props.tagFilterObject) {
      let keys = Object.keys(props.tagFilterObject[invoiceId || 0]);
      let values = Object.values(props.tagFilterObject[invoiceId || 0]);
      subTagOptions = values
        .flatMap((value) => value.options)
        .map((option) => {
          return option.id;
        });
      let processedTags = props?.options
        ?.filter((option) => subTagOptions.includes(option.id))
        .map((option) => {
          return {
            ...option,
            isChecked: props.selectedIds?.includes(option.id),
          };
        });
      setInitialValues({
        options: processedTags,
      });
      setFieldValue("options", processedTags);
    } else if (billId && props.isTagFilteringPresent && props.tagFilterObject) {
      let keys = Object.keys(props.tagFilterObject[billId || 0]);
      let values = Object.values(props.tagFilterObject[billId || 0]);
      subTagOptions = values
        .flatMap((value) => value.options)
        .map((option) => {
          return option.id;
        });
      let processedTags = props?.options
        ?.filter((option) => subTagOptions.includes(option.id))
        .map((option) => {
          return {
            ...option,
            isChecked: props.selectedIds?.includes(option.id),
          };
        });
      setInitialValues({
        options: processedTags,
      });
      setFieldValue("options", processedTags);
    } else {
      let processedTags = props?.options?.map((option) => {
        return {
          ...option,
          isChecked: props.selectedIds?.includes(option.id),
        };
      });
      setInitialValues({
        options: processedTags,
      });
      setFieldValue("options", processedTags);
    }
  };
  const getSelectedIDs = () => {
    let ids = [];
    ids = values?.options
      ?.filter((option) => option.isChecked)
      .map((option) => {
        return option.id;
      });
    return { tagId: props.tagId, optionIds: ids };
  };
  const clearSelections = () => {
    let processedTags = props?.options?.map((option) => {
      return {
        ...option,
        isChecked: false,
      };
    });

    setFieldValue("options", processedTags);
  };
  const handleSelectAll = () => {
    setSearch("");
    setIsAllSelected(!isAllSelected);
    setFieldValue(
      "options",
      props.options?.map((option) => {
        return {
          ...option,
          isChecked: !isAllSelected,
        };
      })
    );
  };
  const checkIfAllSelected = () => {
    if (values?.options?.some((option: any) => !option.isChecked)) {
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
    }
  };
  useMemo(() => {
    checkIfAllSelected();
  }, [values?.options]);
  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <Container className="tags-options-mapping-wrapper">
        <Container className="tags-options-mapping-header-wrapper">
          <BackButton onClick={props.handleClose} />
          <Span>Select Tags</Span>
        </Container>

        <Container className="tags-options-parent-tag-name-wrapper">
          <Span>{props?.parentTag?.name}</Span>
        </Container>

        <Divider sx={{ color: colors.battleshipGrey }} />
        <Container className="tags-options-mapping-option-array-wrapper">
          <Container className="mt-3 w-100">
            <FormInputField
              label=""
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              id="search"
              name="search"
              className="search-input w-100"
              placeholder="Search"
              startAdornment={
                <SearchIcon
                  margin="0px 10px 0px 0px"
                  color={colors.battleshipGrey}
                />
              }
            />
          </Container>
          <Container className="tags-options-mapping-select-all-wrapper ">
            <FormControlLabel
              control={
                <Checkbox
                  value={0}
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                />
              }
              label="Select All"
              sx={{ marginLeft: 0, marginRight: 0 }}
            />
          </Container>
          {values?.options.map((option, index) => {
            return option.name.toLowerCase().includes(search.toLowerCase()) ?(
              <Container className="tags-options-mapping-option-wrapper">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={option.id}
                      checked={option.isChecked}
                      onChange={() => {
                        setFieldValue(
                          `options.${index}.isChecked`,
                          !option.isChecked
                        );
                        setSearch("");
                      }}
                    />
                  }
                  label={option.name}
                  sx={{ marginLeft: 0, marginRight: 0 }}
                />
              </Container>
            ):<></>
          })}
        </Container>
        <Container className="tag-options-mapping-btn-wrapper">
          <Container className="tag-options-mapping-text-btn-wrapper">
            <TextButton
              onClick={clearSelections}
              buttonContent="Clear Selection"
              isDisabled={false}
              type="button"
              fontColor={colors.ceruleanBlue}
              fontSize="14px"
              fontWeight="normal"
            />
          </Container>
          <Container className="tag-options-mapping-action-btn-wrapper">
            <SecondaryButton
              type="button"
              isDisabled={false}
              onClick={() => {
                props.handleClose();
                getProcessedTagsOptions();
              }}
              buttonContent="Cancel"
            />
            <SaveButton
              type="submit"
              isDisabled={false}
              onClick={() => {
                props.getSelectedIds(getSelectedIDs());
                props.handleClose();
                props.categoryPopupClose && props.categoryPopupClose();
              }}
              buttonContent="Confirm"
              buttonClassName="m-0"
            />
          </Container>
        </Container>
      </Container>
    </Form>
  );
};
