import { SvgIconProp } from "../../../../types";

export const PlusIconTransparent = (props: SvgIconProp) => {
  return (
    <svg
      width={props.height ? props.height : "20px"}
      height={props.width ? props.width : "20px"}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color}
        fillRule={"nonzero"}
        d="M9.167 9.167v-5h1.666v5h5v1.666h-5v5H9.167v-5h-5V9.167z"
      />
    </svg>
  );
};
