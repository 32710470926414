export const initialVendorValues = {
  company_name: "",
  vendor_email: "",
  vendor_phone: "",
  pan_number: "",
  gst_registration_type: "",
  gstin: "",
  opening_balance: "",
  vendor_display_name: "",
  custom_fields: {},
  currency_id: "",
  short_code: "",
  payment_term_id: "",
  tag_ids: [],
  is_contact_org_closed: false,
  purchase_account_id: null,
  discount_account_id: null,
  discount_type: "",
  discount: "",
};
export const defaultValues = {
  company_name: "",
  vendor_display_name: "",
  vendor_email: "",
  vendor_phone: "",
  short_code: "",
};
export const otherDetailsValue = {
  tag_ids: [],
  payment_term_id: "",
  currency_id: "",
  gst_registration_type: "",
  gstin: "",
  pan_number: "",
  opening_balance: "",
};
export let initialContact = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  is_primary: true,
};
export const initialAddressValues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: false,
  is_shipping: false,
  is_primary: false,
};
