import React, { Dispatch, SetStateAction, useMemo } from "react";
import { InvoiceImage } from "../../../../../assets/images";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { useAppSelector } from "../../../../../app/hooks";
import { DateFormatHandler, StatusColor } from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { InvoiceList } from "../../../../../types";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import TableContainer from "../../../../common/components/table/TableContainer";
import CustomTooltips from "../../../../common/components/Tooltip";
import EmptyList from "../../../../common/components/EmptyList";
import Pagination from "../../../../common/components/table/Pagination";

type Props = {
  childInvoiceData: InvoiceList[];
  dateSortOrder: string;
  setDateSortOrder: Dispatch<SetStateAction<string>>;
  totalList: number;
  handlePageClick: (e: any) => void;
  itemsPerPage: number;
  page: number;
  pageCount: number;
};
function InvoiceTable({
  childInvoiceData,
  dateSortOrder,
  setDateSortOrder,
  totalList,
  handlePageClick,
  page,
  itemsPerPage,
  pageCount,
}: Props) {
  const { subscriptionRolePermission } = usePermissionHandler();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Invoice Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Invoice Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Invoice Number",
        accessor: "show.invoiceId",
        sort: false,
        sortOrder: "",
        className: "inv_num",
      },
      {
        Header: "Customer name",
        accessor: "show.customerName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value?.customer_name : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "inv_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Due Date",
        accessor: "show.dueDate",
        sort: false,
        sortOrder: "",
        className: "due_date",
      },
      {
        Header: "Invoice Amount",
        accessor: "show.invoiceAmount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Balance Due",
        accessor: "show.due",
        sort: false,
        sortOrder: "",
        className: "balance_due",
        Cell: ({ cell: { value } }: any) => {
          return <span>{value.balanceDue}</span>;
        },
      },
    ],
    [dateSortOrder]
  );
  /**
   * set table row data
   */
  const data = childInvoiceData
    ? childInvoiceData.map((invoice) => ({
        show: {
          date: DateFormatHandler(invoice.invoice_date),
          invoiceId: joinTransactionNumber(invoice.Invoice_ID),
          customerName: invoice.customer_name,
          status: invoice.invoice_status,
          dueDate: DateFormatHandler(invoice.due_date),
          invoiceAmount:
            invoice.currency_code +
            " " +
            NumberFormat(invoice.total, invoice.currency_code, orgCurrencyList),
          due: {
            id: invoice.id,
            status: invoice.invoice_status,
            balanceDue: invoice.balance_due
              ? invoice.currency_code +
                " " +
                NumberFormat(
                  invoice.balance_due,
                  invoice.currency_code,
                  orgCurrencyList
                )
              : invoice.currency_code +
                " " +
                zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    invoice.currency_code
                      ? invoice.currency_code
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                ),
          },
        },
      }))
    : [];

  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data"
      >
        <div className="row">
          <div className="col-md-12">
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/invoice"}
              // type="subscription"
              sortHandler={sortHandler}
              editPermission={subscriptionRolePermission.includes("Update")}
              isDetailViewExist={true}
            >
              <EmptyList
                heading="No Invoices found!"
                subheading="Oops, There is no Invoice data available right now"
                list="Invoice"
                alt="create invoice preview"
                buttonText="Create Invoice"
                navigateLink="#"
                isCrateAccess={false}
                icon={InvoiceImage}
              />
            </TableContainer>
          </div>
        </div>
        {totalList > itemsPerPage ? (
          <div className="card-footer clear-both">
            <Pagination
              pageCount={pageCount}
              page={page}
              handlePageClick={handlePageClick}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default React.memo(InvoiceTable);
