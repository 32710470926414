import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import useGst from "../../../../hooks/useGst";
import {
  ContactsIcon,
  DocIconSquare,
  EmailBlack,
  Locator,
  PhoneBlack,
} from "../../../../../assets/images";
import {
  ShippingOrBillingAddressValues,
  consumerDetailsValues,
} from "../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../commonSlice";
import "../../../styles/ConsumerOverView.css";
import AddressOverviewCard from "../AddressOverviewCard";
import AddressModal from "../../AddressModal";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { addressSelector } from "../../../../home/customerManagement/components/customerForm/AddressDetails/addressSlice";
import {
  addBillingAddress,
  addShippingAddress,
} from "../../../../home/customerManagement/components/customerForm/AddressDetails/addressTypeSlice";
import DeleteAddress from "../../../../home/customerManagement/components/customerDetail/DeleteAddress";
import NotificationModal from "../../NotificationModal";
import OutstandingAmount from "./OutstandingAmount";
import { TAX_SYSTEM } from "../../../../constants/constants";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import useVat from "../../../../hooks/useVat";

type Props = {
  consumerDetails: consumerDetailsValues;
  fetchConsumerDetail: (customerId: number) => void;
  consumerType: string;
};
const ConsumerOverView = (props: Props) => {
  const consumerData = props.consumerDetails;
  const { isGstOrg } = useGst();
  const { isVatOrg } = useVat();
  const dispatch = useAppDispatch();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const addressList = useAppSelector(addressSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const newAddressId = useRef<string>();
  const [addressId, setAddressId] = useState<number | string | undefined>("");
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [billingAddress, setBillingAddress] =
    useState<ShippingOrBillingAddressValues>();
  const [shippingAddress, setShippingAddress] =
    useState<ShippingOrBillingAddressValues>();
  const [taxPreference, setTaxPreference] = useState("");
  useEffect(() => {
    let billingAddress = consumerData.address_details.filter(
      (address: ShippingOrBillingAddressValues) => address.is_billing === true
    )[0];
    setBillingAddress(billingAddress);
    let shippingAddress = consumerData.address_details.filter(
      (address: ShippingOrBillingAddressValues) => address.is_shipping === true
    )[0];
    setShippingAddress(shippingAddress);
    let taxPreferenceType =
      consumerData.other_details.tax_preference === "TAXABLE"
        ? "Taxable"
        : "Non-Taxable";
    setTaxPreference(taxPreferenceType);
  }, [props.consumerDetails]);

  const openAddressModal = () => {
    setShowAddressModal(true);
  };
  const closeAddressModal = () => {
    setShowAddressModal(false);
    document.body.style.overflow = "auto";
  };
  /**
   * set address ID handler
   */
  const setAddressIdHandler = (id: string | number | undefined) => {
    setAddressId(id);
  };

  /**
   * Function to store id of newly created address to implement scroll to newly created address
   */
  const storeNewAddressIdHandler = (id: string) => {
    newAddressId.current = id;
  };

  return (
    <>
      <div className="overview-wrapper tab-content-wrapper">
        <section className="section-blocks other-dets-section">
          <h2>
            <img
              src={DocIconSquare}
              width="24"
              height="24"
              alt="Other details"
            />
            Other Details
          </h2>
          <div className="other-wrapper section-content-wrapper">
            <div className="row g-0">
              <div className="col">
                <div className="field-row">
                  <div className="field-title">
                    <span>Currency</span>
                  </div>
                  <div className="field-value">
                    <span>{consumerData.other_details.currency_code}</span>
                  </div>
                </div>
                {currentUserInfo.organization_tax_system ===
                  TAX_SYSTEM.INDIAN_TAX_SYSTEM && (
                  <>
                    <div className="field-row">
                      <div className="field-title">
                        <span>PAN</span>
                      </div>
                      <div className="field-value">
                        <span>
                          {consumerData.other_details.pan_number || "-"}
                        </span>
                      </div>
                    </div>
                    {isGstOrg && (
                      <div className="field-row">
                        <div className="field-title">
                          <span>GST Registration Type</span>
                        </div>
                        <div className="field-value">
                          <span>
                            {consumerData.other_details.gst_registration_type ||
                              "-"}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {isGstOrg && consumerData.other_details.gstin ? (
                  <div className="field-row">
                    <div className="field-title">
                      <span>GSTIN</span>
                    </div>
                    <div className="field-value">
                      <span>{consumerData.other_details.gstin || "-"}</span>
                    </div>
                  </div>
                ) : null}
                {isGstOrg && props.consumerType === "customer" && (
                  <div className="field-row">
                    <div className="field-title">
                      <span>Tax Preference</span>
                    </div>
                    <div className="field-value">
                      <span>{taxPreference ? taxPreference : "-"}</span>
                    </div>
                  </div>
                )}
                {isVatOrg && props.consumerType === "customer" && (
                  <div className="field-row">
                    <div className="field-title">
                      <span>Tax Registration Number (TRN)</span>
                    </div>
                    <div className="field-value">
                      <span>
                        {consumerData.other_details.tax_registration_number ||
                          "-"}
                      </span>
                    </div>
                  </div>
                )}

                {isGstOrg &&
                  props.consumerType === "customer" &&
                  consumerData.other_details.tax_preference ===
                    "NON_TAXABLE" && (
                    <div className="field-row">
                      <div className="field-title">
                        <span>Exemption Reason</span>
                      </div>
                      <div className="field-value">
                        <span>
                          {consumerData.other_details.exemption_reason_name
                            ? consumerData.other_details.exemption_reason_name
                            : "-"}
                        </span>
                      </div>
                    </div>
                  )}

                <div className="field-row">
                  <div className="field-title">
                    <span>Opening Balance</span>
                  </div>
                  <div className="field-value">
                    <span>
                      {consumerData.other_details.currency_code + " "}
                      {consumerData.other_details.opening_balance
                        ? NumberFormat(
                            Number(consumerData.other_details.opening_balance),
                            consumerData.other_details.currency_code,
                            orgCurrencyList
                          )
                        : 0}
                    </span>
                  </div>
                </div>
                {consumerData.import_id != "" && consumerData.import_id != null && (
                  <div className="field-row">
                    <div className="field-title">
                      <span>Import ID</span>
                    </div>
                    <div className="field-value">
                      <span>{consumerData?.import_id}</span>
                    </div>
                  </div>
                )}
                {Object.keys(consumerData?.custom_fields || {})?.length > 0 &&
                  Object.keys(consumerData?.custom_fields || {})?.map((key) => {
                    if (consumerData?.custom_fields[key].show_in_pdf) {
                      return (
                        <div className="field-row">
                          <div className="field-title">
                            <span>{key}</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {consumerData?.custom_fields[key].value || "-"}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </div>
            </div>
          </div>
        </section>
        <section
          className={
            consumerData.contact_persons.length < 1
              ? "section-blocks contacts-section no-contact"
              : "section-blocks contacts-section"
          }
        >
          <h2>
            <img src={ContactsIcon} width="24" height="24" alt="contacts" />
            Contact Persons<span>({consumerData.contact_persons.length})</span>
          </h2>
          {consumerData.contact_persons.length ? (
            <div className="contacts-wrapper section-content-wrapper">
              {consumerData.contact_persons.map((contact, key) => {
                return (
                  <div className="contact-box" key={"contact-" + key}>
                    <h3>
                      {contact.first_name ? contact.first_name : ""}
                      {contact.first_name && contact.last_name ? " " : ""}
                      {contact.last_name ? contact.last_name : ""}
                      <span>{contact?.is_primary ? "(Main contact)" : ""}</span>
                    </h3>
                    {contact.email ? (
                      <span className="email">
                        <img
                          src={EmailBlack}
                          alt="email"
                          width="16"
                          height="16"
                        />
                        {contact.email || "-"}
                      </span>
                    ) : null}
                    {contact.phone ? (
                      <span className="phone">
                        <img
                          src={PhoneBlack}
                          alt="phone number"
                          width="16"
                          height="16"
                        />
                        {contact.phone || "-"}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : null}
        </section>
        <section className="section-blocks addr-section">
          <h2>
            <img src={Locator} width="24" height="24" alt="Locator Icon" />
            Address
          </h2>
          <div className="address-wrapper section-content-wrapper flex-nowrap">
            <div
              className={`address-container ${
                props.consumerType === "customer"
                  ? "billing-address-container"
                  : ""
              }`}
            >
              <h3>Billing Address</h3>
              {props.consumerType === "customer" ? (
                <>
                  <div className="address-box-container d-flex">
                    {addressList.map((address, index) => {
                      return address.is_billing ? (
                        <AddressOverviewCard
                          openAddressModal={openAddressModal}
                          id="billing"
                          key={address.id}
                          address={address}
                          setAddressIdHandler={setAddressIdHandler}
                          isSameOrg={
                            props.consumerDetails.organization_id ===
                            currentUserInfo.organization_id
                          }
                          shouldScrollTo={newAddressId.current === address.id}
                        />
                      ) : null;
                    })}
                  </div>
                  {props.consumerDetails.organization_id ===
                    currentUserInfo.organization_id && (
                    <button
                      className="add-new addnew-address addnew-billing-address"
                      type="button"
                      onClick={(e: any) => {
                        e.preventDefault();
                        openAddressModal();
                        dispatch(addBillingAddress("add_billing"));
                        setAddressIdHandler("");
                      }}
                    >
                      Add Billing Address
                    </button>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <p>
                    {billingAddress?.address}
                    {billingAddress?.address && billingAddress?.city
                      ? ", "
                      : ""}
                    {billingAddress?.city}
                  </p>
                  <p>
                    {billingAddress?.country_id && billingAddress?.state_id
                      ? billingAddress.state_name
                      : ""}
                    {Number(billingAddress?.zip_code)
                      ? " " + billingAddress?.zip_code
                      : ""}
                    {(billingAddress?.state_id || billingAddress?.zip_code) &&
                    billingAddress?.country_id
                      ? ", "
                      : ""}
                    {billingAddress?.country_name}
                  </p>
                  <p>
                    {billingAddress?.phone
                      ? "Phone Number: " + billingAddress?.phone
                      : ""}
                  </p>
                </>
              )}
            </div>
            <div
              className={`address-container ${
                props.consumerType === "customer"
                  ? "shipping-address-container"
                  : ""
              }`}
            >
              <h3>Shipping Address</h3>
              {props.consumerType === "customer" ? (
                <>
                  {" "}
                  <div className="address-box-container d-flex">
                    {addressList.map((address) => {
                      return address.is_shipping ? (
                        <AddressOverviewCard
                          openAddressModal={openAddressModal}
                          id="shipping"
                          key={address.id}
                          address={address}
                          setAddressIdHandler={setAddressIdHandler}
                          isSameOrg={
                            props.consumerDetails.organization_id ===
                            currentUserInfo.organization_id
                          }
                          shouldScrollTo={newAddressId.current === address.id}
                        />
                      ) : null;
                    })}
                  </div>
                  {props.consumerDetails.organization_id ===
                    currentUserInfo.organization_id && (
                    <button
                      className="add-new addnew-address addnew-shipping-address"
                      type="button"
                      onClick={(e: any) => {
                        e.preventDefault();
                        dispatch(addShippingAddress("add_shipping"));
                        openAddressModal();
                        setAddressIdHandler("");
                      }}
                    >
                      Add Shipping Address
                    </button>
                  )}
                </>
              ) : (
                <>
                  <p>
                    {shippingAddress?.address}
                    {shippingAddress?.address && shippingAddress?.city
                      ? ", "
                      : ""}
                    {shippingAddress?.city}
                  </p>
                  <p>
                    {shippingAddress?.country_id && shippingAddress?.state_id
                      ? shippingAddress.state_name
                      : ""}
                    {Number(shippingAddress?.zip_code)
                      ? " " + shippingAddress?.zip_code
                      : ""}
                    {(shippingAddress?.state_id || shippingAddress?.zip_code) &&
                    shippingAddress?.country_id
                      ? ", "
                      : ""}
                    {shippingAddress?.country_name}
                  </p>
                  <p>
                    {shippingAddress?.phone
                      ? "Phone Number: " + shippingAddress?.phone
                      : ""}
                  </p>
                </>
              )}
            </div>
          </div>
          {showAddressModal && (
            <AddressModal
              closeAddressModal={closeAddressModal}
              consumerName={props.consumerDetails.display_name}
              addressId={addressId}
              createdFrom="detail_page"
              consumerId={props.consumerDetails.id}
              fetchConsumerDetail={props.fetchConsumerDetail}
              storeNewAddressIdHandler={storeNewAddressIdHandler}
            />
          )}
        </section>
      </div>
      <DeleteAddress
        addressId={addressId}
        fetchConsumerDetail={props.fetchConsumerDetail}
        consumerId={props.consumerDetails.id}
      />
      <NotificationModal
        modalHeader="Unable to delete customer address!"
        modalBody="Default address cannot be deleted. Please go to the edit page to delete the default address."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
        modalType="customer_address"
      />
    </>
  );
};

export default ConsumerOverView;
