import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { editTag } from "../home/userManagement/userAPI";
import { getCurrencies } from "../home/currencyConfiguration/currencyConfigurationSlice";
import { getCustomFieldTotalList } from "../home/customFields/components/customFieldAPIFiles/customFieldSlice";
import { getFormatedDate } from "../../helpers/helper";
import { getAmountCalculation } from "../home/invoice/invoiceSlice";
import {
  initialItemValues,
  initialTaxValues,
} from "../../helpers/transactionHelper";
import {
  sourceTaxTypes,
  TAX_SYSTEM,
  tdsApplyLevel,
} from "../constants/constants";
import {
  Currency,
  CurrentUserValues,
  LocationValues,
  PaymentTerms,
  PaymentTermValue,
  TagsList,
  TagValue,
  ExchangeRateValues,
  OrganizationSwitchDropDownValues,
  CurrencyFormType,
  ObjectType,
  CurrencyList,
  ForexGainLossValues,
  CreditNoteValues,
  CNDefaultValues,
  CustomerItemValues,
  DNDefaultValues,
  DebitNoteValues,
  RolePermissionMappingValues,
} from "../../types";
import {
  createNewPaymentTerms,
  createTag,
  deletePaymentTerm,
  editPaymentTerm,
  getCountryList,
  getCurrentUserDetails,
  getOrganizationListToSwitch,
  getPaymentTermsList,
  getStatesList,
  getTags,
  getCurrencyConversionList,
  createExchangeRate,
  getLatestCurrencyConversion,
  customConversions,
  getOrgCurrencyList,
  getOrgListForReport,
  updatePrefixes,
  getPrefixes,
  tableCustomizationFields,
  addTableCustomizationFields,
  getForexGainOrLoss,
  getCurrencyDropDown,
  getRoleMapping,
  getOrganizationIDListOfUser,
  editAllocationDateConfig,
  getTransactionConfig,
  getCustomFrequencySettings,
  updateTransactionConfig,
} from "./commonApi";

export const getCountries = createAsyncThunk("countries", async () => {
  return await getCountryList();
});

export const getCountryStates = createAsyncThunk(
  "countries/states",
  async (countryId: number) => {
    return await getStatesList(countryId);
  }
);
export const currentUserDetails = createAsyncThunk(
  "userDetails",
  async (data: { orgId: number; isOrgChanged: boolean }) => {
    return await getCurrentUserDetails(data);
  }
);

export const currencyDropdown = createAsyncThunk(
  "currencyDropdown",
  async (orgId: number) => {
    return await getCurrencyDropDown(orgId);
  }
);

export const createPaymentTerm = createAsyncThunk(
  "createPaymentTerm",
  async (data: { values: PaymentTermValue; orgId: number }) => {
    return await createNewPaymentTerms(data);
  }
);

export const updatePaymentTerm = createAsyncThunk(
  "updatePaymentTerm",
  async (data: {
    editPaymentTermId: number;
    paymentTermInputs: PaymentTermValue;
    orgId: number;
  }) => {
    return await editPaymentTerm(data);
  }
);

export const deletePaymentTermDetails = createAsyncThunk(
  "deletePaymentTerm",
  async (data: { id: number; orgId: number }) => {
    return await deletePaymentTerm(data);
  }
);

export const paymentTermsList = createAsyncThunk(
  "paymentTerms",
  async (orgId: number) => {
    return await getPaymentTermsList(orgId);
  }
);

export const tagsList = createAsyncThunk("tags", async (orgId: number) => {
  return await getTags(orgId);
});

export const createTags = createAsyncThunk(
  "create-tag",
  async (data: { values: TagValue; orgId: number }) => {
    return await createTag(data);
  }
);

export const editTags = createAsyncThunk(
  "edit-tag",
  async (data: { id: number; newTagInput: TagValue; orgId: number }) => {
    return await editTag(data);
  }
);

export const getOrganizationListOfUser = createAsyncThunk(
  "get-organization",
  async (orgId: number) => {
    return await getOrganizationListToSwitch(orgId);
  }
);

export const getExchangeRateList = createAsyncThunk(
  "get-conversion-list",
  async () => {
    return await getCurrencyConversionList();
  }
);

export const getLatestCurrencyRate = createAsyncThunk(
  "get-conversion-list",
  async (data: {
    base_currency: number;
    to_currency: number;
    date: string;
  }) => {
    return await getLatestCurrencyConversion(data);
  }
);

export const createExchangeRateList = createAsyncThunk(
  "create-conversion-list",
  async (data: { values: ExchangeRateValues }) => {
    return await createExchangeRate(data);
  }
);

export const getCustomConversions = createAsyncThunk(
  "custom-conversion",
  async (data: { conversionId: number }) => {
    return await customConversions(data);
  }
);

export const getOrganizationCurrencies = createAsyncThunk(
  "get-org-currencies",
  async (data: { selectedOrgId: number; orgId: number }) => {
    return await getOrgCurrencyList(data);
  }
);

export const getOrganizationListForReport = createAsyncThunk(
  "get-org-list-for-report",
  async (data: { orgId: number }) => {
    return await getOrgListForReport(data);
  }
);

export const updatePrefix = createAsyncThunk(
  "update-prefix",
  async (data: {
    values: {
      bill_prefix?: string | null;
      invoice_prefix?: string | null;
      dn_prefix?: string | null;
      cn_prefix?: string | null;
      payments_prefix?: string | null;
      receipts_prefix?: string | null;
      journal_prefix?: string | null;
    };
    orgId: number;
  }) => {
    return await updatePrefixes(data);
  }
);

export const getPrefix = createAsyncThunk(
  "update-prefix",
  async (data: { orgId: number }) => {
    return await getPrefixes(data);
  }
);

export const getTableCustomizationFields = createAsyncThunk(
  "table-customization/get-fields",
  async (data: { tableName: string; orgId: number }) => {
    return await tableCustomizationFields(data);
  }
);

export const setTableCustomizationFields = createAsyncThunk(
  "table-customization/set-fields",
  async (data: { orgId: number; tableColumns: ObjectType }) => {
    return await addTableCustomizationFields(data);
  }
);

export const calculateForexGainOrLoss = createAsyncThunk(
  "forex-gain-loss",
  async (data: {
    values: ForexGainLossValues[];
    orgId: number;
    signal: Object;
  }) => {
    return await getForexGainOrLoss(data);
  }
);

export const roleMapping = createAsyncThunk("role-mapping", async () => {
  return await getRoleMapping();
});

export const fetchOrganizationListOfUsers = createAsyncThunk(
  "org-list-of-user",
  async () => {
    return await getOrganizationIDListOfUser();
  }
);
export const updateAllocationDateSettings = createAsyncThunk(
  "allocation-date-settings",
  async (data: { is_enabled: boolean; orgId: number }) => {
    return await editAllocationDateConfig(data);
  }
);
export const fetchtransactionConfig = createAsyncThunk(
  "fetch-transaction-config",
  async (orgId: number) => {
    return await getTransactionConfig(orgId);
  }
);
export const fetchCustomFrequencySettings = createAsyncThunk(
  "fetch-custom-frequency-settings",
  async (orgId: number) => {
    return await getCustomFrequencySettings(orgId);
  }
);

//getCustomFrequencySettings

export const editTransactionConfig = createAsyncThunk(
  "update-transaction-config",
  async (data: {
    values: {
      is_invoices_enabled?: boolean | null;
      is_credit_notes_enabled?: boolean | null;
      is_bills_enabled?: boolean | null;
      is_debit_notes_enabled?: boolean | null;
      is_journal_entry_enabled?: boolean | null;
      is_recurring_invoice_enabled?: boolean | null;
      is_subscription_enabled?: boolean | null;
    };
    orgId: number;
  }) => {
    return await updateTransactionConfig(data);
  }
);

interface CommonState {
  countries: LocationValues[];
  countryApiStatus: string;
  orgCurrencyList: CurrencyFormType[];
  states: LocationValues[];
  paymentTerms: PaymentTerms[];
  currencies: Currency[];
  tags: TagsList[];
  rolePermissionMapping: RolePermissionMappingValues;
  searchQuery: string;
  searchModule: string;
  dueDateListFilter: {
    dueDate: {
      startDate: string;
      endDate: string;
    };
  };
  dateListFilter: { date: { startDate: string; endDate: string } };
  fiscalYearFilter: {
    startDate: string;
    endDate: string;
  };
  fiscalYearFilterForValidation: {
    startDate: string;
    endDate: string;
  };
  fiscalYearFilterLabel: string;
  isSearching: boolean;
  searchBoxVariant: string;
  searchResult: ObjectType[];
  currentUserInfo: CurrentUserValues;
  userOrganization: OrganizationSwitchDropDownValues[];
  superParent: boolean;
  reportOrganization: {
    id: number;
    name: string;
    parent: string;
    currency_id: number;
  }[];
  loaderState: boolean;
  latestCurrencyRate: {
    latest_conversion: CurrencyList;
  };
  isLoadingCurrencyRate?: boolean;
  exchangeApiSatus: boolean;
  customFieldList: {
    Sales: {
      Invoices: ObjectType[];
      "Credit Notes": ObjectType[];
      "Payments Received": ObjectType[];
      Customer: ObjectType[];
    };
    Purchases: {
      Bills: ObjectType[];
      "Debit Notes": ObjectType[];
      "Payments Made": ObjectType[];
      Vendor: ObjectType[];
    };
    Accounts: {
      "Journal Entry": ObjectType[];
      "System Voucher": ObjectType[];
    };
    Subscription: {
      Addons: ObjectType[];
      Plans: ObjectType[];
      Subscriptions: ObjectType[];
    };
  };
  creditNoteAutoFillData: {
    isFromInvoice: boolean;
    balanceDue: number | string | null;
    invoiceId: number | null;
    invoiceStatus: string;
    creditNoteData: {
      defaultFormValues: CNDefaultValues;
      creditNoteFormValues: CreditNoteValues;
      itemFormValue: CustomerItemValues[];
    };
  };
  debitNoteAutoFillData: {
    isFromBill: boolean;
    balanceDue: number | string | null;
    billId: number | null;
    billStatus: string;
    debitNoteData: {
      defaultFormValues: DNDefaultValues;
      debitNoteFormValues: DebitNoteValues;
      itemFormValue: CustomerItemValues[];
    };
  };
  organizationRole: ObjectType;
  amountInWordsConfig: ObjectType;
  customFrequencySettings: ObjectType;
  transactionTotal: {
    gst_total: number | string;
    total_taxable_amount: number | string;
    tax_amount: number | string;
    total: number | string;
    taxes: ObjectType;
    tds_total: number | string;
    tcs_total: number | string;
    gst_amounts: ObjectType;
  };
  budgetFilters: {
    period: number;
    actuals: number;
    startDate: string;
    budgetName: string;
  };
  budgetEditableDateRanges: string[];
}
export const latestConversion = {
  latest_conversion: {
    base_currency: "",
    base_currency_id: 0,
    by_user: false,
    id: 0,
    rate: 0,
    timestamp: "",
    to_currency: "",
    to_currency_id: 0,
  },
};
const initialState: CommonState = {
  countries: [],
  countryApiStatus: "idle",
  states: [],
  orgCurrencyList: [],
  paymentTerms: [],
  currencies: [],
  tags: [],
  dateListFilter: { date: { startDate: "", endDate: "" } },
  dueDateListFilter: {
    dueDate: {
      startDate: "",
      endDate: "",
    },
  },
  fiscalYearFilter: {
    startDate: "",
    endDate: "",
  },
  fiscalYearFilterForValidation: {
    startDate: "",
    endDate: "",
  },
  fiscalYearFilterLabel: "",
  rolePermissionMapping: {
    permission_mapping: {},
    module_mapping: {},
    sub_module_mapping: {},
  },
  searchQuery: "",
  searchModule: "invoice",
  searchBoxVariant: "",
  searchResult: [],
  isSearching: false,
  currentUserInfo: {
    account_closing_date: null,
    country_id: null,
    country_name: null,
    currency_code: "",
    email: "",
    id: 0,
    name: null,
    organization_id: sessionStorage.getItem("orgId")
      ? Number(sessionStorage.getItem("orgId"))
      : 0,
    state_id: null,
    organization_currency: 0,
    organization_fiscal_year: "",
    organization_gst_state: "",
    onboarding_status: true,
    disabled_feature: [{ feature_name: "", disabled: false }],
    user_access_mapping: [{}],
    multi_org_null_last_login: true,
    is_super_parent: false,
    is_tags_enabled: false,
    is_tags_mandatory: false,
    previous_fiscal_start_date: "",
    previous_fiscal_end_date: "",
    is_payment_allocation_enabled: false,
    organization_tax_system: TAX_SYSTEM.NORMAL_TAX_SYSTEM,
    is_fiscal_closed: false,
    is_ledger_org_closed: false,
    migration_date: null,
    organization_date_format: "",
    is_vat_enabled: false,
  },

  userOrganization: [],
  superParent: true,
  reportOrganization: [],
  loaderState: false,
  latestCurrencyRate: latestConversion,
  isLoadingCurrencyRate: false,
  exchangeApiSatus: false,
  customFieldList: {
    Sales: {
      Invoices: [],
      "Credit Notes": [],
      "Payments Received": [],
      Customer: [],
    },
    Purchases: {
      Bills: [],
      "Debit Notes": [],
      "Payments Made": [],
      Vendor: [],
    },
    Accounts: {
      "Journal Entry": [],
      "System Voucher":[]
    },
    Subscription: {
      Addons: [],
      Plans: [],
      Subscriptions: [],
    },
  },
  creditNoteAutoFillData: {
    isFromInvoice: false,
    balanceDue: 0,
    invoiceId: null,
    invoiceStatus: "",
    creditNoteData: {
      creditNoteFormValues: {
        customer_id: "",
        customer_name: "",
        credit_note_number: "",
        subject: "",
        credit_note_date: getFormatedDate(new Date()),
        customer_notes: "",
        file_ids: [],
        tag_ids: [],
        items: [initialItemValues],
        email_to: [],
        reference_number: "",
        is_locked: false,
        lock_date: "",
        tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
        reason: "",
        conversion_id: "",
        source_tax_type: sourceTaxTypes.TCS,
        place_of_supply: "",
        currency_id: "",
      },
      defaultFormValues: {
        customer_id: "",
        customer_name: "",
        credit_note_number: "",
        subject: "",
        credit_note_date: getFormatedDate(new Date()),
        currency_id: "",
        reference_number: "",
        tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
        reason: "",
        source_tax_type: sourceTaxTypes.TCS,
        place_of_supply: "",
      },
      itemFormValue: [
        {
          item_name: "",
          account_id: "",
          account_name: "",
          quantity: 1.0,
          rate: 0.0,
          discount: 0.0,
          discount_type: "Absolute",
          discount_account_id: "",
          discount_account_name: "",
          taxes: [initialTaxValues],
          tds_id: null,
          tds_amount: 0,
          tds_account_id: 0,
          tds_percentage: 0,
          tds_name: "",
          currency_id: null,
        },
      ],
    },
  },
  debitNoteAutoFillData: {
    isFromBill: false,
    balanceDue: 0,
    billId: null,
    billStatus: "",
    debitNoteData: {
      debitNoteFormValues: {
        vendor_id: "",
        vendor_name: "",
        debit_note_number: "",
        subject: "",
        debit_note_date: getFormatedDate(new Date()),
        vendor_notes: "",
        file_ids: [],
        tag_ids: [],
        items: [initialItemValues],
        email_to: [],
        debit_note_status: "",
        conversion_id: "",
        currency_id: "",
        reference_number: "",
        is_locked: false,
        lock_date: "",
        tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
        exclude_discount_accounting: false,
      },
      defaultFormValues: {
        vendor_id: "",
        vendor_name: "",
        debit_note_number: "",
        subject: "",
        debit_note_date: getFormatedDate(new Date()),
        currency_id: "",
        reference_number: "",
        tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
      },
      itemFormValue: [
        {
          item_name: "",
          account_id: "",
          account_name: "",
          quantity: 1.0,
          rate: 0.0,
          discount: 0.0,
          discount_type: "Absolute",
          discount_account_id: "",
          discount_account_name: "",
          taxes: [initialTaxValues],
          tds_id: null,
          tds_amount: 0,
          tds_account_id: 0,
          tds_percentage: 0,
          tds_name: "",
          currency_id: null,
        },
      ],
    },
  },
  organizationRole: {},
  amountInWordsConfig: {},
  customFrequencySettings: {},
  transactionTotal: {
    gst_total: 0.0,
    total_taxable_amount: 0.0,
    tax_amount: 0.0,
    total: 0.0,
    taxes: {},
    tds_total: 0.0,
    tcs_total: 0.0,
    gst_amounts: {},
  },
  budgetFilters: {
    period: 0,
    actuals: 0,
    startDate: getFormatedDate(),
    budgetName: "",
  },
  budgetEditableDateRanges: [],
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    parentUpdate: (state, action: PayloadAction<boolean>) => {
      state.superParent = action.payload;
    },
    setLoaderState: (state, action) => {
      state.loaderState = action.payload;
    },
    setLatestConversionRate: (state, action) => {
      state.latestCurrencyRate = action.payload;
    },
    setExchangeApiSatus: (state, action) => {
      state.exchangeApiSatus = action.payload;
    },
    setCustomFieldList: (state, action) => {
      state.customFieldList = action.payload;
    },
    setCurrentUserDetails: (state, action) => {
      state.currentUserInfo = action.payload;
    },
    setCreditNoteAutofillData: (state, action) => {
      state.creditNoteAutoFillData = action.payload;
    },
    setDebitNoteAutofillData: (state, action) => {
      state.debitNoteAutoFillData = action.payload;
    },
    setSearchQueryValue: (state, action) => {
      state.searchQuery = action.payload.searchQuery;
      state.searchModule = action.payload.searchModule;
    },
    setSearchResult: (state, action) => {
      state.searchResult = [...action.payload];
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },
    setFiscalYearFilter: (state, action) => {
      state.fiscalYearFilter = action.payload;
    },
    setFiscalYearFilterForValidation: (state, action) => {
      state.fiscalYearFilterForValidation = action.payload;
    },
    setFiscalYearFilterLabel: (state, action) => {
      state.fiscalYearFilterLabel = action.payload;
    },
    setSearchBoxVariant: (state, action) => {
      state.searchBoxVariant = action.payload;
    },
    setCountryApiStatus: (state, action) => {
      state.countryApiStatus = action.payload;
    },
    setOrganizationRole: (state, action) => {
      state.organizationRole = action.payload;
    },
    setDueDateListFilter: (state, action) => {
      state.dueDateListFilter = action.payload;
    },
    setDateListFilter: (state, action) => {
      state.dateListFilter = action.payload;
    },
    setAmountInWordsConfig: (state, action) => {
      state.amountInWordsConfig = action.payload;
    },
    setCustomFrequencySettings: (state, action) => {
      state.customFrequencySettings = action.payload;
    },
    setTransactionTotal: (state, action) => {
      state.transactionTotal = action.payload;
    },
    setBudgetFilter: (state, action) => {
      state.budgetFilters = action.payload;
    },
    setBudgetEditableDateRanges: (state, action) => {
      state.budgetEditableDateRanges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.countries = payload;
          state.countryApiStatus = "fulfilled";
        } else {
          state.countries = [];
          state.countryApiStatus = "rejected";
        }
      })
      .addCase(getCountryStates.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.states = payload;
        } else {
          state.states = [];
        }
      })
      .addCase(paymentTermsList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.paymentTerms = payload;
        } else {
          state.paymentTerms = [];
        }
      })
      .addCase(currencyDropdown.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.currencies = payload;
        } else {
          state.currencies = [];
        }
      })
      .addCase(tagsList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.tags = payload;
        } else {
          state.tags = [];
        }
      })
      .addCase(currentUserDetails.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          if (
            payload.is_token_refreshed &&
            !payload.multi_org_null_last_login
          ) {
            state.currentUserInfo = payload;
          }
        } else {
          state.currentUserInfo = initialState.currentUserInfo;
        }
      })
      .addCase(getOrganizationListOfUser.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.userOrganization = payload;
        } else {
          state.userOrganization = [];
        }
      })
      .addCase(getOrganizationListForReport.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.reportOrganization = payload;
        } else {
          state.reportOrganization = [];
        }
      })
      .addCase(getCurrencies.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.orgCurrencyList = payload;
        } else {
          state.orgCurrencyList = [];
        }
      })
      .addCase(getLatestCurrencyRate.pending, (state, { payload }) => {
        state.isLoadingCurrencyRate = true;
      })
      .addCase(getLatestCurrencyRate.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.latestCurrencyRate = payload;
        } else {
          state.latestCurrencyRate = latestConversion;
        }
        state.isLoadingCurrencyRate = false;
      })
      .addCase(getCustomConversions.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.latestCurrencyRate = payload;
        } else {
          state.latestCurrencyRate = latestConversion;
        }
      })
      .addCase(getCustomFieldTotalList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.customFieldList = payload;
        } else {
          state.customFieldList = initialState.customFieldList;
        }
      })
      .addCase(roleMapping.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.rolePermissionMapping = payload;
        } else {
          state.rolePermissionMapping = initialState.rolePermissionMapping;
        }
      })
      .addCase(fetchtransactionConfig.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.amountInWordsConfig = payload;
        } else {
          state.amountInWordsConfig = initialState.amountInWordsConfig;
        }
      })
      .addCase(fetchCustomFrequencySettings.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.customFrequencySettings = payload;
        } else {
          state.customFrequencySettings = initialState.customFrequencySettings;
        }
      })
      .addCase(getAmountCalculation.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.transactionTotal = payload["total_details"];
        } else {
          state.transactionTotal = initialState.transactionTotal;
        }
      });
  },
});

export const {
  parentUpdate,
  setLoaderState,
  setLatestConversionRate,
  setExchangeApiSatus,
  setCurrentUserDetails,
  setCreditNoteAutofillData,
  setDebitNoteAutofillData,
  setSearchQueryValue,
  setSearchResult,
  setIsSearching,
  setFiscalYearFilter,
  setFiscalYearFilterForValidation,
  setFiscalYearFilterLabel,
  setSearchBoxVariant,
  setCountryApiStatus,
  setOrganizationRole,
  setDateListFilter,
  setDueDateListFilter,
  setAmountInWordsConfig,
  setCustomFrequencySettings,
  setTransactionTotal,
  setBudgetFilter,
  setBudgetEditableDateRanges,
} = commonSlice.actions;

export default commonSlice.reducer;

// State selectors
export const countrySelector = (state: RootState) => state.common.countries;
export const countryApiStatusSelector = (state: RootState) =>
  state.common.countryApiStatus;
export const stateSelector = (state: RootState) => state.common.states;
export const paymentTermsSelector = (state: RootState) =>
  state.common.paymentTerms;
export const currenciesSelector = (state: RootState) => state.common.currencies;
export const tagsSelector = (state: RootState) => state.common.tags;
export const currentUserSelector = (state: RootState) =>
  state.common.currentUserInfo;
export const currentUserOrgSelector = (state: RootState) =>
  state.common.userOrganization;
export const getSuperParent = (state: RootState) => state.common.superParent;
export const reportOrgSelector = (state: RootState) =>
  state.common.reportOrganization;
export const loaderStateSelector = (state: RootState) =>
  state.common.loaderState;
export const orgCurrencyListSelector = (state: RootState) =>
  state.common.orgCurrencyList;
export const isLoadingCurrencyRateSelector = (state: RootState) =>
  state.common.isLoadingCurrencyRate;
export const latestCurrencyRateSelector = (state: RootState) =>
  state.common.latestCurrencyRate;
export const exchangeApiStatusSelector = (state: RootState) =>
  state.common.exchangeApiSatus;
export const customFieldListSelector = (state: RootState) =>
  state.common.customFieldList;
export const creditNoteAutoFillDataSelector = (state: RootState) =>
  state.common.creditNoteAutoFillData;
export const debitNoteAutoFillDataSelector = (state: RootState) =>
  state.common.debitNoteAutoFillData;
export const permissionMappingSelector = (state: RootState) =>
  state.common.rolePermissionMapping;
export const searchQuerySelector = (state: RootState) => {
  return {
    searchQuery: state.common.searchQuery,
    searchModule: state.common.searchModule,
  };
};
export const searchResultSelector = (state: RootState) =>
  state.common.searchResult;
export const searchLoaderStateSelector = (state: RootState) =>
  state.common.isSearching;
export const fiscalYearFilterSelector = (state: RootState) =>
  state.common.fiscalYearFilter;
export const fiscalTearFilterForValidationSelector = (state: RootState) =>
  state.common.fiscalYearFilterForValidation;
export const fiscalYearFilterLabelSelector = (state: RootState) =>
  state.common.fiscalYearFilterLabel;
export const searchBoxVariantSelector = (state: RootState) =>
  state.common.searchBoxVariant;
export const organizationRoleSelector = (state: RootState) =>
  state.common.organizationRole;
export const dueDateListFilterSelector = (state: RootState) =>
  state.common.dueDateListFilter;
export const dateListFilterSelector = (state: RootState) =>
  state.common.dateListFilter;
export const amountInWordsConfigSelector = (state: RootState) =>
  state.common.amountInWordsConfig;
export const customFrequencySettingsSelector = (state: RootState) =>
  state.common.customFrequencySettings;
export const transactionTotalSelector = (state: RootState) =>
  state.common.transactionTotal;
export const budgetTableFilterValueSelector = (state: RootState) =>
  state.common.budgetFilters;
export const budgetEditableDateRangeSelector = (state: RootState) =>
  state.common.budgetEditableDateRanges;
