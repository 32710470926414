import { ObjectType } from "../types";

export const splitTransactionNumber = (transactionNumber: string) => {
  let fields: string[] = [];
  let splitTransNumber: { prefix: string; number: string } = {
    prefix: "",
    number: "",
  };
  if (transactionNumber.includes("~")) {
    fields = transactionNumber?.split("~");
    splitTransNumber = {
      prefix: fields && fields[0],
      number: fields && fields[1],
    };
  } else {
    splitTransNumber = { prefix: "", number: transactionNumber };
  }
  return splitTransNumber;
};

export const joinTransactionNumber = (transactionNumber: string) => {
  if (!transactionNumber) return "";
  if (transactionNumber.startsWith("~")) {
    return transactionNumber.replace(/^~|\s/g, "");
  }
  return transactionNumber.replace("~", " - ");
};

export const joinForPayload = (obj: ObjectType) => {
  let joinedTransactionNumber = "";
  if (obj) {
    if (
      Object.keys(obj).length === 2 &&
      !Object.values(obj).includes(undefined) &&
      !Object.values(obj).includes(null) &&
      !Object.values(obj).includes("")
    ) {
      joinedTransactionNumber = Object.values(obj).join("~").trim();
    } else if (Object.keys(obj).length === 2) {
      joinedTransactionNumber =
        joinedTransactionNumber +
        `${Object.values(obj)[0] ? Object.values(obj)[0] : ""}` +
        `${Object.values(obj)[1] ? Object.values(obj)[1] : ""}`;
    } else {
      joinedTransactionNumber = "";
    }
  } else {
    joinedTransactionNumber = "";
  }
  return joinedTransactionNumber?.trim();
};
