import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { IconButton } from "../../../../common/components/button/IconButton";
import { PrimaryButton } from "../../../../common/components/button/PrimaryButton";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { Container } from "../../../../common/components/htmlTags/Container";
import { FormFieldContainer, Input } from "../../../../common/components/ui";
import { colors } from "../../../../constants/colors";
import useCommonData from "../../../../hooks/useCommon";
import { SmtpServerConfigFormProps } from "../../types";
import { FormSelectField } from "../../../../common/components";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import Select from "react-select";
import { OptionType } from "../../../../../types";
import { Span } from "../../../../common/components/htmlTags/Span";
export const SmtpServerConfigForm = ({
  setIsSetup,
  values,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleSubmit,
  isValid,
  validateForm,
  touched,
  errors,
}: SmtpServerConfigFormProps) => {
  const { currentUserInfo } = useCommonData();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShow = () => {
    setShowPassword(!showPassword);
  };
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const reportTypeFormattedOptions: OptionType[] = [
    {
      label: "SSL",
      value: "SSL",
    },
    {
      label: "TLS",
      value: "TLS",
    },
  ];
  return (
    <Container>
      <Container className="mb-3">
        <FormInputField
          isRequired={true}
          label="SMTP Server Address"
          value={values.smtp_server || ""}
          onChange={handleChange}
          type={"text"}
          id="smtp_server"
          name="smtp_server"
          className="w-100 full-width-input"
          error={touched.smtp_server && errors.smtp_server}
          placeholder="Enter SMTP server address"
        />
      </Container>

      <Container className="mb-3">
        <FormInputField
          isRequired={true}
          label="Username"
          value={values.username || ""}
          onChange={handleChange}
          type={"text"}
          id="username"
          name="username"
          className="w-100 full-width-input"
          error={touched.username && errors.username}
          placeholder="Enter username"
        />
      </Container>
      <Container className="mb-3">
        <FormInputField
          isRequired={true}
          label="Password"
          value={values.password || ""}
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          className="w-100 full-width-input"
          placeholder="Enter password"
          error={touched.password && errors.password}
          endAdornment={
            <IconButton
              onClick={toggleShow}
              type="button"
              isDisabled={false}
              fontSize="14px"
              wrapperClass="h-100"
            >
              {showPassword ? (
                <VisibilityIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              )}
            </IconButton>
          }
        />
      </Container>
      <FormFieldContainer
        wrapperClass="mw-325 p-0"
        label={"Encryption Type"}
        isRequired={true}
      >
        <Select<OptionType>
          name="encryption_type"
          isDisabled={false}
          value={{
            value: values.encryption_type,
            label: values.encryption_type,
          }}
          options={reportTypeFormattedOptions}
          placeholder="Choose"
          onChange={(e) => {
            setFieldValue("encryption_type", e?.value);
          }}
          className={`w-100`}
          autoFocus={false}
          styles={customSelectStyle}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          classNamePrefix="react-select"
        />
      </FormFieldContainer>
      <Container className="mb-3">
        <FormInputField
          isRequired={true}
          label="Port"
          value={values.port || ""}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              handleChange(e);
            }
          }}
          type={"text"}
          id="port"
          name="port"
          className="w-100 full-width-input"
          error={touched.port && errors.port}
          placeholder="Enter username"
        />
      </Container>
    </Container>
  );
};
