import { addMonths, format, lastDayOfMonth } from "date-fns";
import { DateRange } from "../types/types";

type ProfitAndLoss = {
  total_operating_income: string;
  total_cost_of_goods_sold: string;
  gross_profit: string;
  total_operating_expense: string;
  non_operating_income: string;
  additional_category_income: number;
  additional_category_expense: string;
  net_profit_loss_before_tax: string;
  net_profit_loss_after_tax: string;
  corporate_tax: string;
};

type Range = Record<string, DateRange>;

type AccountNode = {
  id: number;
  parent_id: number | null;
  account_name: string;
  account_type: string;
  depth: number;
  is_category: boolean;
  range: Range;
  node: AccountNode[];
};

type Accounts = {
  accounts: AccountNode[];
  profit_and_loss: Record<string, ProfitAndLoss>;
  account_total?: Record<string, string | number>;
};

// Generate date ranges
const generateDateRanges = (startDate: string, period: number): string[] => {
  return Array.from({ length: period }, (_, i) => {
    const currentDate = addMonths(new Date(startDate), i);
    const start = format(currentDate, "yyyy-MM-dd");
    const end = format(lastDayOfMonth(currentDate), "yyyy-MM-dd");
    return `${start}_${end}`;
  });
};

// Generate profit and loss data
const generateProfitAndLoss = (
  dateRanges: string[]
): Record<string, ProfitAndLoss> => {
  return dateRanges.reduce((acc, range) => {
    acc[range] = {
      total_operating_income: "0.00",
      total_cost_of_goods_sold: "0.00",
      gross_profit: "0.00",
      total_operating_expense: "0.00",
      non_operating_income: "0.00",
      additional_category_income: 0,
      additional_category_expense: "0.00",
      net_profit_loss_before_tax: "0.00",
      net_profit_loss_after_tax: "0.00",
      corporate_tax: "0.00",
    };
    return acc;
  }, {} as Record<string, ProfitAndLoss>);
};

// Populate account nodes with generated ranges
const populateAccountNodeRanges = (
  node: AccountNode,
  dateRanges: string[]
): AccountNode => {
  const range = dateRanges.reduce((acc, rangeKey) => {
    acc[rangeKey] = { total: "0.00", transaction_exist: false };
    return acc;
  }, {} as Range);
  return {
    ...node,
    range,
    node: node.node.map((childNode) =>
      populateAccountNodeRanges(childNode, dateRanges)
    ),
  };
};

// Generate structured data
export const generateAccountsData = (
  inputData: Accounts,
  startDate: string,
  period: number
): Accounts => {
  const dateRanges = generateDateRanges(startDate, period);
  return {
    accounts: inputData.accounts.map((account) =>
      populateAccountNodeRanges(account, dateRanges)
    ),
    profit_and_loss: generateProfitAndLoss(dateRanges),
  };
};

// Merge ranges
const mergeRanges = (existingRange: Range, newRange: Range): Range => {
  return { ...newRange, ...existingRange };
};

// Merge profit and loss data
const mergeProfitAndLoss = (
  existingProfitAndLoss: Record<string, ProfitAndLoss>,
  newProfitAndLoss: Record<string, ProfitAndLoss>
): Record<string, ProfitAndLoss> => {
  return { ...newProfitAndLoss, ...existingProfitAndLoss };
};

// Merge account nodes
const mergeAccountNodes = (
  existingNode: AccountNode[],
  newNode: AccountNode[]
): AccountNode[] => {
  const existingMap = new Map(existingNode.map((acc) => [acc.id, acc]));
  return newNode.map((newAccount) => {
    const existingAccount = existingMap.get(newAccount.id);
    if (existingAccount) {
      return {
        ...newAccount,
        range: mergeRanges(existingAccount.range, newAccount.range),
        node: mergeAccountNodes(existingAccount.node, newAccount.node),
      };
    }
    return newAccount;
  });
};

// Main merge function
export const mergeAccountsData = (
  existingData: Accounts,
  newData: Accounts
): Accounts =>
  newData
    ? {
        accounts: mergeAccountNodes(existingData.accounts, newData.accounts),
        profit_and_loss: mergeProfitAndLoss(
          existingData.profit_and_loss,
          newData.profit_and_loss
        ),
        account_total: newData.account_total,
      }
    : existingData;
