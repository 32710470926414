import React from "react";
import { Row } from "@tanstack/react-table";
import { ProcessedRow } from "../types";
import { Span } from "../../../../../common/components/htmlTags/Span";

interface NetDebitCellProps {
  row: Row<ProcessedRow>;
  formatNumber: (value: number) => string;
}

const NetDebitCell: React.FC<NetDebitCellProps> = ({ row, formatNumber }) => {
  return (row.original.is_debit &&
    (row.original.net_difference || row.original.transaction_exist)) ||
    row.original.style_class === "total-node" || row.original.is_tag ? (
    <Span style={{ textAlign: "right" }}>
      {row.getIsExpanded()
        ? ""
        : formatNumber(
            Number(
              row.original.style_class === "total-node"
                ? row.original.net_debit
                : row.original.net_difference
            )
          )}
    </Span>
  ) : (
    <></>
  );
};

export default NetDebitCell;
