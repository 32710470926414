import React, {
  forwardRef,
  useEffect,
  useRef,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { toast } from "react-toastify";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import {
  JournalList,
  ObjectType,
  Transactionlimit,
} from "../../../../../../types";
import {
  dateSortOrderSelector,
  endCountSelector,
  filterAppliedState,
  itemOffsetSelector,
  itemsPerPageSelector,
  listFilterSelector,
  pageCountSelector,
  pageSelector,
  setListDateSortOrder,
  setListEndCount,
  setListItemOffset,
  setListItemsPerPage,
  setListPage,
  setListPageCount,
  setListStartCount,
  setTotalListCount,
  startCountSelector,
} from "../../../../../../appSlice";
import {
  getJournalLink,
  getTransactionLimitStatus,
  journalList,
  sendJournalData,
} from "../../../journalVoucherSlice";
import {
  DateFormatHandler,
  downloadUsingURL,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../../helpers/helper";
import {
  currentUserSelector,
  fiscalYearFilterSelector,
  orgCurrencyListSelector,
  reportOrgSelector,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import {
  getModuleStatusFilter,
  getStatusFilterString,
  getTagFilterString,
} from "../../../../../../helpers/statusFilterHelper";
import {
  journalColumnValues,
  journalDefaultValues,
  journalMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import JournalEntryTable from "./JournalEntryTable";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import TableSetup from "../../../../../common/components/table/TableSetup";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import SendEmail from "../../../../../common/components/kebabMenu/SendEmail";
import DownloadPdf from "../../../../../common/components/kebabMenu/DownloadPdf";
import Delete from "../../../../../common/components/Delete";
import CustomTooltips from "../../../../../common/components/Tooltip";

interface FiltersType {
  [category: string]: { id: number; name: string }[];
}
type OrgListObjectType = {
  id: number;
  name: string;
};

type SelectedFilterType = {
  Organizations: number[];
  Status: number[];
};

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, listNum: string) => void;
};

const JournalEntryTableContainer = (props: Props, ref: any) => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const page = useAppSelector(pageSelector);
  const itemsPerPage = useAppSelector(itemsPerPageSelector);
  const startCount = useAppSelector(startCountSelector);
  const endCount = useAppSelector(endCountSelector);
  const pageCount = useAppSelector(pageCountSelector);
  const itemOffset = useAppSelector(itemOffsetSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const dateSortOrder = useAppSelector(dateSortOrderSelector);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterAppliedState = useAppSelector(filterAppliedState);
  const orgList = useAppSelector(reportOrgSelector);
  const { journalVoucherRolePermission } = usePermissionHandler();
  const [journalData, setJournalData] = useState<JournalList[]>([]);
  const [totalJournals, setTotalJournals] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteJournalItemId, setDeleteJournalItemId] = useState("");
  const [deleteJournalId, setDeleteJournalId] = useState(-1);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [journalId, setJournalId] = useState(0);
  const [orgListFilter, setOrgListFilter] = useState<OrgListObjectType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Reference",
    "Notes",
    "Created By",
  ]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let journalVoucherPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Journal Entry"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useImperativeHandle(
    ref,
    () => ({
      fetchJournalList: fetchJournalList,
    }),
    []
  );

  //   useEffect(() => {
  //     if (!Object.keys(selectedFilterData).length) {
  //       initializeFilter();
  //     }
  //   }, []);
  useEffect(() => {}, []);

  useEffect(() => {
    fetchTableCustomizationFields(
      "Journal Entry",
      journalDefaultValues,
      journalMandatoryValues
    );
    fetchJournalList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    // JSON.stringify(fiscalYearFilter),
    // selectedFilterData,
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterAppliedState) fetchJournalList();
  }, [isFilterAppliedState, selectedFilterData]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    journalColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [journalData, selectedColumns]);

  useEffect(() => {
    getOrgList();
  }, [orgList]);

  useEffect(() => {
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  const setPage = (param: number) => {
    dispatch(setListPage(param));
  };
  const setTotalList = (param: number) => {
    dispatch(setTotalListCount(param));
  };
  const setItemsPerPage = (param: number) => {
    dispatch(setListItemsPerPage(param));
  };
  const setStartCount = (param: number) => {
    dispatch(setListStartCount(param));
  };
  const setEndCount = (param: number) => {
    dispatch(setListEndCount(param));
  };
  const setPageCount = (param: number) => {
    dispatch(setListPageCount(param));
  };
  const setItemOffset = (param: number) => {
    dispatch(setListItemOffset(param));
  };
  const setDateSortOrder = (param: string) => {
    dispatch(setListDateSortOrder(param));
  };
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Fetch all journal vouchers
   */
  const fetchJournalList = async () => {
    fetchTransactionLimit();
    setIsLoading(true);
    const responseAction = await dispatch(
      journalList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        tagList: selectedFilterData.Tag
          ? getTagFilterString(selectedFilterData.Tag)
          : [],
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setJournalData(response.journals);
        let length = response.journals.length;
        setTotalJournals(response.total);
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalJournals / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount !== 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalJournals, page]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalJournals;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const sortHandler = (column: string) => {
    if (column === "Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };

  /**
   * Set the contact emails
   */
  const fetchContactEmail = (id: number, emailsList: any[]) => {
    let emails = emailsList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setJournalId(id);
  };

  const downloadPdf = async (journalId: number) => {
    const responseAction = await dispatch(
      getJournalLink({
        journalId: journalId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          journalId + ".pdf"
        );
        if (result) {
          toast.success("Journal entry note downloaded successfully!", {
            toastId: "jounal-voucher-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };

  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  const getJournalColumnValues = () => {
    // let newJournalColumnValues = journalColumnValues;
    // if (
    //   currentUserInfo.organization_tax_system ===
    //     TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
    //   !newJournalColumnValues.includes("Tax Deducted(TDS)")
    // ) {
    //   newJournalColumnValues.push("Tax Deducted(TDS)");
    // }
    // return newJournalColumnValues;
    return journalColumnValues;
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Journal Entry #",
        accessor: "show.journalVoucherNum",
        sort: false,
        sortOrder: "",
        className: "jv_num",
      },
      {
        Header: "Reference #",
        accessor: selectedColumns.includes("Reference")
          ? "show.reference"
          : "Reference",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "jv_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value?.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Notes",
        accessor: selectedColumns.includes("Notes") ? "show.notes" : "Notes",
        sort: false,
        sortOrder: "",
        className: "notes",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement="bottom"
              title={value?.length > 15 ? value : ""}
            >
              <span className="jv_notes">
                {value && value.slice(0, 40).trim()}
                {value?.length > 40 ? "..." : ""}
              </span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Tag",
        accessor: selectedColumns.includes("Tag") ? "show.tag" : "Tag", //.............................. To be modified later
        sort: false,
        sortOrder: "",
        className: "notes",
      },
      {
        Header: "Amount",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "amount",
      },
      {
        Header: "Created By",
        accessor: selectedColumns.includes("Created By")
          ? "show.createdBy"
          : "Created By",
        sort: false,
        sortOrder: "",
        className: "created_by",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Journal Entry"
            tableColumns={selectedColumns}
            customizeColumnValues={journalColumnValues}
            mandatoryColumns={journalMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Journal Entry",
                journalDefaultValues,
                journalMandatoryValues
              );
              fetchJournalList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!journalVoucherPermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <KebabMenu>
              {journalVoucherPermission.includes("Update") &&
              value.isMutable ? (
                <li>
                  <Edit
                    url={`/journal-entry/edit/${value.id}`}
                    isMutable={true}
                    name="Journal Voucher"
                    isLocked={value.isLocked}
                    className={"dropdown-item"}
                    lockDate={value.lockDate}
                    isFiscalClosed={value.is_fiscal_closed}
                  />
                </li>
              ) : null}
              {value.status !== "Draft" ? (
                <>
                  <li>
                    <SendEmail
                      onClick={() => fetchContactEmail(value.id, value.emailTo)}
                    />
                  </li>
                  <li>
                    <DownloadPdf onClick={() => downloadPdf(value.id)} />
                  </li>
                </>
              ) : null}
              {journalVoucherPermission.includes("Delete") &&
              value.isMutable ? (
                <li>
                  <Delete
                    className={"dropdown-item delete"}
                    deleteIcon={true}
                    isMutable={true}
                    onClick={() => props.deleteListData(value.id, value.listName)}
                    isLocked={value.isLocked}
                    lockDate={value.lockDate}
                    name="Journal Voucher"
                    isFiscalClosed={value.is_fiscal_closed}
                  />
                </li>
              ) : null}
            </KebabMenu>
          );
        },
      },
    ],
    [
      dateSortOrder,
      currentUserInfo.organization_id,
      selectedColumns,
      hiddenColumns,
    ]
  );
  /**
   * set table row data
   */
  const data = journalData
    ? journalData.map((journal, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            journal.date
          ),
          journalVoucherNum: joinTransactionNumber(
            `${journal.journal_voucher_number}`
          ),
          reference: journal.reference_number,
          status: journal.journal_status,
          notes: journal.notes,
          tag: journal.entity_tag,
          amount:
            journal.total_amount !== "-"
              ? journal.currency_code +
                " " +
                NumberFormat(
                  Number(journal.total_amount),
                  journal.currency_code,
                  orgCurrencyList
                )
              : journal.total_amount,
          createdBy: journal.created_by,
          organization: journal.organization,
          actions: {
            id: journal.id,
            status: journal.journal_status,
            listName: journal.journal_voucher_number,
            emailTo: journal.email_to,
            organizationId: journal.organization_id,
            isLocked: journal.is_locked,
            lockDate: journal.lock_date,
            isMutable: journal.is_mutable,
            is_fiscal_closed: journal.is_fiscal_closed,
          },
        },
      }))
    : [];

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendJournalData({
        journalId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchJournalList();
      }
    }
    return responseAction;
  };

  /**
   * fetching org list for filters
   */
  const getOrgList = async () => {
    let orgsList: OrgListObjectType[] = [];
    if (orgList.length) {
      orgList.map((orgs: ObjectType) => {
        const { name, id } = orgs;
        orgsList.push({ name: name, id: id });
      });
    }
    setOrgListFilter(orgsList);
  };

  /**
   * Setting filter options for the ListFilter Component
   */
  const Filters: FiltersType[] = [
    {
      Organizations: orgListFilter,
    },
    {
      Status: getModuleStatusFilter("JV"),
    },
  ];
  return (
    <>
      <JournalEntryTable
        totalList={totalJournals}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        JournalEntryRolePermission={journalVoucherRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
        pageCount={pageCount}
        isLoading={isLoading}
        handlePageClick={handlePageClick}
      />
      <SendEmailModal
        emailLists={emailList}
        id={journalId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
};

export default React.memo(forwardRef(JournalEntryTableContainer));
