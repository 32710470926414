import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../app/store";

const initialState = [
  {
    id: "",
    country_id: 0,
    address: "",
    city: "",
    state_id: 0,
    zip_code: null,
    phone: null,
    is_billing: false,
    is_shipping: false,
    is_primary: false,
    state_name: "",
    country_name: "",
    customer_name:"",
    is_updated: false,
  },
];

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    addAddress: (state, action) => {
      state.push(action.payload);
    },
    editAddress: (state, action) => {
      const {
        id,
        country_id,
        address,
        city,
        state_id,
        zip_code,
        phone,
        is_billing,
        is_shipping,
        is_primary,
        state_name,
        country_name,
        customer_name
      } = action.payload;
      const existingAddress = state.find((address) => address.id === id);
      if (existingAddress) {
        existingAddress.address = address;
        existingAddress.country_id = country_id;
        existingAddress.city = city;
        existingAddress.state_id = state_id;
        existingAddress.zip_code = zip_code;
        existingAddress.phone = phone;
        existingAddress.is_billing = is_billing;
        existingAddress.is_shipping = is_shipping;
        existingAddress.is_primary = is_primary;
        existingAddress.state_name = state_name;
        existingAddress.country_name = country_name;
        existingAddress.customer_name = customer_name;
        existingAddress.is_updated = true;
      }
    },
    deleteAddress: (state, action) => {
      const { id } = action.payload;
      const existingAddress = state.find((address) => address.id === id);
      if (existingAddress) {
        return state.filter((address) => address.id !== id);
      }
    },
    setAsPrimaryBillingAddress: (state, action) => {
      const { id } = action.payload;
      const primaryIndex = state.findIndex(
        (item) => item.is_primary === true && item.is_billing === true
      );
      const addressArray = [...state];
      if (primaryIndex !== -1) {
        addressArray[primaryIndex].is_primary = false;
      }
      const selectedIndex = state.findIndex((item) => item.id === id);
      addressArray[selectedIndex].is_primary = true;
      state = addressArray;
    },
    setAsPrimaryShippingAddress: (state, action) => {
      const { id } = action.payload;
      const primaryIndex = state.findIndex(
        (item) => item.is_primary === true && item.is_shipping
      );
      const addressArray = [...state];
      if (primaryIndex !== -1) {
        addressArray[primaryIndex].is_primary = false;
      }
      const selectedIndex = state.findIndex((item) => item.id === id);
      addressArray[selectedIndex].is_primary = true;
      state = addressArray;
    },
    addressReset: () => initialState,
  },
});

export const {
  addAddress,
  editAddress,
  deleteAddress,
  setAsPrimaryBillingAddress,
  setAsPrimaryShippingAddress,
  addressReset,
} = addressSlice.actions;

export default addressSlice.reducer;

export const addressSelector = (state: RootState) => state.address;
