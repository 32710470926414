import React, { useEffect, useState, useId } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { SaveButton, SecondaryButton } from "../../../../../common/components";
import {
  ObjectType,
  systemVoucherConfigurations,
} from "../../../../../../types";
import {
  Form,
  FormFieldContainer,
  Input,
} from "../../../../../common/components/ui";
import {
  createSystemVoucherConfiguration,
  getSystemVoucherConfigurationDetail,
  systemVoucherNameIsExists,
  systemVoucherPrefixIsExists,
  updateSystemVoucherConfigurationItem,
} from "../../../systemVoucherSlice";
import useCommonData from "../../../../../hooks/useCommon";
import MultiselectAccount from "../multiselectAccount/MultiselectAccount";
import labels from "../../../resources/labels";
import systemVoucherValidationSchema from "./systemVoucherValidationSchema";
import "../../../../Home.css";
import "./systemVoucherConfigurationForm.css";

type Props = {
  systemVoucherTypeId?: number | undefined;
  handleRefreshList: () => void;
  handleModalClose: () => void;
};
const SystemVoucherConfigurationForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [initialValues, setInitialValues] =
    useState<systemVoucherConfigurations>({
      systemVoucherId: props.systemVoucherTypeId || "", // Will only have value if editing
      systemVoucherName: "",
      prefix: "",
      accountIds: [],
    });
  const formId = useId();

  useEffect(() => {
    if (props.systemVoucherTypeId) {
      getSystemVoucherDetails(props.systemVoucherTypeId);
    }
  }, [props.systemVoucherTypeId]);

  //Check whether system voucher name is already existing or not
  const checkDuplicateName = async (
    systemVoucherName: string,
    systemVoucherId: number = 0
  ) => {
    const data = {
      systemVoucherName: systemVoucherName.trim(),
      orgId: currentUserInfo.organization_id,
      systemVoucherId: systemVoucherId > 0 ? systemVoucherId : "",
    };
    const responseAction = await dispatch(systemVoucherNameIsExists(data));

    if (Object.keys(responseAction).length && !("error" in responseAction)) {
      return responseAction.payload;
    }
  };
  //Check whether prefix is already existing or not
  const checkDuplicatePrefix = async (
    prefix: string,
    systemVoucherId: number = 0
  ) => {
    const data = {
      prefix: prefix.trim(),
      orgId: currentUserInfo.organization_id,
      systemVoucherId: systemVoucherId > 0 ? systemVoucherId : "",
    };
    const responseAction = await dispatch(systemVoucherPrefixIsExists(data));
    if (Object.keys(responseAction).length && !("error" in responseAction)) {
      return responseAction.payload;
    }
  };

  // Fetch system voucher details when editing
  const getSystemVoucherDetails = async (systemVoucherTypeId: number) => {
    const data = {
      orgId: currentUserInfo.organization_id,
      systemVoucherId: systemVoucherTypeId,
    };
    const responseAction = await dispatch(
      getSystemVoucherConfigurationDetail(data)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let accountIdArray: any[] = response.accounts
          .filter((account: ObjectType) => Number(account.id))
          .map((account: ObjectType) => Number(account.id));
        setInitialValues({
          systemVoucherId: response.id,
          systemVoucherName: response.system_voucher_name,
          prefix: response.prefix,
          accountIds: accountIdArray,
        });
      }
    }
  };
  //submit action
  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: systemVoucherValidationSchema(
      checkDuplicateName,
      checkDuplicatePrefix,
      props.systemVoucherTypeId
    ),
    validateOnChange: false,
    onSubmit: async (values) => {
      const payload = {
        values: {
          system_voucher_name: values.systemVoucherName,
          prefix: values.prefix,
          account_ids: values.accountIds,
        },
        orgId: currentUserInfo.organization_id,
      };

      if (props.systemVoucherTypeId) {
        // Editing existing voucher
        const editPayload = {
          ...payload,
          systemVoucherId: props.systemVoucherTypeId,
        };
        const responseUpdateAction = await dispatch(
          updateSystemVoucherConfigurationItem(editPayload)
        );
        if (responseUpdateAction.payload) {
          const response = responseUpdateAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            toast.success(labels.succesfullyUpdateToastMessage, {
              toastId: "system-voucher-configure-update-success",
              closeButton: false,
              position: "top-center",
            });
          }
        }
      } else {
        // Creating a new voucher
        const responseAction = await dispatch(
          createSystemVoucherConfiguration(payload)
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            toast.success(labels.successToastMessage, {
              toastId: "system-voucher-configure-success",
              closeButton: false,
              position: "top-center",
            });
          }
        }
      }
      props.handleRefreshList(); //refresh the system voucher congiguration list
      props.handleModalClose(); // Close the modal after successful submission
    },
  });

  return (
    <>
      <Container className="system-voucher-configuration-field-wrapper">
        <Form
          wrapperClass="system-voucher-configuration-form"
          name="system-voucher-configuration-form"
          onSubmit={formik.handleSubmit}
        >
          <Container className="field-wrapper w-100">
            <FormFieldContainer
              wrapperClass="p-0"
              label={labels.systemVoucherName}
              isRequired={true}
            >
              <Input
                name="systemVoucherName"
                type="text"
                className="form-control mw-none"
                placeholder={labels.systemVoucherPlaceholder}
                maxLength={100}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.systemVoucherName}
                error={
                  formik.touched.systemVoucherName &&
                  formik.errors.systemVoucherName
                    ? formik.errors.systemVoucherName
                    : ""
                }
              />
            </FormFieldContainer>
            <FormFieldContainer
              wrapperClass="p-0"
              label={labels.systemVoucherPrefixName}
              isRequired={true}
            >
              <Input
                name="prefix"
                type="text"
                className="form-control mw-none"
                placeholder={labels.systemVoucherPrefixPlaceholder}
                maxLength={10}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.prefix}
                error={
                  formik.touched.prefix && formik.errors.prefix
                    ? formik.errors.prefix
                    : ""
                }
              />
            </FormFieldContainer>
            <FormFieldContainer
              wrapperClass="p-0"
              label={labels.accountList}
              isRequired={true}
            >
              <MultiselectAccount
                organizationId={currentUserInfo.organization_id}
                selectedAccounts={formik.values.accountIds}
                onChange={(selectedAccounts) => {
                  formik.setFieldValue(
                    "accountIds",
                    JSON.parse(JSON.stringify(selectedAccounts))
                  );
                }}
                error={
                  (formik.touched.accountIds &&
                    formik.errors.accountIds?.toString()) ||
                  ""
                }
                errorClass="multi-selection-account-error"
                wrapperClass="w-100 clear-both"
                className="account-full-width clear-both"
              />
            </FormFieldContainer>
          </Container>
          <Container className="button-wrapper w-100">
            <SaveButton
              type="button"
              buttonContent="Save"
              onClick={handleSubmit}
              isDisabled={false}
            />
            <SecondaryButton
              type={"button"}
              buttonContent="Cancel"
              onClick={props.handleModalClose}
              isDisabled={false}
            />
          </Container>
        </Form>
      </Container>
    </>
  );
};

export default SystemVoucherConfigurationForm;
