import { Api } from "../../../constants";
import { get, put } from "../../../helpers/apiHelper";

/**
 * API call to get vat settings
 */
export async function getVatSettings(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.vatConfiguration}/vat-settings?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for updating gst settings
 */
export async function saveVatSettings(data: { values: any; orgId: number }) {
  let payload: any = {
    tax_registration_number: data.values.tax_registration_number
  };
  const vat_enabled = data.values.vat_enabled === "Yes" ? true : false;
  const result = await put(
    Api.chartOfAccount +
      `${Api.vatConfiguration}/vat-settings?current_organization=${data.orgId}&vat_enabled=${vat_enabled}`,
    payload
  );
  return result;
}
