import React from "react";
import "./ReportHeader.css";
import { ObjectType } from "../../../../../types";
import {
  DateFormatHandler,
  getProfileFormatedDate,
  handleNegativeValue,
} from "../../../../../helpers/helper";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import { useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import TransactionBalance from "./TransactionBalance";

type Props = {
  organizationData: ObjectType;
};
export default function ReportHeader(props: Props) {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const orgData = props.organizationData;
  const currencyCode = orgData.currency_code;
  const contactOrAccount = orgData.contact_or_account_name;
  const statementType = orgData.statement_type;
  const currentUserInfo = useAppSelector(currentUserSelector);

  return (
    <div className="report-header">
      <div className="report-header-container d-flex justify-content-between">
        <CompanyDetails organizationData={orgData?.organization_details} />
        <div className="report-details align-right">
          <div className="page-type-label">Statement of Accounts</div>
          <div className="preview-date-field">
            <span className="start-dt">
              {getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                orgData.start_date
              )}
            </span>{" "}
            to{" "}
            <span className="end-dt">
              {getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                orgData.end_date
              )}
            </span>
          </div>
          <div className="account-name">{contactOrAccount}</div>

          <div className="account-summary-section">
            <div className="summary-label">Account Summary</div>
            <div className="summary-wrapper">
              <TransactionBalance
                label={"Opening Balance"}
                amount={Number(orgData.opening_balance)}
                currencyCode={currencyCode}
              />
              {contactOrAccount === "Sundry Debtors" ||
              contactOrAccount === "Sundry Creditors" ||
              statementType === "Customer" ||
              statementType === "Vendor" ? (
                <>
                  <TransactionBalance
                    label={
                      contactOrAccount === "Sundry Debtors" ||
                      statementType === "Customer"
                        ? "Invoice Amount"
                        : contactOrAccount === "Sundry Creditors" ||
                          statementType === "Vendor"
                        ? "Bill Amount"
                        : ""
                    }
                    amount={
                      contactOrAccount === "Sundry Debtors" ||
                      statementType === "Customer"
                        ? Number(orgData.invoice_amount)
                        : contactOrAccount === "Sundry Creditors" ||
                          statementType === "Vendor"
                        ? Number(orgData.bill_amount)
                        : 0
                    }
                    currencyCode={currencyCode}
                  />
                  <TransactionBalance
                    label={
                      contactOrAccount === "Sundry Debtors" ||
                      statementType === "Customer"
                        ? "Payments Received"
                        : contactOrAccount === "Sundry Creditors" ||
                          statementType === "Vendor"
                        ? "Payments Made"
                        : ""
                    }
                    amount={
                      contactOrAccount === "Sundry Debtors" ||
                      statementType === "Customer"
                        ? Number(orgData.payment_received_amount)
                        : contactOrAccount === "Sundry Creditors" ||
                          statementType === "Vendor"
                        ? Number(orgData.payment_made_amount)
                        : 0
                    }
                    currencyCode={currencyCode}
                  />
                  {(statementType === "Customer" ||
                    statementType === "Vendor") &&
                    (orgData.credit_note_amount !== 0 ||
                      orgData.debit_note_amount !== 0) && (
                      <TransactionBalance
                        label={
                          statementType === "Customer"
                            ? "Credit note Amount"
                            : "Debit note Amount"
                        }
                        amount={
                          statementType === "Customer"
                            ? Number(orgData.credit_note_amount)
                            : Number(orgData.debit_note_amount)
                        }
                        currencyCode={currencyCode}
                      />
                    )}
                  {(statementType === "Customer" ||
                    statementType === "Vendor") &&
                    orgData.journal_entry_amount !== 0 && (
                      <TransactionBalance
                        label={"Journal Entry Amount"}
                        amount={Number(orgData.journal_entry_amount)}
                        currencyCode={currencyCode}
                      />
                    )}
                    {(statementType === "Customer" ||
                    statementType === "Vendor") &&
                    orgData.system_voucher_amount !== 0 && (
                      <TransactionBalance
                        label={"System Voucher Amount"}
                        amount={Number(orgData.system_voucher_amount)}
                        currencyCode={currencyCode}
                      />
                    )}
                </>
              ) : (
                <>
                  <TransactionBalance
                    label={"Closing Balance"}
                    amount={Number(orgData.closing_balance)}
                    currencyCode={currencyCode}
                  />
                </>
              )}
              <div className="summary-item due-amt">
                <div className="summary-label due-label">Balance Due:</div>
                <div className="summary-value due-amt">
                  {orgData?.balance_due !== null
                    ? handleNegativeValue(
                        NumberFormat(
                          Number(orgData?.balance_due),
                          currencyCode,
                          orgCurrencyList
                        ),
                        ""
                      )
                    : 0}
                </div>
              </div>
              {(statementType === "Customer" || statementType === "Vendor") &&
                (orgData.excess_pr_amount !== 0 ||
                  orgData.excess_pm_amount !== 0) && (
                  <TransactionBalance
                    label={"Excess Payment"}
                    amount={
                      statementType === "Customer"
                        ? Number(orgData.excess_pr_amount)
                        : Number(orgData.excess_pm_amount)
                    }
                    currencyCode={currencyCode}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
