const BASE_URL = process.env.REACT_APP_API_URL;
const VERSION = "api/" + process.env.REACT_APP_API_VERSION + "/";

export const Api = {
  addon: BASE_URL + "plans/addons/" + VERSION,
  agingReport: "aging-report/",
  auditLog: BASE_URL + "auditlogs/" + VERSION,
  bills: BASE_URL + "bills/" + VERSION,
  budget: "budget",
  budgetBase: BASE_URL + "budget/" + VERSION,
  charges: BASE_URL + "plans/charges/" + VERSION,
  chartOfAccount: BASE_URL + "chart-of-accounts/" + VERSION,
  childInvoice: "child-invoice",
  countries: BASE_URL + VERSION + "country",
  coupon: BASE_URL + "coupons/" + VERSION,
  creditNote: BASE_URL + "sales-invoice/credit-note/" + VERSION,
  customerManagement: BASE_URL + "customer/" + VERSION,
  customer: "customer",
  customerAddress: "customer-address",
  customerBillingAddress: "customer-billing-address",
  customerCombinedAddress: "customer-combined-address",
  customerContact: "customer-contact",
  customerShippingAddress: "customer-shipping-address",
  customField: BASE_URL + VERSION + "custom-field",
  currency: BASE_URL + "currency/" + VERSION,
  debitNote: BASE_URL + "bills/debit-note/" + VERSION,
  exchangeRate: "exchange-rate",
  forgotPassword: BASE_URL + VERSION + "forgot-password",
  getPreferredInvoiceStatus: "get-preferred-invoice-status",
  gstConfiguration: "gst_configuration",
  vatConfiguration: "vat_configuration",
  gstTypes: "gst-types",
  internalNameExists: "internal-name-exist",
  inventoryItem: BASE_URL + "inventory/" + VERSION + "item",
  invoice: "invoice",
  journalVoucher: BASE_URL + "journal-voucher/" + VERSION,
  login: BASE_URL + VERSION + "login",
  me: BASE_URL + VERSION + "me",
  notification: BASE_URL + "notification/" + VERSION,
  organization: BASE_URL + VERSION,
  organizationHierarchy: BASE_URL + VERSION + "organization",
  organizationProfile: BASE_URL + VERSION + "organization-profile",
  passwordReset: BASE_URL + VERSION + "password-reset",
  paymentMade: BASE_URL + "bank-payments/" + VERSION,
  paymentReceived: BASE_URL + "bank-receipts/" + VERSION,
  paymentGateway: BASE_URL + "payments/" + VERSION,
  paymentTerms: "payment-term",
  plan: BASE_URL + "plans/" + VERSION,
  planPreferences: BASE_URL + "plans/preferences/" + VERSION,
  pricePoint: "price-point",
  proforma: "proforma",
  proformaInvoice: BASE_URL + "sales-invoice/proforma-invoice/" + VERSION,
  purchaseOrder: BASE_URL + "bills/purchase-order/" + VERSION,
  recurringInvoice: "invoice",
  recurringSalesInvoice:
    BASE_URL + "sales-invoice/recurring-invoice/" + VERSION,
  registration: BASE_URL + VERSION + "registration",
  reports: BASE_URL + "reports/" + VERSION,
  roles: "roles",
  roundOffPreference: "roundoff_preference",
  salesInvoice: BASE_URL + "sales-invoice/" + VERSION,
  salesRecurringInvoice:
    BASE_URL + "sales-invoice/recurring-invoice/" + VERSION,
  search: BASE_URL + "search",
  
  subscription: BASE_URL + "subscriptions/" + VERSION,
  systemVoucher: BASE_URL + "system-voucher/" + VERSION,
  tableCustomization: BASE_URL + VERSION + "table-customization",
  tags: BASE_URL + VERSION + "tags",
  taxConfiguration: "tax_configuration",
  taxExemption: "tax_exemptions",
  tcsConfiguration: "tcs_configuration",
  tdsConfiguration: "tds_configuration",
  transactionConfig: BASE_URL + VERSION + "transaction-config",
  transactionLocking: BASE_URL + VERSION + "transaction-locking",
  updatePreferredInvoiceStatus: "update-preferred-invoice-status",
  userManagement: BASE_URL + VERSION + "user-management",
  userRoles: "user-roles",
  utils: BASE_URL + VERSION + "utils",
  vendor: "vendor",
  vendorAddress: "vendor-address",
  vendorBillingAddress: "vendor-billing-address",
  vendorCombinedAddress: "vendor-combined-address",
  vendorContact: "vendor-contact",
  vendorManagement: BASE_URL + "vendor/" + VERSION,
  vendorShippingAddress: "vendor-shipping-address",
};
