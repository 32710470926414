import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { ReportTagFormValues } from "../../../../../types";
import { IconButton } from "../../../../common/components/button/IconButton";
import { DeleteAlertModal } from "../../../../common/components/deleteModal/DeleteAlertModal";
import { Container } from "../../../../common/components/htmlTags/Container";
import Li from "../../../../common/components/ui/formElements/Li/Li";
import List from "../../../../common/components/ui/formElements/Ul/Ul";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import "../../ReportingTag.css";
import { useDeleteTag } from "../../hooks/useDeleteTag";
import { ReportingTagFormModal } from "../../reportingTagForm/ReportingTagFormModal";
type ListActionProps = {
  tagId: number;
  isEditDisabled: boolean;
  isDeleteDisabled: boolean;
  setReportingTagsList: React.SetStateAction<any>;
  tagDetails: ReportTagFormValues;
  isAssociated: boolean;
};
export const ListActions = (props: ListActionProps) => {
  const { deleteTag } = useDeleteTag();
  const { reportinTagsPermission } = usePermissionHandler();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return reportinTagsPermission.includes("Update") ||
    reportinTagsPermission.includes("Delete") ? (
    <>
      <IconButton
        type="button"
        wrapperClass="p-0"
        isDisabled={false}
        fontSize="18px"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <MoreVertIcon sx={{ color: "#6c757d", fontSize: "20px" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "4px",
            border: "1px solid #e9ecef",
          },
        }}
      >
        <Container style={{ width: "fit-content" }}>
          <List style={{ listStyle: "none" }}>
            <>
              {reportinTagsPermission.includes("Update") ? (
                <Li
                  key={` ${props.tagId}-edit`}
                  tabIndex={0}
                  wrapperClass="dropdown-item list-action-item  d-flex w-100 pr-4"
                  style={
                    props.isEditDisabled === true
                      ? { pointerEvents: "none", opacity: "0.5" }
                      : {}
                  }
                >
                  <ReportingTagFormModal
                    editId={props.tagId}
                    tagDetails={props.tagDetails}
                    buttonClass="reporting-tag-edit-modal-btn no-appearance p-0 w-100 d-flex"
                    wrapperClass="reporting-tag-edit-modal p-0 w-100 "
                    setReportingTagsList={props.setReportingTagsList}
                    onCloseFunction={handleClose}
                  />
                </Li>
              ) : null}
              {reportinTagsPermission.includes("Delete") ? (
                <Li
                  key={`${props.tagId}-delete`}
                  style={
                    props.isDeleteDisabled === true
                      ? { pointerEvents: "none", opacity: "0.5" }
                      : {}
                  }
                  tabIndex={0}
                  wrapperClass="dropdown-item list-action-item d-flex w-100"
                >
                  <DeleteAlertModal
                    onCancel={() => {
                      handleClose();
                    }}
                    onSubmit={() => {
                      deleteTag(props.tagId, props.setReportingTagsList);
                      handleClose();
                    }}
                    isSubmitDisabled={props.isDeleteDisabled}
                    isButtonDisabled={props.isAssociated}
                    deleteMessageContent={
                      <span>
                        Reporting tag will be deleted across the system since it
                        is not associated with any transactions.
                      </span>
                    }
                    deleteModalTitle="Delete Reporting Tag"
                    disabledActionFunction={() => {
                      ErrorToaster(
                        "Reporting tag cannot be deleted since it has transactions associated with it!"
                      );
                    }}
                    isDropdown={false}
                  />
                </Li>
              ) : null}
              {reportinTagsPermission.includes("Create") ? (
                <Li
                  key={`${props.tagId}-clone`}
                  wrapperClass=" dropdown-item list-action-item d-flex w-100"
                  tabIndex={0}
                  // style={
                  //   props.isEditDisabled === true
                  //     ? { pointerEvents: "none", opacity: "0.5" }
                  //     : {}
                  // }
                >
                  <ReportingTagFormModal
                    isClone={true}
                    tagDetails={props.tagDetails}
                    buttonClass="reporting-tag-edit-modal-btn no-appearance p-0 w-100 d-flex"
                    wrapperClass="reporting-tag-edit-modal w-100 p-0"
                    setReportingTagsList={props.setReportingTagsList}
                    onCloseFunction={handleClose}
                  />
                </Li>
              ) : null}
            </>
          </List>
        </Container>
      </Popover>
    </>
  ) : null;
};
