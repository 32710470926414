import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import AccountSelect from "../../../../../common/components/accountSelect/AccountSelect";
import { IconButton } from "../../../../../common/components/button/IconButton";
import { PrimaryButton } from "../../../../../common/components/button/PrimaryButton";
import { SecondaryButton } from "../../../../../common/components/button/SecondaryButton";
import { FormInputField } from "../../../../../common/components/formInputField/FormInputField";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { FormFieldContainer } from "../../../../../common/components/ui";
import { colors } from "../../../../../constants/colors";
import useCommonData from "../../../../../hooks/useCommon";
import { PaymentGatewayConfigFormProps } from "../../../types";
import { InfoToaster } from "../../../../../../helpers/toastHelper";

export const PaymentGatewayConfigForm = ({
  setIsSetup,
  values,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleSubmit,
  isValid,
  validateForm,
  touched,
  errors,
}: PaymentGatewayConfigFormProps) => {
  const { currentUserInfo } = useCommonData();

  const [isSecretKeyVisible, setIsSecretKeyVisible] = useState(false);
  const [isPublishableKeyVisible, setIsPublishableKeyVisible] = useState(false);
  const [isWebhookSecretVisible, setIsWebhookSecretVisible] = useState(false);
  const toggleShow = (
    key: "publishable_key" | "secret_key" | "webhook_secret"
  ) => {
    if (key === "publishable_key") {
      setIsPublishableKeyVisible(!isPublishableKeyVisible);
    } else if (key === "secret_key") {
      setIsSecretKeyVisible(!isSecretKeyVisible);
    } else {
      setIsWebhookSecretVisible(!isWebhookSecretVisible);
    }
  };
  return (
    <Container>
      <Container className="mb-3">
        <FormInputField
          label="Publishable Key"
          value={values.api_key || ""}
          onChange={handleChange}
          type={isPublishableKeyVisible ? "text" : "password"}
          id="api_key"
          name="selected_payment_gateway.api_key"
          className="w-100 "
          error={touched.api_key && errors.api_key}
          placeholder="Enter publishable key"
          onKeyDown={(event) => {
            if (
              (event.ctrlKey || event.metaKey) &&
              (event.key === "c" || event.key === "x")
            ) {
              InfoToaster("Keys cannot be copied!", "no-key-copy");
              return; // Disable copy-paste functionality
            }
          }}
          endAdornment={
            <IconButton
              onClick={() => toggleShow("publishable_key")}
              type="button"
              isDisabled={false}
              fontSize="14px"
            >
              {isPublishableKeyVisible ? (
                <VisibilityIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              )}
            </IconButton>
          }
        />
      </Container>

      <Container className="mb-3">
        <FormInputField
          label="Secret Key"
          value={values.api_secret || ""}
          onChange={handleChange}
          type={isSecretKeyVisible ? "text" : "password"}
          id="api_secret"
          name="selected_payment_gateway.api_secret"
          className="w-100"
          error={touched.api_secret && errors.api_secret}
          placeholder="Enter secret key"
          onKeyDown={(event) => {
            if (
              (event.ctrlKey || event.metaKey) &&
              (event.key === "c" || event.key === "x")
            ) {
              InfoToaster("Keys cannot be copied!", "no-key-copy");
              return; // Disable copy-paste functionality
            }
          }}
          endAdornment={
            <IconButton
              onClick={() => toggleShow("secret_key")}
              type="button"
              isDisabled={false}
              fontSize="14px"
              wrapperClass="h-100"
            >
              {isSecretKeyVisible ? (
                <VisibilityIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              )}
            </IconButton>
          }
        />
      </Container>
      <Container className="mb-3">
        <FormInputField
          label="Webhook Secret"
          value={values.webhook_secret || ""}
          onChange={handleChange}
          type={isWebhookSecretVisible ? "text" : "password"}
          id="webhook_secret"
          name="selected_payment_gateway.webhook_secret"
          className="w-100"
          placeholder="Enter webhook secret"
          error={touched.webhook_secret && errors.webhook_secret}
          onKeyDown={(event) => {
            if (
              (event.ctrlKey || event.metaKey) &&
              (event.key === "c" || event.key === "x")
            ) {
              InfoToaster("Keys cannot be copied!", "no-key-copy");
              return; // Disable copy-paste functionality
            }
          }}
          endAdornment={
            <IconButton
              onClick={() => toggleShow("webhook_secret")}
              type="button"
              isDisabled={false}
              fontSize="14px"
              wrapperClass="h-100"
            >
              {isWebhookSecretVisible ? (
                <VisibilityIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ fontSize: "20px", color: colors.battleshipGrey }}
                />
              )}
            </IconButton>
          }
        />
      </Container>
      <FormFieldContainer
        wrapperClass="mw-325 p-0"
        label={"Account"}
        isRequired={false}
        tooltipMessage="Select the same bank account with respective currency configured in Stripe to ensure accurate accounting of payments received "
      >
        <>
          <AccountSelect
            wrapperClass="w-100"
            onChange={(e) => {
              if (e) {
                setFieldValue(
                  "selected_payment_gateway.chart_of_account_id",
                  e?.id
                );
                setFieldValue(
                  "selected_payment_gateway.chart_of_account_name",
                  e?.account_name
                );
              }
            }}
            organizationId={currentUserInfo.organization_id}
            itemAccount={Number(values.chart_of_account_id)}
            itemAccountName={values.chart_of_account_name}
            itemIndex={0}
            baseAccount={"Bank Accounts"}
            error={
              touched.chart_of_account_name && errors.chart_of_account_name
            }
            className="full-width-dropdown"
          />
        </>
      </FormFieldContainer>
      <Container className="mt-3">
        <PrimaryButton
          type="button"
          isDisabled={false}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          {"Save"}
        </PrimaryButton>
        <SecondaryButton
          type="button"
          isDisabled={false}
          onClick={() => {
            setIsSetup(false);
          }}
          buttonContent="Cancel"
        />
      </Container>
    </Container>
  );
};
