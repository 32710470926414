import { Password } from "@mui/icons-material";
import * as yup from "yup";

export const smtpServerConfigSchema = yup.object().shape({
  smtp_server: yup
    .string()
    .test("smtp_server", "Please enter SMTP server address.", function (value) {
      const { is_active } = this.options.context as any;
      if (is_active && !value) {
        return false;
      }
      return true;
    })
    .nullable(),
  username: yup
    .string()
    .email("Please enter a valid username.")
    .when("is_active", {
      is: true,
      then: (schema) => schema.required("Please enter username."),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  password: yup
    .string()
    .when("is_active", {
      is: true,
      then: (schema) => schema.required("Please enter password."),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  encryption_type: yup
    .string()
    .test(
      "encryption_type",
      "Please choose encryption type.",
      function (value) {
        const { is_active } = this.options.context as any;
        if (is_active && !value) {
          return false;
        }
        return true;
      }
    ),
  port: yup
    .string()
    .test("port", "Please enter port number.", function (value) {
      const { is_active } = this.options.context as any;
      if (is_active && !value) {
        return false;
      }
      return true;
    })
    .nullable(),
});
