import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { useModuleCustomFieldList } from "../../../../../../hooks/useModuleCustomFieldList";
import "../../../../paymentsReceived.css";
import * as PRImports from "../../imports";
import { ReferenceNumber, FormInputFieldsInRows } from "../../imports";
import {
  PaymentTax,
  PaymentAmountDetails,
  PaymentCustomer,
  PaymentDate,
  PaymentModeDetailsForm,
  PaymentNumber,
  DepositAccount,
} from "./index";
import { CustomFieldInTransactions } from "../../../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";
type Props = {
  formik: FormikProps<any>;
  customFieldRef: any;
};
export const PaymentDetailsForm = (props: Props) => {
  const { editId } = useParams();
  const paymentsReceivedCustomFields =
    useModuleCustomFieldList("Payments Received");
  const getCustomFieldData = (customFieldData: PRImports.ObjectType) => {
    props.formik.setFieldValue("custom_fields", customFieldData.values);
  };
  return (
    <section className="form-top-section">
      <div className="row payment-details-form">
        <PaymentCustomer formik={props.formik} />
        <PaymentNumber formik={props.formik} />
        <PaymentModeDetailsForm
          formik={props.formik}
          PaymentDateField={<PaymentDate formik={props.formik} />}
        />
        <ReferenceNumber
          referenceNumber={props.formik.values.reference_number}
          handleChange={props.formik.handleChange}
          error={props.formik.errors.reference_number?.toString() || ""}
          wrapperClass={!props.formik.values.customer_id ? "disabled-item" : ""}
          isDisabled={!props.formik.values.customer_id}
        />
        <DepositAccount formik={props.formik} />
        <PaymentAmountDetails
          formik={props.formik}
          taxObject={<PaymentTax formik={props.formik} />}
        />
        <CustomFieldInTransactions
          getFieldValues={getCustomFieldData}
          customFieldsArray={paymentsReceivedCustomFields}
          initialValues={props.formik.values.custom_fields}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
          wrapperClass={!props.formik.values.customer_id ? "disabled-item" : ""}
          isDisabled={!props.formik.values.customer_id}
        />
      </div>
    </section>
  );
};
