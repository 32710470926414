import PurchaseOrderDetailsHeader from "./PurchaseOrderDetailsHeader";
import "./PurchaseOrderDetails.css";
function PurchaseOrderDetails() {
  return (
    <div className="">
      <div className="card card-dashboard bills-detail-card w-100" id="bills-detail-card">
        <PurchaseOrderDetailsHeader />
      </div>
    </div>
  );
}

export default PurchaseOrderDetails;
