import { FormikProps } from "formik";
import PaymentVendor from "./PaymentVendor";
import { PaymentNumber } from "./PaymentNumber";
import PaymentModeDetails from "./PaymentModeDetails";
import ReferenceNumber from "../../../../../../common/components/ReferenceInput";
import PaidThroughAccount from "./PaidThroughAccount";
import { PaymentAmountDetails } from "./PaymentAmountDetails";
import { FormInputFieldsInRows } from "../../../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { ObjectType } from "../../../../../../../types";
import { useModuleCustomFieldList } from "../../../../../../hooks/useModuleCustomFieldList";
import { useParams } from "react-router-dom";
import { CustomFieldInTransactions } from "../../../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";

type Props = {
  formik: FormikProps<any>;
  customFieldRef?: any;
};

const PaymentDetailsForm = (props: Props) => {
  const { editId } = useParams();

  const paymentsMadeCustomFields = useModuleCustomFieldList("Payments Made");
  const getCustomFieldData = (customFieldData: ObjectType) => {
    props.formik.setFieldValue("custom_fields", customFieldData.values);
  };
  return (
    <>
      <section className="form-top-section">
        <div className="payment-details-container p-0 m-0">
          <div className="row custom-row flex-wrap mb-0">
            <PaymentVendor formik={props.formik} />
            <PaymentNumber formik={props.formik} />

            <PaymentModeDetails formik={props.formik} />
            <ReferenceNumber
              referenceNumber={props.formik.values.reference_number}
              handleChange={props.formik.handleChange}
              error={
                props.formik.errors.reference_number
                  ? props.formik.errors.reference_number.toString()
                  : ""
              }
              isDisabled={!props.formik.values.vendor_id}
              wrapperClass={
                !props.formik.values.vendor_id ? "disabled-item" : ""
              }
            />
            <PaidThroughAccount formik={props.formik} />
            <PaymentAmountDetails formik={props.formik} paymentsMadeCustomFields={paymentsMadeCustomFields}/>
          </div>
        </div>
        <CustomFieldInTransactions
          getFieldValues={getCustomFieldData}
          customFieldsArray={paymentsMadeCustomFields}
          initialValues={props.formik.values.custom_fields}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
          isDisabled={!props.formik.values.vendor_id}
          wrapperClass={!props.formik.values.vendor_id ? "disabled-item" : ""}
        />
      </section>
    </>
  );
};
export default PaymentDetailsForm;
