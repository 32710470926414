import { useFormik } from "formik";
import { customSelectStyle } from "../../SelectCustomStyle";
import { FormSelectField } from "../../formSelectField/FormSelectField";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { DeleteIcon } from "../../customSvgIcons/deleteIcon";
import { colors } from "../../../../constants/colors";
import Multiselect from "multiselect-react-dropdown";
import { TagSelectedClose } from "../../../../../assets/images";
import { addFilterOptions, fieldOptionsForExport } from "../OptionConstants";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  getOrganizationListOfUser,
} from "../../../commonSlice";
import { fetchAccountsForFilter } from "../../../../home/chartOfAccounts/coaSlice";
import { reportTableFilterSelector } from "../../../../home/reports/ReportSlice";
import {
  customerDropdownList,
  customerListSelector,
} from "../../../../home/customerManagement/customerSlice";
import {
  subscriptionAddonDropdown,
  subscriptionPlanDropdown,
} from "../../../../home/subscription/subscriptionSlice";
import { addonList } from "../../../../home/addons/addonSlice";
import { couponList } from "../../../../home/coupon/couponSlice";
import { chargesList } from "../../../../home/charges/ChargesSlice";

type Props = {
  onDelete: (index: number) => void; // Accepts an index argument
  index: number; // Add an index prop
  value: {
    field: string;
    comparator: string;
    value: string | string[];
  };
  getFilterValues: (
    data: {
      field: string;
      comparator: string;
      value: string | string[];
    },
    index: number
  ) => void;
  selectedFields: string[];
  isError: boolean;
  deleteBtnClass?: string;
  moduleType?: string;
};
type FilterRef = {
  handleSubmit: () => Promise<void>;
  isError: boolean;
};
export const AddFilter = forwardRef(
  (props: Props, ref?: React.ForwardedRef<FilterRef>) => {
    const [fieldOptions, setFieldOptions] = useState<
      { label: string; value: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState<
      {
        value: string;
        label: string;
      }[]
    >([]);
    const dispatch = useAppDispatch();
    const currentUserInfo = useAppSelector(currentUserSelector);
    const tableFilters = useAppSelector(reportTableFilterSelector);
    const initialFilterValue = {
      field: "",
      comparator: "",
      value: "",
    };
    const formik = useFormik({
      initialValues: { ...initialFilterValue },
      enableReinitialize: true,
      onSubmit: (values) => {},
    });
    const moduleToCheck = [
      "Transaction Type",
      "Account",
      "Customer",
      "Plan",
      "Addons",
      "Coupons",
      "Charges",
      "Subscription Status",
      "Invoice Status",
      "Frequency",
    ];
    useEffect(() => {
      props.getFilterValues(formik.values, props.index);
    }, [formik.values]);
    useEffect(() => {
      if (
        ["Transaction Type", "Account"].includes(formik.values.field) &&
        selectedTags.length > 0
      ) {
        formik.setFieldValue(
          "value",
          selectedTags.map((tags) => {
            return tags.label;
          })
        );
      }
      if (
        [
          "Customer",
          "Plan",
          "Addons",
          "Coupons",
          "Charges",
          "Subscription Status",
          "Invoice Status",
          "Is Recurring",
          "Frequency",
        ].includes(formik.values.field) &&
        selectedTags.length > 0
      ) {
        formik.setFieldValue(
          "value",
          selectedTags.map((tags) => {
            return tags.value;
          })
        );
      }
    }, [selectedTags]);
    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;

      switch (formik.values.field) {
        case "Transaction Type":
          fetchFieldValueOptions("Transaction Type", signal);
          break;
        case "Account":
          fetchFieldValueOptions("Account", signal);
          break;
        case "Account Group":
          fetchFieldValueOptions("Account Group", signal);
          break;
        case "Customer":
          fetchFieldOptionsForExport("Customer");
          break;
        case "Plan":
          fetchFieldOptionsForExport("Plan");
          break;
        case "Addons":
          fetchFieldOptionsForExport("Addons");
          break;
        case "Coupons":
          fetchFieldOptionsForExport("Coupons");
          break;
        case "Charges":
          fetchFieldOptionsForExport("Charges");
          break;
        case "Subscription Status":
          setFieldOptions(fieldOptionsForExport.subscription_status);
          break;
        case "Invoice Status":
          setFieldOptions(fieldOptionsForExport.invoice_status);
          break;
        case "Is Recurring":
          setFieldOptions(fieldOptionsForExport.is_recurring);
          break;
        case "Organization":
          fetchFieldOptionsForExport("Organization");
          break;
        case "Frequency":
          setFieldOptions(fieldOptionsForExport.frequency);
          break;
        default:
          setFieldOptions([]);
          break;
      }
      return () => {
        controller.abort();
      };
    }, [formik.values.field]);
    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: async () => {
          await formik.submitForm();
        },
        isError: Object.keys(formik.errors).length > 0,
      }),
      [formik]
    );
    const options = {
      field:
        props.moduleType === "Subscription Export"
          ? addFilterOptions.subscriptionField
          : props.moduleType === "Invoice Export"
          ? addFilterOptions.invoiceField
          : addFilterOptions.field,
      comparator: addFilterOptions.comparator,
      field_option: fieldOptions,
    };

    const handleSelectTags = (e: any) => {
      setSelectedTags((values) => {
        return [...e];
      });
    };

    const handleRemoveTags = (e: any) => {
      setSelectedTags((values) => {
        return [...e];
      });
    };
    const fetchFieldValueOptions = async (
      field: string,
      signal: AbortSignal
    ) => {
      setIsLoading(true);
      const responseAction = await dispatch(
        fetchAccountsForFilter({
          orgId: tableFilters.organization_id
            ? tableFilters.organization_id
            : currentUserInfo.organization_id,
          field: field,
          signal: signal,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        setFieldOptions(
          response.map((option: string) => {
            return { value: option, label: option };
          })
        );
        setIsLoading(false);
      } else {
        setFieldOptions([]);
        setIsLoading(false);
      }
    };
    const fetchFieldOptionsForExport = async (field: string) => {
      if (field === "Customer") {
        const customerResponseAction = await dispatch(
          customerDropdownList(currentUserInfo.organization_id)
        );
        if (customerResponseAction.payload) {
          const response = customerResponseAction.payload;
          setFieldOptions(
            response?.map((customer: any) => ({
              label: customer.customer_display_name,
              value: customer.id,
            }))
          );
        }
      } else if (field === "Plan") {
        const planResponseAction = await dispatch(
          subscriptionPlanDropdown({
            page: 1,
            itemsPerPage: 350,
            orgId: currentUserInfo.organization_id,
            searchQuery:"",
            signal: undefined

          })
        );
        if (planResponseAction.payload) {
          const response = planResponseAction.payload;
          setFieldOptions(
            response.plans.map((plan: any) => ({
              label: plan.external_name,
              value: plan.id,
            }))
          );
        }
      } else if (field === "Addons") {
        const addonsResponseAction = await dispatch(
          addonList({
            page: 1,
            itemsPerPage: 350,
            orgId: currentUserInfo.organization_id,
            orgIds: [],
            statusList: [],
          })
        );
        if (addonsResponseAction.payload) {
          const response = addonsResponseAction.payload;
          setFieldOptions(
            response.addons.map((addon: any) => ({
              label: addon.external_name,
              value: addon.id,
            }))
          );
        }
      } else if (field === "Coupons") {
        const couponResponseAction = await dispatch(
          couponList({
            page: 1,
            itemsPerPage: 350,
            orgId: currentUserInfo.organization_id,
            orgIds: [],
            statusList: [],
          })
        );
        if (couponResponseAction.payload) {
          const response = couponResponseAction.payload;
          setFieldOptions(
            response.coupons.map((coupon: any) => ({
              label: coupon.name,
              value: coupon.id,
            }))
          );
        }
      } else if (field === "Charges") {
        const chargeResponseAction = await dispatch(
          chargesList({
            page: 1,
            itemsPerPage: 350,
            orgId: currentUserInfo.organization_id,
            orgIds: [],
            statusList: [],
          })
        );
        if (chargeResponseAction.payload) {
          const response = chargeResponseAction.payload;
          setFieldOptions(
            response.charges.map((charge: any) => ({
              label: charge.external_name,
              value: charge.id,
            }))
          );
        }
      } else if (field === "Organization") {
        const orgResponseAction = await dispatch(
          getOrganizationListOfUser(currentUserInfo.organization_id)
        );
        if (orgResponseAction.payload) {
          const response = orgResponseAction.payload;
          setFieldOptions(
            response.map((org: any) => ({
              label: org.name,
              value: org.id,
            }))
          );
        }
      }
    };
    const getSelectedOption = () => {
      if (
        [
          "Customer",
          "Plan",
          "Addons",
          "Coupons",
          "Charges",
          "Subscription Status",
          "Invoice Status",
          "Frequency",
        ].includes(formik.values.field) &&
        formik.values.value.length > 0
      ) {
        return Array.isArray(formik.values.value)
          ? formik.values.value.map((tags) => {
              const obj = fieldOptions.find((item) => item.value === tags);
              return {
                label: obj?.label,
                value: obj?.value,
              };
            })
          : [];
      } else {
        return Array.isArray(formik.values.value)
          ? formik.values.value.map((tags) => {
              return {
                label: tags,
                value: tags,
              };
            })
          : [];
      }
    };
    const mulitSelectPlaceholderHandler = () => {
      const dontNeedSuffix = ["Addons", "Coupons", "Charges", "Invoice Status", "Subscription Status"];
      return `Select ${
        formik.values.field !== ""
          ? dontNeedSuffix.includes(formik.values.field)
            ? formik.values.field.toLowerCase()
            : formik.values.field === "Frequency"
            ? "frequencies"
            : formik.values.field.toLowerCase() + "s"
          : "an option"
      }`;
    };

    return (
      <div
        className={`row position-relative add-filter-section ${
          props.isError ? "add-filter-error" : ""
        }`}
        key={props.index}
        tabIndex={props.index}
      >
        <FormSelectField
          name="field"
          id="field"
          className={`form-select customize-report-select ${
            props.isError && formik.values.field === ""
              ? "add-filter-error"
              : ""
          } `}
          isDisabled={isLoading}
          isOnlyInRow={false}
          styles={customSelectStyle}
          placeholder="Select a field"
          isRequired={false}
          isSearchable={true}
          error={formik.errors.field}
          label="Select Field"
          onChange={(e: any) => {
            formik.setValues({ field: e.label, comparator: "", value: "" });
          }}
          options={options.field.filter(
            (fields: { value: string; label: string }) =>
              !props?.selectedFields?.includes(fields.label)
          )}
          value={{
            label: formik.values.field,
            value: formik.values.field,
          }}
          wrapperClass={`${
            moduleToCheck.includes(formik.values.field)
              ? "transaction-type-select"
              : ""
          }`}
          isClearable={false}
        />
        {!["Is Recurring"].includes(formik.values.field) && (
          <FormSelectField
            name="comparator"
            id="comparator"
            className={`form-select customize-report-select ${
              props.isError && formik.values.comparator === ""
                ? "add-filter-error"
                : ""
            }`}
            isDisabled={false}
            isOnlyInRow={false}
            styles={customSelectStyle}
            placeholder="Select a comparator"
            isRequired={false}
            isSearchable={true}
            error={formik.errors.comparator}
            label="Select Comparator"
            onChange={(e: any) => formik.setFieldValue("comparator", e.label)}
            options={options.comparator}
            value={{
              label: formik.values.comparator,
              value: formik.values.comparator,
            }}
            wrapperClass={`${
              moduleToCheck.includes(formik.values.field)
                ? "transaction-type-select"
                : ""
            }`}
            isClearable={false}
          />
        )}
        {!moduleToCheck.includes(formik.values.field) ? (
          <FormSelectField
            name="value"
            id="value"
            className={`form-select customize-report-select ${
              props.isError && formik.values.field === ""
                ? "add-filter-error e"
                : ""
            }`}
            isDisabled={false}
            isOnlyInRow={false}
            styles={customSelectStyle}
            placeholder={`Select ${
              formik.values.field !== ""
                ? formik.values.field.toLowerCase()
                : "an option"
            }`}
            isRequired={false}
            isSearchable={true}
            error={formik.errors.value}
            label="Additional Field"
            onChange={(e: any) => formik.setFieldValue("value", [e.label])}
            options={options.field_option}
            value={{
              label: formik.values.value[0],
              value: formik.values.value[0],
            }}
            isClearable={false}
            isLoading={isLoading}
          />
        ) : (
          <div className="form-outline trans-type-item mb-4 tag-wrapper position-relative">
            <span className="align-wrapper">
              <label className="form-label" htmlFor="tags">
                Additional Field
              </label>
            </span>
            <Multiselect
              options={options.field_option}
              id="value_transaction"
              displayValue="label"
              showCheckbox={true}
              loading={isLoading}
              emptyRecordMsg={"No Matches found"}
              className={`${
                props.isError && formik.values.value === ""
                  ? "add-filter-error"
                  : ""
              }`}
              customCloseIcon={
                <img src={TagSelectedClose} alt="tag-selected-close-img" />
              }
              placeholder={mulitSelectPlaceholderHandler()}
              onSelect={handleSelectTags}
              onRemove={handleRemoveTags}
              selectedValues={getSelectedOption()}
            />
          </div>
        )}

        <button
          className={`no-appearance delete-button position-absolute ${props.deleteBtnClass}`}
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            props.onDelete(props.index);
          }}
        >
          <DeleteIcon color={colors.tomato} />
        </button>
      </div>
    );
  }
);
