import React from "react";
import { AccountsImg, SalesDefault } from "../../../../../assets/images";
import { ObjectType } from "../../../../../types";

type Props = {
  title: string;
  data: ObjectType[];
  imgSrc: string;
};
function OutstandingAmount({ data, title, imgSrc }: Props) {
  return (
    <div className="overview-wrapper tab-content-wrapper">
      <section className="section-blocks other-dets-section">
        <h2>
          <img src={imgSrc} width="24" height="24" alt="Other details" />
          {title}
        </h2>
        <div className="other-wrapper section-content-wrapper">
          <div className="row g-0">
            <div className="col">
              {data.map((item, key) => {
                return (
                  <div className="field-row">
                    <div className="field-title">
                      <span>{item.label}</span>
                    </div>
                    <div className={`field-value ${item.className}`}>
                      <span>{item.value || "-"}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OutstandingAmount;
