import { FormikProps } from "formik";
import { getFormatedDate } from "../../../../../helpers/helper";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import {
  CurrencyList,
  JVItemValues,
  JournalItemValues,
} from "../../../../../types";
import {
  createExchangeRateList,
  getCustomConversions,
  getLatestCurrencyRate,
  setExchangeApiSatus,
} from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";

export const useJVExchange = () => {
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  /**
   * Fetch convesion details by conversion id
   */
  const fetchCustomConversions = async (
    id: number,
    setExchangeData: React.SetStateAction<any>,
    formik?: FormikProps<any>,
    type?: string,
    index?: number
  ) => {
    if (id) {
      dispatch(setExchangeApiSatus(true));
      const customConversionResponse = await dispatch(
        getCustomConversions({
          conversionId: id,
        })
      );
      const response = customConversionResponse.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setExchangeData({ ...response.latest_conversion });
        dispatch(setExchangeApiSatus(false));
        if (type && type === "jv_currency" && formik) {
          setUserExchangeData(response.latest_conversion, formik);
        } else if (
          type &&
          type === "jv_item" &&
          formik &&
          typeof index === "number"
        ) {
          setItemExchangeData(response.latest_conversion, formik, index);
        }
        // return response.latest_conversion
      }
    }
  };
  const userExchangeRateSubmitHandler = async (
    data: any,
    setExchangeData: React.SetStateAction<any>,
    formik?: FormikProps<any>,
    type?: string,
    index?: number
  ) => {
    dispatch(setExchangeApiSatus(true));
    const createResponseAction = await dispatch(
      createExchangeRateList({ values: {...data, by_user: true, timestamp: getFormatedDate()} })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      SuccessToaster(
        "Exchange rate updated successfully!",
        "rate-create-success"
      );

      if (type && type === "jv_currency") {
        if (formik) {
          fetchCustomConversions(
            createResponse.id,
            setExchangeData,
            formik,
            type
          );

          // setUserExchangeData(createResponse, formik);
          changeItemListSameExchangeUpdate(
            formik,
            currentUserInfo.organization_currency,
            createResponse.id,
            type
          );
        }
      } else if (type && type === "jv_item") {
        if (formik) {
          fetchCustomConversions(
            createResponse.id,
            setExchangeData,
            formik,
            "jv_item",
            index
          );
          changeItemListSameExchangeUpdate(
            formik,
            formik.values.item_details[Number(index)].currency_id,
            createResponse.id,
            type,
            index
          );
          if (
            Number(currentUserInfo.organization_currency) ===
            Number(formik.values.item_details[Number(index)].currency_id)
          ) {
            fetchCustomConversions(
              createResponse.id,
              setExchangeData,
              formik,
              "jv_currency"
            );
          }
        }
      }
      return createResponse;
    }
  };
  const currencyDateChangeHandler = async (
    currencyId: number | null,
    toCurrencyId: number | null | undefined,
    dateFormated: string,
    setExchangeData: React.SetStateAction<any>,
    formik?: FormikProps<any>
  ) => {
    const responseAction = await dispatch(
      getLatestCurrencyRate({
        base_currency: Number(currencyId),
        to_currency: Number(toCurrencyId),
        date:
          new Date(dateFormated) > new Date()
            ? getFormatedDate()
            : dateFormated,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setExchangeData(response.latest_conversion);
    }
    return response.latest_conversion;
  };
  const currencyChangeHandler = async (
    currencyId: number | null,
    toCurrencyId: number | null | undefined,
    setExchangeData: React.SetStateAction<any>,
    formik: FormikProps<any>,
    type: string,
    index: number
  ) => {
    const responseAction = await dispatch(
      getLatestCurrencyRate({
        base_currency: Number(currencyId),
        to_currency: Number(toCurrencyId),
        date:
          new Date(formik?.values.date) > new Date()
            ? getFormatedDate()
            : formik?.values.date,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setExchangeData(response.latest_conversion);
      if (type === "jv_currency") {
        setUserExchangeData(response.latest_conversion, formik);
        changeItemListExchanges(
          formik,
          formik?.values.date,
          Number(currencyId)
        );
      } else if (type === "jv_item") {
        const conversionId = checkForSameCurrency(
          Number(toCurrencyId),
          formik,
          index
        );
        if (conversionId) {
          fetchCustomConversions(
            conversionId,
            setExchangeData,
            formik,
            "jv_item",
            index
          );
        } else {
          setItemExchangeData(response.latest_conversion, formik, index);
        }
      }
    }
    return response.latest_conversion;
  };
  const checkForSameCurrency = (
    currencyId: number,
    formik: FormikProps<any>,
    indice: number,
  ) => {
    let match: any = {};
    if (Number(currencyId) === Number(currentUserInfo.organization_currency)) {
      match = { conversion_id: formik.values.conversion_id };
    } else if (Array.isArray(formik.values.item_details)) {
      match = (formik.values.item_details as Array<JVItemValues>).filter(
        (item: JVItemValues, index) =>
          Number(item.currency_id) === Number(currencyId) &&
          Number(index) !== Number(indice)
      )[0];
    }

    return match?.conversion_id || null;
  };
  const setUserExchangeData = (
    values: CurrencyList,
    formik: FormikProps<any>
  ) => {
    formik.setFieldValue("conversion_id", values.id);
    formik.setFieldValue("conversion_rate", values.rate);
    formik.setFieldValue("conversion_timestamp", values.timestamp);
    formik.setFieldValue("by_user", values.by_user);
    formik.setFieldValue("is_date_changed", false);
  };

  const setItemExchangeData = (
    values: CurrencyList,
    formik: FormikProps<any>,
    index: number
  ) => {
    formik.setFieldValue(`item_details.${index}.conversion_rate`, values.rate);
    formik.setFieldValue(`item_details.${index}.conversion_id`, values.id);
    formik.setFieldValue(
      `item_details.${index}.conversion_timestamp`,
      values.timestamp
    );
    formik.setFieldValue(`item_details.${index}.by_user`, values.by_user);
  };
  const changeItemListExchanges = async (
    formik: FormikProps<any>,
    dateFormated: string,
    currency_id: number
  ) => {
    if (
      Array.isArray(formik.values.item_details) &&
      formik.values.item_details.length &&
      formik.values.currency_id
    ) {
      formik.values.item_details.map(
        async (item: JournalItemValues, index: number) => {
          if (item.currency_id) {
            const responseAction = await dispatch(
              getLatestCurrencyRate({
                base_currency: Number(currency_id),
                to_currency: Number(item.currency_id),
                date:
                  new Date(dateFormated) > new Date()
                    ? getFormatedDate()
                    : dateFormated,
              })
            );
            const conversion = responseAction.payload;
            if (!("error" in conversion)) {
              setItemExchangeData(conversion.latest_conversion, formik, index);
            }
          }
        }
      );
    }
  };
  const changeItemListCustomExchanges = async (formik: FormikProps<any>) => {
    if (
      Array.isArray(formik.values.item_details) &&
      formik.values.item_details.length
    ) {
      formik.values.item_details.map(
        async (item: JournalItemValues, index: number) => {
          if (item.currency_id && item.conversion_id) {
            const customConversionResponse = await dispatch(
              getCustomConversions({
                conversionId: item.conversion_id,
              })
            );
            const conversion = customConversionResponse.payload;
            if (!("error" in conversion)) {
              setItemExchangeData(conversion.latest_conversion, formik, index);
            }
          }
        }
      );
    }
  };
  const changeItemListSameExchangeUpdate = async (
    formik: FormikProps<any>,
    currencyToCheck: number,
    conversionId: number,
    type: string,
    indice?: number
  ) => {
    if (
      Array.isArray(formik.values.item_details) &&
      formik.values.item_details.length &&
      formik.values.currency_id
    ) {
      formik.values.item_details.map(
        async (item: JournalItemValues, index: number) => {
          if (
            item.currency_id &&
            Number(item.currency_id) === Number(currencyToCheck) &&
            ((type === "jv_item" &&
              typeof index === "number" &&
              Number(index) !== Number(indice)) ||
              type === "jv_currency")
          ) {
            const customConversionResponse = await dispatch(
              getCustomConversions({
                conversionId: conversionId,
              })
            );
            const conversion = customConversionResponse.payload;
            if (!("error" in conversion)) {
              setItemExchangeData(conversion.latest_conversion, formik, index);
            }
          }
        }
      );
    }
  };

  const dateChangeHandler = async (
    dateFormated: string,
    formik: FormikProps<any>
  ) => {
    formik.setFieldValue("date", dateFormated);
    // updating user to org conversion rate
    dispatch(setExchangeApiSatus(true));
    if (formik.values.currency_id) {
      const responseAction = await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(formik.values.currency_id),
          to_currency: Number(currentUserInfo.organization_currency),
          date:
            new Date(dateFormated) > new Date()
              ? getFormatedDate()
              : dateFormated,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        await changeItemListExchanges(
          formik,
          dateFormated,
          formik.values.currency_id
        );
        setUserExchangeData(response.latest_conversion, formik);
      }
    }
  };
  return {
    currencyDateChangeHandler,
    userExchangeRateSubmitHandler,
    fetchCustomConversions,
    dateChangeHandler,
    currencyChangeHandler,
    setUserExchangeData,
    changeItemListCustomExchanges,
  };
};
