import { useEffect, useState } from "react";
import Select from "react-select";
import { customSelectStyle } from "../../../../../../common/components/SelectCustomStyle";
import { colors } from "../../../../../../constants/colors";
import "./OrganizationDropdownReport.css";
import useCommonData from "../../../../../../hooks/useCommon";
import { getOrganizationListForReport } from "../../../../../../common/commonSlice";
import { Container } from "../../../../../../common/components/htmlTags/Container";
import { OrganizationDropdownIcon } from "../../../../../../common/components";
import { ReportingTagCloneIcon } from "../../../../../../common/components/customSvgIcons/ReportingTagClone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Span } from "../../../../../../common/components/htmlTags/Span";
interface Organization {
  id: number;
  name: string;
  currency_id: number;
  parent: string;
}

interface OrganizationDropDownProps {
  selectedOrg: number;
  handleOrgChange: (
    { value, label }: { value: number; label: string },
    currencyId: number
  ) => void;
  error?: string;
  orgDisable?: boolean;
}
interface OptionType {
  label: string;
  value: number;
  description: string;
  currencyId: number;
}

export const OrganizationDropDown = ({
  selectedOrg,
  handleOrgChange,
  error,
  orgDisable,
}: OrganizationDropDownProps) => {
  const [loading, setLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { currentUserInfo, dispatch } = useCommonData();
  const [organizationOptions, setOrganizationOptions] = useState<OptionType[]>(
    []
  );

  useEffect(() => {
    getOrganization();
  }, [currentUserInfo.organization_id]);

  const getOrganization = async () => {
    setLoading(true);
    const responseAction = await dispatch(
      getOrganizationListForReport({ orgId: currentUserInfo.organization_id })
    );
    const response = responseAction.payload;
    if (response && Array.isArray(response)) {
      const options = response.map((org: Organization) => ({
        label: org.name,
        value: org.id,
        description: org.parent,
        currencyId: org.currency_id,
      }));
      setOrganizationOptions(options);
    }
    setLoading(false);
  };
  const CustomInput = (props: any) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReportingTagCloneIcon height="10px" width="10px" />
      <span>{props.value}</span>
    </div>
  );
  const LabelWithRoot = ({ data }: any) => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleOrgChange(
          { value: data.value, label: data.label },
          data.currencyId
        );
        setMenuIsOpen(false);
      }}
      className="custom-select-org-option-wrapper"
      style={{
        backgroundColor:
          data.value === selectedOrg ? colors.newAliceBlue : "white",
      }}
      key={data.value}
    >
      <div className="custom-select-org-option-org-name-wrapper">
        <div className="custom-select-org-option-org-name-wrapper-inner">
          <span className="custom-select-org-option-org-name">
            {data.label}
          </span>
          {data.description && (
            <div className="custom-select-org-option-org-root">
              {data.description}
            </div>
          )}
        </div>
        {data.value === selectedOrg && (
          <CheckCircleIcon
            sx={{ color: colors.ceruleanBlue, fontSize: "18px" }}
          />
        )}
      </div>
    </div>
  );

  const selectedOption = organizationOptions.find(
    (option) => option.value === selectedOrg
  );

  return (
    <Container className="org-dropdown-container">
      <Select
        name="org_dropdown"
        isDisabled={orgDisable}
        value={selectedOption || null} // Pass selectedOption object
        options={organizationOptions}
        placeholder="Choose Organization"
        isLoading={loading}
        onChange={(option) => {
          if (option)
            handleOrgChange(
              { value: option.value, label: option.label },
              option.currencyId
            );
        }}
        isSearchable={false}
        styles={{
          ...customSelectStyle,
          menuList: (styles) => ({ ...styles, padding: 0 }),
          menu: (styles) => ({ ...styles, padding: 0 }),
        }}
        components={{ Option: LabelWithRoot }}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        classNamePrefix="react-select"
      />
       {error && <Span className="error">{error}</Span>}
    </Container>
  );
};
