import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InvoiceImage } from "../../../../../../assets/images";
import {
  DateFormatHandler,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  invoiceColumnValues,
  invoiceDefaultValues,
  invoiceMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { InvoiceList } from "../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";
import EmptyList from "../../../../../common/components/EmptyList";
import Popover from "../../../../../common/components/Popover";
import Pagination from "../../../../../common/components/table/Pagination";
import Loader from "../../../../components/Loader";
import { invoiceList } from "../../../../invoice/invoiceSlice";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
type Props = {
  isSameOrg: boolean;
};
function InvoiceTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [invoiceData, setInvoiceData] = useState<InvoiceList[]>([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "actions",
    "Terms",
  ]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const customerId = editId ? editId : "";

  let invoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Invoices")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchInvoiceList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalInvoices / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalInvoices, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    invoiceColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [invoiceData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Invoices",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = invoiceDefaultValues;
      }
      invoiceMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch all invoices
   */
  const fetchInvoiceList = async () => {
    setIsLoading(true);
    if (customerId) {
      const responseAction = await dispatch(
        invoiceList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          orgIds: [],
          statusList: [],
          customerList: [Number(customerId)],
          invoiceType: [],
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);

        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setInvoiceData(response.invoices);
          let length = response.invoices.length;
          setTotalInvoices(response.total);
          if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Invoice Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Invoice Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Invoice Number",
        accessor: "show.invoiceId",
        sort: false,
        sortOrder: "",
        className: "inv_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "inv_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Due Date",
        accessor: selectedColumns.includes("Due Date")
          ? "show.dueDate"
          : "Due Date",
        sort: false,
        sortOrder: "",
        className: "due_date",
      },
      {
        Header: "Total",
        accessor: "show.invoiceAmount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Balance Due",
        accessor: "show.balanceDue",
        sort: false,
        sortOrder: "",
        className: "balance_due",
      },
      {
        Header: "Billing Address",
        accessor: selectedColumns.includes("Billing Address")
          ? "show.billingAddress"
          : "Billing Address",
        sort: false,
        sortOrder: "",
        className: "billing_addr",
      },
      {
        Header: "Tags",
        accessor: selectedColumns.includes("Tags") ? "show.tags" : "Tags",
        sort: false,
        sortOrder: "",
        className: "tags",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? (
            <div className="pills-wrapper">
              <Popover values={value} tag={true} />
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: "Created By",
        accessor: selectedColumns.includes("Created By")
          ? "show.createdBy"
          : "Created By",
        sort: false,
        sortOrder: "",
        className: "created_by",
      },
      {
        Header: "Due Days",
        accessor: selectedColumns.includes("Due Days")
          ? "show.dueDays"
          : "Due Days",
        sort: false,
        sortOrder: "",
        className: "due_days",
      },
      {
        Header: "Email",
        accessor: selectedColumns.includes("Email") ? "show.email" : "Email",
        sort: false,
        sortOrder: "",
        className: "email",
      },
      {
        Header: "Shipping Address",
        accessor: selectedColumns.includes("Shipping Address")
          ? "show.shippingAddress"
          : "Shipping Address",
        sort: false,
        sortOrder: "",
        className: "shipping_addr",
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      },
      {
        Header: "actions",
        accessor: "actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, selectedColumns, hiddenColumns]
  );
  /**
   * set table row data
   */
  const data = invoiceData
    ? invoiceData.map((invoice) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            invoice.invoice_date
          ),
          invoiceId: joinTransactionNumber(invoice.Invoice_ID),
          referenceNumber: invoice.reference_number,
          status: invoice.invoice_status,
          dueDate: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            invoice.due_date
          ),
          invoiceAmount:
            invoice.currency_code +
            " " +
            NumberFormat(invoice.total, invoice.currency_code, orgCurrencyList),
          balanceDue: invoice.balance_due
            ? invoice.currency_code +
              " " +
              NumberFormat(
                invoice.balance_due,
                invoice.currency_code,
                orgCurrencyList
              )
            : invoice.currency_code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  invoice.currency_code
                    ? invoice.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          tags: invoice.tags,
          billingAddress: invoice.billing_address,
          createdBy: invoice.created_by,
          dueDays: invoice.due_days,
          email: invoice.email_to
            .map((emailData) => emailData.email)
            .join(", "),
          shippingAddress: invoice.shipping_address,
          totalTaxableAmount:
            invoice.currency_code +
            " " +
            NumberFormat(
              invoice.total_taxable_amount,
              invoice.currency_code,
              orgCurrencyList
            ),
          tds:
            invoice.currency_code +
            " " +
            NumberFormat(
              invoice.tax_deducted,
              invoice.currency_code,
              orgCurrencyList
            ),
        },
        actions: {
          id: invoice.id,
          status: invoice.invoice_status,
          listItemId: invoice.Invoice_ID,
          emailTo: invoice.email_to,
          isMutable: invoice.is_mutable,
          redirectTo: `/customers/detail/${editId}`,
          consumerId: editId,
        },
      }))
    : [];
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalInvoices;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped w-100"
        columns={columns}
        data={data}
        url={"/invoice"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={invoicesRolePermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            invoicesRolePermission.includes("Create")
              ? "Create new invoice!"
              : "No Invoices found!"
          }
          subheading={
            invoicesRolePermission.includes("Create")
              ? "Get paid faster by creating invoices."
              : "Oops, There is no Invoice data available right now"
          }
          list="Invoice"
          alt="create invoice preview"
          buttonText="Create Invoice"
          navigateLink={`/invoice/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/customers/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            invoicesRolePermission.includes("Create") && props.isSameOrg
          }
          icon={InvoiceImage}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}
      <div className="table-footer pagination-container w-100 clear-both">
        {totalInvoices > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default InvoiceTransactions;
