import { FormikProps } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { joinForPayload } from "../../../../../../../helpers/prefixHelper";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../../../common/commonSlice";
import { PrefixInput } from "../../../../../paymentsReceived/components/paymentReceivedForm/imports";
import {
  checkSystemVoucherNumber,
  getNewSystemVoucherNumber,
} from "../../../../systemVoucherSlice";

type Props = {
  formik: FormikProps<any>;
};
export const SystemVoucherNumber = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { editId } = useParams();
  let interval: any = null;
  // useEffect(() => {
  //   if (!editId) paymentNumber();
  // }, [currentUserInfo.organization_id]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let paymentNumberSplit =
      props.formik.values.journal_voucher_number?.split("~");
    checkPaymentNumberExist(
      joinForPayload({
        journal_voucher_prefix: paymentNumberSplit[0],
        journal_voucher_number: paymentNumberSplit[1],
      }),
      signal
    );
    return () => {
      controller.abort();
    };
  }, [props.formik.values.journal_voucher_number]);

  const paymentNumber = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getNewSystemVoucherNumber({ orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let PR_number = joinForPayload(response);
        props.formik.setFieldValue("journal_voucher_number", PR_number);
      }
    }
    dispatch(setLoaderState(false));
  };
  /**
   * Check payment number already exist
   */
  const checkPaymentNumberExist = async (
    paymentNumber: string,
    signal: AbortSignal
  ) => {
    if (!paymentNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkSystemVoucherNumber({
            systemVoucherNumber: paymentNumber,
            orgId: currentUserInfo.organization_id,
            systemVoucherId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (response) {
            if (response.journal_voucher_number_available === true) {
              props.formik.setFieldError("journal_voucher_number", undefined);
            } else {
              props.formik.setFieldError(
                "journal_voucher_number",
                "System voucher number already exists. Please choose a different one"
              );
            }
          }
        }
      }, 1000);
    }
  };
  // To get prefix from PrefixInput component.
  const getPrefix = (value: string) => {
    props.formik.setFieldTouched("journal_voucher_number", true);
    let paymentNumberSplit =
      props.formik.values.journal_voucher_number?.split("~");
    props.formik.setFieldValue(
      "journal_voucher_number",
      joinForPayload({
        journal_voucher_prefix: value,
        journal_voucher_number: paymentNumberSplit[1],
      })
    );
  };
  return (
    <div className="col-12 col-lg-4 mw-325">
      <label htmlFor="journal_voucher_number" className="required">
        System Voucher Number
      </label>
      <br />
      <PrefixInput
        prefix={props.formik.values.journal_voucher_number?.split("~")[0] || ""}
        name="journal_voucher_number"
        id="journal_voucher_number"
        className="form-control border-end-0"
        placeholder="Enter journal number"
        value={props.formik.values.journal_voucher_number?.split("~")[1] || ""}
        disabled={false}
        error={
          ((props.formik.touched.journal_voucher_number ||
            props.formik.submitCount > 0) &&
            props.formik.errors.journal_voucher_number?.toString()) ||
          ""
        }
        maxLength={12}
        getPrefix={getPrefix}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.formik.setFieldTouched("journal_voucher_number", true);
          let paymentNumberSplit =
            props.formik.values.journal_voucher_number?.split("~");
          props.formik.setFieldValue(
            "journal_voucher_number",
            joinForPayload({
              journal_voucher_prefix: paymentNumberSplit[0],
              journal_voucher_number: e.target.value,
            })
          );
        }}
      />
      <span className="error">
        {props.formik.touched.journal_voucher_number &&
          props.formik.errors.journal_voucher_number?.toString()}
      </span>
    </div>
  );
};
