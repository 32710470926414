import { createAsyncThunk } from "@reduxjs/toolkit";
import { paymentGatewayConfigCreate, paymentGatewayConfigFetch, paymentGatewayConfigUpdate } from "./PaymentGatewayApi";
import { PaymentGatewayConfigUpdateValues } from "./types";

export const getPaymentGatewayConfig = createAsyncThunk(
    "paymentGatewayConfigFetch",
    async (data: {
      orgId: number;
      values: {
        skip: number;
        limit: number;
      }
    }) => {
      return await paymentGatewayConfigFetch(data);
    }
  );
export const createPaymentGatewayConfig = createAsyncThunk(
    "createPaymentGatewayConfig",
    async (data: {
      orgId: number;
      values: {
        chart_of_account_id: number | null;
        api_key: string;
        api_secret: string;
        webhook_secret: string;
        chart_of_account_name: string;
        gateway_type: string;
        is_default: boolean;
        metadata: {
            [key: string]: string;
        };
        mapping_metadata: {
            [key: string]: string;
        };
      };
    }) => {
      return await paymentGatewayConfigCreate(data);
    }
  );
export const updatePaymentGatewayConfig = createAsyncThunk(
  "updatePaymentGatewayConfig",
  async (data: {
    orgId: number;
    values: PaymentGatewayConfigUpdateValues;
  }) => {
    return await paymentGatewayConfigUpdate(data);
  }
);
