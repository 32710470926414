import { Link, useParams } from "react-router-dom";
import Header from "../../../../../common/components/detailsPage/Header";
import useCommonData from "../../../../../hooks/useCommon";
import { AddNewSquarePlusIcon, PlusIcon } from "../../../../../common/components";
import "./applytags.css";
import { TagsCategorySelectionPopup } from "../../../../../common/components/reportingTagInTransactions/tagSelectionPopups/TagCategorySelectionPopup";
import SingleTag from "../../../../../common/components/ReportingTags/SingleTag/SingleTag";
import useReportingTags from "../hooks/useReportingTags";
import { ObjectType } from "../../../../../../types";
import { useMemo, useState } from "react";
import { INITIAL_SPLIT_TYPE_OPTION } from "../../../../../constants/constants";
import EmptySearchList from "../../../../../common/components/emptySearchList/EmptySearchList";
import { InvoiceImage } from "../../../../../../assets/images";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { DockedButtonGroup } from "../../../../../common/components/button/DockedButtonGroup";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { Span } from "../../../../../common/components/htmlTags/Span";
import { colors } from "../../../../../constants/colors";

const ApplyTags = () => {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();

  const { formik, allTags, tagOptionsForBills, currencyCode } =
    useReportingTags();

  const getSelectedIDs = (data: ObjectType) => {
    let selectedTag = allTags.find((tag) => {
      return tag.id === data.tagId;
    });
    let option_list = selectedTag?.options
      .map((option: ObjectType) => {
        if (data.optionIds.includes(option.id)) {
          return {
            option_id: option.id,
            option_name: option.name,
            amount: 0,
          };
        }
      })
      .filter((option: ObjectType) => option !== undefined);

    const reporting_tag_list = formik.values.reporting_tag_list;
    reporting_tag_list.push({
      split_type: INITIAL_SPLIT_TYPE_OPTION.value,
      tag_amount: 0,
      reporting_tag_name: selectedTag?.name,
      reporting_tag_id: selectedTag?.id,
      option_list: option_list,
    });
    formik.setValues((values: any) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
  };

  const selectedIds = formik.values.reporting_tag_list.map((tag: any) => {
    return tag.reporting_tag_id;
  });

  return (
    <Container
      className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 reportingtag-jv docked-footer-page applytags"
      id="invoice-reportingtag"
    >
      <Header
        onBackButtonClick={() => {
          navigate(`/payment-made/edit/${editId}/reporting-tags`, {
            replace: true,
          });
        }}
        data={{
          num: "Reporting tags",
          status: "",
          tag: (
            <>
              <span className="header-accountName">
                {formik.values.account_name}
              </span>
              <span className="header-amount">
                {" "}
                ({formik.values.transaction_type}: {currencyCode}{" "}
                {NumberFormat(
                  Number(formik.values.transaction_amount),
                  currencyCode,
                  orgCurrencyList
                )}
                )
              </span>
            </>
          ),
        }}
        dataId={editId}
        rightElements={
          <>
            <TagsCategorySelectionPopup
              module="Payment Made"
              getSelectedIds={getSelectedIDs}
              selectedIds={selectedIds}
              selectedParentTagIds={selectedIds}
              isAddNewTag={true}
              isTagFilteringPresent={true}
              tagFilterObject={tagOptionsForBills}
              buttonContent={
                <>
                  <Link
                    className={"dropdown-item add-link-option flex"}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <AddNewSquarePlusIcon />
                    <span className="newtag-btn-text">Add Tags</span>
                  </Link>
                </>
              }
            />
          </>
        }
      />
      <Container className="card-body">
        <Container className="table-section">
          <Container
            className={`table-wrap ${
              formik.values.reporting_tag_list.length === 0
                ? "content-screen-center"
                : ""
            }`}
          >
            {formik.values.reporting_tag_list?.map(
              (tag: any, index: number) => {
                return (
                  <SingleTag
                    key={tag.reporting_tag_id}
                    tag={tag}
                    allTags={allTags}
                    values={formik.values}
                    setValues={formik.setValues}
                    errors={formik.errors}
                    index={index}
                    currencyCode={currencyCode}
                    touched={formik.touched}
                    setFieldTouched={formik.setFieldTouched}
                    isTagFilteringPresent={true}
                    tagFilterObject={tagOptionsForBills}
                    />
                );
              }
            )}
             {formik.values.reporting_tag_list.length === 0 ? (
              <Container className="d-flex flex-column align-items-center justify-content-center">
                <EmptySearchList
                  alt="Empty account list"
                  icon={InvoiceImage}
                  emptyText="No reporting tags associated"
                />
               <TagsCategorySelectionPopup
               module="Payment Made"
              getSelectedIds={getSelectedIDs}
              selectedIds={selectedIds}
              selectedParentTagIds={selectedIds}
              isAddNewTag={true}
              isTagFilteringPresent={true}
              tagFilterObject={tagOptionsForBills}
                  buttonContent={<Span><PlusIcon color={colors.white} margin="0 5px 0 0" />Add Tags</Span>}
                  buttonClass="primary-btn mt-3"
                />
              </Container>
            ) : null}
          </Container>
        </Container>
      </Container>
      <DockedButtonGroup
        onSave={() => {
          formik.handleSubmit();
        }}
        onCancel={() => {
          if (!formik.isSubmitting) {
            navigate(-1);
          }
        }}
        onDelete={() => {}}
        isSaveDisabled={formik.isSubmitting}
        isDeleteButtonDisabled={false}
        buttonText="Save"
        saveButtonType="submit"
        isDeleteButtonEnabled={false}
        isEdit={false}
        isModal={false}
        shouldDockToBottom={true}
      />
    </Container>
  );
};

export default ApplyTags;
