import "./AddressCard.css";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { editBillingAddress } from "./addressTypeSlice";
import { AddressValues } from "../../../../../../types";
import { DeleteImg } from "../../../../../../assets/images";
import {
  addressSelector,
  deleteAddress,
  setAsPrimaryBillingAddress,
} from "./addressSlice";
import { useEffect, useRef } from "react";
type Props = {
  OpenAddressModal: () => void;
  billingAddress: AddressValues;
  setAddressIdHandler: (id: string) => void;
  addressId: string;
  shouldScrollTo: boolean;
};
const BillingAddressCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const addressList = useAppSelector(addressSelector);
  const ref: any = useRef();

  useEffect(() => {
    props.shouldScrollTo &&
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }, []);

  const selectAddressHandler = (addressId: string) => {
    dispatch(setAsPrimaryBillingAddress({ id: addressId }));
  };

  const editAddressHandler = () => {
    dispatch(editBillingAddress("edit_billing"));
  };

  const removeAddressHandler = (id: string, isPrimary: boolean) => {
    dispatch(deleteAddress({ id: id }));
    /**
     * Setting first index address as primary if the deleted address is default
     */
    if (isPrimary) {
      const billingAddressList = addressList
        .filter((item) => item.is_billing)
        .map((address) => {
          return address;
        });
      if (billingAddressList.length > 1) {
        const updatedBillingAddressList = billingAddressList
          .filter((item) => item.id !== "" && item.id !== id)
          .map((address) => {
            return address;
          });
        selectAddressHandler(updatedBillingAddressList[0].id);
      }
    }
  };

  return (
    <div ref={ref} className="address-box-wrapper">
      <div
        className={`boxed-item address-item card ${
          props.billingAddress.is_primary ? "selected" : ""
        }`}
      >
        <div
          className="card-header"
          onMouseDownCapture={() => selectAddressHandler(props.addressId)}
        >
          <h5>Default Address</h5>
          <div className="radio-wrapper form-check">
            <input
              type="radio"
              className="form-check-input option_address_selected"
              name="billing"
              onChange={() => {}}
              value=""
              checked={props.billingAddress.is_primary}
            />
          </div>
        </div>
        <div
          className="card-body"
          onMouseDownCapture={() => selectAddressHandler(props.addressId)}
        >
          <p>
            <b>{props?.billingAddress?.customer_name || ""}</b>
          </p>
          <p>
            {props.billingAddress.address}
            {props.billingAddress.address &&
            (props.billingAddress.city ||
              props.billingAddress.country_id ||
              props.billingAddress.zip_code)
              ? ", "
              : ""}
            {props.billingAddress.city}
            {props.billingAddress.city &&
            (props.billingAddress.country_id || props.billingAddress.zip_code)
              ? ", "
              : ""}
            {props.billingAddress.state_name}
            {props.billingAddress.state_id &&
            (props.billingAddress.zip_code || props.billingAddress.country_id)
              ? ", "
              : ""}
            {props.billingAddress.zip_code
              ? "Pin " + props.billingAddress.zip_code
              : ""}
            {props.billingAddress.zip_code && props.billingAddress.country_id
              ? ", "
              : ""}
            {props.billingAddress.country_name}
          </p>
          <p className="contact-dets">
            {" "}
            {props.billingAddress.phone
              ? "Phone: " + props.billingAddress.phone
              : ""}
          </p>
        </div>
        <div className="card-footer">
          <Link
            to="#"
            role="button"
            className="edit-address-link"
            onClick={(e: any) => {
              e.preventDefault();
              editAddressHandler();
              props.setAddressIdHandler(props.addressId);
              props.OpenAddressModal();
            }}
          >
            Edit
          </Link>
          <Link
            className="text-decoration-none delete-item"
            to="#"
            onClick={() => {
              removeAddressHandler(
                props.addressId,
                props.billingAddress.is_primary
              );
            }}
          >
            <img src={DeleteImg} alt="delete-img" />
            <span>Delete</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BillingAddressCard;
