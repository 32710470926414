import { ObjectType } from "../../../../../types";
import { Container } from "../../htmlTags/Container";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Button from "@mui/material/Button";
import useCommonData from "../../../../hooks/useCommon";
import "./AssociatedTagList.css";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { AssociatedTagListProps } from "../types";
import { Span } from "../../htmlTags/Span";
import CustomTooltips from "../../Tooltip";
export const AssociatedTagList = (props: AssociatedTagListProps) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<KeyboardArrowRightIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "white",
    flexDirection: "row-reverse",
    margin: "0px",
    boxShadow: "none",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
      backgroundColor: `var(--approx-alice-blue) !important`,
    },
  }));
  return (
    <Container className="associated-tag-list-page-wrapper">
      <Container className="associated-tag-list-header-wrapper">
        <Span>{props.accountName}</Span>
      </Container>
      <Container className="associated-tag-list-mapping-wrapper">
        {Array.isArray(props.reportingTagList) &&
          props.reportingTagList.map((tag: ObjectType, index: number) => {
            return (
              <Accordion
                sx={{
                  boxShadow: "none !important",
                  marginBottom: "0px !important",
                }}
                defaultExpanded={
                  props.reportingTagList.length === 1 ||
                  Number(props.tagId) === Number(tag.reporting_tag_id)
                    ? true
                    : false
                }
                className={`associated-tag-MUI-accordion`}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowRightIcon />}
                  aria-controls={`panel${tag.reporting_tag_id}-content`}
                  id={`panel${tag.reporting_tag_id}-header`}
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                  className={`associated-tag-list-summary-MUI-wrapper ${
                    index % 2 === 0 ? "even" : ""
                  }`}
                >
                  <Container className="associated-tag-list-summary-wrapper">
                    {tag.reporting_tag_name.length > 23 ? (
                      <CustomTooltips
                        title={tag.reporting_tag_name}
                        placement="right"
                      >
                        {" "}
                        <Container className="associated-tag-list-summary-name">
                          {tag.reporting_tag_name}
                        </Container>{" "}
                      </CustomTooltips>
                    ) : (
                      <Container className="associated-tag-list-summary-name">
                        {tag.reporting_tag_name}
                      </Container>
                    )}
                    <Container className="associated-tag-list-summary-amount">
                      {props.currencyCode}{" "}
                      {NumberFormat(
                        Number(tag.tag_amount),
                        props.currencyCode || currentUserInfo.currency_code,
                        orgCurrencyList
                      )}
                    </Container>
                  </Container>
                </AccordionSummary>
                <AccordionDetails>
                  {tag.option_list.map((option: ObjectType) => {
                    return (
                      <Container
                        key={option.option_id}
                        className="associated-tag-list-item-options"
                      >
                        {option.option_name?.length > 30? (
                          <CustomTooltips
                            title={option.option_name}
                            placement="right"
                          >
                            {" "}
                            <Container className="associated-tag-list-summary-name">{option.option_name}</Container>
                          </CustomTooltips>
                        ) : (
                          <Container className="associated-tag-list-summary-name">{option.option_name}</Container>
                        )}

                        <Container>
                          {props.currencyCode}{" "}
                          {NumberFormat(
                            Number(option.amount),
                            props.currencyCode || currentUserInfo.currency_code,
                            orgCurrencyList
                          )}
                        </Container>
                      </Container>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Container>
    </Container>
  );
};
