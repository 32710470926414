import React, { useEffect, useState } from "react";
import "../AddressListModal.css";
import { WhitePlusSmall } from "../../../../../../assets/images";
import { AddressValues } from "../../../../../../types";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../../../../app/hooks";
import {
  addShippingAddress,
  editShippingAddress,
} from "../../../../customerManagement/components/customerForm/AddressDetails/addressTypeSlice";

type Props = {
  customerName: string;
  type: string;
  shippingModalClose: boolean;
  modalCloseHandler: (type: string) => void;
  createShippingAddress: (address: AddressValues) => void;
  customerData: AddressValues[] | undefined;
  selectShippingAddressHandler: (index: number | undefined | string) => void;
  selectedShippingAddressId: number | string;
  openAddressModal: () => void;
  setAddressIdHandler: (id: string | number) => void;
};

const ShippingAddressList = (props: Props) => {
  const dispatch = useAppDispatch();
  const [displayList, setDisplayList] = useState(false);

  $(document)
    .off()
    .on("click", (e: any) => {
      if (
        $(e.target).closest(".address-list-container").length === 0 &&
        $(e.target).closest(".show-address-list").length === 0
      ) {
        setDisplayList(false);
        props.modalCloseHandler(props.type);
      }
    });

  useEffect(() => {
    if (props.shippingModalClose) {
      setDisplayList(false);
    }
  }, [props.shippingModalClose]);

  const displayListHandler = () => {
    setTimeout(() => {
      setDisplayList(false);
    }, 400);
  };

  return (
    <>
      <div className="edit-address-wrapper position-relative">
        <Link
          to="#"
          role="button"
          className="edit-address-link show-address-list"
          onClick={(e: any) => {
            e.preventDefault();
            setDisplayList(true);
            props.modalCloseHandler(props.type);
          }}
        >
          Edit
        </Link>
        {displayList ? (
          <div className="address-list-container position-absolute">
            <ul className="address-list">
              {props.customerData?.map((item, index) => {
                return item.is_shipping ? (
                  <li
                    className={`list-item ${
                      item.id === props.selectedShippingAddressId
                        ? "current"
                        : ""
                    }`}
                    key={index}
                  >
                    <div
                      className="radio-container"
                      onMouseDownCapture={() => {
                        props.createShippingAddress(item);
                        displayListHandler();
                        props.selectShippingAddressHandler(item.id);
                      }}
                    >
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="address1"
                          name="option_main_contact"
                          value="main contact person"
                          checked={
                            item.id === props.selectedShippingAddressId
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />
                        <label
                          className="form-check-label fw-normal"
                          htmlFor="address1"
                        >
                          <span className="max-full-width">
                            <b>
                              {item?.customer_name || props?.customerName || ""}
                            </b>
                          </span>
                          <span>{item.address}</span>
                          <span>
                            {item.city}
                            {item.city && (item.state_name || item.country_name)
                              ? ", "
                              : ""}
                            {item.state_name}
                            {item.state_name ? ", " : ""}
                            {item.country_name}
                          </span>
                          <span>
                            {item.zip_code ? "PIN " + item.zip_code : ""}
                          </span>
                          <span>
                            {item.phone ? "Phone Number : " + item.phone : ""}
                          </span>
                        </label>
                      </div>
                    </div>
                    {item.id === props.selectedShippingAddressId && (
                      <Link
                        to="#"
                        role="button"
                        className="edit-address-link show-address-list"
                        onClick={(e) => {
                          e.preventDefault();
                          props.setAddressIdHandler(item.id ? item.id : "");
                          props.openAddressModal();
                          dispatch(editShippingAddress("edit_shipping"));
                        }}
                      >
                        Edit
                      </Link>
                    )}
                  </li>
                ) : null;
              })}
            </ul>
            <button
              type="button"
              className="btn green-button add-address-btn w-100"
              onClick={() => {
                props.setAddressIdHandler("");
                props.openAddressModal();
                dispatch(addShippingAddress("add_shipping"));
              }}
            >
              <img
                src={WhitePlusSmall}
                className="Group"
                alt="plus-small-img"
              />
              Add Address
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ShippingAddressList;
