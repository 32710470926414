import { useEffect, useState } from "react";
import Select from "react-select";
import "./BudgetDropdown.css";
import useCommonData from "../../../../../../hooks/useCommon";
import { Container } from "../../../../../../common/components/htmlTags/Container";
import { budgetList } from "../../../../../budget/budgetSlice";
import { customSelectStyle } from "../../../../../../common/components/SelectCustomStyle";
import { Span } from "../../../../../../common/components/htmlTags/Span";
interface Budget {
  id: number;
  name: string;
}

interface OrganizationDropDownProps {
  budgetId: number;
  handleBudgetChange: ({ value, label }: { value: number; label: string }) => void;
  error?: string;
  isDisabled?: boolean;
  orgId?: number;
  setTouched: (touched: any) => void;
  touched: any;
}
interface OptionType {
  label: string;
  value: number;
}

export const BudgetDropDown = ({
  budgetId,
  handleBudgetChange,
  error,
  isDisabled,
  orgId,
  setTouched,
  touched,
}: OrganizationDropDownProps) => {
  const [loading, setLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { currentUserInfo, dispatch } = useCommonData();
  const [budgetOptions, setBudgetOptions] = useState<OptionType[]>(
    []
  );

  useEffect(() => {
    getOrganization();
  }, [orgId]);

  const getOrganization = async () => {
    setLoading(true);
    const responseAction = await dispatch(
      budgetList({ orgId: orgId || currentUserInfo.organization_id, page: 1, itemsPerPage: 100 })
    );
    const response = responseAction.payload;
    if (response ) {
      const options = response?.budgets.map((budget: Budget) => ({
        label: budget.name,
        value: budget.id,
      }));
      setBudgetOptions(options);
    }
    setLoading(false);
  };

  const selectedOption = budgetOptions.find(
    (option) => option.value === budgetId
  );

  return (
    <Container className="budget-dropdown-container">
      <Select
        name="budget_dropdown"
        className={`${error ? "select-error" : ""}`}
        isDisabled={isDisabled}
        value={selectedOption || null} // Pass selectedOption object
        options={budgetOptions}
        placeholder="Choose Budget"
        isLoading={loading}
        onChange={(option) => {
          if (option) handleBudgetChange(option);
          
        }}
        isSearchable={false}
        styles={customSelectStyle}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => {
          setMenuIsOpen(true);
          }}
        onMenuClose={() => {
          setTouched({...touched, budget_id: true});
          setMenuIsOpen(false);}}
        classNamePrefix="react-select "
      />
      {error && <Span className="error">{error}</Span>}
    </Container>
  );
};
