import React, { useEffect, useState } from "react";
import { EditIconBlueImg } from "../../../../assets/images";
import { useAppSelector } from "../../../../app/hooks";
import { currentUserSelector } from "../../commonSlice";
import { IsFeatureEnabled } from "../../../../helpers/featureFlagHelper";
import EditInputModal from "../EditInputModal";
import "../../../../index.css";
import "./PrefixInput.css";

type Props = {
  prefix: string;
  name: string;
  id?: string;
  error: string;
  className: string;
  placeholder: string;
  value: string | number;
  disabled: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  isPrefixNonEditable?: boolean;
  editId?: number | null;
  onChange: (e: any) => void;
  onClick?: () => void;
  getPrefix?: (inputValue: string) => void;
  isPrefixRequired?: boolean;
};
export default function PrefixInput(props: Props) {
  const [prefixValue, setPrefix] = useState("");
  const [showEditInput, setEditInput] = useState(false);
  const [error, setError] = useState("");
  const currentUserInfo = useAppSelector(currentUserSelector);
  useEffect(() => {
    setPrefix(props.prefix);
  }, [props.prefix]);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let customizePrefixPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((item: any) => item.subModule === "Customize Prefix")
        .map((permission: any) => {
          return permission.subModule;
        })
    : [];

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let _popContainer: any = document.querySelector(".editInputPopup");
      let _popupLink: any = document.querySelector(".editPrefix");
      if (
        !$(e.target).closest(_popupLink).length &&
        !$(e.target).closest(_popContainer).length
      ) {
        setEditInput(false);
      }
    });
  }, []);

  const showEditPopup = (e: any) => {
    e.preventDefault();
    setEditInput(true);
  };
  const saveFunction = (inputValue: string) => {
    if (props.editId) {
      setError("");
      setPrefix(inputValue.trim());
      props.getPrefix && props.getPrefix(inputValue.trim());
      setEditInput(false);
    } else {
      if (props.isPrefixRequired === false) {
        setError("");
        setPrefix(inputValue.trim());
        props.getPrefix && props.getPrefix(inputValue.trim());
        setEditInput(false);
      } else {
        if (inputValue.trim() !== "") {
          setError("");
          setPrefix(inputValue.trim());
          props.getPrefix && props.getPrefix(inputValue.trim());
          setEditInput(false);
        } else {
          setError("Please enter a valid prefix.");
        }
      }
    }
  };

  const cancelFunction = () => {
    setEditInput(false);
    setError("");
  };

  return (
    <div className={`inv-group d-flex w-100 ${props.error ? "error" : ""}`}>
      <div className="inv-prefix border-start-0 position-relative d-flex">
        <span>{prefixValue}</span>
        {IsFeatureEnabled(
          "Customize Prefix",
          currentUserInfo.disabled_feature
        ) && customizePrefixPermission.length ? (
          <a
            href="#editInput"
            role="button"
            className="editPrefix"
            onClick={showEditPopup}
          >
            {props.isPrefixNonEditable ? (
              ""
            ) : (
              <img src={EditIconBlueImg} alt="edit" />
            )}
          </a>
        ) : (
          <></>
        )}
        {showEditInput ? (
          <EditInputModal
            title="Edit Prefix"
            inputType="text"
            currentValue={prefixValue}
            maxLimit={10}
            inputId="edit-prefix"
            label="Edit Prefix"
            saveAction={saveFunction}
            error={error}
            cancelAction={cancelFunction}
            isMandatory={props.isPrefixRequired !== false}
          />
        ) : (
          ""
        )}
      </div>
      <input
        type="text"
        name={props.name}
        id={props.id}
        className={`prefix-input ${props.className}`}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        maxLength={props.maxLength ? props.maxLength : undefined}
        autoFocus={props.autoFocus ? props.autoFocus : false}
        onChange={props.onChange}
        onClick={props.onClick ? props.onClick : () => {}}
        onFocus={(e: any) => e.target.parentElement.classList.add("focused")}
        onBlur={(e: any) => e.target.parentElement.classList.remove("focused")}
      />
    </div>
  );
}
