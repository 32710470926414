import { Row } from "@tanstack/react-table";
import { ProcessedRow } from "../../../types";
import { Span } from "../../../../../../common/components/htmlTags/Span";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import "./VarianceCell.css";
export const VarianceCell = ({
  row,
  isPercentage,
  accountType,
  formatNumber,
}: {
  row: Row<ProcessedRow>;
  isPercentage: boolean;
  accountType: string;
  formatNumber: (value: number) => string;
}) => {
  switch (accountType) {
    case "Incomes":
      return (
        <Span className="variance-cell-wrapper">
          {isPercentage && !row.original.is_category
            ? row.original.variance_percent + "%" || "-"
            : row.original.is_category
            ? "-"
            : formatNumber(row.original.variance || 0) || "-"}
          {Number(row.original.variance) !== 0 && !row.original.is_category && row.original.style_class !== "total-node" && (
            <Span className="variance-icon-wrapper">
              {row.original.is_positive ? (
                <NorthIcon className="variance-icon-positive" />
              ) : (
                <SouthIcon className="variance-icon-negative" />
              )}
            </Span>
          )}
        </Span>
      );
    case "Expenses":
      return (
        <Span className="variance-cell-wrapper">
          {isPercentage && !row.original.is_category
            ? row.original.variance_percent + "%" || "-"
            : row.original.is_category
            ? "-"
            : formatNumber(row.original.variance || 0) || "-"}
          {Number(row.original.variance) !== 0 && !row.original.is_category && row.original.style_class !== "total-node" && (
            <Span className="variance-icon-wrapper">
              {row.original.is_positive ? (
                <NorthIcon className="variance-icon-positive" />
              ) : (
                <SouthIcon className="variance-icon-negative" />
              )}
            </Span>
          )}
        </Span>
      );
    default:
      return <Span>{formatNumber(row.original.variance || 0) || "-"}</Span>;
  }
};
export const CumulativeVarianceCell = ({
  row,
  isPercentage,
  accountType,
  formatNumber,
}: {
  row: Row<ProcessedRow>;
  isPercentage: boolean;
  accountType: string;
  formatNumber: (value: number) => string;
}) => {
  switch (accountType) {
    case "Incomes":
      return (
        <Span className="variance-cell-wrapper">
          {isPercentage && !row.original.is_category
            ? row.original.cumulated_variance_percent + "%" || "-"
            : row.original.is_category
            ? "-"
            : formatNumber(row.original.cumulated_variance || 0) || "-"}
          {Number(row.original.cumulated_variance) !== 0 &&
            !row.original.is_category &&
            row.original.style_class !== "total-node" && (
              <Span className="variance-icon-wrapper">
                {row.original.is_cumulated_positive ? (
                  <NorthIcon className="variance-icon-positive" />
                ) : (
                  <SouthIcon className="variance-icon-negative" />
                )}
              </Span>
            )}
        </Span>
      );
    case "Expenses":
      return (
        <Span className="variance-cell-wrapper">
          {isPercentage && !row.original.is_category
            ? row.original.cumulated_variance_percent + "%" || "-"
            : row.original.is_category
            ? "-"
            : formatNumber(row.original.cumulated_variance || 0) || "-"}
          {Number(row.original.cumulated_variance) !== 0 &&
            !row.original.is_category && row.original.style_class !== "total-node" && (
              <Span className="variance-icon-wrapper">
                {row.original.is_cumulated_positive &&
                !row.original.is_category ? (
                  <NorthIcon className="variance-icon-positive" />
                ) : (
                  <SouthIcon className="variance-icon-negative" />
                )}
              </Span>
            )}
        </Span>
      );
    default:
      return (
        <Span>{formatNumber(row.original.cumulated_variance || 0) || "-"}</Span>
      );
  }
};
