import { ExpandedState } from "@tanstack/react-table";
import useCommonData from "../../../../../hooks/useCommon";

export const useDrillDown = () => {
  const { currentUserInfo, navigate } = useCommonData();
  /**
   * Navigation to account transaction page to view the transactions of a particular account
   */
  const accTransactionNavHandler = (
    accountId: number,
    trialBalanceData: any,
    expanded: ExpandedState
  ) => {
    let trialBalanceFilter = {
      currencyId: trialBalanceData.currency_id,
      dateRange: trialBalanceData.date_range,
      startDate: trialBalanceData.start_date,
      endDate: trialBalanceData.end_date,
      organizationId: trialBalanceData.organization_id,
      is_integrated: trialBalanceData.is_integrated,
      tag_option_in: trialBalanceData.tag_option_in,
    };
    localStorage.setItem(
      "drill-down-filter",
      JSON.stringify(trialBalanceFilter)
    );
    localStorage.setItem(
      "drill-down-report",
      JSON.stringify({ label: "Trial Balance", url: "/trial-balance" })
    );
    localStorage.setItem("expanded-rows", JSON.stringify(expanded));
    localStorage.setItem("drill-down-account", accountId.toString());
    let accTransactionFilter = {
      accountId: accountId,
      currencyId: trialBalanceData.currency_id,
      dateRange: "custom",
      startDate: trialBalanceData.start_date,
      endDate: trialBalanceData.end_date,
      organizationId: trialBalanceData.organization_id,
      is_integrated: trialBalanceData.is_integrated,
      tag_option_in: trialBalanceData.tag_option_in,
    };
    navigate("/account-transactions", {
      state: { accTransactionFilter: accTransactionFilter },
    });
  };
  return { accTransactionNavHandler };
};
