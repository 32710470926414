import { Api } from "../../../constants";
import { post } from "../../../helpers/apiHelper";

export async function exportSubscriptions(data: {
  payload: any;
  orgId: number;
}) {
  const result = await post(
    Api.subscription +
      `exports/subscriptions?current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}

export async function exportInvoices(data: {
  payload: any;
  orgId: number;
}) {
  const result = await post(
    Api.salesInvoice +
      `exports/invoices?current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}
