import { Api } from "../../../constants";
import { get, post, put } from "../../../helpers/apiHelper";
import { PaymentGatewayConfigUpdateValues } from "./types";

/**
 * API call for creating payment gateway config
 */
export async function paymentGatewayConfigCreate(data: {
  orgId: number;
  values: {
    chart_of_account_id: number | null;
    api_key: string;
    api_secret: string;
    webhook_secret: string;
    chart_of_account_name: string;
    gateway_type: string;
    is_default: boolean;
    metadata: {
      [key: string]: string;
    };
    mapping_metadata: {
      [key: string]: string;
    };
  };
}) {
  const result = await post(
    Api.paymentGateway + `gateways/?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for creating payment gateway config
 */
export async function paymentGatewayConfigUpdate(data: {
  orgId: number;
  values: PaymentGatewayConfigUpdateValues;

}) {
  const result = await put(
    Api.paymentGateway +
      `gateways/${data.values.id}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for getting payment gateway config
 */
export async function paymentGatewayConfigFetch(data: {
  orgId: number;
  values: {
    skip: number;
    limit: number;
  };
}) {
  const result = await get(
    Api.paymentGateway +
      `gateways/?current_organization=${data.orgId}&skip=${data.values.skip}&limit=${data.values.limit}`
  );
  return result;
}
