import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { Form } from "./htmlTags/Form";
import { ObjectType } from "../../../types";
import { Card } from "./htmlTags/Card/Card";
import { setLoaderState } from "../commonSlice";
import { AddTagIcon } from "../../../assets/images";
import { CardBody } from "./htmlTags/Card/CardBody";
import { CardHeader } from "./htmlTags/Card/CardHeader";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormSelectField } from "./formSelectField/FormSelectField";
import { AddChargesFormInitialValueType } from "../../home/subscription/subscriptionType";
import { addChargesFormInitialValue } from "../../home/subscription/SubscriptionStateInitialization";
import { validateAddChargeForm } from "../../home/subscription/components/subscriptionForm/ValidateSubscriptionForm";
import { FormSelectFieldWithScroll } from "./formSelectField/FormSelectFiledWithScroll";
import {
  subscriptionChargeFrequencyItems,
  subscriptionChargesDropdown,
} from "../../home/subscription/subscriptionSlice";
import useCommonData from "../../hooks/useCommon";
import Dialog from "@mui/material/Dialog";
import CloseButton from "./button/CloseButton";
import Grid from "@mui/material/Grid";

type Props = {
  onSaveClick: (selectedFrequency: any) => void;
  planFrequency: string;
  selectedChargelist: number[];
  currencyId: number | undefined;
  conversionId: number | undefined;
  editId?: string | undefined;
};
function AddLineCharges(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [chargeFormErrors, setChargeFormErrors] = useState<ObjectType>({});
  const [allCharges, setAllChagres] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");
  const abortControllerRef = useRef<AbortController | null>(null);

  const formik = useFormik({
    initialValues: {
      ...addChargesFormInitialValue,
    } as AddChargesFormInitialValueType,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let errors = validateAddChargeForm(values);
      if (Object.keys(errors).length) {
        setChargeFormErrors(errors);
      } else {
        let passingValue = allCharges.filter(
          (data: any) => Number(data.id) === Number(values.selectedChages.value)
        )[0];

        const itemDetails = await getSelectedChageFrequencyItemDetails(
          values.selectedChages.value
        );

        props.onSaveClick({
          ...passingValue,
          chargeId: values.selectedChages.value,
          chargeName: values.selectedChages.label,
          customerChargeType: values.chageCustomerOn.value,
          no_of_days: values.no_of_days,
          applyCharges: values.applyCharges.value,
          item_price: itemDetails,
        });
        formik.resetForm();
      }
    },
  });

  const fetchChargeDropdown = async (count:number , searchQuery: string) => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    if (formik.values.count && formik.values.count <= 1)
      dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionChargesDropdown({
        page: count,
        itemsPerPage: 10,
        orgId: currentUserInfo.organization_id,
        frequency: props.planFrequency,
        chargeIds: props.selectedChargelist,
        isForDropdown: true,
        searchQuery: searchQuery,
        signal: abortControllerRef.current.signal
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        const modifiedResponse = response.charges.map((data: any) => ({
          value: data.id.toString(),
          label: data.external_name,
        }));
        setAllChagres([...response.charges]);
        formik.setFieldValue(
          "chargesDropdownData",
          count > 1
          ? formik.values.chargesDropdownData.concat(modifiedResponse)
          : modifiedResponse
        );
        formik.setFieldValue(
          "isNextEnabled",
          !(response.pagination.next === null)
        );
        if (response.pagination.next !== null) {
          const regex = new RegExp(`page_num=(\\d+)`);
          const modifiedString = response.pagination.next.match(regex);
          const count = modifiedString[1]
            ? modifiedString[1]
            : formik.values.count;
          formik.setFieldValue("count", count);
        }
      } else if ("error" in response) {
      }
    }
  };

  const getSelectedChageFrequencyItemDetails = async (
    chargeId: number | string
  ) => {
    const responseAction = await dispatch(
      subscriptionChargeFrequencyItems({
        chargeId: chargeId as number,
        orgId: currentUserInfo.organization_id,
        currencyId: props.currencyId ? props.currencyId : 0,
        conversionId: props.conversionId ? props.conversionId : 0,
      })
    );
    let itemDetails: any[] = [];
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        itemDetails = response;
      }
    }
    return itemDetails;
  };

  let chageCustomerOnDropdown = [
    { value: "ON_SUBSCRIPTION_CREATION", label: "On subscription creation" },
    {
      value: "ON_SUBSCRIPTION_TRIAL_START",
      label: "On subscription trial start",
    },
    {
      value: "ON_PLAN_ACTIVATION",
      label: "On plan activation",
    },
    {
      value: "ON_SUBSCRIPTION_ACTIVATION",
      label: "On subscription activation",
    },
  ];

  if (props.editId) {
    chageCustomerOnDropdown = [
      { value: "ON_SUBSCRIPTION_CREATION", label: "On subscription creation" },
      {
        value: "ON_SUBSCRIPTION_TRIAL_START",
        label: "On subscription trial start",
      },
      {
        value: "ON_PLAN_ACTIVATION",
        label: "On plan activation",
      },
      {
        value: "ON_SUBSCRIPTION_ACTIVATION",
        label: "On subscription activation",
      },
      {
        value: "IMMEDIATELY",
        label: "Immediately",
      },
    ];
  }

  const applyChargesDropdown = [
    {
      value: "CHARGE_ONCE",
      label: "Charge Once",
    },
    {
      value: "EVERY_TIME_THIS_EVENT_OCCURS",
      label: "Every time this event occurs",
    },
  ];

  const handleChargesSearch = (searchQuery: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (searchQuery.length > 2 || searchQuery === "") {
      fetchChargeDropdown(1, searchQuery);
    }
    setSearchKey(searchQuery);
  };


  return (
    <>
      <div className="w-100 add-line-button-wrapper add-line">
        <div className="w-100 d-flex align-items-center p-b-18">
          <a
            href="#"
            role="button"
            className={`add-tags-button add-line-button d-flex align-items-center ${
              props?.planFrequency ? "" : "add-detail-disabled"
            }`}
            onClick={(e) => e.preventDefault()}
          >
            <img src={AddTagIcon} alt="" />
            <span
              className="px-2"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                fetchChargeDropdown(1,"");
                formik.setFieldValue("openPlanPopover", true);
              }}
            >
              Select Charges
            </span>
          </a>
        </div>
      </div>
      <Dialog
        open={formik.values.openPlanPopover}
        className="subscription-addon-popover"
        onClose={() => formik.resetForm()}
      >
        <Card wrapperClass="addon-card">
          <CardHeader wrapperClass="addon-card-header-wrapper">
            <Grid container>
              <Grid item xs={10} className="p-t-15">
                Select Charges
              </Grid>
              <Grid xs={2} className="popover-close-button">
                <CloseButton
                  closeModalHandler={() => {
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row custom-row">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectFieldWithScroll
                    count={formik.values.count}
                    name="selectedChages"
                    id="subscription_select_charges"
                    label="Select Charges"
                    options={formik.values.chargesDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedChages}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedChages", option);
                      formik.setFieldValue("selectedFrequency", {
                        value: "",
                        label: "",
                      });
                      // fetchBillingDropdown(option.value);
                    }}
                    onMenuScrollToBottom={() => {
                      if (formik.values.isNextEnabled)
                        fetchChargeDropdown(formik.values.count,searchKey);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    isDisabled={false}
                    error={
                      chargeFormErrors?.selectedChages
                        ? chargeFormErrors.selectedChages
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    dropdownCustomMessage="No Charges available"
                    inputValue={searchKey}
                    onInputChange={handleChargesSearch}
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="chageCustomerOn"
                    id="chageCustomerOn"
                    label="Charge Customers on"
                    options={chageCustomerOnDropdown}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.chageCustomerOn}
                    onChange={(option: any) => {
                      formik.setFieldValue("chageCustomerOn", option);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    // isDisabled={
                    //   formik.values.selectedChages?.label &&
                    //   formik.values.selectedChages.label !== ""
                    //     ? false
                    //     : true
                    // }
                    isDisabled={false}
                    error={
                      chargeFormErrors?.billing_frequency
                        ? chargeFormErrors.billing_frequency
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    classNamePrefix="billing-frequency-select"
        
                  />
                </div>
              </div>
              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <label htmlFor="no_of_days">
                    Number of days service provided
                  </label>
                  <input
                    type="number"
                    className={`form-control quantity w-100`}
                    name="no_of_days"
                    id={"quantity_"}
                    onChange={(e: any) => {
                      formik.setFieldValue("no_of_days", e.target.value);
                    }}
                    value={formik.values.no_of_days}
                  />
                  {chargeFormErrors.no_of_days && (
                    <span className="error">{chargeFormErrors.no_of_days}</span>
                  )}
                </div>
              </div>
              {[
                "ON_SUBSCRIPTION_TRIAL_START",
                "ON_SUBSCRIPTION_ACTIVATION",
                "ON_PLAN_ACTIVATION",
              ].includes(formik.values.chageCustomerOn?.value) && (
                <div className="row custom-row m-t-10">
                  <div className={"col-12 col-lg-4 mw-325 "}>
                    <FormSelectField
                      name="applyCharges"
                      id="applyCharges"
                      label="Apply charges"
                      options={applyChargesDropdown}
                      wrapperClass="mw-325"
                      className="state-select custom-select"
                      value={formik.values.applyCharges}
                      onChange={(option: any) => {
                        formik.setFieldValue("applyCharges", option);
                      }}
                      styles={customSelectStyle}
                      placeholder={"Select"}
                      isSearchable={true}
                      // isDisabled={
                      //   formik.values.selectedChages?.label &&
                      //   formik.values.selectedChages.label !== ""
                      //     ? false
                      //     : true
                      // }
                      isDisabled={false}
                      error={
                        chargeFormErrors?.billing_frequency
                          ? chargeFormErrors.billing_frequency
                          : ""
                      }
                      isRequired={false}
                      isOnlyInRow={false}
                      classNamePrefix="billing-frequency-select"
                    />
                  </div>
                </div>
              )}
              <div className="row mb-0">
                <div className="col">
                  <div className="form-button-wrapper w-100" id="form-btn">
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.submitForm();
                      }}
                      className="save-button"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setChargeFormErrors({});
                        formik.resetForm();
                      }}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
}

export default React.memo(AddLineCharges);
