import { ExpandedState } from "@tanstack/react-table";
import useCommonData from "../../../../../hooks/useCommon";

export const useDrillDown = () => {
  const { currentUserInfo, navigate } = useCommonData();
  /**
   * Navigation to account transaction page to view the transactions of a particular account
   */
  const accTransactionNavHandler = (
    accountId: number,
    balanceSheetData: any,
    expanded: ExpandedState
  ) => {
    let balanceSheetFilter = {
      currencyId: balanceSheetData.currency_id,
      dateRange: balanceSheetData.date_range,
      startDate: balanceSheetData.start_date,
      endDate: balanceSheetData.end_date,
      organizationId: balanceSheetData.organization_id,
      tag_option_in: balanceSheetData.tag_option_in,
      is_integrated: balanceSheetData.is_integrated,
    };
    localStorage.setItem(
      "drill-down-filter",
      JSON.stringify(balanceSheetFilter)
    );
    localStorage.setItem(
      "drill-down-report",
      JSON.stringify({ label: "Balance Sheet", url: "/balance-sheet" })
    );
    localStorage.setItem("expanded-rows", JSON.stringify(expanded));
    localStorage.setItem("drill-down-account", accountId.toString());
    let accTransactionFilter = {
      accountId: accountId,
      currencyId: balanceSheetData.currency_id,
      dateRange: "custom",
      startDate: balanceSheetData.start_date,
      endDate: balanceSheetData.end_date,
      organizationId: balanceSheetData.organization_id,
      is_integrated: balanceSheetData.is_integrated,
      tag_option_in: balanceSheetData.tag_option_in,
    };
    navigate("/account-transactions", {
      state: { accTransactionFilter: accTransactionFilter },
    });
  };
  return { accTransactionNavHandler };
};
