import React, { useCallback, useEffect, useMemo, useState } from "react";
import AgingReportTable from "./AgingReportTable";
import AgingReport, { initialAgingReportData } from "./AgingReport";
import useCommonData from "../../../../hooks/useCommon";
import { ObjectType } from "../../../../../types";
import { getFormatedDate } from "../../../../../helpers/helper";
import { setLoaderState } from "../../../../common/commonSlice";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { useAppSelector } from "../../../../../app/hooks";
import {
  reportCustomizationFilterSelector,
  setAgingReportCustomizationFilters,
  setReportCustomizationFilters,
} from "../../ReportSlice";
import { useLocation } from "react-router-dom";

type Props = {
  title: string;
  generateReport: (values: any) => Promise<any>;
  type: string;
};
function AgingSummary(props: Props) {
  const location = useLocation();
  const reportRef = React.useRef<any>([]);
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const [orgCurrencyCode, setOrgCurrencyCode] = useState("");
  const [reportData, setReportData] = useState<any>(initialAgingReportData);
  const [showReport, setShowReport] = useState(true);
  const agingReportFilter = useAppSelector(
    reportCustomizationFilterSelector
  ).agingReportFilters;
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  useEffect(() => {
    setOrgCurrencyCode(
      orgCurrencyList.filter((item: ObjectType) => {
        return item.id === reportData.currency_id;
      })[0]?.code
    );
  }, [reportData.currency_id]);

  const initialFiltervalues = {
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    date_range: filters ? filters.date_range : "today",
    end_date: filters ? filters.end_date : getFormatedDate(),
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
  };

  useEffect(() => {
    return () => {
      dispatch(
        setReportCustomizationFilters({
          agingReportFilters: {
            start_date: "",
            end_date: "",
            date_range: "",
            aging_by: "",
            number_of_columns: "",
            interval_range: "",
            group_by: "",
            show_by: "",
            customer_id: null,
            vendor_id: null,
            currency_id: 0,
            name: "",
            id: 0,
          },
        })
      );
    };
  }, []);

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    )
      getReport("initial");
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const handleReport = (type: string, argFilters: any = {}) => {
    getReport(type, argFilters);
  };
  const getReport = useCallback(
    async (type: string, argFilters = {}) => {
      dispatch(setLoaderState(true));
      let filters = {
        ...reportRef.current?.filterValues,
        ...agingReportFilter,
        ...argFilters,
        organization_id: reportRef.current?.filterValues?.organization_id,
      };

      if (type === "initial") filters = initialFiltervalues;
      const responseAction = await props.generateReport(filters).finally(() => {
        dispatch(setLoaderState(false));
      });
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          let reportDetails = response;
          let totalDetails = response?.totals;
          if (totalDetails && response.aging_report.length) {
            totalDetails["customer_name"] = "Total";
            totalDetails["vendor_name"] = "Total";
            reportDetails.aging_report.push(totalDetails);
          }
          setReportData(reportDetails);
          
          setShowReport(true);
          dispatch(
            setAgingReportCustomizationFilters({
              start_date: "",
              end_date: reportDetails.end_date,
              date_range: reportDetails.date_range,
              aging_by: reportDetails.aging_by,
              number_of_columns: reportDetails.number_of_columns,
              interval_range: reportDetails.interval_range,
              interval_type: reportDetails.interval_type,
              group_by: reportDetails.group_by,
              show_by: reportDetails.show_by,
              customer_id: reportDetails.customer_id,
              vendor_id: reportDetails.vendor_id,
              organization_id: reportDetails.organization_id,
              currency_id: reportDetails.currency_id,
            })
          );
        }
      }
      
    },
    [agingReportFilter, currentUserInfo.organization_id]
  );
  const generateColumns = useMemo(() => {
    const columns = [];
    columns.push(
      {
        Header: props.type === "debtor" ? "Customer Name" : "Vendor Name",
        accessor: "show.name",
        className: "consumer-name text-align-left",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        className: "org",
      },
      {
        Header: (
          <div className="multi-line-title">
            <span>Total</span>
            <span>
              (
              {props.type === "debtor"
                ? "Customer Currency"
                : "Vendor Currency"}
              )
            </span>
          </div>
        ),
        accessor:
          reportData.show_by === "Invoice Count" ||
          reportData.show_by === "Bill Count"
            ? "TotalInUserCurrency"
            : "show.totalInUserCurrency",
        className: "total-currency text-align-left",
      },
      {
        Header: "Current",
        accessor: "show.current",
        className: "current text-align-right",
      }
    );
    const intervalRange = reportData.interval_range;
    const intervalType = reportData.interval_type;
    let count = 1;
    let initialRange = intervalRange;
    for (const key in reportData.totals) {
      if (key.includes("Days") || key.includes("Weeks")) {
        let header = count + "-" + initialRange + " " + intervalType;
        if (key.includes("Days_ago") || key.includes("Weeks_ago")) {
          count = count - 1;
          header = ">" + count + " " + intervalType;
          columns.push({
            Header: header,
            accessor: `show.daysRange.${intervalType}_ago_${count}`,
            className: "days-range text-align-right",
          });
        } else {
          columns.push({
            Header: header,
            accessor: `show.daysRange.${intervalType}_${count}_${initialRange}`,
            className: "days-range text-align-right",
          });
        }
        count += intervalRange;
        initialRange += intervalRange;
      }
    }
    columns.push({
      Header: "Total",
      accessor: "show.total",
      className: "total text-align-right",
      Cell: ({ cell: { value, row } }: any) => {
        return <b>{value}</b>;
      },
    });
    return columns;
  }, [reportData]);
  const formateNumber = (value: number | string, currencyCode?: string) => {
    let formateValue = NumberFormat(
      Number(value),
      orgCurrencyCode,
      orgCurrencyList
    );
    let code = currencyCode ? currencyCode : reportData.currency_code;
    return reportData.show_by === "Invoice Count" ||
      reportData.show_by === "Bill Count"
      ? formateValue
      : code + " " + formateValue;
  };

  const getDaysRange = (itemData: any) => {
    const obj: any = {};
    const entries = Object.entries(itemData);
    for (const [key, value] of entries) {
      if (key.includes("Days") || key.includes("Weeks")) {
        obj[key] = formateNumber(Number(value));
      }
    }
    return obj;
  };

  const getDataWithCurrency = () => {
    let data = [];
    for (let item of reportData.aging_report) {
      data.push({
        show: {
          name:
            props.type === "debtor"
              ? item?.currency_code
                ? item?.currency_code
                : item?.customer_name
              : item?.currency_code,
          totalInUserCurrency: "",
          current: formateNumber(item.current),
          daysRange: getDaysRange(item),
          total: formateNumber(item.total),
        },
        className: "customRowTitle",
      });
      if (item.users) {
        for (let users of item.users) {
          data.push({
            show: {
              name:
                props.type === "debtor"
                  ? users?.customer_name
                  : users?.vendor_name,
              totalInUserCurrency:
                props.type === "debtor"
                  ? users?.total_in_customer_currency
                    ? formateNumber(
                        users?.total_in_customer_currency,
                        users?.currency_code
                      )
                    : ""
                  : users?.total_in_vendor_currency
                  ? formateNumber(
                      users?.total_in_vendor_currency,
                      users?.currency_code
                    )
                  : "",
              current: formateNumber(users.current),
              daysRange: getDaysRange(users),
              total: formateNumber(users.total),
            },
          });
        }
      }
    }
    return data;
  };

  const getData = () => {
    return reportData.aging_report &&
      (reportData.group_by === "Customer" || reportData.group_by === "Vendor")
      ? reportData.aging_report.map((itemData: any, index: number) => ({
          show: {
            name:
              props.type === "debtor"
                ? itemData?.customer_name
                : itemData?.vendor_name,
            organization: itemData.organization_name,
            totalInUserCurrency:
              props.type === "debtor"
                ? itemData?.total_in_customer_currency
                  ? formateNumber(
                      itemData?.total_in_customer_currency,
                      itemData?.currency_code
                    )
                  : ""
                : itemData?.total_in_vendor_currency
                ? formateNumber(
                    itemData?.total_in_vendor_currency,
                    itemData?.currency_code
                  )
                : "",
            current: formateNumber(itemData.current),
            daysRange: getDaysRange(itemData),
            total: formateNumber(itemData.total),
          },
          className:
            itemData.customer_name === "Total" ||
            itemData?.vendor_name === "Total"
              ? "customRowTitle total-row no-click-action"
              : "",
        }))
      : reportData.aging_report && reportData.group_by === "Currency"
      ? getDataWithCurrency()
      : [];
  };

  const data = getData();

  return (
    <AgingReport
      title={props.title}
      handleReport={handleReport}
      reportData={reportData}
      clearReport={() => setShowReport(false)}
      showReport={showReport}
      type={props.type}
      module={"aging_summary"}
      reportRef={reportRef}
    >
      <AgingReportTable
        columns={generateColumns}
        data={data}
        haveColumnClassName={true}
        hiddenColumns={["TotalInUserCurrency"]}
      />
    </AgingReport>
  );
}

export default AgingSummary;
