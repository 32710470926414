import { useState } from "react";
import parseAmount from "../../../../helpers/amountCopyPasteHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import { splitNumberByDecimalPoint } from "../../../../helpers/helper";
import { zeroDisplayFormat } from "../../../../helpers/numberFormatHelper";
import { getRoundOffAmount } from "../../../../helpers/roundOffHelper";
import { CustomerItemValues, ObjectType } from "../../../../types";
import useCommonData from "../../../hooks/useCommon";
import CustomSearchSelect from "../CustomSearchSelect/CustomSearchSelect";
import CustomToggleButton from "../button/ToggleButton";
import InfoTooltipMessage from "../infoTipMessage/InfoTipMessage";
import "./Discount.css";

type Props = {
  index?: number;
  discount: string | number;
  discountAccountId: number | null;
  discountAccountName: string;
  discountType: string;
  discountErrors?: ObjectType;
  currencyCode: string | undefined;
  baseAccount: string;
  error: string;
  handleItemChange?: (e: any, index: number) => void;
  handleDiscountChange?: (e: any, index: number) => void;
  handleDiscountType?: (value: string) => void;
  item?: CustomerItemValues;
  organizationId: number;
  handleItemAccountChange?: (e: any, index: number) => void;
  itemFormErrors?: CustomerItemValues | undefined;
  handleOnBlur: (name: string, amount: number, index: number) => void;
  wrapperClass?: string;
  isDisabled?: boolean;
};
export const Discount = (props: Props) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const [isCopyPaste, setCopyPaste] = useState(false);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check for copy (Ctrl+C) and paste (Ctrl+V) shortcuts
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "c" ||
        event.key === "v" ||
        event.key === "a" ||
        event.key === "x")
    ) {
      setCopyPaste(true);
      return; // Allow copy-paste functionality
    } else {
      setCopyPaste(false);
    }

    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  const handleItemOnBlur = (amount: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let decimals: number;

      decimals = decimalPlaceOfCurrency(props.currencyCode, orgCurrencyList);

      if (fractionalPart.length > decimals) {
        const roundedAmount = getRoundOffAmount(amount, decimals);
        props.handleOnBlur("", roundedAmount, 0);
      }
    }
  };
  return (
    <div>
      <div className="label-wrapper discount" id="discount_account_id">
        <label htmlFor="discount">Discount</label>
        <InfoTooltipMessage
          title={
            "Add the percentage/amount to be deducted from the sub-total as a discount"
          }
          placement="bottom-start"
          iconClassName="discount-info-icon"
        />
        <div className="discount-type-wrapper d-flex align-items-center">
          <CustomToggleButton
            currencyCode={props.currencyCode || currentUserInfo.currency_code}
            handleToggle={(value) => {
              props.handleDiscountType && props.handleDiscountType(value);
            }}
            discountType={props.discountType}
          />
        </div>
      </div>
      <div
        className={
          props.error
            ? "error discount-selector d-flex w-100"
            : "discount-selector d-flex w-100"
        }
      >
        <input
          type="text"
          name="discount"
          className="discount-input"
          id={"discount_" + props.index}
          onKeyDown={handleKeyPress}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.currencyCode
                ? props.currencyCode
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          value={props.discount ? props.discount : ""}
          onChange={(e) => {
            const { id, name, value } = e.target;
            const regex = /^(\d*\.?\d*)$/;
            let filteredValue = parseAmount(value, isCopyPaste) || value;
            if (value === "" || regex.test(filteredValue.toString())) {
              props.handleDiscountChange &&
                props.handleDiscountChange(
                  {
                    ...e,
                    target: {
                      ...e.target,
                      id: id,
                      name: name,
                      value: filteredValue,
                    },
                  },
                  props.index || 0
                );
            }
          }}
          onBlur={() => handleItemOnBlur(Number(props.discount))}
        />
        <CustomSearchSelect
          itemAccount={Number(props.discountAccountId)}
          itemAccountName={props.discountAccountName}
          organizationId={props.organizationId}
          handleItemAccountChange={(e) => {
            const data = {
              target: {
                name: "discount_account_id",
                value: e,
              },
            };
            props.handleItemAccountChange &&
              props.handleItemAccountChange(data, props.index || 0);
          }}
          itemIndex={props.index || 0}
          error={""}
          baseAccount={props.baseAccount}
          className="discount-account-selector"
          wrapperClass={"sales-purchase-discount-select"}
          isDisabled={props.isDisabled}
        />
      </div>
      {<span className="error">{props.error}</span>}
    </div>
  );
};
