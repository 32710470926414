import { colors } from "../../../constants/colors";
import { SettingsIcon } from "../../../common/components/customSvgIcons/settingsIcon";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import SubscriptionFilterComponent from "./filterComponents/SubscriptionFilterComponents";
import InvoiceFilterComponent from "./filterComponents/InvoiceFilterComponent";

function ExportFilterSideModal(props: any) {
  const exportModuleFilterSelector = (onCloseModal: () => void) => {
    switch (props.moduleType) {
      case "Subscription":
        return <SubscriptionFilterComponent onCloseModal={onCloseModal} />;
        break;
      case "Invoice":
        return <InvoiceFilterComponent onCloseModal={onCloseModal} />;
      default:
        return <></>;
        break;
    }
  };
  return (
    <SideModal
      modalTitle="Select criteria to export"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return exportModuleFilterSelector(onCloseModal);
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        props.buttonContent ? props.buttonContent : "Select criteria to export"
      }
      buttonClass={props.buttonClass ? props.buttonClass : "cancel-button"}
      wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
      onOpenFunction={props.onOpenFunction}
    />
  );
}

export default ExportFilterSideModal;
