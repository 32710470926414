import * as yup from "yup";

export const paymentGatewayConfigSchema = yup.object().shape({
  selected_payment_gateway: yup.object().shape({
    api_key: yup.string().required("Please enter publishable key."),
    api_secret: yup.string().required("Please enter secret key."), 
    webhook_secret: yup.string().required("Please enter webhook secret."),
    chart_of_account_name: yup.string().required("Please select an account."),
  })
});
