export const initialDefaultValues = {
  internal_name: "",
  external_name: "",
  description: "",
  custom_fields: {},
  items: [],
  delete_items: [],
  addon_exists_subscription: false,
  currency: [],
};
