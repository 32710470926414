import React from "react";
import "../../../../index.css";
import "./Button.css";

type ButtonProps = {
  type: "submit" | "reset" | "button";
  isDisabled: boolean;
  wrapperClass?: string;
  buttonContent: string | React.ReactNode;
  onClick?: (e: any) => void;
  fontColor: string;
  fontWeight: string;
  fontSize: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};
export const TextButton = (props: ButtonProps) => {
  return (
    <button
      className={`no-appearance text-btn ${
        props.wrapperClass ? props.wrapperClass : ""
      }`}
      type={props.type}
      disabled={props.isDisabled}
      style={{
        pointerEvents: props.isDisabled ? "none" : undefined,
        opacity: props.isDisabled ? "0.5" : "",
        color: props.fontColor,
        fontWeight: props.fontWeight,
        fontSize: props.fontSize,
      }}
      onClick={props.onClick}
    >
      <span className="d-flex align-items-center">
        {props.startIcon && (
          <span style={{ marginRight: "5px" }}>
            {props.startIcon ? props.startIcon : ""}
          </span>
        )}
        {props.buttonContent}
        {props.endIcon && (
          <span style={{ marginLeft: "5px" }}>
            {props.endIcon ? props.endIcon : ""}
          </span>
        )}
      </span>
    </button>
  );
};
