import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InvoiceImage } from "../../../../../../assets/images";
import {
  DateFormatHandler,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  paymentReceivedColumnValues,
  paymentReceivedDefaultValues,
  paymentReceivedMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { PaymentReceivedListValues } from "../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";
import EmptyList from "../../../../../common/components/EmptyList";
import Popover from "../../../../../common/components/Popover";
import Pagination from "../../../../../common/components/table/Pagination";
import Loader from "../../../../components/Loader";
import { invoicePaymentList } from "../../../../paymentsReceived/paymentReceivedSlice";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import TableContainer from "../../../../../common/components/table/TableContainer";
type Props = {
  isSameOrg: boolean;
};
function PRTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [invoicePaymentData, setInvoicePaymentData] = useState<
    PaymentReceivedListValues[]
  >([]);
  const [totalPaymentsReceived, setTotalPaymentsReceived] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["actions"]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const customerId = editId ? editId : "";
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let paymentsReceivedPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Payments Received"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchInvoicePaymentList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalPaymentsReceived / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalPaymentsReceived, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    paymentReceivedColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [invoicePaymentData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });

  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Payments Received",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = paymentReceivedDefaultValues;
      }
      paymentReceivedMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch all Invoice Payments
   */
  const fetchInvoicePaymentList = async () => {
    setIsLoading(true);
    if (customerId) {
      const responseAction = await dispatch(
        invoicePaymentList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          orgIds: [],
          customerList: [Number(customerId)],
          statusList: [],
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setInvoicePaymentData(response.payments_received);
          let length = response.payments_received?.length;
          setTotalPaymentsReceived(response.total);
          if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };

  const sortHandler = (column: string) => {
    if (column === "Payment date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Payment Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Payment Number",
        accessor: selectedColumns.includes("Payment Number")
          ? "show.paymentNumber"
          : "Payment Number",
        sort: false,
        sortOrder: "",
        className: "pay_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Invoice Number",
        accessor: selectedColumns.includes("Invoice Number")
          ? "show.invoiceNumber"
          : "Invoice Number",
        sort: false,
        sortOrder: "",
        className: "inv_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? (
            <div className="pills-wrapper">
              <Popover values={value} tag={false} />
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "pay_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Amount Received",
        accessor: "show.amountReceived",
        sort: false,
        sortOrder: "",
        className: "amt_recived",
      },
      {
        Header: "Amount in Excess",
        accessor: selectedColumns.includes("Amount in Excess")
          ? "show.amountInExcess"
          : "Amount in Excess",
        sort: false,
        sortOrder: "",
        className: "amt_excess",
      },
      {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      },
      {
        Header: "actions",
        accessor: "actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, selectedColumns, hiddenColumns]
  );

  /**
   * set table row data
   */
  const data = invoicePaymentData
    ? invoicePaymentData?.map((paymentReceived, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            paymentReceived.payment_date
          ),
          paymentNumber: joinTransactionNumber(paymentReceived.payment_number),
          referenceNumber: paymentReceived.reference_number,
          invoiceNumber: paymentReceived.invoice_numbers.map((invoice) => {
            return { name: joinTransactionNumber(invoice) };
          }),
          status: paymentReceived.pr_status,
          amountReceived:
            paymentReceived.currency_code +
            " " +
            NumberFormat(
              paymentReceived.amount_received,
              paymentReceived.currency_code,
              orgCurrencyList
            ),
          amountInExcess:
            paymentReceived.currency_code +
            " " +
            NumberFormat(
              paymentReceived.amount_excess,
              paymentReceived.currency_code,
              orgCurrencyList
            ),
          tds:
            paymentReceived.currency_code +
            " " +
            NumberFormat(
              paymentReceived.tax_deducted,
              paymentReceived.currency_code,
              orgCurrencyList
            ),
        },
        actions: {
          id: paymentReceived.id,
          status: paymentReceived.pr_status,
          listName: paymentReceived.payment_number,
          emailTo: paymentReceived.email_to,
          isUnpaidInvoiceExist: paymentReceived.unpaid_invoice_lines_exist,
          redirectTo: `/customers/detail/${editId}`,
          consumerId: editId,
        },
      }))
    : [];

  /**
   * Invoke when user click to request another page.
   */
  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalPaymentsReceived;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped PR-Overview-table w-100"
        columns={columns}
        data={data}
        url={"/payment-received"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={paymentsReceivedPermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            paymentsReceivedPermission.includes("Create")
              ? "Payments not received!"
              : "No payments found!"
          }
          subheading={
            paymentsReceivedPermission.includes("Create")
              ? "No payments have been received on your invoices."
              : "Oops, There is no Payment received data available right now"
          }
          list="Payment Received"
          alt="Create Invoice Payment preview"
          buttonText="Record Payment"
          navigateLink={`/payment-received/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/customers/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            paymentsReceivedPermission.includes("Create") && props.isSameOrg
          }
          icon={InvoiceImage}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}
      <div className="table-footer pagination-container w-100 clear-both">
        {totalPaymentsReceived > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default PRTransactions;
