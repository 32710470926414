import { ObjectType } from "../../../../types";
import Select, { SingleValue } from "react-select";
import "../../../../index.css";
import "./FormSelectField.css";

type SelectFieldPlacementProps = {
  name: string;
  id: string;
  options: {
    label: string;
    value: number | string;
  }[];
  className: string;
  isDisabled: boolean;
  value:
    | {
        label: string;
        value: number | string;
      }
    | "";
    defaultValue?:
    | {
        label: string;
        value: number | string;
      }
    | "";
  onChange: (
    e: SingleValue<"" | { label: string; value: number | string }>
  ) => void;
  styles: ObjectType;
  placeholder: string;
  isSearchable: boolean;
  error: string | undefined;
  label: string;
  isRequired: boolean;
  isOnlyInRow: boolean;
  wrapperClass?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  menuPlacement: any;
  handleClearSelection?: () => void;
};
export const FormSelectFieldPlacement = (props: SelectFieldPlacementProps) => {
  return (
    <div
      className={`select-field-wrap col ${
        props.wrapperClass ? props.wrapperClass : ""
      } ${props.isOnlyInRow === true ? "row" : ""}`}
    >
      <div className="relative-wrapper">
        <label
          htmlFor={props.id}
          className={props.isRequired ? "fw-bold required" : "fw-bold"}
        >
          {props.label}
        </label>
        <Select
          name={props.name}
          id={props.id}
          options={props.options}
          className={`resusable-form-select-field  ${props.className} ${
            props.error ? "error" : ""
          }`}
          isDisabled={props.isDisabled}
          value={
            Object.values(props.value).some(
              (value) => typeof value === "string" && value !== ""
            )
              ? props.value
              : ""
          }
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          styles={{ ...props.styles }}
          placeholder={props.placeholder}
          isSearchable={props.isSearchable}
          isLoading={props.isLoading === true ? true : false}
          menuPlacement={props.menuPlacement}
        />
      </div>
      <span className="error">{props.error}</span>
    </div>
  );
};
