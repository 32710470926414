import { FormikProps } from "formik";
import { useMemo, useState } from "react";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { EditIcon, TextButton } from "../../../../common/components";
import AccountSelect from "../../../../common/components/accountSelect/AccountSelect";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import { Span } from "../../../../common/components/htmlTags/Span";
import { colors } from "../../../../constants/colors";
import useCommonData from "../../../../hooks/useCommon";
import "./excessAllocationAlert.css";
import { fetchAccountsGlCode } from "../../../chartOfAccounts/coaSlice";
import { ObjectType } from "../../../../../types";

type ExcessAmountAlertProps = {
  excessAmount: number | string | null;
  module: string;
  currencyCode: string | null;
  handleSave: (id: number, name: string) => void;
  handleClose: () => void;
  formik?: FormikProps<any>;
};
export const ExcessAmountAlert = (props: ExcessAmountAlertProps) => {
  const { orgCurrencyList, currentUserInfo, dispatch } = useCommonData();
  const [openSelect, setSelect] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [account, setAccount] = useState({ id: null, name: "" });
  const fetchAccountInit = async () => {
    setLoading(true);
    let accounts: ObjectType[] = [];
    const responseAction = await dispatch(
      fetchAccountsGlCode({
        accountSearchTerm: "",
        baseAccount:
          props.module === "Payments Made" ? "Assets" : "Liabilities",
        orgId: currentUserInfo.organization_id,
        signal: undefined,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        accounts = response.account_list;
      }
      const map = new Map(accounts.map((item) => [item.account_name, item]));
      const accountToFind =
        props.module === "Payments Made"
          ? "Prepaid Expenses"
          : "Unearned Revenue";
      const fountAccount = map.get(accountToFind);
      setAccount({
        id: fountAccount?.id,
        name: fountAccount?.account_name,
      });
      setLoading(false);
    }
  };
  useMemo(async () => {
    if (props.formik?.values.intermediate_account_id) {
      setAccount({
        id: props.formik.values.intermediate_account_id,
        name: props.formik.values.intermediate_account_name,
      });
    } else {
      await fetchAccountInit();
    }
  }, [props.formik?.values]);

  const IntermediateAccountProcessor = () => {
    return (
      <div className="main-alert-message">
        <Span style={{ lineHeight: openSelect ? "2" : "1.5" }}>
          The excess amount of{" "}
          <Span className="">
            <b>
              {`${props.currencyCode} ${NumberFormat(
                Number(props.excessAmount),
                props.currencyCode ? props.currencyCode : "",
                orgCurrencyList
              )} `}{" "}
            </b>
          </Span>
          is being saved as an excess payment under{" "}
          {openSelect ? (
            <AccountSelect
              onChange={(e) => {
                if(e){
                  setAccount({
                    id: e?.id,
                    name: e?.account_name,
                  });
                }
               
              }}
              organizationId={currentUserInfo.organization_id}
              itemAccount={Number(account.id)}
              itemAccountName={account.name}
              itemIndex={0}
              baseAccount={
                props.module === "Payments Made" ? "Assets" : "Liabilities"
              }
              error={""}
              className="w-250 mx-2"
            />
          ) : (
            <TextButton
              buttonContent={account.name}
              endIcon={<EditIcon color={colors.ceruleanBlue} />}
              isDisabled={false}
              onClick={() => {
                setSelect(true);
              }}
              fontColor={colors.ceruleanBlue}
              fontWeight="normal"
              fontSize="16px"
              type="button"
              wrapperClass=""
            />
          )}
        </Span>
      </div>
    );
  };
  return (
    <div className="excess-amount-allocation-wrap">
      <div>{IntermediateAccountProcessor()}</div>
      <div className="excess-amount-allocation-btn-group">
        <SaveButton
          type="submit"
          isDisabled={!account.id || isSubmitting || isLoading}
          buttonContent="Yes proceed"
          onClick={() => {
            setSubmitting(true);
            props.handleSave(Number(account.id), account.name);
          }}
        />
        <SecondaryButton
          type="button"
          isDisabled={false}
          buttonContent="Cancel"
          onClick={props.handleClose}
        />
      </div>
    </div>
  );
};
