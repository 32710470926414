import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  budgetNameExists,
  calculateBudgetAPI,
  calculateFormula,
  createBudgetAPI,
  deleteBudgetAPI,
  editBudgetAPI,
  getActuals,
  getBudgetDetail,
  getBudgetList,
} from "./budgetAPI";

export const getBudgetActuals = createAsyncThunk(
  "getActuals",
  async (data: { start_date: string; actuals: number; orgId: number }) => {
    return await getActuals(data);
  }
);

export const budgetNameIsExists = createAsyncThunk(
  "budget-name/is-exists",
  async (data: {
    budgetId?: string | number;
    budgetName: string;
    orgId: number;
    signal?: AbortSignal;
  }) => {
    return await budgetNameExists(data);
  }
);

export const calculateBudgetFormula = createAsyncThunk(
  "calculate-budget-formula",
  async (data: {
    option: string;
    amount: number;
    month: string;
    numberOfMonths: number;
    firstAmount: number;
    orgId: number;
    signal?: AbortSignal;
  }) => {
    return await calculateFormula(data);
  }
);

export const budgetList = createAsyncThunk(
  "budgetList",
  async (data: { page: number; itemsPerPage: number; orgId: number }) => {
    return await getBudgetList(data);
  }
);

export const createBudget = createAsyncThunk(
  "create-budget",
  async (data: {
    orgId: number;
    name: string;
    start_date: string;
    actuals: number;
    period: number;
    budget_entries: {
      account_id: number;
      monthly_values: { month: string; amount: number }[];
    }[];
  }) => {
    return await createBudgetAPI(data);
  }
);

export const editBudget = createAsyncThunk(
  "edit-budget",
  async (data: {
    orgId: number;
    budget_id: string | undefined;
    name: string;
    start_date: string;
    actuals: number;
    period: number;
    budget_entries: {
      account_id: number;
      monthly_values: { month: string; amount: number }[];
    }[];
  }) => {
    return await editBudgetAPI(data);
  }
);

export const calculateBudget = createAsyncThunk(
  "calculate-budget-table",
  async (data: {
    orgId: number;
    signal?: AbortSignal;
    start_date: string;
    budget_entries: {
      account_id: number;
      monthly_values: { month: string; amount: number }[];
    }[];
  }) => {
    return await calculateBudgetAPI(data);
  }
);

export const budgetDetail = createAsyncThunk(
  "budgetDetail",
  async (data: { budgetId: number; orgId: number }) => {
    return await getBudgetDetail(data);
  }
);

export const deleteBudget = createAsyncThunk(
  "deleteBudget",
  async (data: { deleteBudgetId: number; orgId: number }) => {
    return await deleteBudgetAPI(data);
  }
);
