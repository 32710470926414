import * as React from "react";
import { colors } from "../../../constants/colors";
import { LineItemProps } from "../../../../types";
import ItemField from "./ItemField";
import { useAppSelector } from "../../../../app/hooks";
import CustomSearchSelect from "../CustomSearchSelect/CustomSearchSelect";
import InfoTooltip from "../InfoTooltip";
import Discount from "../Discount";
import Itemtax from "../ItemTax";
import { IsFeatureEnabled } from "../../../../helpers/featureFlagHelper";
import { AddTagIcon } from "../../../../assets/images";
import { DeleteIcon } from "../customSvgIcons/deleteIcon";
import { useParams } from "react-router-dom";
import { tdsPreferenceSelector } from "../../../home/tds/tdsSlice";
import {
  TAX_SYSTEM,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../constants/constants";
import SelectTDSAndTCS from "./SelectTDSAndTCS";
import GstItemTax from "../GstItemTax";

import useGst from "../../../hooks/useGst";
import { zeroDisplayFormat } from "../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../hooks/useCommon";
import {
  getUserEditedItemIndices,
  getUserEditedItemsList,
  setUserEditedItemIndicesList,
  setUserEditedItemsList,
} from "../../../home/items/itemSlice";

function LineItem({
  index,
  item,
  componentType,
  currencyId,
  currencyCode,
  isHsnCode,
  taxFormValues,
  handleItemChange,
  handleItemOnBlur,
  handleItemAccountChange,
  taxType,
  lineItemBaseAccount,
  discountBaseAccount,
  handleItemDropdownChange,
  handleItemTaxChange,
  handleItemGstChange,
  handleTaxOnBlur,
  addItemTaxContainer,
  deleteItemTaxContainer,
  deleteItemContainer,
  itemFormErrors,
  handleAmountChange,
  tdsLevel,
  sourceTaxType,
  clearTaxSelection,
  totalTcsValues,
  tdsIds,
  tcsIds,
  handleTdsLabelChange,
  clearGstSelection,
  supplyState,
  isSez,
  gstRegistrationType,
  updateIsItemEdited,
  customerDetails,
  rcmEnabled,
  amountCalculation,
  forSubscription,
  discountDropDownDisabled
}: LineItemProps) {
  const { orgCurrencyList, currentUserInfo, dispatch } = useCommonData();
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const userEditedItemIndices = useAppSelector(getUserEditedItemIndices);
  const [isCopyPaste, setCopyPaste] = React.useState(false);
  const { editId } = useParams();
  const { isGstOrg } = useGst();
  const organization_tax_system = currentUserInfo.organization_tax_system;
  const userEnteredItemsList = useAppSelector(getUserEditedItemsList);
  const isUAEOrg = currentUserInfo.organization_tax_system === "UAE_TAX_SYSTEM";

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    amountCalculation && amountCalculation(signal, null);
  }, [
    item.rate,
    item.discount_account_id,
    item.discount,
    item.tds_amount,
    item.tds_percentage,
    item.tds_id,
    item.taxes,
  ]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check for copy (Ctrl+C) and paste (Ctrl+V) shortcuts
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "c" || event.key === "v" || event.key === "a")
    ) {
      setCopyPaste(true);
      return; // Allow copy-paste functionality
    } else {
      setCopyPaste(false);
    }

    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  const handleDiscountType = (e: any, index: number) => {
    e.preventDefault();
    let discType: any = document.querySelectorAll(".discount-type-" + index);
    let target = e.target as Element;
    discType.forEach((elem: any) => {
      elem.classList.remove("active");
    });
    target.classList.add("active");
  };

  const getTdsTaxPreference = () => {
    if (!tdsPreference.is_enabled) {
      return false;
    }
    if (
      componentType === "invoice" ||
      componentType === "recurringInvoice" ||
      componentType === "creditNote"
    ) {
      if (
        tdsPreference.support_tds_for.includes("Customers") &&
        tdsPreference.apply_tds_at === "Line item level"
      ) {
        return true;
      }
    }
    if (componentType === "bill" || componentType === "debitNote") {
      if (
        tdsPreference.support_tds_for.includes("Vendors") &&
        tdsPreference.apply_tds_at === "Line item level"
      ) {
        return true;
      }
    }
    return false;
  };
  const isTdsAtLineItemEnabled = () => {
    if (
      editId &&
      (tdsLevel !== tdsApplyLevel.NO_TDS ||
        sourceTaxType !== sourceTaxTypes.NO_SOURCE_TAX)
    ) {
      if (tdsLevel === tdsApplyLevel.LINE_ITEM_LEVEL) {
        return true;
      } else if (tdsLevel === tdsApplyLevel.NO_TDS) {
        return getTdsTaxPreference();
      }
      return false;
    } else {
      return getTdsTaxPreference();
    }
  };

  const isTCSSelected = () => {
    if (totalTcsValues.tcs_id) {
      return true;
    }
    return false;
  };
  return (
    <div className="item-row " key={index}>
      <div className="row g-0">
        <ItemField
          index={index}
          componentType={componentType}
          item={{
            inventory_item_id: item.inventory_item_id,
            item_name: item.item_name,
          }}
          currencyId={currencyId ? currencyId : ""}
          currencyCode={currencyCode ? currencyCode : ""}
          handleItemDropdownChange={handleItemDropdownChange}
          handleInputItemChange={handleItemChange}
          error={itemFormErrors?.item_name ? itemFormErrors?.item_name : ""}
          forSubscription={forSubscription}
        />
        {isHsnCode && isGstOrg && (
          <div className="col w-310 pleft-20">
            <label htmlFor="hsn_code">hsn/sac code</label>
            <input
              type="text"
              className={`form-control hsn_code w-100 hsn_or_sac_code ${
                itemFormErrors?.hsn_or_sac_code ? " error" : ""
              }`}
              name="hsn_or_sac_code"
              placeholder="Enter code"
              id="hsn_code"
              value={item.hsn_or_sac_code ? item.hsn_or_sac_code : ""}
              onChange={(e) => handleItemChange(e, index)}
              maxLength={15}
            />
            <span className="error">{itemFormErrors?.hsn_or_sac_code}</span>
          </div>
        )}
        {isTdsAtLineItemEnabled() && !isUAEOrg && (
          <div
            className="col w-359 pleft-20 select-tds-dropdown"
            style={
              isTCSSelected() ? { pointerEvents: "none", opacity: "0.5" } : {}
            }
          >
            <label htmlFor={"tds-entry"}>TDS</label>
            <SelectTDSAndTCS
              id="tds-select"
              selectPlaceholderText="Choose TDS type"
              inputId="tds-entry"
              inputPlaceHolder="Enter amount"
              index={index}
              item={{
                tds_id: item.tds_id,
                tds_amount: item.tds_amount,
                tds_rate: item.tds_percentage,
                tds_name: item.tds_name,
                tcs_id: null,
                tcs_amount: 0,
                tcs_rate: 0,
                tcs_name: "",
              }}
              handleAmountChange={handleAmountChange}
              handleOnBlur={handleItemOnBlur}
              taxType="TDS"
              clearTaxSelection={clearTaxSelection}
              tdsIds={tdsIds}
              tcsIds={tcsIds}
              handleTdsLabelChange={handleTdsLabelChange}
              handleTotalTcsLabelChange={() => {}}
              applyTdsAt={"line_item_level"}
              error={itemFormErrors}
            />
            {itemFormErrors?.tds_name ? (
              <span className="error">{itemFormErrors?.tds_name}</span>
            ) : itemFormErrors?.tds_amount ? (
              <span className="error">{itemFormErrors?.tds_amount}</span>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <div className="row g-0 justify-content-between">
        <div className="item-col accnt-col w-200 pright-20">
          <label htmlFor="account" className="required">
            Account
          </label>
          <CustomSearchSelect
            itemAccount={Number(item.account_id)}
            itemAccountName={item.account_name}
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={handleItemAccountChange}
            itemIndex={index}
            error={itemFormErrors?.account_id ? "error" : ""}
            baseAccount={lineItemBaseAccount}
          />
          <span className="error">{itemFormErrors?.account_id}</span>
        </div>
        <div className="item-col width-100 pright-20">
          <label htmlFor="quantity">Quantity</label>
          <input
            type="text"
            className={`form-control quantity w-100 ${
              itemFormErrors?.quantity ? " error" : ""
            }`}
            name="quantity"
            id={"quantity_" + index}
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                currencyCode ? currencyCode : currentUserInfo.currency_code,
                orgCurrencyList
              )
            )}
            value={item.quantity ? item.quantity : ""}
            onChange={(e) => {
              handleItemChange(e, index);
            }}
            onBlur={() =>
              handleItemOnBlur(
                "quantity",
                item.quantity ? item.quantity : 0,
                index
              )
            }
          />
          <span className="error">{itemFormErrors?.quantity}</span>
        </div>
        <div className="item-col width-100 pright-20">
          <label htmlFor="rate" className="required">
            Rate
          </label>
          <input
            type="text"
            className={`form-control rate w-100 ${
              itemFormErrors?.rate ? " error" : ""
            }`}
            onKeyDown={handleKeyPress}
            name="rate"
            id={"rate_" + index}
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                currencyCode ? currencyCode : currentUserInfo.currency_code,
                orgCurrencyList
              )
            )}
            value={item.rate ? item.rate : ""}
            onChange={(e) => {
              const { id, name, value } = e.target;
              const regex = /^(\d*\.?\d*)$/;
              let filteredValue = value === "." ? "0." : value;
              dispatch(
                setUserEditedItemsList({
                  ...userEnteredItemsList,
                  [`${index}`]: {
                    item_id: item.inventory_item_id,
                    value: filteredValue,
                  },
                })
              );
              dispatch(
                setUserEditedItemIndicesList([...userEditedItemIndices, index])
              );
              // if (value === "" || regex.test(filteredValue.toString())) {
              handleItemChange(
                {
                  ...e,
                  target: {
                    ...e.target,
                    id: id,
                    name: name,
                    value: String(filteredValue),
                  },
                },
                index
              );
              // }
            }}
            onBlur={() =>
              handleItemOnBlur("rate", item.rate ? item.rate : 0, index)
            }
          />
          <span className="error">{itemFormErrors?.rate}</span>
        </div>
        <div className="item-col discount-col error w-310 pright-20 text-align-left">
          <div className="label-wrapper discount" id="discount_account_id">
            <label htmlFor="discount">Discount</label>
            <InfoTooltip
              title={
                "Add the percentage/amount to be deducted from the sub-total as a discount"
              }
            />
            <div className="discount-type-wrapper d-flex min-width">
              <button
                className={
                  item.discount_type === "Percentage"
                    ? "discount-type-" + index + " percentage active"
                    : "discount-type-" + index + " percentage"
                }
                onClick={(e: any) => {
                  handleDiscountType(e, index);
                  const data = {
                    target: {
                      name: "discount_type",
                      value: "Percentage",
                    },
                  };
                  handleItemChange(data, index);
                }}
              >
                %
              </button>
              <button
                className={
                  item.discount_type === "Absolute"
                    ? "discount-type-" + index + " currency active"
                    : "discount-type-" + index + " currency"
                }
                onClick={(e) => {
                  handleDiscountType(e, index);
                  const data = {
                    target: {
                      name: "discount_type",
                      value: "Absolute",
                    },
                  };
                  handleItemChange(data, index);
                }}
              >
                {currencyCode ? currencyCode : currentUserInfo.currency_code}
              </button>
            </div>
          </div>
          <Discount
            item={item}
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={handleItemAccountChange}
            itemFormErrors={itemFormErrors}
            index={index}
            discount={item.discount}
            discount_type={item.discount_type}
            currencyCode={currencyCode}
            baseAccount={discountBaseAccount}
            error={
              itemFormErrors?.discount || itemFormErrors?.discount_account_id
                ? "error"
                : ""
            }
            handleItemChange={(e, index) => handleItemChange(e, index)}
            handleOnBlur={handleItemOnBlur}
            dropdownDisabled={discountDropDownDisabled}
          />
          <span className="error">{itemFormErrors?.discount}</span>
          <span className="error">{itemFormErrors?.discount_account_id}</span>
        </div>
        <div className="item-col w-194 pright-16 taxes">
          <div className="label-wrapper">
            <label
              htmlFor="tax_id"
              className={`tax-label ${rcmEnabled ? "required" : ""}`}
            >
              Tax {rcmEnabled ? " (Reverse Charge)" : ""}
            </label>
            <InfoTooltip
              title={
                "Add the tax label and amounts to be added to the discounted amount"
              }
            />
          </div>

          {isGstOrg ? (
            <GstItemTax
              item={item}
              itemIndex={index}
              gst_tax={item.gst_tax ? item.gst_tax : null}
              gstTaxErrors={itemFormErrors ? itemFormErrors : undefined}
              organizationId={currentUserInfo.organization_id}
              gstOnChange={(gst, itemExemption) =>
                handleItemGstChange(gst, index, itemExemption)
              }
              disabledFeature={currentUserInfo.disabled_feature}
              clearGstSelection={clearGstSelection}
              supplyState={supplyState}
              isSez={isSez}
              gstRegistrationType={gstRegistrationType}
              updateIsItemEdited={updateIsItemEdited}
              customerDetails={customerDetails}
            />
          ) : (
            <>
              <Itemtax
                itemIndex={index}
                taxes={taxFormValues.taxes}
                taxErrors={itemFormErrors?.taxes}
                organizationId={currentUserInfo.organization_id}
                taxType={taxType}
                taxLabelOnChange={(e, num, taxIndex) => {
                  const data = {
                    target: {
                      name: "tax_id",
                      value: e,
                    },
                  };
                  handleItemTaxChange(data, index, taxIndex);
                }}
                taxValueOnChange={(e, num, taxIndex) =>
                  handleItemTaxChange(e, index, taxIndex)
                }
                deleteItemTax={(taxIndex, id) =>
                  deleteItemTaxContainer(index, taxIndex, id)
                }
                handleTaxOnBlur={handleTaxOnBlur}
                disabledFeature={currentUserInfo.disabled_feature}
                currencyCode={currencyCode}
              />
            </>
          )}
          {IsFeatureEnabled(
            "Tax Configuration",
            currentUserInfo.disabled_feature
          ) &&
            !isGstOrg && (
              <a
                href="#"
                role="button"
                className="add-tags-button"
                onClick={(e) => e.preventDefault()}
              >
                <div
                  className="d-flex align-items-center mx-0"
                  onClick={() => addItemTaxContainer(index)}
                >
                  <img className="add-tax-button" src={AddTagIcon} alt="" />
                  <span className="px-2">Add tax </span>
                </div>
              </a>
            )}
        </div>
        <div className="item-col tax-amt-col w-145 p-0">
          <div className="label-wrapper text-nowrap">
            <label htmlFor="">Taxable Amount</label>
            <InfoTooltip title={"Total Taxable Amount"} />
          </div>
          <div className="d-flex taxable-amount align-items-center justify-content-between w-100 py-2 text-nowrap">
            <span
              className="amount-taxable-nil"
              id={"taxable_amount_span_" + index}
            >
              0.00
            </span>
            <a
              className="delete-taxable delete-btn"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                deleteItemContainer(index);
                // setRemoveItemContainer(!removeItemContainer);
              }}
            >
              <DeleteIcon color={colors.slate} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(LineItem);
