import moment from "moment";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../appSlice";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import {
  DateFormatHandler,
  downloadUsingURL,
  getProfileFormatedDate,
  StatusColor,
} from "../../../../../helpers/helper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { getStatusFilterString } from "../../../../../helpers/statusFilterHelper";
import {
  proformaInvoiceColumnValues,
  proformaInvoiceDefaultValues,
  proformaInvoiceMandatoryValues,
} from "../../../../../helpers/tableCustomizationHelper";
import {
  ObjectType,
  ProformaList,
  Transactionlimit,
} from "../../../../../types";
import {
  dateListFilterSelector,
  dueDateListFilterSelector,
  fiscalYearFilterSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import CustomTooltips from "../../../../common/components/Tooltip";
import TableSetup from "../../../../common/components/table/TableSetup";
import { TAX_SYSTEM } from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import { useCustomizedTable } from "../../../../hooks/useCustomizedTable";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import {
  getProformaPdfLink,
  proformaList,
  sendProformaData,
} from "../../proformaSlice";
import ProformaInvoiceTable from "./ProformaInvoiceTable";
import ProformaListActions from "./ProformaListActions";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, name: string) => void;
};
function ProformaInvoiceTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { customerRolePermission, invoicesRolePermission } =
    usePermissionHandler();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);

  const issueDateListFilter = useAppSelector(dateListFilterSelector);
  const expiryDateListFilter = useAppSelector(dueDateListFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const [proformaInvoiceData, setProformaInvoiceData] = useState<
    ProformaList[]
  >([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["Terms"]);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [proformaInvoiceId, setProformaInvoiceId] = useState(0);
  const [invoiceToPay, setInvoiceToPay] = useState<ObjectType>({});
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();

  useImperativeHandle(
    ref,
    () => ({
      fetchProformaInvoiceList: fetchProformaInvoiceList,
    }),
    []
  );
  const [moduleListItemDates, setModuleListItemDates] = useState({
    date: { startDate: "", endDate: "" },
  });
  useEffect(() => {
    fetchTableCustomizationFields(
      "Proforma Invoices",
      proformaInvoiceDefaultValues,
      proformaInvoiceMandatoryValues
    );
    fetchProformaInvoiceList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    issueDateListFilter,
    expiryDateListFilter,
  ]);

  // useEffect(() => {
  //   // fetchTableCustomizationFields(
  //   //   "Invoices",
  //   //   invoiceDefaultValues,
  //   //   invoiceMandatoryValues
  //   // );
  //   fetchProformaInvoiceList();
  // }, [
  //   page,
  //   itemsPerPage,
  //   dateSortOrder,
  //   currentUserInfo.organization_id,
  //   // selectedFilterData.Organizations,
  //   // selectedFilterData.Status,
  // ]);

  useEffect(() => {
    if (isFilterApplied) fetchProformaInvoiceList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    proformaInvoiceColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    // if (
    //   currentUserInfo.organization_tax_system ===
    //     TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
    //   !selectedColumns.includes("Tax Deducted(TDS)")
    // ) {
    //   hiddenItems.push("Tax Deducted(TDS)");
    // }
    setHiddenColumns([...hiddenItems]);
  }, [proformaInvoiceData, selectedColumns]);

  useEffect(() => {
    // setModuleListItemDates(fiscalYearFilter);
  }, [fiscalYearFilter]);
  useEffect(() => {
    setModuleListItemDates(issueDateListFilter);
  }, [issueDateListFilter]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch all proforma invoices
   */
  const fetchProformaInvoiceList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const customerList = selectedFilterData.hasOwnProperty("Customers")
      ? selectedFilterData?.Customers
      : [];
    const responseAction = await dispatch(
      proformaList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        customerList: customerList,
        issueStartDate: issueDateListFilter.date.startDate || "",
        issueEndDate: issueDateListFilter.date.endDate || "",
        expiryStartDate: expiryDateListFilter.dueDate.startDate || "",
        expiryEndDate: expiryDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setProformaInvoiceData(response.proformas);
        let length = response.proformas.length;
        setTotalList(response.total);
        if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    selectedFilterData,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    // selectedFilterData.Status,    ,
    expiryDateListFilter,
    issueDateListFilter,
  ]);

  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  /**
   * set table column headings
   */
  const getProformaInvoiceColumnValues = () => {
    let newProformaInvoiceColumnValues = JSON.parse(
      JSON.stringify(proformaInvoiceColumnValues)
    );
    // if (
    //   currentUserInfo.organization_tax_system ===
    //     TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
    //   !newProformaInvoiceColumnValues.includes("Tax Deducted(TDS)")
    // ) {
    //   newProformaInvoiceColumnValues.push("Tax Deducted(TDS)");
    // }
    return newProformaInvoiceColumnValues;
  };
  const columns = useMemo(() => {
    let columns = [
      {
        Header: "Issue Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "PI Number",
        accessor: "show.proformaId",
        sort: false,
        sortOrder: "",
        className: "inv_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Customer Name",
        accessor: "show.customerName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value?.customer_name : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "inv_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Expiry Date",
        accessor: selectedColumns.includes("Expiry Date")
          ? "show.expiryDate"
          : "Expiry Date",
        sort: false,
        sortOrder: "",
        className: "due_date",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={`${
                moment(value, "DD-MM-YYYY").isBefore(new Date(), "day")
                  ? "expiry_date"
                  : ""
              }`}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Total",
        accessor: "show.invoiceAmount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Billing Address",
        accessor: selectedColumns.includes("Billing Address")
          ? "show.billingAddress"
          : "Billing Address",
        sort: false,
        sortOrder: "",
        className: "billing_addr",
      },
      {
        Header: "Created By",
        accessor: selectedColumns.includes("Created By")
          ? "show.createdBy"
          : "Created By",
        sort: false,
        sortOrder: "",
        className: "created_by",
      },
      {
        Header: "Shipping Address",
        accessor: selectedColumns.includes("Shipping Address")
          ? "show.shippingAddress"
          : "Shipping Address",
        sort: false,
        sortOrder: "",
        className: "shipping_addr",
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Proforma Invoices"
            tableColumns={selectedColumns}
            customizeColumnValues={getProformaInvoiceColumnValues()}
            mandatoryColumns={proformaInvoiceMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Proforma Invoices",
                proformaInvoiceDefaultValues,
                proformaInvoiceMandatoryValues
              );
              fetchProformaInvoiceList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!invoicesRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ProformaListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id)}
              onClickPayment={() => {
                setOpenModalFlag(true);
                let proformaInvoice = proformaInvoiceData.find(
                  (data) => data.id === value.id
                );
                if (proformaInvoice) setInvoiceToPay(proformaInvoice);
              }}
              onClickDelete={() => {
                if (!value.isMutable) {
                  props.deleteListData(value.id, value.listItemId);
                }
              }}
              fetchProformaInvoiceList={fetchProformaInvoiceList}
            />
          );
        },
      },
    ];
    // if (
    //   currentUserInfo.organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM
    // ) {
    //   let index = columns.length - 1;
    //   columns.splice(index - 1, 0, {
    //     Header: "Tax Deducted(TDS)",
    //     accessor: selectedColumns.includes("Tax Deducted(TDS)")
    //       ? "show.tds"
    //       : "Tax Deducted(TDS)",
    //     sort: false,
    //     sortOrder: "",
    //     className: "tds",
    //   });
    // }
    return columns;
  }, [
    dateSortOrder,
    selectedColumns,
    hiddenColumns,
    currentUserInfo.organization_id,
    // invoicesRolePermission
  ]);
  /**
   * set table row data
   */
  const data = proformaInvoiceData
    ? proformaInvoiceData.map((proformaInvoice) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            proformaInvoice.issue_date
          ),
          proformaId: joinTransactionNumber(proformaInvoice.proforma_number),
          referenceNumber: proformaInvoice.reference_number,
          customerName: proformaInvoice.customer_name,
          status: proformaInvoice.proforma_status,
          expiryDate: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            proformaInvoice.expiry_date
          ),
          invoiceAmount:
            getCurrencyCode(proformaInvoice.currency_code) +
            " " +
            NumberFormat(
              proformaInvoice.total,
              proformaInvoice.currency_code,
              orgCurrencyList
            ),
          balanceDue: proformaInvoice.balance_due
            ? getCurrencyCode(proformaInvoice.currency_code) +
              " " +
              NumberFormat(
                proformaInvoice.balance_due,
                proformaInvoice.currency_code,
                orgCurrencyList
              )
            : getCurrencyCode(proformaInvoice.currency_code) +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  proformaInvoice.currency_code
                    ? proformaInvoice.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          billingAddress: proformaInvoice.billing_address,
          createdBy: proformaInvoice.created_by,
          dueDays: proformaInvoice.due_days,
          email: proformaInvoice.email_to
            .map((emailData) => emailData.email)
            .join(", "),
          shippingAddress: proformaInvoice.shipping_address,
          totalTaxableAmount:
            getCurrencyCode(proformaInvoice.currency_code) +
            " " +
            NumberFormat(
              proformaInvoice.total_taxable_amount,
              proformaInvoice.currency_code,
              orgCurrencyList
            ),
          tds:
            getCurrencyCode(proformaInvoice.currency_code) +
            " " +
            NumberFormat(
              proformaInvoice.tax_deducted,
              proformaInvoice.currency_code,
              orgCurrencyList
            ),
          organization: proformaInvoice.organization,
          actions: {
            id: proformaInvoice.id,
            status: proformaInvoice.proforma_status,
            listItemId: proformaInvoice.proforma_number,
            emailTo: proformaInvoice.email_to,
            isMutable: proformaInvoice.is_mutable,
            organizationId: proformaInvoice.organization_id,
            isLocked: proformaInvoice.is_locked,
            lockDate: proformaInvoice.lock_date,
            balanceDue: proformaInvoice.balance_due,
            currencyCode: proformaInvoice.currency_code,
            writeOffId: proformaInvoice.write_off_id,
            transactionDate: proformaInvoice.issue_date,
            is_fiscal_closed: proformaInvoice.is_fiscal_closed,
            is_invoice_associated: proformaInvoice?.is_invoice_associated,
          },
        },
      }))
    : [];

  /**
   * Set the contact email in a state of selected invoice
   */
  const fetchContactEmail = (id: number, emailList: any[]) => {
    let emails = emailList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setProformaInvoiceId(id);
  };
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Invoice Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * Download Pdf handler
   */
  const downloadPdf = async (proformaInvoiceId: number) => {
    const responseAction = await dispatch(
      getProformaPdfLink({
        proformaId: proformaInvoiceId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          proformaInvoiceId + ".pdf"
        );
        if (result) {
          toast.success("Proforma Invoice downloaded successfully!", {
            toastId: "proforma-invoice-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendProformaData({
        proformaId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchProformaInvoiceList();
      }
    }
    return responseAction;
  };
  return (
    <>
      <ProformaInvoiceTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        invoicesRolePermission={invoicesRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={proformaInvoiceId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(ProformaInvoiceTableContainer));
