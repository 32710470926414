import Select from "react-select";
import { Container } from "../../../../../../common/components/htmlTags/Container";
import { customSelectStyle } from "../../../../../../common/components/SelectCustomStyle";
import { useCommonData } from "../../../../../paymentsReceived/components/paymentReceivedForm/imports";
import "./CurrencyDropdown.css";
import { Span } from "../../../../../../common/components/htmlTags/Span";
type CurrencyDropdownProps = {
  currencyIds: number[];
  currencyId: number;
  currencyCode: string;
  handleCurrencyChange: (e: any, field: string) => void;
  error?: string | undefined;
};
export const CurrencyDropdown = ({
  currencyIds,
  currencyId,
  currencyCode,
  handleCurrencyChange,
  error,
}: CurrencyDropdownProps) => {
  const { orgCurrencyList } = useCommonData();
  /**
   * Get the list of all currencies
   */
  const currencyListOptions = orgCurrencyList
    .filter((currency) => currencyIds.includes(currency.id))
    .map((currency, index) => ({
      label: currency.code,
      value: currency.id,
    }));
  /*
   * Get the currency name in the selection dropdown
   */
  const getName = (listOption: any[], value: any) => {
    return listOption
      .filter((listData) => listData.value === value)
      .map((listData) => ({
        label: listData.label,
        value: listData.value,
      }));
  };
  return (
    <Container className="currency-dropdown-container">
      {" "}
      <Select
        name="currency_id"
        className={`form-select custom-select currency-select ${
          error ? "select-error" : ""
        }`}
        value={{
          value: currencyId,
          label:currencyCode
        }}
        options={currencyListOptions}
        placeholder="Choose currency"
        isSearchable={false}
        onChange={(e) => handleCurrencyChange(e, "currency_id")}
        styles={customSelectStyle}
      />
      {error && <Span className="error">{error}</Span>}
    </Container>
  );
};
