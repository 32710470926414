import { useState } from "react";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import { ObjectType } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import "./exportEmailModalComponent.css";

const ExportEmailModalComponent = (props: any) => {
  const [emailList, setEmailList] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const setEmailHandler = (email: string[]) => {
    setEmailList(email);
  };
  const handleEmailError = (error: string) => {
    setFormErrors({ email: error });
  };
  return (
    <>
      <div className="export-email-modal-wrapper">
        <div>
          <MultiEmailBox
            onChange={setEmailHandler}
            emailList={emailList}
            removeEmailHandler={() => {}}
            onError={handleEmailError}
            className={
              formErrors.email === undefined || formErrors.email === ""
                ? ""
                : "email-error"
            }
          />
          <span className="error">{formErrors.email}</span>
        </div>
        <div className="customize_report_btn_group mt-3">
          <SaveButton
            type="button"
            buttonContent="Submit"
            onClick={() => {
              if(emailList?.length > 0) {
                props.handleSubmit(emailList);
                props.onCloseModal();
              } else {
                setFormErrors({
                  email: "Please enter atleast one email"
                })
              }
            }}
            isDisabled={false}
          />
          <SecondaryButton
            type={"button"}
            buttonContent="Cancel"
            onClick={() => {
              setEmailList([]);
              props.onCloseModal();
            }}
            isDisabled={false}
          />
        </div>
      </div>
    </>
  );
};
export default ExportEmailModalComponent;
