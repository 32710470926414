/**
 * Parses a date range string and formats it to "MMM-YYYY".
 *
 * @param dateRange - The date range in the format "YYYY-MM-DD_YYYY-MM-DD".
 * @returns The formatted date as "MMM-YYYY" or null if input is invalid.
 */
export const formatBudgetDateRange = (dateRange: string): string | null => {
  // Regex to validate the input format "YYYY-MM-DD_YYYY-MM-DD"
  const dateRangeRegex = /^\d{4}-\d{2}-\d{2}_\d{4}-\d{2}-\d{2}$/;

  // Early return if the input doesn't match the expected format
  if (!dateRangeRegex.test(dateRange)) {
    console.error(
      "Invalid date range format. Expected format: YYYY-MM-DD_YYYY-MM-DD"
    );
    return null;
  }

  // Split the input by underscore to separate start and end dates
  const [startDateStr, endDateStr] = dateRange.split("_");

  // Parse the start and end dates to check their validity
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Check if both dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error("Invalid date values provided.");
    return null;
  }

  // Format the start date to "MMM-YYYY" format
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric",
  };

  // Only show date range if months are different
  if (startDate.getMonth() !== endDate.getMonth() || startDate.getFullYear() !== endDate.getFullYear()) {
    return `${startDate.toLocaleDateString("en-US", options).replace(" ", "-")} - ${endDate.toLocaleDateString("en-US", options).replace(" ", "-")}`;
  }
  return startDate.toLocaleDateString("en-US", options).replace(" ", "-");
};
