import React, {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  latestCurrencyRateSelector,
} from "../../../commonSlice";
import { itemDropdown } from "../../../../home/items/itemSlice";
import { InventoryItemValues } from "../../../../../types";

const ItemSelectContext = createContext({
  itemDropdownList: [] as InventoryItemValues[],
  fetchItemDropdown: () => {},
  setCurrencyId: (id: number) => {},
});

type Props = {
  children: React.ReactNode;
  transactionType: string;
  forSubscription: boolean;
};

export const ItemSelectProvider = ({ children, transactionType, forSubscription }: Props) => {
  const [itemDropdownList, setitemDropdownList] = useState<
    InventoryItemValues[]
  >([]);
  const [currencyId, setCurrencyIdState] = useState<number>(0);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currencyId && currentUserInfo.organization_id) {
      fetchItemDropdown();
    }
  }, [currentUserInfo.organization_id, currencyId, exchangeRateValues]);

  const fetchItemDropdown = async () => {
    let newcurrencyId = currencyId
      ? currencyId
      : currentUserInfo.organization_currency;

    let conversionId = exchangeRateValues.id;

    if (currencyId && currentUserInfo.organization_id) {
      let response = await dispatch(
        itemDropdown({
          currencyId: Number(newcurrencyId),
          conversionId: conversionId,
          orgId: currentUserInfo.organization_id,
          transaction_type: transactionType,
          for_subscription: forSubscription
        })
      );
      setitemDropdownList(response.payload);
    }
  };

  const setCurrencyId = (id: number) => {
    setCurrencyIdState(id);
  };

  return (
    <ItemSelectContext.Provider
      value={{
        itemDropdownList: itemDropdownList,
        fetchItemDropdown: fetchItemDropdown,
        setCurrencyId: setCurrencyId,
      }}
    >
      {children}
    </ItemSelectContext.Provider>
  );
};

export const useItemSelect = () => {
  return useContext(ItemSelectContext);
};
