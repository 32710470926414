import React from "react";
import DetailsTableContainerSubscription from "../../detailsPage/DetailsTableContainerSubscription";
import { SubscriptionDetailValues } from "../../../../../types";

type Props = {
  overviewSubscriptionData: SubscriptionDetailValues;
  columnsForPlanAddon: any[];
  currency_code: string;
};
function ItemDetailsSubscription(props: Props) {
  function formatFrequency(frequency: string) {
    if (!frequency) return "";
    let formattedStr = frequency
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStr;
  }

  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }

  return (
    <div className="table-section">
      <div className="table-wrap subscription-invoice-details">
        {props.overviewSubscriptionData.plan_details &&
          props.overviewSubscriptionData.plan_details.length > 0 &&
          props.overviewSubscriptionData?.plan_details?.map((plan) => {
            return (
              <>
                <div className="plan-description">
                  <div
                    style={{
                      fontSize: "larger",
                      marginBottom: "10px",
                    }}
                  >
                    Plans
                  </div>
                  <h6 className="item-bold">{plan.plan_name}</h6>
                  <div className="plan-tag">
                    <span>
                      Frequency: {formatFrequency(plan.frequency)} | Pricing
                      model: {formatFrequency(plan.pricing_model)}
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "#747d84" }}>
                      Invoicing period: {plan.invoicing_period}
                    </span>
                  </div>
                  {!!plan.description ? (
                    <div>
                      <span style={{ color: "#747d84" }}>
                        Description: {plan.description}
                      </span>
                    </div>
                  ) : null}
                </div>
                <DetailsTableContainerSubscription
                  columns={props.columnsForPlanAddon}
                  data={plan.items}
                  currency_code={props.currency_code}
                  haveColumnClassName={true}
                  className="table inv-preview-table subscription-data-view-table"
                  hiddenColumns={["TDS"]}
                  pricingModel={plan.pricing_model}
                  component="invoice"
                />
              </>
            );
          })}
        {props.overviewSubscriptionData.addon_details &&
          props.overviewSubscriptionData.addon_details.length > 0 && (
            <div
              style={{
                fontSize: "larger",
                paddingLeft: "23px",
                paddingTop: "19px",
                marginBottom: "-13px",
              }}
            >
              Addons
            </div>
          )}
        {props.overviewSubscriptionData.addon_details &&
          props.overviewSubscriptionData.addon_details.length > 0 &&
          props.overviewSubscriptionData?.addon_details?.map((addon) => {
            return (
              <>
                <div className="plan-description">
                  <h6 className="item-bold">{addon.addon_name}</h6>
                  <div className="plan-tag">
                    <span>
                      Frequency: {formatFrequency(addon.frequency)} | Pricing
                      model: {formatFrequency(addon.pricing_model)}
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "#747d84" }}>
                      Invoicing period: {addon.invoicing_period}
                    </span>
                  </div>
                  {!!addon.description ? (
                    <div>
                      <span style={{ color: "#747d84" }}>
                        Description: {addon.description}
                      </span>
                    </div>
                  ) : null}
                </div>
                <DetailsTableContainerSubscription
                  columns={props.columnsForPlanAddon}
                  data={addon.items}
                  currency_code={props.currency_code}
                  haveColumnClassName={true}
                  className="table inv-preview-table subscription-data-view-table"
                  hiddenColumns={["TDS"]}
                  pricingModel={addon.pricing_model}
                  component="invoice"
                />
              </>
            );
          })}
        {props.overviewSubscriptionData.charge_details &&
          props.overviewSubscriptionData.charge_details.length > 0 && (
            <div
              style={{
                fontSize: "larger",
                paddingLeft: "23px",
                paddingTop: "19px",
                marginBottom: "-13px",
              }}
            >
              Charges
            </div>
          )}
        {props.overviewSubscriptionData.charge_details &&
          props.overviewSubscriptionData.charge_details.length > 0 &&
          props.overviewSubscriptionData?.charge_details?.map((charge) => {
            return (
              <>
                <div className="plan-description">
                  <h6 className="item-bold">{charge.charge_name}</h6>
                  <div className="plan-tag">
                    <span>
                      Pricing model: {formatFrequency(charge.pricing_model)}
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "#747d84" }}>
                      Invoicing period: {charge.invoicing_period}
                    </span>
                  </div>
                  {!!charge.description ? (
                    <div>
                      <span style={{ color: "#747d84" }}>
                        Description: {charge.description}
                      </span>
                    </div>
                  ) : null}
                </div>
                <DetailsTableContainerSubscription
                  columns={props.columnsForPlanAddon}
                  data={charge.items}
                  currency_code={props.currency_code}
                  haveColumnClassName={true}
                  className="table inv-preview-table subscription-data-view-table"
                  hiddenColumns={["TDS"]}
                  pricingModel={charge.pricing_model}
                  component="invoice"
                />
              </>
            );
          })}
        {props.overviewSubscriptionData.coupon_details &&
          props.overviewSubscriptionData.coupon_details.length > 0 && (
            <div
              style={{
                fontSize: "larger",
                paddingLeft: "23px",
                paddingTop: "19px",
                marginBottom: "-13px",
              }}
            >
              Coupons
            </div>
          )}
        {props.overviewSubscriptionData.coupon_details &&
          props.overviewSubscriptionData.coupon_details.length > 0 &&
          props.overviewSubscriptionData?.coupon_details?.map((coupon) => {
            return (
              <>
                <div className="plan-description coupon-description-container">
                  <div>
                    <h6 className="item-bold">{coupon.name}</h6>
                    <div className="plan-tag">
                      <span>
                        Applicable {convertToNormalText(coupon.duration)}
                      </span>
                    </div>
                  </div>
                  <div className="coupon-discount-amount-container">
                    {coupon.discount}
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
}

export default ItemDetailsSubscription;
