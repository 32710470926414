import { useState, useEffect } from "react";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { vatSettingsValidation } from "./vatSettingValidation";
import useCommonData from "../../../../hooks/useCommon";
import { VatSettingsValues } from "../../../../../types";
import VatSettingsContainer from "./VatSettingContainer";
import { getFormatedDate } from "../../../../../helpers/helper";
import { ErrorImg } from "../../../../../assets/images";
import { toast } from "react-toastify";

import moment from "moment";
import {
  SuccessToaster,
  ErrorToaster,
} from "../../../../../helpers/toastHelper";
import { fetchVatSettings, vatSettingsSave } from "../../vatSlice";
import { useFormik } from "formik";
import {
  currentUserDetails,
  setLoaderState,
} from "../../../../common/commonSlice";
import { message } from "../../../../constants/messages";
import useToast from "../../../../hooks/useToast";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { FormInputFieldItem } from "../../../../common/components/formInputFieldItem/formInputFieldItem";
import { Info } from "@mui/icons-material";
import InfoTooltip from "../../../../common/components/InfoTooltip";

const VatSettings = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { isToastExistById } = useToast();
  const [formValues, setFormValues] = useState<VatSettingsValues>({
    vat_enabled: "No",
    tax_registration_number: "",
  });
  const [transactionExist, setTransactionExist] = useState(false);
  const { gstPermission } = usePermissionHandler();

  useEffect(() => {
    getVatSettings();
  }, [currentUserInfo.organization_id]);

  const getVatSettings = async () => {
    try {
      dispatch(setLoaderState(true));
      let response = await dispatch(
        fetchVatSettings({
          orgId: currentUserInfo.organization_id,
        })
      );
      let data: any = response.payload;

      setFormValues((formValues) => {
        return {
          ...formValues,
          vat_enabled: data?.vat_enabled ? "Yes" : "No",
          tax_registration_number: data?.tax_registration_number,
        };
      });
      setTransactionExist(data?.transaction_exist);
      dispatch(setLoaderState(false));
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: vatSettingsValidation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      let responseAction = await dispatch(
        vatSettingsSave({
          orgId: currentUserInfo.organization_id,
          values: values,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(message().vatSettingUpdateSuccess, "vat-success-toast");
        await dispatch(
          currentUserDetails({
            orgId: Number(sessionStorage.getItem("orgId"))
              ? Number(sessionStorage.getItem("orgId"))
              : 0,
            isOrgChanged: false,
          })
        );
      }
      // setting last saved data as initial formValues and form will reset with this values when user click on cancel button
      setFormValues((formValues) => {
        return {
          ...formValues,
          vat_enabled: values?.vat_enabled,
          tax_registration_number:
            values?.vat_enabled === "Yes"
              ? values?.tax_registration_number
              : "",
        };
      });

      formik.setFieldTouched("gst", false, false);
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  const gstEnabledChangeHandler = (val: string) => {
    if (val === "No" && transactionExist) {
      ErrorToaster(message().vatTransactionExists);
    } else {
      formik.setFieldValue("vat_enabled", val);
    }
  };

  const trnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^-?(\d+|\d*\.\d+)?$/; // Modify if you want stricter validation
    const newValue = e.target.value;

    if (transactionExist && !isToastExistById("trn-edit-transaction-exist")) {
      toast.error(message().trnTransactionExist, {
        icon: ({ theme, type }) => (
          <img src={ErrorImg} width="20px" height="18px" alt="errorImage" />
        ),
        closeButton: false,
        toastId: "trn-edit-transaction-exist",
        className: "toast-error",
        position: "top-center",
      });
    } else {
      if (regex.test(newValue)) {
        formik.setFieldValue("tax_registration_number", newValue);
      }
    }
  };
  return (
    <>
      <VatSettingsContainer heading="VAT Settings">
        <form
          action=""
          name="vat-settings-form"
          id="vat-settings-form"
          className="vat-settings-form w-100"
          onSubmit={formik.handleSubmit}
        >
          <div className="fields-wrapper">
            <section className="form-fields-section">
              <div className="form-fields">
                <div className="row g-0">
                  <div className="col">
                    <span className="vat-enable">
                      Is your business registered for VAT?
                    </span>
                    <RadioButtonGroup
                      name="vat_enabled"
                      id="vat_enabled"
                      valuesArray={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      value={formik.values.vat_enabled}
                      icon={
                        <span>
                          <RadioButtonUncheckedIcon />
                        </span>
                      }
                      checkedIcon={
                        <span>
                          <RadioButtonCheckedIcon className="radio-btn-tds" />
                        </span>
                      }
                      isDisabled={false}
                      // isDisabled={
                      //   !(
                      //     gstPermission.includes("Create") &&
                      //     gstPermission.includes("Update") &&
                      //     gstPermission.includes("Delete") &&
                      //     gstPermission.includes("Read")
                      //   )
                      // }
                      onClick={gstEnabledChangeHandler}
                    />
                  </div>
                </div>
                {formik.values.vat_enabled === "Yes" && (
                  <>
                    <div className="row g-0">
                      <div className="col input-field-wrapper">
                        <div>
                          <label htmlFor="trn" className="required">
                            Tax Registration Number (TRN)
                          </label>
                          <InfoTooltip title="The TRN will be used in all your transactions and for filing your Tax returns." />
                        </div>
                        {/* <span>(Maximum 15 digits)</span> */}
                        <FormInputFieldItem
                          type="text"
                          name="trn"
                          id="trn"
                          className={`form-control ${
                            formik.errors.tax_registration_number &&
                            formik.touched.tax_registration_number
                              ? "error"
                              : ""
                          } mw-325`}
                          // placeholder="Ex:22AAAAA0000A1Z5"\
                          placeholder="Tax Registration Number (TRN)"
                          value={formik.values.tax_registration_number}
                          onChange={trnChangeHandler}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.tax_registration_number &&
                            formik.errors.tax_registration_number
                              ? formik.errors.tax_registration_number
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
          <section className="form-button-section">
            <div
              className="button-fields form-button-wrapper"
              id="form-btn-vat-profile"
            >
              <button
                className="save-button"
                type="submit"
                disabled={
                  formik.isSubmitting
                  // ||
                  // !(
                  //   gstPermission.includes("Create") &&
                  //   gstPermission.includes("Update") &&
                  //   gstPermission.includes("Delete") &&
                  //   gstPermission.includes("Read")
                  // )
                }
              >
                Save
              </button>
              <button
                className="text-decoration-none cancel-button close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => formik.resetForm()}
              >
                Cancel
              </button>
            </div>
          </section>
        </form>
      </VatSettingsContainer>
    </>
  );
};

export default VatSettings;
