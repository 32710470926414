export const initialCustomerValues = {
  company_name: "",
  customer_email: "",
  customer_phone: "",
  pan_number: "",
  gst_registration_type: "",
  gstin: "",
  opening_balance: "",
  custom_fields: {},
  customer_display_name: "",
  currency_id: "",
  short_code: "",
  payment_term_id: "",
  tag_ids: [],
  tax_preference: "",
  exemption_reason_id: 0,
  exemption_reason_name: "",
  is_contact_org_closed: false,
  sales_account_id: null,
  discount_account_id: null,
  discount_type: "Absolute",
  discount: "",
  exclude_discount_accounting: false,
};
export const defaultValues = {
  company_name: "",
  customer_display_name: "",
  customer_email: "",
  customer_phone: "",
  short_code: "",
};
export const otherDetailsValue = {
  tag_ids: [],
  payment_term_id: "",
  currency_id: "",
  gst_registration_type: "",
  gstin: "",
  pan_number: "",
  opening_balance: "",
  tax_preference: "",
  exemption_reason_id: 0,
  exemption_reason_name: "",
};
export let initialContact = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  is_primary: true,
};
