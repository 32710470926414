import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { TransactionEditTagRemovalAlertModal } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";
import { setDateRange } from "../../../reports/ReportSlice";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { validate, validateItemValues } from "./ValidatePurchaseOrderForm";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import { useParams, useSearchParams } from "react-router-dom";
import { vendorDetailsForTransactions } from "../../../vendorManagement/vendorSlice";
import {
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import {
  initialTCSTotalValues,
  initialTDSTotalValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";
import {
  DateFormatHandler,
  getFormatedDate,
  getKeyByValue,
} from "../../../../../helpers/helper";
import {
  noAccessErrorCodes,
  sourceTaxTypes,
  tdsApplyLevel,
  gstRegistrationTypes,
} from "../../../../constants/constants";
import {
  clearGstTaxList,
  getGstTaxList,
  getStateList,
  stateListSelector,
} from "../../../gst/gstSlice";
import {
  initialDefaultPurchaseOrderValues,
  initialItemValues,
  initialPurchaseOrderValues,
  initialTaxValues,
  initialTotalValues,
} from "./StateInitialization";
import {
  ObjectType,
  PurchaseOrderItemValues,
  ItemTaxValues,
  FilesValues,
  TotalDetailsValues,
  TotalAmountCalculationValues,
  CustomerItemValues,
  ItemRef,
  TransactionItemValues,
  TDSTotalValues,
  TCSTotalValues,
  PurchaseOrderDefaultValues,
  PurchaseOrderValues,
  PurchaseOrderDefaultRef,
} from "../../../../../types";
import {
  createPurchaseOrder,
  editPurchaseOrderDetails,
  deletePurchaseOrderItemDetails,
  checkPurchaseOrderNumber,
  purchaseOrderDetails,
  attachPurchaseOrderFiles,
  purchaseOrderList,
  deletePurchaseOrderFile,
  deletePurchaseOrderRecipientDetails,
  getAmountCalculation,
  getTaxableAmount,
  deletePurchaseOrderItemTaxDetails,
} from "../../purchaseOrderSlice";
import useCommonData from "../../../../hooks/useCommon";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import DefaultDetails from "./DefaultDetails";
import TransactionHeader from "../../../../common/components/TransactionHeader";
import ScrollToError from "../../../../common/components/scrollToError";
import PurchaseOrderFooter from "./PurchaseOrderFormFooter";
import useGst from "../../../../hooks/useGst";
import moment from "moment";
import DeletePurchaseOrder from "../Delete";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import ItemDetails from "../../../../common/components/lineItems/ItemDetails";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import DropZone from "../../../../common/components/DropZone/DropZone";
import _ from "lodash";
import "../../PurchaseOrder.css";

type LocationProps = {
  redirectTo?: string;
};
let interval: any = null;
function PurchaseOrderForm() {
  const location = usePreservedLocation();
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { purchaseOrderRolePermission } = usePermissionHandler();
  const defaultRef = useRef<PurchaseOrderDefaultRef | null>(null);
  const itemRef = useRef<ItemRef | null>(null);
  const fileRef = useRef<any>([]);
  const locationState = location?.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const purchaseOrderId = searchParams.get("purchaseOrderId");
  const [purchaseOrderFormValues, setPurchaseOrderFormValues] =
    useState<PurchaseOrderValues>(initialPurchaseOrderValues);
  const [defaultFormValues, setDefaultFormValues] =
    useState<PurchaseOrderDefaultValues>(
      JSON.parse(JSON.stringify(initialDefaultPurchaseOrderValues))
    );
  const [itemFormValues, setItemFormValues] = useState<
    PurchaseOrderItemValues[]
  >([initialItemValues]);
  const [taxFormValues, setTaxFormValues] = useState<PurchaseOrderItemValues[]>(
    [initialItemValues]
  );
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDetailsValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [itemFormErrors, setItemFormErrors] = useState<
    PurchaseOrderItemValues[]
  >([]);
  const [emailFormErrors, setEmailFormErrors] = useState("");
  const [isPurchaseOrderNumberExist, setIsPurchaseOrderNumberExist] =
    useState(false);
  const [statesFlag, setStatesFlag] = useState<boolean>(false);
  const [isVendorName, setIsVendorName] = useState<boolean>(false);
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [vendorId, setVendorId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emaiId: number }[]>(
    []
  );
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [deletePurchaseOrderItemId, setDeletePurchaseOrderItemId] =
    useState("");
  const [deletePurchaseOrderId, setDeletePurchaseOrderId] = useState(-1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const [formSubmit, setFormSubmit] = useState(true);
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [conversionDate, setConversionDate] = useState(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [purchaseOrderStatus, setPurchaseOrderStatus] = useState("");
  const [vendorIdForEdit, setVendorIdForEdit] = useState();
  const [defaultValuesForEdit, setDefaultValuesForEdit] =
    useState<PurchaseOrderValues>(initialPurchaseOrderValues);
  const [conversionCurrencyId, setConversionCurrencyId] = useState<number>();
  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [sourceTaxType, setSourceTaxType] = useState(sourceTaxTypes.TCS);
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [isTotalEditable, setIsTotalEditable] = useState(false);
  const [editTotal, setEditTotal] = useState(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);
  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [unableToSaveModalTriggered, setUnableToSaveModalTriggered] =
    useState<number>();
  const [gstRegistrationType, setGstRegistrationType] = useState("");

  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();
  const unableToSaveModalRef = useRef<any>();
  const updateExchangeRateModalRef = useRef<any>();
  const { isGstOrg } = useGst();
  const stateList = useAppSelector(stateListSelector);
  const [isVendorChanged, setIsVendorChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const openEditAlertRef = useRef<any>();
  const [vendorPurchaseDefault, setVendorPurchaseDefault] = useState(null);
  const [rcmEnabled, setRcmEnabled] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId) {
      getRoundOffPreference();
    }
    if (purchaseOrderId) {
      getPODetails(Number(purchaseOrderId));
    }
    if (editId) {
      purchaseOrderDetailsResponse();
    }
    if (defaultFormValues.vendor_id) {
      getVendorDetails(Number(defaultFormValues.vendor_id));
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0 && files.status !== "removed"
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    unableToSaveModalTriggered &&
      unableToSaveModalRef.current?.openAlertModal &&
      unableToSaveModalRef.current?.openAlertModal();
  }, [unableToSaveModalTriggered]);

  const getPODetails = async (purchaseOrderId: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      purchaseOrderDetails({
        orgId: currentUserInfo.organization_id,
        purchaseOrderId: purchaseOrderId,
      })
    );
    const response = responseAction.payload;
    const isSameOrg =
      response.organization_id === currentUserInfo.organization_id;
    const isLocked = response.is_locked;
    if (Object.keys(response).length && !("error" in response)) {
      let defaultValues = {
        vendor_id: response.vendor_id,
        vendor_name: response.vendor_name,
        purchase_order_number: response.purchase_order_number,
        reference_number: response.reference_number,
        issue_date: getFormatedDate(response.issue_date),
        payment_term_id: response.payment_term_id,
        delivery_date: getFormatedDate(response.delivery_date),
        currency_id: response.currency_id,
        tds_level: response.tds_level,
        source_tax_type: response.source_tax_type,
        source_of_supply: {
          label: response.source_of_supply,
          value: response.source_of_supply,
        },
        destination_of_supply: {
          label: response.destination_of_supply,
          value: response.destination_of_supply,
        },
        purchase_order_status: response.purchase_order_status,
        is_mutable: response.is_mutable,
        rcm_enabled: response.rcm_enabled ? response.rcm_enabled : false,
        conversion_id: response.conversion_id,
      };

      let stateType =
        defaultValues.source_of_supply.value ===
        defaultValues.destination_of_supply.value
          ? "Intra State"
          : "Inter State";
      setInitialStateType(stateType);
      setRcmEnabled(response.rcm_enabled);
      let vendorDetails = await getVendorDetails(Number(response.vendor_id));

      if (isGstOrg) {
        let transaction_tax_details = [] as any;
        response.item_details.forEach((item: any) => {
          let gst_tax = item.gst_tax;
          if (gst_tax?.tax_id) {
            transaction_tax_details.push({
              is_group: gst_tax?.is_group,
              tax_id: gst_tax?.tax_id,
            });
          }
        });
        let state_type =
          !defaultValues.source_of_supply ||
          !defaultValues.destination_of_supply
            ? ""
            : defaultValues.source_of_supply.value ===
              defaultValues.destination_of_supply.value
            ? "Intra State"
            : "Inter State";
        setStateType(state_type);
        if (
          vendorDetails.gst_registration_type === gstRegistrationTypes.sez ||
          vendorDetails.gst_registration_type ===
            gstRegistrationTypes.sezDeveloper ||
          vendorDetails.gst_registration_type === gstRegistrationTypes.overseas
        ) {
          state_type = "Inter State";
        }

        let stateCode = await getStateCode(
          defaultValues.destination_of_supply.value
        );

        await fetchGstTaxList(
          state_type,
          vendorDetails.gst_registration_type,
          transaction_tax_details,
          stateCode
        );
        setGstRegistrationType(vendorDetails.gst_registration_type);
        setVendorPurchaseDefault(vendorDetails.purchase_default);
      }

      setSourceTaxType(response.source_tax_type);
      let otherValues = {
        vendor_notes: response.vendor_notes,
        file_ids: response.purchase_order_files?.map((file: any) => file.id),
        items: response.item_details,
        email_to: response.email_to,
        conversion_id: response.conversion_id,
        currency_id: response.currency_id,
        tds_percentage: response.total_details.tds_percentage,
        exclude_discount_accounting: vendorDetails.purchase_default
          .exclude_discount_accounting
          ? vendorDetails.purchase_default.exclude_discount_accounting
          : false,
      };
      let totalTdsValue = {
        tds_id: response.tds_id,
        tds_account_id: response.tds_account_id,
        tds_percentage: response.tds_percentage,
        tds_amount: response.tds_amount,
        tds_name: response.tds_name,
      };
      let totalTcsValue = {
        tcs_id: response.tcs_id,
        tcs_account_id: response.tcs_account_id,
        tcs_percentage: response.tcs_percentage,
        tcs_amount: response.tcs_amount,
        tcs_name: response.tcs_name,
      };
      setTotalTdsValues(totalTdsValue);
      setTotalTcsValues(totalTcsValue);
      setConversionDate(getFormatedDate(response.issue_date));
      setCurrencyId(response.currency_id);
      setCustomFields(response.custom_fields);
      custom_fields.current = response.custom_fields;
      setConversionCurrencyId(response.currency_id);
      setUploadedFiles(response.purchase_order_files);
      setFileIds(response.purchase_order_files.map((file: any) => file.id));
      setInsertedFiles(
        response.purchase_order_files.map((file: any) => file.id)
      );
      setPurchaseOrderStatus(response.purchase_order_status);
      setVendorIdForEdit(response.vendor_id);
      setDefaultValuesForEdit({ ...defaultValues, ...otherValues });

      if (response.payment_term_id === null) {
        setIsCustom(true);
      }
      setCurrencyCode(response.currency_code);
      setDefaultFormValues({ ...defaultValues });
      setPurchaseOrderFormValues({ ...defaultValues, ...otherValues });

      const preferenceResponse = await dispatch(
        fetchRoundOffPreference({
          orgId: currentUserInfo.organization_id,
          roundoff_transaction_type: "Purchases",
        })
      );
      let preferencePayload = preferenceResponse.payload;

      setIsRoundOffEnabled((prevValue) => {
        return response?.roundoff_enabled || preferencePayload.is_enabled
          ? true
          : false;
      });

      setIsTotalEditable((prevValue) => {
        if (response?.roundoff_enabled || preferencePayload.is_enabled) {
          if (response?.roundoff_enabled) {
            return response?.is_total_editable;
          } else {
            return preferencePayload.is_total_editable;
          }
        } else {
          return false;
        }
      });

      let itemDetails = response.item_details;
      let tdsIdArray: number[] = [];
      itemDetails.map((item: any, index: number) => {
        if (item.discount_type === "Absolute") {
          item.discount = item.discount.discount_value;
        } else if (item.discount_type === "Percentage") {
          item.discount = item.discount.discount_percentage;
        }
        if (!item.taxes.length) {
          item.taxes = [initialTaxValues];
        }
        if (item.tds_id) {
          tdsIdArray.push(item.tds_id);
        }
      });
      if (itemDetails.length) {
        setItemFormValues([...itemDetails]);
        setTaxFormValues(itemDetails);
      } else {
        setItemFormValues([initialItemValues]);
        setTaxFormValues([initialItemValues]);
      }
      if (response.tds_id) {
        tdsIdArray.push(response.tds_id);
      }
      setTdsIds(tdsIdArray);
      let tcsIdArray: number[] = [];
      if (response.tcs_id) {
        tcsIdArray.push(response.tcs_id);
      }
      setTcsIds(tcsIdArray);
      let totalDetails = response.total_details;
      setTotalFormValues(totalDetails);
      let emailContacts = response.email_to;
      let contactEmail: string[] = [];
      let emailDetails: any[] = [];
      emailContacts.map((contact: any) => {
        contactEmail.push(contact.email);
        emailDetails.push(contact);
      });
      setEmailLists(contactEmail);
      setEmailData(emailDetails);
      setVendorId(response.vendor_id);
      setEditTotal(response?.total);
    } else {
      if (isLocked) {
        navigate(-1);
        ErrorToaster(
          `Transactions before ${DateFormatHandler(
            response.lock_date
          )} have been locked. Hence action cannot be performed`
        );
      } else {
        dispatch(setErrorID(noAccessErrorCodes.billEdit));
      }
    }
  };

  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
        roundoff_transaction_type: "Purchases",
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  /**
   * Get purchaseOrder details for the edit purchaseOrder purpose.
   */
  const purchaseOrderDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      purchaseOrderDetails({
        purchaseOrderId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      const isLocked = response.is_locked;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg &&
        !isLocked
      ) {
        let defaultValues = {
          vendor_id: response.vendor_id,
          vendor_name: response.vendor_name,
          purchase_order_number: response.purchase_order_number,
          reference_number: response.reference_number,
          issue_date: getFormatedDate(response.issue_date),
          payment_term_id: response.payment_term_id,
          delivery_date: getFormatedDate(response.delivery_date),
          currency_id: response.currency_id,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          source_of_supply: {
            label: response.source_of_supply,
            value: response.source_of_supply,
          },
          destination_of_supply: {
            label: response.destination_of_supply,
            value: response.destination_of_supply,
          },
          purchase_order_status: response.purchase_order_status,
          is_mutable: response.is_mutable,
          rcm_enabled: response.rcm_enabled ? response.rcm_enabled : false,
          conversion_id: response.conversion_id,
        };
        let stateType =
          defaultValues.source_of_supply.value ===
          defaultValues.destination_of_supply.value
            ? "Intra State"
            : "Inter State";
        setInitialStateType(stateType);
        setRcmEnabled(response.rcm_enabled);
        let vendorDetails = await getVendorDetails(Number(response.vendor_id));

        if (isGstOrg) {
          let transaction_tax_details = [] as any;
          response.item_details.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group,
                tax_id: gst_tax?.tax_id,
              });
            }
          });
          let state_type =
            !defaultValues.source_of_supply ||
            !defaultValues.destination_of_supply
              ? ""
              : defaultValues.source_of_supply.value ===
                defaultValues.destination_of_supply.value
              ? "Intra State"
              : "Inter State";
          setStateType(state_type);
          if (
            vendorDetails.gst_registration_type === gstRegistrationTypes.sez ||
            vendorDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            vendorDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            state_type = "Inter State";
          }

          let stateCode = await getStateCode(
            defaultValues.destination_of_supply.value
          );

          await fetchGstTaxList(
            state_type,
            vendorDetails.gst_registration_type,
            transaction_tax_details,
            stateCode
          );
          setGstRegistrationType(vendorDetails.gst_registration_type);
          setVendorPurchaseDefault(vendorDetails.purchase_default);
        }
        setSourceTaxType(response.source_tax_type);
        let otherValues = {
          vendor_notes: response.vendor_notes,
          file_ids: response.purchase_order_files.map((file: any) => file.id),
          items: response.item_details,
          email_to: response.email_to,
          conversion_id: response.conversion_id,
          currency_id: response.currency_id,
          tds_percentage: response.total_details.tds_percentage,
          exclude_discount_accounting: vendorDetails.purchase_default
            .exclude_discount_accounting
            ? vendorDetails.purchase_default.exclude_discount_accounting
            : false,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name,
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name,
        };
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setConversionDate(getFormatedDate(response.issue_date));
        setCurrencyId(response.currency_id);
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setConversionCurrencyId(response.currency_id);
        setUploadedFiles(response.purchase_order_files);
        setFileIds(response.purchase_order_files.map((file: any) => file.id));
        setInsertedFiles(
          response.purchase_order_files.map((file: any) => file.id)
        );
        setPurchaseOrderStatus(response.purchase_order_status);
        setVendorIdForEdit(response.vendor_id);
        setDefaultValuesForEdit({ ...defaultValues, ...otherValues });

        if (response.payment_term_id === null) {
          setIsCustom(true);
        }
        setCurrencyCode(response.currency_code);
        setDefaultFormValues({ ...defaultValues });
        setPurchaseOrderFormValues({ ...defaultValues, ...otherValues });

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Purchases",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });

        let itemDetails = response.item_details;
        let tdsIdArray: number[] = [];
        itemDetails.map((item: any, index: number) => {
          if (item.discount_type === "Absolute") {
            item.discount = item.discount.discount_value;
          } else if (item.discount_type === "Percentage") {
            item.discount = item.discount.discount_percentage;
          }
          if (!item.taxes.length) {
            item.taxes = [initialTaxValues];
          }
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (itemDetails.length) {
          setItemFormValues([...itemDetails]);
          setTaxFormValues(itemDetails);
        } else {
          setItemFormValues([initialItemValues]);
          setTaxFormValues([initialItemValues]);
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
        }
        setTdsIds(tdsIdArray);
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
        }
        setTcsIds(tcsIdArray);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.email_to;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setVendorId(response.vendor_id);
        setEditTotal(response?.total);
        handleExpiryDatePopupation(response.issue_date, response.delivery_date);
      } else {
        if (isLocked) {
          navigate(-1);
          ErrorToaster(
            `Transactions before ${DateFormatHandler(
              response.lock_date
            )} have been locked. Hence action cannot be performed`
          );
        } else {
          dispatch(setErrorID(noAccessErrorCodes.POEdit));
        }
      }
    }
  };

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [purchaseOrderFormValues, statesFlag]);

  /**
   * Check purchase order number already exist
   */
  const checkPurchaseOrderNumberExist = async (purchaseOrderNumber: string) => {
    if (!purchaseOrderNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkPurchaseOrderNumber({
            purchaseOrderNumber: purchaseOrderNumber,
            orgId: currentUserInfo.organization_id,
            purchaseOrderId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.purchase_order_number_available === false) {
              setIsPurchaseOrderNumberExist(true);
              setFormErrors({
                ...formErrors,
                ["purchase_order_number"]:
                  "Purchase order number already exists. Please choose a different one",
              });
            } else {
              setIsPurchaseOrderNumberExist(false);
              setFormErrors({ ...formErrors, ["purchase_order_number"]: "" });
            }
          }
        }
      }, 1000);
    }
  };
  /**
   * Set purchase order default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPurchaseOrderFormValues({ ...purchaseOrderFormValues, [name]: value });
  };
  /**
   * Function to handle change in vendor to fetch vendor details
   */
  const handleVendorChange = async (e: any) => {
    if (e.value && e.value !== vendorIdForEdit) {
      setIsVendorChanged(true);
      let response = await getVendorDetails(Number(e.value));
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }
      if (state_type !== "") {
        await fetchGstTaxList(state_type, response.gst_registration_type);
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsVendorName(true);
      setVendorId(Number(e.value));
      setVendorPurchaseDefault(response.purchase_default);
      setDefaultFormValues((previousValues: any) => {
        return {
          ...previousValues,
          payment_term_id: response.purchase_default.payment_term_id,
        };
      });
    } else {
      setPurchaseOrderFormValues(defaultValuesForEdit);
      setCurrencyId(Number(defaultValuesForEdit.currency_id));
    }
  };
  /**
   * Fetch vendor details by id
   */
  const getVendorDetails = async (vendorId: number) => {
    const responseAction = await dispatch(
      vendorDetailsForTransactions({
        vendorId: vendorId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (defaultRef.current?.defaultData) {
          let defaultFormValues = {
            ...defaultRef.current?.defaultData,
            payment_term_id: response.other_details.payment_term_id,
            currency_id: response.other_details.currency_id,
            exclude_discount_accounting: response.purchase_default
              .exclude_discount_accounting
              ? response.purchase_default.exclude_discount_accounting
              : false,
          };
          setVendorPurchaseDefault(response.purchase_default);
          setDefaultFormValues({ ...defaultFormValues });
        }
        if (response.other_details.payment_term_id)
          defaultRef.current?.calculateDueDate(
            response.other_details.payment_term_id,
            new Date(defaultRef.current?.defaultData.issue_date)
          );
        setCurrencyId(response.other_details.currency_id);
      }
    }
    return responseAction.payload;
  };

  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };

  const asynchronousSubmit = async (status: string, action: string) => {
    await handleCustomSubmission();
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Vendors")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (itemRef.current?.totalTdsValues.tds_id || itemDetails?.length) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string) => {
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let purchaseOrderInputs: any = {
      ...purchaseOrderFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      custom_fields: custom_fields.current.values,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    let errors,
      itemErrorResponse: any = [];
    let itemErrors: any = [];
    let emailError: string = emailFormErrors ? emailFormErrors : "";

    errors = validate(
      purchaseOrderInputs,
      action,
      isPurchaseOrderNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;
    let taxValues = itemRef.current?.taxValues
      ? itemRef.current?.taxValues
      : taxFormValues;
    itemValues.map((item, itemIndex) => {
      itemErrorResponse = validateItemValues(
        item,
        itemIndex,
        taxValues,
        purchaseOrderInputs.rcm_enabled,
        gstRegistrationType,
        purchaseOrderInputs?.exclude_discount_accounting
      );
      if (itemErrorResponse) {
        if (itemErrorResponse) {
          ScrollToError(Object.keys(itemErrorResponse)[0]);
        }
        errorExist = true;
        itemErrors.push(itemErrorResponse);
      } else {
        itemErrors.push({});
      }
    });

    if (
      Object.keys(errors).length ||
      (errorExist && itemErrors.length) ||
      customFieldRef.current.errors ||
      emailFormErrors !== ""
    ) {
      setFormErrors({
        ...errors,
        email: emailFormErrors,
      });
      setItemFormErrors(itemErrors);
    } else {
      setFormErrors({});
      setItemFormErrors([]);
      if (editId) {
        // if (editId && currentUserInfo.is_tags_enabled && !didAlertModalOpen) {
        //   setDidAlertModalOpen(true);
        //   openEditAlertRef.current?.openAlertModal &&
        //     openEditAlertRef.current?.openAlertModal();
        //   return;
        // } else {
        UpdatePurchaseOrderSubmit(
          purchaseOrderInputs,
          itemValues,
          status,
          action
        );
        // }
      } else {
        createPurchaseOrderSubmit(
          purchaseOrderInputs,
          itemValues,
          status,
          action
        );
      }
    }
  };

  /**
   * Create new purchase order
   */
  const createPurchaseOrderSubmit = async (
    purchaseOrderInputs: any,
    itemValues: TransactionItemValues[],
    purchaseOrderStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    purchaseOrderInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof purchaseOrderInputs;
    for (key in purchaseOrderInputs) {
      if (purchaseOrderInputs[key] === "") {
        purchaseOrderInputs[key] = null;
      }
      if (
        key === "purchase_order_number" &&
        purchaseOrderInputs[key] !== "" &&
        purchaseOrderInputs[key] !== null
      ) {
        purchaseOrderInputs[key] = defaultRef.current?.purchaseOrderNumber;
      }
      if (key === "tds_percentage") {
        purchaseOrderInputs[key] =
          purchaseOrderInputs[key] === "" || purchaseOrderInputs[key] === null
            ? 0
            : Number(purchaseOrderInputs[key]);
      }
      if (key === "reference_number" && purchaseOrderInputs[key]) {
        purchaseOrderInputs[key] = purchaseOrderInputs[key].toString().trim();
      }
      if (key === "destination_of_supply") {
        purchaseOrderInputs[key] = isGstOrg
          ? purchaseOrderInputs[key].value
          : null;
      }
      if (
        key === "source_of_supply" &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        purchaseOrderInputs[key] = isGstOrg
          ? purchaseOrderInputs[key].value
          : null;
      } else if (
        key === "source_of_supply" &&
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        purchaseOrderInputs[key] = null;
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        purchaseOrderInputs[key] =
          purchaseOrderInputs[key] === null ? 0 : purchaseOrderInputs[key];
      }
    }
    let purchaseOrderItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of purchaseOrderItems) {
      let itemInputs = items;

      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    purchaseOrderInputs.items = purchaseOrderItems;
    purchaseOrderInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      purchaseOrderInputs.email_to = emailLists;
    }
    purchaseOrderInputs.total = calculatedTotal;
    purchaseOrderInputs.roundoff_enabled = isRoundOffEnabled;
    purchaseOrderInputs.is_total_editable = isTotalEditable;

    $("#form-btn-invoice").addClass("customer-form-section-disable");
    const createPurchaseOrderResponseAction = await dispatch(
      createPurchaseOrder({
        values: purchaseOrderInputs,
        status: purchaseOrderStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const createPurchaseOrderResponse =
      createPurchaseOrderResponseAction.payload;
    if (
      Object.keys(createPurchaseOrderResponse).length &&
      !("error" in createPurchaseOrderResponse)
    ) {
      dispatch(setLoaderState(false));
      let purchaseOrderId = createPurchaseOrderResponse.id;
      if (purchaseOrderStatus === "Draft") navigate(-1);
      else
        navigate(`/purchase-order/detail/${purchaseOrderId}`, {
          replace: true,
        });
      toast.success("Purchase Order created successfully!", {
        toastId: "purchase-order-create-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in createPurchaseOrderResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const UpdatePurchaseOrderSubmit = async (
    purchaseOrderInputs: any,
    itemValues: TransactionItemValues[],
    purchaseOrderStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    purchaseOrderInputs.conversion_id = exchangeRateValues.id;
    if (
      defaultRef.current?.defaultData?.conversion_id !== exchangeRateValues.id
    ) {
      purchaseOrderInputs.recalculate_exchange_rate = true;
    }
    let key: keyof typeof purchaseOrderInputs;
    for (key in purchaseOrderInputs) {
      if (purchaseOrderInputs[key] === "") {
        purchaseOrderInputs[key] = null;
      }
      if (key === "payment_term_id" && purchaseOrderInputs[key] !== "") {
        if (purchaseOrderInputs[key] === null) {
          purchaseOrderInputs[key] = null;
        } else {
          purchaseOrderInputs[key] = Number(purchaseOrderInputs[key]);
        }
      }
      if (
        key === "purchase_order_number" &&
        purchaseOrderInputs[key] !== "" &&
        purchaseOrderInputs[key] !== null
      ) {
        purchaseOrderInputs[key] = defaultRef.current?.purchaseOrderNumber;
      }
      if (key === "tds_percentage") {
        purchaseOrderInputs[key] =
          purchaseOrderInputs[key] === "" || purchaseOrderInputs[key] === null
            ? 0
            : Number(purchaseOrderInputs[key]);
      }
      if (key === "reference_number" && purchaseOrderInputs[key]) {
        purchaseOrderInputs[key] = purchaseOrderInputs[key].toString().trim();
      }
      if (key === "destination_of_supply") {
        purchaseOrderInputs[key] = isGstOrg
          ? purchaseOrderInputs[key].value
          : null;
      }
      if (
        key === "source_of_supply" &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        purchaseOrderInputs[key] = isGstOrg
          ? purchaseOrderInputs[key].value
          : null;
      } else if (
        key === "source_of_supply" &&
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        purchaseOrderInputs[key] = null;
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        purchaseOrderInputs[key] =
          purchaseOrderInputs[key] === null ? 0 : purchaseOrderInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    let deleteTaxItems = itemRef.current?.deleteTaxItems;
    if (deleteTaxItems) {
      for (let item of deleteTaxItems) {
        await dispatch(
          deletePurchaseOrderItemTaxDetails({
            itemId: item.itemId,
            taxId: item.taxItemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    if (isGstOrg) {
      let deleteGstItems = itemRef.current?.deleteGstItems;
      if (deleteGstItems) {
        for (let item of deleteGstItems) {
          await dispatch(
            deletePurchaseOrderItemTaxDetails({
              itemId: item.itemId,
              taxId: item.id,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
    }

    let deleteItems = itemRef.current?.deleteItems;
    if (deleteItems) {
      for (let item of deleteItems) {
        let res = await dispatch(
          deletePurchaseOrderItemDetails({
            purchaseOrderId: Number(editId),
            itemId: item.itemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emaiId);
    });

    if (deleteContactIds.length) {
      await dispatch(
        deletePurchaseOrderRecipientDetails({
          purchaseOrderId: Number(editId),
          emailIds: deleteContactIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let purchaseOrderItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of purchaseOrderItems) {
      let itemInputs = items;

      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let key: keyof typeof itemInputs;
      for (key in itemInputs) {
        if (itemInputs[key] === "") {
          if (key === "rate" || key === "quantity") {
            itemInputs[key] = 0;
          } else {
            itemInputs[key] = null;
          }
        }
        if (key === "discount") {
          if (itemInputs[key]?.discount_value) {
            itemInputs[key] = itemInputs[key]?.discount_value;
          } else if (itemInputs[key]?.discount_percentage) {
            itemInputs[key] = itemInputs[key]?.discount_percentage;
          } else {
            itemInputs[key] = Number(itemInputs[key]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    purchaseOrderInputs.items = purchaseOrderItems;
    purchaseOrderInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      purchaseOrderInputs.email_to = emailLists;
    }
    purchaseOrderInputs.total = calculatedTotal;
    purchaseOrderInputs.roundoff_enabled = isRoundOffEnabled;
    purchaseOrderInputs.is_total_editable = isTotalEditable;

    // Purchase Order Edit
    const updatePurchaseOrderResponseAction = await dispatch(
      editPurchaseOrderDetails({
        id: Number(editId),
        editPurchaseOrderInputs: purchaseOrderInputs,
        status: purchaseOrderStatus,
        orgId: currentUserInfo.organization_id,
      })
    );

    const updatePurchaseOrderResponse =
      updatePurchaseOrderResponseAction.payload;
    if (
      Object.keys(updatePurchaseOrderResponse).length &&
      !("error" in updatePurchaseOrderResponse)
    ) {
      if (purchaseOrderStatus === "Draft") navigate(-1);
      else {
        redirectUrl
          ? navigate(
              `/purchase-order/detail/${Number(
                editId
              )}?redirect=${redirectUrl}`,
              {
                replace: true,
              }
            )
          : navigate(`/purchase-order/detail/${Number(editId)}`, {
              replace: true,
            });
      }
      toast.success("Purchase Order updated successfully!", {
        toastId: "purchase-order-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in updatePurchaseOrderResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };
  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  /**
   * Function to store emailIds to be deleted in Edit invoice
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emaiId: contact.id },
        ]);
      }
    });
  };
  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    let payload: any = data.values;
    if (isGstOrg) {
      payload.item_list = payload.item_list.map((item: any) => {
        let processedData;
        if (item.gst_details) {
          processedData = Object.fromEntries(
            Object.entries(item?.gst_details)?.filter(
              ([key, value]) => value !== 0
            )
          );
        }
        return {
          ...item,
          gst_details: processedData,
        };
      });
    }
    const responseAction = await dispatch(
      getAmountCalculation({
        values: payload,
        orgId: data.orgId,
        signal: data.signal,
      })
    );
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: CustomerItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getTaxableAmount(data));
    return taxResponseAction;
  };
  /**
   * Upload selected files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("purchase_order_file", file);
    let responseAction = await dispatch(
      attachPurchaseOrderFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };
  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deletePurchaseOrderFile({
          purchaseOrderId: Number(editId),
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let purchaseOrderInputs: any = {
      ...purchaseOrderFormValues,
      ...defaultValues,
    };
    let errors = validate(
      purchaseOrderInputs,
      "",
      isPurchaseOrderNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  const fetchPurchaseOrderListAfterDelete = async () => {
    const responseAction = await dispatch(
      purchaseOrderList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: [],
        orgIds: [],
        statusList: [],
      })
    );
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };

  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };

  const setErrorOnItemTaxChange = (errors: any) => {
    setItemFormErrors([...errors]);
  };

  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };

  const getStateCode = async (stateName: string) => {
    let stateCode = "";
    if (Object.keys(stateList).length === 0) {
      const result = await dispatch(
        getStateList({ orgId: currentUserInfo.organization_id })
      );
      let states = result.payload;
      stateCode = getKeyByValue(states, stateName);
    } else {
      stateCode = getKeyByValue(stateList, stateName);
    }
    return stateCode;
  };

  const fetchGstTaxList = async (
    state_type: string,
    gstRegType: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }],
    stateCode?: string
  ) => {
    dispatch(clearGstTaxList());

    if (state_type === "Intra State" && !stateCode) {
      if (defaultRef.current?.defaultData.destination_of_supply?.value) {
        stateCode = await getStateCode(
          defaultRef.current?.defaultData.destination_of_supply?.value as string
        );
      } else if (defaultRef.current?.defaultData.source_of_supply?.value) {
        stateCode = await getStateCode(
          defaultRef.current?.defaultData.source_of_supply?.value as string
        );
      }
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      state_type = "Inter State";
    }
    dispatch(clearGstTaxList());

    const result = await dispatch(
      getGstTaxList({
        stateType: state_type,
        orgId: currentUserInfo.organization_id,
        transactionSource: "Bills",
        stateCode,
        transaction_tax_details,
      })
    );
  };

  const getTotalValues = (total: number | string) => {
    setTotalAmount(Number(total));
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };

  const handleEmailError = (error: string) => {
    setEmailFormErrors(error);
  };
  const rcmChangeHandler = (isRcmEnable: boolean) => {
    setRcmEnabled(isRcmEnable);
  };
  const handleExpiryDatePopupation = (
    issueDate: string,
    expiryDate: string
  ) => {
    let label = "Custom";
    if (getFormatedDate(moment(issueDate).toDate()) === expiryDate) {
      label = "On issue date";
    } else if (
      getFormatedDate(moment(issueDate).add(7, "days").toDate()) === expiryDate
    ) {
      label = "In 7 days";
    } else if (
      getFormatedDate(moment(issueDate).add(14, "days").toDate()) === expiryDate
    ) {
      label = "In 14 days";
    } else if (
      getFormatedDate(moment(issueDate).add(30, "days").toDate()) === expiryDate
    ) {
      label = "In 30 days";
    }
    dispatch(
      setDateRange({
        start_date: expiryDate,
        end_date: expiryDate,
        date_range: label.replace(" ", "_").toLowerCase(),
      })
    );
  };
  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 card-bill-management"
        id="invoice-create"
      >
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Purchase Order"
            transactionNumber={Number(editId)}
          />
        </div>
        <div className="card-body">
          <form
            name="add_invoice_form"
            id="add_invoice_form"
            className={`cn-form add-module-item-form ${
              defaultRef.current?.defaultData?.vendor_id ? "" : " inactive"
            }`}
            tabIndex={-1}
          >
            <div className="fields-wrapper pt-0">
              <div className="fields-wrappers">
                <DefaultDetails
                  defaultFormValues={defaultFormValues}
                  purchaseOrderFormValues={purchaseOrderFormValues}
                  handleVendorChange={handleVendorChange}
                  checkPurchaseOrderNumberExist={checkPurchaseOrderNumberExist}
                  formErrors={formErrors}
                  handleNewConversionDate={handleNewConversionDate}
                  ref={defaultRef}
                  customFieldRef={customFieldRef}
                  customFieldValue={customFields}
                  getCustomFieldData={getCustomFieldData}
                  setStateType={setStateType}
                  fetchGstTaxList={fetchGstTaxList}
                  setSupplyState={setSupplyState}
                  initialStateType={initialStateType}
                  gstRegistrationType={gstRegistrationType}
                  setIsStateChanged={setIsStateChanged}
                  setRCMEnable={rcmChangeHandler}
                  handleExpiryDatePopupation={handleExpiryDatePopupation}
                />
                <ItemDetails
                  formValues={purchaseOrderFormValues}
                  itemFormValues={itemFormValues}
                  totalFormValues={totalFormValues}
                  formErrors={formErrors}
                  itemFormErrors={itemFormErrors}
                  currencyId={currencyId}
                  currencyCode={currencyCode}
                  amountCalculation={amountCalculation}
                  taxableAmount={taxableAmount}
                  isHsnCode={true}
                  taxType="receivable"
                  lineItemBaseAccount="Expenses~Assets~Liabilities"
                  discountBaseAccount="Incomes"
                  conversionDate={conversionDate}
                  newConversionDate={newConversionDate}
                  conversionCurrencyId={conversionCurrencyId}
                  userId={vendorId}
                  componentType={"bill"}
                  setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                  totalTdsValues={totalTdsValues}
                  totalTcsValues={totalTcsValues}
                  sourceTaxType={sourceTaxType}
                  tdsIds={tdsIds}
                  tcsIds={tcsIds}
                  ref={itemRef}
                  stateType={stateType}
                  supplyState={supplyState}
                  getTotalValue={getTotalValues}
                  gstRegistrationType={gstRegistrationType}
                  isRoundOffEnabled={isRoundOffEnabled}
                  isTotalEditable={isTotalEditable}
                  editTotal={editTotal}
                  updateCalculatedTotal={updateCalculatedTotal}
                  isCustomerVendorChanged={isVendorChanged}
                  isStateChanged={isStateChanged}
                  consumerId={vendorId}
                  consumerTradeDefault={vendorPurchaseDefault}
                  rcmEnabled={rcmEnabled}
                  forSubscription={false}
                >
                  <NoteTextArea
                    label="Notes"
                    name="vendor_notes"
                    id="customerNotes"
                    className="notes-textarea"
                    placeholder="Enter notes here..."
                    value={purchaseOrderFormValues.vendor_notes}
                    onChange={handleChange}
                    formErrors={formErrors}
                  />
                </ItemDetails>
                <section id="upload_section">
                  <div className="upload-header pb-3">Attach Files</div>
                  <DropZone
                    uploadedFiles={uploadedFiles}
                    uploadFileIds={fileIds}
                    uploadAttachedFiles={uploadAttachedFiles}
                    removeFile={removeFile}
                    handleSubmitAction={handleSubmitAction}
                    ref={fileRef}
                  />
                  <span className="error">{formErrors.attachFiles}</span>
                </section>
                <div className="email-reciepients-wrapper">
                  <label htmlFor="deposit_to">Email to</label>
                  <br />
                  <MultiEmailBox
                    onChange={setEmailHandler}
                    emailList={emailLists}
                    removeEmailHandler={removeEmailHandler}
                    onError={handleEmailError}
                    className={emailFormErrors !== "" ? "email-error" : ""}
                  />
                  <span className="error">{emailFormErrors}</span>
                </div>
              </div>
              <PurchaseOrderFooter
                editId={editId}
                purchaseOrderStatus={purchaseOrderStatus}
                isFormSubmit={formSubmit && formSubmitAction}
                onClickSubmit={asynchronousSubmit}
                rolePermission={purchaseOrderRolePermission}
                vendorSelected={
                  defaultRef.current?.defaultData?.vendor_id ? true : false
                }
                onClickDelete={(e) => {
                  e.preventDefault();
                  setDeletePurchaseOrderId(Number(editId));
                  setDeletePurchaseOrderItemId(
                    purchaseOrderFormValues.purchase_order_number
                  );
                }}
              />
            </div>
          </form>
        </div>
        <DeletePurchaseOrder
          deletePurchaseOrderId={deletePurchaseOrderId}
          deletePurchaseOrderItemId={deletePurchaseOrderItemId}
          page={page}
          itemsPerPage={itemsPerPage}
          dateSortOrder={dateSortOrder}
          refreshPurchaseOrders={fetchPurchaseOrderListAfterDelete}
          organizationId={currentUserInfo.organization_id}
        />
      </div>
      {/* <TransactionEditTagRemovalAlertModal
        module="purchase order"
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={() => {
          handleSubmit(buttonAction.status, buttonAction.action);
        }}
        openAlertRef={openEditAlertRef}
      /> */}
    </>
  );
}

export default React.memo(PurchaseOrderForm);
