import { useCallback } from "react";

type AccountNode = {
  id: string;
  parent_id: string | null;
  transaction_exist: boolean;
  depth: number;
  is_category: boolean;
  closing_balance: string;
  account_name: string;
  is_tag_associated: boolean;
  is_tag: boolean;
  node: AccountNode[];
  style_class?: string;
};
/**
 * Function to find and modify a specific node within a hierarchical data structure based on its ID.
 *
 * @param {string} id - The ID of the node to find and process.
 * @param {AccountNode[]} data - The hierarchical data structure containing nodes of type AccountNode.
 * @param {AccountNode[]} newNodes - The nodes to either append or remove based on the shouldAppend parameter.
 * @param {boolean} shouldAppend - If true, newNodes will be appended at the beginning of the found node's children;
 *                                 if false, newNodes (if present) will be removed from the found node's children.
 *
 * @returns {AccountNode[]} - A new array of AccountNode with the necessary modifications applied.
 *
 * @description
 * - The function searches for a node with a specific ID within a hierarchical data structure.
 * - If the node is found:
 *    - If `shouldAppend` is true, it prepends `newNodes` to the `node` array of the found node.
 *    - If `shouldAppend` is false, it removes any nodes in `newNodes` from the found node's children.
 * - The function uses recursion to traverse the data structure, ensuring that all child nodes are processed.
 * - The function maintains immutability by returning a new structure rather than modifying the input directly.
 */
export const useProcessData = (shouldAppend: boolean) => {
  const processNodeById = useCallback(
    (
      id: string | number | null,
      data: AccountNode[],
      newNodes: AccountNode[],
      shouldAppend: boolean,
    ): AccountNode[] => {
      return data?.map((node) => {
        if (Number(node.id) === Number(id)) {
          let updatedNodes: AccountNode[];
          if (!node.node.some((obj) => obj.is_tag)) {
            updatedNodes = [...newNodes, ...node.node];
          } else {
            updatedNodes = node.node.filter((childNode) => !childNode.is_tag);
          }

          return {
            ...node,
            node: updatedNodes,
          };
        }
        return {
          ...node,
          node: processNodeById(id, node.node, newNodes, shouldAppend),
        };
      });
    },
    [shouldAppend]
  );
  return { processNodeById };
};
