import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import { setLoaderState } from "../../../../common/commonSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { SalesPurchaseDefault } from "../../../../common/components/SalesPurchaseDefault/SalesPurchaseDefault";
import {
  ContactRef,
  CreateContactError,
  CreateContactValues,
  CreateCustomerValues,
  CustomerDefaultValues,
  DefaultDetailsRef,
  ObjectType,
  OtherDetailsRef,
  OtherDetailsType,
} from "../../../../../types";
import {
  createCustomer,
  createCustomerAddress,
  createCustomerContact,
  customerDetails,
  customerDropdownList,
  deleteCustomerAddress,
  deleteCustomerContactDetails,
  editCustomerDetails,
  getTaxExemptionReasonList,
  updateCustomerAddress,
  updateCustomerContact,
} from "../../customerSlice";
import {
  addAddress,
  addressReset,
  addressSelector,
} from "./AddressDetails/addressSlice";
import {
  defaultValues,
  initialContact,
  initialCustomerValues,
  otherDetailsValue,
} from "./StateInitialization";
import {
  checkContactFormError,
  validateContactPerson,
  validateCustomerForm,
  validateSalesDefaultForm,
} from "./ValidateCustomerForm";
import {
  customerCreatePayloadRefiner,
  customerEditPayloadRefiner,
} from "../../helpers/payloadRefiner";
import useCommonData from "../../../../hooks/useCommon";
import AddressDetails from "./AddressDetails/AddressDetails";
import ContactPerson from "./ContactPerson";
import DefaultDetails from "./DefaultDetails";
import OtherDetails from "./OtherDetails";
import useGst from "../../../../hooks/useGst";
import "./FormLabel.css";
import "./FormTabs.css";
import "./customerForm.css";
import "../../Customers.css";
import useVat from "../../../../hooks/useVat";

function CreateCustomer(prop: any) {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { isGstOrg } = useGst();
  const { isVatOrg } = useVat();
  const defaultRef = useRef<DefaultDetailsRef | null>(null);
  const otherRef = useRef<OtherDetailsRef | null>(null);
  const contactRef = useRef<ContactRef | null>(null);
  const customerId = prop?.id ? Number(prop.id) : 0;
  const redirectTo = prop?.redirectTo;
  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();
  const addressList = useAppSelector(addressSelector);
  let [customerFormValues, setCustomerFormValues] =
    useState<CreateCustomerValues>(initialCustomerValues);
  const [defaultFormValues, setDefaultFormValues] =
    useState<CustomerDefaultValues>(defaultValues);
  const [otherFormValues, setOtherFormValues] =
    useState<OtherDetailsType>(otherDetailsValue);
  const [defaultConsumerValues, setDefaultConsumerValues] =
    useState<ObjectType>({
      account: {
        id: null,
        name: "",
      },
      discount: {
        discount: "",
        account: {
          id: null,
          name: "",
        },
        discountType: "Absolute",
      },
      paymentTerm: {
        id: null,
        name: "",
      },
      consolidated_invoicing: false,
      exclude_discount_accounting: false,
    });
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [contactFormErrors, setContactFormErrors] = useState<
    CreateContactError[]
  >([]);
  const [otherErrorCount, setOtherErrorCount] = useState(0);
  const [contactErrorCount, setContactErrorCount] = useState(0);
  const [salesDefaultErrorCount, setSalesDefaultErrorCount] = useState(0);
  const [salesDefaultError, setSalesDefaultError] = useState<ObjectType>();
  const [contactErrorTempCount, setContactErrorTempCount] = useState(0);
  const [isCancel, setIsCancel] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [contactArray, setContactArray] = useState<CreateContactValues[]>([
    initialContact,
  ]);
  const [addressIds, setAddressIds] = useState<number[]>([]);
  const [initialAddress, setInitialAddress] = useState<any[]>([]);
  const [taxExempReasons, setTaxExempReasons] = useState<any[]>([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [customerName, setCustomerName] = useState("");
  useEffect(() => {
    dispatch(addressReset());
  }, []);

  useEffect(() => {
    getTaxExemptionReasons(currentUserInfo.organization_id);
    if (customerId) {
      getCustomerDetails();
      $("#add_customer_form").css("display", "none");
    }
  }, [currentUserInfo.organization_id]);

  /**
   * Fetch customer custom field  value
   */
  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };

  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async () => {
    dispatch(setLoaderState(true));
    $("#add_customer_form").css("display", "none");
    const responseAction = await dispatch(
      customerDetails({
        id: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      $("#add_customer_form").css("display", "block");
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        let defaultValues = {
          company_name: response.company_name,
          customer_email: response.customer_email,
          customer_phone: response.customer_phone
            ? response.customer_phone
            : "",
          customer_display_name: response.customer_display_name,
          short_code: response.short_code,
        };
        setCustomerName(response.customer_display_name);
        let otherValues = {
          pan_number: response.other_details.pan_number,
          gst_registration_type: response.other_details.gst_registration_type,
          gstin: response.other_details.gstin,
          is_contact_org_closed: response.is_contact_org_closed,
          opening_balance: response.other_details.opening_balance
            ? response.other_details.opening_balance
            : "",
          currency_id: response.other_details.currency_id,
          payment_term_id: response.other_details.payment_term_id,
          tag_ids: response.other_details.tags.map((tag: any) => tag.id),
          tax_preference: response.other_details.tax_preference,
          exemption_reason_id: response.other_details.exemption_reason_id,
          transaction_exist: response.transaction_exist,
          tax_registration_number:
            response?.other_details?.tax_registration_number,
        };
        setCurrencyCode(response.other_details.currency_code);
        let defaultConsumerValuesResponse = {
          sales_account_id: response.sales_default.sales_account_id,
          sales_account_name: response.sales_default.sales_account_name,
          discount_account_id: response.sales_default.discount_account_id,
          discount_account_name: response.sales_default.discount_account_name,
          discount_type: response.sales_default.discount_type,
          discount: response.sales_default.discount,
          payment_term_id: response.sales_default.payment_term_id || response.other_details.payment_term_id,
          consolidated_invoicing: response.sales_default.consolidated_invoicing,
          exclude_discount_accounting: response?.sales_default
            ?.exclude_discount_accounting
            ? response?.sales_default?.exclude_discount_accounting
            : false,
        };
        setDefaultConsumerValues({
          ...defaultConsumerValues,
          account: {
            id: defaultConsumerValuesResponse.sales_account_id,
            name: defaultConsumerValuesResponse.sales_account_name,
          },
          discount: {
            discount: defaultConsumerValuesResponse.discount,
            discountType: defaultConsumerValuesResponse.discount_type,
            account: {
              id: defaultConsumerValuesResponse.discount_account_id,
              name: defaultConsumerValuesResponse.discount_account_name,
            },
          },
          paymentTerm: {
            id: defaultConsumerValuesResponse.payment_term_id,
            name: "",
          },
          consolidated_invoicing:
            defaultConsumerValuesResponse.consolidated_invoicing,
          exclude_discount_accounting:
            defaultConsumerValuesResponse.exclude_discount_accounting
              ? defaultConsumerValuesResponse.exclude_discount_accounting
              : false,
        });
        setCustomerFormValues({
          ...defaultValues,
          ...otherValues,
          ...defaultConsumerValuesResponse,
          custom_fields: custom_fields.current.values,
        });
        setDefaultFormValues(defaultValues);
        setOtherFormValues(otherValues);
        let addressData = response.address_details;
        let addressIdArray: number[] = [];
        addressData.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : 0,
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : 0,
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : null,
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
            id: addressDetails.id,
            state_name: addressDetails.state_name,
            country_name: addressDetails.country_name,
            customer_name: addressDetails.customer_name,
            is_updated: false,
          };
          addressIdArray.push(addressDetails.id);
          dispatch(addAddress(address));
        });
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setAddressIds(addressIdArray);
        setInitialAddress(addressData);
        setOrganizationId(response.organization_id);
        let contactData = response.contact_persons;
        if (contactData.length) {
          let contacts: CreateContactValues[] = [];
          contactData.forEach(async (contactDetails: any) => {
            let contactObject = {
              first_name: contactDetails.first_name,
              last_name: contactDetails.last_name,
              email: contactDetails.email,
              phone: contactDetails.phone ? contactDetails.phone : "",
              is_primary: contactDetails.is_primary,
              id: contactDetails.id,
            };
            contacts.push(contactObject);
          });
          setContactArray(contacts);
        }
      } else {
        dispatch(setErrorID(noAccessErrorCodes.customerEdit));
        // navigate(redirectTo);
      }
    }
  };

  const asynchronousSubmit = async (e: any) => {
    await handleCustomSubmission();
    handleSubmit(e);
  };
  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let defaultFormValues = defaultRef.current?.defaultData;
    let otherFormValues = otherRef.current?.otherDetails;
    let contactArray = contactRef.current?.contactArray;
    customerFormValues = {
      ...customerFormValues,
      ...defaultFormValues,
      ...otherFormValues,
      custom_fields: custom_fields.current.values,
    };
    const { errors, count } = validateCustomerForm(
      customerFormValues,
      isGstOrg,
      isVatOrg
    );
    const defaultFormErrors = validateSalesDefaultForm(defaultConsumerValues);
    setSalesDefaultError(defaultFormErrors);
    if (Object.keys(defaultFormErrors).length > 0) {
      setSalesDefaultErrorCount(1);
    } else {
      setSalesDefaultErrorCount(0);
    }
    setOtherErrorCount(count);
    let contactErrors: any = [];
    let contErrCount = 0;
    contactArray?.map((item) => {
      let contactErrorResponse = validateContactPerson(item);
      if (contactErrorResponse) {
        contactErrors.push(contactErrorResponse);
        contErrCount += Object.keys(contactErrorResponse).length;
      } else {
        contactErrors.push({ email: "", phone: "" });
      }
    });
    let contactError = checkContactFormError(contactErrors);
    if (!contactError) setContactErrorCount(0);
    setContactErrorCount(contErrCount);
    setContactErrorTempCount(contErrCount);
    if (
      Object.keys(errors).length ||
      contactError ||
      defaultRef.current?.isShortCodeExist ||
      customFieldRef.current.errors
    ) {
      setFormErrors(errors);
      if (contactErrors.length) {
        setContactFormErrors(contactErrors);
      } else {
        setContactFormErrors([]);
      }
    } else if (Object.keys(defaultFormErrors).length > 0) {
      return;
    } else {
      $("#form-btn").addClass("customer-form-section-disable");
      if (customerId) {
        updateCustomerSubmit();
      } else {
        createCustomerSubmit();
      }
    }
  };
  /**
   * Create new customer
   */
  const createCustomerSubmit = async () => {
    dispatch(setLoaderState(true));
    dispatch(setListFilters({}));
    let customerInputs: any = { ...customerFormValues };
    let key: keyof typeof customerInputs;
    for (key in customerInputs) {
      if (customerInputs[key] === "") {
        customerInputs[key] = null;
      }

      if (key === "customer_display_name") {
        customerInputs[key] = customerInputs[key].trim();
      }
    }
    const {
      sales_account_id,
      discount,
      discount_account_id,
      discount_type,
      payment_term_id,
      customer_email,
      customer_phone,
      pan_number,
      opening_balance,
      gst_registration_type,
      gstin,
      ...rest
    } = customerFormValues;
    const createCustomerResponseAction = await dispatch(
      createCustomer({
        values: customerCreatePayloadRefiner(
          customerFormValues,
          defaultConsumerValues
        ),
        orgId: currentUserInfo.organization_id,
      })
    );
    const createCustomerResponse = createCustomerResponseAction.payload;
    if (
      Object.keys(createCustomerResponse).length &&
      !("error" in createCustomerResponse)
    ) {
      dispatch(setLoaderState(false));
      let customerId = createCustomerResponse.id;
      if (customerId) {
        let addressInputs: any[] = addressList
          .filter((item) => item.id !== "")
          .map((address) => {
            return {
              ...address,
              customer_name: address.customer_name
                ? address.customer_name
                : customerName,
              zip_code: address.zip_code ? address.zip_code : null,
            };
          });
        await dispatch(
          createCustomerAddress({
            id: customerId,
            addressInputs: addressInputs,
            orgId: currentUserInfo.organization_id,
          })
        );
        await checkContacts(customerId);
        if (redirectTo) {
          // navigate(redirectTo);

          navigate(`/customers/detail/${customerId}`, { replace: true });
        }
        toast.success("Customer created successfully!", {
          toastId: "customer-create-success",
          closeButton: false,
          position: "top-center",
        });
      }
      $("#form-btn").removeClass("customer-form-section-disable");
      if (prop?.handleModalClose) {
        await dispatch(customerDropdownList(currentUserInfo.organization_id));
        prop?.handleModalClose();
      }
    } else if ("error" in createCustomerResponse) {
      dispatch(setLoaderState(false));
      $("#form-btn").removeClass("customer-form-section-disable");
    } else {
      dispatch(setLoaderState(false));
    }
  };
  /**
   * Update customer submit
   */
  const updateCustomerSubmit = async () => {
    dispatch(setListFilters({}));
    dispatch(setLoaderState(true));
    let customerInputs: any = { ...customerFormValues };
    const {
      sales_account_id,
      discount,
      discount_account_id,
      discount_type,
      payment_term_id,
      customer_email,
      customer_phone,
      pan_number,
      opening_balance,
      gst_registration_type,

      ...rest
    } = customerFormValues;
    let key: keyof typeof customerInputs;
    for (key in customerInputs) {
      if (customerInputs[key] === "") {
        customerInputs[key] = null;
      }
    }
    const response = await dispatch(
      editCustomerDetails({
        id: customerId,
        editCustomerInputs: customerEditPayloadRefiner(
          customerFormValues,
          defaultConsumerValues
        ),
        orgId: currentUserInfo.organization_id,
      })
    )
      .then(() => {
        dispatch(setLoaderState(false));
      })
      .catch(() => {
        dispatch(setLoaderState(false));
      });
    let editAddressInputs: any[] = addressList
      .filter((item) => item.id !== "" && !item.id.toString().includes("-"))
      .map((address) => {
        return {
          ...address,
          customer_name: address.customer_name
            ? address.customer_name
            : customerName,
          zip_code: address.zip_code ? address.zip_code : null,
        };
      });

    let createAddressInputs: any[] = addressList
      .filter((item) => item.id !== "" && item.id.toString().includes("-"))
      .map((address) => {
        return {
          ...address,
          customer_name: address.customer_name
            ? address.customer_name
            : customerName,
          zip_code: address.zip_code ? address.zip_code : null,
        };
      });
    await dispatch(
      updateCustomerAddress({
        customerId: customerId ? customerId : 0,
        addressInputs: editAddressInputs,
        orgId: currentUserInfo.organization_id,
      })
    );

    await dispatch(
      createCustomerAddress({
        id: customerId,
        addressInputs: createAddressInputs,
        orgId: currentUserInfo.organization_id,
      })
    );
    let deleteAddressIds: number[] = [];
    for (let id of addressIds) {
      deleteAddressIds.push(id);
    }
    for (let address of addressList) {
      if (deleteAddressIds.includes(Number(address.id))) {
        const index = deleteAddressIds.indexOf(Number(address.id));
        deleteAddressIds.splice(index, 1);
      }
    }
    if (deleteAddressIds.length) {
      await dispatch(
        deleteCustomerAddress({
          customerId: customerId,
          addressId: deleteAddressIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    await contactRef.current?.deleteContacts();
    await checkContacts(customerId);
    navigate(`/customers/detail/${customerId}`, { replace: true });
    toast.success("Customer updated successfully!", {
      toastId: "customer-update-success",
      closeButton: false,
      position: "top-center",
    });
  };
  $(document).ready(function () {
    $("#addCustomerModal").on("hide.bs.modal", function (e: any) {
      setCustomerFormValues(initialCustomerValues);
      setContactArray([initialContact]);
      $(".nav-link").removeClass("active");
      $(".tab-pane").removeClass("show active");
      $("#pills-other-tab").addClass("active");
      $("#pills-other").addClass("show active");
      return true;
    });
  });
  /**
   * Check contacts have any value or not
   */
  const checkContacts = async (customerId: number) => {
    let isContact = false;
    const contacts: any = contactRef.current?.contactArray;
    let contactEditApis = [];
    let contactCreateApis = [];
    let createContactArray = [];
    for (let key in contacts) {
      if (
        contacts[key].first_name !== "" ||
        contacts[key].last_name !== "" ||
        contacts[key].phone !== "" ||
        contacts[key].email !== ""
      ) {
        let contactObj: any = contacts[key];
        let index: keyof typeof contactObj;
        for (index in contactObj) {
          if (contactObj[index] === "") {
            contactObj[index] = null;
          }
        }
        if (contactObj.id) {
          let contactId = contactObj.id;
          if (
            contacts[key].first_name === null &&
            contacts[key].last_name == null &&
            contacts[key].phone == null &&
            contacts[key].email == null
          ) {
            contactEditApis.push(
              dispatch(
                deleteCustomerContactDetails({
                  customerId,
                  contactId: Number(contactId),
                  orgId: currentUserInfo.organization_id,
                })
              )
            );
          }
          delete contactObj.id;
          contactEditApis.push(
            dispatch(
              updateCustomerContact({
                customerId: customerId,
                contactId: Number(contactId),
                editContactInputs: contactObj,
                orgId: currentUserInfo.organization_id,
              })
            )
          );
        } else {
          createContactArray.push(contactObj);
        }
        isContact = true;
      }
    }
    if (contactEditApis.length) {
      await Promise.all(contactEditApis);
    }
    for (let index = 0; index < createContactArray.length; index++) {
      contactCreateApis.push(
        dispatch(
          createCustomerContact({
            id: customerId,
            contactInputs: createContactArray[index],
            orgId: currentUserInfo.organization_id,
          })
        )
      );
    }
    if (contactCreateApis.length) {
      await Promise.all(contactCreateApis);
    }
    return isContact;
  };

  const getTaxExemptionReasons = async (orgId: number) => {
    const taxExempReasonsList = await dispatch(
      getTaxExemptionReasonList({ orgId: orgId })
    );
    let taxExemptionReasons = taxExempReasonsList.payload.map((item: any) => {
      let tempObj = {
        value: "",
        label: "",
      };
      tempObj.value = item.id;
      tempObj.label = item.exemption_reason;
      return tempObj;
    });
    setTaxExempReasons(taxExemptionReasons);
  };

  const handleContactErrors = (count: number) => {
    setContactErrorCount(count);
    setContactErrorTempCount(count);
  };
  const handleAccountChange = (id: number | null) => {
    setDefaultConsumerValues((prevValues: ObjectType) => {
      return {
        ...prevValues,
        account: {
          id: id,
        },
      };
    });
  };
  const handleDiscountChange = (
    discount: string | number,
    account: ObjectType,
    discountType: string
  ) => {
    setDefaultConsumerValues((prevValues: ObjectType) => {
      return {
        ...prevValues,
        discount: {
          discount: discount,
          account: {
            id: account.id,
            name: account.name,
          },
          discountType: discountType,
        },
      };
    });
  };

  const handleConsolidatedInvoiceChange = (e: any) => {
    setDefaultConsumerValues({
      ...defaultConsumerValues,
      consolidated_invoicing: e.target.checked,
    });
  };

  const handlePaymentChangeHandle = (paymentTerm: ObjectType) => {
    setDefaultConsumerValues((prevValues: ObjectType) => {
      return {
        ...prevValues,
        paymentTerm: {
          id: paymentTerm.value,
          name: "",
        },
      };
    });
  };
  const getCurrencyCode = (code: string) => {
    setCurrencyCode(code);
  };
  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };
  const handleExcludeDiscountAccount = (e: any) => {
    let newValue: any = {
      exclude_discount_accounting: e.target.checked,
    };
    if (e.target.checked) {
      newValue = {
        ...newValue,
        discount: {
          ...newValue.discount,
          account: {
            id: null,
            name: "",
          },
        },
      };
    }
    setDefaultConsumerValues({
      ...defaultConsumerValues,
      ...newValue,
    });
  };
  const handleCancel = async () => {
    setCustomerFormValues(initialCustomerValues);
    setContactArray([initialContact]);
    setIsCancel(!isCancel);
    setFormErrors({});
    setOtherErrorCount(0);
    setContactErrorCount(0);
    setContactFormErrors([]);
    $(".nav-link").removeClass("active");
    $(".tab-pane").removeClass("show active");
    $("#pills-other-tab").addClass("active");
    $("#pills-other").addClass("show active");
    if (prop?.handleModalClose) {
      prop?.handleModalClose();
      await dispatch(customerDropdownList(currentUserInfo.organization_id));
    }
  };
  const getCustomerName = (name: string) => {
    setCustomerName(name);
  };
  return (
    <>
      <form
        onSubmit={asynchronousSubmit}
        name="add_customer_form"
        id="add_customer_form"
        tabIndex={-1}
        className="add-consumer-form customer-form-wrap"
      >
        <div className="fields-wrapper">
          <DefaultDetails
            defaultFormValues={defaultFormValues}
            formErrors={formErrors}
            customerId={customerId}
            ref={defaultRef}
            customFieldRef={customFieldRef}
            customFieldValue={customFields}
            getCustomFieldData={getCustomFieldData}
            getCustomerName={getCustomerName}
          />
          <section className="form-tab-section px-0">
            <ul
              className="nav nav-pills pills-tab mt-0"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-other-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-other"
                  type="button"
                  role="tab"
                  aria-controls="pills-other"
                  aria-selected="true"
                >
                  Other Details
                  {otherErrorCount ? (
                    <span className="error-count">{otherErrorCount}</span>
                  ) : null}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-sales-default-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-sales-default"
                  type="button"
                  role="tab"
                  aria-controls="pills-sales-default"
                  aria-selected="false"
                >
                  Sales Default
                  {salesDefaultErrorCount ? (
                    <span className="error-count">
                      {salesDefaultErrorCount}
                    </span>
                  ) : null}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-address-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-address"
                  type="button"
                  role="tab"
                  aria-controls="pills-address"
                  aria-selected="false"
                >
                  Address Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Contact Persons
                  {contactErrorCount ? (
                    <span className="error-count">{contactErrorCount}</span>
                  ) : null}
                </button>
              </li>
            </ul>
            <div className="tab-content pb-0" id="pills-tabContent">
              <OtherDetails
                redirectTo={redirectTo}
                otherFormValues={otherFormValues}
                formErrors={formErrors}
                id={customerId}
                isCancel={isCancel}
                type={"Customer"}
                ref={otherRef}
                isTaxPreference={true}
                taxExemptReasons={taxExempReasons}
                organizationId={organizationId}
                isContactOrgClosed={customerFormValues.is_contact_org_closed}
                fetchCurrencyCode={getCurrencyCode}
              />
              <AddressDetails
                initialAddress={initialAddress}
                consumerName={customerName}
              />
              <SalesPurchaseDefault
                module="Sales"
                currencyCode={currencyCode}
                discount={{
                  discount: defaultConsumerValues.discount.discount,
                  discountType: defaultConsumerValues.discount.discountType,
                  account: {
                    id: defaultConsumerValues.discount.account.id,
                    name: defaultConsumerValues.discount.account.name,
                  },
                }}
                account={{
                  name: defaultConsumerValues.account.name,
                  id: defaultConsumerValues.account.id,
                }}
                paymentTerm={{
                  id: defaultConsumerValues.paymentTerm.id,
                  name: defaultConsumerValues.paymentTerm.name,
                }}
                handleAccountChange={handleAccountChange}
                handleDiscountChange={handleDiscountChange}
                handlePaymentChangeHandle={handlePaymentChangeHandle}
                handleConsolidatedInvoiceChange={
                  handleConsolidatedInvoiceChange
                }
                consolidatedInvoicing={
                  defaultConsumerValues.consolidated_invoicing
                }
                handleExcludeDiscountAccount={handleExcludeDiscountAccount}
                excludeDiscountAccount={
                  defaultConsumerValues.exclude_discount_accounting
                }
                errors={salesDefaultError || {}}
              />
              <ContactPerson
                customerId={customerId}
                contactArray={contactArray}
                contactFormErrors={contactFormErrors}
                contactErrorCount={contactErrorCount}
                contactErrorTempCount={contactErrorTempCount}
                handleContactErrors={handleContactErrors}
                ref={contactRef}
              />
            </div>
          </section>
        </div>
        <div className="row mb-0">
          <div className="col">
            <div
              className="form-button-wrapper w-100 docked-button-container"
              id="form-btn"
            >
              <button className="save-button">Save</button>
              {redirectTo === "/customers" ? (
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="text-decoration-none cancel-button"
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="cancel-button close-btn"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateCustomer;
