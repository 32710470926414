import { ObjectType } from "../../../../types";
import { useModuleCustomFieldList } from "../../../hooks/useModuleCustomFieldList";
import "./rowWiseFieldsView.css";

type RowWiseProps = {
  mapObject: ObjectType;
  module: string;
};

export const RowWiseFieldView = (props: RowWiseProps) => {
  const moduleCustomFields = useModuleCustomFieldList(props?.module||"");
  const pdfCustomFields = Object.keys(props?.mapObject || {})
    .filter((field) => props.mapObject[field]?.show_in_pdf)
    .map((field) => {
      return {
        field_name: field,
        value: props?.mapObject[field]?.value,
      };
    });

  return (
    <div className="row-wise-field-wrapper d-flex row">
      {pdfCustomFields.map((field: any) => {
        return (
          <div className="row-wise-field-item" key={field?.field_name}>
            <span className="row-wise-field-item-header">
              {field?.field_name}
            </span>
            <div className="row-wise-field-item-details pt-3">
              {field?.value || "-"}
            </div>
          </div>
        );
      })}
    </div>
  );
};
