import { useCallback } from "react";
import { AccountNode } from "../types";
import useCommonData from "../../../../hooks/useCommon";

export const useProcessTableData = (currencyCode: string) => {
  const { orgCurrencyList, currentUserInfo } = useCommonData();

  function processAndSanitizeNode(
    inputNodes: AccountNode[],
    profitAndLossData: any,
    tagProfitAndLossData: any
  ): AccountNode[] {
    if (!inputNodes || inputNodes.length === 0) return [];

    const returnElements = inputNodes
      .map((node, index) => {
        const processedChildren = processAndSanitizeNode(
          node.node as AccountNode[],
          profitAndLossData,
          tagProfitAndLossData
        );

        let additionalNodes: AccountNode[] = [];
        Object.entries(node.range || {}).map(([key, value]) => {
          additionalNodes = getAdditionalNodes(
            node,
            profitAndLossData,
            tagProfitAndLossData,
            key
          );
        });

        if (additionalNodes.length > 0) {
          return [
            {
              ...node,
              node: processedChildren,
            },
            ...additionalNodes,
          ];
        }

        return {
          ...node,
          node: processedChildren,
        };
      })
      .flat();

    return returnElements;
  }

  function getAdditionalNodes(
    node: AccountNode,
    profitAndLossData: any,
    tagProfitAndLossData: any,
    key: string
  ): AccountNode[] {
    const additionalNodes: AccountNode[] = [];

    if (node.account_name === "Direct Incomes") {
      additionalNodes.push(
        createAdditionalNode(
          "Total Operating Income",
          profitAndLossData,
          "total_operating_income",
          tagProfitAndLossData?.total_operating_income,
          node,
          "1toi"
        )
      );
    } else if (node.account_name === "Indirect Incomes") {
      additionalNodes.push(
        createAdditionalNode(
          "Non Operating Income",
          profitAndLossData,
          "non_operating_income",
          tagProfitAndLossData?.non_operating_income,
          node,
          "1noi"
        )
      );
    } else if (node.account_name === "Direct Expenses") {
      additionalNodes.push(
        createAdditionalNode(
          "Total Cost of Goods Sold",
          profitAndLossData,
          "total_cost_of_goods_sold",
          tagProfitAndLossData?.total_cost_of_goods_sold,
          node,
          "1tcogs"
        )
      );
      additionalNodes.push(
        createAdditionalNode(
          "Gross Profit",
          profitAndLossData,
          "gross_profit",
          tagProfitAndLossData?.gross_profit,
          node,
          "1gp"
        )
      );
    } else if (node.account_name === "Indirect Expenses") {
      additionalNodes.push(
        createAdditionalNode(
          "Total Operating Expense",
          profitAndLossData,
          "total_operating_expense",
          tagProfitAndLossData?.total_operating_expense,
          node,
          "1toe"
        )
      );
      additionalNodes.push(
        createAdditionalNode(
          "Net Profit / Loss before tax",
          profitAndLossData,
          "net_profit_loss_before_tax",
          tagProfitAndLossData?.net_profit_loss_before_tax,
          node,
          "1nplbt"
        )
      );
    }

    return additionalNodes;
  }

  function createAdditionalNode(
    name: string,
    profitAndLossData: any,
    objName: string,
    isTagAssociated: boolean,
    node: AccountNode,
    id: string
  ): AccountNode {
    let rangeUpdated = {};
      Object.entries(profitAndLossData || {})?.map(
        ([key, value]: [string, any]) => {
          rangeUpdated = {
            ...rangeUpdated,
            [key]: {
              total: value[objName] || "0.00",
              transaction_exist: isTagAssociated || false,
            },
          };
        }
      );
    return {
      id: id,
      parent_id: node.id,
      transaction_exist: false,
      depth: 0,
      is_category: false,
      closing_balance: 0.00,
      is_tag_associated: isTagAssociated,
      account_name: name,
      node: [],
      style_class: "additional-node",
      range: rangeUpdated,
    };
  }

  const appendLastRow = useCallback(
    (
      nodeList: AccountNode[],
      profitAndLossData: any,
      tagProfitAndLossData: any
    ) => {
      let rangeUpdated = {};
      Object.entries(profitAndLossData || {})?.map(
        ([key, value]: [string, any]) => {
          rangeUpdated = {
            ...rangeUpdated,
            [key]: {
              total: value.net_profit_loss_after_tax || "0.00",
              transaction_exist: tagProfitAndLossData?.net_profit_loss_after_tax || false,
            },
          };
        }
      );
      const totalObject = {
        id: "1nplat",
        parent_id: null,
        transaction_exist: false,
        depth: 0,
        is_category: false,
        closing_balance: 0.00,
        is_tag_associated: tagProfitAndLossData?.net_profit_loss_after_tax,
        account_name: `Net Profit / Loss after tax`,
        node: [],
        style_class: "additional-node",
        range: rangeUpdated,
      };

      return totalObject; // Fallback if expenses node not found
    },
    [currencyCode]
  );

  function generateDefaultId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  return { processAndSanitizeNode, appendLastRow };
};
