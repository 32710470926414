import { useEffect, useState } from "react";
import { ObjectType } from "../../../../../types";
import AccordianItem from "../../../../common/components/detailsPage/AccordianItem";
import { CustomFieldTable } from "../customFieldTable/customFieldTable";

type Props = {
  accountsModuleList: {
    "Journal Entry": ObjectType[];
    "System Voucher": ObjectType[];
  };
};
export const AccountsCustomFields = (props: Props) => {
  const [customFieldModuleList, setCustomFieldModuleList] = useState(
    props.accountsModuleList
  );

  useEffect(() => {
    setCustomFieldModuleList(props.accountsModuleList);
  }, [props.accountsModuleList]);

  const journalVoucherFields = customFieldModuleList["Journal Entry"]
    ? customFieldModuleList["Journal Entry"]
        ?.filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];
    const systemVoucherFields = customFieldModuleList["System Voucher"]
    ? customFieldModuleList["System Voucher"]
        ?.filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];
  return (
    <div
      className="accordion transactions-accordion"
      id="transactions-accordion"
    >
      {" "}
      <AccordianItem
        title="Journal Entry"
        index={1}
        module="Custom Fields"
        subModule="Journal Entry"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={journalVoucherFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Journal Entry"}
        />
      </AccordianItem>
      <AccordianItem
        title="System Voucher"
        index={2}
        module="Custom Fields"
        subModule="System Voucher"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={systemVoucherFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"System Voucher"}
        />
      </AccordianItem>
    </div>
  );
};
