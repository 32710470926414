import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { DeleteIcon } from "../customSvgIcons/deleteIcon";
import { colors } from "../../../constants/colors";
import { DeleteModalBody } from "./DeleteAlertContent";

type DeleteModalProps = {
  deleteMessageContent: React.ReactNode;
  deleteModalTitle: string;
  onSubmit: () => void;
  onCancel: () => void;
  isSubmitDisabled: boolean;
  hideButtonText?: boolean;
  isButtonDisabled?: boolean;
  disabledActionFunction?: () => void;
  isDropdown?: boolean;
};

export const DeleteAlertModal = (props: DeleteModalProps) => {
  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <DeleteModalBody {...props} onCancel={onCloseModal} />;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span className={`delete d-flex align-items-center ${props.isDropdown ? "dropdown-item " : ""}`}>
          <DeleteIcon
            width="18px"
            height="18px"
            color={colors.tomato}
            margin="0px 5px 0px 0px"
          />
          {props.hideButtonText ? "" : "Delete"}
        </span>
      }
      isButtonDisabled={props.isButtonDisabled}
      buttonClass="no-appearance w-100 p-0"
      modalTitle={props.deleteModalTitle}
      isCloseButtonEnabled={true}
      disabledActionFunction={props.disabledActionFunction}
    />
  );
};
