import { useState } from "react";
import { Card } from "../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../common/components/htmlTags/Card/CardHeader";
import { Container } from "../../common/components/htmlTags/Container";
import { SmtpServerConfigForm } from "./components/smtpServerConfigForm/SmtpServerConfigForm";
import { useSmtpSettings } from "./hooks/useSmptSettings";
import { PrimaryButton } from "../../common/components/button/PrimaryButton";
import { Form } from "../../common/components/ui";
import "./Smtpsettings.css";
import { RadioButtonGroup } from "../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Span } from "../../common/components/htmlTags/Span";
import { SecondaryButton } from "../../common/components/button/SecondaryButton";

export const SmtpServerConfig = () => {
  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
    validateForm,
    setFieldTouched,
  } = useSmtpSettings();
  const [isSetup, setIsSetup] = useState(false);
  return (
    <Card wrapperClass="payment-gateway-config-card">
      <CardHeader>SMTP Server Configuration</CardHeader>
      <Form name="">
        <Container className="smtp-settings-config-and-form-fields-wrapper">
          <Container className="smtp-server-settings-selection">
            <Span>SMTP Server</Span>
            <Span>
              <RadioButtonGroup
                name="is_active"
                id="is_active"
                valuesArray={[
                  { value: "false", label: "Default System SMTP Server" },
                  { value: "true", label: "Configured SMTP Server" },
                ]}
                value={values.is_active}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                onClick={(value) => {
                  setFieldValue("is_active", JSON.parse(value));
                }}
                isDisabled={false}
              />
            </Span>
          </Container>
          {values.is_active && (
            <Container className="smtp-server-configuration-form-wrapper">
              <SmtpServerConfigForm
                setIsSetup={setIsSetup}
                values={{
                  ...values,
                  encryption_type: values.encryption_type as any,
                  port: Number(values.port),
                }}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isSetup={isSetup}
                isValid={isValid}
                validateForm={validateForm}
              />
            </Container>
          )}
          <Container className="mt-3">
            <PrimaryButton
              type="submit"
              isDisabled={false}
              onClick={(e) => {
                setIsSetup(false);
                handleSubmit(e);
              }}
            >
              {"Save"}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              isDisabled={false}
              onClick={() => {
                setIsSetup(false);
              }}
              buttonContent="Cancel"
            />
          </Container>
        </Container>{" "}
      </Form>
    </Card>
  );
};
