import { useParams } from "react-router-dom";
import { JVItemValues } from "../../../../../types";
import useCommonData from "../../../../hooks/useCommon";
import { systemVoucherDetails } from "../../systemVoucherSlice";
import { svInitialValues } from "../helpers/StateInitialization";
import { setLoaderState } from "../../../../common/commonSlice";

export const useSVFormIntitialization = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { editId } = useParams();
  const fetchSVDetails = async (setSVValues: React.SetStateAction<any>) => {
    dispatch(setLoaderState(true));
    let details: any = {};
    const responseAction = await dispatch(
      systemVoucherDetails({
        systemVoucherId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      details = {
        ...response,
        file_ids: response.journal_files.map((file: any) => file.id),
        email_to: response.email_to.map((email: any) => email.email),
        system_config_id: response.system_voucher_config_id,
        item_details: response.item_details.map(
          (item: JVItemValues, index: number) => {
            return {
              ...item,
              index: index,
            };
          }
        ),
      };
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
    setSVValues(details);
    return editId ? details : svInitialValues;
  };
  return { fetchSVDetails };
};
