import PredefinedDateRangePicker from "../../../../home/reports/componets/PredefinedDateRange";
import Checkbox from "@mui/material/Checkbox";
import { FormSelectField } from "../../formSelectField/FormSelectField";
import { customSelectStyle } from "../../SelectCustomStyle";
import { SaveButton } from "../../button/SaveButton";
import { SecondaryButton } from "../../button/SecondaryButton";
import "../CustomizeReport.css";
import { useFormik } from "formik";
import { customizePLOptions } from "../OptionConstants";
import { PLDateArrayHelper } from "./PLfilterHelper";
import { useEffect, useState } from "react";
import { CustomizeReportPLValidation } from "./CustomizeReportValidation";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  reportCustomizationFilterSelector,
  reportTableFilterSelector,
  selectedTagsDataSelector,
  setDateRange,
  setReportCustomizationFilters,
  setReportCustomizationFlag,
  setReportTableFilters,
  setSelectedTagsData,
} from "../../../../home/reports/ReportSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import { CheckedBox, ObjectType } from "../../../../../types";
import { InfoMessage } from "../../infos/InfoMessage";
import { ReportTagDropDown } from "../../reportingTagDropDown/reportingTagDropDown";
import {
  getReportingTagDropdown,
  reportingTagsListSelector,
} from "../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { currentUserSelector } from "../../../commonSlice";
import { Container } from "../../htmlTags/Container";

type Props = {
  handleClose: () => void;
  handleReport?: (type: string, plCustomizationValues?: any) => void;
};
export const CustomizeProfitAndLossForm = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const reportCustomizationValues = useAppSelector(
    reportCustomizationFilterSelector
  );
  const PLFilterValues = useAppSelector(
    reportCustomizationFilterSelector
  ).profitAndLossFilters;
  const tableFilterValues = useAppSelector(reportTableFilterSelector);
  const dispatch = useAppDispatch();
  const options = {
    account_filter: customizePLOptions.filterAccountsOptions,
    report_basis: customizePLOptions.reportBasisOptions,
    compare_with: customizePLOptions.compareWithOptions,
    compare_value: customizePLOptions.compareWithValueOptions,
  };
  const [selectedTags, setSelectedTags] = useState<CheckedBox[]>([]);
  const reportingTagsDropdownList = useAppSelector(reportingTagsListSelector);
  const selectedTagsData = useAppSelector(selectedTagsDataSelector);
  const selectInfoMessage = (accountFilter: string) => {
    switch (accountFilter) {
      case "Accounts without zero balance":
        return "The report will only include accounts with non-zero balance";
        break;
      case "Accounts with transactions":
        return "The report will include only the accounts with transactions for the filtered period";
        break;
      case "All accounts":
        return "The report will include all the accounts in the chart of accounts";
        break;
      default:
        return "";
        break;
    }
  };
  const formik = useFormik({
    initialValues: {
      start_date: tableFilterValues.start_date
        ? tableFilterValues.start_date
        : PLFilterValues.start_date,
      end_date: tableFilterValues.end_date
        ? tableFilterValues.end_date
        : PLFilterValues.end_date,
      date_range: tableFilterValues.start_date
        ? tableFilterValues.date_range
        : PLFilterValues.date_range,
      account_filter: PLFilterValues.account_filter,
      report_basis: PLFilterValues.report_basis,
      compare_with: PLFilterValues.compare_with,
      compare_value: PLFilterValues.compare_value,
      is_descending: PLFilterValues.is_descending,
      compare_list: PLFilterValues.compare_list,
      tag_option_in: PLFilterValues.tag_option_in
        ? PLFilterValues.tag_option_in
        : [],
    },
    validationSchema: CustomizeReportPLValidation,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        setReportTableFilters({
          ...tableFilterValues,
          start_date: values.start_date,
          end_date: values.end_date,
          date_range: values.date_range,
          tag_option_in: values.tag_option_in,
        })
      );
      dispatch(
        setReportCustomizationFilters({
          ...reportCustomizationValues,
          profitAndLossFilters: {
            ...values,
            compare_list: values.compare_list,
          },
        })
      );
      dispatch(setSelectedTagsData(selectedTags));
      dispatch(setReportCustomizationFlag(true));
      props.handleReport && props.handleReport("submit", values);
      props.handleClose();
    },
  });
  useEffect(() => {
    formik.setFieldValue("compare_list", [
      ...PLDateArrayHelper(
        formik.values.compare_with,
        Number(formik.values.compare_value),
        {
          start: tableFilterValues.start_date
            ? tableFilterValues.start_date
            : "",
          end: tableFilterValues.end_date,
          date_range: tableFilterValues.date_range,
        }
      ),
    ]);
  }, [
    formik.values.date_range,
    tableFilterValues.date_range,
    formik.values.compare_with,
    formik.values.compare_value,
    tableFilterValues.start_date,
    tableFilterValues.end_date,
  ]);
  useEffect(() => {
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  }, [currentUserInfo.organization_id]);
  useEffect(() => {
    if (
      PLFilterValues?.tag_option_in &&
      PLFilterValues.tag_option_in?.length > 0
    ) {
      let prevSelectedTags = JSON.parse(JSON.stringify(selectedTagsData));
      setSelectedTags(prevSelectedTags);
    } else {
      dispatch(setSelectedTagsData([]));
      setSelectedTags([]);
    }
  }, [PLFilterValues]);

  useEffect(() => {
    if (formik.values.report_basis === "Accrual") {
      let optionIds: any = [];
      selectedTags.map((item) => {
        optionIds = [...optionIds, ...item.optionIDs];
      });
      formik.setValues({
        ...formik.values,
        tag_option_in: optionIds,
      });
    } else {
      formik.setValues({
        ...formik.values,
        tag_option_in: [],
      });
    }
  }, [selectedTags, formik.values.report_basis]);
  return (
    <form id="customize-report-form" onSubmit={formik.handleSubmit}>
      <div className="customize-report-wrapper">
        <PredefinedDateRangePicker
          isSingleDatePicker={false}
          isAdvanced={true}
          defaultValue="quarter"
          reportType="SOA"
          resetType={""}
          key="SOA"
          isInModal={true}
          handleDateRange={() => {}}
          filterdata={{
            startDate: formik.values.start_date,
            endDate: formik.values.end_date,
            dateRrange: formik.values.date_range,
          }}
        />
        <Container className="w-100">
          <InfoMessage
            message={selectInfoMessage(formik.values.account_filter)}
          />
        </Container>
        <Container className="row">
          <Container className="col">
            <FormSelectField
              name="account_filter"
              id="account_filter"
              className="form-select customize-report-select"
              isDisabled={false}
              isOnlyInRow={true}
              styles={customSelectStyle}
              placeholder="Choose an option"
              isRequired={false}
              isSearchable={true}
              error=""
              label="Filter Accounts"
              onChange={(e) => {
                formik.setFieldValue("account_filter", e && e.label);
              }}
              options={options.account_filter}
              value={{
                value: formik.values.account_filter,
                label: formik.values.account_filter,
              }}
            />
          </Container>
          <Container className="col">
            <FormSelectField
              name="report_basis"
              id="report_basis"
              className="form-select customize-report-select"
              isDisabled={false}
              isOnlyInRow={true}
              styles={customSelectStyle}
              placeholder="Choose an option"
              isRequired={false}
              isSearchable={true}
              error=""
              label="Report Basis"
              onChange={(e) =>
                formik.setFieldValue("report_basis", e && e.value)
              }
              options={options.report_basis}
              value={{
                value: formik.values.report_basis,
                label: formik.values.report_basis,
              }}
            />
          </Container>
        </Container>
        {formik.values.report_basis === "Accrual" &&
          currentUserInfo.is_tags_enabled && (
            <div className="select-field-wrap col">
              <label className="fw-bold">Reporting Tags</label>
              <ReportTagDropDown
                data={reportingTagsDropdownList}
                checked={selectedTags}
                setChecked={setSelectedTags}
              />
            </div>
          )}
        <Container className="row">
          <Container className="col">
            <FormSelectField
              name="compare_with"
              id="compare_with"
              className="form-select customize-report-select"
              isDisabled={false}
              isOnlyInRow={true}
              styles={customSelectStyle}
              placeholder="Choose an option"
              isRequired={false}
              isSearchable={true}
              error=""
              label="Compare With"
              onChange={(e) => {
                formik.setFieldValue("compare_with", e && e.label);
              }}
              options={options.compare_with}
              value={{
                value: formik.values.compare_with,
                label: formik.values.compare_with,
              }}
            />
          </Container>
          <Container className="col">
            {formik.values.compare_with !== "" && (
              <FormSelectField
                name="compare_value"
                id="compare_value"
                className="form-select customize-report-select"
                isDisabled={false}
                isOnlyInRow={true}
                styles={customSelectStyle}
                placeholder="Choose an option"
                isRequired={false}
                isSearchable={true}
                error={formik.errors.compare_value}
                label={`Number of ${formik.values.compare_with}`}
                onChange={(e) =>
                  formik.setFieldValue("compare_value", e && e.value)
                }
                options={options.compare_value}
                value={{
                  label: formik.values.compare_value,
                  value: formik.values.compare_value,
                }}
              />
            )}
          </Container>
        </Container>
        {formik.values.compare_with !== "" && (
          <div className="col">
            <span className="d-flex align-items-center justify-content-left mb-3">
              <Checkbox
                value={formik.values.is_descending}
                onChange={(e) => {
                  formik.setFieldValue("is_descending", e.target.checked);
                }}
                checked={formik.values.is_descending}
              />
              Arrange period/year from latest to oldest.
            </span>
          </div>
        )}
        <div className="customize_report_btn_group">
          <SaveButton
            type="submit"
            buttonContent="Save"
            onClick={() => {}}
            isDisabled={formik.isSubmitting}
          />
          <SecondaryButton
            type={"button"}
            buttonContent="Cancel"
            onClick={() => {
              dispatch(
                setDateRange({ start_date: "", end_date: "", date_range: "" })
              );
              props.handleClose()}}
            isDisabled={formik.isSubmitting}
          />
        </div>
      </div>
    </form>
  );
};
