import { SecondaryButton, SaveButton } from "../button";
import "./TransactionEditTagRemovalAlert.css";

type Props = {
  module: string;
  onSubmit: () => void;
  onCancel: () => void;
  is_transaction_associated?: boolean;
};
export const TransactionEditTagRemovalAlert = (props: Props) => {
  const messageSelector = () => {
    if (props.is_transaction_associated) {
      switch (props.module) {
        case "bill":
          return `Updating the bill will reset all previously assigned tags, including those linked to the payments made. You will need to reassign them manually. Are you sure you want to proceed?`;
        case "invoice":
          return `Updating the invoice will reset all previously assigned tags, including those linked to the payments received. You will need to reassign them manually. Are you sure you want to proceed?`;
        default:
          return `Updating the ${props.module} will remove all previously assigned tags. Are you sure you wish to proceed?`;
      }
    } else {
      return `Updating the ${props.module} will remove all previously assigned tags. Are you sure you wish to proceed?`;
    }
  };
  return (
    <div className="transaction-edit-tag-removal-alert-modal-body-wrapper">
      <div className="transaction-edit-tag-removal-alert-content">
        <div className="">
          {messageSelector()}
          <div className="w-100 d-flex justify-content-end mt-3">
            {" "}
            <SaveButton
              type="button"
              onClick={() => {
                props.onSubmit();
                props.onCancel();
              }}
              isDisabled={false}
              buttonContent="Yes Proceed"
            />
            <SecondaryButton
              type="button"
              buttonContent="Cancel"
              onClick={() => {
                props.onCancel();
              }}
              isDisabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
