import { Link } from "react-router-dom";
import TdsFormContainer from "../../../../../home/tds/components/tdsForm/TdsFormContainer";
import { Container } from "../../../htmlTags/Container";
import "../TdsSelect.css";
import { useTDSSelect } from "../TdsSelectProvider";
import { constants } from "../constants";
import { TdsDropdownProps } from "../types";
import Input from "../../../ui/formElements/Input/Input";
import List from "../../../ui/formElements/Ul/Ul";
import Li from "../../../ui/formElements/Li/Li";
import { useEffect } from "react";
import Select, { components } from "react-select";
import { customSelectStyle } from "../../../SelectCustomStyle";

export const TdsSelectDropdownComponent = (props: TdsDropdownProps) => {
  const { fetchTDSSelectOptions, tdsTaxes, setIsPercentage } = useTDSSelect();
  let isAddNewButtonShow = false;
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let tdsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TDS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  if (tdsPermission.includes("test")) {
    isAddNewButtonShow = true;
  }

  useEffect(() => {
    resetDropDown();
  }, []);
  // useEffect(() => {
  //   $(".dropdown-toggle").dropdown("hide");
  // }, [props.error]);
  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  const CreatableOption = (props: any) => (
    <components.Option {...props}>
      <TdsFormContainer
        id={0}
        isEdit={false}
        refreshList={fetchTDSSelectOptions}
        createdFrom="transaction_page"
        label={constants.labels.NEW_TDS}
      />
    </components.Option>
  );

  return (
    <>
      <Container className="tds-dropdown-wrapper">
        <Container
          className={`btn-group tds-input-dropdown-wrap taxcode-dropdown ${
            props.dropdownPosition === "top"
              ? "tds-tcs-dropdown-total dropup"
              : ""
          }`}
        >
          {/* <Input
            type="text"
            name="tax_id"
            className="search-dropdown dropdown-toggle no-border"
            placeholder={constants.placeholders.TDS_SELECT}
            dataBsToggle="dropdown"
            value={props.item.tds_id ? props.item.tds_name : ""}
            autoComplete="off"
            onKeyDown={(e) => {
              let code = e.keyCode || e.which;
              if (code !== 9) {
                $(e.target).dropdown("show");
              }
            }}
            id={`tax-code-${props.item.tds_id}`}
            disabled={props.disabled ? props.disabled : false}
          />
          <List wrapperClass="dropdown-menu dropdown-menu-start">
            <>
              {" "}
              {tdsTaxes.map((tds, index) => {
                return (
                  <Li key={index}>
                    <Link
                      className={`dropdown-item ${
                        props.item.tds_id === tds.id ? "active" : ""
                      }`}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        props.handleTDSChange(
                          tds.id,
                          tds.rate,
                          tds.tax_name,
                          tds.is_percentage,
                          props.index
                        );
                        setIsPercentage(tds.is_percentage);
                      }}
                    >
                      <span className="itemLabel">{tds.tax_name}</span>
                    </Link>
                  </Li>
                );
              })}
              {isAddNewButtonShow && (
                <Li key={"add-tds"}>
                  <Link
                    to="#"
                    className="dropdown-item add-link-option"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <TdsFormContainer
                      id={0}
                      isEdit={false}
                      refreshList={fetchTDSSelectOptions}
                      createdFrom="transaction_page"
                      label={constants.labels.NEW_TDS}
                    />
                  </Link>
                </Li>
              )}
            </>
          </List> */}
          <Select
            name="tds_id"
            id="tds_id"
            placeholder={constants.placeholders.TDS_SELECT}
            options={tdsTaxes.map((tax) => {
              return {
                label: tax?.tax_name,
                value: tax.id,
              };
            })}
            value={(() => {
              const tds = tdsTaxes.find((tax) => tax.id === props.item.tds_id);
              if (tds) {
                return {
                  label: tds.tax_name,
                  value: tds.id,
                };
              }
              return null;
            })()}
            className="state-select form-select custom-select"
            isDisabled={props.disabled ? props.disabled : false}
            styles={{
              ...customSelectStyle,
              menuList:(provided: any, state: any) => ({
                ...provided,
                height: tdsTaxes?.length>0?"150px":"none",
                padding: "4px 12px"
              }),
            }}
            onChange={(e) => {
              if (e) {
                const tds = tdsTaxes.find((tax) => tax.id === e.value);
                if (tds) {
                  props.handleTDSChange(
                    tds.id,
                    tds.rate,
                    tds.tax_name,
                    tds.is_percentage,
                    props.index
                  );
                  setIsPercentage(tds.is_percentage);
                }
              }
            }}
            // components={{ Option: CreatableOption}}
          />
        </Container>
      </Container>
    </>
  );
};
