import React, { useCallback, useEffect, useMemo, useState } from "react";
import AgingReportTable from "./AgingReportTable";
import AgingReport, { initialAgingReportData } from "./AgingReport";
import useCommonData from "../../../../hooks/useCommon";
import { ObjectType, TableData } from "../../../../../types";
import {
  DateFormatHandler,
  getFormatedDate,
  getProfileFormatedDate,
} from "../../../../../helpers/helper";
import { setLoaderState } from "../../../../common/commonSlice";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { Cell, Row } from "react-table";
import { useAppSelector } from "../../../../../app/hooks";
import {
  reportCustomizationFilterSelector,
  setAgingReportCustomizationFilters,
  setReportCustomizationFilters,
} from "../../ReportSlice";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { useLocation } from "react-router-dom";

type Props = {
  title: string;
  generateReport: (values: any) => Promise<any>;
  type: string;
};
function AgingDetails(props: Props) {
  const location = useLocation();
  const reportRef = React.useRef<any>([]);
  const { dispatch, currentUserInfo, orgCurrencyList, navigate } =
    useCommonData();
  const [orgCurrencyCode, setOrgCurrencyCode] = useState("");
  const [reportData, setReportData] = useState<any>(initialAgingReportData);
  const [showReport, setShowReport] = useState(true);
  const { subModulesWithPermission } = usePermissionHandler();
  const agingReportFilter = useAppSelector(
    reportCustomizationFilterSelector
  ).agingReportFilters;
  const [filterString, setFilterString] = useState("");
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  useEffect(() => {
    setOrgCurrencyCode(
      orgCurrencyList.filter((item: ObjectType) => {
        return item.id === reportData.currency_id;
      })[0]?.code
    );
  }, [reportData.currency_id]);

  useEffect(() => {
    return () => {
      dispatch(
        setReportCustomizationFilters({
          agingReportFilters: {
            start_date: "",
            end_date: "",
            date_range: "",
            aging_by: "",
            number_of_columns: "",
            interval_range: "",
            group_by: "",
            show_by: "",
            customer_id: null,
            vendor_id: null,
            currency_id: 0,
            name: "",
            id: 0,
          },
        })
      );
    };
  }, []);

  const initialFiltervalues = {
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    date_range: filters ? filters.date_range : "today",
    end_date: filters ? filters.end_date : getFormatedDate(),
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
  };

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    )
      getReport("initial");
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const handleReport = (type: string, argFilters: any = {}) => {
    getReport(type, argFilters);
  };
  const getReport = useCallback(
    async (type: string, argFilters = {}) => {
      dispatch(setLoaderState(true));
      let filters = {
        ...reportRef.current?.filterValues,
        ...agingReportFilter,
        ...argFilters,
        organization_id: reportRef.current?.filterValues.organization_id,
      };
      if (type === "initial") filters = initialFiltervalues;
      const tempFilterString = JSON.stringify(filters);
      const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
      setFilterString(hashFragment);
      const responseAction = await props.generateReport(filters).finally(() => {
          dispatch(setLoaderState(false));
        });
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          let reportDetails = response;
          setReportData(reportDetails);
          
          setShowReport(true);
          dispatch(
            setAgingReportCustomizationFilters({
              start_date: "",
              end_date: reportDetails.end_date,
              date_range: reportDetails.date_range,
              aging_by: reportDetails.aging_by,
              number_of_columns: reportDetails.number_of_columns,
              interval_range: reportDetails.interval_range,
              interval_type: reportDetails.interval_type,
              group_by: reportDetails.group_by,
              show_by: reportDetails.show_by,
              customer_id: reportDetails.customer_id,
              vendor_id: reportDetails.vendor_id,
              organization_id: reportDetails.organization_id,
              // organization_name: reportDetails.organization_name,
              currency_id: reportDetails.currency_id,
            })
          );
        }
      }
      
    },
    [agingReportFilter, currentUserInfo.organization_id]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        className: "date text-align-left",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        className: "org",
      },
      {
        Header: "Transaction#",
        accessor: "show.transactionNumber",
        className: "column-navigation transaction-number text-align-left",
      },
      {
        Header: "Type",
        accessor: "show.type",
        className: "type text-align-left",
      },
      {
        Header: "Status",
        accessor: "show.status",
        className: "column-navigation status text-align-left",
      },
      {
        Header: props.type === "debtor" ? "Customer Name" : "Vendor Name",
        accessor: "show.name",
        className: "column-navigation consumer-name text-align-left",
      },
      {
        Header: "Age",
        accessor: "show.age",
        className: "age text-align-left",
      },
      {
        Header: "Amount",
        accessor: "show.amount",
        className: "column-navigation amount text-align-right",
      },
      {
        Header: "Balance Due",
        accessor: "show.balanceDue",
        className: "column-navigation balance text-align-right",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    []
  );
  const formateNumber = (value: number | string, currencyCode?: string) => {
    let formateValue = NumberFormat(
      Number(value),
      orgCurrencyCode,
      orgCurrencyList
    );
    let code = currencyCode ? currencyCode : reportData.currency_code;
    return code + " " + formateValue;
  };
  let current =
    reportData &&
    reportData.group_by === "None" &&
    reportData.aging_report.filter((item: any) => item.age_group === "current")
      .length
      ? [
          {
            show: {
              date: "CURRENT",
              amount: formateNumber(reportData.totals?.current),
              balanceDue: formateNumber(reportData.balance_due_totals?.current),
            },
            className: "customRowTitle light-offwhite-bg no-click-action",
          },
        ]
      : [];

  const showTransactions = (data: any) => {
    return data.map((itemData: any, index: number) => ({
      show: {
        date: getProfileFormatedDate(
          currentUserInfo.organization_date_format,
          itemData?.transaction_date
        ),
        transactionNumber: joinTransactionNumber(itemData?.transaction_number),
        organization: itemData.organization_name,
        type: itemData.transaction_type,
        status: itemData.transaction_status,
        name:
          props.type === "debtor"
            ? itemData.customer_name
            : itemData.vendor_name,
        age: itemData.age + " " + reportData.interval_type,
        amount: formateNumber(itemData.total),
        balanceDue: formateNumber(itemData.balance_due),
      },
      action:
        itemData.transaction_type === "Invoice"
          ? {
              transUrl: `/invoice/detail/${itemData.transaction_id}`,
              transPermission: subModulesWithPermission.includes("Invoices"),
              userUrl: `/customers/detail/${itemData.customer_id}`,
              userPermission: subModulesWithPermission.includes("Customer"),
            }
          : itemData.transaction_type === "Bill"
          ? {
              transUrl: `/bill/detail/${itemData.transaction_id}`,
              transPermission: subModulesWithPermission.includes("Bills"),
              userUrl: `/vendors/detail/${itemData.vendor_id}`,
              userPermission: subModulesWithPermission.includes("Vendor"),
            }
          : { url: "", permission: "" },
    }));
  };
  let transactionsCurrent = () => {
    let currentReport = reportData.aging_report
      ? reportData.aging_report.filter(
          (item: any) => item.age_group === "current"
        )
      : [];
    return showTransactions(currentReport);
  };

  let total = reportData?.aging_report?.length
    ? [
        {
          show: {
            age: "Total",
            amount: formateNumber(
              reportData.group_by === "None"
                ? reportData.totals?.total
                : reportData.totals
            ),
            balanceDue: formateNumber(
              reportData.group_by === "None"
                ? reportData.balance_due_totals?.total
                : reportData.balance_due_totals
            ),
          },
          className: "customRowTitle total-row no-click-action",
        },
      ]
    : [];

  const getAmount = (range: string, groupBy: string, reportTotal?: any) => {
    const entries =
      groupBy === "None"
        ? Object.entries(reportData.totals)
        : Object.entries(reportTotal);
    for (const [key, value] of entries) {
      if (key.includes(range)) {
        return formateNumber(Number(value));
      }
    }
    return 0;
  };

  const getBalanceDue = (
    range: string,
    groupBy: string,
    balanceDueTotal?: any
  ) => {
    const entries =
      groupBy === "None"
        ? Object.entries(reportData.balance_due_totals)
        : Object.entries(balanceDueTotal);
    for (const [key, value] of entries) {
      if (key.includes(range)) {
        return formateNumber(Number(value));
      }
    }
    return 0;
  };

  const getData = () => {
    let numberOfColumns = reportData.number_of_columns;
    let intervalRange = reportData.interval_range;
    const intervalType = reportData.interval_type;
    const intervalArray: string[] = [`1_${intervalRange}`];
    let customReportData = [];
    if (numberOfColumns) {
      while (numberOfColumns !== 1) {
        numberOfColumns === 2
          ? intervalArray.push(`ago_${intervalRange}`)
          : intervalArray.push(
              `${intervalRange + 1}_${
                intervalRange + reportData.interval_range
              }`
            );
        intervalRange = intervalRange + reportData.interval_range;
        numberOfColumns--;
      }
      let rangeStart = 1;
      let rangeEnd = reportData.interval_range;
      if (
        reportData.group_by === "Currency" ||
        reportData.group_by === "Customer" ||
        reportData.group_by === "Vendor"
      ) {
        for (let reportItem of reportData.aging_report) {
          customReportData.push({
            show: {
              date:
                reportData.group_by === "Currency"
                  ? reportItem.currency_code
                  : reportData.group_by === "Customer"
                  ? reportItem.customer_name
                  : reportItem.vendor_name,
              amount: formateNumber(reportItem.total),
              balanceDue: formateNumber(reportItem.balance_due),
            },
            className: "customRowTitle light-offwhite-bg no-click-action",
          });
          rangeStart = 1;
          rangeEnd = reportData.interval_range;
          for (let intervalRange of intervalArray) {
            const entries = Object.entries(reportItem.report_total);
            for (const [key, value] of entries) {
              if (key.includes(intervalRange)) {
                customReportData.push({
                  show: {
                    date: intervalRange.includes("ago")
                      ? `>${rangeStart - 1} ${intervalType}`
                      : `${rangeStart}-${rangeEnd} ${intervalType}`,
                    amount: getAmount(
                      intervalRange,
                      "Currency",
                      reportItem.report_total
                    ),
                    balanceDue: getBalanceDue(
                      intervalRange,
                      "Currency",
                      reportItem.report_balance_due_total
                    ),
                  },
                  className: "customRowTitle light-offwhite-bg no-click-action",
                });
                let filteredReport = reportItem.report
                  .filter(
                    (agingReport: any) =>
                      agingReport.age_group ===
                      `${intervalType}_${intervalRange}`
                  )
                  .map((report: any) => report);
                if (filteredReport.length) {
                  customReportData.push(...showTransactions(filteredReport));
                }
              }
            }
            rangeStart = rangeStart + reportData.interval_range;
            rangeEnd = rangeEnd + reportData.interval_range;
          }
          const entries = Object.entries(reportItem.report_total);
          for (const [key, value] of entries) {
            if (key.includes("current")) {
              customReportData.push({
                show: {
                  date: "CURRENT",
                  amount: getAmount(
                    "current",
                    "Currency",
                    reportItem.report_total
                  ),
                  balanceDue: getBalanceDue(
                    "current",
                    "Currency",
                    reportItem.report_balance_due_total
                  ),
                },
                className: "customRowTitle light-offwhite-bg no-click-action",
              });
              let filteredReport = reportItem.report
                .filter(
                  (agingReport: any) => agingReport.age_group === "current"
                )
                .map((report: any) => report);
              if (filteredReport.length) {
                customReportData.push(...showTransactions(filteredReport));
              }
            }
          }
        }
      }
      if (reportData.group_by === "None") {
        for (let intervalRange of intervalArray) {
          const amount = getAmount(intervalRange, "None");
          const amountSplitArray = amount.toString().split(" ");
          const balanceDue = getBalanceDue(intervalRange, "None");
          const balanceDueSplitArray = balanceDue.toString().split(" ");
          if (
            (amountSplitArray.length > 1 &&
              Number(amountSplitArray[1]) !== 0) ||
            (balanceDueSplitArray.length > 1 &&
              Number(balanceDueSplitArray[1]) !== 0)
          ) {
            customReportData.push({
              show: {
                date: intervalRange.includes("ago")
                  ? `>${rangeStart - 1} ${intervalType}`
                  : `${rangeStart}-${rangeEnd} ${intervalType}`,
                amount: amount,
                balanceDue: balanceDue,
              },
              className: "customRowTitle light-offwhite-bg no-click-action",
            });
          }
          let filteredReport = reportData.aging_report
            .filter(
              (agingReport: any) =>
                agingReport.age_group === `${intervalType}_${intervalRange}`
            )
            .map((report: any) => report);
          if (filteredReport.length) {
            customReportData.push(...showTransactions(filteredReport));
          }
          rangeStart = rangeStart + reportData.interval_range;
          rangeEnd = rangeEnd + reportData.interval_range;
        }
      }
    }
    return customReportData;
  };
  let data = [...getData(), ...current, ...transactionsCurrent(), ...total];
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (cell.column.Header) {
      let action = row?.values?.action;
      if (
        cell.column.Header === "Transaction#" ||
        cell.column.Header === "Status" ||
        cell.column.Header === "Amount" ||
        cell.column.Header === "Status" ||
        cell.column.Header === "Balance Due"
      ) {
        if (action?.transUrl && action?.transPermission) {
          navigate(`?${filterString}`);
          navigate(`${action?.transUrl}${filterString}`);
        }
      } else if (
        cell.column.Header === "Customer Name" ||
        cell.column.Header === "Vendor Name"
      ) {
        if (action?.userUrl && action?.userPermission) {
          navigate(`?${filterString}`);
          navigate(`${action?.userUrl}${filterString}`);
        }
      }
    }
  };
  return (
    <AgingReport
      title={props.title}
      handleReport={handleReport}
      reportData={reportData}
      clearReport={() => setShowReport(false)}
      showReport={showReport}
      type={props.type}
      module={"aging_details"}
      reportRef={reportRef}
    >
      <AgingReportTable
        columns={columns}
        data={data}
        className="navigation"
        navigation={navigation}
        haveColumnClassName={true}
        hiddenColumns={[]}
      />
    </AgingReport>
  );
}

export default AgingDetails;
