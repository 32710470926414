import { useMemo } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { JournalDetailValues } from "../../../../../types";
import {
  DateFormatHandler,
  getProfileFormatedDate,
} from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import FileList from "../../../../common/components/FileList";
import Subject from "../../../../common/components/detailsPage/Subject";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  journalData: JournalDetailValues;
};

const JournalVoucherDetailBody: React.FC<Props> = ({ journalData }) => {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const isAuditLogEnabled = process.env.REACT_APP_AUDIT_LOG_ENABLED;
  const { currentUserInfo } = useCommonData();
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "ACCOUNT",
        accessor: "show.account",
        className: "jv_account",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "DESCRIPTION",
        accessor: "show.description",
        className: "jv_description",
      },
      {
        Header: "DEBITS",
        accessor: "show.debits",
        className: "jv_debit",
      },
      {
        Header: "CREDITS",
        accessor: "show.credits",
        className: "jv_credit",
      },
    ],
    []
  );

  /**
   * set table row data
   */
  const data = journalData.item_details
    ? journalData.item_details.map((itemData, index) => ({
        show: {
          account: itemData.account_name,
          description: itemData.description,
          debits: itemData.debit
            ? journalData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.debit),
                journalData.currency_code,
                orgCurrencyList
              )
            : "",
          credits: itemData.credit
            ? journalData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.credit),
                journalData.currency_code,
                orgCurrencyList
              )
            : "",
        },
      }))
    : [];

  return (
    <>
      <div id="invoice" className="jv-preview transaction-preview">
        <div className="jv-detail-header detail-header d-flex justify-content-between">
          <div className="header-details">
            <h1 className="detil-page-title">Journal Entry</h1>
            <div className="jv-record-details header-item-summary">
              <div className="voucher-number item-summary-col">
                <div className="summary-item-title">Journal Entry #</div>
                <div className="summary-item-value">
                  {joinTransactionNumber(journalData.journal_voucher_number)}
                </div>
              </div>
              <div className="jv-amount item-summary-col">
                <div className="summary-item-title">Amount</div>
                <div className="summary-item-value">
                  {journalData.currency_code +
                    " " +
                    NumberFormat(
                      Number(journalData.total_details.total_amount_credit),
                      journalData.currency_code,
                      orgCurrencyList
                    )}
                </div>
              </div>
              <div className="jv-date item-summary-col">
                <div className="summary-item-title">Date</div>
                <div className="summary-item-value">
                  {getProfileFormatedDate(
                    currentUserInfo.organization_date_format,
                    journalData.date
                  )}
                </div>
              </div>
              {journalData.reference_number ? (
                <div className="jv-ref item-summary-col">
                  <div className="summary-item-title">Reference #</div>
                  <div className="summary-item-value">
                    {journalData.reference_number}
                  </div>
                </div>
              ) : null}
              <div className="jv-createdby item-summary-col">
                <div className="summary-item-title">Created By</div>
                <div className="summary-item-value">
                  {journalData.created_by}
                </div>
              </div>
              {journalData.is_words_enabled && (
                <div className="jv-total-amount-in-words item-summary-col">
                  <div className="summary-item-title">Amount in Words:</div>
                  <div className="summary-item-value">
                    {journalData.total_details.amount_in_words} Only
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <RowWiseFieldView
          mapObject={journalData.custom_fields}
          module="Journal Entry"
        />
        {journalData.notes ? (
          <Subject label="Notes" subject={journalData.notes} />
        ) : null}
        <div className="table-section jv-table-section pb-0">
          <DetailsTableContainer
            columns={columns}
            data={data}
            className="table fixed-table jv-preview-table"
          />
        </div>
        {journalData.journal_files.length ? (
          <FileList files={journalData.journal_files} />
        ) : null}
        {isAuditLogEnabled === "true" && (
          <AuditTrail
            title="Audit Trail"
            transactionType="journal_vouchers"
            transactionId={Number(journalData.id)}
          />
        )}
      </div>
    </>
  );
};
export default JournalVoucherDetailBody;
