import React, { useId, useState } from "react";
import Select, { FormatOptionLabelMeta, SingleValue } from "react-select";
import "../../../../../../ColorVariables.css";
import { FormSelectOption, OptionType } from "../../../../../../types";
import "./FormSelect.css";
import { useCustomSelectStyles } from "./hooks/useCustomSelectStyles";
import CustomMenu from "./CustomMenu";
import CustomOption from "./CustomOption";

type Props = {
  name: string;
  id?: string;
  defaultValue?: FormSelectOption | FormSelectOption[] | null;
  value?: FormSelectOption | FormSelectOption[] | null;
  options: FormSelectOption[];
  selectClass?: string;
  wrapperClass?: string;
  error?: string;
  errorClass?: string;
  isDisabled?: boolean;
  // isMulti?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  inputValue?: string;
  maxMenuHeight?: number;
  menuPlacement?: "bottom" | "auto" | "top";
  ariaLabel?: string;
  ariaLabelledby?: string;
  classNamePrefix?: string | null;
  autoFocus?: boolean;
  placeholder: string;
  noOptionsMessage?: string;
  inputId?: string;
  selectBackgroundColor?: string;
  controlHeight?: string;
  focusBorderColor?: string;
  focusTextColor?: string;
  hoverTextColor?: string;
  customMenuClass?: string;
  customOptionClass?: string;
  borderRadius?: string;
  formatOptionLabel?: (option:any, { context }:FormatOptionLabelMeta<any>
  ) => React.ReactNode;
  onChange?: (newValue: SingleValue<FormSelectOption>) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
};

const FormSelect: React.FC<Props> = ({
  name,
  id,
  defaultValue,
  value,
  options,
  selectClass,
  wrapperClass,
  error,
  errorClass,
  isDisabled,
  // isMulti,
  isRtl,
  isSearchable,
  inputValue,
  maxMenuHeight,
  menuPlacement,
  ariaLabel,
  ariaLabelledby,
  classNamePrefix,
  autoFocus,
  placeholder,
  noOptionsMessage,
  inputId,
  selectBackgroundColor,
  controlHeight,
  focusBorderColor,
  focusTextColor,
  hoverTextColor,
  customMenuClass,
  customOptionClass,
  borderRadius,
  formatOptionLabel,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
}) => {
  const customStyles = useCustomSelectStyles({
    selectBackgroundColor,
    controlHeight,
    focusBorderColor,
    focusTextColor,
    hoverTextColor,
    borderRadius,
  });
  const selectId = useId();
  const [inputtedValue, setInputValue] = useState("");

  const customFilterOption = (option: any, inputValue: any) => {
    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      (option.data?.import_id && option.data.import_id.toLowerCase().includes(inputValue.toLowerCase())) || false
    );
  };
  
  return (
    <div className={`select-wrap ${wrapperClass ? wrapperClass : ""}`}>
      <Select
        name={name}
        id={id || selectId}
        defaultValue={defaultValue || null}
        value={value || null}
        options={options}
        placeholder={placeholder}
        className={`form-select select-field ${selectClass || ""} ${
          error ? "error" : ""
        }`}
        
        styles={customStyles}
        isDisabled={isDisabled || false}
        // isMulti={isMulti || false}
        isRtl={isRtl || false}
        isSearchable={isSearchable || false}
        onInputChange={(e) => {
          setInputValue(e);
        }}
        formatOptionLabel={formatOptionLabel}
        inputValue={inputtedValue || ""}
        maxMenuHeight={maxMenuHeight || 300}
        menuPlacement={menuPlacement || "auto"}
        aria-label={ariaLabel || ""}
        aria-labelledby={ariaLabelledby || ""}
        classNamePrefix={classNamePrefix || null}
        autoFocus={autoFocus || false}
        noOptionsMessage={() => noOptionsMessage || "No options found"}
        inputId={inputId || ""}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        components={{
          Menu: (props) => (
            <CustomMenu {...props} className={customMenuClass} />
          ),
          Option: (props) => (
            <CustomOption {...props} className={customOptionClass} name={name} />
          ),
        }}
        // menuIsOpen={true}
        filterOption={customFilterOption}
      />
      {error && <span className={`error ${errorClass || ""}`}>{error}</span>}
    </div>
  );
};

export default FormSelect;
