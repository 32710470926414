import { FormikProps } from "formik";
import { EmailTo } from "../../../../../common/components";
type Props = {
  formik: FormikProps<any>;
  emailError: string;
};

export const SystemVoucherSendEmail = (props: Props) => {
  return <EmailTo formik={props.formik} error={props.emailError} />;
};
