import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { Container } from "../../../../common/components/htmlTags/Container";
import { TableBody } from "../../../../common/components/htmlTags/TableTags/TableBody";
import { TableCell } from "../../../../common/components/htmlTags/TableTags/TableCell";
import { TableContainer } from "../../../../common/components/htmlTags/TableTags/TableContainer";
import { TableHeader } from "../../../../common/components/htmlTags/TableTags/TableHeader";
import { TableHeaderContainer } from "../../../../common/components/htmlTags/TableTags/TableHeaderContainer";
import { TableRow } from "../../../../common/components/htmlTags/TableTags/TableRow";
import useCommonData from "../../../../hooks/useCommon";
import "./BalanceSheetReportTable.css";
import AccountNameCell from "./components/AccountNameCell";
import { TotalCell } from "./components/TotalCell";
import { useDrillDown } from "./hooks/useDrillDown";
import { useGetRowsToBeExpanded } from "./hooks/useGetRowsToBeExpanded";
import { useProcessStyle } from "./hooks/useProcessStyle";
import { useProcessTableData } from "./hooks/useProcessTableData";
import { useProcessData } from "./hooks/useProcessTagData";
import { AccountNode, ProcessedRow } from "./types";
import { useScrollToElement } from "../../../../hooks/useScrollToElement";
const BalanceSheetReportTable = ({
  jsonData,
  filterValues,
}: {
  jsonData: {
    currency_code: string;
    total_liabilities_equity: string;
    items: AccountNode[];
  };
  filterValues?: any;
}) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const shouldAppend = useRef(true);
  const expandedIds = useRef<ExpandedState>({});
  const { scrollToElement } = useScrollToElement();
  const [currencyCode, setCurrencyCode] = useState(jsonData.currency_code);
  const { getRowsToBeExpanded } = useGetRowsToBeExpanded();
  const { processNodeById } = useProcessData(shouldAppend.current);
  const { processAndSanitizeNode, appendNetTotalRow } =
    useProcessTableData(currencyCode);
  const {
    columnStyleProcessor,
    headerStyleProcessor,
    rowStyleProcessor,
    cellStyleProcessor,
  } = useProcessStyle();
  const [data, setData] = useState<any>([]);

  const [expanded, setExpanded] = useState<ExpandedState>(
    getRowsToBeExpanded()
  );
  const { accTransactionNavHandler } = useDrillDown();
  const formatNumber = useMemo(
    () => (value: number) =>
      NumberFormat(value, jsonData.currency_code, orgCurrencyList),
    [jsonData.currency_code, orgCurrencyList, jsonData.items]
  );
  useEffect(() => {
    scrollToElement("className", "active-selected-account");
  }, []);
  useMemo(() => {
    let processedData = processAndSanitizeNode(
      appendNetTotalRow(
        (jsonData?.items as any) || [],
        jsonData?.total_liabilities_equity || ""
      ),
      [],
      Number(jsonData.total_liabilities_equity)
    );
    setData(processedData);
  }, [
    jsonData.items,
    // currencyCode,
    currentUserInfo.organization_id,
    jsonData.total_liabilities_equity,
  ]);
  // Define the columns for the table
  const columns = useMemo<ColumnDef<ProcessedRow>[]>(
    () => [
      {
        accessorKey: "account_name",
        header: "Account",
        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <AccountNameCell
            row={row}
            columnStyleProcessor={columnStyleProcessor}
            accTransactionNavHandler={accTransactionNavHandler}
            expandedIds={expandedIds}
            processNodeById={processNodeById}
            shouldAppend={shouldAppend}
            setData={setData}
            data={data}
            jsonData={jsonData}
            table={table}
            filterValues={filterValues}
          />
        ),

        footer: (info: any) => info.column.id,
      },

      {
        accessorKey: "closing_balance",
        header: "Total",

        cell: ({ row }: { row: Row<ProcessedRow> }) => (
          <TotalCell row={row} formatNumber={formatNumber} />
        ),
        footer: (info: any) => info.column.id,
      },
    ],
    [jsonData.items, data, formatNumber]
  );
  // Memoized function to format numbers

  const table = useReactTable({
    data: data as any,
    columns,
    state: { expanded },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.node || [],
    getRowCanExpand: (row) => true,
  });
  return (
    <Container className="reusable-report-table-wrapper">
      <TableContainer>
        <TableHeaderContainer>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    style={headerStyleProcessor(header)}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHeader>
                ))}
              </TableRow>
            );
          })}
        </TableHeaderContainer>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} style={rowStyleProcessor(row)}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id} style={cellStyleProcessor(row)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Container>
  );
};

export default BalanceSheetReportTable;
