import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";

export async function createCustomFrequency(data: {
  organization_id: number;
  custom_repeat_count: number;
  custom_repeat_frequency: string;
  is_enabled: boolean;
  user_id: number;
}) {
  let url =
    Api.planPreferences +
    `subscription_frequency_options/create?current_organization=${data.organization_id}`;

  const result = await post(url, {
    organization_id: data.organization_id,
    custom_repeat_count: data.custom_repeat_count,
    custom_repeat_frequency: data.custom_repeat_frequency,
    is_enabled: data.is_enabled,
    user_id: data.user_id,
  });

  return result;
}

export async function updateCustomFrequency(data: {
  orgId: number;
  formData: any[];
}) {
  const result = await put(
    Api.planPreferences +
      `subscription_frequency_options/update` +
      `?current_organization=${data.orgId}`,
    data.formData
  );
  return result;
}
