import { FormikProps } from "formik";
import ReferenceNumber from "../../../../../../common/components/ReferenceInput";

type Props = {
  formik: FormikProps<any>;
};
export const SystemVoucherReferenceNumber = (props: Props) => {
  return (
    <ReferenceNumber
      referenceNumber={props.formik.values.reference_number}
      handleChange={props.formik.handleChange}
      error={props.formik.errors.reference_number?.toString() || ""}
      wrapperClass={!props.formik.values.currency_id ? "disabled-item" : ""}
      isDisabled={!props.formik.values.currency_id}
    />
  );
};
