import { Link, useParams } from "react-router-dom";
import Header from "../../../../../../common/components/detailsPage/Header";
import useCommonData from "../../../../../../hooks/useCommon";
import { AddNewSquarePlusIcon, PlusIcon } from "../../../../../../common/components";
import "./applytags.css";
import { TagsCategorySelectionPopup } from "../../../../../../common/components/reportingTagInTransactions/tagSelectionPopups/TagCategorySelectionPopup";
import SingleTag from "../../../../../../common/components/ReportingTags/SingleTag/SingleTag";
import useReportingTags from "../hooks/useReportingTags";
import { ObjectType } from "../../../../../../../types";
import { useEffect, useMemo, useRef, useState } from "react";
import { INITIAL_SPLIT_TYPE_OPTION } from "../../../../../../constants/constants";
import EmptySearchList from "../../../../../../common/components/emptySearchList/EmptySearchList";
import { InvoiceImage } from "../../../../../../../assets/images";
import { Container } from "../../../../../../common/components/htmlTags/Container";
import { DockedButtonGroup } from "../../../../../../common/components/button/DockedButtonGroup";
import { NumberFormat } from "../../../../../../../helpers/numberFormatHelper";
import { TagEditAlert } from "../../../../../../common/components/tagEditAlerts/TagEditAlert";
import { Span } from "../../../../../../common/components/htmlTags/Span";
import { colors } from "../../../../../../constants/colors";

const ApplyTags = () => {
  const { editId } = useParams();
  const { navigate, orgCurrencyList } = useCommonData();
  const confirmationPopupRef = useRef<any>();

  const [invoiceData, setInvoiceData] = useState<any>({});
  const {
    formik,
    allTags,
    currencyCode,
    getReportingTagsList,
    getInvoiceDetails,
  } = useReportingTags();

  const getSelectedIDs = (data: ObjectType) => {
    let selectedTag = allTags.find((tag) => {
      return tag.id === data.tagId;
    });
    let option_list = selectedTag?.options
      .map((option: ObjectType) => {
        if (data.optionIds.includes(option.id)) {
          return {
            option_id: option.id,
            option_name: option.name,
            amount: 0,
          };
        }
      })
      .filter((option: ObjectType) => option !== undefined);

    const reporting_tag_list = formik.values.reporting_tag_list;
    reporting_tag_list.push({
      split_type: INITIAL_SPLIT_TYPE_OPTION.value,
      tag_amount: 0,
      reporting_tag_name: selectedTag?.name,
      reporting_tag_id: selectedTag?.id,
      option_list: option_list,
    });
    formik.setValues((values) => {
      return {
        ...values,
        reporting_tag_list: reporting_tag_list,
      };
    });
  };

  const selectedIds = formik.values.reporting_tag_list.map((tag) => {
    return tag.reporting_tag_id;
  });
  useEffect(() => {
    getInvoice();
  }, []);
  const getInvoice = async () => {
    const response = await getInvoiceDetails();
    setInvoiceData(response);
  };
  return (
    <Container
      className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 reportingtag-jv docked-footer-page applytags"
      id="invoice-reportingtag"
    >
      <Header
        onBackButtonClick={() => {
          navigate(`/invoice/edit/${editId}/reporting-tags`, {
            replace: true,
          });
        }}
        data={{
          num: "Reporting tags",
          status: "",
          tag: (
            <>
              <span className="header-accountName">
                {formik.values.account_name}
              </span>
              <span className="header-amount">
                {" "}
                ({formik.values.transaction_type}: {currencyCode}{" "}
                {NumberFormat(
                  Number(formik.values.transaction_amount),
                  currencyCode,
                  orgCurrencyList
                )}
                )
              </span>
            </>
          ),
        }}
        dataId={editId}
        rightElements={
          <>
            <TagsCategorySelectionPopup
              module="Invoice"
              getSelectedIds={getSelectedIDs}
              getReportingTagsList={getReportingTagsList}
              selectedIds={selectedIds}
              selectedParentTagIds={selectedIds}
              isTagFilteringPresent={false}
              isAddNewTag={true}
              buttonContent={
                <>
                  <Link
                    className={"dropdown-item add-link-option flex"}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <AddNewSquarePlusIcon />
                    <span className="newtag-btn-text">Add Tags</span>
                  </Link>
                </>
              }
            />
          </>
        }
      />
      <Container className="card-body">
        <Container className="table-section">
          <Container
            className={`table-wrap ${
              formik.values.reporting_tag_list.length === 0
                ? "content-screen-center"
                : ""
            }`}
          >
            {formik.values.reporting_tag_list?.map((tag, index) => {
              return (
                <SingleTag
                  key={tag.reporting_tag_id}
                  tag={tag}
                  allTags={allTags}
                  values={formik.values}
                  setValues={formik.setValues}
                  errors={formik.errors}
                  index={index}
                  currencyCode={currencyCode}
                  touched={formik.touched}
                  setFieldTouched={formik.setFieldTouched}
                  isTagFilteringPresent={false}
                />
              );
            })}
            {formik.values.reporting_tag_list.length === 0 ? (
              <Container className="d-flex flex-column align-items-center justify-content-center">
                <EmptySearchList
                  alt="Empty account list"
                  icon={InvoiceImage}
                  emptyText="No reporting tags associated"
                />
                <TagsCategorySelectionPopup
                  module="Invoice"
                  getSelectedIds={getSelectedIDs}
                  getReportingTagsList={getReportingTagsList}
                  selectedIds={selectedIds}
                  selectedParentTagIds={selectedIds}
                  isTagFilteringPresent={false}
                  isAddNewTag={true}
                  buttonContent={<Span><PlusIcon color={colors.white} margin="0 5px 0 0" />Add Tags</Span>}
                  buttonClass="primary-btn mt-3"
                />
              </Container>
            ) : null}
          </Container>
        </Container>
      </Container>
      <DockedButtonGroup
        onSave={() => {
          if (
            invoiceData.is_tags_associated &&
            invoiceData.is_payment_allocated
          ) {
            confirmationPopupRef.current?.openAlertModal &&
              confirmationPopupRef.current?.openAlertModal();
          } else {
            formik.handleSubmit();
          }
        }}
        onCancel={() => {
          if (!formik.isSubmitting) {
            navigate(-1);
          }
        }}
        onDelete={() => {}}
        isSaveDisabled={formik.isSubmitting}
        isDeleteButtonDisabled={false}
        buttonText="Save"
        saveButtonType="button"
        isDeleteButtonEnabled={false}
        isEdit={false}
        isModal={false}
        shouldDockToBottom={true}
      />
      <TagEditAlert
        confirmationPopupRef={confirmationPopupRef}
        onSubmit={async () => {
          formik.handleSubmit();
        }}
        module="invoice"
      />
    </Container>
  );
};

export default ApplyTags;
