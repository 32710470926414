import { getFormatedDate } from "./imports";

export const initialPRFormValues = {
  payment_mode: "Cash",
  check_number: "",
  check_expiry: getFormatedDate(),
  check_date: getFormatedDate(),
  check_expiry_in: "",
  post_dated_check_notes: "",
  payment_number: "",
  reference_number: "",
  payment_date: getFormatedDate(),
  customer_id: null,
  deposit_to_account_id: null,
  intermediate_account_id: null,
  intermediate_account_name: "",
  payment_number_value: "",
  deposit_to_account_name: "",
  amount_received: "",
  bank_charges_amount: 0,
  bank_charges_account_id: null,
  bank_charges_account_name: "",
  tax_account_id: null,
  tax_account_name: "",
  notes: "",
  is_tax_deducted: false,
  custom_fields: {},
  bank_currency_id: null,
  currency_id: null,
  currency_code: "",
  bank_currency_code: "",
  bank_conversion_id: null,
  bank_conversion_rate: null,
  bank_conversion_timestamp: "",
  bank_by_user: false,
  bank_reverse_conversion_rate: null,
  amount_received_bank_currency: "",
  bank_charges_bank_currency: "",
  conversion_id: 0,
  conversion_rate: null,
  conversion_timestamp: "",
  conversion_reverse_rate: null,
  customer_name: "",
  file_ids: [],
  unpaid_invoice_lines: [],
  email_to: [],
  is_currency_changed: false,
  is_date_changed: false,
  action: "",
  is_total_error: false,
  by_user: false,
  delete_email_ids: [],
  email_data: [],
  files: [],
  amount_excess: "",
  pr_status: "Open",
  total: "",
  total_refunded_amount: "",
  fullyAllocatedSundryInvoiceIds: [],
};
