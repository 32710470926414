import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { deleteInvoiceDetails } from "../invoiceSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteInvoiceId: number;
  deleteInvoiceItemId: string;
  organizationId: number;
  refreshInvoices: (action: string) => void;
};
const DeleteInvoice = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteInvoiceId]);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteInvoiceDetails({
          deleteInvoiceId: props.deleteInvoiceId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (
          Object.keys(deleteRoleResponse).length &&
          !("error" in deleteRoleResponse)
        ) {
          props.refreshInvoices("delete");
          isDeletedRef.current = true;
          $(".close-btn").click();
          toast.success("Invoice deleted successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          $(".close-btn").click();
        }
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete Invoice!"
      subHeading="Are you sure you want to delete the invoice"
      transactionName={joinTransactionNumber(props.deleteInvoiceItemId)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteInvoice;
