import { PaymentGatewayConfigForm } from "../components/paymentGatewayConfig/stripe/PaymentGatewayConfigForm";
import stripeLogo from "../../../../assets/images/stripeSquareLogo.png";
// import razorpayLogo from "../../../../assets/images/razorPaySquareLogo.png";
// import amazonpayLogo from "../../../../assets/images/amazonPaySquareLogo.png";
// import paypalLogo from "../../../../assets/images/paypalSquareLogo.png";
// import billdeskLogo from "../../../../assets/images/billDeskSquareLogo.png";
export const supportedPaymentGateways = [
  {
    id: null,
    name: "Stripe",
    gateway_type: "stripe",
    metadata: {},
    is_active: false,
    api_key: "",
    api_secret: "",
    webhook_secret: "",
    is_default: true,
    mapping_metadata: {},
    chart_of_account_id: null,
    chart_of_account_name: "",
    logo: stripeLogo,
    description:
      "Stripe is an online payment processing platform that allows you to receive one-time payments securely from customers.",
    config: PaymentGatewayConfigForm,
  },
  // {
  //   id: null,
  //   name: "Razorpay",
  //   gateway_type: "razorpay",
  //   metadata: {},
  //   is_active: false,
  //   api_key: "",
  //   api_secret: "",
  //   webhook_secret: "",
  //   is_default: false,
  //   mapping_metadata: {},
  //   chart_of_account_id: null,
  //   chart_of_account_name: "",
  //   logo: razorpayLogo,
  //   description:
  //     "Razorpay is an online payment processing platform that allows you to receive one-time payments securely from customers.",
  //   config: PaymentGatewayConfigForm,
  // },
  // {
  //   id: null,
  //   name: "Amazon Pay",
  //   gateway_type: "amazonpay",
  //   metadata: {},
  //   is_active: false,
  //   api_key: "",
  //   api_secret: "",
  //   webhook_secret: "",
  //   is_default: false,
  //   mapping_metadata: {},
  //   chart_of_account_id: null,
  //   chart_of_account_name: "",
  //   logo: amazonpayLogo,
  //   description:
  //     "Amazon Pay is an online payment processing platform that allows you to receive one-time payments securely from customers.",
  //   config: PaymentGatewayConfigForm,
  // },
  // {
  //   id: null,
  //   name: "PayPal",
  //   gateway_type: "paypal",
  //   metadata: {},
  //   is_active: false,
  //   api_key: "",
  //   api_secret: "",
  //   webhook_secret: "",
  //   is_default: false,
  //   mapping_metadata: {},
  //   chart_of_account_id: null,
  //   chart_of_account_name: "",
  //   logo: paypalLogo,
  //   description:
  //     "PayPal is an online payment processing platform that allows you to receive one-time payments securely from customers.",
  //   config: PaymentGatewayConfigForm,
  // },
  // {
  //   id: null,
  //   name: "BillDesk",
  //   gateway_type: "billdesk",
  //   metadata: {},
  //   is_active: false,
  //   api_key: "",
  //   api_secret: "",
  //   webhook_secret: "",
  //   is_default: false,
  //   mapping_metadata: {},
  //   chart_of_account_id: null,
  //   chart_of_account_name: "",
  //   logo: billdeskLogo,
  //   description:
  //     "BillDesk is an online payment processing platform that allows you to receive one-time payments securely from customers.",
  //   config: PaymentGatewayConfigForm,
  // },
];
