import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadUsingURL } from "../../../../../helpers/helper";
import { SystemVoucherDetailValues } from "../types";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { setErrorID } from "../../../../../appSlice";
import { SystemVoucherDetailsInitialValues } from "./StateInitialization";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { setLoaderState } from "../../../../common/commonSlice";
import { SendEmailIcon } from "../../../../common/components/customSvgIcons/sendEmailIcon";
import { DownloadIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import { TagIcon } from "../../../../common/components/customSvgIcons/TagIcon";
import {
  getSystemVoucherLink,
  systemVoucherList,
  sendSystemVoucherData,
  systemVoucherDetails,
} from "../../systemVoucherSlice";
import useCommonData from "../../../../hooks/useCommon";
import SystemVoucherDetailBody from "./SystemVoucherDetailBody";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import Loader from "../../../components/Loader";
import DeleteSystemVoucher from "../Delete";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";

function SystemVoucherHeader() {
  const { dispatch, navigate, currentUserInfo, isLoading } = useCommonData();
  const { systemVoucherPermission } = usePermissionHandler();
  const { editId } = useParams();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deleteSystemId, setDeleteSystemId] = useState(-1);
  const [deleteSystemItemId, setDeleteSystemItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [isCashBased, setIsCashBased] = useState(false);
  const [writeOffCancelledJV, setWriteOffCancelledJV] = useState(false);
  const [systemVoucherData, setSystemData] =
    useState<SystemVoucherDetailValues>(SystemVoucherDetailsInitialValues);

  useEffect(() => {
    if (editId) {
      systemVoucherDetail();
    }
  }, [currentUserInfo.organization_id]);
  /**
   * Fetch System Details
   */
  const systemVoucherDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      systemVoucherDetails({
        systemVoucherId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.journal_status === "Draft") {
          navigate("/system-voucher");
        }
        let systemVoucherData = response;
        let itemTotalData = {
          account_id: "",
          description: "Total",
          debit: response.total_details?.total_amount_debit,
          credit: response.total_details?.total_amount_credit,
        };
        systemVoucherData.item_details.push(itemTotalData);
        setSystemData(systemVoucherData);
        setWriteOffCancelledJV(systemVoucherData.write_off_cancelled_jv);
        setIsCashBased(systemVoucherData.is_cash_based);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.JVDetail));
        // navigate("/journal-voucher");
      }
    }
  };
  /**
   * Set the entered emails in the journal data to a state.
   */
  useEffect(() => {
    let emails = systemVoucherData.email_to.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [systemVoucherData]);

  /**
   * Back button handler from System details page
   */
  const backFromSystemViewHandler = () => {
    navigate(-1);
  };

  const fetchSystemList = async (action: string) => {
    await dispatch(
      systemVoucherList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        tagList: [],
      })
    );
    if (action === "delete") {
      navigate(-1);
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getSystemVoucherLink({
        systemVoucherId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(editId) + ".pdf"
        );
        if (result) {
          toast.success("System Voucher downloaded successfully!", {
            toastId: "journal-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendSystemVoucherData({
        systemVoucherId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchSystemList("send");
      }
    }
    return responseAction;
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromSystemViewHandler}
        data={{
          num: systemVoucherData.journal_voucher_number,
          status: systemVoucherData.journal_status,
        }}
        dataId={editId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editId}
          data={{
            num: systemVoucherData.journal_voucher_number,
            status: systemVoucherData.journal_status,
            userName: systemVoucherData.created_by,
            date: systemVoucherData.date,
            orgName: systemVoucherData.organization_name,
          }}
          isSameOrg={
            systemVoucherData.organization_id ===
            currentUserInfo.organization_id
          }
        >
          {systemVoucherPermission.includes("Update") &&
          systemVoucherData.is_mutable &&
          !systemVoucherData.entity_tag &&
          !systemVoucherData.is_fiscal_closed ? (
            <Edit
              url={`/system-voucher/edit/${systemVoucherData.id}?replace=true`}
              isMutable={true}
              name="System Voucher"
              isLocked={systemVoucherData.is_locked}
              className=""
              lockDate={systemVoucherData.lock_date}
            />
          ) : null}

          {systemVoucherPermission.includes("Delete") &&
          systemVoucherData.is_mutable &&
          !systemVoucherData.entity_tag &&
          !systemVoucherData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={true}
              onClick={() => {
                setDeleteSystemId(Number(systemVoucherData.id));
                setDeleteSystemItemId(systemVoucherData.journal_voucher_number);
              }}
              isLocked={systemVoucherData.is_locked}
              lockDate={systemVoucherData.lock_date}
              name="System Voucher"
              isFiscalClosed={systemVoucherData.is_fiscal_closed}
            />
          ) : null}

          <KebabMenu wrapperClass="detail-top-action-menu kebab-menu-dropdown-wrap">
            <li>
              <button
                type="button"
                className="dropdown-item"
                // to="#"
                data-bs-toggle="modal"
                data-bs-target="#emailToModal"
                style={{ textDecoration: "none", color: colors.charcoalGrey }}
              >
                <span>
                  <SendEmailIcon
                    color={colors.battleshipGrey}
                    margin="0px 5px 0px 0px"
                  />
                  Send Email
                </span>
              </button>
            </li>
            <li>
              <button
                // to="#"
                type="button"
                onClick={downloadPdf}
                style={{ textDecoration: "none", color: colors.charcoalGrey }}
                className="dropdown-item"
              >
                <span>
                  <DownloadIcon
                    color={colors.battleshipGrey}
                    margin="0px 8px 0px 0px"
                  />
                  Download PDF
                </span>
              </button>
            </li>
            {currentUserInfo.is_tags_enabled === true && isCashBased === false && (
              <li>
                <button
                  type="button"
                  style={{
                    textDecoration: "none",
                    color: colors.charcoalGrey,
                    opacity:
                      isCashBased === false && writeOffCancelledJV !== true
                        ? 1
                        : 0.5,
                  }}
                  className={`dropdown-item`}
                  onClick={(e) => {
                    if (!isCashBased && writeOffCancelledJV) {
                      e.preventDefault();
                      e.stopPropagation();
                    } else {
                      navigate(`/system-voucher/edit/${editId}/reporting-tags`, {
                        replace: true,
                      });
                    }
                  }}
                >
                  <span>
                    <TagIcon
                      color={colors.battleshipGrey}
                      margin="0px 5px 0px 0px"
                    />
                    Reporting Tags
                  </span>
                </button>
              </li>
            )}
          </KebabMenu>
        </HeaderBody>
        {isLoading ? <Loader /> : null}
        <SystemVoucherDetailBody systemVoucherData={systemVoucherData} />
      </div>
      <SendEmailModal
        emailLists={emailList}
        id={Number(editId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteSystemVoucher
        deleteSystemVoucherId={deleteSystemId}
        deleteSystemVoucherItemId={deleteSystemItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshJournals={fetchSystemList}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
}

export default SystemVoucherHeader;
