import { Container } from "../../htmlTags/Container";
import { TdsSelectProps } from "./types";
import { TdsSelectDropdownComponent } from "./components/TdsSelect";
import { TdsSelectInputComponent } from "./components/TdsInput";
import "./TdsSelect.css";
import { Link } from "react-router-dom";
import { useTDSSelect } from "./TdsSelectProvider";
import ItemCloseButton from "../../button/ItemCloseButton";
import { Span } from "../../htmlTags/Span";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../hooks/useCommon";

export const TdsSelectComponent = (props: TdsSelectProps) => {
  const { orgCurrencyList } = useCommonData();
  const { setIsPercentage } = useTDSSelect();

  return (
    <Container className="tds-field-parent-wrap">
      <Container className=" w-100 d-flex align-items-center">
        <Container
          className={`tds-select-wrapper  d-flex ${props.error ? " error" : ""}
       `}
          style={props.disabled ? { pointerEvents: "none", opacity: 0.5 } : {}}
        >
          <TdsSelectDropdownComponent {...props} />
          <TdsSelectInputComponent {...props} />
        </Container>
        {props.item.tds_id ? (
          <Container>
            <Link
              to="#"
              className="removeItem"
              onClick={() => {
                props.clearTDS(props.index);
                setIsPercentage(false);
              }}
            >
              <ItemCloseButton />
            </Link>
          </Container>
        ) : null}
      </Container>
      {props.error && (
        <Span className={`error ${props.errorClass ? props.errorClass : ""}`}>
          {props.error}
        </Span>
      )}
      {!Number.isNaN(props.item.tds_total) &&
        Number(props.item.tds_percentage) > 0 && (
          <Container>
            <Span className="tds-converted-amount">{`${
              props.currencyCode
            }  ${NumberFormat(
              Number(props.item.tds_total),
              props.currencyCode,
              orgCurrencyList
            )}`}</Span>
          </Container>
        )}
    </Container>
  );
};
