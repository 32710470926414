import { ConfirmationPopup } from "../confirmationPopup/ConfirmationPopup";
import { Span } from "../htmlTags/Span";
import { TagEditAlertPopupProps } from "./types";

export const TagEditAlert = (props: TagEditAlertPopupProps) => {
  return (
    <ConfirmationPopup
      confirmationOpenRef={props.confirmationPopupRef}
      onCancel={() => {}}
      onSubmit={async () => {
        await props.onSubmit();
      }}
      popupTitle="Are you sure you want to update?"
      buttonText={{
        submit: "Yes Proceed",
        cancel: "Cancel",
      }}
      popupButtonContent={<></>}
      bodyWrapperClass=""
    >
      <Span>
        {` Updating the tags on this ${
          props.module
        } will remove all previously assigned
        tags linked to the associated ${
          props.module === "invoice" ? "payments received" : "payments made"
        } . You will need to
        reassign them manually. Are you sure you want to proceed?`}
      </Span>
    </ConfirmationPopup>
  );
};
