import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Loader";
import { AccessInfoImg } from "../../../../../assets/images";
import "../../../reports/Reports.css";
import "../TrialBalance.css";
import ReportBody from "./ReportBody";
import { getFormatedDate } from "../../../../../helpers/helper";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { getTrialBalanceReport, reportLoaderSelector, setSelectedTagsData } from "../../ReportSlice";
import ReportHeader from "../../componets/ReportHeader";
import ReportFilter from "../../componets/ReportFilter";
import { filter } from "lodash";
import { getFiscalQuarterYear } from "../../../../../helpers/fiscalYearHelper";
import { message } from "../../../../constants/messages";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import { ObjectType } from "../../../../../types";
import { useLocation } from "react-router-dom";
import TrialBalanceReportTable from "./TrialBalanceReportTable";
import ReportsExportComponent from "../../../../common/components/reportsExportComponent/reportsExportComponent";
import { Api } from "../../../../../constants";
import {
  downloadFile,
  getFileName,
} from "../../componets/exportDownloadHelper";

export default function TrialBalance() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const reportRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const loader = useAppSelector(reportLoaderSelector);
  const initialTrialBalanceData = {
    currency_id: 0,
    currency_code: "",
    total_credit: 0,
    total_debit: 0,
    date_range: "today",
    organization_id: 0,
    is_integrated: true,
    end_date: getFormatedDate(),
    items: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
    },
  };
  const initialFiltervalues = {
    currency_id: currentUserInfo.organization_currency,
    date_range: "today",
    end_date: getFormatedDate(),
    organization_id: currentUserInfo.organization_id,
    is_integrated: true,
    tag_option_in: [],
  };
  const [isLoading, setIsLoader] = useState(true);
  const [trialBalanceData, setTrialBalanceData] = useState<any>(
    initialTrialBalanceData
  );
  const [showReport, setShowReport] = useState(false);
  const [drillDownFilter, setDrillDownFilter] = useState<ObjectType>({});
  const [startDate, setStartDate] = useState(getFormatedDate());
  const [orgName, setOrgName] = useState("");
  const [filterDataForExport, setFilterDataForExport] = useState<ObjectType>({});

  useEffect(() => {
    const storedFilter = localStorage.getItem("drill-down-filter");
    const filterParsed: ObjectType = storedFilter
      ? JSON.parse(storedFilter)
      : {};
    if (Object.keys(filterParsed).length) {
      if (
        currentUserInfo.organization_id &&
        currentUserInfo.organization_currency
      ) {
        setDrillDownFilter({ ...filterParsed });
      }
    } else {
      if (
        currentUserInfo.organization_id &&
        currentUserInfo.organization_currency
      ) {

        getTrialBalanceReports("initial");
      }
    }
  }, [
    currentUserInfo.organization_id,
    currentUserInfo.organization_currency,
    location.pathname,
  ]);

  useEffect(() => {
    if (Object.keys(drillDownFilter).length) {
      getTrialBalanceReports("drilldown", drillDownFilter);
    }
  }, [drillDownFilter]);

  const handleReport = () => {
    setIsLoader(true);
    getTrialBalanceReports();
  };

  /**
   * Function to get the trial balance reports
   */
  const getTrialBalanceReports = async (
    type = "",
    drillDownFilter: ObjectType = {}
  ) => {
    setIsLoader(true);
    let { start_date, ...filterValues } = reportRef.current.filterValues;
    setStartDate(start_date);
    if (type === "initial") {
      filterValues = initialFiltervalues;
      dispatch(setSelectedTagsData([]));
      localStorage.removeItem("drill-down-filter");
    }
    if (type === "drilldown") {
      if (Object.keys(drillDownFilter).length) {
        filterValues = {
          currency_id: drillDownFilter.currencyId,
          organization_id: drillDownFilter.organizationId,
          end_date: drillDownFilter.endDate,
          date_range: drillDownFilter.dateRange,
          tag_option_in: drillDownFilter.tag_option_in,
          is_integrated: drillDownFilter.is_integrated,
        };
      }
    }
    const responseAction = await dispatch(
      getTrialBalanceReport({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
        temp: location.pathname === "/trial-balance-1" ? true : false,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTrialBalanceData(response);
        setIsLoader(false);
        setShowReport(true);
        setOrgName(response.organization_details.organization_name);
        setFilterDataForExport(filterValues);
      }
    }
  };

  const exportFile = () => {
    downloadFile(
      filterDataForExport,
      "trial-balance",
      getFileName(
        orgName || "",
        "trial-balance",
        filterDataForExport.start_date,
        filterDataForExport.end_date
      )
    );
  };

  // const handleReportTypeChange = (reportType: any) => {
  //   reportRef.current.formValues.is_integrated = reportType;
  // };

  return (
    <div className="reports tb-report">
      <div
        className="card card-dashboard report-card tb-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Trial Balance</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={true}
                isAdvanced={true}
                defaultValue="today"
                reportType="TB"
                handleReport={handleReport}
                clearReport={() => setShowReport(false)}
                filterdata={
                  drillDownFilter
                }
                ref={reportRef}
                // habdleReportType={handleReportTypeChange}
                enable_toggleReportType={true}
              />
            </div>
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center ">
                  {/* hidding the send email, download pdf and export to excel options from trial balance header as per ticket: https://app.clickup.com/t/85zrmgcae */}

                  {/* <ReportHeaderActionLinks /> */}
                </div>
                <ReportsExportComponent
                  onExportPDF={() => { }}
                  onExportExcel={exportFile}
                />
                <ReportHeader
                  reportData={trialBalanceData}
                  reportName="Trial Balance"
                />
                <div className="table-section report-table-section tb-report-section trial-balance-report-section">
                  <InfoBox
                    message={
                      message(trialBalanceData.currency_code).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  <div className="report-table-outline w-100">
                    <TrialBalanceReportTable
                      jsonData={trialBalanceData}
                      filterValues={reportRef.current.filterValues}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {isLoading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
