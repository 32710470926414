import React, { ReactNode, useState, useEffect, useMemo } from "react";
import {
  Autocomplete,
  TextField,
  Checkbox,
  Box,
  Typography,
  Chip,
  Popper,
  PopperProps,
} from "@mui/material";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  Close,
  ArrowDropDown,
} from "@mui/icons-material";
import ChipCloseIcon from "../../../customSvgIcons/ChipCloseIcon";
import "./MultiSelectAutocomplete.css";

type Props<T> = {
  data: T[]; // Generic type for the data
  id?: string;
  changeHandler: (selectedOptions: T[]) => void; // Updated type for changeHandler
  selectedValues: T[]; // Generic type for selected values
  getOptionLabel: (option: T) => string; // Function to get the display label
  getOptionSecondaryLabel?: (option: T) => string; // Optional function for secondary label
  getOptionKey: (option: T) => string | number; // Function to get unique key for options
  isAutoSelect?: boolean;
  customClearIcon?: ReactNode;
  popupIcon?: ReactNode;
  isDisabled?: boolean;
  isOpenOnFocus?: boolean;
  noOptionText: string;
  placeholderText: string;
  clearIcon?: any;
  error?: any;
};

const MultiSelectAutocomplete = <T,>({
  id,
  data,
  getOptionLabel,
  getOptionSecondaryLabel,
  getOptionKey,
  selectedValues,
  noOptionText,
  isAutoSelect,
  isOpenOnFocus,
  isDisabled,
  placeholderText,
  changeHandler,
  popupIcon,
  clearIcon,
  error,

  ...props
}: Props<T>) => {
  const [selectedOptions, setSelectedOptions] = useState<T[]>(
    selectedValues || []
  );
  // const [open, setOpen] = useState(false);

  // Ensure that `selectedOptions` is synchronized with `selectedValues`
  useEffect(() => {
    setSelectedOptions(selectedValues);
  }, [selectedValues]);

  // Custom Popper Component
    const CustomPopper = useMemo(
      () =>
        (popperProps: PopperProps) => (
          <Popper {...popperProps} className="custom-popper" placement="bottom" />
        ),
      []
    );

  // Icon for checkboxes
  const checkBoxIcon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const handleChange = (event: React.ChangeEvent<{}>, value: T[]) => {
    setSelectedOptions(value);
    changeHandler(value); // Directly pass the selected options array
  };

  // Function to remove selected option
  const handleDeleteChip = (optionToDelete: T) => {
    const updatedOptions = selectedOptions.filter(
      (option) => getOptionKey(option) !== getOptionKey(optionToDelete)
    );

    setSelectedOptions(updatedOptions);
    changeHandler(updatedOptions);
  };
  // Open dropdown on focus
  // const handleFocus = () => {
  //   setOpen(true);
  // };
  // Prevent dropdown from closing on blur
  // const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
  //   // Logic to keep dropdown open when losing focus from Autocomplete
  //   const relatedTarget = event.relatedTarget;
  //   if (relatedTarget && relatedTarget.id === "autocomplete-multi-select") {
  //     event.preventDefault();
  //     setOpen(true);
  //   } else {
  //     setOpen(true);
  //   }
  // };

  return (
    <Autocomplete
      multiple
      id={id || "autocomplete-multi-select"}
      options={data}
      disableCloseOnSelect
      autoSelect={!!isAutoSelect}
      disabled={!!isDisabled}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionText}
      value={selectedOptions}
      onChange={handleChange}
      openOnFocus={!!isOpenOnFocus}
      popupIcon={popupIcon ? popupIcon : <ArrowDropDown />}
      clearIcon={clearIcon ? clearIcon : null}
      className={`custom-autocomplete ${error ? "auto-complete-error" : ""}`}
      filterOptions={(options, { inputValue }) => {
        const filter = inputValue.toLowerCase();
        return options.filter((option) =>
          getOptionLabel(option).toLowerCase().includes(filter)
        );
      }}
      // open={open}
      // onOpen={() => setOpen(true)}
      // onClose={() => setOpen(false)}
      // onFocus={handleFocus}
      // onBlur={handleBlur}
      renderOption={(optionProps, option, { selected }) => (
        <li {...optionProps}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            className="custom-option"
          >
            <Checkbox
              icon={checkBoxIcon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              className="option-wrapper"
            >
              <Typography variant="body1" className="primary-label name">
                {getOptionLabel(option)}
              </Typography>
              {getOptionSecondaryLabel && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="secondary-label ledger-root"
                >
                  {getOptionSecondaryLabel(option)}
                </Typography>
              )}
            </Box>
          </Box>
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const {
            onDelete,
            key: tagKey,
            ...otherTagProps
          } = getTagProps({ index });

          return (
            <Chip
              key={getOptionKey(option)}
              label={getOptionLabel(option)}
              onDelete={() => handleDeleteChip(option)}
              deleteIcon={<ChipCloseIcon />}
              {...otherTagProps}
              className="chip custom-chip"
            />
          );
        })
      }
      classes={{
        root: "custom-autocomplete",
        input: "custom-input",
        noOptions: "custom-no-options",
      }}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholderText || "Select accounts"}
          className="custom-textfield"
        />
      )}
      PopperComponent={CustomPopper}
    />
  );
};

export default MultiSelectAutocomplete;
