import React, { useEffect, useRef, useState } from "react";
import { AddTagIcon } from "../../../assets/images";
import Dialog from "@mui/material/Dialog";
import { Card } from "./htmlTags/Card/Card";
import { CardBody } from "./htmlTags/Card/CardBody";
import { CardHeader } from "./htmlTags/Card/CardHeader";
import { Form } from "./htmlTags/Form";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormSelectField } from "./formSelectField/FormSelectField";
import CloseButton from "./button/CloseButton";
import Grid from "@mui/material/Grid";
import useCommonData from "../../hooks/useCommon";
import {
  subscriptionPlanDropdown,
  subscriptionPlanFrequency,
} from "../../home/subscription/subscriptionSlice";
import { AddPlanFormInitialValueType } from "../../home/subscription/subscriptionType";
import { useFormik } from "formik";
import { addPlanFormInitialValue } from "../../home/subscription/SubscriptionStateInitialization";
import { coustomCase } from "../../../helpers/planHelper";
import {
  customFrequencySettingsSelector,
  setLoaderState,
} from "../commonSlice";
import { validateAddPlanForm } from "../../home/subscription/components/subscriptionForm/ValidateSubscriptionForm";
import { ObjectType } from "../../../types";
import { FormSelectFieldWithScroll } from "./formSelectField/FormSelectFiledWithScroll";
import { useAppSelector } from "../../../app/hooks";

type Props = {
  onSaveClick: (selectedFrequency: any) => void;
  getSelectedPlanFrequencyItemDetails: (
    planId: number | string,
    pricingId: number
  ) => any;
};

type planType = {
  currency_dict: {};
  external_name: string;
  id: number;
  organization: string;
  organization_id: number;
  plan_status: string;
};

function AddLinePlan(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [planFormErrors, setPlanFormErrors] = useState<ObjectType>({});
  const [searchKey, setSearchKey] = useState("");
  const [planListData, setPlanListData] = useState<planType[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);

  function extractNumber(input: string): string | null {
    const match = input.match(/\d+/);
    return match ? match[0] : null;
  }

  const formik = useFormik({
    initialValues: {
      ...addPlanFormInitialValue,
    } as AddPlanFormInitialValueType,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let errors = validateAddPlanForm(values);
      if (Object.keys(errors).length) {
        setPlanFormErrors(errors);
      } else {
        let passingValue = values.planFrequencyData.filter(
          (data: any) =>
            data.frequency === values.selectedFrequency.value &&
            data.custom_repeat_count ===
              Number(extractNumber(values.selectedFrequency.label))
        )[0];
        const itemDetails = await props.getSelectedPlanFrequencyItemDetails(
          values.selectedPlan.value,
          passingValue.pricing_id
        );
        props.onSaveClick({
          ...passingValue,
          planId: values.selectedPlan.value,
          planName: values.selectedPlan.label,
          item_price: itemDetails,
        });
        setPlanFormErrors({});
        formik.resetForm();
      }
    },
  });
  const fetchPlanDropdown = async (count: number, searchQuery: string) => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    if (formik.values.count && formik.values.count <= 1)
      dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionPlanDropdown({
        page: count,
        itemsPerPage: 10,
        orgId: currentUserInfo.organization_id,
        searchQuery: searchQuery,
        signal: abortControllerRef.current.signal,
      })
    );

    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setPlanListData(response.plans);
        const modifiedResponse = response.plans.map((data: any) => ({
          value: data.id.toString(),
          label: data.external_name,
        }));

        formik.setFieldValue(
          "planDropdownData",
          count > 1
            ? formik.values.planDropdownData.concat(modifiedResponse)
            : modifiedResponse
        );
        formik.setFieldValue(
          "isNextEnabled",
          !(response.pagination.next === null)
        );
        if (response.pagination.next !== null) {
          const regex = new RegExp(`page_num=(\\d+)`);
          const modifiedString = response.pagination.next.match(regex);
          const count = modifiedString[1]
            ? modifiedString[1]
            : formik.values.count;
          formik.setFieldValue("count", count);
        }
      } else if ("error" in response) {
      }
    }
  };

  const fetchFrequencyDropdown = async (currencyId: number) => {
    const responseAction = await dispatch(
      subscriptionPlanFrequency({
        planId: formik.values.selectedPlan.value,
        orgId: currentUserInfo.organization_id,
        currencyId: currencyId,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        const modifiedResponse = response.pricings.map((data: any) => ({
          value: data.frequency,
          label: coustomCase(data.frequency, data.custom_repeat_count),
        }));
        formik.setFieldValue("frequencyDropdownData", modifiedResponse);
        formik.setFieldValue("planFrequencyData", response.pricings);
      } else if ("error" in response) {
      }
    }
  };

  const fetchCurrencyDropdown = async (planID: number) => {
    const planData = planListData.filter(
      (item) => Number(item.id) === Number(planID)
    );
    const modifiedOptionData =
      Object.keys(planData[0].currency_dict).length > 0
        ? Object.entries(planData[0].currency_dict).map(([key, value]) => ({
            value: Number(key),
            label: value,
          }))
        : [
            {
              value: currentUserInfo.organization_currency,
              label: currentUserInfo.currency_code,
            },
          ];

    formik.setFieldValue(
      "currencyDropdownData",
      modifiedOptionData.length > 0
        ? modifiedOptionData
        : [
            {
              value: currentUserInfo.organization_currency,
              label: currentUserInfo.currency_code,
            },
          ]
    );
  };
  const handlePlanSearch = (searchQuery: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (searchQuery.length > 2 || searchQuery === "") {
      fetchPlanDropdown(1, searchQuery);
    }
    setSearchKey(searchQuery);
  };
  // const FreList = customFrequencyList.map(
  //   (item: { frequency: any; custom_repeat_frequency: any }) => ({
  //     label: item.frequency,
  //     value: item.custom_repeat_frequency,
  //   })
  // );

  return (
    <>
      <div className="w-100 add-line-button-wrapper add-line">
        <div className="w-100 d-flex align-items-center p-b-18">
          <a
            href="#"
            role="button"
            className="add-tags-button add-line-button d-flex align-items-center "
            onClick={(e) => e.preventDefault()}
          >
            <img src={AddTagIcon} alt="" />
            <span
              className="px-2"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                fetchPlanDropdown(1, "");
                formik.setFieldValue("openPlanPopover", true);
              }}
            >
              Select Plans
            </span>
          </a>
        </div>
      </div>
      <Dialog
        open={formik.values.openPlanPopover}
        className="subscription-plan-popover"
        onClose={() => formik.resetForm()}
      >
        <Card wrapperClass="plan-card">
          <CardHeader wrapperClass="plan-card-header-wrapper">
            <Grid container>
              <Grid item xs={10} className="p-t-15">
                Select Plans
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton
                  closeModalHandler={() => {
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row custom-row">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectFieldWithScroll
                    count={formik.values.count}
                    name="select_plan"
                    id="subscription_select_plan"
                    label="Select Plan"
                    options={formik.values.planDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedPlan}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedPlan", option);
                      formik.setFieldValue("selectedCurrency", {
                        value: "",
                        label: "",
                      });
                      formik.setFieldValue("selectedFrequency", {
                        value: "",
                        label: "",
                      });

                      if (option?.value) {
                        fetchCurrencyDropdown(option?.value);
                      }
                    }}
                    onMenuScrollToBottom={() => {
                      if (formik.values.isNextEnabled) {
                        fetchPlanDropdown(formik.values?.count, searchKey);
                      }
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    isDisabled={false}
                    error={
                      planFormErrors?.select_plan
                        ? planFormErrors.select_plan
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    dropdownCustomMessage="No Plans available"
                    inputValue={searchKey}
                    onInputChange={handlePlanSearch}
                    isClearable={true}
                  />
                </div>
              </div>

              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="billing_currency"
                    id="subscription_billing_currency"
                    label="Select Currency"
                    options={formik.values.currencyDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedCurrency}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedCurrency", option);
                      formik.setFieldValue("selectedFrequency", {
                        value: "",
                        label: "",
                      });
                      if (option?.value) {
                        fetchFrequencyDropdown(option?.value);
                      }
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={false}
                    isDisabled={
                      formik.values.selectedPlan?.label &&
                      formik.values.selectedPlan.label !== ""
                        ? false
                        : true
                    }
                    error={
                      planFormErrors?.billing_currency
                        ? planFormErrors.billing_currency
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    classNamePrefix="billing-frequency-select"
                  />
                </div>
              </div>

              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="billing_frequency"
                    id="subscription_billing_frequency"
                    label="Frequency"
                    options={formik.values.frequencyDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedFrequency}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedFrequency", option);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={false}
                    isDisabled={
                      formik.values.selectedPlan?.label &&
                      formik.values.selectedPlan.label !== ""
                        ? false
                        : true
                    }
                    error={
                      planFormErrors?.billing_frequency
                        ? planFormErrors.billing_frequency
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    classNamePrefix="billing-frequency-select"
                  />
                </div>
              </div>
              <div className="row mb-0">
                <div className="col">
                  <div className="form-button-wrapper w-100" id="form-btn">
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.submitForm();
                      }}
                      className="save-button"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPlanFormErrors({});
                        formik.resetForm();
                      }}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
}

export default React.memo(AddLinePlan);
