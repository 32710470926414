import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  ConsumerBalanceDetailFilter,
  ReportFilters,
  SoaFilters
} from "../../../types";
import {
  agingCustomizations,
  deleteAgingCustomizations,
  generateAccountTransactionReport,
  generateAccountTransactionReportNew,
  generateBalanceSheetReport,
  generateCustomerBalance,
  generateCustomerBalanceDetail,
  generatePayableSummaryReport,
  generateProfitAndLossReport,
  generateReceivableSummaryReport,
  generateSOAReport,
  generateSundryCreditorDetails,
  generateSundryCreditorSummary,
  generateSundryDebtorDetails,
  generateSundryDebtorSummary,
  generateTrialBalanceReport,
  generateVendorBalance,
  generateVendorBalanceDetail,
  getTdsCustomerForSoa,
  getTdsVendorForSoa,
  saveAgingCustomizations,
  topReceivablesList,
  totalPayableAmount,
  totalPayablesList,
  totalReceivableAmount,
  updateAgingCustomizations,
  getReportingTagsAssociatedBalanceSheet,
  getReportingTagsAssociatedTrialBalance,
  getReportingTagsAssociatedProfitAndLoss,
  generateBudgetVarianceReport,
  generateBudgetSummaryReport
} from "./ReportsApi";
import { AgingCustomization, AgingReportFilters, ReportsState } from "./types";

export const getSoaReport = createAsyncThunk(
  "SOA/report",
  async (data: { filterValues: SoaFilters; orgId: number }) => {
    return await generateSOAReport(data);
  }
);

export const getBalanceSheetReport = createAsyncThunk(
  "BalanceSheet/report",
  async (data: {
    filterValues: ReportFilters;
    orgId: number;
    temp: boolean;
  }) => {
    return await generateBalanceSheetReport(data);
  }
);

export const getTrialBalanceReport = createAsyncThunk(
  "TrialBalance/report",
  async (data: {
    filterValues: ReportFilters;
    orgId: number;
    temp: boolean;
  }) => {
    return await generateTrialBalanceReport(data);
  }
);

export const getProfitAndLossReport = createAsyncThunk(
  "ProfitAndLoss/report",
  async (data: {
    filterValues: ReportFilters;
    orgId: number;
    temp: boolean;
  }) => {
    return await generateProfitAndLossReport(data);
  }
);

export const getTotalReceivableAmount = createAsyncThunk(
  "invoice/totalReceivable",
  async (data: { orgId: number; currencyId: number }) => {
    return await totalReceivableAmount(data);
  }
);

export const getTotalPayableAmount = createAsyncThunk(
  "bill/totalPayable",
  async (data: { orgId: number; currencyId: number }) => {
    return await totalPayableAmount(data);
  }
);

export const getAccountTransactionReport = createAsyncThunk(
  "AccountTransaction/report",
  async (data: {
    filterValues: ReportFilters;
    orgId: number;
    pageNum: number;
    pageSize: number;
  }) => {
    return await generateAccountTransactionReport(data);
  }
);

export const getAccountTransactionReportNew = createAsyncThunk(
  "AccountTransaction/report",
  async (data: {
    filterValues: ReportFilters;
    orgId: number;
    pageNum: number;
    pageSize: number;
  }) => {
    return await generateAccountTransactionReportNew(data);
  }
);

export const fetchTdsCustomers = createAsyncThunk(
  "customer/tds",
  async (data: { reportOrg: number; orgId: number; customerName: string }) => {
    return await getTdsCustomerForSoa(data);
  }
);

export const fetchTdsVendors = createAsyncThunk(
  "vendor/tds",
  async (data: { reportOrg: number; orgId: number; vendorName: string }) => {
    return await getTdsVendorForSoa(data);
  }
);

export const getTopReceivablesList = createAsyncThunk(
  "invoice/topReceivables",
  async (data: { orgId: number; currencyId: number }) => {
    return await topReceivablesList(data);
  }
);

export const getTopPayablesList = createAsyncThunk(
  "bill/topPayables",
  async (data: { orgId: number; currencyId: number }) => {
    return await totalPayablesList(data);
  }
);

export const getSundryDebtorSummary = createAsyncThunk(
  "aging-report/sundry-debtor/summary",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateSundryDebtorSummary(data);
  }
);

export const getSundryDebtorDetails = createAsyncThunk(
  "aging-report/sundry-debtor/details",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateSundryDebtorDetails(data);
  }
);

export const getSundryCreditorSummary = createAsyncThunk(
  "aging-report/sundry-creditor/summary",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateSundryCreditorSummary(data);
  }
);

export const getSundryCreditorDetails = createAsyncThunk(
  "aging-report/sundry-creditor/details",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateSundryCreditorDetails(data);
  }
);

export const getVendorBalanceReport = createAsyncThunk(
  "customer-balace-report",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateVendorBalance(data);
  }
);

export const getCustomerBalanceReport = createAsyncThunk(
  "vendor-balance-report",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateCustomerBalance(data);
  }
);

export const getVendorBalanceDetailReport = createAsyncThunk(
  "vendor-balance-detail-report",
  async (data: {
    filterValues: ConsumerBalanceDetailFilter;
    orgId: number;
  }) => {
    return await generateVendorBalanceDetail(data);
  }
);

export const getCustomerBalanceDetailReport = createAsyncThunk(
  "customer-balance-detail-report",
  async (data: {
    filterValues: ConsumerBalanceDetailFilter;
    orgId: number;
  }) => {
    return await generateCustomerBalanceDetail(data);
  }
);

export const getPayableSummaryReport = createAsyncThunk(
  "payable-summary-report",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generatePayableSummaryReport(data);
  }
);

export const getReceivableSummaryReport = createAsyncThunk(
  "receivable-summary-report",
  async (data: { filterValues: ReportFilters; orgId: number }) => {
    return await generateReceivableSummaryReport(data);
  }
);

export const getBudgetVarianceReport = createAsyncThunk(
  "budget-variance-report",
  async (data: { filterValues:  {
    start_date: string;
    end_date: string;
    organization_id: number;
    currency_id: number;
    budget_id: number;
  }; orgId: number }) => {
    return await generateBudgetVarianceReport(data);
  }
);

export const getBudgetSummaryReport = createAsyncThunk(
  "budget-summary-report",
  async (data: { filterValues:  {
    budget_id: number;
    start_date: string | null;
    period: number | null;
    no_of_periods: number | null;
    organization_id: number;
    currency_id: number;
  }; orgId: number }) => {
    return await generateBudgetSummaryReport(data);
  }
);


export const getAgingCustomizations = createAsyncThunk(
  "aging-report/saved-customization-list",
  async (data: { orgId: number; reportType?: string }) => {
    return await agingCustomizations(data);
  }
);
export const createAgingCustomizations = createAsyncThunk(
  "aging-report/customization/create",
  async (data: { orgId: number; customization: AgingCustomization }) => {
    return await saveAgingCustomizations(data);
  }
);
export const editAgingCustomizations = createAsyncThunk(
  "aging-report/customization/edit",
  async (data: {
    orgId: number;
    customizationId: number;
    customization: AgingCustomization;
  }) => {
    return await updateAgingCustomizations(data);
  }
);
export const removeAgingCustomizations = createAsyncThunk(
  "aging-report/customization/delete",
  async (data: { orgId: number; customizationId: number }) => {
    return await deleteAgingCustomizations(data);
  }
);
export const getReportingTagsDataForBalanceSheet = createAsyncThunk(
  "balance-sheet/reporting-tags",
  async (data: {
    currencyId: number;
    accountId: number;
    optionIds: number[];
    endDate: string;
    organizationId: number;
    isIntegrated: boolean;
    currentOrganization: number;
  }) => {
    return await getReportingTagsAssociatedBalanceSheet(data);
  }
);

export const getReportingTagsDataForTrialBalance = createAsyncThunk(
  "balance-sheet/reporting-tags",
  async (data: {
    currencyId: number;
    accountId: number;
    optionIds: number[];
    endDate: string;
    organizationId: number;
    isIntegrated: boolean;
    currentOrganization: number;
  }) => {
    return await getReportingTagsAssociatedTrialBalance(data);
  }
);
export const getReportingTagsDataForProfitAndLoss = createAsyncThunk(
  "profit-and-loss/reporting-tags",
  async (data: {
    currentOrganization: number;
    filterValues: any;
    account_id: number | string | null;
    pl_category_name: string | null;
  }) => {
    return await getReportingTagsAssociatedProfitAndLoss(data);
  }
);




const initialState: ReportsState = {
  balanceSheet: [],
  trialBalance: [],
  profitAndLoss: [],
  reportLoader: false,
  reportCustomizationFilters: {
    accountTransactionFilters: {
      start_date: "",
      end_date: "",
      date_range: "",
      report_basis: "Accrual",
      group_by: null,
      get_groups: false,
      advanced_filter: [],
      tag_option_in: [],
      is_integrated: true
    },
    agingReportFilters: {
      start_date: "",
      end_date: "",
      date_range: "",
      organization_id: null,
      aging_by: "",
      number_of_columns: "",
      interval_range: 0,
      interval_type: "",
      group_by: "",
      show_by: "",
      customer_id: null,
      vendor_id: null,
      currency_id: 0,
      name: "",
      id: 0
    },
    profitAndLossFilters: {
      start_date: "",
      end_date: "",
      date_range: "",
      report_basis: "Accrual",
      account_filter: "Accounts without zero balance",
      compare_with: "",
      compare_value: "",
      compare_list: [],
      is_descending: false,
      tag_option_in: [],
      is_integrated: true
    }
  },
  reportTableFilters: {
    date_range: "",
    currency_id: "",
    end_date: "",
    organization_id: null,
    start_date: "",
    tag_option_in: [],
    is_integrated: true
  },
  ReportCustomizationFlag: false,
  dateRange: {
    start_date: "",
    end_date: "",
    date_range: ""
  },
  agingSavedCustomizations: [],
  selectedTagsData: []
};

export const reportSlice = createSlice({
  name: "reportManagement",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.reportLoader = action.payload;
    },
    setReportCustomizationFilters: (state, action) => {
      state.reportCustomizationFilters = {
        ...state.reportCustomizationFilters,
        ...action.payload
      };
    },
    setATCustomizationFilters: (state, action) => {
      state.reportCustomizationFilters.accountTransactionFilters = {
        ...state.reportCustomizationFilters.accountTransactionFilters,
        ...action.payload
      };
    },
    setReportTableFilters: (state, action) => {
      state.reportTableFilters = {
        ...state.reportTableFilters,
        ...action.payload
      };
    },
    setReportTableFiltersReset: (state, action) => {
      state.reportTableFilters = action.payload;
    },
    setReportCustomizationFlag: (state, action) => {
      state.ReportCustomizationFlag = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setPLCustomizationFilter: (state, action) => {
      state.reportCustomizationFilters.profitAndLossFilters = {
        ...state.reportCustomizationFilters.profitAndLossFilters,
        ...action.payload
      };
    },
    setAgingReportCustomizationFilters: (state, action) => {
      state.reportCustomizationFilters.agingReportFilters = {
        ...state.reportCustomizationFilters.agingReportFilters,
        ...action.payload
      };
    },
    setSelectedTagsData: (state, action) => {
      state.selectedTagsData = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalanceSheetReport.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.balanceSheet = payload;
        } else {
          state.balanceSheet = [];
        }
      })
      .addCase(getTrialBalanceReport.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.trialBalance = payload;
        } else {
          state.trialBalance = [];
        }
      })
      .addCase(getProfitAndLossReport.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.profitAndLoss = payload;
        } else {
          state.profitAndLoss = [];
        }
      })
      .addCase(getAgingCustomizations.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.agingSavedCustomizations = payload;
        }
      });
  }
});

export const {
  setLoader,
  setReportCustomizationFilters,
  setReportTableFilters,
  setATCustomizationFilters,
  setReportCustomizationFlag,
  setDateRange,
  setReportTableFiltersReset,
  setPLCustomizationFilter,
  setAgingReportCustomizationFilters,
  setSelectedTagsData
} = reportSlice.actions;

export default reportSlice.reducer;

export const balanceSheetSelector = (state: RootState) =>
  state.report.balanceSheet;
export const trialBalanceSelector = (state: RootState) =>
  state.report.trialBalance;
export const profitAndLossSelector = (state: RootState) =>
  state.report.profitAndLoss;
export const reportLoaderSelector = (state: RootState) =>
  state.report.reportLoader;
export const reportCustomizationFilterSelector = (state: RootState) =>
  state.report.reportCustomizationFilters;
export const reportTableFilterSelector = (state: RootState) =>
  state.report.reportTableFilters;
export const reportCustFlagSelector = (state: RootState) =>
  state.report.ReportCustomizationFlag;
export const dateRangeSelector = (state: RootState) => state.report.dateRange;
export const agingCustomizationsSelector = (state: RootState) =>
  state.report.agingSavedCustomizations;
export const selectedTagsDataSelector = (state: RootState) =>
  state.report.selectedTagsData;
