import "./vatSettings.css";

type Props = {
  heading: string;
  children: React.ReactNode;
};

function VatSettingsContainer(props: Props) {
  return (
    <div className="card card-user-management card-vat-profile main-card h-100">
      <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
        <h1>{props.heading}</h1>
      </div>
      <div className="card-body">{props.children}</div>
    </div>
  );
}

export default VatSettingsContainer;
