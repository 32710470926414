import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteChargesAPI,
  getChargesList,
  chargesTransactionLimitStatus,
  updateChargesStatus,
  checkPriceInternalName,
  createNewCharges,
  getChargesOverviewDetails,
  getChargesEditDetails,
  editChargesAPI,
} from "./ChargesAPI";
import { CreateChargeDefaultValues } from "./types";

export const createCharges = createAsyncThunk(
  "plan/charges/create",
  async (data: { values: CreateChargeDefaultValues; orgId: number }) => {
    return await createNewCharges(data);
  }
);

export const editCharges = createAsyncThunk(
  "plan/charges/edit",
  async (data: {
    charge_id?: string | number;
    values: CreateChargeDefaultValues;
    orgId: number;
  }) => {
    return await editChargesAPI(data);
  }
);

export const chargesList = createAsyncThunk(
  "addonList",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    orgIds: number[];
    statusList: string[];
  }) => {
    return await getChargesList(data);
  }
);

export const chargesDetails = createAsyncThunk(
  "chargesDetail",
  async (data: { charge_id: number; orgId: number }) => {
    return await getChargesOverviewDetails(data);
  }
);

export const chargesEditDetails = createAsyncThunk(
  "chargesEditDetail",
  async (data: { charge_id: number; orgId: number }) => {
    return await getChargesEditDetails(data);
  }
);

export const deleteCharges = createAsyncThunk(
  "deleteCharges",
  async (data: { deleteChargesId: number; orgId: number }) => {
    return await deleteChargesAPI(data);
  }
);

export const changeChargesStatus = createAsyncThunk(
  "charges/update-status",
  async (data: { id: number; orgId: number; status: string }) => {
    return await updateChargesStatus(data);
  }
);

export const getChargesTransactionLimitStatus = createAsyncThunk(
  "charges/limit/status",
  async (orgId: number) => {
    return await chargesTransactionLimitStatus(orgId);
  }
);

export const checkPriceInternalNameExist = createAsyncThunk(
  "ChargesInternalNameExists",
  async (data: {
    internalName: string;
    orgId: number;
    charge_id?: string | number;
  }) => {
    return await checkPriceInternalName(data);
  }
);
