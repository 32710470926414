import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { orgCurrencyListSelector } from "../../commonSlice";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import useCommonData from "../../../hooks/useCommon";

type Props = {
  taxTypes: any[];
  currencyCode: string | undefined;
  subTotal: number | string;
  totalTax: number | string;
  paymentLabel?: string;
  payment?: number | string;
  total: number | string;
  totalInWords?: string | null;
  children: React.ReactNode;
  balanceLabel: string;
  balanceAmount: number | string;
  balanceDueRequired?: boolean;
  coupon_amount?: number | null;
  couponRequired?: boolean;
  tdsItems: {
    tdsAmount: number;
    tdsName: string | null;
    tdsPercentage: number | null;
    defaultTdsPercentage?: number | null;
  };
  tcsItems: {
    tcsAmount: number;
    tcsName: string | null;
    tcsPercentage: number | null;
    defaultTcsPercentage?: number | null;
  };
  roundOffValue?: number | null;
  roundoffEnabled?: boolean;
  isAmountInWordsEnabled?: boolean;
};
export default function DetailTotalSection(props: Props) {
  const { currentUserInfo, dispatch } = useCommonData();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const organization_tax_system = currentUserInfo.organization_tax_system;

  return (
    <div className="w-75 right-content amt-calculation-portion">
      <div className="calc-section w-75 me-auto">
        <div className="subtotal-section pb-4">
          <div>Total Taxable Amount</div>
          <div>
            {props.currencyCode +
              " " +
              NumberFormat(
                Number(props.subTotal),
                props.currencyCode ? props.currencyCode : "",
                orgCurrencyList
              )}
          </div>
        </div>
        <div className="discount-section pb-4">
          <div>Total Tax</div>
          <div>
            {props.currencyCode +
              " " +
              NumberFormat(
                Number(props.totalTax),
                props.currencyCode ? props.currencyCode : "",
                orgCurrencyList
              )}
          </div>
        </div>
        {props.taxTypes.map((key, index) => {
          return (
            <div className="tax-section pb-4" key={index}>
              <div>{key[0]}</div>
              <div>
                {props.currencyCode +
                  " " +
                  NumberFormat(
                    Number(key[1]),
                    props.currencyCode ? props.currencyCode : "",
                    orgCurrencyList
                  )}
              </div>
            </div>
          );
        })}
        <div className="discount-section pb-4">
          <div>
            {props.tcsItems.tcsAmount ? "TCS" : "TDS"}
            <span className="tax-detail d-block clear-both">
              {props.tcsItems.tcsAmount
                ? props.tcsItems.tcsName
                  ? props.tcsItems.tcsName +
                    (props.tcsItems.tcsPercentage
                      ? " - (" + props.tcsItems.tcsPercentage + "%)"
                      : "")
                  : ""
                : props.tdsItems.tdsName
                ? props.tdsItems.tdsName +
                  (props.tdsItems.defaultTdsPercentage
                    ? " - (" + props.tdsItems.defaultTdsPercentage + "%)"
                    : "")
                : ""}
            </span>
            <span className="tax-updated-detail d-block clear-both">
              {props.tdsItems.tdsAmount
                ? props.tdsItems.tdsPercentage
                  ? "Updated TDS " +
                    (props.tdsItems.tdsPercentage
                      ? " - (" + props.tdsItems.tdsPercentage + "%)"
                      : "")
                  : ""
                : ""}
            </span>
          </div>
          <div>
            {props.tcsItems.tcsAmount ? "" : "-"}
            {props.currencyCode}{" "}
            {NumberFormat(
              Number(
                props.tcsItems.tcsAmount
                  ? props.tcsItems.tcsAmount
                  : props.tdsItems.tdsAmount
              ),
              props.currencyCode ? props.currencyCode : "",
              orgCurrencyList
            )}
          </div>
        </div>

        {props?.roundoffEnabled === true && (
          <div className="discount-section pb-4">
            <div>Round Off</div>
            <div>
              {props.currencyCode +
                " " +
                NumberFormat(
                  Number(props.roundOffValue),
                  props.currencyCode ? props.currencyCode : "",
                  orgCurrencyList
                )}
            </div>
          </div>
        )}
        {props.couponRequired && (
          <div className="discount-section pb-4">
            <div>Coupon</div>
            <div>
              -
              {props.currencyCode +
                " " +
                NumberFormat(
                  Number(props.coupon_amount),
                  props.currencyCode ? props.currencyCode : "",
                  orgCurrencyList
                )}
            </div>
          </div>
        )}
      </div>

      <div className="total-calc w-75 me-auto">
        <div className="total-section pb-4">
          <div>Total</div>
          <div>
            {props.currencyCode +
              " " +
              NumberFormat(
                Number(props.total),
                props.currencyCode ? props.currencyCode : "",
                orgCurrencyList
              )}
          </div>
        </div>
        {props.children}
      </div>
      {props.payment !== undefined && (
        <div className="total-calc w-75 me-auto">
          <div className="total-section pb-4">
            <div>{props.paymentLabel}</div>
            <div>
              <span className="invoice-payment-made">
                {"(-)" +
                  props.currencyCode +
                  " " +
                  NumberFormat(
                    Number(props.payment),
                    props.currencyCode ? props.currencyCode : "",
                    orgCurrencyList
                  )}
              </span>
            </div>
          </div>
        </div>
      )}

      {!props.balanceDueRequired && (
        <div
          className={`total-calc w-75 me-auto ${
            props.isAmountInWordsEnabled ? "pb-4" : "mb-0"
          }`}
        >
          <div className="total-section ">
            <div>{props.balanceLabel}</div>
            <div>
              {props.currencyCode +
                " " +
                NumberFormat(
                  Number(props.balanceAmount),
                  props.currencyCode ? props.currencyCode : "",
                  orgCurrencyList
                )}
            </div>
          </div>
        </div>
      )}

      {props.isAmountInWordsEnabled && props.totalInWords && (
        <div className="total-calc w-75 me-auto mb-0">
          <div className="total-section">
            <div className="total-in-words-label">Total In Words:</div>
            <div className="total-in-words">{props.totalInWords} Only</div>
          </div>
        </div>
      )}
    </div>
  );
}
