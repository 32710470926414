import { useFormik } from "formik";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import {
  setLoaderState,
  useCommonData,
  useEffect,
  useState,
} from "../../paymentsReceived/components/paymentReceivedForm/imports";
import { smtpServerConfigSchema } from "../resources/smtpValidations";
import {
  getSmtpServerConfig,
  updateSmtpServerConfig,
} from "../SmtpsettingsSlice";
import { SmtpServerConfigUpdateValues } from "../types";
export const useSmtpSettings = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [isSetup, setIsSetup] = useState(false);
  const keyPattern = "***************************";
  const {
    handleChange,
    handleReset,
    handleSubmit,
    values,
    initialValues,
    touched,
    errors,
    isSubmitting,
    isValid,
    validateForm,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
  } = useFormik({
    initialValues: {
      id: null,
      smtp_server: "",
      username: "",
      is_active: false,
      password: "",
      encryption_type: "SSL",
      port: "",
    },
    validationSchema: smtpServerConfigSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleUpdateSMTPConfig(values);
    },
  });

  useEffect(() => {
    handleGetSMTPConfig();
  }, [currentUserInfo.organization_id]);

  const handleGetSMTPConfig = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getSmtpServerConfig({
        orgId: currentUserInfo.organization_id,
      })
    ).finally(() => {
      dispatch(setLoaderState(false));
    });
    const response = responseAction.payload;
    if (response) {
      setFieldValue("encryption_type", response.encryption_type || "SSL");
      setFieldValue("is_active", response.is_active);
      setFieldValue("smtp_server", response.smtp_server);
      setFieldValue("port", response.port);
      setFieldValue("username", response.username);
      setFieldValue("password", response.password);
    }
  };

  const handleUpdateSMTPConfig = async (
    values: SmtpServerConfigUpdateValues
  ) => {
    const { id } = values;
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updateSmtpServerConfig({
        orgId: currentUserInfo.organization_id,
        values: { ...values, port: values.is_active ? values.port : 0 },
      })
    ).finally(() => {
      dispatch(setLoaderState(false));
    });
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "SMTP server configuration updated successfully!",
        "smtpServerUpdationSuccess"
      );
    }
  };
  return {
    handleChange,
    handleSubmit,
    values,
    touched,
    isValid,
    validateForm,
    errors,
    isSubmitting,
    isSetup,
    setIsSetup,
    setFieldTouched,
    setErrors,
    setFieldError,
    setFieldValue,
    setValues,
    setTouched,
    setSubmitting,
  };
};
