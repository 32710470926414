import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import {
  CheckDetails,
  PaymentModeSelect,
} from "../../../../../../common/components";
import { getFormatedDate } from "../../imports";
import React, { useEffect } from "react";
import moment from "moment";

type Props = {
  formik: FormikProps<any>;
  PaymentDateField: React.ReactNode;
};
export const PaymentModeDetailsForm = (props: Props) => {
  const { editId } = useParams();

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        if (type === "paymentDate") {
          props.formik.setFieldValue("check_date", "Invalid date");
        }
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          if (type === "paymentDate") {
            let check_expiry_date = getFormatedDate(
              moment(dateFormated)
                .add(
                  props.formik.values.check_expiry_in === "90 days" ? 90 : 180,
                  "days"
                )
                .toDate()
            );
            props.formik.setValues((values: any) => {
              return {
                ...values,
                check_expiry: check_expiry_date,
                check_date: dateFormated,
              };
            });
          }
        } else {
          props.formik.setFieldValue("check_date", "Invalid date");
        }
      }
    }
  };
  return (
    <>
      <div
        className={`col-12 col-lg-4 mw-325 mb-3  ${
          !props.formik.values.customer_id ? "disabled-item" : ""
        }`}
      >
        <label htmlFor="payment_mode" className="required">
          Payment Mode
        </label>
        <PaymentModeSelect
          isDisabled={!props.formik.values.customer_id}
          handleChange={(e) => {
            props.formik.setFieldValue("payment_mode", e && e.label);
            if (e.label === "Check") {
              props.formik.setFieldValue("check_date", getFormatedDate());
            }
          }}
          paymentMode={props.formik.values.payment_mode}
          error={
            (props.formik.touched.payment_mode &&
              props.formik.errors.payment_mode?.toString()) ||
            ""
          }
          module="Payment Received"

        />
        <span className="error error_payment_mode">
          {(props.formik.touched.payment_mode &&
            props.formik.errors.payment_mode?.toString()) ||
            ""}
        </span>
      </div>
      {props.PaymentDateField}
      {props.formik.values.payment_mode === "Check" && (
        <CheckDetails
          checkExpiry={props.formik.values.check_date}
          checkNumber={props.formik.values.check_number}
          handleChange={props.formik.handleChange}
          handleDate={handleDate}
          isActive={props.formik.values.payment_mode === "Check"}
          editId={editId}
          formErrors={props.formik.errors}
          formik={props.formik}
          module="PR"
        />
      )}
    </>
  );
};
