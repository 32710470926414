import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  InventoryItemValues,
  consumerDetailsValues,
} from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import NotificationModal from "../../../../common/components/NotificationModal";
import OrgTag from "../../../../common/components/OrgTag";
import Edit from "../../../../common/components/kebabMenu/Edit";
import { removeItem } from "../../itemSlice";
import DeleteItem from "../Delete";

type Props = {
  itemData: InventoryItemValues;
};
const ItemDetailHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const itemData = props.itemData;
  const [deleteItemId, setDeleteItemId] = useState(-1);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let customerRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Customer")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Back button handler from customer details page
   */
  const backFromCustomerViewHandler = () => {
    // if (editId) {
    //   navigate("/customers");
    // } else {
    //   navigate("/customers/create");
    // }
    navigate("/items");
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromCustomerViewHandler}
        data={{
          num: itemData?.name,
          status: "",
        }}
        dataId={editId}
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          {itemData ? (
            <>
              <div className="left-group">
                <span>
                  {itemData?.organization_name && <OrgTag organization={itemData?.organization_name} />}
                </span>
              </div>

              <div className="actions-wrap d-flex flex-no-shrink">
                {customerRolePermission.includes("Update") ? (
                  <Edit
                    url={"/items/edit/" + itemData.id}
                    isMutable={true}
                    name="Item"
                    isLocked={false}
                    className=""
                    lockDate=""
                  />
                ) : null}
                {customerRolePermission.includes("Delete") ? (
                  <Delete
                    className="text-decoration-none delete-item"
                    deleteIcon={true}
                    isMutable={Boolean(!itemData.is_associated)}
                    onClick={() => {
                      if (itemData?.is_associated) {
                        setDeleteItemId(Number(itemData.id));
                      }
                    }}
                    isLocked={false}
                    lockDate=""
                    name="Item"
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <DeleteItem
        deleteId={Number(itemData.id)}
        refresh={() => {
          navigate("/items")
        }}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
};

export default ItemDetailHeader;
