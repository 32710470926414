import * as yup from "yup";

export const budgetSummaryReportFilterSchema = yup.object({
    organization_id: yup.string().required("Please select an organization."),
    start_date: yup.string().required("Please select a start date."),
    period: yup.string().required("Please select a period."),
    no_of_periods: yup.number().required("Please select a number of periods."),
    currency_id: yup.string().required("Please select a currency."), 
    budget_id: yup.string().required("Please select a budget."),
});
