import { useMediaQuery } from "@react-hook/media-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setListFilters } from "../../../../appSlice";
import { DefaultProfileImage } from "../../../../assets/images";
import { organizationPermission } from "../../../../helpers/organizationHelper";
import UserService from "../../../../keycloak/UserService";
import { ObjectType, UserOrganizationValues } from "../../../../types";
import {
  currentUserDetails,
  currentUserOrgSelector,
  currentUserSelector,
  fetchCustomFrequencySettings,
  fetchOrganizationListOfUsers,
  fetchtransactionConfig,
  getOrganizationListOfUser,
  parentUpdate,
  searchQuerySelector,
  setCurrentUserDetails,
  setFiscalYearFilterForValidation,
  setLoaderState,
} from "../../../common/commonSlice";
import { colors } from "../../../constants/colors";
import { getCurrencies } from "../../currencyConfiguration/currencyConfigurationSlice";
import { SearchBox } from "../../search/searchBox/searchBox";
import {
  getUserOrganizationsList,
  updateTokenStatus,
} from "../../userManagement/userSlice";
import NotificationModal from "../notificationModal";
import "./Header.css";
import AddDropdown from "./addDropdown/addDropdown";
import { LogoSection } from "./logoSection/logoSection";
import { OrganizationDropdown } from "./organizationDropdown/organizationDropdown";
import { ProfileDropdown } from "./profileDropdown/ProfileDropdown";
import { Settings } from "./settings/settings";
import HeaderSearchModal from "./headerSearchModal/HeaderSearchModal";
import { getFiscalYear } from "../../../../helpers/fiscalYearHelper";
import { FiscalYearPopupModal } from "../../onBoarding/fiscalYearPopup/FiscalYearPopupModal";

function Header() {
  const initialOrganizationValues = {
    last_login_org_id: 0,
    organization_details: [
      {
        id: 0,
        last_login: false,
        name: "",
        parent_hierarchy: "",
      },
    ],
  };
  const dispatch = useAppDispatch();
  const userOrganization = useAppSelector(currentUserOrgSelector);
  const userInfoId = useRef(0);
  const [profileName, setProfileName] = useState("");
  const [currentOrganizationId, setCurrentOrganizationId] = useState(0);
  const [currentOrganizationName, setCurrentOrganizationName] = useState("");
  const [disabledFeatureList, setDisabledFeatureList] = useState<ObjectType[]>(
    []
  );
  const [isOrgListApiCalled, setOrgListApiCalled] = useState(false);
  const [isSignUp, setSignUp] = useState(false);
  const [organizationId, setOrganizationId] = useState(currentOrganizationId);
  const [accessMapping, setAccessMapping] = useState([]);
  const [parentOrganizationId, setParentOrganizationId] = useState(0);
  const [isSwitchOrg, setIsSwitchOrg] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [invitedOrganizationList, setInvitedOrganizationList] =
    useState<UserOrganizationValues>(initialOrganizationValues);
  const [isLastOrgIdNull, setIsLastOrgIdNull] = useState(-1);
  const [isOrgChanged, setIsOrgChanged] = useState(false);
  const [organizationIDList, setOrganizationIDList] = useState<number[]>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const medLargeDevices = useMediaQuery("(min-width: 768px)");
  const searchButtonMediaQuery = useMediaQuery("(max-width: 1024px)");
  const [localStorageOrg, setLocalStorageOrg] = useState(
    sessionStorage.getItem("orgId")
  );

  let displayName: string = "";
  if (profileName) {
    let userName = profileName.trim();
    if (!/\s/.test(userName)) {
      if (userName.length > 1) {
        displayName = userName[0].toUpperCase() + userName[1].toUpperCase();
      } else {
        displayName = userName[0];
      }
    } else {
      let values = userName.match(/\b(\w)/g)?.join("")!;
      displayName = values[0] + values[1];
    }
  }

  useEffect(() => {
    const usersOrganizationList = async () => {
      const responseAction = await dispatch(getUserOrganizationsList(0));
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setInvitedOrganizationList(response);
      }
    };
    if (isLastOrgIdNull !== -1) usersOrganizationList();
  }, [isLastOrgIdNull, currentUserInfo.organization_id]);

  // useEffect(() => {
  //   getOrgList();
  // }, [currentOrganizationId, isSwitchOrg, isLastOrgIdNull]);
  const CurrentUserInformation = async (
    orgId: number,
    orgChanged: boolean = false
  ) => {
    setDisabled(true);
    dispatch(setLoaderState(true));
    const currentUserResponse = await dispatch(
      currentUserDetails({
        orgId: orgId,
        isOrgChanged: orgChanged ? orgChanged : isOrgChanged,
      })
    );
    setDisabled(false);
    if (
      currentUserResponse.payload &&
      !("error" in currentUserResponse.payload)
    ) {
      if (currentUserResponse.payload.is_token_refreshed) {
        currentUserResponse.payload.multi_org_null_last_login
          ? setIsLastOrgIdNull(1)
          : setIsLastOrgIdNull(0);
      }
      dispatch(setLoaderState(false));
      if (!currentUserResponse.payload.is_token_refreshed) {
        dispatch(setLoaderState(true));
        await UserService.updateToken();
        dispatch(
          updateTokenStatus({
            userId: currentUserResponse.payload.id,
            tokenStatus: true,
            orgId: currentUserResponse.payload.organization_id,
          })
        )
          .then(() => {
            dispatch(setLoaderState(false));
            currentUserResponse.payload.multi_org_null_last_login
              ? setIsLastOrgIdNull(1)
              : setIsLastOrgIdNull(0);
          })
          .catch(() => {
            dispatch(setLoaderState(false));
          });
      }
      if (!currentUserResponse.payload.multi_org_null_last_login) {
        dispatch(setCurrentUserDetails(currentUserResponse.payload));
        dispatch(
          setFiscalYearFilterForValidation(
            getFiscalYear(
              currentUserResponse.payload.organization_fiscal_year ||
                "April-March",
              "current"
            )
          )
        );
        setAccessMapping(currentUserResponse.payload.user_access_mapping);
        setProfileName(currentUserResponse.payload.name);
        setDisabledFeatureList(currentUserResponse.payload.disabled_feature);
        userInfoId.current = currentUserResponse.payload.id;
        setCurrentOrganizationId(currentUserResponse.payload.organization_id);
        await dispatch(
          fetchtransactionConfig(currentUserResponse.payload.organization_id)
        );
        await dispatch(
          fetchCustomFrequencySettings(
            currentUserResponse.payload.organization_id
          )
        );
        sessionStorage.setItem(
          "orgId",
          currentUserResponse.payload.organization_id
        );
        setLocalStorageOrg(sessionStorage.getItem("orgId"));
        dispatch(parentUpdate(currentUserResponse.payload.is_super_parent));
        // getUserOrganizations();
      }
    } else {
      const errorDetails =
        currentUserResponse.payload?.error?.response?.detail[0]?.type;
      if (errorDetails === "invalid.organization_id") {
        setDisabled(true);
        const userResponse = await dispatch(
          currentUserDetails({ orgId: 0, isOrgChanged: isOrgChanged })
        );
        if (userResponse.payload.is_token_refreshed) {
          userResponse.payload.multi_org_null_last_login
            ? setIsLastOrgIdNull(1)
            : setIsLastOrgIdNull(0);
        }
        setDisabled(false);
        if (!("error" in userResponse.payload)) {
          if (!userResponse.payload.multi_org_null_last_login) {
            setAccessMapping(userResponse.payload.user_access_mapping);
            setProfileName(userResponse.payload.name);
            setCurrentOrganizationId(userResponse.payload.organization_id);
            sessionStorage.setItem(
              "orgId",
              userResponse.payload.organization_id
            );
            setLocalStorageOrg(sessionStorage.getItem("orgId"));
            // getUserOrganizations();
          }
        } else {
          setAccessMapping([]);
          setProfileName("");
          setCurrentOrganizationId(0);
        }
      } else {
        setAccessMapping([]);
        setProfileName("");
        setCurrentOrganizationId(0);
      }
      dispatch(setLoaderState(false));
    }
  };
  // useEffect(() => {
  //   // CurrentUserInformation();
  // }, [localStorageOrg]);

  useEffect(() => {
    if (currentOrganizationId !== 0 && isLastOrgIdNull === 0) {
      getUserOrganizations();
    }
  }, [currentOrganizationId, isLastOrgIdNull]);

  // const getOrgList = async () => {
  //   if (currentOrganizationId !== 0 && isLastOrgIdNull === 0) {
  //     const responseAction = await dispatch(
  //       getOrganizationListForReport({ orgId: currentOrganizationId })
  //     );
  //     if (responseAction.payload) {
  //       const response = responseAction.payload;
  //       if (Object.keys(response).length && !("error" in response)) {
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    setOrganizationId(currentOrganizationId);
    organizationPermission(
      parentOrganizationId,
      currentUserInfo.organization_id
    );
  }, [currentOrganizationId, currentUserInfo.organization_id]);

  useEffect(() => {
    if (currentOrganizationId !== 0 && isLastOrgIdNull === 0) {
      const getOrgCurrencyList = async () => {
        const getCurrencyListResponseAction = await dispatch(
          getCurrencies({ orgId: currentOrganizationId })
        );
        const getPrefixResponse = getCurrencyListResponseAction.payload;
        if (
          Object.keys(getCurrencyListResponseAction).length &&
          !("error" in getCurrencyListResponseAction)
        ) {
        }
      };
      getOrgCurrencyList();
    }
  }, [currentOrganizationId, isLastOrgIdNull]);

  useEffect(() => {
    let parentOrgId: number = 0;
    let orgIds: any;
    for (orgIds of Object.keys(accessMapping)) {
      const data = accessMapping[orgIds];
      for (let childOrgIds of data) {
        if (Number(childOrgIds) === Number(organizationId)) {
          parentOrgId = Number(orgIds);
          break;
        }
      }
    }
    setParentOrganizationId(parentOrgId);
  }, [accessMapping, organizationId]);

  useEffect(() => {
    organizationPermission(
      parentOrganizationId,
      currentUserInfo.organization_id
    );
  }, [parentOrganizationId, currentUserInfo.organization_id]);

  useEffect(() => {
    userOrganization
      ? userOrganization.map((org) => {
          if (org.id === currentOrganizationId) {
            setCurrentOrganizationName(org.name);
          }
        })
      : setCurrentOrganizationName("");
  }, [currentOrganizationId, userOrganization]);

  useEffect(() => {
    if (currentOrganizationName !== "") {
      sessionStorage.setItem("OrgName", currentOrganizationName);
    }
  }, [currentOrganizationName]);

  useEffect(() => {
    !isOrgListApiCalled &&
      !isSignUp &&
      getOrganizationIDList().then((response: any) => {
        if (
          Array.isArray(response.org_list) &&
          response.org_list.length === 0
        ) {
          return;
        }
        if (sessionStorage.getItem("orgId")) {
          CurrentUserInformation(Number(sessionStorage.getItem("orgId")));
          return;
        }
        if (response.last_login_org_id) {
          CurrentUserInformation(Number(response.last_login_org_id));
          return;
        }
        if (organizationIDList.includes(Number(localStorageOrg))) {
          CurrentUserInformation(Number(localStorageOrg));
          return;
        } else {
          CurrentUserInformation(response.org_list[0]);
          return;
        }
      });
  }, [currentOrganizationId]);

  const getUserOrganizations = useCallback(async () => {
    if (currentOrganizationId !== 0 && isLastOrgIdNull === 0) {
      await dispatch(getOrganizationListOfUser(currentOrganizationId));
    }
  }, [currentOrganizationId, isLastOrgIdNull]);

  const getOrganizationIDList = async () => {
    const organizationIDListResponse = await dispatch(
      fetchOrganizationListOfUsers()
    );
    const response = organizationIDListResponse.payload;
    if (!("error" in response)) {
      if (response.org_list.length !== 0) {
        setOrgListApiCalled(true);
      }
      if (Array.isArray(response.org_list) && response.org_list.length === 0) {
        setSignUp(true);
        CurrentUserInformation(0).then(() => {
          CurrentUserInformation(Number(sessionStorage.getItem("orgId")));
          return;
        });
      }
      setOrganizationIDList(response.org_list);
    }
    return response;
  };

  const switchOrganizationHandler = async (id: number) => {
    setOrganizationId(id);
    setCurrentOrganizationId(id);
    setIsSwitchOrg(!isSwitchOrg);
    dispatch(setListFilters({}));
    sessionStorage.setItem("orgId", id.toString());
    setLocalStorageOrg(sessionStorage.getItem("orgId"));
    await CurrentUserInformation(Number(id));
  };

  const selectOrganizationHandler = async (orgId: number) => {
    setOrganizationId(orgId);
    setCurrentOrganizationId(orgId);
    sessionStorage.setItem("orgId", orgId.toString());
    setLocalStorageOrg(sessionStorage.getItem("orgId"));
    setIsOrgChanged(true);
    await CurrentUserInformation(Number(orgId), true);
  };

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-wrapper fixed-top">
          <LogoSection />
          <div
            className="collapse navbar-collapse top-nav"
            id="navbarSupportedContent"
          >
            <ul
              className="navbar-nav me-auto"
              style={disabled ? { pointerEvents: "none" } : {}}
            >
              <li className="nav-item" key={"nav-add-drop"}>
                <AddDropdown
                  orgId={currentOrganizationId}
                  userInfoId={userInfoId.current}
                  disabledFeatureList={disabledFeatureList}
                />
              </li>
              <li>
                {searchButtonMediaQuery ? (
                  <HeaderSearchModal />
                ) : (
                  <SearchBox
                    variant="blue"
                    moduleValue={"global_search"}
                    searchValue={""}
                    getSearchResult={(
                      searchOutput: ObjectType[],
                      value,
                      module
                    ) => {}}
                  />
                )}
              </li>
              {/* hidding the Search option and All option dropdown from Header as per ticket: https://app.clickup.com/t/85zrmgcae */}
              <li className="nav-item" key={"nav-org"}>
                <OrganizationDropdown
                  switchOrganizationHandler={switchOrganizationHandler}
                  currentOrganizationName={currentOrganizationName}
                  currentOrganizationId={currentOrganizationId}
                  userOrganization={userOrganization}
                />
              </li>
              {medLargeDevices && (
                <li className="nav-item" key={"nav-settings"}>
                  {" "}
                  <Settings disabledFeatureList={disabledFeatureList} />
                </li>
              )}
              {/* hidding the Notification option from Header as per ticket: https://app.clickup.com/t/85zrmgcae */}
              <li className="nav-item" key={"nav-profile"}>
                {" "}
                <ProfileDropdown
                  profileImage={DefaultProfileImage}
                  profileName={profileName}
                  displayName={displayName}
                  navLinkColor={colors.black}
                  invitedOrganizationList={invitedOrganizationList}
                  selectOrganizationHandler={selectOrganizationHandler}
                  disabledFeatureList={disabledFeatureList}
                  isLastOrgIdNull={isLastOrgIdNull}
                />
              </li>
              {/* hiding the settings and Profile option from profile dropdown as per ticket: https://app.clickup.com/t/85zrmgcae */}
            </ul>
          </div>
        </nav>
      </div>
      <FiscalYearPopupModal
        shouldOpen={currentUserInfo.organization_fiscal_year === null}
      />
      <NotificationModal />
    </div>
  );
}

export default Header;
