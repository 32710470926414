import { useEffect } from "react";

export const useScrollToElement = () => {
  const scrollToElement = (
    identifierType: "id" | "className",
    identifier: string
  ) => {
    const element = findElement(identifierType, identifier);
    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const scrollOffset =
        typeof window.pageYOffset !== "undefined"
          ? window.pageYOffset
          : window.scrollY;
      const y = scrollOffset + elementTop - 200;
      window.scrollTo(0, y);
    }
  };
  const findElement = (
    identifierType: "id" | "className",
    identifier: string
  ) => {
    let element = null;
    if (identifierType === "className") {
      element = document.getElementsByClassName(identifier)[0];
    } else {
      element = document.getElementById(identifier);
    }
    return element;
  };
  return { scrollToElement };
};
