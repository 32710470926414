import { InvoicePaymentDetailValues, ObjectType } from "../../../../../types";
import {
  DateFormatHandler,
  getProfileFormatedDate,
} from "../../../../../helpers/helper";
import AuthorizedSignature from "../../../../common/components/AuthorizedSignature";
import AddressSection from "../../../../common/components/detailsPage/AddressSection";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import Notes from "../../../../common/components/detailsPage/Notes";
import FileList from "../../../../common/components/FileList";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import JournelView from "../../../../common/components/JournelView";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { HorizontalTab } from "../../../../common/components/HorizontalTab";
import Table from "../../../../common/components/table/Table";
import { useMemo, useState } from "react";
import { EditIcon } from "../../../../common/components";
import RefundModal from "../refund/RefundModal";

import "./paymentDetailsBody.css";
import { DeleteIconModal } from "./DeleteIconModal/deleteIconModal";
import { removeRefund } from "../../paymentReceivedSlice";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { getFiscalYear } from "../../../../../helpers/fiscalYearHelper";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";

type Props = {
  paymentReceivedData: InvoicePaymentDetailValues;
  paymentReceivedDetailResponse: () => void;
};

const PaymentReceivedDetailBody: React.FC<Props> = ({
  paymentReceivedData,
  paymentReceivedDetailResponse,
}) => {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isDeleteAttempted, setIsDeletedAttempted] = useState(false);
  const dispatch = useAppDispatch();
  const isAuditLogEnabled = process.env.REACT_APP_AUDIT_LOG_ENABLED;

  const fiscal_year_date = useMemo(() => {
    return getFiscalYear(
      currentUserInfo.organization_fiscal_year || "April-March",
      "current"
    );
  }, [currentUserInfo.organization_fiscal_year]);

  let no_withholding_tax_count = 0;
  let no_payment_amount_count = 0;
  paymentReceivedData.unpaid_invoice_lines.forEach((element) => {
    if (element.withholding_tax === 0) {
      no_withholding_tax_count = no_withholding_tax_count + 1;
    }
    if (element.payment === 0) {
      no_payment_amount_count = no_payment_amount_count + 1;
    }
  });

  const deleteRefund = async (refundId: number) => {
    let responseAction = await dispatch(
      removeRefund({
        refundId: refundId,
        orgId: currentUserInfo.organization_id,
      })
    );
    let response = responseAction.payload;

    if (Object.keys(response).length && !("error" in response)) {
      await paymentReceivedDetailResponse();
      SuccessToaster("Refund removed successfully", "refund-remove-success");
      setIsDeletedAttempted(true);
    }
  };

  const paymentReceivedFor = (
    <>
      {paymentReceivedData.unpaid_invoice_lines.filter(
        (unpaidInvoices) =>
          unpaidInvoices.payment !== 0 || unpaidInvoices.withholding_tax !== 0
      ).length ? (
        <div
          className="payment-detail-table-wrapper mt-0"
          style={{ border: "none" }}
        >
          <table
            className={`table payment-detail-table pr-detail-table m-0 mt-0 ${
              paymentReceivedData.is_tax_deducted ? "with-withhold" : ""
            }`}
            style={{ border: "none" }}
          >
            <thead>
              <tr>
                <th>INVOICE NUMBER</th>
                <th>INVOICE DATE</th>
                <th>INVOICE AMOUNT</th>
                {paymentReceivedData.is_tax_deducted &&
                no_withholding_tax_count !==
                  paymentReceivedData.unpaid_invoice_lines.length ? (
                  <th>WITHHOLDING TAX</th>
                ) : (
                  <></>
                )}
                {no_payment_amount_count !==
                paymentReceivedData.unpaid_invoice_lines.length ? (
                  <th>PAYMENT AMOUNT</th>
                ) : (
                  <></>
                )}
                <th>FOREX GAIN/LOSS</th>
              </tr>
            </thead>
            <tbody>
              {paymentReceivedData.unpaid_invoice_lines
                .filter(
                  (unpaidInvoices) =>
                    unpaidInvoices.payment !== 0 ||
                    unpaidInvoices.withholding_tax !== 0
                )
                .map((unpaidInvoices) => {
                  return (
                    <tr>
                      <td>
                        {joinTransactionNumber(unpaidInvoices.invoice_number)}
                      </td>
                      <td>
                        {getProfileFormatedDate(
                          currentUserInfo.organization_date_format,
                          unpaidInvoices.invoice_date
                        )}
                      </td>
                      <td>
                        {NumberFormat(
                          Number(unpaidInvoices.invoice_amount),
                          paymentReceivedData.currency_code,
                          orgCurrencyList
                        )}
                      </td>
                      {paymentReceivedData.is_tax_deducted &&
                      no_withholding_tax_count !==
                        paymentReceivedData.unpaid_invoice_lines.length ? (
                        <td>
                          {NumberFormat(
                            Number(unpaidInvoices.withholding_tax),
                            paymentReceivedData.currency_code,
                            orgCurrencyList
                          )}
                        </td>
                      ) : (
                        <></>
                      )}
                      {no_payment_amount_count !==
                      paymentReceivedData.unpaid_invoice_lines.length ? (
                        <td>
                          {NumberFormat(
                            Number(unpaidInvoices.payment),
                            paymentReceivedData.currency_code,
                            orgCurrencyList
                          )}
                        </td>
                      ) : (
                        <></>
                      )}
                      <td>
                        {unpaidInvoices.forex_amount.toString().indexOf("-") <
                          0 && Number(unpaidInvoices.forex_amount) !== 0
                          ? "+" + unpaidInvoices.forex_amount
                          : unpaidInvoices.forex_amount}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );

  const refundColumns = [
    {
      Header: "Date",
      accessor: "show.refunded_on",
      sort: false,
      sortOrder: "",
      className: "refund-date",
    },
    {
      Header: "Payment Mode",
      accessor: "show.payment_mode",
      sort: false,
      sortOrder: "",
      className: "credit_note_no",
    },
    {
      Header: "Amount Refunded",
      accessor: "show.amount",
      sort: false,
      sortOrder: "",
      className: "credit_applied",
    },
    {
      Header: "Forex gain/loss",
      accessor: "show.forex_amount",
      sort: false,
      sortOrder: "",
      className: "credit_applied",
    },
    {
      Header: "",
      accessor: "show.action",
      sort: false,
      sortOrder: "",
      className: "action",
      Cell: ({ cell: { value } }: any) => {
        return (
          <>
            {paymentReceivedData.organization_id ===
              currentUserInfo.organization_id &&
            fiscal_year_date.startDate <= value.refunded_on ? (
              <span className="cn-pr-action-btn pull-right">
                <RefundModal
                  paymentReceivedData={paymentReceivedData}
                  editId={value.id}
                  refreshList={paymentReceivedDetailResponse}
                >
                  <button
                    className="no-appearance"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleEditRedirection(value.id);
                    }}
                  >
                    <EditIcon color="blue" />
                  </button>
                </RefundModal>
                <DeleteIconModal
                  title="Delete Refund"
                  content="Are you sure about deleting the refund made from this excess payment?"
                  onDeleteConfirm={() => {
                    deleteRefund(value.id);
                  }}
                />
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const refundData = paymentReceivedData.refund_history
    ? paymentReceivedData.refund_history.map((refund) => {
        return {
          show: {
            refunded_on: getProfileFormatedDate(
              currentUserInfo.organization_date_format,
              refund.refunded_on
            ),
            payment_mode: refund.payment_mode,
            amount: paymentReceivedData.currency_code + " " + refund.amount,
            forex_amount:
              currentUserInfo.currency_code + " " + refund.forex_amount,
            action: {
              id: refund.id,
              refunded_on: refund.refunded_on,
            },
          },
        };
      })
    : [];

  const refundHistory = (
    <>
      <Table
        id={"pr_refund_table"}
        columns={refundColumns}
        data={refundData}
        tableClassName={
          "list-table table table-striped w-100 pr-details-refund-table"
        }
        dataSort={true}
        isLoading={undefined}
        haveColumnClassName={true}
        onSortClick={() => {}}
        rowClassName={""}
        emptyClassName={"text-center dataTables_empty"}
        hiddenColumns={[]}
        tbodyRowOnClick={() => {}}
        tbodyColumnClick={() => {}}
      >
        No Refund History
      </Table>
    </>
  );

  const tabHeaders = [`Payments received for`];
  if (
    (paymentReceivedData.refund_history &&
      paymentReceivedData.refund_history.length > 0) ||
    isDeleteAttempted
  ) {
    tabHeaders.push(`Refund History`);
  }

  return (
    <>
      <div id="invoice" className="cn-preview pr-preview transaction-preview">
        <div className="invoice-header d-flex justify-content-between">
          <CompanyDetails
            organizationData={paymentReceivedData.organization_details}
          />
          <div className="invoice-details module-detail-right-summary text-align-right">
            <div className="label-invoice">Payment Received</div>
            <div className="bill-addr-label">From</div>
            <div className="bill-address">
              <span>
                {paymentReceivedData?.customer_billing_address?.customer_name ||
                  paymentReceivedData?.customer_name ||
                  ""}
              </span>
              {paymentReceivedData.customer_billing_address ? (
                <AddressSection
                  addressData={paymentReceivedData.customer_billing_address}
                />
              ) : (
                ""
              )}
            </div>
            {paymentReceivedData.reference_number ? (
              <>
                <div className="bill-addr-label">Reference Number</div>
                <div className="invoice-number">
                  {paymentReceivedData.reference_number}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="table-section payment-detail-section">
          <div className="row pay-detail-row g-0">
            <div className="col pay-date-col">
              <div className="pay-item-text w-100">Payment Date:</div>
              <div className="pay-item-value w-100">
                {getProfileFormatedDate(
                  currentUserInfo.organization_date_format,
                  paymentReceivedData.payment_date
                )}
              </div>
            </div>
            <div className="col pay-item-col">
              <div className="pay-item-text w-100">Payment Number:</div>
              <div className="pay-item-value w-100">
                {joinTransactionNumber(paymentReceivedData.payment_number)}
              </div>
            </div>
            <div className="col pay-mode-col">
              <div className="pay-item-text w-100">Payment Mode:</div>
              <div className="pay-item-value w-100">
                {paymentReceivedData.payment_mode}
              </div>
            </div>
            <div className="col pay-words-col">
              <div className="pay-item-text w-100">
                Amount Received (words):
              </div>
              <div className="pay-item-value w-100">
                {paymentReceivedData.amount_in_words} Only
              </div>
            </div>
          </div>
          <RowWiseFieldView
            mapObject={paymentReceivedData.custom_fields}
            module="Payments Received"
          />
          {paymentReceivedData.notes ? (
            <Notes notes={paymentReceivedData.notes} />
          ) : (
            ""
          )}
          <div className="row g-0">
            <div className="col d-flex amt-received-column">
              <div className="amout-received-digits d-flex">
                <span className="element-label">Amount Received:</span>
                <div style={{ padding: "0px 14px 14px 0px" }}>
                  <span className="amount">
                    {paymentReceivedData.currency_code}{" "}
                    {NumberFormat(
                      Number(paymentReceivedData.total),
                      paymentReceivedData.currency_code,
                      orgCurrencyList
                    )}
                  </span>
                  {paymentReceivedData.refund_history &&
                    paymentReceivedData.refund_history.length > 0 && (
                      <span className="refund-amount-wrapper">
                        <span className="refund-label">Refunded: </span>
                        <span className="refund-amount">
                          {paymentReceivedData.currency_code}{" "}
                          {NumberFormat(
                            Number(paymentReceivedData.total_refunded_amount),
                            paymentReceivedData.currency_code,
                            orgCurrencyList
                          )}
                        </span>
                      </span>
                    )}
                </div>
              </div>
              <div className="show-excess-amt">
                <span className="excess-label">Excess Amount:</span>
                <span className="excess-amt">
                  {paymentReceivedData.currency_code}{" "}
                  {NumberFormat(
                    Number(paymentReceivedData.amount_excess),
                    paymentReceivedData.currency_code,
                    orgCurrencyList
                  )}
                </span>
              </div>
            </div>
          </div>
          <div
            className="row g-0 pay-item-details"
            style={{ border: "1px solid var(--warm-white)" }}
          >
            <HorizontalTab
              tabHeaders={tabHeaders}
              tabBody={[paymentReceivedFor, refundHistory]}
              wrapperClass="invoice-cn-pr-detail-tab"
              disableRerender={true}
            />
          </div>
          <div className="row g-0">
            <div className="col">
              <AuthorizedSignature />
            </div>
          </div>
        </div>
      </div>
      {paymentReceivedData.files.length ? (
        <FileList files={paymentReceivedData.files} />
      ) : null}
      <JournelView
        title="Customer Payments"
        transactionType="Payments Received"
        transactionId={Number(paymentReceivedData.id)}
      />
      {isAuditLogEnabled === "true" && (
        <AuditTrail
          title="Audit Trail"
          transactionType="payment_received"
          transactionId={Number(paymentReceivedData.id)}
        />
      )}
    </>
  );
};
export default PaymentReceivedDetailBody;
